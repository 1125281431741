const initialState = {
    isLogin: false,
    error: null,
    success: null,
    loading: false,
    branchId: null,
    branchLogin: null,
}

const promotionReducer = (state = initialState, action) => {
    switch (action.type) {
        default:
            return state;
    }
}

export default promotionReducer