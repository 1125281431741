import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { makeStyles } from '@material-ui/core/styles'
import { useDispatch } from 'react-redux'
import { loadBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import { loadCategory } from 'Reduxes/inventory/configuration/category/categoryActions'
import { loadColor } from 'Reduxes/inventory/configuration/color/colorActions'
import InputNumber from 'Elements/inputnumber/InputNumber'
import { Button, CircularProgress } from '@material-ui/core'

const animatedComponents = makeAnimated()
const useStyles = makeStyles((theme) => ({
  minWidth: {
    minWidth: '100% !important'
  },
  button: {
    padding: '20px 0 15px',
    marginBottom: '10px',
    borderTop: '1px dashed #eee',
    marginRight: '16px',
    borderRadius: '3px',
    textTransform: 'uppercase',
    fontWeight: '700',
    fontSize: '13px',
    height: '48px'
  },

  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '14px',
    textAlign: 'left',
    fontWeight: '500',
    textTransform: 'capitalize'
  },
  spacer: {
    height: '25px'
  },
  halfWidth: {
    width: '80%'
  },
  formextrafiltercontainer: {
    padding: '44px 20px 20px',
    marginTop: '15px',
    borderRadius: '10px',
    background: '#fafafa',
    border: '1px solid #eee',
    '& label': {
      fontSize: '12px',
      color: '#555'
    }
  },
  daterangeCont: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: '10px'
  }
}))

const ProductFilter = ({ handleFilterSubmit, isLoading, stateFilters }) => {
  const dispatch = useDispatch()

  /**
   * State for handling Filter Product
   */
  const [brands, setBrands] = useState([])
  const [colors, setColors] = useState([])
  const [categories, setCategories] = useState([])
  const [isChildLoading, setChildLoading] = useState(true)

  useEffect(() => {
    // Fetch Brands
    const fetchBrands = async () => {
      let resp = await dispatch(loadBrand())

      if (resp.status) {
        resp.data = resp.data.map((data) => {
          return {
            value: data.id,
            label: data.name
          }
        })
        setBrands(resp.data)
      }
    }

    // Fetch categories
    const fetchCategories = async () => {
      let resp = await dispatch(loadCategory())

      if (resp.status) {
        resp.data = resp.data.map((data) => {
          return {
            value: data.id,
            label: data.name
          }
        })

        setCategories(resp.data)
      }
    }

    const fetchColors = async () => {
      let resp = await dispatch(loadColor())

      if (resp.status) {
        resp.data = resp.data.map((data) => {
          return {
            value: data.id,
            label: data.name
          }
        })

        setColors(resp.data)
      }
    }

    fetchBrands()
    fetchCategories()
    fetchColors()
  }, [])

  useEffect(() => {
    setChildLoading(false)
  }, [brands, categories, colors])

  const localClass = useStyles()

  const [filters, setFilters] = useState(stateFilters)

  return (
    <div style={{ width: '600px', height: '600px' }}>
      <div className={localClass.spacer}></div>

      {isChildLoading ? (
        <CircularProgress size={30} />
      ) : (
        <>
          <div className={localClass.halfWidth}>
            <label htmlFor="Filter by Brands" className={localClass.label}>
              Filter by Brands
            </label>
            <Select
              className={localClass.minWidth}
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(selected) =>
                setFilters({ ...filters, brand_ids: selected })
              }
              value={filters?.brand_ids}
              isMulti
              options={brands}
            />
          </div>
          <div className={localClass.spacer}></div>

          <div className={localClass.halfWidth}>
            <label htmlFor="Filter by Categories" className={localClass.label}>
              Filter by Categories
            </label>
            <Select
              className={localClass.minWidth}
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(selected) =>
                setFilters({ ...filters, category_ids: selected })
              }
              value={filters?.category_ids}
              isMulti
              options={categories}
            />
          </div>
          <div className={localClass.spacer}></div>

          <div className={localClass.halfWidth}>
            <label htmlFor="Filter by Colors" className={localClass.label}>
              Filter by Colors
            </label>
            <Select
              className={localClass.minWidth}
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(selected) =>
                setFilters({ ...filters, color_ids: selected })
              }
              value={filters?.color_ids}
              isMulti
              options={colors}
            />
          </div>
          <div className={localClass.spacer}></div>

          <div className={localClass.halfWidth}>
            <label
              htmlFor="Filter by Product Types"
              className={localClass.label}
            >
              Filter Product Types
            </label>
            <Select
              className={localClass.minWidth}
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(selected) =>
                setFilters({ ...filters, product_types: selected })
              }
              value={filters?.product_types}
              isMulti
              options={[
                { value: 'consignment', label: 'Consignment' },
                { value: 'product_sales', label: 'Product Sales' },
                { value: 'product_general', label: 'Product General' },
                { value: 'discontinue', label: 'Discontinue' }
              ]}
            />
          </div>
          <div className={localClass.spacer}></div>

          <div className={localClass.halfWidth}>
            <label
              htmlFor="Filter by Product Statuses"
              className={localClass.label}
            >
              Filter Product Statuses
            </label>
            <Select
              className={localClass.minWidth}
              closeMenuOnSelect={false}
              components={animatedComponents}
              onChange={(selected) =>
                setFilters({ ...filters, product_statuses: selected })
              }
              value={filters?.product_statuses}
              isMulti
              options={[
                { value: 'false', label: 'Inactive' },
                { value: 'true', label: 'Active' }
              ]}
            />
          </div>
          <div className={localClass.spacer}></div>

          <div className={localClass.daterangeCont}>
            <InputNumber
              name="stock_from"
              label="Stock From"
              withFieldContainer
              handleChange={(value) => {
                filters.stock_from = !value ? 0 : value
                setFilters(filters)
              }}
              value={filters?.stock_from}
              min={0}
            />

            <InputNumber
              name="stock_to"
              label="Stock To"
              withFieldContainer
              handleChange={(value) => {
                filters.stock_to = !value ? 0 : value
                setFilters(filters)
              }}
              value={filters?.stock_to}
              min={0}
            />
          </div>

          <div className={localClass.spacer}></div>

          <div className={localClass.daterangeCont}>
            <InputNumber
              name="sales_price_from"
              label="Sales Price From"
              withFieldContainer
              handleChange={(value) => {
                filters.sales_price_from = !value ? 0 : value
                setFilters(filters)
              }}
              value={filters?.sales_price_from}
              min={0}
            />

            <InputNumber
              name="sales_price_to"
              label="Sales Price To"
              withFieldContainer
              handleChange={(value) => {
                filters.sales_price_to = !value ? 0 : value
                setFilters(filters)
              }}
              value={filters?.sales_price_to}
              min={0}
            />
          </div>

          <div className={localClass.spacer}></div>

          <div className={localClass.daterangeCont}>
            <InputNumber
              name="hpp_from"
              label="HPP from"
              withFieldContainer
              handleChange={(value) => {
                filters.hpp_from = !value ? 0 : value
                setFilters(filters)
              }}
              value={filters?.hpp_from}
              min={0}
            />

            <InputNumber
              name="hpp_to"
              label="HPP to"
              withFieldContainer
              handleChange={(value) => {
                filters.hpp_to = !value ? 0 : value
                setFilters(filters)
              }}
              value={filters?.hpp_to}
              min={0}
            />
          </div>

          <div className={localClass.spacer}></div>

          <Button
            onClick={() => handleFilterSubmit(filters)}
            disabled={isLoading}
            startIcon={
              isLoading && (
                <CircularProgress size={24} style={{ color: '#fff' }} />
              )
            }
            className={`${localClass.minWidth} ${localClass.button}`}
            style={{ backgroundColor: '#f78300', color: '#fff' }}
          >
            Apply Filters
          </Button>
        </>
      )}
    </div>
  )
}

export default ProductFilter
