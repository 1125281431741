import React, { useEffect, useState } from 'react'
import { Field, useFormikContext, ErrorMessage } from 'formik';
import { useDispatch } from 'react-redux';
import { loadEdc } from 'Reduxes/pos/edc/edcActions'
import { loadBranch, filterBranch } from 'Reduxes/configuration/branch/branchActions'
import { loadContact, filterContact } from 'Reduxes/contact/contactActions'
import { loadUnit } from 'Reduxes/inventory/configuration/unit/unitActions'
import { loadVariant } from 'Reduxes/inventory/configuration/variant/variantActions'
import { filterInvoiceToPaid } from 'Reduxes/consignment/invoice/invoiceActions'

export default function SelectData(props) {
    const { name, handleChange, label, value, note, options, col, placeholder, id, nofieldcon, isDisabled } = props;
    const formik = useFormikContext();
    const { setFieldValue } = formik
    const [selectOption, setSelectOption] = useState([])
    const [rawOptions, setRawOptions] = useState(null) // ini untuk nampung resp.data dari server, agar bisa di didapat ekstra data  row nya sesuai option yg dipilih
    const dispatch = useDispatch();
    let extraData = null;
    useEffect(() => {
        const loadRemote = async () => {
            let resp = null;
            let label = null;
            let value = null;

            if (options === 'branch') {
                resp = await dispatch(loadBranch())
                label = 'branch_name'
            } else if (options === 'variant') {
                resp = await dispatch(loadVariant())
            } else if (options === 'contact') {
                resp = await dispatch(loadContact(options))
                // console.log(resp)
            } else if (options === 'contact-brand') {
                resp = await dispatch(filterContact({ type: 'brand' }))
            } else if (options === 'contact-return-consignment') {
                resp = await dispatch(filterContact({ type: 'return_consignment' }))
            } else if (options === 'internal-transfer') {
                resp = await dispatch(filterBranch({ type: 'internal_transfer' }))
            } else if (options === 'uom') {
                resp = await dispatch(loadUnit())
            } else if (options === 'invoice_to_paid') {
                resp = await dispatch(filterInvoiceToPaid(id))
            } else if (options === 'edc') {
                resp = await dispatch(loadEdc())
            } else {
                setSelectOption([
                    { label: 'Module not found', value: '' }
                ])
                return;
            }

            /* 
            ERROR POSSIBILITY 
            ==============================
            . di action load nya ga return dan ga pake handleRequest tp masih pake handleReq
            */
            if (resp.status) {
                if (!resp.data.length) {
                    setSelectOption([
                        { label: 'Data unavailable', value: '' }
                    ])
                } else {
                    setRawOptions(resp.data);
                    if (options === 'uom' && resp.data) {
                        setSelectOption(resp.data.reverse().map(data => (
                            { label: data[label || 'name'], value: data[value || 'id'] }
                        )))
                    } else {
                        setSelectOption(resp.data.map(data => (
                            { label: data[label || 'name'], value: data[value || 'id'] }
                        )))
                    }
                }
            } else {
                setSelectOption([
                    { label: 'Data failed', value: '' }
                ])
            }

        }
        if (typeof options === 'string') {
            setSelectOption([
                { label: 'Loading data', value: '' }
            ])
            loadRemote();
        } else {
            if (options && options.length) {
                setSelectOption(options)
            } else {
                setSelectOption([
                    { label: 'Option unavailable', value: '' }
                ])
            }
        }
    }, [])

    useEffect(() => {
        if (value) {
            if (Array.isArray(options)) {
                setFieldValue(name, value)

                const label = options.find(o => o.value === value).label

                setFieldValue(`${name[0] !== '_' ? '_' : ''}${name}_label`, label)
            } else {
                setFieldValue(name, value)

                const label = selectOption.find(o => o.value === value)

                setFieldValue(`${name[0] !== '_' ? '_' : ''}${name}_label`, label)
            }
        } else {
            if (!placeholder) {
                if (selectOption.length) {
                    setFieldValue(name, selectOption[0].value)
                    setFieldValue(`${name[0] !== '_' ? '_' : ''}${name}_label`, selectOption[0].label)
                }
            } else {
                setFieldValue(name, '')
                setFieldValue(`${name[0] !== '_' ? '_' : ''}${name}_label`, 'placeholder')
            }
        }

        rawOptions && handleChange && handleChange(value || selectOption[0].value, formik, rawOptions[0])

    }, [selectOption])

    useEffect(() => {
        setFieldValue(name, value);

        // console.log(name)
    }, [value])

    useEffect(() => {
        // setSelectOption([{ label: 'asdasdsas', value: 123 }]);
        // console.log('options changed', name, selectOption, options)
    }, [options])

    // console.clear()
    // console.log(selectOption)
    return (
        <div className={nofieldcon ? '' : `field-container ${col ? `span-${col}` : ``}`}>
            {label && <label htmlFor={name}>{label}</label>}
            <Field name={name} value={value}>
                {
                    (formik) => {
                        let { form, field } = formik;
                        const { setFieldValue } = form;
                        const { name } = field;

                        return (
                            <select {...field}
                                disabled={isDisabled}
                                onChange={e => {
                                    let newVal = e.currentTarget.value;
                                    let selectedLabel = e.target[e.target.selectedIndex].text
                                    setFieldValue(name, newVal)
                                    setFieldValue(`${name}_label`, selectedLabel)

                                    /* 
                                    jika pake setvalues pada selectdata
                                    value selectdatanya ga ngupdate
                                    case found at create new sales on payment name
                                    setValues({
                                        ...values,
                                        [name]: newVal,
                                        [`${name}_label`]: selectedLabel
                                    })


                                    send ekstra data 
                                     contoh implementasi untuk variant 
                                     agar bisa didapat juga sub variant value nya
                                    console.clear();
                                    console.log(rawOptions)
                                    console.log(newVal, form, extraData, '<<<<<<<<<'); 
                                    */
                                    if (typeof options === 'string') {
                                        extraData = rawOptions.find(x => x.id === +newVal)
                                    }


                                    if (handleChange) {
                                        handleChange(newVal, form, extraData)

                                    }
                                }}
                            >
                                {
                                    placeholder && <option value="">{placeholder === '' ? 'Select your option' : placeholder}</option>

                                }
                                {
                                    selectOption.map((opt, index) => {
                                        return (
                                            <option key={index} value={opt.value}>{opt.label}</option>
                                        )
                                    })
                                }

                            </select>
                        )
                    }
                }
            </Field>
            { note && <span className="note">{note}</span>}
            <ErrorMessage name={name} component="div" className="errMsg" />
        </div>
    )
}