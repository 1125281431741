import React, { useEffect, useState } from 'react'
import { money, sortData, handleSortState } from '@/Helper'
import NoReport from 'Elements/NoReport'
import { Helmet } from "react-helmet"
import KopSurat from 'Elements/KopSurat'
import ThSort from 'Elements/ThSort'
import MutationRowParent from './MutationRowParent'
import { NoPrint } from "react-easy-print";
import SimpleBarHOC from '../../../../comps/elements/SimpleBarHOC'


const keys = ['in', 'out', 'sales', 'reject', 'on_hand']
const thEnd = {
    on_hand: {
        sort_type: null,
        has_sort: true,
        className: 'cell-width-as-content',
        label: "on hand",
    },
    on_hand_value: {
        sort_type: null,
        has_sort: true,
        label: "value",
        className: "cell-tr"
    }
}

export default function Mutation({ data }) {

    const [rows, setRows] = useState([])
    const [scroll, setScroll] = useState(true)
    const [showDetail, setShowDetail] = useState(false)
    const [sort, setSort] = useState({
        no: {
            label: 'no.',
            has_sort: false,
            rowspan: 2,
        },
        code: {
            label: 'id',
            className: 'cell-width-as-content cell-highlight',
            sort_type: null,
            has_sort: true,
            rowspan: 2,
        },
        brand_name: {
            has_sort: true,
            className: 'cell-width-as-content',
            sort_type: null,
            rowspan: 2,
            label: 'brand'
        },
        name: {
            className: 'cell-width-as-content',
            has_sort: true,
            sort_type: null,
            rowspan: 2,
            label: 'name'
        },
        variant: {
            has_sort: false,
            rowspan: 2,
            label: 'variant'
        },
        /*
        total_product: {
            has_sort: true,
            rowspan: variants.length + 1,
            sort_type: null,
            label: '#product'
        },
        variant: {
            has_sort: false,
            className: 'th-text-center',
            colspan: longestVariant.length,
            label: 'variant'
        },
        total_stock: {
            rowspan: variants.length + 1,
            has_sort: true,
            sort_type: null,
            label: 'stock'
        },
        total_reject: {
            has_sort: true,
            rowspan: variants.length + 1,
            sort_type: null,
            label: 'reject'
        },
        uom: {
            rowspan: variants.length + 1,
            has_sort: false,
            label: 'uom'
        },
        total_value: {
            has_sort: true,
            rowspan: variants.length + 1,
            className: "cell-tr",
            sort_type: null,
            label: 'total value'
        }, */

    })
    const [variantSort, setVariantSort] = useState({})
    const sortColumn = (colName, variantIndex = 1000) => {

        if (variantIndex !== 1000) { // variamt sort

            setRows(prevState => {
                return [...sortData({ type: variantSort[colName].sort_type, column: colName, data: prevState })]
            })

            setVariantSort(prev => {
                return { ...handleSortState({ data: prev, column: colName }) }
            })
        } else {
            setRows(prevState => {
                return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
            })

            setSort(prev => {
                return { ...handleSortState({ data: prev, column: colName }) }
            })
        }
    }
    const toggleScroll = () => {
        setScroll(!scroll)
    }
    const toggleSubrows = () => {
        setShowDetail(!showDetail)
    }

    const variantsOnProducts = data.map(d => {
        return d.branches.map(branch => {
            return branch.variants.map(variant => variant.label)
        })
    })

    let longestBranch = null;
    data.forEach(element => {
        if (!longestBranch) {
            longestBranch = element.branches;
            return;
        }

        if (longestBranch.length < element.branches) {
            longestBranch = element.branches
        }
    });

    const grandTotal = data.reduce((total, sum) => {
        return total + sum.on_hand_value;
    }, 0)

    const findLongestVariantIndex = (variantOnBranch) => {
        return variantOnBranch.reduce((longestArrIdx, currentVariant, currentIdx) => {
            return currentVariant.length > variantOnBranch[longestArrIdx].length ? currentIdx : longestArrIdx
        }, 0)
    }

    useEffect(() => {

        if (!data.length) {
            return;
        }

        let initRows = []

        setSort(prev => {
            let thBranch = {}
            longestBranch.forEach((lb, lbIdx) => {
                thBranch[lb.branch_name] = {
                    colspan: 5,
                    has_sort: false,
                    label: lb.branch_name,
                    className: "cell-tc"
                }
            })

            return { ...prev, ...thBranch }
        })

        initRows = data.map((d, dIdx) => {

            // create variant property
            const variantProp = {}

            longestBranch.forEach((lb, lbIdx) => {
                let branch = d.branches?.[lbIdx];
                [...keys].forEach((x, xIdx) => {
                    const isOut = x === "out"
                    const isSales = x === "sales"
                    const isReject = x === "reject"
                    const hasValue = branch?.[x] !== 0
                    const isMinus = isOut || isSales || isReject
                    const val = isMinus && hasValue ? (branch?.[x] * -1) : branch?.[x] || 0
                    variantProp[`variantParent${lbIdx}${xIdx}`] = val
                })
            })

            return d.branches.length > 0 ? { ...d, ...variantProp } : null
        }).filter(Boolean)

        setVariantSort(prev => {
            longestBranch.forEach((v, i) => {
                keys.forEach((key, keyIdx) => {
                    prev[`variantParent${i}${keyIdx}`] = {
                        sort_type: null,
                    }
                })
            })

            return { ...prev }
        })

        setRows(initRows)

    }, [data, longestBranch])


    return !data.length ? <NoReport /> : (
        <>
            <Helmet>
                <style type="text/css">
                    {`@page {size: landscape}`}
                </style>
            </Helmet>
            <div className="print-report zoom-60">
                <KopSurat fit />
                <p className="asdasd" style={{ marginBottom: '20px' }}>Product Mutation Report <span>Showing report from all branches</span></p>

                <NoPrint force={true}>
                    <div className="table-menu">
                        <div className="left">
                            <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                            <button onClick={toggleSubrows}>toggle subrows</button>
                        </div>
                        <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                    </div>
                </NoPrint>


                <SimpleBarHOC status={scroll}>
                    <table className="table-report inventory-mutation mutation " width="100%">
                        <thead>
                            <tr>
                                {
                                    Object.keys(sort).map((key, idx) => (
                                        <ThSort
                                            key={`th-${idx}`}
                                            label={sort[key].label}
                                            rowspan={sort[key].rowspan}
                                            colspan={sort[key].colspan}
                                            sort={sort[key].has_sort}
                                            handleClick={() => sortColumn(key)}
                                            className={`${sort[key].className}  ${sort[key].sort_type}`}
                                        />
                                    ))
                                }
                            </tr>
                            <tr className={`sticky-variant-row-1`}>
                                {
                                    longestBranch.map((lb, lbIdx) => (
                                        keys.map((key, keyIdx) => (
                                            <ThSort
                                                key={`subth${keyIdx}`}
                                                // label={key.replaceAll("_", " ")}
                                                label={key.split().filter((v) => {
                                                    if (v === '_') return v = ''
                                                    return v
                                                }).join()}
                                                sort={true}
                                                className="cell-width-as-content"
                                                handleClick={() => sortColumn(`variantParent${lbIdx}${keyIdx}`, lbIdx)}
                                            />
                                        ))
                                    ))
                                }
                                {/* 
                                Remoe total on hand and total value on report mutation.
                                <th></th>
                                <th className="cell-tr cell-width-as-content">IDR {money(grandTotal)}</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {
                                rows.map((d, index) => (
                                    <MutationRowParent
                                        key={`trdetail${index}`}
                                        no={index + 1}
                                        showDetail={showDetail}
                                        keys={keys}
                                        longestBranch={longestBranch}
                                        subrows={variantsOnProducts[index][findLongestVariantIndex(variantsOnProducts[index])]}
                                        data={d}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </SimpleBarHOC>
            </div>
        </>
    )
}
