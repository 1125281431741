import React from 'react'
import Logo from 'Img/logo-black.png'
import Email from 'Img/icons/email.svg'
import Notif from 'Img/icons/notification.svg'
import SignOut from 'Img/icons/turn-off.svg'
import Avatar from 'Img/avatar.jpg'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux';
import { userLogout } from '../../reduxes/user/userActions'
import { getCurrentUser, getLocalStorage } from '@/Helper'


export default function Topbar() {
    const dispatch = useDispatch();
    const { branchLogin } = getLocalStorage()
    const { name } = getCurrentUser()

    return (
        <div className="topbar">
            <div className="container">
                <a className="logo" href="/">
                    <img src={Logo} alt="logo" />
                    <span>PhillipWorks</span>
                </a>
                <div className="menus">
                    {/*<Link className="menu" to="/"><img src={Email} alt="" /></Link>*/}
                    {/*<Link className="menu" to="/"><img src={Notif} alt="" /></Link>*/}
                    <Link
                        className="menu"
                        onClick={
                            () => {
                                dispatch(userLogout());
                            }
                        }
                        to="/login"
                    >
                        <img src={SignOut} alt="" />
                    </Link>

                    <span>
                        <Link className="avatar" to="/configuration">
                            <FontAwesomeIcon icon="user-circle" />

                            <span>{name} - {branchLogin?.branch_name ?? ''}</span>
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    )
}
