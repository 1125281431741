import React, { useEffect, useState } from 'react'
import { money, sortData, handleSortState, dateDMY } from '@/Helper'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport'
import { Helmet } from 'react-helmet'
import KopSurat from "../../../comps/elements/KopSurat";
import { NoPrint } from "react-easy-print";
import ThSort from 'Elements/ThSort'
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';

export default function TableDetail({ data }) {

    const [rows, setRows] = useState({})
    const [scroll, setScroll] = useState(true)
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        },
        date: {
            label: 'date',
            has_sort: true,
            sort_type: null,
        },
        branch: {
            label: 'branch',
            sort_type: null,
            has_sort: false
        },
        id: {
            label: 'code',
            has_sort: false,
            sort_type: null,
        },
        type: {
            label: 'type',
            has_sort: false,
            sort_type: null,
        },
        category: {
            label: 'category',
            has_sort: false,
        },
        sub_total: {
            label: 'sub total',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null,
        },
        total_discount: {
            label: 'disc',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null,
        },
        grand_total: {
            label: 'total',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null,
        },
        hpp: {
            label: 'hpp',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
        additional_cost: {
            label: 'ad. cost',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null
        },
        net_income: {
            label: 'net value',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        }
    })

    const toggleScroll = () => {
        setScroll(!scroll)
    }

    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            prevState.pos[tableIndex].details = sortData({ type: sort[colName].sort_type, column: colName, data: prevState.pos[tableIndex].details })
            return { ...prevState }
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {
        setRows(data)
    }, [data])

    return (
        <>
            {
                rows?.pos?.length < 1 ? <NoReport /> : (
                    <>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <div className="print-report zoom-75">
                            <KopSurat />
                            <p style={{ marginTop: '40px' }} className="asdasd">POS Sales Report <span>Showing POS report from all branches</span></p>
                            {
                                rows?.pos?.map((item, itemIdx) => (
                                    <>
                                        <h5><DeviceHubIcon /> Product Name  : {item.product_name}</h5>
                                        <div className="report-summary-box mb-16">
                                            <div className="item teal">
                                                <p>
                                                    <span>Net Income</span>
                                                    <span>IDR {money(item.net_income)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Sales Qty</span>
                                                    <span>{item.total_qty || 0}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>sub total</span>
                                                    <span>IDR {money(item.sub_total)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Discount</span>
                                                    <span>IDR {money(item.total_discount)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Total</span>
                                                    <span>IDR {money(item.grand_total)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>HPP</span>
                                                    <span>IDR {money(item.hpp)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Other Cost</span>
                                                    <span>IDR {money(item.additional_cost)}</span>
                                                </p>
                                            </div>
                                        </div>

                                        <NoPrint force={true}>
                                            <div className="table-menu">
                                                <div className="left">
                                                    <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                                </div>
                                                <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                            </div>
                                        </NoPrint>

                                        <div className={`scroll-x ${itemIdx < 1 ? ' mb-40' : ''}`}>
                                            <SimpleBarHOC status={scroll}>
                                                <table className="table-report">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                Object.keys(sort).map((key, idx) => (
                                                                    <ThSort
                                                                        key={`th-${idx}`}
                                                                        label={sort[key].label}
                                                                        sort={sort[key].has_sort}
                                                                        handleClick={() => sortColumn(key, itemIdx)}
                                                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                                    />
                                                                ))
                                                            }

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            item.details.map((detail, detailIdx) => (
                                                                <tr className={detail.category === 'return' && 'row-red'}>
                                                                    <td className="td-index">{detailIdx + 1}. </td>
                                                                    <td className="td-width-as-content">{dateDMY(detail.date)}</td>
                                                                    <td className="td-width-as-content">{detail.branch}</td>
                                                                    <td className="td-width-as-content">{detail.code}</td>
                                                                    <td className="td-width-as-content">{detail.type}</td>
                                                                    <td className="td-width-as-content">{detail.category}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.sub_total)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.total_discount)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.grand_total)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.hpp)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.additional_cost)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.net_income)}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </SimpleBarHOC>
                                        </div>
                                    </>
                                ))
                            }
                        </div>
                    </>
                )

            }
        </>
    )
}
