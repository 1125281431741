import React, { useState, memo, useEffect } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { money } from '@/Helper'
import DetailProduct from './DetailProduct';


const DetailRows = ({ data, no, variants, showDetail: defaultShowDetail }) => {

    const [d, setD] = useState(data)
    const [showDetail, setShowDetail] = useState(defaultShowDetail)
    const handleShowDetail = () => {
        setShowDetail(!showDetail)
    }

    useEffect(() => {
        setShowDetail(defaultShowDetail)
    }, [defaultShowDetail])

    useEffect(() => {
        setD(data)
        setShowDetail(false)
    }, [data, variants])

    return (
        <>
            <tr>
                <td>{no}</td>
                <td className="cell-width-as-content cell-highlight">
                    <span className="trigger-show-detail" onClick={handleShowDetail}>
                        {d.code} <KeyboardArrowDownIcon className="info-icon" />
                    </span>
                </td>
                <td className="cell-capitalize td-width-as-content">{d.product_type.replace('_', ' ') || 'N/a'}</td>
                <td className="td-width-as-content">{d.name || 'N/a'}</td>
                <td>{d.total_product}</td>
                {
                    variants.map((lv, lvIdx) => {
                        let qty = 0;
                        d.variants.forEach(variant => {
                            qty += variant?.types?.[lvIdx]?.stock ?? 0
                        })
                        return <td key={`parentvariant${lvIdx}`}>{qty}</td>
                    })
                }
                <td>{d.total_stock}</td>
                <td>{d.total_reject}</td>
                <td>Pcs</td>
                <td className="cell-currency cell-tr">{money(d.total_value)}</td>
            </tr>

            <DetailProduct
                indexParent={no}
                show={showDetail}
                variants={variants}
                products={d.products}
            />

        </>
    )
}



export default memo(DetailRows)