import React, { useEffect, useState } from 'react'
import { resetStatusPayment, addPayment, updatePayment, getPaymentById } from 'Reduxes/consignment/payment/paymentActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop, money, globalMessage } from '@/Helper'
import SelectAsync from 'Elements/SelectAsync';
import Button from '@material-ui/core/Button';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import SelectData from 'Elements/SelectData'
import FormButton from 'Elements/FormButton'
import DeleteIcon from '@material-ui/icons/Delete';
import Grid from '@material-ui/core/Grid';

function FormElement(props) {
    const { type, showTable, rowId } = props;
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(true);
    const { error, idEdit, data, loading } = useSelector(state => state.paymentReducer);
    const { data: contacts } = useSelector(state => state.contactReducer);
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState(0)
    const [invoice, setInvoice] = useState([])
    const [paymentAmountSubmit, setPaymentAmountSubmit] = useState(0)
    const paymentMethod = [
        { label: 'Cash', value: 'cash' },
        { label: 'Transfer', value: 'transfer' },
    ]
    const [initialValues, setInitialValues] = useState({
        date: new Date().toISOString().split('T')[0],
        note: '',
        contact_id: '',
        payment_method: '',
        payment_items: []
    })

    const total_arr = []
    let submitValue = paymentAmountSubmit

    invoice.map((singleData) => {
        const { discount, sub_total, rest_of_amount, total } = singleData
        console.log(singleData)
        total_arr.push(rest_of_amount || total)
    })

    const total_sum = total_arr.reduce((a, b) => a + b, 0)

    console.log(1, total_sum)
    console.log(2, submitValue)
    console.log(3, total_arr)

    const handleChangeContact = (data) => {
        setContact(data.value)
    }

    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const paymentItems = invoice.map(data => {
                return {
                    invoice_id: data.invoice_id,
                    payment_amount: data.total_payment
                }
            })
            const cleanVal = {
                contact_id: contact.id || contact,
                date: values.date,
                note: values.note,
                payment_method: values.payment_method,
                payment_items: paymentItems
            }

            let result = type === 'new' ?
                await dispatch(addPayment(cleanVal))
                : await dispatch(updatePayment(idEdit, cleanVal))
            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }

    useEffect(() => {
        const getEditData = async () => {

            const dataPayment = await dispatch(getPaymentById(rowId))

            if (dataPayment.status) {
                const { date, note, contact_id, contact_name, payment_method, payment_items } = dataPayment.data;

                setInitialValues({
                    date,
                    note,
                    contact_id,
                    payment_method,
                })
                setContact({ id: contact_id, name: contact_name })
                setInvoice(
                    payment_items.map(item => {
                        const { discount, due_date, id, invoice_code, invoice_id, payment_amount, payment_id, sales_period, sub_total, total } = item
                        console.log(item)
                        return ({
                            discount,
                            due_date,
                            id,
                            code: invoice_code,
                            invoice_id,
                            total_payment: payment_amount,
                            payment_id,
                            period: sales_period,
                            sub_total,
                            total,
                            margin: total - payment_amount,
                        })
                    })
                )
            }
        }
        getEditData()
    }, [])

    useEffect(() => {
        setAddress(contacts.length ? contacts[0].address : '')
    }, [contacts])

    const contactProps = {
        handleChange: (val) => {
            const addAddress = val.row.address
            setAddress(addAddress)
            setContact(val.value)
        }
    }

    const handleInvoiceNumber = data => {
        const { row } = data
        const isDuplicate = invoice.find(inv => inv.id === row.id)
        if (isDuplicate) {
            globalMessage('You cannot enter the same Invoice Number', 'warning')
            return false
        }
        setInvoice([...invoice, { ...row, invoice_id: row.id, total_payment: 0, margin: 0, payment_amount: 0 }])
    }


    const handleDeleteInvoiceRow = (index) => {
        const deletedInvoice = [...invoice]
        deletedInvoice.splice(index, 1)
        setInvoice(deletedInvoice)
    }

    const paymentAmountChange = (e) => {
        let { value } = e.target
        if (value === 0 || value === '0' || value.length < 1 || value === '') {
            value = 0;
        }
        if (isNaN(value)) {
            return;
        }
        setPaymentAmountSubmit(+value)
    }

    const paymentAmountHandle = () => {
        const totalInvoiceItem = invoice.length;
        const payment = invoice.map((singleData, index) => {
            let isLastInvoice = totalInvoiceItem === index + 1;
            if (submitValue > (singleData.rest_of_amount || singleData.total)) {
                singleData.total_payment = (singleData.rest_of_amount || singleData.total)
                singleData.margin = isLastInvoice ? submitValue - (singleData.rest_of_amount || singleData.total) : 0
                singleData.isNegative = false
            } else {
                singleData.total_payment = submitValue
                singleData.margin = (singleData.rest_of_amount || singleData.total) - submitValue
                singleData.isNegative = true
            }

            if (singleData.total_payment < 0) {
                singleData.total_payment = 0
                singleData.margin = (singleData.rest_of_amount || singleData.total)
            }
            submitValue -= (singleData.rest_of_amount || singleData.total)
            return singleData
        })
        setInvoice(payment)
    }

    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Payment" : "Update data Payment"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data payment`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusPayment())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {

                                    const { isSubmitting } = formik;

                                    return (
                                        <>
                                            <Form className="form-layout-1">
                                                <FieldsContainer title="Payment Information">
                                                    <SelectAsync name="contact_id" {...(contact ? { value: { label: contact.name, value: contact.id } } : {})} showAddress options="contact" label="Contact" handleChange={handleChangeContact} />
                                                    {/* <SelectAsync {...contactProps} showAddress options="contact" handleChange={handleChangeContact} label="Contact" /> */}
                                                    <SelectData name="payment_method" options={paymentMethod} label="Payment Method" />
                                                    <SelectAsync name="invoice_id" options="invoice_to_paid" label="Invoice No." handleChange={handleInvoiceNumber} type="invoice_number" id={contact} />
                                                    <Datepicker name="date" label="Date" />
                                                    <Textarea name="note" label="Notes" col="6" />
                                                </FieldsContainer>
                                                {
                                                    invoice.length > 0 ?
                                                        <>
                                                            <div className="table-product-no-max-height-and-overflow" style={{ overflow: "hidden" }}>
                                                                <table className="table table-detail style-2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>INV No.</th>
                                                                            <th>Due Date</th>
                                                                            <th>Selling Periode</th>
                                                                            {/* <th>Subtotal</th>
                                                                            <th>Discount</th> */}
                                                                            <th>Total</th>
                                                                            <th>Total Payment</th>
                                                                            <th>Difference</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            invoice.map((invoiceData, index) => {
                                                                                const { code, due_date, period, total_payment, margin, discount, total, rest_of_amount, sub_total, isNegative } = invoiceData

                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td><DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteInvoiceRow(index)} /></td>
                                                                                        <td>{code}</td>
                                                                                        <td>{due_date}</td>
                                                                                        <td>{period}</td>
                                                                                        {/* <td>IDR {money(sub_total)}</td>
                                                                                        <td>IDR {money(discount)}</td> */}
                                                                                        <td>IDR {rest_of_amount ? money(rest_of_amount) : money(total)}</td>
                                                                                        <td>IDR {money(total_payment)}</td>
                                                                                        {/* <td className={isNegative ? 'text-danger' : 'text-danger'}>IDR {(isNegative ? '-' : '') + money(margin)}</td> */}
                                                                                        <td className={isNegative ? 'text-danger' : 'text-danger'}>IDR {money(margin)}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>

                                                                <Grid
                                                                    container
                                                                    direction="row"
                                                                    justify="flex-end"
                                                                    alignItems="flex-start"
                                                                >
                                                                    <Button
                                                                        style={{ marginRight: '20px', marginTop: '30px' }}
                                                                        variant="contained"
                                                                        className="button-style-2"
                                                                        color="secondary"
                                                                        // type="button"
                                                                        onClick={paymentAmountHandle}
                                                                    >Submit Payment Amount</Button>
                                                                    <div className="total-container">
                                                                        {/* <p>Sub Total</p>
                                                                        <p>IDR {money(sub_total_sum)}</p>
                                                                        <p>Discount</p>
                                                                        <p>IDR {money(total_discount_sum)}</p> */}

                                                                        <p>Payment Amount</p>
                                                                        <input name="payment_amount" label="Payment Amount" value={paymentAmountSubmit} onChange={paymentAmountChange} className="input-number" type="text" />
                                                                        <p>Total</p>
                                                                        <p>IDR {money(total_sum)}</p>
                                                                    </div>
                                                                </Grid>
                                                            </div>
                                                        </> : <></>
                                                }
                                                {/* {
                                                    editTable && invoice.length > 0 ?
                                                        <>
                                                            <div className="table-product-no-max-height-and-overflow" style={{ overflow: "hidden" }}>
                                                                <table className="table table-detail style-2">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>INV No.</th>
                                                                            <th>Due Date</th>
                                                                            <th>Selling Periode</th>
                                                                            <th>Subtotal</th>
                                                                            <th>Discount</th>
                                                                            <th>Total</th>
                                                                            <th>Total Payment</th>
                                                                            <th>Margin</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            invoice.map((invoiceData, index) => {
                                                                                const { invoice_code, due_date, sales_period, payment_amount, margin, discount, total, sub_total } = invoiceData
                                                                                console.log('ini', invoiceData)
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td><DeleteIcon style={{ cursor: 'pointer' }} onClick={() => handleDeleteInvoiceRow(index)} /></td>
                                                                                        <td>{invoice_code}</td>
                                                                                        <td>{due_date}</td>
                                                                                        <td>{sales_period}</td>
                                                                                        <td>{sub_total}</td>
                                                                                        <td>{discount}</td>
                                                                                        <td>{total}</td>
                                                                                        <td>{payment_amount}</td>
                                                                                        <td>{margin}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <Grid container spacing={3}>
                                                                    <Grid item xs={8} />
                                                                    <Grid item xs={2}>
                                                                        <div className="total-container">
                                                                            <p>Sub Total</p>
                                                                            <p>{sub_total_sum}</p>
                                                                            <p>Discount</p>
                                                                            <p>{total_discount_sum}</p>
                                                                            <p>Total</p>
                                                                            <p>{total_sum}</p>
                                                                        </div>
                                                                        <input name="payment_amount" label="Payment Amount" value={paymentAmountSubmit} onChange={paymentAmountChange} className="input-number" style={{ marginBottom: '15px' }} />
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Submit Payment Amount"
                                                                            onClick={paymentAmountHandle}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        </>
                                                        : <h1>No Invoice Selected</h1>
                                                } */}
                                                {/* KALO SUDAH DI SUBMIT, STATUS JADI DRAFT DAN MUNCUL INFO DIBAWAH INI */}
                                                {/* <Grid container spacing={3} className="detail-page">
                                                    <Grid item xs={3}>
                                                        <p className="detail-note">Printed by: User 1</p>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p className="detail-note">Created by: User 1</p>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p className="detail-note">Last Edited by: User 1</p>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <p className="detail-note">Confirmed by: User 1</p>
                                                    </Grid>
                                                </Grid> */}
                                                <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                            </Form>
                                        </>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper>
    )
}

export default FormElement