import React, { useEffect, useState } from 'react'
import { resetStatusStockopname, addStockopname, updateStockopname, loadProductByCat, getStockOpnameById } from 'Reduxes/inventory/stockopname/stockopnameActions'
import { filterProduct } from 'Reduxes/inventory/product/productActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop, globalMessage, getLocalStorage, getLocalStorageAuth } from '@/Helper'
import SelectAsync from 'Elements/SelectAsync';
import Input from 'Elements/Input';
import Select from 'Elements/select/Select';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import SelectData from 'Elements/SelectData'
import FormButton from 'Elements/FormButton'
import LoadingInline from 'Elements/LoadingInline'
import { serverValue } from 'Configs/Form'
import TableFormProduct from './TableFormProduct'
import TableFormCategory from './TableFormCategory'

function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const { type, showTable } = props;
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);
    const [basedOnValue, setBasedOnValue] = useState('all_product');
    const [stockLocation, setStockLocation] = useState('');
    const [statusProduct, setStatusProduct] = useState('')
    const [productType, setProductType] = useState('');
    const [categoryRow, setCategoryRow] = useState([]);  // untuk table category
    const [products, setProducts] = useState([]);
    const { error, idEdit } = useSelector(state => state.stockopnameReducer);
    const { loading: loadingProducts } = useSelector(state => state.productReducer);
    const currentBranch = getLocalStorage().branchLogin.id
    const [branchId, setBranchId] = useState(currentBranch)
    const [selectedBrand, setSelectedBrand] = useState(null) // nyimpan data rawOpt dari brand yang dipilih, ini berguna ketika user ubah kategori setelah memilih brand name
    const [initialValues, setInitialValues] = useState({
        "branch_id": currentBranch,
        "date": new Date().toISOString().split('T')[0],
        "based_on": '',
        "reference": '',
        "note": '',
        "location": '',
        "stock_opname_item": [],
        "forced": true,
        "status_product": '',
        "product_type": ''
    })

    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            delete values.scanner
            delete values.location_label
            delete values.based_on_label
            if (values.stock_opname_item.length < 1) {
                globalMessage(`You must at least add one product`, 'warning')
                return;
            }
            values.stock_opname_item = values.stock_opname_item.map(x => {
                if (!x.qty_stock) {
                    x.qty_stock = 0;
                }
                if (!x.qty_reject_physic) {
                    x.qty_reject_physic = 0;
                }
                if (!x.qty_stock_physic) {
                    x.qty_stock_physic = 0;
                }
                if (!x.qty_reject) {
                    x.qty_reject = 0;
                }

                return x;
            })

            delete values?.brand_id

            if (selectedBrand) {
                Object.assign(values, { brand: selectedBrand })
            }

            let cleanVal = serverValue(values);
            let result = type === 'new' ?
                await dispatch(addStockopname(cleanVal))
                : await dispatch(updateStockopname(idEdit, cleanVal))
            if (result) {
                resetForm();
                showTable();
            }
            scrollToTop()
            setSubmitting(false)
        }
    }
    // async product change handler
    const prodSelectHandler = ({ row }) => {
        if (row) {
            const hasAdded = products.find(p => p.code === row?.code)
            if (hasAdded) {
                globalMessage(`Product has been added`, 'warning')
                return;
            }
            setProducts([...products, row])
        }
    }
    // async category change handler
    const catSelectHandler = ({ row }) => {
        setCategoryRow([...categoryRow, row])
    }
    const searchProduct = async (location, branch_id, status_product, brand_id, product_type) => {
        const reqProducts = await dispatch(
            filterProduct({
                location,
                flat: true,
                branch_id,
                status_product,
                ...(brand_id ? { by_brand: [brand_id] } : null),
                ...(product_type ? { product_type } : null)
            })
        )

        if (reqProducts.status) {
            setProducts(reqProducts.data)
        } else {
            globalMessage(`Error get products with with param : ${location}`, 'warning')
        }
    }
    // delete category row
    const removeCategory = idx => {
        const filtered = categoryRow.filter((_, catIdx) => catIdx !== idx);
        setCategoryRow(filtered);
    }

    // generate product by category
    const generateProductByCat = async () => {
        const resp = await dispatch(loadProductByCat(
            {
                location: stockLocation,
                categories: categoryRow.map(a => a.id),
                branch_id: branchId,
                status_product: statusProduct,
                ...(selectedBrand ? { by_brand: [selectedBrand.id] } : null),
                ...(productType ? { product_type: productType} : null)
            }
        ))
        if (resp.status) {
            if (!resp.data.length) {
                globalMessage('No product available', 'warning');
            }
            setProducts(resp.data);
            setCategoryRow([])
        } else {
            globalMessage('fail to load product by category', 'warning')
        }
    }

    const branchChangeHandler = async data => {
        const { formik, selected } = data
        if (formik.values.location === '') {
            setBranchId(selected.value)
            setProducts([])
        } else {
            setBranchId(selected.value)
            searchProduct(formik.values.location, selected.value, formik.values.status_product, selectedBrand?.id, productType)
        }
    }

    const basedOnChangeHandler = async (val, formik) => {
        setBasedOnValue(val);
        setProducts([])
        if (val === 'all_product') {
            if (stockLocation === '' && statusProduct === '') {
                setProducts([])
            } else {
                searchProduct(stockLocation, formik.values.branch_id, statusProduct, selectedBrand?.id, productType)
            }
        }
    }

    const changeStockLocation = async (location, formik) => {
        setStockLocation(location)
        setProducts([])
        if (basedOnValue === 'all_product') {
            if (location === '') {
                setProducts([])
            } else {
                // searchProduct(location, formik.values.branch_id, statusProduct, selectedBrand?.id)
            }
        }
    }

    const changeStatusProduct = async (statusProduct, formik) => {
        setStatusProduct(statusProduct)
        setProducts([])
        if (basedOnValue === 'all_product') {
            if (statusProduct === '') {
                setProducts([])
            } else {
                searchProduct(stockLocation, formik.values.branch_id, statusProduct, selectedBrand?.id, productType)
            }
        }
    }

    const changeProductType = async (productType, formik) => {
        setProductType(productType)
        setProducts([])
        if (basedOnValue === 'all_product') {
            if (statusProduct === '') {
                setProducts([])
            } else {
                searchProduct(stockLocation, formik.values.branch_id, statusProduct, selectedBrand?.id, productType)
            }
        }
    }

    const changeBrand = ({ selected, rawOpt, formik }) => {
        setSelectedBrand(rawOpt)
        setProducts([])
        if (basedOnValue === 'all_product') {
            if (statusProduct === '') {
                setProducts([])
            } else {
                searchProduct(stockLocation, formik.values.branch_id, statusProduct, rawOpt?.id, productType)
            }
        }
    }

    useEffect(() => {
        const fetchDataEdit = async () => {
            const resp = await dispatch(getStockOpnameById(idEdit))
            const {
                branch_id, date, reference, note, based_on, location, stock_opname_item, stock_opname_category, status_product, brand,
            } = resp.data;
            setInitialValues({
                branch_id,
                date,
                based_on,
                reference,
                forced: true,
                note,
                location,
                status_product,
                stock_opname_item: [],
                brand_id: brand?.id,
                product_type: resp?.data?.product_type,
                ...(stock_opname_category ? { stock_opname_category: stock_opname_category } : {})
            })

            setStockLocation(location);
            setProducts(stock_opname_item);
            setBasedOnValue(based_on);
            setStatusProduct(status_product);
            setSelectedBrand(brand);
            setProductType(resp?.data?.product_type || '')
            if (stock_opname_category?.length > 0) {
                setCategoryRow((prevValues) => [
                    ...prevValues,
                    ...stock_opname_category.map((cat) => {
                        return {
                            ...cat,
                            id: cat.category_id,
                            category_id: cat.category_id,
                            name: cat.category_name
                        }
                    })
                ])
            }
            setIsReady(true);
        }
        if (type === 'edit') {
            fetchDataEdit();
            setIsReady(true)
        } else {
            setIsReady(true)
        }
    }, [])


    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Stock Opname" : "Update data Stock Opname"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data stock opname`}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusStockopname())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {
                                    const { isSubmitting, initialValues } = formik;
                                    return (
                                        <Form className="form-layout-1">
                                            <FieldsContainer title="Stockopname Information">
                                                <Select
                                                    {...((getLocalStorageAuth().user_category_id === 1 || getLocalStorageAuth().user_category_id === 2) ? {} : { isDisabled: true })}
                                                    name="branch_id"
                                                    options='branch'
                                                    label="Branch"
                                                    getData={branchChangeHandler}
                                                />
                                                <Datepicker name="date" label="Date" />
                                                <Input name="reference" label="Reference" />
                                                <Textarea name="note" label="Notes" col="6" />
                                            </FieldsContainer>
                                            <div className="fields-group-container stock_information">
                                                <p className="field-group-title">
                                                    Based on
                                                    <span>Search product by product code  or product name</span>
                                                </p>
                                                <SelectData name="based_on" label="Based on"
                                                    handleChange={basedOnChangeHandler}
                                                    value={initialValues.based_on}
                                                    options={[
                                                        { label: 'All Products', value: 'all_product' },
                                                        { label: 'Product', value: 'product' },
                                                        { label: 'Category', value: 'category' },
                                                    ]}
                                                />
                                                <SelectData
                                                    name="location"
                                                    placeholder="Select option"
                                                    label="Stock location"
                                                    options={[
                                                        { label: 'All location', value: 'all_location' },
                                                        { label: 'Stock', value: 'stock' },
                                                        { label: 'Reject', value: 'reject' },
                                                    ]}
                                                    value={initialValues.location}
                                                    handleChange={changeStockLocation}
                                                />
                                                <SelectData
                                                    name="status_product"
                                                    placeholder="Select option"
                                                    label="Product Status"
                                                    options={[
                                                        { label: 'Active', value: 'active' },
                                                        { label: 'Inactive', value: 'inactive' },
                                                    ]}
                                                    value={initialValues.status_product}
                                                    handleChange={changeStatusProduct}
                                                />
                                                <Select name="brand_id" getData={changeBrand} options="brand" label="Brand Name" value={selectedBrand} />
                                                <SelectData
                                                    name="product_type"
                                                    placeholder="Select option"
                                                    label="Product Type"
                                                    options={[
                                                        { value: 'consignment', label: 'Consignment' },
                                                        { value: 'product_sales', label: 'Product Sales' },
                                                        { value: 'product_general', label: 'Product General' },
                                                        { value: 'discontinue', label: 'Discontinue' }
                                                    ]}
                                                    value={initialValues.product_type}
                                                    handleChange={changeProductType}
                                                />
                                                {
                                                    (basedOnValue === 'product' && stockLocation !== '' && statusProduct !== '') &&
                                                    <SelectAsync
                                                        type="productName"
                                                        param={{ 
                                                            type: stockLocation, 
                                                            flat: true, 
                                                            status_product: statusProduct, 
                                                            ...(selectedBrand ? { by_brand: [selectedBrand.id] } : null),
                                                            ...(productType ? { product_type: productType } : null)
                                                        }}
                                                        extraParams={{ branch_id: branchId }}
                                                        name="_product_id"
                                                        cache={false}
                                                        value=""
                                                        handleChange={prodSelectHandler}
                                                        label="Product name"
                                                    />
                                                }
                                                {
                                                    (basedOnValue === 'category' && stockLocation !== '' && statusProduct !== '') &&
                                                    <SelectAsync
                                                        type="category"
                                                        param={{ type: stockLocation }}
                                                        extraParams={{ branch_id: branchId }}
                                                        resetOnSelect
                                                        name="_category_id"
                                                        cache={false}
                                                        value=""
                                                        handleChange={catSelectHandler}
                                                        label="Category"
                                                    />
                                                }
                                            </div>
                                            {categoryRow.length > 0 && <TableFormCategory generateProductByCat={generateProductByCat} removeCategory={removeCategory} rows={categoryRow} />}
                                            {loadingProducts && stockLocation !== '' && <LoadingInline message="Loading data products" />}
                                            {type === 'edit' && stockLocation === '' && <LoadingInline message="Loading data products" />}
                                            {
                                                (products.length > 0 && stockLocation !== '') &&
                                                <TableFormProduct stockLocation={stockLocation} rows={products} setRows={setProducts} dataErrStatus={setDataErr} />
                                            }
                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </>
                    )
                        : <LoadingContent />
                }
            </>
        </ContentWrapper>
    )
}

export default FormElement