import { makeGetParam, handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
export const CATEGORY_SET = 'CATEGORY_SET'
export const CATEGORY_ERROR = 'CATEGORY_ERROR'
export const CATEGORY_ADD = 'CATEGORY_ADD'
export const CATEGORY_SHOW = 'CATEGORY_SHOW'
export const CATEGORY_EDIT = 'CATEGORY_EDIT'
export const CATEGORY_UPDATE = 'CATEGORY_UPDATE'
export const CATEGORY_DELETE = 'CATEGORY_DELETE'
export const CATEGORY_LOADING = 'CATEGORY_LOADING'
export const CATEGORY_RESET = 'CATEGORY_RESET'
export const CATEGORY_STOP_LOADING = 'CATEGORY_STOP_LOADING'
const type = "CATEGORY"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const filterCategory = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/category${makeGetParam(args)}`),
        next: () => dispatch({ type: CATEGORY_STOP_LOADING })
    })
}
export const loadCategory = (all) => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/category${all ? '?all=true' : ''}`),
        next: (payload) => dispatch({ type: CATEGORY_SET, payload })
    })
}
export const addCategory = data => async (dispatch) => {
    console.log('add', data)
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/category`, data),
        next: (payload) => dispatch({ type: CATEGORY_ADD, payload })
    })
}
export const updateCategory = (idEdit, data) => async (dispatch) => {

    delete data.created_at
    delete data.deleted_at
    delete data.updated_at
    delete data.id
    delete data.parent_name
    delete data.variant_name

    console.log(data, 'updated')

    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/category/${idEdit}`, data),
        next: (payload) => dispatch({ type: CATEGORY_UPDATE, payload })
    })
}
export const deleteCategory = id => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/category`, id),
        next: () => dispatch({ type: CATEGORY_DELETE, payload: id })
    })
}
export const showCategory = idx => {
    return {
        type: CATEGORY_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: CATEGORY_EDIT,
        payload: idEdit,
    }
}
export const resetStatusCategory = () => {
    return {
        type: CATEGORY_RESET
    }
}