import React from 'react'
import { money } from '@/Helper'
import NoReport from 'Elements/NoReport'

export default function General({ data, group_by }) {

    const { data: dataWeek } = {
        "status": "success",
        "data": {
            "total_stock": 160,
            "total_value": 15700000,
            "products": [
                {
                    "id": 1,
                    "code": "BR20B-TS001CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Baju Pramuka",
                    "brand": "EIGER",
                    "stock": 10,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 20000,
                    "total_value": 200000,
                    "variants": [
                        {
                            "date": 58,
                            "detail_date": "2020-12-28 11:35:09",
                            "stock": 22,
                            "reject": 22,
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 10,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                },
                {
                    "id": 100002,
                    "code": "BR20B-TS002CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Helmet",
                    "brand": "EIGER",
                    "stock": 50,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 50000,
                    "total_value": 2700000,
                    "variants": [
                        {
                            "date": 53,
                            "detail_date": "2020-12-28 11:39:19",
                            "stock": 22,
                            "reject": 22,
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 50,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                },
                {
                    "id": 100003,
                    "code": "BR20B-TS003CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Cardinal",
                    "brand": "EIGER",
                    "stock": 50,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 250000,
                    "total_value": 15200000,
                    "variants": [
                        {
                            "date": 52,
                            "detail_date": "2020-12-28 11:42:04",
                            "stock": 22,
                            "reject": 22,
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                },
                {
                    "id": 100004,
                    "code": "BR20B-TS004CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Baju Kaws",
                    "brand": "EIGER",
                    "stock": 50,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 10000,
                    "total_value": 15700000,
                    "variants": [
                        {
                            "date": 53,
                            "detail_date": "2020-12-28 11:44:08",
                            "stock": 22,
                            "reject": 22,
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                }
            ]
        },
        "errors": []
    }

    const { data: dataMonth } = {
        "status": "success",
        "data": {
            "total_stock": 160,
            "total_value": 15700000,
            "products": [
                {
                    "id": 1,
                    "code": "BR20B-TS001CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Baju Pramuka",
                    "brand": "EIGER",
                    "stock": 10,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 20000,
                    "total_value": 200000,
                    "variants": [
                        {
                            "date": "2020-12",
                            "detail_date": "2020-12-28 11:35:09",
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 10,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                },
                {
                    "id": 100002,
                    "code": "BR20B-TS002CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Helmet",
                    "brand": "EIGER",
                    "stock": 50,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 50000,
                    "total_value": 2700000,
                    "variants": [
                        {
                            "date": "2020-12",
                            "detail_date": "2020-12-28 11:39:19",
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 50,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                },
                {
                    "id": 100003,
                    "code": "BR20B-TS003CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Cardinal",
                    "brand": "EIGER",
                    "stock": 50,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 250000,
                    "total_value": 15200000,
                    "variants": [
                        {
                            "date": "2020-12",
                            "detail_date": "2020-12-28 11:42:04",
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                },
                {
                    "id": 100004,
                    "code": "BR20B-TS004CO",
                    "category": "T-Shirt",
                    "type": "product_sales",
                    "name": "Baju Kaws",
                    "brand": "EIGER",
                    "stock": 50,
                    "reject": 0,
                    "uom_name": "Piece",
                    "sales_price": 10000,
                    "total_value": 15700000,
                    "variants": [
                        {
                            "date": "2020-12",
                            "detail_date": "2020-12-28 11:44:08",
                            "variants": [
                                {
                                    "id": 1,
                                    "name": "T-shirt",
                                    "types": [
                                        {
                                            "label": "L",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "M",
                                            "stock": 0,
                                            "reject": 0
                                        },
                                        {
                                            "label": "S",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                },
                                {
                                    "id": 2,
                                    "name": "shoes",
                                    "types": [
                                        {
                                            "label": "40",
                                            "stock": 0,
                                            "reject": 0
                                        }
                                    ]
                                }
                            ]
                        }
                    ],
                    "details": []
                }
            ]
        },
        "errors": []
    }

    // console.log(111111111, group_by)



    let allWeeks = [];
    let variants = null;
    let longestVariant = [];

    if (data.products.length) {

        /* week */
        data.products.forEach(product => {
            product.variants.forEach(variant => {
                allWeeks = [...new Set([...allWeeks, variant.date])]
            })
        });
        allWeeks = allWeeks.sort((a, b) => a - b);

        /* variant */
        variants = data.products[0].variants[0].variants;

        variants.forEach(v => {
            if (v.types.length > longestVariant.length)
                longestVariant = v.types
        })
    }


    /*   console.clear()
      console.log('data', data)
      console.log('allWeeks', allWeeks)
      console.log('variants', variants)
      console.log('longestVariant', longestVariant) */


    return !data.products.length ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '20px' }}>Stock Summary by Product Report <span>Showing  report from all branches</span></p>
            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Stock Value</span>
                        <span>IDR {money(data.total_value)}</span>
                    </p>
                </div>
            </div>
            <div className="scroll-x">
                <table className="table-report inventory-stock">
                    <thead>

                        <tr>
                            <th rowSpan={variants.length + 2}>No.</th>
                            <th rowSpan={variants.length + 2}>Code</th>
                            <th rowSpan={variants.length + 2}>Category</th>
                            <th rowSpan={variants.length + 2}>Type</th>
                            <th rowSpan={variants.length + 2}>Name</th>
                            <th rowSpan={variants.length + 2}>brand</th>

                            {
                                allWeeks.map((week, idx) => <th className="cell-tc" colSpan={longestVariant.length + 2}>{group_by === 'week' ? 'Week' : 'Month'} ({week})</th>)
                            }

                            <th rowSpan={variants.length + 2}>UoM</th>
                            <th className="cell-tr" rowSpan={variants.length + 2}>Sales Price</th>
                            <th className="cell-tr" rowSpan={variants.length + 2}>Total Value</th>
                        </tr>

                        <tr>
                            {
                                allWeeks.map(x => {
                                    return longestVariant.map((lv, lvIdx) => (
                                        <>
                                            {
                                                lvIdx < 1 &&
                                                <th className="cell-tc" colSpan={longestVariant.length}>Variant</th>
                                            }
                                            {
                                                longestVariant.length - 1 === lvIdx && (
                                                    <>
                                                        <th>Stock</th>
                                                        <th>Reject</th>
                                                    </>
                                                )
                                            }
                                        </>
                                    ))
                                })
                            }
                        </tr>

                        {
                            variants.map(variant => {
                                return (
                                    <tr>
                                        {
                                            allWeeks.map(aw => {
                                                return longestVariant.map((lv, lvIdx) => {
                                                    return (
                                                        <>
                                                            <th>{variant?.types?.[lvIdx]?.label ?? '-'}</th>
                                                            {
                                                                lvIdx === longestVariant.length - 1 && (
                                                                    <>
                                                                        <th></th>
                                                                        <th></th>
                                                                    </>
                                                                )
                                                            }
                                                        </>

                                                    )
                                                })
                                            })
                                        }

                                    </tr>
                                )
                            })
                        }



                    </thead>
                    <tbody>
                        {
                            data.products.map((product, productIdx) => (
                                <tr>
                                    <td>{productIdx + 1}.</td>
                                    <td className="cell-width-as-content">{product.code}</td>
                                    <td className="cell-width-as-content">{product.category}</td>
                                    <td className="cell-width-as-content">{product.type}</td>
                                    <td className="cell-width-as-content">{product.name}</td>
                                    <td className="cell-width-as-content">{product.brand}</td>
                                    {
                                        allWeeks.map((aw, awIdx) => {


                                            return longestVariant.map((lv, lvIdx) => {

                                                let variants = data?.products?.[productIdx]?.variants ?? null;
                                                let value = 0;
                                                let reject = 0;
                                                let stock = 0;

                                                if (variants) {
                                                    let isFound = variants.find(x => x.date === aw)

                                                    if (isFound) {
                                                        // console.log(isFound, 11)

                                                        reject = isFound.reject;
                                                        stock = isFound.stock;

                                                        isFound.variants.forEach(variant => {
                                                            value += variant?.types?.[lvIdx]?.stock ?? 0;
                                                        })
                                                    }
                                                }

                                                // console.log(value, aw)

                                                return (
                                                    <>
                                                        <td>{value || '-'}</td>
                                                        {
                                                            lvIdx === longestVariant.length - 1 && (
                                                                <>
                                                                    <td>{stock || '-'}</td>
                                                                    <td>{reject || '-'}</td>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            })
                                        })
                                    }
                                    <td>{product.uom_name}</td>
                                    <td className="cell-currency cell-tr">IDR {money(product.sales_price)}</td>
                                    <td className="cell-currency cell-tr">IDR {money(product.total_value)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
