import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addPromotionConfiguration, updatePromotionConfiguration, resetStatusPromotionConfiguration } from 'Reduxes/pos/promotion_configuration/promotionConfigurationActions'
import { loadBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import { loadBranch } from 'Reduxes/configuration/branch/branchActions'
import { scrollToTop, globalMessage, getLocalStorage, getLocalStorageAuth } from '@/Helper'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import Datepicker from 'Elements/Datepicker';
import Input from 'Elements/Input';
import SelectData from 'Elements/SelectData'
import SelectAsync from 'Elements/SelectAsync';
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete'
import Button from 'Elements/Button';
import InputNum from 'Elements/inputnum/InputNum'
import InputNumber from '../../../comps/elements/inputnumber/InputNumber'
import CustomSelectAsync from 'Elements/CustomSelectAsync'
import Multiselect from 'Elements/multiselect/Multiselect';
import { default as Select } from 'Elements/select/Select'
import SelectFilter from 'Elements/SelectFilter'




function FormElement(props) {
    const { type, showTable } = props
    const dispatch = useDispatch()
    const [isReady, setIsReady] = useState(true);
    const [typeValue, setTypeValue] = useState('general');
    const [tableBrandRow, setTableBrandRow] = useState([]);
    const [tableProductRow, setTableProductRow] = useState([]);
    const [tableCategoryRow, setTableCategoryRow] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState([]);
    const { error, idEdit, data } = useSelector(state => state.promotionConfigurationReducer);
    const [productSearchType, setProductSearchType] = useState('productName');
    const [inputError, setInputError] = useState(null)
    const { data: contacts } = useSelector(state => state.contactReducer);
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState(null)
    const [user, setUser] = useState([])
    const [brand, setBrand] = useState([])
    const [optBrand, setOptBrand] = useState([{ label: 'Loading options', value: null }])
    const [showBrandTable, setShowBrandTable] = useState(false)
    const [showProductTable, setShowProductTable] = useState(false)
    const [showCategoryTable, setShowCategoryTable] = useState(false)
    const [product, setProduct] = useState([])
    const [category, setCategory] = useState([])
    const [item, setItem] = useState([])
    const [editForm, setEditForm] = useState([])
    const [branches, setBranches] = useState([])
    const [optBranches, setOptBranches] = useState([{ label: 'Loading options', value: null }])
    const [returnable, setReturnable] = useState(false);
    const isSuperAdmin =
        getLocalStorageAuth()?.user_category_id === 1 ||
        getLocalStorageAuth()?.user_category_id === 2;

    /** Giveaway State. */
    const [giveawayForm, setGiveawayForm] = useState([{
        minimum_purchase: 0,
        products: []
    }])

    const [giveawayProducts, setGiveawayProducts] = useState([[]])
    /** End of Giveaway State. */
    const [inputBrandList, setInputBrandList] = useState([
        {
            brand: null
        }
    ])
    const [inputProductList, setInputProductList] = useState([
        {
            product: ""
        }
    ])
    const [inputCategoryList, setInputCategoryList] = useState([
        {
            category: ""
        }
    ])
    const [promotionDate, setPromotionDate] = useState("");
    const [initialValues, setInitialValues] = useState({
        type: "",
        code: "",
        name: "",
        date_start: "blank",
        date_end: "blank",
        user_target: '',
        brand_target: '',
        item_target: '',
        branches: [],
        returnable: null
    })
    const [showForm, setShowForm] = useState(true);
    const updateShowForm = async () => {
        await setShowForm(false);
        setTimeout(() => {
            setShowForm(true);
        }, 0);
        setShowForm(true);
    };
    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, setValues, resetForm }) => {


            let sendValue = {}
            const checkBrandKey = () => {
                if (inputBrandList.length > 0) {
                    return ('brand' in inputBrandList[0] || 'name' in inputBrandList[0]) && inputBrandList[0].brand !== '' ? true : false
                } else {
                    return ('brand' in inputBrandList || 'name' in inputBrandList) && inputBrandList[0].brand !== '' ? true : false
                }
            }

            const checkProductKey = () => {
                if (inputProductList.length > 0) {
                    return ('product' in inputProductList[0] || 'name' in inputProductList[0]) && inputProductList[0].product !== '' ? true : false
                } else {
                    return ('product' in inputProductList || 'name' in inputProductList) && inputProductList[0].product !== '' ? true : false
                }
            }
            const checkCategoryKey = () => {
                if (inputCategoryList.length > 0) {
                    return ('category' in inputCategoryList[0] || 'name' in inputCategoryList[0]) && inputCategoryList[0].category !== '' ? true : false
                } else {
                    return ('category' in inputCategoryList || 'name' in inputCategoryList) && inputCategoryList[0].category !== '' ? true : false
                }
            }


            const brand_id = checkBrandKey() && inputBrandList.map(brand => {
                return {
                    "brand": brand.id
                }
            })

            const product_id = checkProductKey() && inputProductList.map(product => {
                return {
                    "product": product.id
                }
            })

            const category_id = checkCategoryKey() && inputCategoryList.map(category => {
                return {
                    "category": category.id
                }
            })


            if (values.type === 'percent') {
                sendValue = {
                    "minimum": +values.minimum_purchase,
                    "discount": +values.discount
                }
            } else if (values.type === 'quantity') {
                sendValue = {
                    "qty_buy": +values.qty_buy,
                    "qty_bonus": +values.qty_bonus,
                    "maximum": +values.maximum
                }
            } else if (values.type === 'giveaway') {
                sendValue = {
                    "giveaway_product_qty": +values.giveaway_product_qty,
                    "giveaways": giveawayForm
                }
            } else if (values.type === 'voucher') {
                sendValue = {
                    "minimum": +values.minimum_purchase,
                    "value": +values.value
                }
            }

            const brandFilter = brand_id.length > 0 && brand_id.filter(brand => brand.hasOwnProperty('brand') && brand['brand'] !== undefined)
            const categoryFilter = category_id.length > 0 && category_id.filter(category => category.hasOwnProperty('category') && category['category'] !== undefined)
            const productFilter = product_id.length > 0 && product_id.filter(product => product.hasOwnProperty('product') && product['product'] !== undefined)


            // If checkbox All is checked, then fill with ['general', 'member', 'vip'].
            const isUserTargetAll = user.find((user) => user.includes('all'))

            let cleanVal = {
                "branch_ids": isSuperAdmin ? branches : [getLocalStorage().branchLogin.id],
                "type": values.type,
                "code": values.code,
                "name": values.name,
                "date_start": values.date_start,
                "date_end": values.date_end,
                "user_target": isUserTargetAll ? ['general', 'member', 'vip'] : user,
                "brand_target": brand,
                "item_target": item,
                "values": sendValue,
                //KONFIRMASI LAGI CARA DAPET CONTACT ID YANG UPDATE FORM DAN CREATE FORM 
                "created_by_contact_id": 1,
                "updated_by_contact_id": 1,
                "promotion_brand": brandFilter,
                "promotion_product": productFilter,
                "promotion_category": categoryFilter,
                "returnable": returnable
            }
            if (!product_id || product_id.length === 0) delete cleanVal['promotion_product']
            if (!brand_id || brand_id.length === 0) delete cleanVal['promotion_brand']
            if (!category_id || category_id.length === 0) delete cleanVal['promotion_category']

            let result = type === 'new' ?
                await dispatch(addPromotionConfiguration(cleanVal))
                : await dispatch(updatePromotionConfiguration(idEdit, cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }

    const handleChangeBranch = (param) => {
        if (Array.isArray(param.value)) {
            setBranches(param.value.map(branch => branch.value))
            setOptBranches(param.value)
        }
    }

    const handleRemoveTableBrand = (index) => {
        const list = [...inputBrandList]
        list.splice(index, 1)
        setInputBrandList(list)
    }

    const handleChangeTableBrand = (args) => {
        const { row, rows, formikAttr, props } = args;
        const isDuplicate = inputBrandList.find(r => r?.id === (row?.id ?? row?.value));

        if (isDuplicate) {
            globalMessage('Brand has been added already', 'warning')
            inputBrandList.pop()
        } else {
            inputBrandList[props.index] = { id: row?.id ?? row.value, name: row?.name ?? row.label }
            setInputBrandList([...inputBrandList])
        }

    }

    const handleAddTableBrandRow = () => {
        setInputBrandList([
            ...inputBrandList,
            {
                brand: null
            },
        ])
    }
    const handleRemoveTableProduct = (index) => {
        const list = [...inputProductList]
        list.splice(index, 1)
        setInputProductList(list)
    }

    const handleChangeTableProduct = ({ row, rows, formikAttr, props }) => {
        const isDuplicate = inputProductList.find(r => r?.id === row?.id);
        if (isDuplicate) {
            globalMessage('Product has been added already', 'warning')
            inputProductList.pop()
        } else {
            inputProductList[props.index] = { id: row.id, name: row.name }
            setInputProductList([...inputProductList])
        }
    }

    const handleAddTableProductRow = () => {
        setInputProductList([
            ...inputProductList,
            {
                product: ""
            },
        ])
    }


    /** Add table giveaway bonus. */
    const handleAddTableProductGARow = () => {
        setGiveawayForm([
            ...giveawayForm,
            {
                minimum_purchase: 0,
                products: []
            }
        ])

        setGiveawayProducts([...giveawayProducts, []])
    }

    const handleMultiSelectProduct = (data, parentIndex) => {
        const newGiveawayProducts = data?.length > 0 ? data?.map((val) => {
            return {
                ...val,
                parent_index: parentIndex
            }
        }) : []

        let isDuplicate = false

        const newIds = data?.map(val => val?.value)

        giveawayProducts.forEach(
            (product) => {
                product.length > 0 && product.forEach((prod) => {
                    if (newIds?.includes(prod.value) && prod.parent_index !== parentIndex) {
                        isDuplicate = true
                        globalMessage('Product has been added already', 'warning')
                        updateShowForm()
                        return
                    }
                })
            }
        )

        !isDuplicate && setGiveawayProducts(state => {
            return state.map((val, idx) => {
                if (idx === parentIndex) {
                    return newGiveawayProducts
                }

                return val
            })
        })
    }

    /** Remove table giveaway bonus. */
    const handleRemoveTableProductGARow = (index) => {
        const list = giveawayForm.filter((_, idx) => idx !== index)
        setGiveawayForm(list)
    }

    /** Handle minimum purchase of each giveaway bonus. */
    const handleMinimumPurchaseGiveaway = (minimumPurchase, index) => {
        setGiveawayForm(state => {
            return state.map((value, idx) => {
                if (idx !== index) return value

                value['minimum_purchase'] = minimumPurchase

                return value
            })
        })
    }

    useEffect(() => {
        setGiveawayForm(state => {
            return state.map((value, index) => {
                const filteredProducts = giveawayProducts[index]

                value['products'] = filteredProducts

                return value
            })
        })
    }, [giveawayProducts])

    const handleRemoveTableCategory = (index) => {
        const list = [...inputCategoryList]
        list.splice(index, 1)
        setInputCategoryList(list)
    }

    const handleChangeTableCategory = ({ row, rows, formikAttr, props }) => {
        const isDuplicate = inputCategoryList.find(r => r?.id === row?.id);
        if (isDuplicate) {
            globalMessage('Category has been added already', 'warning')
            inputCategoryList.pop()
        } else {
            inputCategoryList[props.index] = { id: row.id, name: row.name }
            setInputCategoryList([...inputCategoryList])
        }
    }

    const handleAddTableCategoryRow = () => {
        setInputCategoryList([
            ...inputCategoryList,
            {
                category: ""
            },
        ])
    }

    const handleChangeBrand = (event) => {
        const { name, checked } = event.target
        // BRAND HANDLE CHANGE
        if (checked) {
            setBrand([...brand, name])
        } else {
            setBrand(brand.filter(data => data !== name))
            setItem([])
            setShowCategoryTable(false)
            setShowProductTable(false)
        }

        if (checked && name === "brand") {
            setShowBrandTable(true)
        } else if (!checked && name === "brand") {
            setInputBrandList([{ brand: null }])
            setShowBrandTable(false)
        }
    }

    const handleChangeProduct = (event) => {
        const { name, checked } = event.target

        if (checked && name === 'all_product') {
            setItem([...item, name])
        } else if (!checked && name === 'all_product') {
            setItem(item.filter(data => data !== name))
        }

        if (checked && name === "product") {
            setProduct([...product, name])
            setItem([...item, name])
            setShowProductTable(true)
        } else if (!checked && name === "product") {
            setInputProductList([{ product: "" }])
            setShowProductTable(false)
            setItem(item.filter(data => data !== name))
            setProduct(product.filter(data => data !== name))
        }
        if (checked && name === "category") {
            setCategory([...category, name])
            setItem([...item, name])
            setShowCategoryTable(true)
        } else if (!checked && name === "category") {
            setInputCategoryList([{ category: "" }])
            setShowCategoryTable(false)
            setItem(item.filter(data => data !== name))
            setCategory(category.filter(data => data !== name))
        }


    }

    const handleChangeUser = (event) => {
        const { value, checked } = event.target
        // USER HANDLE CHANGE
        if (checked) {
            setUser([...user, value])
        } else {
            setUser(user.filter(data => data !== value))
        }
    };

    const handleChangeReturnAble = (event) => {
        setReturnable(!returnable)
    }

    const prodSelectHandler = (row, rows, formikAttr, index) => {
        const isDuplicate = giveawayProducts?.find(r => r?.id === row?.id);
        if (isDuplicate) {
            globalMessage('Product has been added already', 'warning')
            giveawayProducts.pop()
        } else {
            setGiveawayProducts([...giveawayProducts, {
                ...row, parentIndex: index
            }])
        }
    }

    const loadMasterbrand = async () => {
        const req = await dispatch(loadBrand());
        if (req.status) {
            setOptBrand(
                req.data.map(x => ({ label: x.name, value: x.id }))
            )

        } else {
            setOptBrand([{ label: 'Fetching Error', value: null }])
        }
    }

    const loadMasterBranch = async () => {
        const req = await dispatch(loadBranch());

        if (!req.status) setOptBranches([{ label: 'Fetching error', value: null }])
        else {
            const optBranches = req.data.map(branch => ({ label: branch.branch_name, value: branch.id }))
            const branchIds = branches?.map((branch) => branch);
            if (branchIds?.length > 0) {
                const filtered = optBranches.filter((branch) => branchIds.includes(branch.value))
                setOptBranches(filtered)
            }

        }
    }

    useEffect(() => {
        if (type === 'edit') {
            let findData = data.find(singleData => singleData.id === idEdit);
            const { brand_target, code, date_end, date_start, id, item_target, name, promotion_brand, promotion_category, promotion_product, type, user_target, values, promotion_branches, returnable } = findData;
            setInitialValues({
                branches: promotion_branches,
                type,
                code,
                name,
                date_start,
                date_end,
                user_target,
                brand_target,
                item_target,
                promotion_brand,
                promotion_category,
                promotion_product,
                discount: values.discount,
                minimum_purchase: values.minimum,
                qty_bonus: values.qty_bonus,
                qty_buy: values.qty_buy,
                maximum: values.maximum,
                giveaway_product_id: values.giveaway_product_id,
                giveaway_product_qty: values.giveaway_product_qty,
                giveaway_product_name: values.giveaway_product_name,
                value: values.value,
                returnable
            })

            const branchIds = promotion_branches?.length === 0 ? [] : promotion_branches.map(branch => branch.id);
            if (branchIds?.length > 0) {
                setBranches(branchIds)

                const branchOptions = promotion_branches.map(branch => ({ label: branch.branch_name, value: branch.id }))
                setOptBranches(branchOptions)
            }
            setPromotionDate(date_start)
            setUser(user_target)
            setBrand(brand_target)
            setItem(item_target)
            setReturnable(returnable)

            const brandEdit = brand_target.find(element => element === 'brand' ? true : false)

            const productEdit = item_target.find(element => element === 'product' ? true : false)

            const categoryEdit = item_target.find(element => element === 'category' ? true : false)


            brandEdit ? setShowBrandTable(true) : setShowBrandTable(false)
            productEdit ? setShowProductTable(true) : setShowProductTable(false)
            categoryEdit ? setShowCategoryTable(true) : setShowCategoryTable(false)

            setInputBrandList(promotion_brand)
            setInputProductList(promotion_product)
            setInputCategoryList(promotion_category)

            /** Set giveaway value */

            if (type === 'giveaway') {
                let products = []

                const giveaways = values.giveaways.map((giveaway) => {
                    giveaway.minimum_purchase = parseInt(giveaway.minimum_purchase)

                    if (Array.isArray(giveaway?.products) && giveaway?.products.length > 0) {
                        products.push(giveaway.products)
                    }

                    return giveaway
                })

                setGiveawayForm(giveaways)
                setGiveawayProducts(products)
            }
            setEditForm(true)

        }

        loadMasterbrand();
        loadMasterBranch();

    }, [])

    useEffect(() => {
        setAddress(contacts.length ? contacts[0].address : '')
    }, [contacts])

    const promotionType = [
        { label: 'Percent', value: 'percent' },
        { label: 'Value', value: 'voucher' },
        { label: 'Quantity', value: 'quantity' },
        { label: 'Giveaway', value: 'giveaway' },
    ]

    const checkEditCheckbox = (name, target) => {
        let found = false
        if (target === 'user_target') {
            user.find(element => element === name ? found = true : found)
            return found
        } else if (target === 'brand_target') {
            brand.find(element => element === name ? found = true : found)
            return found
        } else if (target === 'item_target') {
            item.find(element => element === name ? found = true : found)
            return found
        } else if (target === 'returnable') {
            return returnable
        }
    }

    const disabledCheckbox = args => {
        let disabled = false
        args.find(element => element === 'all' || element === 'all_brand' || element === 'all_product' ? disabled = true : disabled = false)
        return disabled
    }

    const disabledProductCheckbox = (args, name) => {
        let disabled = false
        args.find(element => element !== name ? disabled = true : disabled = false)
        return disabled
    }
    // const triggerProduct = 'brand' in inputBrandList
    const triggerProduct = inputBrandList.length > 0 ? inputBrandList.some(obj => obj.hasOwnProperty('brand')) : true
    const allBrand = brand.find(data => data !== 'brand') ? false : true

    const notIdOne = () => idEdit !== 1;


    const [extraParams, setExtraParams] = useState({
        by_brand: [],
        brand_type: '',
    })

    useEffect(() => {

        const setParams = () => {

            let hasBrand = brand.find(x => x === 'brand')
            let hasExternal = brand.find(x => x === 'external')
            let hasInternal = brand.find(x => x === 'internal')
            let hasAllBrand = brand.find(x => x === 'all_brand')
            let hasInternalExternal = brand.filter(x => x === 'external' || x === 'internal')

            if (hasAllBrand || hasInternalExternal.length === 2) {
                setExtraParams({
                    by_brand: JSON.stringify([]),
                    brand_type: 'all_brand',
                })

            }
            else if (hasExternal) {
                setExtraParams({
                    by_brand: inputBrandList.map(s => {
                        if ('id' in s) {
                            return s.id
                        }
                    }).filter(Boolean),

                    brand_type: hasExternal,
                })
            } else if (hasInternal) {
                setExtraParams({
                    by_brand: inputBrandList.map(s => {
                        if ('id' in s) {
                            return s.id
                        }
                    }).filter(Boolean),
                    brand_type: hasInternal,
                })
            } else if (hasBrand) {
                setExtraParams({
                    by_brand: inputBrandList.map(s => {
                        if ('id' in s) {
                            return s.id
                        }
                    }).filter(Boolean)
                })

            }

        }

        setParams()




    }, [brand, inputBrandList])

    useEffect(() => {
        updateShowForm()
    }, [inputBrandList, inputCategoryList, inputProductList])

    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Promotion" : "Update data Promotion"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data promotion`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusPromotionConfiguration())} option={{ type: 'error', message: error }} />
                }
                {
                    inputError && <Alert hide={() => setInputError(null)} option={{ type: 'error', message: inputError }} />
                }
                {
                    isReady ? (
                        <>

                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {


                                    const { isSubmitting, values } = formik;
                                    const { type } = formik.values
                                    return (
                                        <Form className="form-layout-1">

                                            <FieldsContainer title="Promotion Configuration">
                                                <Input name="code" label="ID" />
                                                <Input name="name" label="Promotion Name" />

                                                {
                                                    isSuperAdmin &&
                                                    <Multiselect
                                                        name="branch_ids"
                                                        getData={handleChangeBranch}
                                                        {
                                                        ...idEdit ? {
                                                            defaultValues: optBranches
                                                        } : {}
                                                        }
                                                        hasSelectAll
                                                        label="branch"
                                                        options="branch"
                                                    />
                                                }
                                                {
                                                    notIdOne() && (
                                                        <>
                                                            <SelectData name="type" options={promotionType} label="Promotion Type" />
                                                            <Datepicker name="date_start" label="Start Date" />
                                                            <Datepicker name="date_end" label="End Date" />
                                                        </>
                                                    )
                                                }
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                {
                                                    notIdOne() && (
                                                        <div className="grid-span-3">
                                                            {
                                                                type !== 'voucher' &&
                                                                (
                                                                    <>
                                                                        <FormControl component="fieldset" style={{ width: '100%' }}>
                                                                            <FormLabel component="legend">User</FormLabel>
                                                                            <FormGroup row={true}>
                                                                                <FormControlLabel
                                                                                    control={<Checkbox onChange={handleChangeUser} name="user_target" value="all"
                                                                                        checked={editForm && checkEditCheckbox('all', 'user_target')}
                                                                                    />}
                                                                                    label="All"
                                                                                />
                                                                                <FormControlLabel
                                                                                    control={<Checkbox onChange={handleChangeUser} name="user_target" value="general"
                                                                                        checked={editForm && checkEditCheckbox('general', 'user_target')}
                                                                                        disabled={disabledCheckbox(user)}
                                                                                    />}
                                                                                    label="General"
                                                                                />
                                                                                <FormControlLabel
                                                                                    control={<Checkbox onChange={handleChangeUser} name="user_target" value="member"
                                                                                        checked={editForm && checkEditCheckbox('member', 'user_target')}
                                                                                        disabled={disabledCheckbox(user)}
                                                                                    />}
                                                                                    label="Member"
                                                                                />
                                                                                <FormControlLabel
                                                                                    control={<Checkbox onChange={handleChangeUser} name="user_target" value="vip"
                                                                                        checked={editForm && checkEditCheckbox('vip', 'user_target')}
                                                                                        disabled={disabledCheckbox(user)}
                                                                                    />}
                                                                                    label="VIP"
                                                                                />
                                                                            </FormGroup>
                                                                            <FormLabel component="legend"
                                                                                style={{
                                                                                    marginTop: '20px'
                                                                                }}>
                                                                                Return Configuration
                                                                            </FormLabel>
                                                                            <FormGroup>
                                                                            <FormControlLabel
                                                                                control={<Checkbox onChange={handleChangeReturnAble} name="returnable" value={returnable}
                                                                                    checked={editForm && returnable}
                                                                                />}
                                                                                style={{
                                                                                    width:'20%'
                                                                                }}
                                                                                label="Returnable"
                                                                            />
                                                                            </FormGroup>
                                                                        </FormControl>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                }

                                            </FieldsContainer>
                                            {
                                                type === 'percent' &&
                                                <>
                                                    {
                                                        true && (
                                                            <Grid container spacing={3}>
                                                                {
                                                                    notIdOne() && (
                                                                        <Grid item xs={6}>
                                                                            <Input name="discount" label="Discount" placeholder="%" />
                                                                            <FormControl component="fieldset">
                                                                                <FormLabel component="legend">Brand</FormLabel>
                                                                                <FormGroup row={true}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeBrand} name="all_brand"
                                                                                            checked={editForm && checkEditCheckbox('all_brand', 'brand_target')}
                                                                                        />}
                                                                                        label="All Brand"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeBrand} name="brand"
                                                                                            checked={editForm && checkEditCheckbox('brand', 'brand_target')}
                                                                                            disabled={disabledCheckbox(brand)}
                                                                                        />}
                                                                                        label="Choose Brand"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeBrand} name="internal"
                                                                                            checked={editForm && checkEditCheckbox('internal', 'brand_target')}
                                                                                            disabled={disabledCheckbox(brand)}
                                                                                        />}
                                                                                        label="Internal Brand"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeBrand} name="external"
                                                                                            checked={editForm && checkEditCheckbox('external', 'brand_target')}
                                                                                            disabled={disabledCheckbox(brand)}
                                                                                        />}
                                                                                        label="External Brand"
                                                                                    />
                                                                                </FormGroup>
                                                                            </FormControl>
                                                                        </Grid>
                                                                    )
                                                                }

                                                                <Grid item xs={6}>
                                                                    <InputNum name="minimum_purchase" label="Minimum Purchase" placeholder="IDR" />
                                                                    {
                                                                        notIdOne() && (
                                                                            <FormControl component="fieldset">
                                                                                <FormLabel component="legend">Product</FormLabel>
                                                                                <FormGroup row={true}>
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeProduct} name="all_product"
                                                                                            checked={editForm && checkEditCheckbox('all_product', 'item_target')}
                                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'all_product')}
                                                                                        />}
                                                                                        label="All Product"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeProduct} name="category"
                                                                                            checked={editForm && checkEditCheckbox('category', 'item_target')}
                                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'category')}
                                                                                        />}
                                                                                        label="Choose Category"
                                                                                    />
                                                                                    <FormControlLabel
                                                                                        control={<Checkbox onChange={handleChangeProduct} name="product"
                                                                                            checked={editForm && checkEditCheckbox('product', 'item_target')}
                                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'product')}
                                                                                        />}
                                                                                        label="Choose Product"
                                                                                    />
                                                                                </FormGroup>
                                                                            </FormControl>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                        )
                                                    }

                                                    <Grid container spacing={3}>
                                                        {
                                                            showBrandTable ?
                                                                <Grid item xs={6}>
                                                                    <table className="table table-detail">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No.</th>
                                                                                <th></th>
                                                                                <th>Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                showForm && inputBrandList.map((input, index) => {

                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {
                                                                                                    inputBrandList.length > 1 &&
                                                                                                    <DeleteIcon onClick={() => handleRemoveTableBrand(index)} style={{ cursor: 'pointer' }} />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <SelectAsync
                                                                                                    index={index}
                                                                                                    // options="brand"
                                                                                                    options={optBrand}
                                                                                                    portal
                                                                                                    {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                    handleChange={handleChangeTableBrand}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="button-cover">
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Add Brand"
                                                                            onClick={handleAddTableBrandRow}
                                                                        />
                                                                    </div>
                                                                </Grid> :
                                                                <Grid item xs={6} />
                                                        }
                                                        {
                                                            showCategoryTable &&
                                                            <Grid item xs={6}>
                                                                <table className="table table-detail">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Now.</th>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            showForm && inputCategoryList.map((input, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            {
                                                                                                inputCategoryList.length > 1 &&
                                                                                                <DeleteIcon onClick={() => handleRemoveTableCategory(index)} style={{ cursor: 'pointer' }} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <SelectAsync
                                                                                                index={index}
                                                                                                extraParams={extraParams}
                                                                                                type="category"
                                                                                                portal
                                                                                                {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                handleChange={handleChangeTableCategory}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="button-cover">
                                                                    <Button
                                                                        style="LightblueMd"
                                                                        type="button"
                                                                        label="Add Category"
                                                                        onClick={handleAddTableCategoryRow}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        }
                                                        {
                                                            showProductTable &&
                                                            <Grid item xs={6}>
                                                                <table className="table table-detail">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            showForm && inputProductList.map((input, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            {
                                                                                                inputProductList.length > 1 &&
                                                                                                <DeleteIcon onClick={() => handleRemoveTableProduct(index)} style={{ cursor: 'pointer' }} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <SelectAsync
                                                                                                index={index}
                                                                                                extraParams={extraParams}
                                                                                                type="productName"
                                                                                                portal
                                                                                                {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                handleChange={handleChangeTableProduct}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="button-cover">
                                                                    <Button
                                                                        style="LightblueMd"
                                                                        type="button"
                                                                        label="Add Product"
                                                                        onClick={handleAddTableProductRow}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                type === 'voucher' &&
                                                <>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Input name="value" label="Value" />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Input name="minimum_purchase" label="Minimum Purchase" placeholder="IDR" />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        {
                                                            showBrandTable ?
                                                                <Grid item xs={4}>
                                                                    <table className="table table-detail">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No.</th>
                                                                                <th></th>
                                                                                <th>Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                showForm && inputBrandList.map((input, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {
                                                                                                    inputBrandList.length > 1 &&
                                                                                                    <DeleteIcon onClick={() => handleRemoveTableBrand(index)} style={{ cursor: 'pointer' }} />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <SelectAsync
                                                                                                    index={index}
                                                                                                    options="brand"
                                                                                                    portal
                                                                                                    {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                    handleChange={handleChangeTableBrand}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="button-cover">
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Add Brand"
                                                                            onClick={handleAddTableBrandRow}
                                                                        />
                                                                    </div>
                                                                </Grid> :
                                                                <Grid item xs={4} />
                                                        }
                                                        {
                                                            showCategoryTable ?
                                                                <Grid item xs={4}>
                                                                    <table className="table table-detail">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No.</th>
                                                                                <th></th>
                                                                                <th>Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                showForm && inputCategoryList.map((input, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {
                                                                                                    inputCategoryList.length > 1 &&
                                                                                                    <DeleteIcon onClick={() => handleRemoveTableCategory(index)} style={{ cursor: 'pointer' }} />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <SelectAsync
                                                                                                    index={index}
                                                                                                    type="category"
                                                                                                    portal
                                                                                                    {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                    handleChange={handleChangeTableCategory}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="button-cover">
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Add Category"
                                                                            onClick={handleAddTableCategoryRow}
                                                                        />
                                                                    </div>
                                                                </Grid> : <Grid item xs={4} />
                                                        }
                                                        {
                                                            showProductTable ?
                                                                <Grid item xs={4}>
                                                                    <table className="table table-detail">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No.</th>
                                                                                <th></th>
                                                                                <th>Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                showForm && inputProductList.map((input, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {
                                                                                                    inputProductList.length > 1 &&
                                                                                                    <DeleteIcon onClick={() => handleRemoveTableProduct(index)} style={{ cursor: 'pointer' }} />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <SelectAsync
                                                                                                    index={index}
                                                                                                    type="productName"
                                                                                                    portal
                                                                                                    {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                    handleChange={handleChangeTableProduct}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="button-cover">
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Add Product"
                                                                            onClick={handleAddTableProductRow}
                                                                        />
                                                                    </div>
                                                                </Grid> : <Grid item xs={4} />
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                type === 'quantity' &&
                                                <>
                                                    <Grid container spacing={3}>
                                                        <Grid item xs={6}>
                                                            <Input name="qty_buy" label="Buying Quantity" />
                                                            {/* <Input name="qty_bonus" label="Bonus Quantity" /> */}
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Brand</FormLabel>
                                                                <FormGroup row={true}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="all_brand"
                                                                            checked={editForm && checkEditCheckbox('all_brand', 'brand_target')}
                                                                        />}
                                                                        label="All Brand"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="brand"
                                                                            checked={editForm && checkEditCheckbox('brand', 'brand_target')}
                                                                            disabled={disabledCheckbox(brand)}
                                                                        />}
                                                                        label="Choose Brand"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="internal"
                                                                            checked={editForm && checkEditCheckbox('internal', 'brand_target')}
                                                                            disabled={disabledCheckbox(brand)}
                                                                        />}
                                                                        label="Internal Brand"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="external"
                                                                            checked={editForm && checkEditCheckbox('external', 'brand_target')}
                                                                            disabled={disabledCheckbox(brand)}
                                                                        />}
                                                                        label="External Brand"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Input name="qty_bonus" label="Bonus Quantity" />
                                                            {/* BUG PW BY ALUR NOMOR 40, DI HIDE DULU MAXIMUM VALUE NYA */}
                                                            {/* <Input name="maximum" label="Maximum Value Bonus" /> */}
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Product</FormLabel>
                                                                <FormGroup row={true}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeProduct} name="all_product"
                                                                            checked={editForm && checkEditCheckbox('all_product', 'item_target')}
                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'all_product')}
                                                                        />}
                                                                        label="All Product"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeProduct} name="category"
                                                                            checked={editForm && checkEditCheckbox('category', 'item_target')}
                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'category')}
                                                                        />}
                                                                        label="Choose Category"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeProduct} name="product"
                                                                            checked={editForm && checkEditCheckbox('product', 'item_target')}
                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'product')}
                                                                        />}
                                                                        label="Choose Product"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        {
                                                            showBrandTable ?
                                                                <Grid item xs={6}>
                                                                    <table className="table table-detail">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No.</th>
                                                                                <th></th>
                                                                                <th>Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                showForm && inputBrandList.map((input, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {
                                                                                                    inputBrandList.length > 1 &&
                                                                                                    <DeleteIcon onClick={() => handleRemoveTableBrand(index)} style={{ cursor: 'pointer' }} />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <SelectAsync
                                                                                                    index={index}
                                                                                                    options="brand"
                                                                                                    portal
                                                                                                    {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                    handleChange={handleChangeTableBrand}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="button-cover">
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Add Brand"
                                                                            onClick={handleAddTableBrandRow}
                                                                        />
                                                                    </div>
                                                                </Grid> :
                                                                <Grid item xs={6} />
                                                        }
                                                        {
                                                            showCategoryTable &&
                                                            <Grid item xs={6}>
                                                                <table className="table table-detail">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            showForm && inputCategoryList.map((input, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            {
                                                                                                inputCategoryList.length > 1 &&
                                                                                                <DeleteIcon onClick={() => handleRemoveTableCategory(index)} style={{ cursor: 'pointer' }} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <SelectAsync
                                                                                                index={index}
                                                                                                type="category"
                                                                                                portal
                                                                                                {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                handleChange={handleChangeTableCategory}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="button-cover">
                                                                    <Button
                                                                        style="LightblueMd"
                                                                        type="button"
                                                                        label="Add Category"
                                                                        onClick={handleAddTableCategoryRow}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        }
                                                        {
                                                            showProductTable &&
                                                            <Grid item xs={6}>
                                                                <table className="table table-detail">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            showForm && inputProductList.map((input, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            {
                                                                                                inputProductList.length > 1 &&
                                                                                                <DeleteIcon onClick={() => handleRemoveTableProduct(index)} style={{ cursor: 'pointer' }} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <SelectAsync
                                                                                                index={index}
                                                                                                type="productName"
                                                                                                portal
                                                                                                {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                handleChange={handleChangeTableProduct}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="button-cover">
                                                                    <Button
                                                                        style="LightblueMd"
                                                                        type="button"
                                                                        label="Add Product"
                                                                        onClick={handleAddTableProductRow}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            {
                                                type === 'giveaway' &&
                                                <>
                                                    <Grid container spacing={4}>
                                                        <Grid item xs={6}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Brand</FormLabel>
                                                                <FormGroup row={true}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="all_brand"
                                                                            checked={editForm && checkEditCheckbox('all_brand', 'brand_target')}
                                                                        />}
                                                                        label="All Brand"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="brand"
                                                                            checked={editForm && checkEditCheckbox('brand', 'brand_target')}
                                                                            disabled={disabledCheckbox(brand)}
                                                                        />}
                                                                        label="Choose Brand"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="internal"
                                                                            checked={editForm && checkEditCheckbox('internal', 'brand_target')}
                                                                            disabled={disabledCheckbox(brand)}
                                                                        />}
                                                                        label="Internal Brand"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeBrand} name="external"
                                                                            checked={editForm && checkEditCheckbox('external', 'brand_target')}
                                                                            disabled={disabledCheckbox(brand)}
                                                                        />}
                                                                        label="External Brand"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">Product</FormLabel>
                                                                <FormGroup row={true}>
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeProduct} name="all_product"
                                                                            checked={editForm && checkEditCheckbox('all_product', 'item_target')}
                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'all_product')}
                                                                        />}
                                                                        label="All Product"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeProduct} name="category"
                                                                            checked={editForm && checkEditCheckbox('category', 'item_target')}
                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'category')}
                                                                        />}
                                                                        label="Choose Category"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox onChange={handleChangeProduct} name="product"
                                                                            checked={editForm && checkEditCheckbox('product', 'item_target')}
                                                                            disabled={allBrand && triggerProduct || disabledProductCheckbox(item, 'product')}
                                                                        />}
                                                                        label="Choose Product"
                                                                    />
                                                                </FormGroup>
                                                            </FormControl>
                                                            <Input name="giveaway_product_qty" label="Bonus Quantity" />
                                                        </Grid>

                                                        <Grid item xs={6}>
                                                            <table className="table table-detail">
                                                                <thead>
                                                                    <tr>
                                                                        <th>No.</th>
                                                                        <th></th>
                                                                        <th>Minimum Purchase</th>
                                                                        <th>Products Bonus</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        showForm && giveawayForm?.map((input, index) => {
                                                                            return (
                                                                                <tr key={index}>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>
                                                                                        {
                                                                                            giveawayForm.length > 1 &&
                                                                                            <DeleteIcon onClick={() => handleRemoveTableProductGARow(index)} style={{ cursor: 'pointer' }} />
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        <InputNumber
                                                                                            key={index}
                                                                                            withFieldContainer
                                                                                            value={input.minimum_purchase}
                                                                                            handleChange={(val) => handleMinimumPurchaseGiveaway(val, index)}
                                                                                            defaultValue={input.minimum_purchase}
                                                                                        />
                                                                                    </td>
                                                                                    <td>

                                                                                        <CustomSelectAsync onChange={(value) => handleMultiSelectProduct(value, index)} key={index} defaultValue={input?.products} />
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                    }
                                                                </tbody>
                                                            </table>
                                                            <div className="button-cover">
                                                                <Button
                                                                    style="LightblueMd"
                                                                    type="button"
                                                                    label="Add Bonus"
                                                                    onClick={handleAddTableProductGARow}
                                                                />
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container spacing={3}>
                                                        {
                                                            showBrandTable ?
                                                                <Grid item xs={6}>
                                                                    <table className="table table-detail">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>No.</th>
                                                                                <th></th>
                                                                                <th>Name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                showForm && inputBrandList.map((input, index) => {
                                                                                    return (
                                                                                        <tr key={index}>
                                                                                            <td>{index + 1}</td>
                                                                                            <td>
                                                                                                {
                                                                                                    inputBrandList.length > 1 &&
                                                                                                    <DeleteIcon onClick={() => handleRemoveTableBrand(index)} style={{ cursor: 'pointer' }} />
                                                                                                }
                                                                                            </td>
                                                                                            <td>
                                                                                                <SelectAsync
                                                                                                    index={index}
                                                                                                    options="brand"
                                                                                                    portal
                                                                                                    {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                    handleChange={handleChangeTableBrand}
                                                                                                />
                                                                                            </td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                    <div className="button-cover">
                                                                        <Button
                                                                            style="LightblueMd"
                                                                            type="button"
                                                                            label="Add Brand"
                                                                            onClick={handleAddTableBrandRow}
                                                                        />
                                                                    </div>
                                                                </Grid> :
                                                                <Grid item xs={6} />
                                                        }
                                                        {
                                                            showProductTable &&
                                                            <Grid item xs={6}>
                                                                <table className="table table-detail">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            showForm && inputProductList.map((input, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            {
                                                                                                inputProductList.length > 1 &&
                                                                                                <DeleteIcon onClick={() => handleRemoveTableProduct(index)} style={{ cursor: 'pointer' }} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <SelectAsync
                                                                                                index={index}
                                                                                                type="productName"
                                                                                                portal
                                                                                                {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                handleChange={handleChangeTableProduct}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="button-cover">
                                                                    <Button
                                                                        style="LightblueMd"
                                                                        type="button"
                                                                        label="Add Product"
                                                                        onClick={handleAddTableProductRow}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        }
                                                        {
                                                            showCategoryTable &&
                                                            <Grid item xs={6}>
                                                                <table className="table table-detail">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>No.</th>
                                                                            <th></th>
                                                                            <th>Name</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            showForm && inputCategoryList.map((input, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td>{index + 1}</td>
                                                                                        <td>
                                                                                            {
                                                                                                inputCategoryList.length > 1 &&
                                                                                                <DeleteIcon onClick={() => handleRemoveTableCategory(index)} style={{ cursor: 'pointer' }} />
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            <SelectAsync
                                                                                                index={index}
                                                                                                type="category"
                                                                                                portal
                                                                                                {...(input ? { value: { label: input.name, value: input.id } } : {})}
                                                                                                handleChange={handleChangeTableCategory}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </table>
                                                                <div className="button-cover">
                                                                    <Button
                                                                        style="LightblueMd"
                                                                        type="button"
                                                                        label="Add Category"
                                                                        onClick={handleAddTableCategoryRow}
                                                                    />
                                                                </div>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            <FormButton
                                                isSubmitting={isSubmitting}
                                                showTable={showTable}
                                            />

                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper >
    )
}

export default FormElement
