import React, { useState, useEffect, createElement } from 'react'
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Color from './color/Index'
import Unit from './unit/Index'
import Warehouse from './warehouse/Index'
import Brand from './brand/Index'
import Category from './category/Index'
import Variant from './variant'
import { filterTabs } from '../../../utils/MenuConfig'
import { useSelector } from 'react-redux';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}> {children} </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // backgroundColor: '#eee',
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: 'green',

            }
        }
    },

}));



export default function Configuration(props) {
    const { permission } = useSelector(state => state.userReducer);
    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [configurations, setConfigurations] = useState([
        {
            label: 'Brand',
            component: Brand,
            permissionName: 'brand',
            permissionType: 'read',
        },
        {
            label: 'Color',
            component: Color,
            permissionName: 'color',
            permissionType: 'read',
        },
        {
            label: 'Variant',
            component: Variant,
            permissionName: 'variant',
            permissionType: 'read',
        },

        {
            label: 'Category',
            component: Category,
            permissionName: 'category',
            permissionType: 'read',
        },
        // {
        //     label: 'UoM',
        //     component: Unit,
        //     permissionName: 'unit',
        //     permissionType: 'read',
        // },
        {
            label: 'Warehouse',
            component: Warehouse,
            permissionName: 'warehouse',
            permissionType: 'read',
        },
    ]);

    useEffect(() => {
        const fileteredSubmodules = filterTabs(configurations, permission);

        setConfigurations(fileteredSubmodules);
    }, [permission]);

    return (
        <>
            <div className={classes.root + " mt--16"}>
                <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                        {
                            configurations.map((config, configIdx) => <Tab key={configIdx} label={config.label} {...a11yProps(configIdx)} />)
                        }
                    </Tabs>
                </AppBar>
                {
                    configurations.map((config, configIdx) => (
                        <TabPanel value={value} key={configIdx} className="mt-16" index={configIdx}>
                            {
                                config.component ?
                                    createElement(config.component) : config.label
                            }
                        </TabPanel>
                    ))
                }
            </div>
        </>
    )
}
