import {
    COLOR_LOADING,
    COLOR_SET,
    COLOR_ERROR,
    COLOR_ADD,
    COLOR_SHOW,
    COLOR_DELETE,
    COLOR_UPDATE,
    COLOR_RESET,
    COLOR_EDIT,
} from './colorActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
}

const colorReducer = (state = initialState, { payload, type }) => {

    switch (type) {
        case COLOR_LOADING:
            return {
                ...state,
                loading: true,
            }
        case COLOR_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case COLOR_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case COLOR_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New color has been added successfully.'
            }
        case COLOR_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case COLOR_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case COLOR_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case COLOR_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case COLOR_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default colorReducer