import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage, useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
    label: {
        ...theme.pw.form.label
    },
    input: {
        ...theme.pw.form.input
    },
    container: {
        ...theme.pw.form.input.container
    },
    errMsg: {
        ...theme.pw.form.input.errMsg
    }
}))


export default function InputNum(props) {

    const { min, labelAsBaloon, max, value, label, getData, name, type } = props;
    const classes = useStyles(props);
    const formik = useFormikContext();

    /* state */
    const [val, setVal] = useState(type ? null : (value || 0))

    /* function */
    const handleChange = event => {
        let val = event.target.value;

        if (type && val.length < 1) {
            val = undefined
        }
        if (!type && (val === 0 || val === '0' || val.length < 1 || val === '')) {
            val = 0;
        }

        if (!type && isNaN(val)) {
            return;
        }

        if (min || max) {
            if (val < min) {
                // val = min;
            } else if (val > max) {
                return;
            }
        }

        if (!type && (val.length > 1 && (val[0] === 0 || val[0] === '0'))) {
            val = val.substring(1)
        }

        setVal(val);
        formik && formik.setFieldValue(name, +val);

        if (getData) {
            getData({
                value: +val,
                props
            })
        }
    }

    /* EFFECT[formik] : update value dari formik */
    useEffect(() => {

        if (!formik) { return; }

        /* 
        sebelumnya ini di cek pake apakah key nya ada / values[name]
        tapi ada case ini ga masuk ke if nya 
        karena ternyata values[name] punya value 0
        */
        const { values } = formik;
        if (values && (name in values)) {
            setVal(values[name])
        }
    }, [formik])


    return (
        <div className={classes.container}>
            {label && <label className={classes.label} htmlFor={name}>{label}</label>}
            <input value={val} onChange={handleChange} maxLength='16' className={classes.input} name={name || ''} type="text" />
            {
                formik &&
                <ErrorMessage name={name} component="div" className={classes.errMsg} />
            }
        </div>
    )
}
