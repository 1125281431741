import React, { useState, useEffect } from 'react'
import InputNumber from 'Elements/inputnumber/InputNumber';
import { money, makeImgUrl } from '@/Helper'
import ImageUploader from 'react-images-upload';
import { reqPost, hostUrl } from 'Configs/request'
import { Lightbox } from "react-modal-image";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function TableReturn(props) {
    const { items, setSelectedReturnProduct } = props;
    const [rows, setRows] = useState(items)

    const updateQty = (val, props) => {
        rows[props.index].qty = val;
        setRows([...rows]);
    }
    useEffect(() => {
        setSelectedReturnProduct(rows);
        setLoading(rows.map(x => false))
        setShowImage(rows.map(x => false))
    }, [rows])

    useEffect(() => {
        setRows(items);
    }, [items])
    console.log(rows)
    const [loading, setLoading] = useState([])
    const [showImage, setShowImage] = useState([])

    const onDrop = async (pictureFiles, pictureDataURLs, index) => {

        if (pictureFiles.length) {
            let formData = new FormData();
            let file = pictureFiles[0];
            formData.append('file_retur', file)

            setLoading(state => {
                state[index] = true
                return [...state]
            })

            const resp = await reqPost(`${hostUrl}/api/pos/attachment`, formData);
            setLoading(state => {
                state[index] = false
                return [...state]
            })

            setRows(state => {
                state[index].proof_retur = resp.data
                return [...state];

            })

        } else {
            setRows(state => {
                state[index].proof_retur = null
                return [...state];

            })
        }
    }

    const deleteProofReturn = (index) => {
        setRows(state => {
            state[index].proof_retur = null
            return [...state];
        })
    }


    const openLightbox = (index) => {
        setShowImage(state => {
            state[index] = true
            return [...state]
        })
    };
    const closeLightbox = (index) => {
        setShowImage(state => {
            state[index] = false
            return [...state]
        })
    };
    const generateUploadProofImage = (index) => {
        return (
            <>
                {
                    showImage[index] && (
                        <Lightbox
                            medium={makeImgUrl(rows[index].proof_retur)}
                            onClose={() => closeLightbox(index)}
                        />
                    )
                }
                {
                    rows[index].proof_retur && !loading[index] &&

                    <div
                        style={{
                            textAlign: 'center',
                            background: '#eee',
                            position: 'relative',
                            borderRadius: '100px'

                        }}>

                        <a
                            onClick={() => deleteProofReturn(index)}
                            style={{
                                position: 'absolute',
                                right: '0',
                                marginTop: '-5px'
                            }}
                        >
                            <HighlightOffIcon
                                style={{
                                    cursor: 'pointer',
                                    fontSize: '16px',

                                }} />
                        </a>
                        <a
                            onClick={() => openLightbox(index)}
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                textAlign: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                                color: '#21495e',
                                marginBottom: '10px',
                                fontSize: '10px',
                                paddingTop: '10px',
                                paddingBottom: '10px'
                            }} >
                            <p>Show Image</p>

                        </a>
                    </div>



                }
                {
                    loading[index] && !rows[index].proof_retur && <div style={{ display: 'flex', justifyContent: 'center' }}> <span> <FontAwesomeIcon spin icon="spinner" /> Uploading </span></div>
                }
                {
                    !rows[index].proof_retur && !loading[index] &&
                    <ImageUploader
                        withIcon={true}
                        buttonText='Upload'
                        onChange={(val1, val2) => onDrop(val1, val2, index)}
                        imgExtension={['.jpg', '.png', '.jpeg']}
                        maxFileSize={5242880}
                        singleImage={true}
                        label="Upload Proof Member Hire"
                        withIcon={false}
                        withPreview={false}
                        withLabel={false}
                        buttonStyles={{
                            background: '#eee',
                            border: '1px solid #ddd',
                            color: "#777",
                            fontSize: '12px',
                            fontWeight: '600'
                        }}

                    />
                }
            </>

        )
    }
    return (
        <div className="table-product">
            <table>
                <thead>
                    <tr>
                        <th>No</th>
                        <th>SKU</th>
                        <th>Product</th>
                        <th>Price /unit</th>
                        <th>Qty</th>
                        <th>Unit</th>
                        <th>Discount</th>
                        <th>Total</th>
                        <th>Proof Image</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        rows.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td className="width-as-content">{item.sku}</td>
                                <td className="width-as-content">{item.product_name}</td>
                                <td className="width-as-content text-right">IDR {money(item.price)}</td>
                                <td>
                                    <InputNumber
                                        value={item.qty}
                                        min={1}
                                        handleChange={updateQty}
                                        // max={item.qty}
                                        index={index}
                                    />
                                </td>
                                <td>{item.uom_name}</td>
                                <td>IDR {money(item.discount * +item.qty)}</td>
                                <td className="width-as-content text-right">
                                    IDR {money((item.price * +item.qty) - (item.discount * +item.qty))}
                                </td>
                                <td>
                                    {
                                        generateUploadProofImage(index)
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}
