import React, { useEffect, useState } from 'react';
import { money, sortData, handleSortState } from '@/Helper';
import NoReport from 'Elements/NoReport';
import KopSurat from '../../../comps/elements/KopSurat';
import ThSort from 'Elements/ThSort';
import VariantSort from 'Elements/VariantSort';
import { Helmet } from 'react-helmet';
import { NoPrint } from 'react-easy-print';
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';

export default function TableGeneral({ data }) {
    const [rows, setRows] = useState([]);
    const [scroll, setScroll] = useState(true);
    const [totalVariant, setTotalVariant] = useState({});
    const [mostVariantValue, setMostVariantValue] = useState([]);
    const [variantSort, setVariantSort] = useState({});
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        },
        product_code: {
            has_sort: true,
            sort_type: null,
            label: 'code',
        },
        category: {
            has_sort: true,
            sort_type: null,
            label: 'Category',
        },
        product_name: {
            has_sort: true,
            sort_type: null,
            label: 'name',
        },
        brand_name: {
            has_sort: true,
            sort_type: null,
            label: 'brand',
        },
        variant: {
            className: 'th-text-center',
            has_sort: false,
            colspan: mostVariantValue.length,
            label: 'variant',
        },
        total_qty: {
            has_sort: true,
            className: 'th-text-center',
            sort_type: null,
            label: 'Total',
        },
        uom: {
            has_sort: true,
            sort_type: null,
            label: 'uom',
        },
        sales_price: {
            has_sort: true,
            className: 'th-text-right',
            sort_type: null,
            label: 'price',
        },
        sub_total: {
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            label: 'sub total',
        },
        total_discount: {
            has_sort: true,
            className: 'th-text-right',
            sort_type: null,
            label: 'discount',
        },
        grand_total: {
            has_sort: true,
            className: 'th-text-right',
            sort_type: null,
            label: 'total',
        },
        hpp: {
            has_sort: true,
            className: 'th-text-right',
            sort_type: null,
            label: 'hpp',
        },
        additional_cost: {
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            label: 'ad. cost',
        },
        net_income: {
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            label: 'net value',
        },
    });

    const sortColumn = (colName, variantIndex = 1000) => {
        if (variantIndex !== 1000) {
            // variamt sort
            setRows((prevState) => {
                return [
                    ...sortData({
                        type: variantSort[`variant-${variantIndex}`].sort_type,
                        column: colName,
                        data: prevState,
                    }),
                ];
            });

            setVariantSort((prev) => {
                return {
                    ...handleSortState({
                        data: prev,
                        column: `variant-${variantIndex}`,
                    }),
                };
            });
        } else {
            setRows((prevState) => {
                return [
                    ...sortData({
                        type: sort[colName].sort_type,
                        column: colName,
                        data: prevState,
                    }),
                ];
            });

            setSort((prev) => {
                return { ...handleSortState({ data: prev, column: colName }) };
            });
        }
    };
    const toggleScroll = () => {
        setScroll(!scroll);
    };

    useEffect(() => {
        let initRows;
        let totalVariant = {};
        let initMostVariantValue = [];

        // untuk memeasan jumalh kolom variant table
        data.pos.forEach((pos) => {
            pos.variants.forEach((variant) => {
                if (variant.types.length > initMostVariantValue.length) {
                    initMostVariantValue = variant.types;
                }

                variant.types.forEach((type) => {
                    if (type.label == '36' && type.qty !== 0) {
                        // console.log(777777, type);
                    }
                    if (!totalVariant[type.label]) {
                        totalVariant[type.label] = type.qty;
                    } else {
                        totalVariant[type.label] += type.qty;
                    }
                });
            });
        });

        initRows = data.pos.map((pos, posIdx) => {
            let selected = false;

            let selectedData = pos.variants
                .map((x) => {
                    x.types.forEach((type) => {
                        if (type.qty > 0) {
                            selected = true;
                            return;
                        }
                    });

                    if (selected) {
                        return x.types;
                    }
                })
                .filter(Boolean)[0];

            if (selectedData && !selectedData.length) {
                selectedData = pos.variants[0].types;
            }

            // create variant property
            const variantProp = {};
            initMostVariantValue.forEach((variant, variantIdx) => {
                variantProp[`variant${variantIdx}`] =
                    selectedData?.[variantIdx]?.qty ?? 0;
            });

            return { ...pos, ...variantProp };
        });

        setVariantSort((prev) => {
            initMostVariantValue.forEach((v, i) => {
                prev[`variant-${i}`] = {
                    sort_type: null,
                };
            });

            return { ...prev };
        });
        setMostVariantValue(initMostVariantValue);
        setRows(initRows);
        setSort((prev) => {
            prev.variant.colspan = initMostVariantValue.length;
            return { ...prev };
        });
        setTotalVariant(totalVariant);
    }, [data]);

    // console.clear();
    // console.log(data);
    // console.log({ rows, mostVariantValue, variantSort, sort });

    return (
        <div>
            {data.pos.length < 1 ? (
                <NoReport />
            ) : (
                <>
                    <Helmet>
                        <style type='text/css'>
                            {`@page {size: landscape}`}
                        </style>
                    </Helmet>

                    <div className='print-report zoom-60'>
                        <KopSurat />
                        <p style={{ marginTop: '40px' }} className='asdasd'>
                            POS Sales Report{' '}
                            <span>Showing POS report from all branches</span>
                        </p>
                        <div className='report-summary-box mb-16'>
                            <div className='item teal'>
                                <p>
                                    <span>Net Value</span>
                                    <span>{money(data.net_income)}</span>
                                </p>
                            </div>
                            <div className='item'>
                                <p>
                                    <span>Total Qty Sales</span>
                                    <span>{data.total_sales}</span>
                                </p>
                            </div>
                            <div className='item'>
                                <p>
                                    <span>Discount</span>
                                    <span>
                                        IDR {money(data.total_discount)}
                                    </span>
                                </p>
                            </div>
                            <div className='item'>
                                <p>
                                    <span>total</span>
                                    <span>IDR {money(data.grand_total)}</span>
                                </p>
                            </div>
                            <div className='item'>
                                <p>
                                    <span>HPP</span>
                                    <span>IDR {money(data.hpp)}</span>
                                </p>
                            </div>
                            <div className='item'>
                                <p>
                                    <span>Other Cost</span>
                                    <span>
                                        IDR {money(data.additional_cost)}
                                    </span>
                                </p>
                            </div>
                        </div>

                        <NoPrint force={true}>
                            <div className='table-menu'>
                                <div className='left'>
                                    <button onClick={toggleScroll}>
                                        {scroll ? 'Remove' : 'Show'} Scroll
                                    </button>
                                </div>
                                <div className='right'>
                                    <p className='note'>
                                        *Data currency shown in IDR (Indonesian
                                        Rupiah){' '}
                                    </p>
                                </div>
                            </div>
                        </NoPrint>

                        <SimpleBarHOC status={scroll}>
                            <table className='table-report pos-product-general'>
                                <thead>
                                    <tr>
                                        {Object.keys(sort).map((key, idx) => (
                                            <ThSort
                                                key={`th-${idx}`}
                                                label={sort[key].label}
                                                colspan={sort[key].colspan}
                                                sort={sort[key].has_sort}
                                                handleClick={() =>
                                                    sortColumn(key)
                                                }
                                                className={`${sort[key].className}  ${sort[key].sort_type}`}
                                            />
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.pos[0].variants.map(
                                        (variant, variantIdx) => (
                                            <tr
                                                key={variantIdx}
                                                className={`sticky-variant-row-${
                                                    variantIdx + 1
                                                }`}>
                                                <td colSpan={5}></td>
                                                {mostVariantValue.map(
                                                    (n, nIdx) => (
                                                        <VariantSort
                                                            key={nIdx}
                                                            handleClick={() =>
                                                                sortColumn(
                                                                    `variant${nIdx}`,
                                                                    nIdx
                                                                )
                                                            }
                                                            sort={true}
                                                            label={
                                                                <span
                                                                    style={{
                                                                        display:
                                                                            'inline-block',
                                                                        textAlign:
                                                                            'center',
                                                                    }}>
                                                                    {variant
                                                                        ?.types?.[
                                                                        nIdx
                                                                    ]?.label ??
                                                                        '-'}
                                                                    <br />
                                                                    {totalVariant[
                                                                        variant
                                                                            .types?.[
                                                                            nIdx
                                                                        ]?.label
                                                                    ] ? (
                                                                        <span>
                                                                            (
                                                                            {
                                                                                totalVariant[
                                                                                    variant
                                                                                        .types?.[
                                                                                        nIdx
                                                                                    ]
                                                                                        ?.label
                                                                                ]
                                                                            }
                                                                            )
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            &nbsp;
                                                                        </>
                                                                    )}
                                                                </span>
                                                            }
                                                            className={`${
                                                                nIdx % 2 === 0
                                                                    ? 'aa'
                                                                    : 'bb'
                                                            } td-highlight ${
                                                                variantSort[
                                                                    `variant-${nIdx}`
                                                                ].sort_type
                                                            }`}
                                                        />
                                                    )
                                                )}
                                                <td colSpan={9}></td>
                                            </tr>
                                        )
                                    )}

                                    {rows?.map((pos, posIdx) => {
                                        return (
                                            <tr key={posIdx}>
                                                <td>{posIdx + 1}. </td>
                                                <td className='td-width-as-content mw-100'>
                                                    {pos.product_code}
                                                </td>
                                                <td className='td-width-as-content mw-100'>
                                                    {pos.category || '-'}
                                                </td>
                                                <td className='td-width-as-content mw-100'>
                                                    {pos.product_name}
                                                </td>
                                                <td className='mw-100'>
                                                    {pos.brand_name}
                                                </td>
                                                {mostVariantValue.map(
                                                    (row, rowIdx) => (
                                                        <td
                                                            key={rowIdx}
                                                            className={
                                                                rowIdx % 2 === 0
                                                                    ? 'aa'
                                                                    : 'bb'
                                                            }>
                                                            {
                                                                pos[
                                                                    `variant${rowIdx}`
                                                                ]
                                                            }
                                                        </td>
                                                    )
                                                )}
                                                <td className='td-text-center mw-70'>
                                                    {pos.total_qty}
                                                </td>
                                                <td>{pos.uom}</td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.sales_price)}
                                                </td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.sub_total)}
                                                </td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.total_discount)}
                                                </td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.grand_total)}
                                                </td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.hpp)}
                                                </td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.additional_cost)}
                                                </td>
                                                <td className='td-currency text-right'>
                                                    {money(pos.net_income)}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </SimpleBarHOC>
                    </div>
                </>
            )}
        </div>
    );
}
