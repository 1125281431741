import React, { useState, useEffect } from 'react'
import { money, globalMessage, globalDialog } from '@/Helper'
import InputNumber from 'Elements/inputnumber/InputNumber';
import ButtonIcon from 'Elements/ButtonIcon'
import SelectData from 'Elements/SelectData';
import Input from 'Elements/Input';
import Select from 'Elements/select/Select'
import { v4 as uuidv4 } from 'uuid';
import ScanProduct from 'Elements/ScanProduct'
import SelectAsync from 'Elements/SelectAsync';
import ReactSelect from 'react-select'



export default function ProductManager(props) {
    const { getData: sendProducts, defaultData, dataErrStatus } = props;
    const [products, setProducts] = useState([{ key: uuidv4() }])
    const [isLoading, setLoading] = useState(true)
    const [isEditable, setEditable] = useState(false)
    // add product
    const addProduct = () => {
        setProducts([...products, { key: uuidv4() }]);
    }
    // remove product
    const removeProduct = id => {
        globalDialog(() => {
            setProducts(products.filter((product) => product.key !== id))
        })
    }
    // INPUT CHANGE : U O M
    const setUom = (index, val) => {
        setProducts(state => {
            return state.map((s, i) => {
                s.uom_id = i === index ? +val : +s.uom_id
                return s;
            })
        })
    }

    const setReason = (params) => {
        const { value, index } = params;
        setProducts(state => {
            state[index].reason = value;
            return [...state];
        })
    }
    // INPUT QTY
    const setQty = (val, product) => {
        const { code } = product;
        setProducts(state => {
            return state.map(s => {
                if (s.code === code) {
                    s.qty = +val;
                }
                return s;
            });
        })
    }
    // effect product deliv
    useEffect(() => {
        /* ngirim data ke parent sendProducts : props by alias */
        sendProducts(products)
        if (defaultData && defaultData.length === products.length) {
            setLoading(false)
        }
    }, [products])

    // masang data default dari edit
    useEffect(() => {
        if (defaultData) {
            setEditable(true)

            let defaultDataProducts = []

            defaultData.forEach((product) => {
                const { id, sales_price, uom_id, name, code, product_variant_item, qty, color_name, reject_reason, variant_type_value } = product

                defaultDataProducts.push({
                    id,
                    sales_price,
                    uom_id,
                    name,
                    code,
                    product_variant_item,
                    variant_type_value,
                    qty: product_variant_item.length ? product_variant_item.map(prod => prod.qty)[0] : qty,
                    color_name,
                    reject_reason,
                    key: uuidv4()
                })
            })

            setProducts(defaultDataProducts)
        }
    }, [defaultData])
    /* ================================= */
    const selectProduct = ({ row }, index) => {
        if (row) {
            const isDuplicate = products.find(product => product.code === row.code)
            if (isDuplicate) {
                globalMessage('Product has been added already', 'warning')
                setProducts(state => {
                    return state.filter((_, i) => i !== index)
                });
            } else {
                setProducts(state => {
                    state[index] = { ...row, key: state[index].key };
                    state[index].qty = 0;
                    state[index].product_location = "stock"
                    return [...state];
                });
            }
        }
    }

    const handleScannerProduct = ({ master_product }) => {
        if (master_product.status) {
            const isFound = products.find(product => product.code === master_product.data[0]?.code)
            let data = master_product.data[0]
            if (master_product.data.length) {
                if (isFound) {
                    const updatedQty = products.map(product => {
                        if (product.code === isFound.code) {
                            product.qty = (product.qty || 0) + 1
                        }
                        return product;
                    })
                    return updatedQty //kirim data ke actionScannerProduct
                } else {
                    data.qty = 1;
                    data.product_location = "stock"
                    if (products.length === 1 && !products[0].code) {
                        return ([{ ...data, key: uuidv4() }])
                    } else {
                        return ([...products, { ...data, key: uuidv4() }])
                    }
                }
            } else {
                dataErrStatus(true)
                return products
            }
        } else {
            dataErrStatus(true)
            return products
        }
    }
    const actionScannerProduct = (handle) => {
        setProducts(handle)
    }

    return (
        <div className="table-product style-2">
            <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} type={"delivery"} />
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Product Name</th>
                        <th>Product Code</th>
                        <th>Reject Qty</th>
                        <th>UoM</th>
                        <th>Price</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (isLoading && isEditable) ? <tr><td colSpan="7"><h1 style={{textAlign:'center'}}>Loading...</h1></td></tr> :
                        products.map((product, productIdx) => {
                            const { sales_price, id, name, code, product_variant_item, uom_id, color_name, reject_reason, qty, variant_type_value } = product;
                            const hasVariant = product_variant_item && product_variant_item.length;
                            const productName =  name ?
                                 {
                                     label: `${name} ${color_name ? `- ${color_name}`: ''} ${variant_type_value ? `(${variant_type_value})` : ''}`,
                                     value: code
                                 } : null
                            const barcode = hasVariant ? product_variant_item.map(prod => prod.barcode) : code
                            const reason = hasVariant ? product_variant_item[0].reject_reason : reject_reason;
                            let finalQty = 0;
                            if (hasVariant) {
                                finalQty = product_variant_item[0].qty
                            } else {
                                finalQty = qty
                            }

                            return (
                                <tr key={product.key}>
                                    <td className="width-120">
                                        <div>
                                            {(productIdx > 0 || products.length > 1) && <ButtonIcon onClick={() => removeProduct(product.key)} icon="trash" theme="grey" />}
                                            {(productIdx === products.length - 1) && <ButtonIcon onClick={addProduct} icon="plus" theme="teal" />}
                                        </div>
                                    </td>
                                    <td className="width-250">
                                        {
                                            <SelectAsync
                                                type="productName"
                                                portal={true}
                                                name={product.key}
                                                cache={false}
                                                index={productIdx}
                                                options='productName'
                                                value={productName}
                                                handleChange={(x) => selectProduct(x, productIdx)}
                                                param={{ flat: true }}
                                                />
                                        }
                                    </td>
                                    <td className="width-160">
                                        {barcode}
                                    </td>
                                    <td className="qtyInput width-as-content">
                                        {
                                            <InputNumber
                                                withFieldContainer
                                                value={finalQty}
                                                handleChange={(val) => setQty(val, product)}
                                            />
                                        }
                                    </td>
                                    <td className="width-130">
                                        {
                                            // <SelectData handleChange={(val) => setUom(productIdx, val)} value={uom_id} options="uom" name={`uom-${productIdx}`} />
                                            <ReactSelect 
                                                label="UoM"
                                                options={{label: 'Pieces', value: 1}}
                                                value={{ label: 'Pieces', value: 1}}
                                                name={`uom-${productIdx}`}
                                                isDisabled={true}
                                            />
                                        }
                                    </td>
                                    <td className="text-left width-160">
                                        {sales_price ? `IDR ${money(sales_price)}` : '-'}
                                    </td>
                                    <td className="width-200">
                                        {code ? <Input value={reason || ''} handleChange={(val) => setReason({ value: val, index: productIdx })} name={`something${product.key}`} /> : '-'}
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}