import React, { useRef } from 'react'
import Logo from 'Img/logo-black.png'
import { useSelector, useDispatch } from 'react-redux';
import { resetStatusProfile, updateLogo } from 'Reduxes/configuration/profile/profileActions'
import Alert from 'Comps/elements/Alert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ContentWrapper from 'Comps/container/ContentWrapper'
import LoadingContent from 'Elements/LoadingContent';
import { reqPost, hostUrl } from 'Configs/request'
import Permission from '../../../comps/permission/Permission'

const TableData = ({ showForm }) => {
    const { data, loading, success } = useSelector(state => state.profileReducer);
    const dispatch = useDispatch();
    const inputLogo = useRef(null)

    const changeLogo = e => {
        inputLogo.current.click();
    }

    const uploadNewLogo = async e => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('filename', file)
        const req = await reqPost(`${hostUrl}/api/company/uploadImage`, formData);
        const urlNewImage = `${req.data.logo_url}`;
        dispatch(updateLogo(urlNewImage));
    }

    const urlFilter = function (url) {
        return String(url).includes("http") ? url : hostUrl.concat('/' + url);
    }

    return (
        <ContentWrapper
            title="Company Profile"
            subtitle="Keep your company profile data stay up to date"
            buttons={[
                {
                    label: 'Update Profile',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => showForm('Edit'),
                    subMenu: 'profile',
                    type: 'update',
                }
            ]}
        >

            <div className="hidden">
                <input type="file" ref={inputLogo} onChange={uploadNewLogo} accept="image/*" />
            </div>

            {
                success &&
                <Alert hide={() => dispatch(resetStatusProfile())} option={{ type: 'success', message: success }} />
            }

            {
                loading ? <LoadingContent />
                    : (
                        <>
                            {
                                !data ?
                                    <Alert option={{ type: 'error', message: 'Company profile data unavailable' }} /> :
                                    <div className="profile">
                                        <div className="img">
                                            <img src={urlFilter(data.logo_url)} alt="logo" />
                                            <Permission
                                                component={
                                                    <p onClick={changeLogo}>Change Logo</p>
                                                }
                                                subMenu="profile"
                                                type="update"
                                            />
                                        </div>
                                        <div className="desc">
                                            <h1>{data.company_name}</h1>
                                            <p className="address">
                                                {
                                                    [data.address, data.city, data.province, data.country, data.postal_code].filter(Boolean).join(', ')
                                                }
                                            </p>
                                            <p className="other">
                                                <span>Phone : {data.phone || '-'}</span>
                                                <span>Email : {data.email}</span>
                                                <span>Website : {data.site_address || '-'}</span>
                                            </p>
                                        </div>
                                    </div>
                            }
                        </>
                    )
            }
        </ContentWrapper>
    )


}

export default TableData
