import React from 'react'
import PropTypes from "prop-types";

const PageTitle = ({title, description}) => {
    return (
        <div className="page-title">
            <h3>{title}<span> {description}</span></h3>
        </div>
    )
}

PageTitle.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
}

PageTitle.defaultProps = {
    title: '',
    description: '',
}

export default PageTitle