import React, { useState, useEffect } from 'react'
import { getAuthToken, globalDialog, money, scrollToTop } from '../../../Helper'
import { debounce } from 'lodash'

/** ICONS */
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

/** Components */
import WarningIcon from '@material-ui/icons/Warning'
import Permission from '../../../comps/permission/Permission'
import { globalTableStyle } from 'Configs/table'
import Alert from 'Comps/elements/Alert'
import MUIDataTable from 'mui-datatables'
import KopSurat from 'Elements/KopSurat'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { makeGetParam, customGetParam } from 'Configs/request.js'

/** Reject Redux */
import {
  deleteReject,
  resetStatusReject
} from 'Reduxes/inventory/reject/rejectActions'

import { useDispatch, useSelector } from 'react-redux'
import PrintProvider, { Print } from 'react-easy-print'
import CustomToolbar from '../../../comps/elements/CustomToolbar'
import { TableConfigOnDownload } from '../../../configs/on-download-table'
import { Chip, CircularProgress, Typography } from '@material-ui/core'
import RejectFilter from './RejectFilter'
import { CustomSortColumn } from '../../../utils/SortColumn'

const TableDataSSR = (props) => {
  const classes = globalTableStyle()

  const { error, success } = useSelector((state) => state.rejectReducer)

  const { 
    showDetail, 
    showForm,
    indexSetPage,
    indexSetRowsPerPage,
    indexSetFilters,
    indexSetParams,
    initialPage,
    initialRowsPerPage,
    initialFilters,
    initialParams
   } = props

  const dispatch = useDispatch()

  const handleDelete = (id) => {
    globalDialog(async () => {
      await dispatch(deleteReject(id))
      scrollToTop()
      fetchReject()
    })
  }
  const [page, setPage] = useState(initialPage ?? 0)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage ?? 10)
  const [count, setCount] = useState(0)
  const [data, setData] = useState()
  const [isLoading, setLoading] = useState(false)

  const hasInitialFilters = Object.keys(initialFilters)?.length > 0

  const initialFiltersValue = hasInitialFilters ? initialFilters : {
    branches: [],
    statuses: [],
    date_from: '',
    date_to: '',
    quantity_from: 0,
    quantity_to: 0
  }

  const [filters, setFilters] = useState(initialFiltersValue)
  const [params, setParams] = useState('')

  /** Fetch Reject */
  useEffect(() => {
    fetchReject()
  }, [])

  const fetchReject = () => {
    setLoading(true)

    if (hasInitialFilters && initialParams) {
      loadReject(page, rowsPerPage, initialParams).then((res) => {
        setData(res.data)
        setLoading(false)
        setCount(res.total)
      })
    } else {
      loadReject(page).then((res) => {
        setData(res.data)
        setLoading(false)
        setCount(res.total)
      })
    }
  }

  /** Fetch Reject
   * @param page integer
   * @param rowsPerPage integer
   * @param params Parameter URL
   */
  const loadReject = async (page, rowPerPage, params = null) => {
    if (!rowPerPage) rowPerPage = rowsPerPage
    
    return new Promise(async (resolve, rejeck) => {
      const auth = getAuthToken()
      const headers = {
        Authorization: auth,
        method: 'GET'
      }

      let query = `${process.env.PW_API_URL}/api/reject`

      if (params) {
        query += `?page=${page}&limit=${rowPerPage}${params}`
        const idxQuestionMarkParam = query.indexOf('?')
        query = query
          .split('')
          .map((str, idx) => {
            if (str === '?' && idx > idxQuestionMarkParam) {
              return '&'
            }
            return str
          })
          .join('')
      } else {
        query += `?page=${page}&limit=${rowPerPage}`
      }

      const reject = await fetch(query, {
        headers
      })
        .then((res) => res.json())
        .catch(({ message }) => rejeck({ message }))

      if (reject) {
        const total = reject.total
        const data = reject.data

        resolve({
          data,
          total
        })
      }

      rejeck()
    })
  }

  const changePage = (page, rowsPerPage, params = null) => {
    setLoading(true)

    loadReject(page, rowsPerPage, params).then((res) => {
      setTimeout(() => {
        setLoading(false)
        setPage(page)
        setData(res.data)
        setCount(res.total)
        setRowsPerPage(rowsPerPage)
        indexSetRowsPerPage(rowsPerPage)
        indexSetPage(page)
      }, 300)
    })
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'no',
      label: 'No.',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const tableState = tableMeta.tableState
          const { page, rowsPerPage } = tableState
          if (page >= 1) {
            const result = tableMeta.rowIndex + 1 + page * rowsPerPage
            return result
          }
          return tableMeta.rowIndex + 1
        }
      }
    },
    {
      name: 'date',
      label: 'Date',
      options: {
        filter: false
      }
    },
    {
      name: 'code',
      label: 'Code',
      options: {
        filter: false
      }
    },
    {
      name: 'branch_name',
      label: 'Branch',
      options: {
        filter: false
      }
    },
    {
      name: 'reference',
      label: 'Ref',
      options: {
        filter: false
      }
    },
    {
      name: 'total_qty',
      label: 'Qty',
      options: {
        customBodyRender: (value) => {
          return value || 0
        },
        filter: false
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return value === 'draft' ? (
            <label className={`label danger`}>Draft</label>
          ) : value === 'confirmed' ? (
            <label className={`label primary`}>Confirmed</label>
          ) : (
            <label className={`label success`}>Checked</label>
          )
        }
      }
    },
    {
      name: 'is_warning',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        print: false
      }
    },
    {
      name: 'created_by_contact_name',
      label: 'Created By'
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: 0,
            zIndex: 51
          }
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: '0',
            background: 'white',
            zIndex: 51
          }
        }),
        filter: false,
        print: false,
        filterType: 'checkbox',
        customBodyRender: (value, tableMeta, updateValue) => {
          const isWarning = tableMeta.rowData[8]
          const attrShow = {
            className: 'row-action',
            onClick: () => showDetail(value)
          }
          const attrEdit = {
            className: 'row-action',
            onClick: () => {
              showForm('Edit', value)
              scrollToTop()
            }
          }
          const attrDelete = {
            className: 'row-action',
            onClick: () => handleDelete([value])
          }

          return (
            <>
              {isWarning && <WarningIcon className="row-action red" />}
              <VisibilityIcon {...attrShow} />
              {tableMeta.rowData[7] === 'draft' && (
                <>
                  <Permission
                    component={<EditIcon {...attrEdit} />}
                    subMenu="reject"
                    type="update"
                  />
                  <Permission
                    component={<DeleteIcon {...attrDelete} />}
                    subMenu="reject"
                    type="delete"
                  />
                </>
              )}
            </>
          )
        }
      }
    }
  ]

  const handleSearch = debounce((keyword, props) => {
    setLoading(true)
    const params = makeGetParam({ q: keyword })
    loadReject(page, rowsPerPage, params).then((res) => {
      setData(res.data)
      setCount(res.total)
      setLoading(false)
    })
  }, 1000)

  const handleFilterSubmit = ({
    branches,
    date_from,
    date_to,
    statuses,
    quantity_from,
    quantity_to
  }) => {
    setLoading(true)
    let params = ''

    setFilters({
      branches,
      date_from,
      date_to,
      statuses,
      quantity_from,
      quantity_to
    })

    indexSetFilters({
      branches,
      date_from,
      date_to,
      statuses,
      quantity_from,
      quantity_to
    })

    if (branches?.length) {
      branches = serializeSelectValue(branches)
      params += makeGetParam({ branch_ids: branches })
    }

    if (statuses?.length) {
      statuses = serializeSelectValue(statuses)
      params += makeGetParam({ statuses })
    }

    if (date_from && date_to) {
      params += makeGetParam({ date_from, date_to })
    }

    if (quantity_from && quantity_to) {
      params += customGetParam({ quantity_from, quantity_to })
    }

    setParams(params)
    indexSetParams(params)

    setTimeout(() => {
      loadReject(0, 10, params).then((res) => {
        setData(res.data)
        setCount(res.total)
        setLoading(false)
        indexSetPage(0)
        indexSetRowsPerPage(10)
      })
    }, 300)
  }

  const serializeSelectValue = (data) => {
    return data.map((data) => data.value)
  }

  const onDeleteChip = (title) => {
    switch (title) {
      case 'Branch':
        filters.branches = []
        setFilters(filters)
        break
      case 'Status':
        filters.statuses = []
        setFilters(filters)
        break
      case 'Date':
        filters.date_from = ''
        filters.date_to = ''
        setFilters(filters)
        break
      case 'Type':
        filters.types = []
        setFilters(filters)
        break
      case 'Quantity':
        filters.quantity_from = 0
        filters.quantity_to = 0
        setFilters(filters)
      default:
    }

    handleFilterSubmit(filters)
  }

  /**
   * @param get label value from options  { value: 'val', label: 'Label' }
   * @returns label
   */
  const getChipLists = (data) => {
    return data.map(({ label }) => label)
  }

  /**
   *
   * @returns Component Chip
   */
  const RenderChipFilter = () => {
    /**
     * title: string
     * lists: (value for render) string[] | string
     */
    let keyProperty = []

    if (filters.branches?.length) {
      keyProperty.push({
        title: 'Branch',
        lists: getChipLists(filters.branches)
      })
    }

    if (filters.statuses?.length) {
      keyProperty.push({
        title: 'Status',
        lists: getChipLists(filters.statuses)
      })
    }

    if (filters.quantity_from && filters.quantity_to) {
      keyProperty.push({
        title: 'Quantity',
        lists: `${filters.quantity_from} - ${filters.quantity_to}`
      })
    }

    if (filters.date_from && filters.date_to) {
      keyProperty.push({
        title: 'Date',
        lists: `${filters.date_from} - ${filters.date_to}`
      })
    }

    return keyProperty.map(({ title, lists }) => (
      <>
        <Chip
          variant="outlined"
          color="secondary"
          label={`${title}: ${lists}`}
          key={title}
          onDelete={() => onDeleteChip(title)}
        />
      </>
    ))
  }

  const handleDeleteBatch = (ids) => {
    const triggerDelete = async (ids) => {
      await Promise.all(
        ids.map(async (id) => {
          await dispatch(deleteReject(id))
        })
      )
    }

    triggerDelete(ids)
    scrollToTop()
    setLoading(false)
  }

  const options = {
    // responsive: 'stacked' /* scroll */,
    responsive: false /* scroll */,
    rowsPerPage: rowsPerPage || 10,
    colIdPosition: 0,
    filename: 'Reject_Data',
    rowsPerPageOptions: [10, 25, 50],
    filter: true,
    serverSide: true,
    sort: true,
    print: false,
    count: count || 10,
    confirmFilters: true,
    page: page,
    customFilter: true,
    customFilterDialogFooter: () => (
      <RejectFilter
        handleFilterSubmit={handleFilterSubmit}
        isLoading={isLoading}
        stateFilters={filters}
      />
    ),
    customToolbar: () => <CustomToolbar showPrint={true} />,
    onSearchChange: (keyword) => {
      handleSearch(keyword)
    },
    onDownload: (buildHead, buildBody, columns, values) =>
      TableConfigOnDownload(
        'Reject_Data',
        buildHead,
        buildBody,
        columns,
        values
      ),
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        changePage(tableState.page, tableState.rowsPerPage, params)
      }
    },
    onRowsDelete: (e) => {
      const ids = e.data.map(({ index }) => index)

      const rejectIds = data
        .filter((val, idx) => ids.includes(idx))
        ?.map((data) => data.id)

      setLoading(true)
      handleDeleteBatch(rejectIds)
      fetchReject()
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true)

      const sortered = CustomSortColumn(changedColumn, direction, data)

      if (sortered.length) {
        setData(sortered)

        setTimeout(() => {
          setLoading(false)
        }, 300)
      }
    }
  }

  return (
    <PrintProvider>
      <Print single name="print-product-table-1">
        <KopSurat />
        <div className="spacer-60"></div>
        <ContentWrapper
          title="Data Rejects"
          subtitle="showing table of all data Reject"
          buttons={[
            {
              label: 'Add Reject',
              style: 'OrangeMd',
              icon: 'plus-circle',
              onClick: () => showForm('New'),
              type: 'create',
              subMenu: 'reject'
            }
          ]}
        >
          <>
            {success && (
              <Alert
                hide={() => dispatch(resetStatusReject())}
                option={{ type: 'success', message: success }}
              />
            )}
            {error && (
              <Alert
                hide={() => dispatch(resetStatusReject())}
                option={{ type: 'error', message: error }}
              />
            )}

            <Typography variant="h6">
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>

            {filters && <RenderChipFilter />}
            {
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
                className={classes.table}
              />
            }
          </>
        </ContentWrapper>
      </Print>
    </PrintProvider>
  )
}

export default TableDataSSR
