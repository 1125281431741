import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl, makeGetParam, reqDelIDS } from 'Configs/request'
export const USERCATEGORY_SET = 'USERCATEGORY_SET'
export const USERCATEGORY_ERROR = 'USERCATEGORY_ERROR'
export const USERCATEGORY_ADD = 'USERCATEGORY_ADD'
export const USERCATEGORY_SHOW = 'USERCATEGORY_SHOW'
export const USERCATEGORY_EDIT = 'USERCATEGORY_EDIT'
export const USERCATEGORY_UPDATE = 'USERCATEGORY_UPDATE'
export const USERCATEGORY_DELETE = 'USERCATEGORY_DELETE'
export const USERCATEGORY_LOADING = 'USERCATEGORY_LOADING'
export const USERCATEGORY_RESET = 'USERCATEGORY_RESET'
export const USERCATEGORYPERMISSION_ADD = 'USERCATEGORYPERMISSION_ADD'
export const USERCATEGORYPERMISSION_GET = 'USERCATEGORYPERMISSION_GET'
const type = "USERCATEGORY"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const loadUsercategory = () => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/user_category`),
        next: (payload) => dispatch({ type: USERCATEGORY_SET, payload })
    })
}

export const addUsercategory = data => async (dispatch, getState) => {

    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/user_category`, data),
        next: (payload) => dispatch({ type: USERCATEGORY_ADD, payload })
    })
}

export const updateUsercategory = (idEdit, data) => async (dispatch, getState) => {

    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/user_category/${idEdit}`, data),
        next: (payload) => dispatch({ type: USERCATEGORY_UPDATE, payload })
    })
}

export const deleteUsercategory = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/user_category`, id),
        next: () => dispatch({ type: USERCATEGORY_DELETE, payload: id })
    })
}

export const showUsercategory = idx => {
    return {
        type: USERCATEGORY_SHOW,
        payload: idx
    }
}

export const setEdit = idEdit => {
    return {
        type: USERCATEGORY_EDIT,
        payload: idEdit,
    }
}

export const resetStatusUsercategory = () => {
    return {
        type: USERCATEGORY_RESET
    }
}

export const addUsercategoryPermission = data => async (dispatch, getState) => {

    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/user_category/permission`, data),
        next: (payload) => dispatch({ type: USERCATEGORYPERMISSION_ADD, payload })
    })
}

/** Get user category permission only for current user */
export const getUsercategoryPermission = (data) => async (dispatch, getState) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/user_category/permission${makeGetParam(data)}`),
        next: (payload) => dispatch({ type: USERCATEGORYPERMISSION_GET, payload })
    })
}

/** Only get permission by user category without another action */
export const getPermission = (data) => async (dispatch, getState) => {

    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/user_category/permission${makeGetParam(data)}`),
    })
}

/** Only delete permission by user category without another action */
export const deletePermission = (data) => async (dispatch, getState) => {

    return handleRequest({
        type,
        dispatch,
        body: () => reqDelIDS(`${hostUrl}/api/user_category/permission`, data),
    })
}