import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormikContext } from 'formik';
import ScanProduct from 'Elements/ScanProduct'
import Select from 'Elements/select/Select';
import FieldsContainer from 'Elements/FieldsContainer'
import { globalTableStyle } from 'Configs/table'
import ThSort from 'Elements/ThSort'
import Input from 'Elements/Input';

/** 
     * ============
     * Table Columns
     * ============
     */
export const productOpnameColumns = {
    location: {
        label: 'Location',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    code: {
        label: 'Code',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    category_name: {
        label: 'Category',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    brand_name: {
        label: 'Brand',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    product_name: {
        label: 'Product Name',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    variant_type_value: {
        label: 'Variant',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    qty_stock_physic: {
        label: 'Qty',
        className: 'cell-width-as-content',
        has_sort: false,
    },
    qty_reject_physic: {
        label: 'Qty Reject',
        className: 'cell-width-as-content',
        has_sort: false,
    },
    uom_name: {
        label: 'UoM',
        className: 'cell-width-as-content',
        has_sort: false,
    },
}
/**
 * ============
 * End Table productOpnameColumns
 * ============
 */

export default function TableFormProduct(props) {
    const classes = globalTableStyle()

    const { rows, setRows, stockLocation, dataErrStatus } = props;
    const { setFieldValue, setValues, values } = useFormikContext();
    const [stateRow, setStateRow] = useState([]);
    const [sortType, setSortType] = useState('asc');
    const [qtyPhysic, setQtyPhysic] = useState(rows.map(r => r?.qty_physic || r?.qty_stock_physic || 0));
    const [qtyPhysicReject, setQtyPhysicReject] = useState(rows.map(r => r?.qty_reject_physic || r?.product_qty_physic || 0));
    const handleRemoveRow = idxRemove => {
        // setQtyPhysic(qtyPhysic.filter((_, idx) => idx !== idxRemove))
        // setQtyPhysicReject(qtyPhysicReject.filter((_, idx) => idx !== idxRemove))
        setQtyPhysic(state => {
            return state.filter((_, idx) => idx !== idxRemove)
        })
        setQtyPhysicReject(state => {
            return state.filter((_, idx) => idx !== idxRemove)
        })
        setStateRow(
            stateRow.filter((_, idx) => idx !== idxRemove)
        )
        setRows(state => {
            return state.filter((_, idx) => idx !== idxRemove)
        })
        const stock_opname_item = values.stock_opname_item.filter((_, idx) => idx !== idxRemove)
        setValues({
            ...values,
            stock_opname_item
        })
    }

    const handleQtyChange = (event, idx) => {
        if (!values.stock_opname_item) return

        const isFilterForm = filteredProducts?.length > 0 && filterProductName !== ''
        const quantity = parseInt(event.target.value) || 0;

        if (isFilterForm) updateQuantityWithFilterProductTable('qty_stock_physic', idx, quantity);
        else updateQuantityDefault(idx, 'qty_stock_physic', quantity);

    }
    const handleQtyRejectChange = (event, idx) => {
        if (!values.stock_opname_item) return

        const isFilterForm = filteredProducts?.length > 0 && filterProductName !== ''
        const quantity = parseInt(event.target.value) || 0;

        if (isFilterForm) updateQuantityWithFilterProductTable('qty_reject_physic', idx, quantity);
        else updateQuantityDefault(idx, 'qty_reject_physic', quantity);
    }

    /**
     * @param {'qty_stock_physic' | 'qty_reject_physic'} quantityType 
     * @param {number} idx
     * @param {number} value
     */
    const updateQuantityWithFilterProductTable = (quantityType, idx, val) => {
        const currentFilteredProduct = filteredProducts.find((_, index) => index === idx)

        // when data doesnt exist, return
        if (!currentFilteredProduct) return

        const originalProductIdx = stateRow.findIndex((product) => product.code === currentFilteredProduct.code)

        if (originalProductIdx < 0) return

        // set live time quantity on form table filtered products
        setFilteredProducts((prevValues) => {
            const idxToChange = prevValues.findIndex((_, index) => index === idx)
            const hasDataToChange = idxToChange >= 0
            if (hasDataToChange && quantityType === 'qty_stock_physic') {
                prevValues[idxToChange].custom_qty_stock_physic = val
            }
            if (hasDataToChange && quantityType === 'qty_reject_physic') {
                prevValues[idxToChange].custom_qty_reject_physic = val
            }
            return prevValues
        })


        // set quantity on original form table products and then assign it to field value for store data.
        if (quantityType === 'qty_stock_physic') {
            setQtyPhysic(prev => {
                prev[originalProductIdx] = val;
                return prev
            })
        } else if (quantityType === 'qty_reject_physic') {
            setQtyPhysicReject(prev => {
                prev[originalProductIdx] = val;
                return prev
            })
        }

        const stock_opname_item = values.stock_opname_item.map((soi, soiIdx) => {
            if (quantityType === 'qty_stock_physic') soi.qty_stock_physic = qtyPhysic[soiIdx];
            if (quantityType === 'qty_reject_physic') soi.qty_reject_physic = qtyPhysicReject[soiIdx];
            return soi;
        })
        setFieldValue('stock_opname_item', stock_opname_item)
    }

    /**
     * @param {'qty_stock_physic' | 'qty_reject_physic'} quantityType 
     * @param {number} idx
     * @param {number} value
     */
    const updateQuantityDefault = (idx, quantityType, val) => {
        let stockOpnameItem = null

        values.stock_opname_item.forEach((item, index) => {
            if (quantityType === 'qty_stock_physic') {
                if (!qtyPhysic[index]) {
                    setQtyPhysic(state => {
                        state[index] = 0
                        return state
                    })
                }
            } else if (quantityType === 'qty_reject_physic') {
                if (!qtyPhysicReject[index]) {
                    setQtyPhysicReject(state => {
                        state[index] = 0
                        return state
                    })
                }
            }
        })

        // set state quantity
        if (quantityType === 'qty_stock_physic') {
            setQtyPhysic(state => {
                state[idx] = val
                return state
            })
        } else if (quantityType === 'qty_reject_physic') {
            setQtyPhysicReject(prev => {
                prev[idx] = val;
                return prev
            })
        }

        // assign quantity to stock opname item
        stockOpnameItem = values.stock_opname_item.map((soi, soiIdx) => {
            if (quantityType === 'qty_stock_physic') soi.qty_stock_physic = qtyPhysic[soiIdx];
            if (quantityType === 'qty_reject_physic') soi.qty_reject_physic = qtyPhysicReject[soiIdx];
            return soi;
        })
        setFieldValue('stock_opname_item', stockOpnameItem)
    }

    useEffect(() => {
        let tempRows = [];
        const stock_opname_item = [];
        let physicLength = 0;
        rows.map((row, idx) => {
            const rowMustArray = Array.isArray(row) ? row : [row]
            rowMustArray.map(r => {
                let {
                    code,
                    category_name,
                    brand_name,
                    product_name,
                    variant_type_id,
                    variant_type_value,
                    uom_name,
                    uom_id,
                    id,
                    product_id,
                    category_id,
                    unit_of_measurement_id,
                    brand_id,
                    stock_on_hand,
                    reject,
                    qty_stock_physic,
                    qty_reject_physic,
                    qty_stock,
                    qty_reject
                } = r;
                tempRows.push({
                    location: stockLocation,
                    code,
                    category_name,
                    brand_name,
                    product_name,
                    variant_type_value,
                    uom_name,
                })
                stock_opname_item.push({
                    code,
                    product_id: id || product_id,
                    product_name,
                    category_id,
                    category_name,
                    brand_id,
                    brand_name,
                    uom_id: uom_id || unit_of_measurement_id,
                    uom_name,
                    variant_type_value: variant_type_value || null,
                    variant_type_id: variant_type_id || null,
                    ...((stockLocation === 'all_location' || stockLocation === 'stock') && {
                        qty_stock: stock_on_hand || qty_stock,
                        qty_stock_physic: qtyPhysic[idx] || qty_stock_physic
                    }),
                    ...((stockLocation === 'all_location' || stockLocation === 'reject') && {
                        qty_reject: reject || qty_reject,
                        qty_reject_physic: qtyPhysicReject[idx] || qty_reject_physic
                    }),
                })
                physicLength++;
            })
        })
        setFieldValue('stock_opname_item', stock_opname_item)
        setStateRow(tempRows);
    }, [rows])
    useEffect(() => {
        if (stockLocation === 'reject') {
            setOptionsQty('qty_reject')
        }
    }, [stockLocation])
    // handle scanner
    const handleScannerProduct = val => {
        if (values.stock_opname_item) {
            const stock_opname_item = values.stock_opname_item.map((soi, soiIdx) => {
                if (soi.code === val) {
                    if (optionsQTy === 'qty') {
                        if (isNaN(soi.qty_stock_physic)) {
                            soi.qty_stock_physic = 0
                        }
                        soi.qty_stock_physic += 1;
                        setQtyPhysic(prev => {
                            prev[soiIdx] = soi.qty_stock_physic;
                            return prev
                        })
                    } else if (optionsQTy === 'qty_reject') {
                        if (isNaN(soi.qty_reject_physic)) {
                            soi.qty_reject_physic = 0
                        }
                        soi.qty_reject_physic += 1;
                        setQtyPhysicReject(prev => {
                            prev[soiIdx] = soi.qty_reject_physic;
                            return prev
                        })
                    }
                }
                return soi;
            })
            return stock_opname_item
        } else {
            dataErrStatus(true)
            return []
        }
    }

    const actionScannerProduct = (handle) => {
        setFieldValue('stock_opname_item', handle)
    }
    //OPTIONS SCANNER
    const [optionsQTy, setOptionsQty] = useState("qty")
    const handleOptionsQty = ({ selected }) => {
        setOptionsQty(selected.value)
    }

    const customSort = (columnName) => {
        const executeSort = (datas) => {
            return datas.sort((prev, next) => {
                const prevValue = typeof prev === 'string' ? prev[columnName]?.split('') : prev[columnName]
                const nextValue = typeof next === 'string' ? next[columnName]?.split('') : next[columnName]
                switch (sortType) {
                    case 'asc':
                        if (prevValue > nextValue) return 1
                        if (prevValue < nextValue) return -1
                        return 0
                    case 'desc':
                        if (prevValue < nextValue) return 1
                        if (prevValue > nextValue) return -1
                        return 0
                }
            })
        }

        const getNewQuantities = (quantityType) => {
            const clonedSortedSoItems = [...sorted]

            return clonedSortedSoItems.map((product) => {
                const equalIdx = stateRow.findIndex((value) => product.code === value.code)

                if (equalIdx < 0) return 0

                switch (quantityType) {
                    case 'qty_stock_physic':
                        return currentQtyPhysics[equalIdx] ? currentQtyPhysics[equalIdx] : 0
                    case 'qty_reject_physic':
                        return currentQtyRejectPhysics[equalIdx] ? currentQtyRejectPhysics[equalIdx] : 0
                }
            })
        }

        const currentStateRows = [...stateRow]

        const sorted = executeSort(currentStateRows)

        let currentQtyPhysics = [...qtyPhysic]
        let currentQtyRejectPhysics = [...qtyPhysicReject]

        const newQtyRejectPhysics = getNewQuantities('qty_reject_physic')
        const newQtyPhysics = getNewQuantities('qty_stock_physic')

        const sortedSoItem = executeSort(values.stock_opname_item)

        setFieldValue('stock_opname_item', sortedSoItem)

        setSortType(sortType === 'asc' ? 'desc' : 'asc')

        setStateRow(sorted)
        setQtyPhysic(newQtyPhysics)
        setQtyPhysicReject(newQtyRejectPhysics)
    }

    const getTableHeaders = () => {
        const clonedColumns = JSON.parse(JSON.stringify(productOpnameColumns))

        const renderTableHeaders = (currentColumns) => (
            Object.keys(currentColumns).map((key, index) =>
                <ThSort
                    key={`th-${index}`}
                    label={currentColumns[key].label}
                    sort={currentColumns[key].has_sort}
                    handleClick={() => customSort(key)}
                    className={''}
                />
            )
        )

        if (stockLocation === 'stock') {
            delete clonedColumns.qty_reject_physic
        }

        if (stockLocation === 'reject') {
            delete clonedColumns.qty_stock_physic
        }

        return renderTableHeaders(clonedColumns)
    }

    /**
     * Search for filtering product on table products
     * Steps:
     * 1. If user filtering product, then it will save original products data into memory as originalProducts.
     * 2. Hide original form product and originalProducts, then show table filtered products.
     * 3. When user change the quantity, it also change the quantity on originalProducts.
     * 4. When user has finish on filtering and modifying quantity, then user must be click on button "Reset" to save the changes
     *    and revert form original products.
     */
    const [filteredProducts, setFilteredProducts] = useState([])
    const [filterProductName, setFilterProductName] = useState('')
    const onClickFilterProduct = () => {
        if (!filterProductName) return

        let currentProducts = [...stateRow];

        let keyword = filterProductName.split(' ').join('').split('-').join('').trim()
        currentProducts = currentProducts
            .map((product, idx) => {
                const formattedName = product?.product_name.split(' ').join('')
                const hasLikeName = formattedName.search(keyword)

                // handle when no name and no code smiliar to keyword, then return nothing.
                if (hasLikeName < 0) {
                    const formattedCode = product?.code.split('-').join('')
                    const hasLikeCode = formattedCode?.search(keyword)
                    if (hasLikeCode < 0) return
                }

                // case when edit, assign stock physic from data edit
                if (qtyPhysic[idx]) product.custom_qty_stock_physic = qtyPhysic[idx]
                // case when edit, assign reject physic from data edit
                if (qtyPhysicReject[idx]) product.custom_qty_reject_physic = qtyPhysicReject[idx]

                return product
            })?.filter(Boolean)

        setFilteredProducts([...currentProducts])
    }

    /**
     * 
     */
    const onResetFilterProduct = () => {
        const currents = [...stateRow]
        setFilterProductName('')
        // Reset filter products
        setFilteredProducts([])
        setStateRow(() => [...currents])
    }

    const stockOpnameProducts = filteredProducts?.length > 0 ? filteredProducts : stateRow
    const onFormFilterProduct = filteredProducts?.length > 0 && filterProductName !== ''
    return (
        <>
            {
                rows.length &&
                <div>
                    <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} noUseDisp={true} />
                    {
                        (stockLocation === 'all_location') && <FieldsContainer title="">
                            <Select
                                label="Scanner Options"
                                name="scanner"
                                defaultInputValue={"Quantity"}
                                getData={handleOptionsQty}
                                options={
                                    [
                                        { label: "Quantity", value: 'qty' },
                                        { label: "Quantity Reject", value: 'qty_reject' },
                                    ]
                                }
                            />
                        </FieldsContainer>
                    }
                    <FieldsContainer title="">
                        <Input
                            placeholder="Search product below"
                            handleChange={(value) =>
                                setFilterProductName(value.toUpperCase())
                            }
                            col={4}
                            name="filterProduct"
                            value={filterProductName}
                        />
                        <div style={{ marginTop: '7px' }}>
                            {
                                filterProductName !== '' && (
                                    <button
                                        type='button'
                                        className={'btn btn-rect btn-orange'}
                                        style={{ marginRight: '20px' }}
                                        onClick={() => onClickFilterProduct()}
                                    >
                                        Filter
                                    </button>
                                )
                            }
                            {
                                filterProductName !== '' && filteredProducts?.length > 0 && (
                                    <button
                                        type='button'
                                        className={'btn btn-rect btn-orange'}
                                        onClick={() => onResetFilterProduct()}
                                    >
                                        Reset
                                    </button>
                                )
                            }
                        </div>
                    </FieldsContainer>
                    <table className="table-form-2 no-padding">
                        <thead>
                            <tr>
                                {
                                    getTableHeaders()
                                }
                                <th className="action">Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                stockOpnameProducts.map((sr, srIdx) => {
                                    const qtyValue = onFormFilterProduct ? sr.custom_qty_stock_physic ?? 0 : (qtyPhysic[srIdx] ?? 0)
                                    const qtyRejectValue = onFormFilterProduct ? sr.custom_qty_reject_physic ?? 0 : (qtyPhysicReject[srIdx] ?? 0)
                                    return (
                                        <tr key={srIdx}>
                                            <td>{sr.location === 'all_location' ? 'All location' : sr.location}</td>
                                            <td>{sr.code}</td>
                                            <td>{sr.category_name}</td>
                                            <td>{sr.brand_name}</td>
                                            <td>{sr.product_name}</td>
                                            <td>{sr.variant_type_value}</td>
                                            {
                                                (stockLocation === 'all_location' || stockLocation === 'stock') &&
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={qtyValue}
                                                        className="input-on-table-row"
                                                        onChange={(e) => handleQtyChange(e, srIdx)}
                                                    />
                                                </td>
                                            }
                                            {
                                                (stockLocation === 'all_location' || stockLocation === 'reject') &&
                                                <td>
                                                    <input
                                                        type="text"
                                                        value={qtyRejectValue}
                                                        className="input-on-table-row"
                                                        onChange={(e) => handleQtyRejectChange(e, srIdx)}
                                                    />
                                                </td>
                                            }
                                            <td>{sr.uom_name}</td>
                                            <td className="action">
                                                <FontAwesomeIcon
                                                    icon="trash"
                                                    onClick={() => handleRemoveRow(srIdx)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}
