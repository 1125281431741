import {
    BRAND_LOADING,
    BRAND_SET,
    BRAND_ERROR,
    BRAND_ADD,
    BRAND_SHOW,
    BRAND_DELETE,
    BRAND_UPDATE,
    BRAND_RESET,
    BRAND_EDIT,
    BRAND_STOP_LOADING
} from './brandActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
    nomor: 1
}

const brandReducer = (state = initialState, { payload, type }) => {

    switch (type) {
        case BRAND_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case BRAND_LOADING:
            return {
                ...state,
                loading: true,
            }
        case BRAND_UPDATE:
            console.log(payload)
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case BRAND_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case BRAND_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New brand has been added successfully.'
            }
        case BRAND_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case BRAND_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case BRAND_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case BRAND_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case BRAND_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default brandReducer