import React from 'react'
import { default as ButtonMui } from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';


const renderIcon = (param) => {
    const { props, classes } = param;

    switch (props.icon) {
        case 'plus':
            return <AddIcon className={classes.icon} />
        default:
            return null;
    }
}

const useStyles = makeStyles((theme) => ({
    icon: {
        color: ({ theme: propTheme }) => {
            return propTheme && propTheme.includes('Grey') ? theme.pw.c5 : 'white'
        },
        marginRight: ({ label }) => label ? '6px' : 0,
    },
    button: {
        boxShadow: 'none',
        height: '44px',
        minWidth: 'auto',

        '&:hover': {
            boxShadow: 'none',
        }
    }
}))

export default function Button(props) {
    const { label, handleClick, icon, loading, disabled, type, theme } = props
    const classes = useStyles(props);
    const formik = useFormikContext();

    return (
        <ButtonMui
            type={type || 'button'}
            variant="contained"
            disabled={disabled || loading}
            className={classes.button}
            onClick={() => {
                handleClick && handleClick(formik || null)
            }}
        >
            {
                loading &&
                <CircularProgress className={classes.icon} color="magenta" size={16} thickness={5} />
            }
            {
                !loading && icon &&
                renderIcon({ props, classes })
            }
            {
                label || ''
            }
        </ButtonMui>
    )
}
