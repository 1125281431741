import {
    REJECT_LOADING,
    REJECT_SET,
    REJECT_STOP_LOADING,
    REJECT_ERROR,
    REJECT_ADD,
    REJECT_SHOW,
    REJECT_DELETE,
    REJECT_UPDATE,
    REJECT_RESET,
    REJECT_EDIT,
    REJECT_CONFIRM
} from './rejectActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const rejectReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case REJECT_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case REJECT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case REJECT_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case REJECT_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case REJECT_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New reject has been added successfully.'
            }
        case REJECT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case REJECT_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case REJECT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case REJECT_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case REJECT_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                // data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case REJECT_CONFIRM:

            let newData = state.data;

            newData = newData.map(x => {
                if (x.id === payload.id) {
                    x.status = payload.type
                }

                return x
            })

            return {
                ...state,
                data: newData

            }
        default:
            return state;
    }
}

export default rejectReducer