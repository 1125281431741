import { makeGetParam, handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
export const PRODUCT_SET = 'PRODUCT_SET'
export const PRODUCT_ERROR = 'PRODUCT_ERROR'
export const PRODUCT_ADD = 'PRODUCT_ADD'
export const PRODUCT_SHOW = 'PRODUCT_SHOW'
export const PRODUCT_EDIT = 'PRODUCT_EDIT'
export const PRODUCT_UPDATE = 'PRODUCT_UPDATE'
export const PRODUCT_DELETE = 'PRODUCT_DELETE'
export const PRODUCT_LOADING = 'PRODUCT_LOADING'
export const PRODUCT_RESET = 'PRODUCT_RESET'
export const PRODUCT_FILTER = 'PRODUCT_FILTER'
export const PRODUCT_BARCODE = 'PRODUCT_BARCODE'
export const PRODUCT_STOP_LOADING = 'PRODUCT_STOP_LOADING'
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
const type = "PRODUCT"
export const filterProduct = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        // body: () => fakeResp,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/product${makeGetParam(args)}`),
        next: () => dispatch({ type: PRODUCT_STOP_LOADING })
    })
}
export const getBarcode = (args) => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/product-generate-barcode`, args),
    })
}
export const loadProduct = () => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        // body: () => fakeResp,
        body: () => reqGet(`${hostUrl}/api/product`),
        next: (payload) => dispatch({ type: PRODUCT_SET, payload })
    })
}
export const addProduct = data => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/product`, data),
        next: (payload) => dispatch({ type: PRODUCT_ADD, payload })
    })
}
export const updateProduct = (idEdit, data) => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/product/${idEdit}`, data),
        next: (payload) => dispatch({ type: PRODUCT_UPDATE, payload })
    })
}
export const deleteProduct = id => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/product`, id),
        next: () => dispatch({ type: PRODUCT_DELETE, payload: id })
    })
}
export const showProduct = idx => {
    return {
        type: PRODUCT_SHOW,
        payload: idx
    }
}
export const setEditProduct = idEdit => {
    return {
        type: PRODUCT_EDIT,
        payload: idEdit,
    }
}
export const resetStatusProduct = () => {
    return {
        type: PRODUCT_RESET
    }
}

export const showProductByid = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/product/${idx}`),
    })
}
