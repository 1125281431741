import React, { useState, useEffect } from 'react'
import MultiSelect from "react-multi-select-component";
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux'
import { loadBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import { loadBranch } from 'Reduxes/configuration/branch/branchActions'
import { loadPromotion } from 'Reduxes/other/promotion/promotionActions'
import { filterContact } from 'Reduxes/contact/contactActions'
import { filterCategory } from 'Reduxes/inventory/configuration/category/categoryActions'
import { filterProduct } from 'Reduxes/inventory/product/productActions'



const useStyles = makeStyles((theme) => ({
    container: {
        ...theme.pw.form.fieldContainer
    },
    label: {
        ...theme.pw.form.label
    },
}))



export default function Multiselect(props) {

    const { options, label, name = '', hasSelectAll = false, selectAllLabel = 'Select All', getData, param = {}, isFilter, defaultValues = null} = props;
    const classes = useStyles();
    const [selected, setSelected] = useState([]);
    const [renderedOptions, setRenderedOptions] = useState([]);
    const dispatch = useDispatch();

    const handleChange = (val) => {
        setSelected(val)
    }

    useEffect(() => {
        const fetchOptions = async () => {
            let resp;
            let label = 'name'; // acuan label yg ada di response
            let value = 'id';// acuan ID yg ada di response

            if (options === 'brand') {
                resp = await dispatch(loadBrand());
            } else if (options === 'branch') {
                if (param && param.all) {
                    resp = await dispatch(loadBranch(true));    
                } else {
                    resp = await dispatch(loadBranch());
                }
                label = 'branch_name';
            } else if (options === 'promotion') {
                resp = await dispatch(loadPromotion());
            } else if (options === 'contact') {
                resp = await dispatch(filterContact(param))
            } else if (options === "category") {
                resp = await dispatch(filterCategory(param))
            } else if (options === "product_type") {
                resp = await dispatch(filterProduct(param))
            }

            if (resp) {
                if (resp.status) { //fetching good
                    setRenderedOptions(resp.data.map(d => ({
                        label: d[label], value: d[value]
                    })));
                } else { // fetching error
                    setRenderedOptions([{ label: 'error fetching options', value: '' }])
                }
            }

        }

        if (Array.isArray(options)) {
            setRenderedOptions(options);
        } else {
            fetchOptions();
        }
    }, [])

    useEffect(() => {
        getData && getData({
            value: selected,
            isAll: selected.length === renderedOptions.length,
            props,
        })
    }, [selected])

    return (
        <div className={classes.container}>
            { label && <label className={classes.label} htmlFor={name || ''}>{label}</label>}

            <MultiSelect
                hasSelectAll={hasSelectAll}
                options={renderedOptions}
                value={defaultValues ?? selected}
                selectAllLabel={selectAllLabel}
                onChange={handleChange}
                labelledBy={"Select"}
                def
                overrideStrings={{
                    "allItemsAreSelected": `All ${label} are selected`,
                    "selectSomeItems": `Select ${label}`,

                }}
            />
        </div>
    )
}
