import React from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { globalDialog } from '../../../Helper';

const TableForm = (props) => {
    const { data, setVariantList } = props;

    return (
        <div className="table-product style-2 variant">
            <table className="">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Variant</th>
                        <th>Value</th>
                        <th>ID</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data.map((x, y) => (
                            <tr key={y}>
                                <td>{y + 1}</td>
                                <td>{x.variant_name}</td>
                                <td>{x.subvariant_value}</td>
                                <td>{x.barcode}</td>
                                <td>
                                    {
                                        <DeleteIcon onClick={() => {
                                            globalDialog(() => {
                                                setVariantList(data.filter((a, b) => b !== y))
                                            })
                                        }} />
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

export default TableForm
