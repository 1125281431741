import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Button from 'Elements/Button'

export default function TableFormCategory({ rows, removeCategory, generateProductByCat }) {
    const [loading, setLoading] = useState(false);
    const generate = (formik) => {
        const { setFieldValue } = formik;
        generateProductByCat()
        setFieldValue('stock_opname_category', rows.map(r => ({
            category_id: r.id,
            category_name: r.name,
        })))
        setLoading(true);
    }

    return (
        <table className="table-form-2 category-stockopname">
            <thead>
                <tr>
                    <th>Category Name</th>
                    <th className="right">Action</th>
                </tr>
            </thead>
            <tbody>
                {
                    rows.map((row, rowIdx) => (
                        <tr key={rowIdx}>
                            <td>{row?.name}</td>
                            <td className="right">
                                <FontAwesomeIcon
                                    icon="trash"
                                    onClick={() => removeCategory(rowIdx)}
                                />
                            </td>
                        </tr>
                    ))
                }
                <tr>
                    <td colSpan="2" className="td-button">
                        <Button
                            label={loading ? 'Generating...' : 'Generate Product'}
                            disabled={loading}
                            onClick={generate}
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    )
}
