import React from 'react'
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { scrollToTop, fieldsToFormik } from '@/Helper'
import { addVariant, resetStatusVariant } from 'Reduxes/inventory/configuration/variant/variantActions'
import Alert from 'Comps/elements/Alert'
import { validationSchema } from './validation'
import { fieldGroup } from './Form'
import Input from 'Elements/Input';
import InputChip from 'Elements/InputChip';
import SelectData from 'Elements/SelectData';
import FieldsContainer from 'Elements/FieldsContainer'
import FormButton from 'Elements/FormButton'

const FormNew = (props) => {

    const { goToIndex } = props;
    const { error } = useSelector(state => state.variantReducer)
    const dispatch = useDispatch();
    const formOpts = {
        initialValues: fieldsToFormik(fieldGroup),
        // validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const types = values.variant_value.map(a => ({ value: a.trim() }));

            const cleanVal = {
                "code": values.code,
                "name": values.name,
                "status": values.status === 'true',
                types
            }

            let result = await dispatch(addVariant(cleanVal))

            if (result) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusVariant())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts}>
                {(formik) => {
                    const { isSubmitting } = formik;
                    return (
                        <Form className="form-layout-1">
                            <FieldsContainer title="Variant Information">
                                <Input name="code" label="Code" uppercasing />
                                <Input name="name" label="Name" />
                                <SelectData name="status" label="Status" options={[
                                    { value: 'true', label: 'active' },
                                    { value: 'false', label: 'inactive' },
                                ]} />
                                <InputChip
                                    name="variant_value"
                                    col="3"
                                    label="Variant Size"
                                />
                            </FieldsContainer>
                            <br />
                            <FormButton isSubmitting={isSubmitting} showTable={goToIndex} />
                        </Form>
                    )
                }}

            </Formik>
        </>
    )
}

export default FormNew