import React from 'react'
import ScannerDetection from "Lib/scanner-detection";
import {
    filterProduct
} from 'Reduxes/inventory/product/productActions'
import {
    scrollToTop,
    getLocalStorage,
    HandleScannerProduct
} from '@/Helper'
import {
    useDispatch,
    useSelector
} from 'react-redux';

export default function ScanProduct({
    handle,
    action,
    noUseDisp,
    type
}) {

    const dispatch = useDispatch();
    const branchData = getLocalStorage();

    const onComplete = async function (code) {

        if (noUseDisp) {
            action(handle(code))
        } else {
            if (!type) {
                const productData = await dispatch(filterProduct({
                    code,
                }))
                
                action(handle(productData))

            }
            else if(type==='pos'){
                const productData = await dispatch(filterProduct({
                    code,
                    // branch_id: branchData.branchLogin.id,
                }))
                action(handle(productData))
            }
            else if (type === 'reject') {

                const productData = await dispatch(filterProduct({
                    code,
                    // branch_id: branchData.branchLogin.id,
                }))


                if (productData.status) {
                    if (productData.data.length) {
                        
                        const product_code = productData.data[0].product_code;

                        const master_product = await dispatch(filterProduct({
                            code: product_code,
                            flat: 'false'
                        }))


                        action(handle({
                            master_product: master_product,
                            barcode: code,
                        }))

                    } else {
                        action(handle({
                            master_product: productData,
                            barcode: code
                        }))
                    }
                } else {
                    action(handle({
                        master_product: productData,
                        barcode: code
                    }))
                }

            }
            else if(type === "receipt"){
                const productData = await dispatch(filterProduct({
                    code,
                    // branch_id: branchData.branchLogin.id,
                }))

                action(handle({
                    master_product : productData,
                    barcode:code
                }))

            } else if(type === "delivery"){
                const productData = await dispatch(filterProduct({
                    code,
                    branch_id: branchData.branchLogin.id,
                }))

                action(handle({
                    master_product : productData,
                    barcode:code
                }))

            }
        }
    };

    const onReceive = function(e){
        e.target.blur()
    }

    new ScannerDetection({
        onComplete, 
        onReceive
    });


    return (
        <div ></div>
    )
}
