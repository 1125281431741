import {
    BRANCH_LOADING,
    BRANCH_SET,
    BRANCH_ERROR,
    BRANCH_ADD,
    BRANCH_SHOW,
    BRANCH_DELETE,
    BRANCH_UPDATE,
    BRANCH_RESET,
    BRANCH_EDIT,
} from './branchActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
}

const branchReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case BRANCH_LOADING:
            return {
                ...state,
                loading: true,
            }
        case BRANCH_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case BRANCH_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case BRANCH_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New branch has been added successfully.'
            }
        case BRANCH_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case BRANCH_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case BRANCH_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case BRANCH_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case BRANCH_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default branchReducer