import React, { useEffect, useState } from 'react'
import { resetStatusReject, addReject, showReject, updateReject } from 'Reduxes/inventory/reject/rejectActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { datePost, scrollToTop, globalMessage, getLocalStorage, getLocalStorageAuth } from '@/Helper'
import Input from 'Elements/Input';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import SelectData from 'Elements/SelectData';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import ProductManager from './ProductManager'

function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const { type, showTable, rowId } = props;
    const dispatch = useDispatch();
    const [isReady] = useState(true)
    const [defaultData, setDefaultData] = useState(null) // untuk update
    const { error } = useSelector(state => state.rejectReducer)
    const [initialValues, setInitialValues] = useState({
        branch_id: '',
        date: datePost(new Date()),
        reference: '',
        barcode: '',
        notes: '',
        products: [],
        product_id: '',
        variant_type_id: '',
        unit_of_measurement_id: '',
        price: '',
        qty: 5,
        reason: '',
    })

    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const { notes, branch_id, date, reference } = values;
            const products = [];
            let isRejectAble = true;
            productReject.forEach(product => {
                const sameVal = {
                    unit_of_measurement_id: +product.uom_id,
                    price: product.sales_price,
                    product_id: product.id,
                }
                const qtyReject = product.qty || product.reject_qty || 0;
                if (qtyReject > 0) {
                    products.push({
                        ...sameVal,
                        qty: qtyReject,
                        variant_type_id: product.variant_type_id || product.product_variant_item.map(prod => prod.variant_type_id)[0],
                        reason: product.reason || product.reject_reason || product?.product_variant_item?.map(prod => prod.reject_reason)[0]
                    })
                } else {
                    isRejectAble = false
                }
            })
            const cleanVal = {
                branch_id: +branch_id,
                date,
                reference,
                notes,
                products
            }
            if (isRejectAble) {
                let result = type === 'new' ?
                    await dispatch(addReject(cleanVal))
                    : await dispatch(updateReject(rowId, cleanVal))
                if (result) {
                    resetForm();
                    showTable();
                }
                setSubmitting(false)
                scrollToTop()
            } else {
                globalMessage('Qty must greater than 0', 'warning')
            }
        }
    }

    /* 
    untuk menampung daftar product yg akan di reject (ditampilkan ke table), 
    dibuat default {} agar bia dilooping untuk menampilkan 1 row pada table nya
    */
    const [productReject, setProductReject] = useState([]);
    // jemput data dari product manager
    const getData = products => {
        setProductReject(products);
    }

    useEffect(() => {
        const fetchDataEdit = async () => {
            const resp = await dispatch(showReject(rowId))
            const { branch_id, products, notes, reference, date } = resp.data;
            setDefaultData(products)
            setInitialValues({
                branch_id,
                date,
                reference,
                notes,

            })
        }
        if (type === 'edit') {
            fetchDataEdit();
        }
    }, [])

    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Reject" : "Update data reject"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data reject`}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusReject())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {
                                    const { isSubmitting } = formik;
                                    return (
                                        <Form className="form-layout-1">
                                            <FieldsContainer title="Reject Information">
                                                <SelectData
                                                    label="Branch"
                                                    name="branch_id"
                                                    options="branch"
                                                    value={getLocalStorage().branchLogin.id}
                                                    {...((getLocalStorageAuth().user_category_id !== 1 && getLocalStorageAuth().user_category_id !== 2) ? { isDisabled: true } : {})}

                                                />
                                                <Datepicker name="date" label="Date" />
                                                <Input name="reference" label="Reference" />
                                            </FieldsContainer>
                                            <ProductManager defaultData={defaultData} getData={getData} dataErrStatus={setDataErr} />
                                            <Textarea name="notes" label="Notes" col="6" />
                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </>
                    )
                        : <LoadingContent />
                }
            </>
        </ContentWrapper >
    )
}

export default FormElement