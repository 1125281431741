import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl, handleRequest } from 'Configs/request'
export const PROMOTION_CONFIGURATION_SET = 'PROMOTION_CONFIGURATION_SET'
export const PROMOTION_CONFIGURATION_ERROR = 'PROMOTION_CONFIGURATION_ERROR'
export const PROMOTION_CONFIGURATION_ADD = 'PROMOTION_CONFIGURATION_ADD'
export const PROMOTION_CONFIGURATION_SHOW = 'PROMOTION_CONFIGURATION_SHOW'
export const PROMOTION_CONFIGURATION_EDIT = 'PROMOTION_CONFIGURATION_EDIT'
export const PROMOTION_CONFIGURATION_UPDATE = 'PROMOTION_CONFIGURATION_UPDATE'
export const PROMOTION_CONFIGURATION_DELETE = 'PROMOTION_CONFIGURATION_DELETE'
export const PROMOTION_CONFIGURATION_LOADING = 'PROMOTION_CONFIGURATION_LOADING'
export const PROMOTION_CONFIGURATION_RESET = 'PROMOTION_CONFIGURATION_RESET'
export const PROMOTION_CONFIGURATION_CONFIRM = 'PROMOTION_CONFIGURATION_CONFIRM'
export const PROMOTION_CONFIGURATION_STOP_LOADING = 'PROMOTION_CONFIGURATION_STOP_LOADING'
export const PROMOTION_CONFIGURATION_RESET_EDIT = 'PROMOTION_CONFIGURATION_STOP_LOADING'
const type = 'PROMOTION_CONFIGURATION'
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const addPromotionConfiguration = data => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/promotion`, data),
        next: (payload) => dispatch({
            type: PROMOTION_CONFIGURATION_ADD,
            payload
        })
    })
}
export const loadPromotionConfiguration = () => async (dispatch) => {
    const online = await isOnline()
    handleReq({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/promotion`),
        next: (payload) => dispatch({ type: PROMOTION_CONFIGURATION_SET, payload })
    })
}
export const updatePromotionConfiguration = (idEdit, data) => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/promotion/${idEdit}`, data),
        next: (payload) => dispatch({ type: PROMOTION_CONFIGURATION_UPDATE, payload })
    })
}
export const setEditPromotionConfiguration = idEdit => {
    return {
        type: PROMOTION_CONFIGURATION_EDIT,
        payload: idEdit,
    }
}
export const showPromotionConfiguration = idx => {
    return {
        type: PROMOTION_CONFIGURATION_SHOW,
        payload: idx
    }
}
export const deletePromotionConfiguration = id => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${online ? hostUrl : offlineUrl}/api/promotion/`, id),
        next: () => dispatch({ type: PROMOTION_CONFIGURATION_DELETE, payload: id })
    })
}
export const resetStatusPromotionConfiguration = () => {
    return {
        type: PROMOTION_CONFIGURATION_RESET
    }
}
/* 
    handle bug id 1 nyangkut ketika edit id 1 tapi ga dilanjutkan (cancel) 
*/
export const resetShowIfCancelEdit = () => {
    return {
        type: PROMOTION_CONFIGURATION_RESET_EDIT
    }
}
export const updateStatusPromotionConfiguration = (id, type) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/product_shipment/${id}/${type}`),
        next: () => dispatch({ type: PROMOTION_CONFIGURATION_CONFIRM, payload: { type, id } })
    })
}
export const showPromotionById = id => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/promotion/${id}`)
    })
}