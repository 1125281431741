import React, { useEffect, useState } from 'react'
import { Field, ErrorMessage, useFormikContext } from 'formik';

function Input(props) {
    const {
        name,
        label,
        type = 'text',
        disabled = false,
        readOnly = false,
        value = '',
        labelAsBaloon,
        placeholder,
        onSubmitValidation,
        handleChange: handleChangeFromParent,
        col,
        uppercasing } = props;

    const { setFieldValue, initialValues } = useFormikContext();
    const formik = useFormikContext()

    const [defaultValue, setDefaultValue] = useState(
        initialValues[name] ? initialValues[name] : ''
    )
    const handleChange = (e) => {
        const val = e.target.value;

        setFieldValue(name, val)
        setDefaultValue(val);

        if (handleChangeFromParent) {
            handleChangeFromParent(e.target.value);
        }
    }

    useEffect(() => {
        setDefaultValue(value)
    }, [value])

    useEffect(() => {
        if (initialValues[name]) {
            setDefaultValue(initialValues[name])
        }
    }, [initialValues])

    return (
        <div className={`field-container ${labelAsBaloon ? 'label-as-baloon ' : ''} ${col ? `span-${col}` : ``}`}>
            <label htmlFor={name}>{label}</label>
            <Field
                type={type}
                disabled={disabled}
                readOnly={readOnly}
                name={name}
                placeholder={placeholder}
                id={name}
                value={defaultValue}
                onChange={handleChange}
                style={uppercasing ? { textTransform: 'uppercase' } : null}
            />
            {
                formik.touched.is_user || onSubmitValidation ?
                    <p className="errMsg">{formik.errors[name]}</p>
                    :
                    <ErrorMessage name={name} component="div" className="errMsg" />
            }
        </div>
    )
}

export default Input
