import React, { useState, memo, useEffect } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { money } from '@/Helper'
import DetailRowChild from './DetailRowChild';


const GeneralRowParent = ({ data, showDetail: showDetailDefault, no, variants, index, data2 }) => {

    const [d, setD] = useState(data)
    const [showDetail, setShowDetail] = useState(showDetailDefault)
    const handleShowDetail = () => {
        setShowDetail(!showDetail)
    }

    useEffect(() => {
        setShowDetail(showDetailDefault)
    }, [showDetailDefault])

    useEffect(() => {
        setD(data)
        setShowDetail(false)
    }, [data, variants, data2])

    return (
        <>
            <tr>
                <td>{no}</td>
                <td className="cell-width-as-content cell-highlight">
                    <span className="trigger-show-detail" onClick={handleShowDetail}>
                        {d.code} {d.details.length > 0 && <KeyboardArrowDownIcon className="info-icon" />}
                    </span>
                </td>
                <td>{d?.category ?? 'N/a'}</td>
                <td className="cell-width-as-content">{d?.name ?? 'N/a'}</td>
                <td>{d.brand || 0}</td>
                <td>{money(d.sales_price) || 0}</td>
                {
                    variants.map((lv, lvIdx) => {
                        let qty = 0;
                        data2[index].variants.forEach(variant => {
                            qty += variant?.types?.[lvIdx]?.stock ?? 0;
                        })

                        return <td key={`tdvariant${lvIdx}`}>{qty}</td>
                    })
                }
                <td>{d.stock}</td>
                <td>{d.reject}</td>
                <td>{d.uom_name}</td>
                <td className="cell-currency cell-tr">{money(d.total_value)}</td>
            </tr>

            {showDetail && <DetailRowChild
                indexParent={no}
                index={index}
                variants={variants}
                details={d.details}
                data2={data2}
                product={data}
            />}

        </>
    )
}



export default GeneralRowParent