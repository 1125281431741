import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    user_category_name:
        Yup.string()
            .required('Category name is required')
            .max(30, 'Maximum input is 30 character')
            .matches(/^.*[a-zA-Z]+.*$/, 'You must input at 1 letter'),
})

