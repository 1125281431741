import React from 'react'
import { Formik, Form } from 'formik';
import Button from 'Elements/Button';
import { scrollToTop } from '@/Helper'
import Alert from 'Comps/elements/Alert'
import FieldsGroup from 'Elements/FieldsGroup'
import { validationSchema } from './validation'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { type, menus } from '../../../utils/MenuConfig';
import { resetStatusUsercategory, addUsercategory, addUsercategoryPermission } from 'Reduxes/usercategory/usercategoryActions'

const useStyles = makeStyles((theme) => ({
    customCheckbox: {
        width: "20px",
        height: "20px",
    }
}));

const FornNew = (props) => {
    const customClass = useStyles();
    const { goToIndex, fieldGroup } = props;
    const { data, success, error, loading } = useSelector(state => state.usercategoryReducer)
    const dispatch = useDispatch();
    let initValue = {
        user_category_name: '',
    };

    const colLength = [...Array(7)]

    const handlePermissionChange = (setFieldValue, value, name, submod, index) => {
        setFieldValue(name, value)

        menus.map((menu) => {
            if (menu.label == submod) {
                menu.subMenus.map((subMenu) => {
                    setFieldValue(formatName(subMenu.name, index), value)
                })
            }
        })

        if (index == 0 && !value) {
            menus.map((menu) => {
                if (menu.label == submod) {
                    type.map((action, actionIndex) => {
                        setFieldValue(formatName(menu.label, actionIndex), value)
                    })

                    menu.subMenus.map((subMenu) => {
                        type.map((action, actionIndex) => {
                            setFieldValue(formatName(subMenu.name, actionIndex), value)
                        })
                    })
                } else {
                    menu.subMenus.map((subMenu) => {
                        if (subMenu.name == submod) {
                            type.map((action, actionIndex) => {
                                setFieldValue(formatName(subMenu.name, actionIndex), value)
                            })
                        }
                    })
                }
            })
        }
    }

    const cbRow = (submod, formik) => {
        const { values, setFieldValue } = formik

        return (
            <>
                {
                    colLength.map((a, index) => {
                        const name = formatName(submod, index);

                        return (
                            <td key={index} style={{
                                paddingTop: '10px',
                                paddingBottom: '5px'
                            }}>
                                <input
                                    type="checkbox"
                                    name={name}
                                    onChange={() => {
                                        handlePermissionChange(setFieldValue, !values[name], name, submod, index);
                                    }}
                                    className={customClass.customCheckbox}
                                    checked={values[name]}
                                    disabled={index > 0 ? isDisable(submod, values) : false}
                                />
                            </td>
                        )
                    })
                }
            </>
        )
    }

    const salesSubMod = (arrSubMod, formik) => (
        <>
            {
                arrSubMod.map((s, sI) => (
                    <tr key={sI}>
                        <td></td>
                        <td>{s.label}</td>
                        {cbRow(s.name, formik)}
                    </tr>
                ))
            }
        </>
    )

    const formatName = (name, index) => {
        return `${name.replace(' ', '_').toLowerCase()}_${type[index]}`;
    }

    const formatToSnakeCase = (name) => {
        return `${name.replace(' ', '_').toLowerCase()}`;
    }

    menus.map((menu) => {
        type.map((action, index) => {
            const mainKeyName = formatName(menu.label, index);

            Object.assign(initValue, {
                [mainKeyName]: false,
            })
        });

        menu.subMenus.map((subMenu) => {
            type.map((action, index) => {
                const keyName = formatName(subMenu.name, index);

                Object.assign(initValue, {
                    [keyName]: false,
                })
            });
        })
    });

    const isDisable = (menu, values) => {
        return !values[formatName(menu, 0)];
    }

    const formOpts = {
        initialValues: initValue,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm, validateForm }) => {
            const cleanVal = {
                user_category_name: values.user_category_name
            }

            let result = await dispatch(addUsercategory(cleanVal))

            let data = {
                permissions: [],
            }

            menus.map((menu) => {
                type.map((action, index) => {
                    if (values[formatName(menu.label, index)]) {
                        data.permissions.push({
                            user_category_id: result.data.id,
                            type: action,
                            menu: formatToSnakeCase(menu.label),
                        });
                    }
                })

                menu.subMenus.map((submenu) => {
                    type.map((action, index) => {
                        if (values[formatName(submenu.name, index)]) {
                            data.permissions.push({
                                user_category_id: result.data.id,
                                type: action,
                                menu: formatToSnakeCase(submenu.name),
                            });
                        }
                    })
                })
            })

            if (data.permissions.length > 0 && result.data.id) {
                await dispatch(addUsercategoryPermission(data));
            }

            if (result) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusUsercategory())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts}>
                {(formik) => {
                    const { isSubmitting, validateForm } = formik;

                    return (
                        <Form className="form-member">
                            <FieldsGroup fields={fieldGroup} />
                            <table className="table-usercategory">
                                <thead>
                                    <tr>
                                        <th>Module</th>
                                        <th>Sub Module</th>
                                        <th>Read</th>
                                        <th>Create</th>
                                        <th>Update</th>
                                        <th>Approval</th>
                                        <th>Delete</th>
                                        <th>Print</th>
                                        <th>Export</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        menus.map((menu) => (
                                            <>
                                                <tr>
                                                    <td>{menu.label}</td>
                                                    <td></td>
                                                    {cbRow(menu.label, formik)}
                                                </tr>

                                                {salesSubMod(menu.subMenus, formik)}
                                            </>
                                        ))
                                    }
                                </tbody>
                            </table>

                            <div className="fields-group-container buttons">
                                <Button
                                    type="submit"
                                    style="OrangeMdWide"
                                    label={isSubmitting ? 'Saving' : 'Save'}
                                    icon={isSubmitting ? 'spinner' : null}
                                    onClick={
                                        () => {
                                            validateForm().then((response) => {
                                                if (Object.keys(response).length > 0) {
                                                    scrollToTop();
                                                }
                                            });
                                        }}
                                    disabled={false} />
                                <button disabled={isSubmitting} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default FornNew