import {
    POS_LOADING,
    POS_SET,
    POS_ERROR,
    POS_RESET,
} from './posActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const posReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case POS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case POS_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case POS_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case POS_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        default:
            return state;
    }
}

export default posReducer
