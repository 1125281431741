import React, { useState, useEffect } from 'react'
import { money, handleSortState, sortData } from '@/Helper'
import TdSort from 'Elements/TdSort'

let headerEnd = {
  deviation: {
    has_sort: true,
    sort_type: null,
    className: 'cell-tr',
    label: 'Dif. Stk'
  },
  deviation_value: {
    has_sort: true,
    sort_type: null,
    className: 'cell-tr',
    label: 'Dif. Value'
  },
  total_reject_deviation: {
    has_sort: true,
    sort_type: null,
    className: 'cell-tr cell-width-as-content',
    label: 'Dif. Rej'
  },
  total_reject_deviation_value: {
    has_sort: true,
    sort_type: null,
    className: 'cell-tr',
    label: 'Dif. Value'
  },
}

export default function ChildRow({ data, branches, variants, longestVariant, detailType }) {

  const [rows, setRows] = useState(data)
  const [header, setHeader] = useState({
    no: {
      label: '',
      has_sort: false,
    },
    date: {
      has_sort: true,
      sort_type: null,
      label: 'Transaction Date'
    },
    branch_name: {
      has_sort: true,
      sort_type: null,
      label: 'Branch',
    },
    transaction_code: {
      has_sort: true,
      sort_type: null,
      label: 'Transaction Code',
      colspan: 5,
    },
  })

  let customizeHeaderEnd = { ... headerEnd };

  if (detailType === 'stockopname_detail_stock') {
    delete customizeHeaderEnd.total_reject_deviation;
    delete customizeHeaderEnd.total_reject_deviation_value;
  } else if (detailType === 'stockopname_detail_reject') {
    delete customizeHeaderEnd.deviation;
    delete customizeHeaderEnd.deviation_value;
  }

  const sortColumn = (colName, variantIndex = 1000) => {

    setRows(prevState => {
      prevState = sortData({ type: header[colName].sort_type, column: colName, data: prevState })
      return [...prevState]
    })

    setHeader(prev => {
      return { ...handleSortState({ data: prev, column: colName }) }
    })
  }

  useEffect(() => {

    let variantHeader = {}
    let initRows = []

    Object.keys(longestVariant).forEach((variant, variantIdx) => {
      for (let i = 0; i < 6; i++) {
        variantHeader[`variant${variantIdx}${i}`] = {
          has_sort: true,
          sort_type: null,
          className: 'cell-tr',
          label: ' '
        }
      }
    })
    initRows = branches.map(branch => {
      [...new Array(longestVariant.length)].forEach((x, xIdx) => {
        [...['deviation', 'reject_deviation']].forEach((y, yIdx) => {
          if (detailType === 'stockopname_detail_stock' && y === 'deviation' || detailType === 'stockopname_detail_reject' && y === 'reject_deviation') {
            let ttl = 0

            branch.variants.forEach((variant, variantIdx) => {
              let types = variant.types;

              ttl += types?.[xIdx]?.[y] ?? 0
            })

            branch[`variant${xIdx}${yIdx}`] = ttl
          }
        })
      })

      return branch
    })

    setRows(initRows)
    setHeader(prev => {
      return {
        ...prev,
        ...variantHeader,
        ...customizeHeaderEnd
      }
    })

  }, [branches, longestVariant])


  return (
    <>
      {
        rows.map((branch, branchIdx) => (
          <React.Fragment key={`subrows${branchIdx}`}>
            {branchIdx < 1 && (
              <tr className="tr-detail detail-heading">
                {
                  Object.keys(header).map((key, idx) => (
                    <TdSort
                      key={`th-${idx}`}
                      label={header[key].label}
                      rowspan={header[key].rowspan}
                      colspan={header[key].colspan}
                      sort={header[key].has_sort}
                      handleClick={() => sortColumn(key)}
                      className={`${header[key].className}  ${header[key].sort_type}`}
                    />
                  ))
                }
              </tr>
            )}
            <tr>
              <td></td>
              <td>{branch.date}</td>
              <td>{branch.branch_name}</td>
              <td colSpan={5}>{branch.transaction_code}</td>
              {
                [...new Array(longestVariant.length)].map((x, xIdx) => { // yg looping 3x
                  return (
                    <React.Fragment key={xIdx}>
                      {
                        [...['deviation', 'reject_deviation']].map((y, yIdx) => {
                          if (detailType === 'stockopname_detail_stock' && y === 'deviation' || detailType === 'stockopname_detail_reject' && y === 'reject_deviation') {
                            return (
                              <td colspan={6}>
                                {branch[`variant${xIdx}${yIdx}`]}
                              </td>
                            )
                          }
                        })
                      }
                    </React.Fragment>
                  )
                })
              }
              {
                detailType === 'stockopname_detail_stock' && (
                  <>
                    <td>{branch.deviation}</td>
                    <td className="cell-width-as-content">{money(branch.deviation_value)}</td>
                  </>
                )
              }

              {
                detailType === 'stockopname_detail_reject' && (
                  <>
                    <td>{branch.total_reject_deviation}</td>
                    <td className="cell-width-as-content">{money(branch.total_reject_deviation_value)}</td>
                  </>
                )
              }
            </tr>
          </React.Fragment>
        ))
      }
    </>
  )
}
