import React, { useState, useEffect } from 'react'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport'
import { money, dateDMY, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import { Helmet } from "react-helmet"
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';

export default function TableDetail(props) {
    // export default function TableDetail({ data: reports }) {


    const [scroll, setScroll] = useState(true)
    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            className: 'cell-width-as-content',
            has_sort: false,
        },
        date: {
            has_sort: true,
            sort_type: null,
            label: 'date',
        },
        branch_name: {
            label: 'branch',
            has_sort: false,
        },
        code: {
            label: 'id',
            has_sort: false,
        },
        sub_total: {
            label: 'sub total',
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        total_discount: {
            label: 'disc',
            className: 'th-text-right ',
            sort_type: null,
            has_sort: true,
        },
        grand_total: {
            label: 'total',
            has_sort: true,
            className: 'th-text-right ',
            sort_type: null,
        },
        user_type: {
            label: 'type',
            sort_type: null,
            has_sort: true,
        },
        category: {
            label: 'category',
            has_sort: true,
            sort_type: null
        },
    })

    const toggleScroll = () => {
        setScroll(!scroll)
    }

    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            prevState[tableIndex].details = sortData({ type: sort[colName].sort_type, column: colName, data: prevState[tableIndex].details })
            return [...prevState]
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {
        setRows(props.data)
    }, [props])

    console.clear()
    console.log(props)

    return (
        <>
            {
                rows.length < 1 ? <NoReport /> : (
                    <>
                        {
                            rows.map((report, reportIdx) => (
                                <>
                                    <div style={{ marginTop: '0px' }}>
                                        <Helmet>
                                            <style type="text/css">
                                                {`@page {size: landscape}`}
                                            </style>
                                        </Helmet>
                                        <div className={`print-report zoom-60 ${reportIdx < 1 ? 'print-mt-120' : ''}`}>
                                            <h5><DeviceHubIcon /> Promotion Name  : {report.promotion_name}</h5>
                                            <div className="report-summary-box mb-16">
                                                <div className="item">
                                                    <p>
                                                        <span>Product Qty</span>
                                                        <span>{report.sales}</span>
                                                    </p>
                                                </div>
                                                <div className="item">
                                                    <p>
                                                        <span>Discount</span>
                                                        <span>IDR {money(report.total_discount)}</span>
                                                    </p>
                                                </div>
                                            </div>

                                            <NoPrint force={true}>
                                                <div className="table-menu">
                                                    <div className="left">
                                                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                                    </div>
                                                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                                </div>
                                            </NoPrint>

                                            <SimpleBarHOC status={scroll}>
                                                <table className="table-report">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                Object.keys(sort).map((key, idx) => (
                                                                    <ThSort
                                                                        key={`th-${idx}`}
                                                                        label={sort[key].label}
                                                                        sort={sort[key].has_sort}
                                                                        handleClick={() => sortColumn(key, reportIdx)}
                                                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                                    />
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            report.details.map((detail, detailIdx) => (
                                                                <tr className={detail.category === 'return' && 'row-red'}>
                                                                    <td className="td-index td-width-as-content">{detailIdx + 1}.</td>
                                                                    <td className="">{dateDMY(detail.date)}</td>
                                                                    <td className="td">{detail.branch_name}</td>
                                                                    <td className="">{detail.code}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.sub_total)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.total_discount)}</td>
                                                                    <td className="td-text-right td-currency">{money(detail.grand_total)}</td>
                                                                    <td className="">{detail.user_type}</td>
                                                                    <td className="">{detail.category}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </SimpleBarHOC>

                                        </div>
                                    </div>
                                </>
                            ))

                        }
                    </>
                )

            }
        </>
    )
}
