import React, { useState, useEffect } from 'react'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport'
import { money, dateDMY, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';
import { Helmet } from "react-helmet"


export default function TableDetail({ data: datas }) {

    const [scroll, setScroll] = useState(true)
    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        },
        date: {
            label: 'date',
            has_sort: true,
            sort_type: null,
        },
        branch_name: {
            label: 'branch',
            has_sort: false
        },
        transaction_code: {
            label: 'transaction code',
            has_sort: false,
        },
        type: {
            label: 'sales type',
            has_sort: false,
        },
        sub_total: {
            label: 'sub total',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
        discount_value: {
            label: 'disc',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        grand_total: {
            label: 'total',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        }
    })
    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            prevState[tableIndex].pos = sortData({ type: sort[colName].sort_type, column: colName, data: prevState[tableIndex].pos })
            return [...prevState]
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }


    const toggleScroll = () => {
        setScroll(!scroll)
    }
    useEffect(() => {
        setRows(datas)
    }, [datas])


    return (
        <>
            {
                rows.status === 'failed' || rows.length < 1 ? <NoReport /> : (
                    <>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <p style={{ marginTop: '40px' }} className="asdasd">POS Sales Report <span>Showing POS report from all branches</span></p>

                        {
                            rows.map((data, dataIdx) => (
                                <div className="report-pos-detail-table-container">
                                    <h5><DeviceHubIcon /> Payment Method  : {data.payment_method}</h5>
                                    <div className="report-summary-box mb-16">
                                        <div className="item teal">
                                            <p>
                                                <span>Sub Total</span>
                                                <span>IDR {money(data.sub_total)}</span>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <span>Sales</span>
                                                <span>{data.sales}</span>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <span>Discount</span>
                                                <span>IDR {money(data.discount_value)}</span>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <span>Total</span>
                                                <span>IDR {money(data.grand_total)}</span>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <span>Retur</span>
                                                <span>{data.return}</span>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <span>Total Return</span>
                                                <span>IDR {money(data.total_return || 0)}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <NoPrint force={true}>
                                        <div className="table-menu">
                                            <div className="left">
                                                <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                            </div>
                                            <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                        </div>
                                    </NoPrint>

                                    <SimpleBarHOC status={scroll}>
                                        <table className="table-report">
                                            <thead>
                                                <tr>
                                                    {
                                                        Object.keys(sort).map((key, idx) => (
                                                            <ThSort
                                                                key={`th-${idx}`}
                                                                label={sort[key].label}
                                                                colspan={sort[key].colspan}
                                                                sort={sort[key].has_sort}
                                                                handleClick={() => sortColumn(key, dataIdx)}
                                                                className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                            />
                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.pos.map((pos, posIdx) => (
                                                        <tr>
                                                            <td>{posIdx + 1}</td>
                                                            <td>{dateDMY(pos.date)}</td>
                                                            <td className="td-highlight">{pos.branch_name}</td>
                                                            <td>{pos.transaction_code}</td>
                                                            <td>{pos.type}</td>
                                                            <td className="td-text-right">{money(pos.sub_total)}</td>
                                                            <td className="td-text-right">{money(pos.discount_value)}</td>
                                                            <td className="td-text-right">{money(pos.grand_total)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </SimpleBarHOC>
                                </div>
                            ))
                        }
                    </>
                )
            }
        </>
    )
}
