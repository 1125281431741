import React, { useState, useEffect, createElement, useReducer } from 'react'
import Module from 'Layouts/Module'
import { useSelector, useDispatch } from 'react-redux';
import FormNew from './FormNew'
import TableData from './TableData'
import Detail from './Detail'
import FormEdit from './FormEdit'
import { scrollToTop, datePost, globalDialog } from '@/Helper'
import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
import { loadWarehouse, showWarehouse, deleteWarehouse, setEdit } from 'Reduxes/inventory/configuration/warehouse/warehouseActions'

export default function Warehouse(props) {

    const dev = 0;
    const { data, loading } = useSelector(state => state.warehouseReducer)
    const dispatch = useDispatch();
    const [branches, setBranches] = useState([])
    const dataLength = data.length;

    useEffect(() => {
        dispatch(loadWarehouse())

        const fetchBranches = async () => {
            const reqBranches = await reqGet(`${hostUrl}/api/branch`)
            let branchesRes = reqBranches.data.map(x => {
                return { label: x.branch_name, value: x.id }
            })

            const newContent = fieldGroup;
            newContent[0].fields[1].options = branchesRes

            setFieldGroup(newContent)
        }

        fetchBranches();
    }, [])

    const fieldGroupInit = [
        {
            name: 'Warehouse Information',
            fields: [
                {
                    label: 'code',
                    name: 'warehouse_code',
                    col: 3,
                    value: dev ? 'WH-' + dataLength : ''
                }, {
                    label: 'Branch',
                    col: 4,
                    name: 'branch_id',
                    type: 'select',
                    value: branches.length ? branches[0].value : 0,
                    options: branches,
                }, {
                    name: 'status',
                    label: 'Status',
                    type: 'select',
                    options: [
                        { label: 'Active', value: 'true' },
                        { label: 'Inactive', value: 'false' },
                    ],
                    value: 'true'
                }
            ]
        }, {
            name: 'Address Information',
            fields: [
                {
                    label: 'Location',
                    name: 'location_name',
                    col: 4,
                    value: dev ? 'Bandung' : ''
                },
                {
                    label: 'Address',
                    name: 'address',
                    col: 6,
                    value: dev ? 'Jl. Soeta' : ''
                },
            ]
        }, {
            name: 'Other Information',
            fields: [
                {
                    label: 'Phone',
                    name: 'phone',
                    value: dev ? '0821312322349' : ''
                }, {
                    name: 'is_store',
                    type: 'hidden',
                    value: 'false'
                }
            ]
        },
    ]
    const [fieldGroup, setFieldGroup] = useState(fieldGroupInit)

    const [contents, setContents] = useState([
        {
            name: 'table',
            active: true,
            component: TableData,
            props: {
                data,
                setContentActiveFormEdit: id => {
                    dispatch(setEdit(id))
                    scrollToTop()
                    setContentActive('formedit')
                },
                setContentActiveDetail: (idShow) => {
                    dispatch(showWarehouse(idShow))
                    setContentActive('detail')
                },
                deleteWarehouseProps: id => {
                    globalDialog(async () => {
                        await dispatch(deleteWarehouse(id))
                        scrollToTop()
                    })
                }
            },
            title: {
                title: 'Warehouse',
                subtitle: 'Manage data warehouse',
            }
        },
        {
            name: 'form',
            active: false,
            component: FormNew,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Add new Warehouse',
                subtitle: 'Fill the form below to create new Warehouse',
            },
        },
        {
            name: 'formedit',
            active: false,
            component: FormEdit,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Update Warehouse',
                subtitle: 'Good to keep the Warehouse data updated',
            }
        }, {
            name: 'detail',
            active: false,
            component: Detail,
            props: {
                setContentActiveFormEdit: idx => {
                    dispatch(showWarehouse(idx))
                    setContentActive('formedit')
                },
            },
            title: {
                title: 'Warehouse Detail',
                subtitle: '',
                button: {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => {
                        setContentActive('table')
                    },
                }
            }
        }
    ])
    const setContentActive = activeContent => {
        setContents(
            contents.map(content => {
                content.active = content.name === activeContent
                return content;
            })
        )
    }

    console.log('contents', contents);

    return (
        <>
            {
                contents.map((c, cI) => {
                    if (c.active) {
                        return (
                            <Module {...c.title} key={cI}>
                                {createElement(c.component, c.props)}
                            </Module>
                        )
                    }
                })
            }
        </>
    )
}
