import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    name:
        Yup.string()
            .required('Name is required'),
    address:
        Yup.string()
            .required('Address is required'),
    user_category_id:
        Yup.string()
            .when('is_user', {
                is: true,
                then: Yup.string().required('Roles is required')
            })
            .nullable(),
    username:
        Yup.string()
            .when('is_user', {
                is: true,
                then: Yup.string().required('Username is required')
            })
            .nullable(),
    // province:
    //     Yup.string()
    //         .required('Province is required'),
    // city:
    //     Yup.string()
    //         .required('City is required'),
    postal_code:
        Yup.string()
            .matches(/^\d+$/, 'Postal code should have digits only')
            .max(5, 'Postal code must 5 digits')
            .nullable(),
    phone:
        Yup.string()
            .max(15, 'Maximum phone number is 15 character')
            // .required('Phone number  is required')
            .nullable()
            .matches(/^\d+$/, 'Phone number should have digits only'),
    email: Yup.string()
        .email('Invalid email')
        .nullable(),
    brand_comission:
        Yup.number()
            .when('is_brand', {
                is: true,
                then: Yup.number().max(100, 'Maximum value is 100%')
            }).nullable(),
    consignment_comission:
        Yup.number()
            .when('is_consignment', {
                is: true,
                then: Yup.number().max(100, 'Maximum value is 100%')
            }).nullable(),
    // .required('Email is required'),
    note: Yup.string().max(150, "Maximum notes is 150 character").nullable()
})
