import React, { useEffect, useState } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import LoadingContent from 'Elements/LoadingContent'
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import { showSales } from 'Reduxes/pos/sales/salesActions'
import { money, makeImgUrl } from '@/Helper'
import { useDispatch } from 'react-redux';
import CustomToolbar from "../../../comps/elements/CustomToolbar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import ImageIcon from '@material-ui/icons/Image';
import { Lightbox } from "react-modal-image";

export default function Detail(props) {

    const { showTable, rowId } = props;
    const dispatch = useDispatch();
    const [data, setData] = useState(null)
    const parsed = data?.payment_method?.payment_name.split('_')
    const paymentName = parsed?.map(a => a.charAt(0).toUpperCase() + a.substr(1)).join(' ')
    const findPromotion = data?.pos_item.find(item => 'promotion_type' in item?.promotion)?.promotion?.promotion_type
    const parsedDiscountType = findPromotion && findPromotion.charAt(0).toUpperCase() + findPromotion.slice(1)
    const detailHeader = !data ? {} : {
        status: data.status,
        "payment method": paymentName,
        "total payment": `IDR ${money(data.total)}`,
        "change": `IDR ${money(data.payment_method?.change)}`,
        // "discount type": data.pos_item.map(item => item?.promotion.map(promo => promo?.promotion_type || '-')),
        // "discount type": parsedDiscountType || '-',
        "total quantity": data?.total_qty ?? 0,
        "member type": data.type === 'umum' ? 'General' : data.type,
        "Proof Member": makeImgUrl(data.proof_member),
        "Created By": data?.created_by_contact_name
    }
    console.log(parsedDiscountType)
    useEffect(() => {
        const fetchRow = async () => {
            const resp = await dispatch(showSales(rowId))
            setData(resp.data)
        }
        fetchRow();
    }, [])


    const [showImage, setShowImage] = useState(data?.pos_item.length ? data.pos_item.map(x => false) : [])
    const openLightbox = (index) => {
        setShowImage(state => {
            state[index] = true
            return [...state]
        })
    };
    const closeLightbox = (index) => {
        setShowImage(state => {
            state[index] = false
            return [...state]
        })
    };

    return (
        <>
            {
                !data ? <LoadingContent /> :
                    <PrintProvider>
                        <Print single name="print-sales-detail-1" >
                            <ContentWrapper
                                title="Sales Detail"
                                subtitle={`Showing product sales with id ${data.code || 'N/a'}`}
                                buttons={[
                                    {
                                        label: 'Go back',
                                        style: 'WhiteMd',
                                        icon: 'arrow-left',
                                        onClick: () => showTable(),
                                    },
                                    {
                                        label: "Print",
                                        style: "DarkblueMd",
                                        marginLeft: "ml-10",
                                        icon: "print",
                                        onClick: () => window.print(),
                                        subMenu: 'sales_pos',
                                        type: 'print',
                                    },
                                ]}
                            >
                                <div className="detail-page">
                                    <DetailHeader data={detailHeader} />



                                    {
                                        data.pos_item.length > 0 && (
                                            <table className="table table-detail style-2">
                                                <thead>
                                                    <tr>
                                                        <th>SKU</th>
                                                        <th>Product</th>
                                                        <th>Price / unit</th>
                                                        <th>Unit</th>
                                                        <th>Variant</th>
                                                        <th>Color</th>
                                                        <th>Promotion</th>
                                                        <th>Discount</th>
                                                        <th>Qty</th>
                                                        <th>Total</th>
                                                        {
                                                            data.category === "return" && <th>Proof Image</th>
                                                        }

                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.pos_item.map((row, rowIndex) => {
                                                            const { promotion_name, promotion_type, promotion_values } = row.promotion ?? {};
                                                            const promoName = promotion_name ?? '-'
                                                            const promoPercent = promotion_type === 'percent' ? ` (${promotion_values.discount}%)` : ''
                                                            return (
                                                                <>
                                                                    {
                                                                        showImage[rowIndex] && (
                                                                            <Lightbox
                                                                                medium={makeImgUrl(row.proof_retur)}
                                                                                onClose={() => closeLightbox(rowIndex)}
                                                                            />
                                                                        )
                                                                    }
                                                                    <tr key={rowIndex}>
                                                                        <td>{row.sku}</td>
                                                                        <td className="highlight">{row.product_name}</td>
                                                                        <td>IDR {money(row.price)}</td>
                                                                        <td>{row.uom_name}</td>
                                                                        <td>{row?.variant_type_value ?? '-'}</td>
                                                                        <td>{row?.color_name ?? '-'}</td>
                                                                        <td>
                                                                            {
                                                                                promoName + promoPercent
                                                                            }
                                                                        </td>
                                                                        <td>IDR {money(row.discount)}</td>
                                                                        <td>{row.qty}</td>
                                                                        <td>IDR {money(row.total)}</td>
                                                                        {

                                                                            data.category === "return" &&
                                                                            <td>
                                                                                {
                                                                                    row.proof_retur ?
                                                                                        <a onClick={() => openLightbox(rowIndex)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', color: '#21495e' }}>
                                                                                            <ImageIcon style={{ fontSize: '14px ' }} />
                                                                                            <p style={{ marginLeft: '5px' }}>Preview Image</p>
                                                                                        </a> : '-'
                                                                                }

                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </>
                                                            )
                                                        })
                                                    }
                                                    <tr className="total">
                                                        <td colSpan={data.category === "return" ? '9' : '8'} className="note" rowspan={data.return_total ? "5" : "3"}>
                                                            <b>Notes : </b>
                                                            <p>{data.note || '-'}</p>
                                                        </td>
                                                        <td>Sub Total</td>
                                                        <td>IDR {money(data.subtotal)}</td>
                                                    </tr>
                                                    <tr className="total">
                                                        <td>Discount</td>
                                                        <td>IDR {money(data.discount)}</td>
                                                    </tr>
                                                    {
                                                        data?.return_total && (
                                                            <tr className="total">
                                                                <td>Return Total</td>
                                                                <td>IDR {money(data.return_total)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    <tr className="total">
                                                        <td>Total</td>
                                                        <td>IDR {money(data.total)}</td>
                                                    </tr>
                                                    {
                                                        data?.return_total && (
                                                            <tr className="total">
                                                                <td>Difference</td>
                                                                <td>IDR {money(data.return_total + data.total)}</td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>)
                                    }
                                </div>
                            </ContentWrapper>

                        </Print>
                    </PrintProvider>
            }
        </>
    )
}
