import React from 'react'
import styles from './style.module.scss'

export default function NavWarning({ num }) {
    return (
        <>
            <span className={styles.warning}>{num}</span>
        </>
    )
}
