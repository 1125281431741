import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    branch_code:
        Yup.string()
            .required('Branch code is required')
            .matches(/\w*[a-zA-Z]\w*/, 'Branch code should have at least 1 letter'),
    branch_name:
        Yup.string()
            .required('Branch name is required'),
    is_active:
        Yup.string()
            .required('Status is required'),
    address:
        Yup.string()
            .required('Address is required'),
    // province:
    //     Yup.string()
    //         .required('Province is required'),
    // city:
    //     Yup.string()
    //         .required('City is required'),
    postal_code:
        Yup.string()
            .matches(/^\d+$/, 'Postal code should have digits only')
            .max(5, 'Postal code must 5 digits')
            .nullable(),
    phone:
        Yup.string()
            .max(15, 'Maximum phone number is 15 character')
            // .required('Phone number  is required')
            .nullable()
            .matches(/^\d+$/, 'Phone number should have digits only'),
    email: Yup.string()
        .nullable()
        .email('Invalid email')
    // web_address: Yup.string()
    //     .matches(
    //         /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //         'Invalid url'
    //     )
})

