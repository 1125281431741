import { handleRequest, reqPost, hostUrl } from 'Configs/request'

const type = "LOGIN_BRANCH"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const LOGIN_BRANCH_LOADING = 'LOGIN_BRANCH_LOADING'
export const LOGIN_BRANCH_STOP_LOADING = 'LOGIN_BRANCH_STOP_LOADING'
export const LOGIN_BRANCH_ERROR = 'LOGIN_BRANCH_ERROR'
export const LOGIN_BRANCH_SET = 'LOGIN_BRANCH_SET'
export const LOGIN_BRANCH_RESET = 'LOGIN_BRANCH_RESET';

export const getBranchByCode = branchCode => async (dispatch, state) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/branch/auth`, { 'branch_code': branchCode }),
        next: (payload) => dispatch({ type: LOGIN_BRANCH_SET, payload })
    })
}

export const resetStatusLoginBranch = () => {
    return {
        type: LOGIN_BRANCH_RESET
    }
}
