import React, { useEffect, useState } from 'react'
import { sortData, handleSortState } from '@/Helper'
import NoReport from 'Elements/NoReport'
import KopSurat from "Elements/KopSurat";
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import { Helmet } from "react-helmet"
import SimpleBarHOC from '../../../../comps/elements/SimpleBarHOC';
import ParentRow from './ParentRow'
import { money } from '../../../../Helper';

export default function StockOpnameDetail({ data, detailType }) {

  const hasData = data.products.length;
  let longestVariant = [];
  let variants = []

  if (hasData) {
    variants = data?.products?.[0]?.branches?.[0]?.variants;

    variants.forEach(variant => {
      if (!longestVariant) {
        longestVariant = variant.types;
        return;
      }

      if (variant.types.length > longestVariant.length) {
        longestVariant = variant.types;
      }
    })
  }

  const [rows, setRows] = useState([])
  const [scroll, setScroll] = useState(true)
  const [showDetail, setShowDetail] = useState(false)
  const [variantSort, setVariantSort] = useState({})
  const [sort, setSort] = useState({
    no: {
      label: 'no.',
      has_sort: false,
      rowspan: variants.length + 2,
    },
    date: {
      label: '',
      className: 'cell-min-width-100',
      rowspan: variants.length + 2,
      has_sort: false,
    },
    code: {
      label: 'id',
      className: 'cell-width-as-content',
      sort_type: null,
      rowspan: variants.length + 2,
      has_sort: true,
    },
    category_name: {
      label: 'category',
      className: 'cell-width-as-content',
      sort_type: null,
      rowspan: variants.length + 2,
      has_sort: true,
    },
    product_name: {
      label: 'name',
      className: 'cell-width-as-content',
      sort_type: null,
      rowspan: variants.length + 2,
      has_sort: true,
    },
    sales_type: {
      label: 'type',
      rowspan: variants.length + 2,
      sort_type: null,
      has_sort: true,
    },
    sales_price: {
      label: 'price',
      className: 'cell-width-as-content',
      sort_type: null,
      rowspan: variants.length + 2,
      has_sort: true,
    },
    brand_name: {
      label: 'brand',
      rowspan: variants.length + 2,
      sort_type: null,
      has_sort: true,
    },
    uom_name: {
      label: 'uom',
      rowspan: variants.length + 2,
      sort_type: null,
      has_sort: true,
    },
    variants: {
      label: 'variants',
      className: 'cell-tc',
      colspan: longestVariant.length * 6,
      has_sort: false,
    },
    stock: {
      label: '',
      rowspan: variants.length + 2,
      has_sort: false,
      colspan: 15
    }
  })
  const sortColumn = (colName, variantIndex = 1000) => {

    if (variantIndex !== 1000) { // variamt sort
      setRows(prevState => {
        return [...sortData({ type: variantSort[`variant-${variantIndex}`].sort_type, column: colName, data: prevState })]
      })

      setVariantSort(prev => {
        return { ...handleSortState({ data: prev, column: `variant-${variantIndex}` }) }
      })
    } else {
      setRows(prevState => {
        return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
      })

      setSort(prev => {
        return { ...handleSortState({ data: prev, column: colName }) }
      })
    }
  }
  const toggleScroll = () => {
    setScroll(!scroll)
  }
  const toggleDetail = () => {
    setShowDetail(!showDetail)
  }

  useEffect(() => {

    let initRows

    initRows = data.products.map((product, dIdx) => {
      return product
    })

    setRows(initRows)

  }, [data])

  return hasData < 1 ? <NoReport /> : (
    <>
      <Helmet>
        <style type="text/css">
          {`@page {size: landscape}`}
        </style>
      </Helmet>
      <div className="print-report zoom-60">
        <KopSurat fit />
        <p className="asdasd" style={{ marginBottom: '20px' }}>Stock Opname Report <span> Showing Stock Opname from all products</span></p>

        <NoPrint force={true}>
          <div className="table-menu">
            <div className="left">
              <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
              <button onClick={toggleDetail}>toggle subrows</button>
            </div>
            <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
          </div>
        </NoPrint>

        <div className="report-summary-box" style={{
          marginTop: '20px',
          marginBottom: '20px'
        }}>
          {
            detailType === 'stockopname_detail_stock' && <>
              <div className="item teal">
                <p>
                  <span>Stock Diff Plus</span>
                  <span>{data?.summary?.stock_plus}</span>
                </p>
              </div>
              <div className="item teal">
                <p>
                  <span>Stock Diff Min</span>
                  <span>{data?.summary?.stock_minus}</span>
                </p>
              </div>
              <div className="item">
                <p>
                  <span>Value Diff Plus</span>
                  <span>{money(data?.summary?.stock_plus_value)}</span>
                </p>
              </div>
              <div className="item">
                <p>
                  <span>Value Diff Minus</span>
                  <span>{money(data?.summary?.stock_minus_value)}</span>
                </p>
              </div>
            </>
          }
          {
            detailType === 'stockopname_detail_reject' && <>
              <div className="item">
                <p>
                  <span>Reject Diff Plus</span>
                  <span>{data?.summary?.reject_plus}</span>
                </p>
              </div>
              <div className="item">
                <p>
                  <span>Reject Diff Min</span>
                  <span>{data?.summary?.reject_minus}</span>
                </p>
              </div>
              <div className="item">
                <p>
                  <span>Value Diff Plus</span>
                  <span>{money(data?.summary?.reject_plus_value)}</span>
                </p>
              </div>
              <div className="item">
                <p>
                  <span>Value Diff Minus</span>
                  <span>{money(data?.summary?.reject_minus_value)}</span>
                </p>
              </div>
            </>
          }
        </div>

        <SimpleBarHOC status={scroll}>
          <table width="100%" className="table-report inventory-stockopname">
            <thead>
              <tr>
                {
                  Object.keys(sort).map((key, idx) => (
                    <ThSort
                      key={`th-${idx}`}
                      label={sort[key].label}
                      rowspan={sort[key].rowspan}
                      colspan={sort[key].colspan}
                      sort={sort[key].has_sort}
                      handleClick={() => sortColumn(key)}
                      className={`${sort[key].className}  ${sort[key].sort_type}`}
                    />
                  ))
                }
                <th colspan="5" rowspan="5"></th>
              </tr>
              {
                variants.map((variant, variantIdx) => (
                  <tr key={`something2${variantIdx}`} className={`sticky-variant-row-${variantIdx + 1}`}>
                    {
                      longestVariant.map((lv, lvIdx) => (
                        <ThSort
                          colspan={6}
                          key={`th-variant-${lvIdx}`}
                          label={variant?.types?.[lvIdx]?.label ?? '-'}
                          sort={false}
                          handleClick={() => sortColumn(`variant${lvIdx}`, lvIdx)}
                          className={` cell-tc ${variantSort?.[`variant-${lvIdx}`]?.sort_type}`}
                        />
                      ))
                    }
                  </tr>
                ))
              }

              <tr className={`sticky-variant-row-${variants.length + 1}`}>
                {
                  longestVariant.map((lv, lvIdx) => (
                    <React.Fragment key={lvIdx}>
                      {
                        [
                          // { title: 'stock', label: 'stk' },
                          // { title: 'physic', label: 'phy' },
                          { title: 'difference', label: 'dif stk' },
                          // { title: 'reject', label: 'rej' },
                          // { title: 'physic reject', label: 'p.rej' },
                          { title: 'difference', label: 'dif rej' }
                        ]
                          .map((x, idx) => {
                            if (detailType === 'stockopname_detail_stock' && x.label === 'dif rej') return;
                            if (detailType === 'stockopname_detail_reject' && x.label === 'dif stk') return;
                            return (
                              <ThSort
                                key={`th-variant-most-bottom-${idx}`}
                                label={x.label}
                                title={x.title}
                                colspan={6}
                                className="cell-width-as-content"
                                sort={false}
                              />
                            )
                          }).filter(Boolean)
                      }
                    </React.Fragment>
                  ))
                }
              </tr>
            </thead>
            <tbody>
              {
                rows.map((product, productIdx) => (
                  <React.Fragment key={productIdx}>
                    <ParentRow
                      showDetail={showDetail}
                      product={product}
                      variants={variants}
                      index={productIdx}
                      longestVariant={longestVariant}
                      detailType={detailType}
                    />
                  </React.Fragment>
                ))
              }
            </tbody>
          </table>
        </SimpleBarHOC>
      </div>
    </>
  )
}
