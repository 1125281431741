import React, { useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable';
import { Field, useFormikContext } from 'formik';

const components = {
    DropdownIndicator: null,
};

const replaceCommaWithDot = str => {
    return str.replace(/,/g, '.').toUpperCase();
}

const createOption = (label: string) => ({
    label,
    value: replaceCommaWithDot(label)
});

export default function InputChip(props) {

    const { label, name, value, handleChangeProp, col } = props;
    const formikContext = useFormikContext();
    const [state, setState] = useState({
        inputValue: '',
        value: value || [],
    })
    const handleChange = (value, actionMeta) => {
        setState({ ...state, value });
    }
    const handleInputChange = (inputValue) => {
        setState({ ...state, inputValue: replaceCommaWithDot(inputValue) });
    };

    /* handle onChange */
    useEffect(() => {
        const onlyVal = (state.value || []).map(x => x.value);
        // debugger;

        // set value formik 
        if (formikContext) {
            formikContext.setFieldValue(name, onlyVal);
        }
        // ngirim ke luar valuenya
        if (handleChangeProp) {
            handleChangeProp({
                value: onlyVal,
                values: state.value
            })
        }
    }, [state.value])


    useEffect(() => {
        // set default value dari formik
        // jika name disini sama dengan yg di formik
        // jika ini merupakan bagian formik
        if (formikContext) {
            const { initialValues } = formikContext;
            if (initialValues[name] || initialValues[name] !== '') {
                // debugger;
                const defVal = initialValues[name] ?
                    initialValues[name].split(',').map(a => ({ value: a, label: a }))
                    : '';
                setState({ ...state, value: defVal });
            }
        }
    }, [])

    const handleKeyDown = (event) => {
        let { inputValue, value } = state;
        value = value || [];


        if (!inputValue) return;

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                const newVal = createOption(inputValue);

                const isDuplicate = value.find(v => v.value === replaceCommaWithDot(newVal.value));
                if (!isDuplicate) {
                    setState({
                        inputValue: '',
                        value: [...value, createOption(inputValue)],
                    });
                }

                event.preventDefault();
                break;
            default:
        }
    };


    return (
        <div className={`field-container ${col ? `span-${col}` : ``}`}>

            { label && <label htmlFor={name}>{label}</label>}
            <Field name={name}>
                {(formik) => {
                    let { form, field } = formik;
                    const { setFieldValue, setValues, values } = form;
                    const { value, name } = field;

                    return (
                        <CreatableSelect
                            components={components}
                            inputValue={state.inputValue}
                            // styles={customStyles}
                            classNamePrefix={`input-chips`}
                            isClearable
                            isMulti
                            menuIsOpen={false}
                            onChange={handleChange}
                            onInputChange={handleInputChange}
                            onKeyDown={handleKeyDown}
                            placeholder="Type something and press enter..."
                            value={state.value}
                        />
                    )
                }}
            </Field >
        </div >

    )
}

