import React, { useState, memo, useEffect } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { money } from '@/Helper'
import GeneralRowChild from './GeneralRowChild';


const GeneralRowParent = ({ data, showDetail: defaultShowDetail, no, variants }) => {

    const [d, setD] = useState(data)
    const [showDetail, setShowDetail] = useState(defaultShowDetail)
    const handleShowDetail = () => {
        setShowDetail(!showDetail)
    }

    useEffect(() => {
        setShowDetail(!showDetail)
    }, [defaultShowDetail])

    useEffect(() => {
        setD(data)
        setShowDetail(false)
    }, [data, variants])

    return (
        <>
            <tr>
                <td>{no}</td>
                <td className="cell-width-as-content cell-highlight">
                    <span className="trigger-show-detail" onClick={handleShowDetail}>
                        {d.code} {d.categories.length && <KeyboardArrowDownIcon className="info-icon" />}
                    </span>
                </td>
                <td>{d?.type ?? 'N/a'}</td>
                <td>{d?.name ?? 'N/a'}</td>
                <td colSpan={2}>{d.brand_initial ?? 'N/a'}</td>
                {
                    variants.map((lv, lvIdx) => {
                        let qty = 0;
                        d.variants.forEach(variant => {
                            qty += variant?.types?.[lvIdx]?.stock ?? 0
                        })
                        return <td key={`parentvariant${lvIdx}`}>{qty}</td>
                    })
                }
                <td>{d.total_stock}</td>
                <td>{d.total_reject}</td>
                <td>Pcs</td>
                <td className="cell-currency cell-tr">{money(d.total_value)}</td>
            </tr>

            <GeneralRowChild
                indexParent={no}
                show={showDetail}
                variants={variants}
                categories={d.categories}
            />

        </>
    )
}



export default memo(GeneralRowParent)