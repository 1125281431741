import React, { memo, useEffect, useState } from 'react'
import { default as ReactSelect } from 'react-select'
import { ErrorMessage, useFormikContext } from 'formik';
import { loadCategory } from 'Reduxes/inventory/configuration/category/categoryActions'
import { loadBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import { loadColor } from 'Reduxes/inventory/configuration/color/colorActions'
import { loadVariant } from 'Reduxes/inventory/configuration/variant/variantActions'
import { loadUnit } from 'Reduxes/inventory/configuration/unit/unitActions'
import { loadBranch } from 'Reduxes/configuration/branch/branchActions'
import { loadContact } from 'Reduxes/contact/contactActions'
import { filterProduct, loadProduct } from 'Reduxes/inventory/product/productActions'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import { filterMember, loadProvince, loadCity } from 'Reduxes/member/memberActions'



const useStyles = makeStyles((theme) => ({
    label: {
        ...theme.pw.form.label
    },
    note: {
        ...theme.pw.form.text_helper
    }
}))

const customStyles = {
    base: (base) => ({
        ...base,
        zIndex: 9999,
        background: 'red'
    }),
    option: (provided, state) => ({
        ...provided,
        fontWeight: '400',
        fontSize: '13px',
        color: '#555',
        zIndex: 999999,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#555',
        top: '55%',
        fontSize: '13px',
    }),
    input: (provided) => ({
        ...provided,
        margin: '0 2px',
        paddingTop: 0,
        paddingBottom: 0,
        height: '38px',
        paddingLeft: '4px',
        color: '#555',
        fontSize: '13px',
        '&input': {
            background: 'magenta'
        }
    }),
    control: (provided, state) => ({
        ...provided,
        width: '100%',
        border: state.isFocused ? '1px solid #red' : '1px solid #ddd',
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? '#ccc' : '#ddd',
        '&:hover': {
            // border: state.isFocused ? 0 : 0
        }
    }),
    placeholder: () => ({
        fontWeight: '400',
        fontSize: '13px',
        color: '#555',
    }),
    noOptionsMessage: (provided) => ({
        ...provided,
        fontWeight: '400',
        fontSize: '13px',
        color: '#555',
        overflow: 'visible'
    }),
    valueContainer: (provided) => ({
        ...provided,
        height: '44px',
        // overflow: 'auto'
    })
}

const makeOptByRemote = ({ data, type }) => {
    if (type === 'branch') {
        return data.map(x => ({ label: x.branch_name, value: x.id }))

    } else if (type === "province") {
        return data.map(x => ({ value: x.provinsi, label: x.provinsi }))
    }
    else if (type === "city") {
        return data.map(x => ({ value: x.ibukota, label: x.ibukota }))
    }

    else if (type === 'productByVariant') {

        return data.map(x => (
            x.variant_type_value ? { label: `${x.name} ${x.color_name} (${x.variant_type_value})`, value: x.code } : { label: `${x.name}`, value: x.code }

        ))
    }

    return data.map(x => ({ label: x.name, value: x.id }))
}

const Select = (props) => {

    const {
        options, isMulti, getData, isError,
        isClearable, name, param, label,
        note, setFormikDefault, portal,
        defaultInputValue, handleProps,
        onSubmitValidation, isDisabled, col,
    } = props

    const formik = useFormikContext();
    const classes = useStyles(props);
    const dispatch = useDispatch();

    /* State */
    const [arrOpts, setArrOpts] = useState([]);
    const [placeholder, setPlaceholder] = useState('Select option');
    const [remoteData, setRemoteData] = useState(null);
    const [value, setValue] = useState(null);

    /* Handle Change */
    const handleChange = (selected, option) => {

        let validSelected = selected ? selected : { value: '', label: 'Select option' }

        setValue(validSelected);

        if (handleProps) {
            handleProps(validSelected)
        }

        formik && formik.setFieldValue(
            name, validSelected.value
        )


        // jika field provinsi di hapus makan otomatis city akan terhapus 
        if (name === 'province') {
            if (validSelected.value === '') {
                formik && formik.setFieldValue(
                    'city', validSelected.value
                )
            }
        }


        if (getData) {
            let rawOpt;

            if (options === 'productByVariant') {
                rawOpt = !remoteData ? null : remoteData.find(x => x.code === validSelected.value);
            } else if (options === 'province') {
                rawOpt = !remoteData ? null : remoteData.find(x => x.provinsi === validSelected.value);
            } else if (options === 'city') {
                rawOpt = !remoteData ? null : remoteData.find(x => x.city === validSelected.value);
            } else {
                rawOpt = !remoteData ? null : remoteData.find(x => x.id === validSelected.value);
            }

            getData({ selected: validSelected, rawOpt, formik, props })
        }
    }

    /* set formik default value */
    const setFormikDefaultValue = (arr) => {

        if (!setFormikDefault) {
            return;
        }

        formik && formik.setFieldValue(
            name,
            arr ? arr[0].value : null
        )
    }

    /* Effect : Onload */
    useEffect(() => {
        let resp = null;

        const fetchOpts = async () => {
            setPlaceholder('Loading options...');

            if (options === 'isActive') {

                const optsIsActive = [
                    { value: true, label: 'Active' },
                    { value: false, label: 'Inactive' },
                ]

                setArrOpts(optsIsActive)
                setPlaceholder('Select option');
                setFormikDefaultValue(optsIsActive);
                return;

            } else if (options === 'category') {
                resp = await dispatch(loadCategory())
            } else if (options === 'brand') {
                resp = await dispatch(loadBrand())
            } else if (options === 'color') {
                resp = await dispatch(loadColor())
            } else if (options === 'uom') {
                resp = await dispatch(loadUnit())
            } else if (options === 'variant') {
                resp = await dispatch(loadVariant())
            } else if (options === 'branch') {
                resp = await dispatch(loadBranch())
            } else if (options === 'contact') {
                resp = await dispatch(loadContact(param ? param : {}))
                resp.data = resp.data?.map((item) => {
                    // const brand = item.brand[0]
                    const commission = item.brand_comission
                    item.name = `${item.name} ${commission ? `(${commission})%` : ''}`
                    return item
                })
            } else if (options === 'product') {
                resp = await dispatch(loadProduct())
            } else if (options === 'productByVariant') {
                resp = await dispatch(filterProduct({ flat: true }))
            } else if (options === 'province') {
                resp = await dispatch(loadProvince(param ? param : {}))
            } else if (options === 'city') {
                resp = await dispatch(loadCity(param ? param : {}))
            } else {
                setPlaceholder('Options not defined')
                setFormikDefaultValue(null);
                return;
            }


            if (resp.status) {

                // arr has been transformed {label: value:}
                let optsTransf = makeOptByRemote({
                    data: resp.data,
                    type: options,
                });

                /* mindahkan none ke atas */
                // find none
                const hasNoneIdx = optsTransf.findIndex(x => x.label.toLowerCase() === 'none')
                if (hasNoneIdx >= 0) {
                    let prevNone = optsTransf[hasNoneIdx];
                    optsTransf.splice(hasNoneIdx, 1);
                    optsTransf.unshift(prevNone);
                }

                setArrOpts(optsTransf);
                setPlaceholder('Select option');
                setFormikDefaultValue(optsTransf);
                setRemoteData(resp.data);

            } else {
                setPlaceholder('Load option fail')
                setFormikDefaultValue(null);

            }
        }

        if (Array.isArray(options)) { // hasdefault
            setArrOpts(options);
            setFormikDefaultValue(options);

        } else { // must fetch
            fetchOpts();
        }
    }, [options])


    /* Effect[Formik] : update data on data edit fetched */
    useEffect(() => {

        if (!formik) { return; }

        const w = arrOpts.find(x => x.value === formik.values[name])

        w && setValue({ label: w.label, value: w.value })

    }, [formik, arrOpts])

    return (
        <div className={`field-container ${col ? `span-${col}` : ``}`}>
            { label && <label className={classes.label} htmlFor={name || ''}>{label}</label>}
            <ReactSelect
                onChange={handleChange}
                styles={customStyles}
                placeholder={placeholder}
                name={name}
                {...isMulti ? { isMulti: true } : {}}
                {...isClearable ? { isClearable: true } : {}}
                classNamePrefix="react-select-pw"
                className='react-select-container'
                options={arrOpts}
                value={value}
                isDisabled={isDisabled}
                {...defaultInputValue ? { defaultInputValue } : {}}
                {...portal ? { menuPortalTarget: document.body } : {}}
            />
            {
                note && <span className={`${classes.note} ${isError ? 'error' : ''}`}>{note}</span>
            }

            {
                formik && (
                    <>
                        {
                            (formik.touched.is_user && name === 'user_category_id') || onSubmitValidation ?
                                <p className="errMsg">{formik.errors.user_category_id}</p> :
                                <ErrorMessage name={name} component="div" className="errMsg" />

                        }
                    </>
                )
            }
        </div>
    )
}

export default memo(Select)
