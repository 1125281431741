import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "SALESRETURN"

export const SALESRETURN_SET = 'SALESRETURN_SET'
export const SALESRETURN_ERROR = 'SALESRETURN_ERROR'
export const SALESRETURN_ADD = 'SALESRETURN_ADD'
export const SALESRETURN_SHOW = 'SALESRETURN_SHOW'
export const SALESRETURN_EDIT = 'SALESRETURN_EDIT'
export const SALESRETURN_UPDATE = 'SALESRETURN_UPDATE'
export const SALESRETURN_DELETE = 'SALESRETURN_DELETE'
export const SALESRETURN_LOADING = 'SALESRETURN_LOADING'
export const SALESRETURN_STOP_LOADING = 'SALESRETURN_STOP_LOADING'
export const SALESRETURN_RESET = 'SALESRETURN_RESET'
export const SALESRETURN_CONFIRM = 'SALESRETURN_CONFIRM'

export const loadSalesreturn = () => async (dispatch, getState) => {
    const fakeResp = {
        "status": "success",
        "data": [
            {
                "array1": [
                    {
                        "sku": "TS20-01-001S-BL",
                        "product_name": "Baju wanita",
                        "product_price": 250000,
                        "discount": 50000,
                        "qty": 5,
                        "unit_name": "Pc",
                    }
                ], "note": "somewhere over the rainbow", "created_by": "User 1", "created_at": "Kamis, 25-02-2020", "updated_at": "Kamis, 25-02-2020", "updated_by": "User 2",
                "printed": 1, "id": 15, "address": "Jl. Soekarno Hatta No.409 Bandung, Jawa Barat Indonesia", "date": "20-20-2020", "code": "TS20-001-BL", "contact_name": "widiyanto", "sub_total": 350000, "discount": 10000, "reference": "Budi", "status": "draft"
            }], "errors": []
    }

    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/sales_retur`),
        // body: () => fakeResp,
        next: (payload) => dispatch({ type: SALESRETURN_SET, payload })
    })
}
export const addSalesreturn = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/sales_retur`, data),
        next: (payload) => dispatch({ type: SALESRETURN_ADD, payload })
    })
}
export const updateSalesreturn = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/sales_retur/${idEdit}`, data),
        next: (payload) => dispatch({ type: SALESRETURN_UPDATE, payload })
    })
}
export const deleteSalesreturn = id => async (dispatch, getState) => {
    console.log(id, 123)
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/sales_retur`, id),
        next: () => dispatch({ type: SALESRETURN_DELETE, payload: id })
    })
}
export const setEditSalesreturn = idEdit => {
    return {
        type: SALESRETURN_EDIT,
        payload: idEdit,
    }
}
export const resetStatusSalesreturn = () => {
    return {
        type: SALESRETURN_RESET
    }
}
export const updateStatus = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/sales_retur/${type}/${id}`, {}),
        next: (resp) => dispatch({ type: SALESRETURN_CONFIRM, payload: { type, resp, id } })
    })
}
export const showSalesreturn = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/sales_retur/${idx}`),
    })
}