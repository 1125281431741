import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts } from 'Configs/table'
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from 'Comps/elements/Alert'
import Table from 'Elements/Table'
import TableNoData from 'Comps/elements/TableNoData'
import { deleteUsercategory, resetStatusUsercategory, loadUsercategory } from 'Reduxes/usercategory/usercategoryActions'
import LoadingContent from 'Elements/LoadingContent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Permission from '../../../comps/permission/Permission';


const useStyles = makeStyles(theme => ({
    table: {
        '& td:nth-child(2)': {
            width: '10%',
        },
        '& td:nth-child(5)': {
            width: '10%',
        },
        '& .row-action': {
            color: '#555',
            fontSize: '16px',
            marginRight: '8px',
            cursor: 'pointer'
        }
    },
}));


export default function TableData(props) {

    const { setContentActiveFormEdit, deleteUsercategoryProps, setContentActiveDetail } = props
    const [categories, setCategories] = useState([]);
    const { data, loading, error, success } = useSelector(state => state.usercategoryReducer);
    const classes = useStyles();
    const dispatch = useDispatch();

    useEffect(() => {
        const categories = data.map(category => {
            return category.id < 2 ? null : category
        }).filter(Boolean)
        setCategories(categories);
    }, [data])

    const table = tableOpts({
        data: categories,
        colIdPosition: 0,
        noPrint: true,
        filename: "User_Category_Data",
        filter: false,
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        filter: false,
                        print: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        // display:false,
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                {
                    name: "user_category_code",
                    label: 'Code',
                    options: {

                        filter: true,
                        filterType: "textField"
                    },
                },
                {
                    name: "user_category_name",
                    label: 'Name',
                    options: {

                        filter: true,
                        filterType: "textField"
                    },
                },
                {
                    name: "id",
                    label: 'Actions',
                    options: {

                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        filter: false,

                        customBodyRender: (value, tableMeta, updateValue) => {
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => setContentActiveDetail(value)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => setContentActiveFormEdit(value)
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => {
                                    deleteUsercategoryProps([value])
                                }
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />

                                    {
                                        value !== 1 &&
                                        <>
                                            <Permission
                                                component={
                                                    <EditIcon {...attrEdit} />
                                                }
                                                subMenu="user_category"
                                                type="update"
                                            />
                                            <Permission
                                                component={
                                                    <DeleteIcon {...attrDelete} />
                                                }
                                                subMenu="user_category"
                                                type="delete"
                                            />
                                        </>
                                    }
                                </>
                            );
                        }
                    }
                },
            ],
        },
        deleteAct: (ids) => {
            const filteredIds = ids.filter(id => id !== 1);

            dispatch(deleteUsercategory(filteredIds))
        }
    })


    return (
        <>
            {
                success &&
                <Alert hide={() => dispatch(resetStatusUsercategory())} option={{ type: 'success', message: success }} />
            }
            {
                error && <Alert hide={() => dispatch(resetStatusUsercategory())} option={{ type: 'error', message: error }} />
            }

            {
                loading ? <LoadingContent />
                    : (
                        data.length ?
                            <Table {...table} className={classes.table} />
                            : <TableNoData />
                    )
            }
        </>
    )
}

