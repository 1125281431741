import moment from 'moment'
import Module from 'Layouts/Module'
import TableData from './TableData'
import { useSelector, useDispatch } from 'react-redux';
import React, { useState, useEffect, createElement } from 'react'
import { filterLogActivity } from '../../../reduxes/configuration/activitylog/logActivityActions'

export default function Log(props) {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.logActivityReducer)

    const [contents, setContents] = useState([
        {
            name: 'table',
            active: true,
            component: TableData,
            props: {
                data,
            },
            title: {
                title: 'Activity Log',
                subtitle: 'Manage user activity log',
            }
        },
    ])

    useEffect(() => {
        const getActivity = async () => {
            await dispatch(filterLogActivity({ date: moment().format('Y-M-DD') }))
        }

        getActivity();
    }, [])

    return (
        <>
            {
                contents.map((c, cI) => {
                    if (c.active) {
                        return (
                            <Module {...c.title} key={cI}>
                                {createElement(c.component, c.props)}
                            </Module>
                        )
                    }
                })
            }
        </>
    )
}
