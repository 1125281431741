import { handleRequest, reqGet, hostUrl } from 'Configs/request'

const type = "PROMOTION"

export const loadPromotion = () => async (dispatch, state) => {
    //  belum ada kebutuhan untuk disimpan ke reducer
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/promotion`),
    })
}