import { 
    handleReq, 
    handleRequest, 
    reqGet, 
    reqDel, 
    hostUrl, 
    makeGetParam 
} from 'Configs/request'

const type = "LOG_ACTIVITY"

export const LOG_ACTIVITY_SET = 'LOG_ACTIVITY_SET'
export const LOG_ACTIVITY_ERROR = 'LOG_ACTIVITY_ERROR'
export const LOG_ACTIVITY_RESET = 'LOG_ACTIVITY_RESET'
export const LOG_ACTIVITY_DELETE = 'LOG_ACTIVITY_DELETE'
export const LOG_ACTIVITY_LOADING = 'LOG_ACTIVITY_LOADING'
export const LOG_ACTIVITY_SET_ANCHOR = 'LOG_ACTIVITY_SET_ANCHOR'

export const loadLogActivity = () => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/log_activity`),
        next: (payload) => dispatch({ type: LOG_ACTIVITY_SET, payload }),
    })
}

export const filterLogActivity = (args = {}) => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/log_activity${makeGetParam(args)}`),
        next: (payload) => dispatch({ type: LOG_ACTIVITY_SET, payload }),
    })
}

export const deleteLogActivity = (args = {}) => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/log_activity${makeGetParam(args)}`, ),
        next: () => dispatch({ type: LOG_ACTIVITY_DELETE }),
    })
}

export const setLogActivityAnchor = (anchor) => async (dispatch) => {
    dispatch({ type: LOG_ACTIVITY_SET_ANCHOR, payload: anchor });
}

export const resetStatusLogActivity = () => {
    return {
        type: LOG_ACTIVITY_RESET,
    }
}