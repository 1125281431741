import React from 'react'

export default function DisplayImage({ linkImage, onChangeLink }) {

    console.log({linkImage, onChangeLink})
    return (
        <div className="proof-image-box">

            <img src={linkImage} height="300" width="250"></img>
       
            <div className="change-proof-image" onClick={onChangeLink}>
                Change Proof Image
            </div>
         </div>
    )
}
