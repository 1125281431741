import React, { useEffect, useState } from 'react'
import { addEdc, updateEdc, resetStatusEdc } from 'Reduxes/pos/edc/edcActions'
import { loadBranch } from 'Reduxes/configuration/branch/branchActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop } from '@/Helper'
import Button from 'Elements/Button';
import Input from 'Elements/Input';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import DeleteIcon from '@material-ui/icons/Delete';

function FormElement(props) {
    const { type, showTable } = props;
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(false);
    const { error, idEdit, data } = useSelector(state => state.edcReducer);
    const [selectOption, setSelectOption] = useState([])
    const [edcItem, setEdcItem] = useState([
        {
            code: '',
            branch: null,
            status: true
        },
    ])
    const [initialValues, setInitialValues] = useState({
        "code": '',
        "name": '',
        "branch": '',
    })

    const handleDelete = (index) => {
        const edc = [...edcItem]
        edc.splice(index, 1)
        setEdcItem(edc)
    }

    const addEdcItem = () => {
        setEdcItem([
            ...edcItem,
            {
                code: '',
                branch: 1,
                status: true
            }
        ])
    }

    const handleInputChange = (e, index) => {
        let { name, value } = e.target
        if (value === "true" || value === "false") {
            value = JSON.parse(value);
        }
        if (name === 'branch') {
            value = +value
        }
        const edc = [...edcItem]
        edc[index][name] = value
        setEdcItem(edc)
    }

    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            let cleanVal = {
                "code": values.code,
                "name": values.name,
                "edc_item": edcItem
            }
            let result = type === 'new' ?
                await dispatch(addEdc(cleanVal))
                : await dispatch(updateEdc(idEdit, cleanVal))
            if (result) {
                resetForm();
                showTable();
            }
            scrollToTop()
            setSubmitting(false)
        }
    }

    useEffect(() => {
        if (type === 'edit') {
            let findData = data.find(d => d.id === idEdit);
            const { code, edc_item, name } = findData;
            const edc_Item = edc_item.map(item => {
                const { id, branch_id, code, status } = item
                return {
                    id,
                    branch_id,
                    branch: branch_id,
                    code,
                    status
                }
            })
            setInitialValues({
                code,
                name,
                edc_item: edc_Item,
            })
            setEdcItem(edc_Item)
        }

        const loadRemote = async () => {
            let resp = null;
            let label = null;
            let value = null;
            resp = await dispatch(loadBranch())
            label = 'branch_name'
            setIsReady(true)
            if (resp.status) {
                setSelectOption(resp.data.map(data => (
                    { label: data[label || 'name'], value: data[value || 'id'] }
                )))
            }
        }
        loadRemote();
    }, [])

    return (
        <ContentWrapper
            title={type === 'new' ? "Create New EDC" : "Update data EDC"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data edc`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusEdc())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {
                                    const { isSubmitting } = formik;
                                    return (
                                        <Form className="form-layout-1">
                                            <FieldsContainer title="EDC Information">
                                                <Input name="code" label="EDC Code" />
                                                <Input name="name" label="Source" />
                                            </FieldsContainer>
                                            <table className="table table-detail style-2">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th></th>
                                                        <th>EDC ID</th>
                                                        <th>Branch</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        edcItem.map((edc, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{index + 1}</td>
                                                                    {
                                                                        edcItem.length === 1 ?
                                                                            <td></td>
                                                                            : <td><DeleteIcon cursor="pointer" onClick={() => handleDelete(index)} /></td>
                                                                    }
                                                                    <td>
                                                                        <input name="code" value={edc.code} onChange={(e) => handleInputChange(e, index)} maxLength="24" />
                                                                    </td>
                                                                    <td style={{ maxWidth: '200px', width: '200px' }}>
                                                                        <div className='field-container-edc'>
                                                                            <select
                                                                                name="branch"
                                                                                onChange={e => handleInputChange(e, index)}
                                                                                style={{ width: '200px' }}
                                                                                value={edcItem[index].branch}
                                                                            >
                                                                                <option value="DEFAULT" selected disabled>Select options</option>
                                                                                {
                                                                                    selectOption.map((option, index) => {
                                                                                        return (
                                                                                            <option key={index} value={option.value}>{option.label}</option>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                    <td style={{ maxWidth: '200px', width: '200px' }}>
                                                                        <div className='field-container-edc'>
                                                                            <select
                                                                                name="status"
                                                                                onChange={e => handleInputChange(e, index)}
                                                                                style={{ width: '200px' }}
                                                                                value={edc.status}
                                                                            >
                                                                                <option value={true}>
                                                                                    Active
                                                                                </option>
                                                                                <option value={false}>
                                                                                    Inactive
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="button-container" style={{ marginTop: '20px', marginBottom: '20px' }}>
                                                <Button icon="plus" style="RectGreen" onClick={addEdcItem} label="ADD EDC" />
                                            </div>
                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </>
                    )
                        : <LoadingContent />
                }
            </>
        </ContentWrapper>
    )
}

export default FormElement