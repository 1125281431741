import React from 'react';
import AsyncSelect from 'react-select/async';
import { useDispatch } from 'react-redux'
import { filterProduct } from 'Reduxes/inventory/product/productActions'


const CustomSelectAsync = (props) => {
  const dispatch = useDispatch()

  // const [loading, setLoading] = useState(true)

  const loadPageOptions = async (inputValue) => {
    if (inputValue.length < 1) {
      return;
    }

    const { data } = await dispatch(filterProduct({
      q: inputValue,
      has_promotion: '0'
    }))

    const newOptions = data.map((data) => {
      return {
        label: `${data?.name} ${data.color_name || ''} ${data.variant_type_value ? `(${data.variant_type_value})` : ``}`,
        value: data.id,
        id: data.id
      }
    })

    if (data.length) {
      return newOptions
    }
  }

  return (
    <React.Fragment>
      <div id="component-async-select-paginate">
        <div className="async-select-paginate-label">{props.label}</div>
        <AsyncSelect
          loadOptions={loadPageOptions}
          // styles={props.customStyles ?? BaseStyleSelect}
          classNamePrefix="react-select"
          placeholder={props.placeholder ?? 'Select'}
          isMulti={true}
          menuPortalTarget={document.body}
          isClearable={true}
          {...(props.name ? { name: props.name } : {})}
          {...(props.onChange ? { onChange: props.onChange } : {})}
          {...(props.defaultValue ? { defaultValue: props.defaultValue } : {})}
          // {...(props.isDisabled ? { isDisabled: props.isDisabled } : {})}
          // {...(props.isClearable ? { isClearable: props.isClearable } : {})}
          {...(props.isSearchable ? { isSearchable: props.isSearchable } : {})}
        // {...(props.value ? { value: props.value } : {})}
        // {...(props.newKey ? { key: props.newKey } : {})}
        // {...(props.isOptionDisabled ? { isOptionDisabled: props.isOptionDisabled } : {})}
        // components={{ DropdownIndicator, ClearIndicator, MultiValueRemove, IndicatorSeparator }}
        />
        {/* <div className={`message-select ${props.isError ? 'error-message' : ''}`}>{props.message}</div> */}
      </div>
    </React.Fragment>
  );
}
export default CustomSelectAsync;