import React, { useState } from 'react'
import TableDataSSR from './TableDataSSR'
import FormElement from './FormElement'
import Detail from './Detail'

function Product() {
    const [content, setContent] = useState('table')
    const [idShow, setIdShow] = useState('table')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filters, setFilters] = useState({})
    const [params, setParams] = useState('')

    const showDetail = (id) => {
        setIdShow(id)
        setContent('detail')
    }
    const showTable = () => {
        setContent('table');
    }
    const refreshTable = () => {
        showTable()
    }
    const showForm = (type) => {
        setContent('form' + type);
    }

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                return <TableDataSSR
                    showForm={showForm}
                    showDetail={showDetail}
                    indexSetRowsPerPage={setRowsPerPage}
                    indexSetPage={setPage}
                    indexSetFilters={setFilters}
                    indexSetParams={setParams}
                    initialPage={page}
                    initialRowsPerPage={rowsPerPage}
                    initialFilters={filters}
                    initialParams={params}
                />
            case 'formNew':
                return (<FormElement type="new" showTable={refreshTable} />)
            case 'formEdit':
                return (<FormElement type="edit" showTable={refreshTable} />)
            case 'detail':
                return (
                    <Detail
                        showTable={showTable}
                        rowId={idShow}
                        page={page}
                        rowsPerPage={rowsPerPage}
                    />
                )
            default:
                return <>Nothing to show here</>
        }
    }

    return (
        <>
            {renderContent(content)}
        </>
    )
}

export default Product
