import React, { useState, useRef, createRef, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Select from 'Elements/select/Select';
import TableGeneral from './TableGeneral';
import TableDetail from './TableDetail';
import { makeStyles } from '@material-ui/core/styles';
import { loadPosReport } from 'Reduxes/pos/posActions';
import { useDispatch } from 'react-redux';
import { hostUrl, makeGetParam } from 'Configs/request.js';
import LoadingContent from 'Elements/LoadingContent';
import FilterGeneral from '../report-filter/FilterGeneral';
import Button from 'Elements/Button';
import { actionButton } from "../../../configs/report";
import PrintProvider, { Print } from "react-easy-print";
import KopSurat from "../../../comps/elements/KopSurat";
import { getLocalStorage, getLocalStorageAuth } from '@/Helper'
import 'simplebar/dist/simplebar.min.css';

const useStyles = makeStyles((theme) => ({
    formfilter: {
        display: 'grid',
        gridTemplateColumns: '300px 300px',
        columnGap: '24px',
        marginBottom: '15px'
    },
    formextrafilter: {
        '& .toggler': {
            fontSize: '12px',
            fontWeight: 700,
            color: '#333',
            '& svg': {
                marginBottom: '-7px'
            }
        }
    },
    formextrafiltercontainer: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '3px',
        marginTop: '15px',
        marginBottom: '20px'
    }
}))


export default function Report() {

    const classes = useStyles();
    const dispatch = useDispatch();
    const loaderRef = useRef();
    const currentBranch = getLocalStorage().branchLogin.id
    const isSuperadmin = getLocalStorageAuth().user_category_id === 1 || getLocalStorageAuth().user_category_id === 2
    /* STATE */
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [reportUrl, setReportUrl] = useState('');
    const [filter, setFilter] = useState({
        group_by: null,
        details: null,
    })
    const [extraFilter, setExtraFilter] = useState({});

    const reqReport = async () => {
        const reportUrl = `${hostUrl}/api/pos/report-general` + makeGetParam({ ...filter, ...extraFilter, ...(!isSuperadmin ? { branch_id: currentBranch } : {}) })
        setReportUrl(reportUrl)
        const resp = await dispatch(loadPosReport({ ...filter, ...extraFilter, ...(!isSuperadmin ? { branch_id: currentBranch } : {}) }))
        setData(resp.data);
        setLoading(false);
    }
    const handleSelect = select => {
        setData(null);

        setFilter(state => {
            state[select.props.name] = select.selected.value
            state.group_by = state.details === 'true' ? null : state.group_by;

            return { ...state };
        })

    }
    const handleExtraFilter = objFilter => {
        setExtraFilter(objFilter)
    }
    const applyFilter = async () => {
        setData(null);
        if ((filter.group_by && filter.details) || filter.details === 'true') {
            setLoading(true);
            reqReport()
        }
    }

    useEffect(() => {
        if (loading) {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }
    }, [loading])

    return (
        <div>
            <ContentWrapper
                title="POS Sales Report"
                subtitle="Use available filter below to show custom criteria "
                buttons={actionButton({ reportUrl })}
            >
                <div className="report report-pos">

                    <div className={classes.formfilter}>
                        <Select
                            name="details"
                            label="Type"
                            getData={handleSelect}
                            options={[
                                { label: 'Summary Sales', value: 'false' },
                                { label: 'Report by Branch', value: 'true' }
                            ]}
                        />
                        {
                            filter.details === 'false' && <Select
                                name="group_by"
                                label="Group By"
                                getData={handleSelect}
                                options={[
                                    { label: 'Day', value: 'day' },
                                    { label: 'Month', value: 'month' },
                                    { label: 'Year', value: 'year' }
                                ]}
                            />
                        }
                    </div>

                    {/* THE AWESOME FILTER  */}
                    <div className="mb-30">
                        <FilterGeneral
                            setFilter={setFilter}
                            getData={handleExtraFilter}
                        />
                    </div>

                    <div className="mb-20">
                        <Button onClick={applyFilter} style="OrangeWideMdRect" icon="check-circle" label="Apply Filter" />
                    </div>

                    <PrintProvider>
                        <Print single name="print-pos-report-table-1" >
                            <KopSurat fit />

                            {
                                data && (
                                    <>
                                        {
                                            data?.pos?.length > 0 && (
                                                <p className="asdasd">
                                                    POS Sales Report
                                                    <span>Showing POS report from all branches</span>
                                                </p>
                                            )
                                        }
                                        {
                                            filter.details === 'false' ?
                                                <TableGeneral data={data} /> :
                                                <TableDetail data={data} />
                                        }
                                    </>
                                )
                            }

                            {loading ? <LoadingContent /> : null}

                        </Print>
                    </PrintProvider>

                </div>
            </ContentWrapper>
        </div>
    )
}
