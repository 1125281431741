import React from 'react'
import { money, dateDMY } from '@/Helper'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport'

const setColor = (type) => {
    return type == 'sales_return' ? { color: 'red' } : { color: 'black' };
}

export default function TableDetail({ data }) {

    const brandComm = data.brand_commission;

    return brandComm.length < 1 ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '20px' }}>Report Brand Commission by Costumer Detail <span>Showing Details of Report Brand Commission</span></p>

            {
                brandComm.map(report => (
                    <>

                        <h5><DeviceHubIcon /> Brand Name  : {report.brand_name}</h5>
                        <div className="report-summary-box">
                            <div className="item teal">
                                <p>
                                    <span>Net Value</span>
                                    <span>IDR {money(report.net_income)}</span>
                                </p>
                            </div>

                            <div className="item">
                                <p>
                                    <span>Total Return</span>
                                    <span>IDR {money(report.sr_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Subtotal</span>
                                    <span>IDR {money(report.sub_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Commission</span>
                                    <span>IDR {money(report.commission)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Total</span>
                                    <span>IDR {money(report.grand_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>HPP</span>
                                    <span>IDR {money(report.hpp)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Other Cost</span>
                                    <span>IDR {money(report.additional_cost)}</span>
                                </p>
                            </div>
                        </div>

                        <div className="report-summary-pill">
                            <p>
                                <span>Sales Return :</span>
                                <span>{report.sr_counter}</span>
                            </p>
                            <p>
                                <span>#Sales :</span>
                                <span>{report.so_counter}</span>
                            </p>
                        </div>

                        <div className="scroll-x">
                            <table className="table-report mt-0 brand print-report zoom-80">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Date</th>
                                        <th>No Sales</th>
                                        <th className="th-text-right">sub total</th>
                                        <th className="th-text-right">commission</th>
                                        <th className="th-text-right">total</th>
                                        <th className="th-text-right">hpp</th>
                                        <th className="th-text-right">other cost</th>
                                        <th className="th-text-right">net income</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        report.details.map((data, index) => (
                                            <tr>
                                                <td style={setColor(data.type)} className="td-index">{index + 1}.</td>
                                                <td style={setColor(data.type)} className="td-width-as-content">{data.date}</td>
                                                <td style={setColor(data.type)} className="td-width-as-content">{data.code}</td>
                                                <td style={setColor(data.type)} className="td-text-right td-currency">IDR {money(data.sub_total)}</td>
                                                <td style={setColor(data.type)} className="td-text-right td-currency">IDR {money(data.commission)}</td>
                                                <td style={setColor(data.type)} className="td-text-right td-currency">IDR {money(data.grand_total)}</td>
                                                <td style={setColor(data.type)} className="td-text-right td-currency">IDR {money(data.hpp)}</td>
                                                <td style={setColor(data.type)} className="td-text-right td-currency">IDR {money(data.additional_cost)}</td>
                                                <td style={setColor(data.type)} className="td-text-right td-currency">IDR {money(data.net_income)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                ))
            }
        </>
    )
}

















/*
import React from 'react'
import { money, dateDMY } from '@/Helper'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

export default function TableDetail({ data }) {
    const reports = [
        {
            net_income: 9000000,
            brand_name: "SIRARU",
            sr_total: 124477,
            sub_total: 76667,
            comission: 908886,
            grand_total: 16468146,
            hpp: 812684,
            additional_cost: 81247,
            sr_counter: 2,
            so_counter: 5,
            brand_comission: [
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                }
            ]
        },
        {
            net_income: 9000000,
            brand_name: "XTC",
            sr_total: 124477,
            sub_total: 76667,
            comission: 908886,
            grand_total: 16468146,
            hpp: 812684,
            additional_cost: 81247,
            sr_counter: 2,
            so_counter: 5,
            brand_comission: [
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
            ]
        },
        {
            net_income: 9000000,
            brand_name: "!#@#$%%",
            sr_total: 124477,
            sub_total: 76667,
            comission: 908886,
            grand_total: 16468146,
            hpp: 812684,
            additional_cost: 81247,
            sr_counter: 2,
            so_counter: 5,
            brand_comission: [
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
                {
                    date: '22-08-91',
                    code: 'XXXXUWAR',
                    sub_total: 19751,
                    comission: 709,
                    grand_total: 1274914,
                    hpp: 124815,
                    additional_cost: 1751559,
                    net_income: 1015528
                },
            ]
        },
    ]
    return (
        <>
            {
                reports.map(report => (
                    <>



                    </>
                ))
            }
        </>
    )
}
 */