import {
    INVOICE_LOADING,
    INVOICE_STOP_LOADING,
    INVOICE_SET,
    INVOICE_ERROR,
    INVOICE_ADD,
    INVOICE_SHOW,
    INVOICE_DELETE,
    INVOICE_UPDATE,
    INVOICE_RESET,
    INVOICE_EDIT,
    INVOICE_CONFIRM
} from './invoiceActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const invoiceReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case INVOICE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case INVOICE_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case INVOICE_UPDATE:
            // ketika pindah ke done idEdit tidak dipasang seperti seharusnya 
            // jadi diakali ngambil dari pyload ny 
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case INVOICE_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case INVOICE_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New invoice has been added successfully.'
            }
        case INVOICE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case INVOICE_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case INVOICE_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case INVOICE_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case INVOICE_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case INVOICE_CONFIRM:
            console.log('111111111111', payload, type);

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData

            }
        default:
            return state;
    }
}

export default invoiceReducer