import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableNoData from 'Comps/elements/TableNoData'
import LoadingContent from 'Elements/LoadingContent';
import Table from 'Elements/Table'
import { deleteInvoice, resetStatusInvoice } from 'Reduxes/consignment/invoice/invoiceActions'
import { scrollToTop, money, moneyToInt, globalMessage, globalDialog } from '@/Helper'
import Label from 'Elements/Label'
import PrintProvider, { Print } from "react-easy-print";
import KopSurat from 'Elements/KopSurat'
import SelectFilter from 'Elements/SelectFilter'

import {
  FormGroup,
  FormLabel,
  TextField
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Permission from '../../../comps/permission/Permission';

const useStyles = makeStyles((theme) => ({
  spacer: {
    height: "25px"
  }
}));

export default function TableData(props) {
  const localClass = useStyles();
  const { showDetail, showForm } = props;
  const { data, loading, error, success } = useSelector(state => state.invoiceReducer);

  const classes = globalTableStyle();
  const dispatch = useDispatch();
  const handleDelete = async id => {
    let allowDelete = true;
    id.forEach(i => {
      const isFound = data.find(d => d.id === i)
      if (isFound && isFound.status !== 'draft') {
        allowDelete = false;
      }
    });

    if (!allowDelete) {
      globalMessage('You only allowed to delete data with status \'Draft\'', 'warning')
      return;
    }

    globalDialog(async () => {
      await dispatch(deleteInvoice(id))
      scrollToTop()
    })
  }


  const [date, setdate] = useState({
    dateFilterChecked: false
  })

  const [dueDate, setDueDate] = useState({
    dueDateFilterChecked: false
  })

  const [subTotal, setSubTotal] = useState({
    subTotalFilterChecked: false
  })

  const [disc, setDisc] = useState({
    discFilterChecked: false
  })

  const [total, setTotal] = useState({
    totalFilterChecked: false
  })


  //OPTIONS UNTUK SELECT CUSTOM FILTER 
  const uniqueContact = [...new Set(data.map(item => item.contact_name))];
  const contactOptions = uniqueContact.map(item => {
    return {
      value: item,
      label: item
    }
  })

  const table = tableOpts({
    handleDelete,
    data,
    colIdPosition: 0,
    filename: "Invoice_Data",
    table: {
      columns: [
        {
          name: 'id',
          options: {
            display: false,
            filter: false,
            viewColumns: false
          }
        },
        {
          name: 'no',
          label: 'No.',
          options: {
            filter: false,
            print: true,
            customBodyRender: (value, tableMeta) => {
              return tableMeta.rowIndex + 1
            }
          }
        },
        {
          name: "date",
          label: 'Date',
          options: {
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && date.dateFilterChecked) {
                  return [`Min Date: ${v[0]}`, `Max Date: ${v[1]}`];
                } else if (v[0] && v[1] && !date.dateFilterChecked) {
                  return `Min Date: ${v[0]}, Max Date: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Date: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Date: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                // console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },

            filterOptions: {
              names: [],
              logic(date, filters) {


                if (filters[0] && filters[1]) {
                  return Date.parse(date) < Date.parse(filters[0]) || Date.parse(date) > Date.parse(filters[1]);
                } else if (Date.parse(filters[0])) {
                  return Date.parse(date) < Date.parse(filters[0]);
                } else if (Date.parse(filters[1])) {
                  return Date.parse(date) > Date.parse(filters[1]);
                }
                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel className={localClass.title}>Dates</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />
                    <TextField
                      variant="outlined"
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />

                  </FormGroup>
                </div>
              ),
            },

          }
        },

        {
          name: "due_date",
          label: 'Due Date',
          options: {
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && dueDate.dueDateFilterChecked) {
                  return [`Min Due Date: ${v[0]}`, `Max Due Date: ${v[1]}`];
                } else if (v[0] && v[1] && !dueDate.dueDateFilterChecked) {
                  return `Min Due Date: ${v[0]}, Max Due Date: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Due Date: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Due Date: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                // console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },

            filterOptions: {
              names: [],
              logic(date, filters) {


                if (filters[0] && filters[1]) {
                  return Date.parse(date) < Date.parse(filters[0]) || Date.parse(date) > Date.parse(filters[1]);
                } else if (Date.parse(filters[0])) {
                  return Date.parse(date) < Date.parse(filters[0]);
                } else if (Date.parse(filters[1])) {
                  return Date.parse(date) > Date.parse(filters[1]);
                }
                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel className={localClass.title}>Due Dates</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />
                    <TextField
                      variant="outlined"
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />

                  </FormGroup>
                </div>
              ),
            },

          }
        },
        // {
        //   name: "due_date",
        //   label: 'Due Date',
        //   options: {
        //     filter: false
        //   }
        // },
        {
          name: 'code',
          label: 'Code',
          options: {
            filter: false
          }
        },

        {
          name: "contact_name",
          label: 'Contact',
          options: {
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[5].length === 1 && v[5][0]?.value === "") {  // index harus di sesuaikan dengan posisi column
                  return [];
                } else if (v[5].length) {
                  const val = v[5].map(x => x.value);
                  return [`Contact : ${val.join(', ')}`]
                }
                return [];

              },
              update: (filterList, filterPos, index) => { // untuk update chip filter diatas table
                if (filterPos === 0) {
                  filterList[index] = [];
                }
                return filterList;

              }
            },
            filterOptions: {
              logic(value, filters) {
                const dataFilter = filters[5]?.map(item => item.value)
                return !dataFilter || !dataFilter.length ? false : !dataFilter.includes(value); // jika tidak ada filter akan ditampilkan semua data di table

              },

              display: (filterList, onChange, index, column) => (
                <>
                  <SelectFilter
                    label="contact"
                    name="contact"
                    handleChange={select => {
                      filterList[index] = select || [];
                      onChange(filterList, index, column);
                    }}
                    options={contactOptions}
                    defaultValue={filterList[index][index]}
                  />
                </>
              )

            }

          }
        },
        {
          name: "sub_total",
          label: 'Sub Total',
          options: {

            customBodyRender: (value, tableMeta, updateValue) => {
              return `IDR ${money(value)}`
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && subTotal.subTotalFilterChecked) {
                  return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
                } else if (v[0] && v[1] && !subTotal.subTotalFilterChecked) {
                  return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Price: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Price: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                // console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(price, filters) {

                if (filters[0] && filters[1]) {
                  return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                } else if (filters[0]) {
                  return moneyToInt(price) < filters[0];
                } else if (filters[1]) {
                  return moneyToInt(price) > filters[1];
                }

                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Sub Total</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      type='number'
                    />
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      type='number'

                    />
                  </FormGroup>
                </div>
              )

            }
          }
        },
        {
          name: "total_discount",
          label: 'Discount',
          options: {

            customBodyRender: (value, tableMeta, updateValue) => {

              return `IDR ${money(value)}`
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && disc.discFilterChecked) {
                  return [`Min Discount: ${v[0]}`, `Max Discount: ${v[1]}`];
                } else if (v[0] && v[1] && !disc.discFilterChecked) {
                  return `Min Discount: ${v[0]}, Max Discount: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Discount: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Discount: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                // console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(price, filters) {

                if (filters[0] && filters[1]) {
                  return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                } else if (filters[0]) {
                  return moneyToInt(price) < filters[0];
                } else if (filters[1]) {
                  return moneyToInt(price) > filters[1];
                }

                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Discount</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      type='number'
                    />
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      type='number'
                    />
                  </FormGroup>
                </div>
              )

            }

          }
        },
        {
          name: 'total',
          label: 'Total',
          options: {

            customBodyRender: (value, tableMeta, updateValue) => {
              return `IDR ${money(value)}`;
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && total.totalFilterChecked) {
                  return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
                } else if (v[0] && v[1] && !total.totalFilterChecked) {
                  return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Price: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Price: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                // console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(price, filters) {

                if (filters[0] && filters[1]) {
                  return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                } else if (filters[0]) {
                  return moneyToInt(price) < filters[0];
                } else if (filters[1]) {
                  return moneyToInt(price) > filters[1];
                }

                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Total</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      type='number'
                    />
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      type='number'

                    />
                  </FormGroup>
                </div>
              )

            }
          }
        },
        {
          name: "status",
          label: 'Status',
          options: {

            customBodyRender: (value, tableMeta, updateValue) => {
              // draft, confirm, close
              let type = '';
              if (value === 'draft') {
                type = 'grey';
              } else if (value === 'confirmed') {
                type = 'success';
              } else {
                type = 'primary';
              }

              return <Label text={value} type={type} />
            },
            filter: true,
            filterType: 'checkbox',
            // filterOptions: {
            //     names: ['Draft', 'Confirmed', 'Closed'],
            //     logic(val, filterVal) {
            //     const show =
            //             (filterVal.indexOf('Draft') >= 0  && val ==='draft')||
            //             (filterVal.indexOf('Confirmed') >= 0  && val ==='confirmed') ||
            //             (filterVal.indexOf('Closed') >= 0  && val === 'closed');


            //     return !show;
            //     },
            // },


          }
        }, {
          name: "id",
          label: 'Actions',
          options: {
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                zIndex: 51
              }
            }),
            setCellProps: () => ({

              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 51
              }
            }),
            filter: false,
            print: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const attrShow = {
                className: 'row-action',
                onClick: () => showDetail(value)
              }
              const attrEdit = {
                className: 'row-action',
                onClick: () => {
                  // dispatch(setEditInvoice(value))
                  // dispatch(loadProduct());
                  showForm('Edit', value)
                  scrollToTop()
                }
              }
              const attrDelete = {
                className: 'row-action',
                onClick: () => handleDelete([value])
              }

              return (
                <>
                  <VisibilityIcon {...attrShow} />
                  {
                    tableMeta.rowData[9] === 'draft' &&
                    <Permission
                      component={<EditIcon {...attrEdit} />}
                      subMenu="invoice"
                      type="update"
                    />
                  }
                  <Permission
                    component={<DeleteIcon {...attrDelete} />}
                    subMenu="invoice"
                    type="delete"
                  />
                </>
              );
            }
          }
        },
      ],
    },
  })

  // console.log(data)
  return (
    <PrintProvider>
      <Print single name="print-invoice-table-1" >
        <KopSurat />
        <div className="spacer-60"></div>
        <ContentWrapper
          title="Invoices"
          subtitle="showing table of all data invoices"
          buttons={[
            {
              label: 'Add invoice',
              style: 'OrangeMd',
              icon: 'plus-circle',
              onClick: () => showForm('New'),
              subMenu: 'invoice',
              type: 'create',
            }
          ]}
        >
          <>
            {
              success &&
              <Alert hide={() => dispatch(resetStatusInvoice())} option={{ type: 'success', message: success }} />
            }
            {
              error && <Alert hide={() => dispatch(resetStatusInvoice())} option={{ type: 'error', message: error }} />
            }
            {
              loading ? <LoadingContent />
                : (
                  data.length ?
                    <Table {...table} className={classes.table} />
                    : <TableNoData />
                )
            }
          </>
        </ContentWrapper>

      </Print>
    </PrintProvider>
  )
}