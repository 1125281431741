import React from 'react'
import { Field, ErrorMessage } from 'formik';
import Button from './Button'
import Input from './Input'
import Select from './Select'
import CheckBox from './CheckBox'
import Datepicker from './Datepicker'
import Textarea from './Textarea'
import { default as SelectV2 } from './select/Select'

export default function Fields(props) {

    const { fields } = props;

    return (
        <>
            {
                fields.map((el, idx) => {
                    const { type, ...rest } = el
                    if (!type || type === 'email' || type === 'text' || type === 'password' || type === 'hidden') {
                        return <Input key={idx} type={type} {...rest} />
                    } else {
                        if (type === 'button' || type === 'buttons' || type === 'submit') {
                            let btnArr = type === 'buttons' ? el.buttons : [el];
                            return btnArr.map((b, bI) => <Button key={bI} {...b} />)
                        } else if (type === 'select') {
                            return <Select key={idx} {...rest} />
                        } else if (type === 'selectV2') {
                            return <SelectV2 key={idx} {...rest} />
                        } else if (type === 'check') {
                            return <CheckBox key={idx} {...rest} />
                        } else if (type === 'datepicker') {
                            return <Datepicker key={idx} {...rest} />
                        } else if (type === 'textarea') {
                            return <Textarea key={idx} {...rest} />
                        }
                    }
                })
            }
        </>
    )
}

