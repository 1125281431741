import React from 'react'
import { useSelector } from 'react-redux';
import DetailHeader from 'Comps/layouts/detailheader/DetailHeader'

export default function Detail(props) {

    const { idxShow, data } = useSelector(state => state.variantReducer);
    const detail = data.filter((a, b) => b === +idxShow)[0]
    console.log('detail', detail)

    const variant = detail.variant_value && detail.variant_value.length ?
        detail.variant_value.map(variant =>
            <><span className="variant-value">{variant.value}</span></>
        ) : 'No variant added';

    const objData = {
        code: detail.code,
        name: detail.name,
        status: detail.status ? 'active' : 'inactive',
        'variant value': variant,
    }

    return (
        <div>
            <DetailHeader className="variant" data={objData} />
        </div>
    )
}
