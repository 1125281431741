import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import { deleteMember, resetStatusMember } from 'Reduxes/member/memberActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableNoData from 'Comps/elements/TableNoData'
import LoadingContent from 'Elements/LoadingContent';
import Table from 'Elements/Table'
import { scrollToTop, money, moneyToInt, globalDialog } from '@/Helper'
import Label from 'Elements/Label'
import CustomToolbar from "../../../comps/elements/CustomToolbar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import KopSurat from 'Elements/KopSurat'


import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';


import { makeStyles } from '@material-ui/core/styles';
import Permission from '../../../comps/permission/Permission';


const useStyles = makeStyles((theme) => ({
  spacer: {
    height: "25px"
  }
}));


export default function TableData(props) {
  const localClass = useStyles();
  const { showDetail, showForm } = props;
  const { data, loading, error, success } = useSelector(state => state.memberReducer);
  const classes = globalTableStyle();
  const dispatch = useDispatch();
  const handleDelete = id => {
    globalDialog(async () => {
      await dispatch(deleteMember(id))
      scrollToTop()
    })
  }

  const dataMod = data.map(d => {
    const tempAddress = [d.address, d.city === '' ? null : d.city, d.province === '' ? null : d.province, d.country, d.postal_code === 0 ? null : d.postal_code].filter(Boolean)
    d.full_address = tempAddress.join(', ')

    return d.id < 3 ? null : d;
  }).filter(Boolean);

  const [purchaseState, setPurchaseState] = useState({
    purchaseFilterChecked: false
  })

  const [cardRec, setCardRec] = useState({
    cardRecFilterChecked: false
  })

  const [cardExp, setCardExp] = useState({
    cardExpFilterChecked: false
  })

  const table = tableOpts({
    handleDelete,
    data: dataMod,
    colIdPosition: 0,
    filename: "Member_Data",
    table: {
      columns: [
        {
          name: 'id',
          options: {
            display: false,
            filter: false,
            print: false,
            viewColumns: false
          }
        },
        {
          name: 'no',
          label: 'No.',
          options: {
            // display:false,
            filter: false,
            print: true,
            customBodyRender: (value, tableMeta) => {
              return tableMeta.rowIndex + 1
            }
          }
        },

        {
          name: "member_code",
          label: 'Code',
          options: {
            filter: false,

          }
        },
        {
          name: "name",
          label: 'Name',
          options: {
            filter: false,

          }
        },
        {
          name: "full_address",
          label: 'Address',
          options: {
            filter: false,
            customBodyRender: (value) => {
              return <div className="td-address">{value}</div>
            }
          }
        },

        {
          name: "email",
          label: 'Email',
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                !value ? '-' : <a className="td-email" href={`mailto:${value}`}>{value}</a>
              )
            }
          }
        },
        {
          name: "phone",
          label: 'Phone',
          options: {
            filter: false,
          }
        },
        {
          name: "total_transaction",
          label: 'Total Purchase',
          options: {

            customBodyRender: (value, tableMeta, updateValue) => {
              return value ? `IDR ${money(value)}` : 0
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && purchaseState.purchaseFilterChecked) {
                  return [`Min Purchase: ${v[0]}`, `Max Purchase: ${v[1]}`];
                } else if (v[0] && v[1] && !purchaseState.purchaseFilterChecked) {
                  return `Min Purchase: ${v[0]}, Max Purchase: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Purchase: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Purchase: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(purchase, filters) {

                if (filters[0] && filters[1]) {
                  return moneyToInt(purchase) < parseInt(filters[0]) || moneyToInt(purchase) > parseInt(filters[1]);
                } else if (filters[0]) {
                  return moneyToInt(purchase) < filters[0];
                } else if (filters[1]) {
                  return moneyToInt(purchase) > filters[1];
                }

                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Total Purchase</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      type='number'
                    />
                    <TextField
                      variant="outlined"
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      type='number'
                    />
                  </FormGroup>
                </div>
              )

            }
          }
        },
        {
          name: 'member_card_received_date',
          label: 'Received',
          options: {
            filter: true,
            display: true,
            filterType: 'custom',

            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && cardRec.cardRecFilterChecked) {
                  return [` Rec Min Date: ${v[0]}`, `Rec Max Date: ${v[1]}`];
                } else if (v[0] && v[1] && !cardRec.cardRecFilterChecked) {
                  return `Rec Min Date: ${v[0]}, Rec Max Date: ${v[1]}`;
                } else if (v[0]) {
                  return `Rec Min Date: ${v[0]}`;
                } else if (v[1]) {
                  return `Rec Max Date: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },

            filterOptions: {
              names: [],
              logic(date, filters) {
                const nanDate = isNaN(Date.parse(date))
                if (filters[0] && filters[1]) {
                  return (nanDate || Date.parse(date) < Date.parse(filters[0])) || (nanDate || Date.parse(date) > Date.parse(filters[1]));
                } else if (Date.parse(filters[0])) {
                  return nanDate || Date.parse(date) < Date.parse(filters[0]);
                } else if (Date.parse(filters[1])) {
                  return nanDate || Date.parse(date) > Date.parse(filters[1]);
                }
                // console.log(typeof date)
                // console.log( filters[0])
                // console.log(Date.now())
                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Received</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />
                    <TextField
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />

                  </FormGroup>
                </div>
              ),
            },

          }
        },

        {
          name: 'member_card_expired_date',
          label: 'Expired',
          options: {
            filter: true,
            display: true,
            filterType: 'custom',

            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && cardExp.cardExpFilterChecked) {
                  return [`Exp Min Date: ${v[0]}`, `Exp Max Date: ${v[1]}`];
                } else if (v[0] && v[1] && !cardExp.cardExpFilterChecked) {
                  return `Exp Min Date: ${v[0]}, Exp Max Date: ${v[1]}`;
                } else if (v[0]) {
                  return `Exp Min Date: ${v[0]}`;
                } else if (v[1]) {
                  return `Exp Max Date: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },

            filterOptions: {
              names: [],
              logic(date, filters) {
                const nanDate = isNaN(Date.parse(date))
                if (filters[0] && filters[1]) {
                  return (nanDate || Date.parse(date) < Date.parse(filters[0])) || (nanDate || Date.parse(date) > Date.parse(filters[1]));
                } else if (Date.parse(filters[0])) {
                  return nanDate || Date.parse(date) < Date.parse(filters[0]);
                } else if (Date.parse(filters[1])) {
                  return nanDate || Date.parse(date) > Date.parse(filters[1]);
                }
                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Expired</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />
                    <TextField
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />

                  </FormGroup>
                </div>
              ),
            },

          }
        },


        {
          name: "is_active",
          label: 'Status',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => (
              <Label type={value ? 'success' : 'danger'} text={value ? 'active' : 'Inactive'} onClick={() => alert(1)} />
            ),
            filter: true,
            filterType: 'checkbox',
            filterOptions: {
              names: ['Active', 'Inactive'],
              logic(val, filterVal) {
                const show =
                  (filterVal.indexOf('Active') >= 0 && val) ||
                  (filterVal.indexOf('Inactive') >= 0 && !val);

                return !show;

              },
            },
          }
        },

        {
          name: "id",
          label: 'Actions',
          options: {
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                zIndex: 51
              }
            }),
            setCellProps: () => ({

              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 51
              }
            }),
            filter: false,
            print: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              const attrShow = {
                className: 'row-action',
                onClick: () => showDetail(value)
              }
              const attrEdit = {
                className: 'row-action',
                onClick: () => {
                  showForm('Edit', value)
                  scrollToTop()
                }
              }
              const attrDelete = {
                className: 'row-action',
                onClick: () => handleDelete([value])
              }

              return (
                <>
                  {
                    value > 2 && (
                      <>
                        <Permission
                          component={
                            <EditIcon {...attrEdit} />
                          }
                          type="update"
                          subMenu="member"
                        />
                        <Permission
                          component={
                            <DeleteIcon {...attrDelete} />
                          }
                          type="delete"
                          subMenu="member"
                        />
                      </>

                    )
                  }
                  <VisibilityIcon {...attrShow} />

                </>
              );
            }
          }
        },
      ],
    },
  })

  // console.log(data)

  return (
    <PrintProvider>
      <Print single name="print-member-table-1" >
        <KopSurat />
        <div className="spacer-60"></div>

        <ContentWrapper
          title="Member"
          subtitle="showing table of all data member"
          buttons={[
            {
              label: 'Add Member',
              style: 'OrangeMd',
              icon: 'plus-circle',
              onClick: () => showForm('New'),
              subMenu: 'member',
              type: 'create',
            }
          ]}
        >
          <>
            {
              (success || error) &&
              <Alert hide={() => dispatch(resetStatusMember())} option={{ type: success ? 'success' : 'error', message: (success || error) }} />
            }
            {
              loading ? <LoadingContent />
                : (
                  data.length ?
                    <Table {...table} className={classes.table} />
                    : <TableNoData />
                )
            }
          </>
        </ContentWrapper>
      </Print>
    </PrintProvider>

  )
}