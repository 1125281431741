import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Select from 'Elements/select/Select';
import { makeStyles } from '@material-ui/core/styles';
import LoadingContent from 'Elements/LoadingContent';
import { muiStyle, actionButton } from 'Configs/report'
import Button from 'Elements/Button';
import { makeGetParam, hostUrl, reqGet } from 'Configs/request.js';
import Filter from './Filter'
/* stock - product */
import { default as StockProduct } from './stock-product/General';
import { default as StockProductWeekMonth } from './stock-product/GeneralWeekMonth';
import { default as StockProductDetail } from './stock-product/Detail';
/* stock - branch */
import { default as StockBranch } from 'Pages/inventory/report/stock-branch/General';
import { default as StockBranchWeekMonth } from 'Pages/inventory/report/stock-branch/GeneralWeekMonth';
import { default as StockBranchDetail } from 'Pages/inventory/report/stock-branch/Detail';
import { default as StockBranchDetailWeekMonth } from 'Pages/inventory/report/stock-branch/DetailWeekMonth';
/* stcok - category */
import { default as StockCategory } from 'Pages/inventory/report/stock-category/General';
import { default as StockCategoryDetail } from 'Pages/inventory/report/stock-category/Detail';
/* stcok - brand */
import { default as StockBrand } from 'Pages/inventory/report/stock-brand/General';
import { default as StockBrandDetail } from 'Pages/inventory/report/stock-brand/Detail';
/* mutation */
import Mutation from './mutation/Mutation';
/* stockopname */
import Stockopname from './stockopname/Stockopname';
import StockOpnameDetail from './stockopname-detail/StockOpnameDetail';
import { globalMessage } from '../../../Helper';
import PrintProvider, { Print } from "react-easy-print";
import KopSurat from "../../../comps/elements/KopSurat";



const useStyles = makeStyles((theme) => (muiStyle))
const optsDetail = [
    {
        label: 'Summary by product',
        value: 'summary_product'
    },
    {
        label: 'Detail by product',
        value: 'detail_product'
    },
    {
        label: 'Summary by Category',
        value: 'summary_category'
    },
    {
        label: 'Detail by category',
        value: 'detail_category'
    },
    {
        label: 'Summary by brand',
        value: 'summary_brand'
    },
    {
        label: 'Detail by brand',
        value: 'detail_brand'
    },
    {
        label: 'Summary by branch',
        value: 'summary_branch'
    },
    // {
    //     label: 'Detail by branch',
    //     value: 'detail_branch'
    // }
]

const showGroupByStatus = ['summary_product', 'summary_branch', 'detail_branch'];


const getUrlReport = {
    'stockopname': `${hostUrl}/api/stock_opname/report`,
    'stockopname_detail': `${hostUrl}/api/stock_opname/detail/report`,
    'mutation': `${hostUrl}/api/product/report-mutation`,
    'stock': {
        'product': `${hostUrl}/api/product/report-stock`,
        'branch': `${hostUrl}/api/product/report-stock/branch`,
        'category': `${hostUrl}/api/product/report-stock/category`,
        'brand': `${hostUrl}/api/product/report-stock/brand`
    }
}


export default function Report() {

    const classes = useStyles();


    /* STATE */
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [extraFilter, setExtraFilter] = useState({});
    const [requiredError, setRequiredError] = useState([false])
    const [showGroupBy, setShowGroupBy] = useState(false)
    const [reportUrl, setReportUrl] = useState('');
    const [filter, setFilter] = useState({
        type: null,
        details: null,
        group_by: null,
    })
    const [isSummaryByCategory, setIsSummaryByCategory] = useState(false)


    /* METHOD */
    const handleSelect = select => {
        setData(null);
        setRequiredError([false]);


        let selectValue = select.selected.value;
        selectValue = selectValue === 'day' ? null : selectValue

        if (filter.details === 'summary_product') {
            setShowGroupBy(state => {
                return true
            })
        }

        if (select.selected.value === "stockopname" || "mutation" || ("stockopname_detail_stock" || "stockopname_detail_reject")) {
            setFilter(state => {
                return { ...state, details: null }
            })
        }

        if (select.selected.value === "summary_category") {
            setIsSummaryByCategory(true)
        } else {
            setIsSummaryByCategory(false)
        }

        setFilter(state => {
            state[select.props.name] = selectValue
            state['group_by'] = selectValue === 'detail_product' ? null : state['group_by']
            return { ...state };
        })

    }
    /* ~~ */


    const reqReport = async (params) => {
        const resp = await reqGet(params.url)
        setLoading(false);

        if (resp.status !== 'success') {
            globalMessage('Fetching report error', 'warning')
            return;
        }

        setData(resp.data);
    }
    const handleExtraFilter = objFilter => {
        const filteredByValue = Object.fromEntries(Object.entries(objFilter).filter(([key, value]) => {
            return key === "end_date" || key === "start_date" || key === "warehouse" || key === "status" || Array.isArray(value)
                ? value : !isNaN(value)
        }))
        setExtraFilter(filteredByValue)
    }

    const applyFilter = async () => {
        setData(null);

        let url = null;

        if (filter.type === 'stockopname_detail_stock' || filter.type === 'stockopname_detail_reject') {
            url = getUrlReport['stockopname_detail'];
        } else {
            url = getUrlReport[filter.type];
        }

        // jika main filter terpenuhi
        if (
            filter.type === 'stockopname' ||
            filter.type === 'mutation' ||
            filter.type === 'stock' ||
            (filter.type === 'stockopname_detail_stock' || filter.type === 'stockopname_detail_reject')
        ) {

            if (filter.type === 'stock') {
                if (!filter.details) {

                    setRequiredError(state => {
                        state[0] = !filter.details;
                        return { ...state }
                    })
                    return;

                }

                url = getUrlReport[filter.type][filter.details.split('_')[1]]
            }

            setLoading(true);

            let filterMod = {
                ...filter,
                details: filter.details && filter.details.includes('detail') ? 'true' : filter.details
            }
            const reportUrl = url + makeGetParam({ ...filterMod, ...extraFilter }, true);
            setReportUrl(reportUrl)
            await reqReport({ url: reportUrl })
        }
    }

    useEffect(() => {
        if (loading) {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }
    }, [loading])


    return (
        <div>
            <ContentWrapper
                title="Inventory Report"
                subtitle="Use available filter below to show custom criteria"
                buttons={actionButton({ reportUrl, flag: isSummaryByCategory })}
            >
                <div className="report report-pos" style={{ paddingBottom: '40px' }}>
                    <div className={`${classes.formfilter} col-3`}>
                        <Select
                            name="type"
                            label="Type"
                            getData={handleSelect}
                            options={[
                                { label: 'Stock', value: 'stock' },
                                { label: 'mutation of product', value: 'mutation' },
                                { label: 'Stock Opname', value: 'stockopname' },
                                { label: 'Stock Opname Difference Stock', value: 'stockopname_detail_stock'},
                                { label: 'Stock Opname Difference Reject', value: 'stockopname_detail_reject'}
                            ]}
                        />
                        {
                            filter.type === 'stock' && (
                                <>
                                    <Select
                                        name="details"
                                        label="Show by"
                                        getData={handleSelect}
                                        {
                                        ...(requiredError[0] ? {
                                            note: "Please select detail type",
                                            isError: true
                                        } : {})
                                        }
                                        options={optsDetail.map(({ label, value }) => ({ label, value }))}
                                    />
                                    {/* {
                                        (showGroupByStatus.includes(filter.details)) &&
                                        <Select
                                            name="group_by"
                                            label="Group by"
                                            getData={handleSelect}
                                            options={[
                                                { label: 'Day', value: 'day' },
                                                { label: 'Week', value: 'week' },
                                                { label: 'Month', value: 'month' }
                                            ]}
                                        />
                                    } */}

                                </>
                            )
                        }

                    </div>

                    {/* THE AWESOME FILTER  */}
                    <div className="mb-30">
                        <Filter
                            setFilter={setFilter}
                            type={filter.type}
                            getData={handleExtraFilter}
                        />
                    </div>

                    <div className="mb-20">
                        <Button onClick={applyFilter} style="OrangeWideMdRect" icon="check-circle" label="Show Report" />
                    </div>





                    <PrintProvider>
                        <Print single name="print-pos-report-table-1" >
                            {
                                data && (
                                    <>
                                        {filter.type === 'stockopname' && <Stockopname filter={filter} data={data} />}
                                        {(filter.type === 'stockopname_detail_stock' || filter.type === 'stockopname_detail_reject') && <StockOpnameDetail filter={filter} data={data} detailType={filter.type}/>}
                                        {filter.type === 'mutation' && <Mutation filter={filter} data={data} />}
                                        {/* product */}
                                        {
                                            (filter.details === 'summary_product' && !filter.group_by) &&
                                            <StockProduct data={data} />
                                        }
                                        {
                                            (filter.details === 'summary_product' && filter.group_by) &&
                                            <StockProductWeekMonth group_by={filter.group_by} data={data} />
                                        }
                                        {
                                            filter.details === 'detail_product' &&
                                            <StockProductDetail data={data} />
                                        }
                                        {/* branch */}
                                        {
                                            (filter.details === 'summary_branch' && !filter.group_by) &&
                                            <StockBranch data={data} />
                                        }
                                        {
                                            (filter.details === 'summary_branch' && filter.group_by) &&
                                            <StockBranchWeekMonth group_by={filter.group_by} data={data} />
                                        }
                                        {
                                            (filter.details === 'detail_branch' && !filter.group_by) &&
                                            <StockBranchDetail group_by={filter.group_by} data={data} />
                                        }
                                        {
                                            (filter.details === 'detail_branch' && filter.group_by) &&
                                            <StockBranchDetailWeekMonth group_by={filter.group_by} data={data} />
                                        }
                                        {
                                            filter.details === 'summary_category' &&
                                            <StockCategory data={data} />
                                        }
                                        {
                                            filter.details === 'detail_category' &&
                                            <StockCategoryDetail data={data} />
                                        }
                                        {
                                            filter.details === 'summary_brand' &&
                                            <StockBrand data={data} />
                                        }
                                        {
                                            filter.details === 'detail_brand' &&
                                            <StockBrandDetail data={data} />
                                        }
                                    </>
                                )
                            }

                        </Print>
                    </PrintProvider>

                    {loading ? <LoadingContent /> : null}

                </div>
            </ContentWrapper>
        </div>
    )
}
