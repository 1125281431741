import isElectron from "is-electron";
import { globalMessage } from '@/Helper'

export default function print_receipt(data_receipt, options) {
  if (isElectron()) {
    console.log("struct : " + JSON.stringify(data_receipt));
    let { remote } = window.require("electron");

    console.log(remote.app.getAppPath());
    const { PosPrinter } = remote.require("electron-pos-printer");
    const path = require("path");

    const listPrinter = remote.BrowserWindow.getFocusedWindow().webContents.getPrinters();
    const found = listPrinter.find(printer => printer.name === options.printerName);
    if (!found) {
      throw new Error('PW_POS_PRINTER Not Found');
    }

    function Padder(len, pad) {
      if (len === undefined) {
        len = 1;
      } else if (pad === undefined) {
        pad = "0";
      }

      var pads = "";
      while (pads.length < len) {
        pads += pad;
      }

      this.pad = function (what) {
        var s = what.toString();
        return pads.substring(0, pads.length - s.length) + s;
      };
    }

    const items = [];
    data_receipt.items.map((x, i) => {
      const indexPad = new Padder(3);

      const product_detail =
        "[" +
        x.code +
        "] <br>" +
        x.name +
        "<br>" +
        x.qty +
        " x " +
        x.price.toLocaleString() +
        "<br>Size: " +
        x.size +
        " Color: " +
        x.color +
        (x.discount_item_type ? "<br>" + x.discount_item_type : "") +
        (x.return !== "-" ? "<br>" + x.return : "");

      const total =
        x.discount_item_value < 0
          ? "<br><br>" +
          x.total.toLocaleString() +
          "<br><br>" +
          x.discount_item_value.toLocaleString()
          : x.total.toLocaleString();

      items.push([
        {
          type: "text",
          value: indexPad.pad(i + 1) + "<br><br><br><br>",
          style: "text-align:left;",
        },
        { type: "text", value: product_detail, style: "text-align:left;" },
        { type: "text", value: total, style: "text-align:right;" },
      ]);
    });

    const data = [
      {
        type: "image",
        path: path.join(remote.app.getAppPath(), "build/logo-black.png"), // file path
        position: "center", // position of image: 'left' | 'center' | 'right'
        width: "auto", // width of image in px; default: auto
        height: "60px", // width of image in px; default: 50 or '50px'
      },
      {
        type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
        value:
          data_receipt.company_name +
          " " +
          data_receipt.branch_name +
          "<br>" +
          data_receipt.address +
          "<br>" +
          data_receipt.phone +
          "<br><br>",
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "table",
        tableBody: [
          [
            {
              type: "text",
              value: "Receipt No<br>Cashier<br>Cust",
              style: `text-align:left;column-width: 85px;`,
            },
            { type: "text", value: ":<br>:<br>:", style: `text-align:center;` },
            {
              type: "text",
              value:
                data_receipt.receipt_no +
                "<br>" +
                data_receipt.cashier_name +
                "<br>" +
                capitalizeFirstLetter(data_receipt.customer_type),
              style: `text-align:left;`,
            },
            // {
            //   type: "text",
            //   value: date(),
            //   style: `text-align:right;`,
            // },
          ],
        ],
        css: {
          "font-size": "11px",
          "font-family": "Lucida Console",
          "max-width": "270px",
          "margin-left": "10px",
          border: "0px solid #ffffff",
        },
        tableBodyStyle: `border: none;`,
      },
      {
        type: "text",
        value: "<br>" + date(),
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "table",
        tableBody: items,
        css: {
          "font-size": "11px",
          "font-family": "Lucida Console",
          "max-width": "270px",
          "margin-left": "10px",
          border: "0px solid #ddd",
        },
        tableBodyStyle: `border: none;`,
      },
      {
        type: "text",
        value: "<br>",
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "text",
        value:
          "Total item: " +
          data_receipt.total_item +
          " Total qty: " +
          data_receipt.total_qty +
          "<br><br>",
        style: `text-align:left;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "table",
        tableBody: [
          [
            {
              type: "text",
              value:
                // "Subtotal" +
                // (data_receipt.discount_type
                //   ? " [" + data_receipt.discount_type + "]"
                //   : "") +
                // "<br>Tax<br>" + 
                "Total<br>" + "Payment [" +
                (data_receipt.payment_card_no
                  ? data_receipt.payment_type.replace(/_/gi, " ") +
                  " [" +
                  data_receipt.payment_card_no +
                  "]"
                  : capitalizeFirstLetter(data_receipt.payment_type.replace(/_/gi, " "))) +
                "]<br>Change",
              style: `text-align:left;column-width: 130px;`,
            },
            {
              type: "text",
              value:
                // data_receipt.subtotal.toLocaleString() +
                // "<br>" +
                // data_receipt.discount_value.toLocaleString() +
                // "<br>" +
                // data_receipt.tax.toLocaleString() +
                // "<br>" +
                data_receipt.total.toLocaleString() +
                "<br>" +
                data_receipt.pay.toLocaleString() +
                "<br>" +
                data_receipt.change.toLocaleString(),
              style: `text-align:right;`,
            },
          ],
        ],
        css: {
          "font-size": "11px",
          "font-family": "Lucida Console",
          "max-width": "270px",
          "margin-left": "10px",
          border: "0px solid #ddd",
        },
        tableBodyStyle: `border: none;`,
      },
      {
        type: "text",
        value: "<br><br>",
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "text",
        value:
          data_receipt.customer_type === "umum" || data_receipt.customer_type === "general"
            ? "<br>"
            : "[" +
            data_receipt.member_name +
            "] " +
            capitalizeFirstLetter(data_receipt.customer_type) +
            "<br>Member Card : " +
            data_receipt.member_code +
            "<br><br>",
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "text",
        value: "Thank you for coming!<br><br>",
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
      {
        type: "text",
        value: "www.phillipworks.com<br><br>",
        style: `text-align:center;`,
        css: { "font-size": "11px", "font-family": "Lucida Console" },
      },
    ];

    // let webContents = remote.getCurrentWebContents();
    // let printers = webContents.getPrinters(); //list the printers
    // console.log(printers);

    function date() {
      const x = new Date();
      const h = "0" + x.getHours();
      const m = "0" + x.getMinutes();
      const s = "0" + x.getSeconds();
      const d = "0" + x.getDate();
      const y = x.getFullYear().toString();
      const ms = x.getMonth();
      const meses = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "June",
        "July",
        "Aug",
        "Sept",
        "Oct",
        "Nov",
        "Dec",
      ];

      return (
        d.substr(-2) +
        "/" +
        (ms + 1) +
        "/" +
        y.substr(-2) +
        " " +
        h.substr(-2) +
        ":" +
        m.substr(-2) +
        ":" +
        s.substr(-2)
      );
    }

    function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

    let printerName = options.printerName;
    let widthPage = "300px";

    const options_print = {
      preview: false, // Preview in window or print
      width: widthPage, //  width of content body
      margin: "0 0 0 0", // margin of content body
      copies: 1, // Number of copies to print
      printerName: printerName, // printerName: string, check it at webContent.getPrinters()
      timeOutPerLine: 400,
      silent: true,
    };

    const now = {
      type: "text",
      value: "" + date() + "<br>",
      css: {
        "font-size": "11px",
        "font-family": "Lucida Console",
        "text-align": "center",
      },
    };

    const d = [...data];

    if (printerName && widthPage) {
      for (let x = 0; x < 2; x++) { // jumlah lembar yang di print
        PosPrinter.print(d, options_print)
        .then(() => { })
        .catch((error) => {
          console.error(error);
        });
      }
    } else {
      globalMessage("Select the printer and the width", 'info');
    }
    console.log(data);
  }
}
