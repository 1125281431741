import React from 'react'
import Dashboard from 'Pages/Dashboard'
import Sale from 'Pages/pos/sales/Sales'
import DataEdc from 'Pages/pos/edc/Edc'
import PromotionConfiguration from 'Pages/pos/promotion_configuration/PromotionConfiguration'
import Submodule from 'Comps/layouts/Submodule'
import { default as ReportPos } from 'Pages/pos/report-pos/Report'
import { default as ReportCategory } from 'Pages/pos/report-category/Report'
import { default as ReportProduct } from 'Pages/pos/report-product/Report'
import { default as ReportPromotion } from 'Pages/pos/report-promotion/Report'
import { default as ReportBrand } from 'Pages/pos/report-brand/Report'
import { default as ReportPayment } from 'Pages/pos/report-payment/Report'


const Pos = (props) => {

    const pageTitle = {
        title: 'Point of Sale',
        description: 'the time and place where a retail transaction is completed',
    }

    const breadcrumb = [
        { label: 'Home' },
        { label: 'Point of Sale' },
        { label: 'Sale', active: true },
    ]

    const subModules = [
        {
            label: 'Sales',
            component: Sale,
            permissionName: 'sales_pos',
            permissionType: 'read',
        },
        {
            label: 'Promotion Configuration',
            component: PromotionConfiguration,
            permissionName: 'promotion',
            permissionType: 'read',
        },
        {
            label: 'EDC Data',
            component: DataEdc,
            permissionName: 'edc',
            permissionType: 'read',
        },
        {
            label: 'Report',
            child: [
                { label: 'POS Sales', component: ReportPos },
                { label: 'Sales by Product ', component: ReportProduct },
                { label: 'Sales by Category ', component: ReportCategory },
                { label: 'Sales by Promotion', component: ReportPromotion },
                { label: 'Sales by Brand', component: ReportBrand },
                { label: 'Sales by Payment Method', component: ReportPayment },
            ],
            permissionType: 'read',
            permissionName: 'report_pos'
        },
    ]

    return (
        <Dashboard pageTitle={pageTitle} breadcrumb={breadcrumb} >
            <Submodule subModules={subModules} />
        </Dashboard>
    )
}


export default Pos