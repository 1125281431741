import React, {useState} from 'react'
import ImageIcon from '@material-ui/icons/Image';
import { money, makeImgUrl } from '@/Helper'
import { Lightbox } from "react-modal-image";

export default function DetailHeader(props) {


    const [openLight, setOpenLight] = useState(false)
    const closeLightbox = () => {
         setOpenLight(false)
    };

    const { data, className = '' } = props;
    const generateItem = (key, index) => {
        const keyAsClass = key.replace(' ', '_').toLowerCase();
        if (key === 'status') {
            return (
                <div className={`col ${keyAsClass}`} key={index}>
                    <span className="key">{key}</span>
                    <span className="val">
                        <label className={`status-text ${data[key]}`}>{data[key]}</label>
                    </span>
                </div>
            )
        } else if (key === 'email') {
            return (
                <div className={`col ${keyAsClass}`} key={index}>
                    <span className="key">{key}</span>
                    <span className="val">
                        <a href={`mailto: ${data[key]}`}>{data[key]}</a>
                    </span>
                </div>
            )
        } else if (key === 'Proof Member') {
            return (
                <div className={`col ${keyAsClass}`} key={index}>
                    <span className="key">{key}</span>
                    <span className="val">
                        { 
                            data['Proof Member'] === makeImgUrl(null) || data['Proof Member'] === makeImgUrl("") ?'-':
                            <a onClick={() => setOpenLight(true)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                <ImageIcon style={{ fontSize: '16px ' }} />
                                <p style={{ marginLeft: '5px' }}>Preview Image</p>
                            </a> 
                        }
                    </span>
                </div>
            )
        
        }
        else {
            return (
                <div className={`col ${keyAsClass}`} key={index}>
                    <span className="key">{key}</span>
                    <span className="val">{data[key] || '-'}</span>
                </div>
            )
        }
    }


    return (
        <div className={`detail style-2 ${className}`}>

             {
                openLight && (
                <Lightbox
                    medium={makeImgUrl(data['Proof Member'])}
                    onClose={closeLightbox}
                />
                  )
            }
            
            {
                Object.keys(data).map((key, dataIndex) => generateItem(key, dataIndex))
            }
        </div>
    )
}
