import {
    CONTACT_LOADING,
    CONTACT_SET,
    CONTACT_ERROR,
    CONTACT_ADD,
    CONTACT_SHOW,
    CONTACT_DELETE,
    CONTACT_SET_FILTER,
    CONTACT_RESET,
    CONTACT_EDIT,
    CONTACT_UPDATE
} from './contactActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
    dataFilter: [],
}

const contactReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case CONTACT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case CONTACT_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case CONTACT_SET_FILTER:
            return {
                ...state,
                error: null,
                loading: false,
                dataFilter: payload
            }
        case CONTACT_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New contact has been added successfully.'
            }
        case CONTACT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case CONTACT_SHOW:
            return {
                ...state,
                idShow: payload
            }
        case CONTACT_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case CONTACT_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case CONTACT_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case CONTACT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        default:
            return state;
    }
}

export default contactReducer