export const matchEditValue = (fieldGroup, dataEdit) => {
     
    const res = {};

    Object.keys(dataEdit).map(x => {
        fieldGroup.map(fg => {
            if (
                fg.fields.find(f => f.name === x)
            ) {
                res[x] = dataEdit[x];
            }
        })
    })

    return res;

}

export const serverValue = (v, nullable = false) => {
    let res = {}

    Object.keys(v).forEach(x => {

        let defVal = v[x]
        let c;

        if (defVal !== null) {
            c = +defVal;
        }

        if(x[0] === '_' || ['_label', 'fieldGroup'].includes(x)) {
            return;
        }


        if (defVal === '' && nullable) {
            defVal = null
        } else if(defVal === '' || Array.isArray(defVal)){
            defVal = defVal;
        } else if(x === 'phone') {
            defVal = defVal;
        } else if (defVal === 'true' || defVal === true) {
            defVal = true;
        } else if (defVal === 'false' || defVal === false) {
            defVal = false;
        } else if (defVal === 'null' || defVal === null) {
            defVal = null
        } else if (typeof c === 'number' && isFinite(defVal)) {
            defVal = +defVal
        }
        res[x] = defVal
    })

    return res;

}
export const localValue = v => {
    let res = {}

    Object.keys(v).forEach(x => {
        let defVal = v[x]

        if (defVal === true) {
            defVal = 'true';
        } else if (defVal   === false) {
            defVal = 'false';
        } else if (defVal === null) {
            defVal = 'null';
        }

        res[x] = defVal
    })

    return res;

}
export const conv = strToCheck => {
    if(strToCheck === 'true' || strToCheck === true) {
        return true;
    } else if (strToCheck === 'false' || strToCheck === false) {
        return false;
    } else if (typeof parseInt(strToCheck) !== NaN) {
        return parseInt(strToCheck)
    }
}