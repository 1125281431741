import React, { useState, useEffect } from 'react'
// import { makeImgUrl } from '@/Helper'
import { reqGet, hostUrl } from 'Configs/request'
import logo from "../../assets/img/phillipworks-bg.png"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export default function KopSurat({ title, nomor, fit = false }) {
    const [data, setData] = useState({})
    const getProfile = async () => {
        const online = await isOnline()
        const resp = await reqGet(`${online ? hostUrl : offlineUrl}/api/company`)
        if (resp.status) {

            const fullAddress = [
                resp?.data?.[0]?.address,
                resp?.data?.[0]?.city,
                resp?.data?.[0]?.province,
                resp?.data?.[0]?.country,
            ]

            setData({
                company_name: resp.data[0].company_name,
                address: fullAddress.filter(Boolean).join(', '),
                phone: resp?.data?.[0]?.phone ?? '-',
                email: resp?.data?.[0]?.email ?? '-',
                website: resp?.data?.[0]?.site_address ?? '-',
                // logo: makeImgUrl(resp.data[0].logo_url)
                logo
            })
        }
    }

    useEffect(() => {
        getProfile()
    }, [])

    return (
        <div className="kop-surat-component">
            <div className="kop-surat">
                <div className="kop-surat-image">
                    <div className="logo">
                        <img src={data.logo} alt="logo" style={fit ? { width: '150px', height: '105px' } : {}} />
                    </div>
                </div>
                <div className="kop-surat-information" style={fit ? { marginTop: '0px' } : {}}>
                    <div>
                        <div className="company-name" style={fit ? { fontSize: '20px' } : {}}>
                            {data.company_name}
                        </div>
                        <div className="company-address" style={fit ? { fontSize: '13px', paddingTop: '3px' } : {}}>
                            {data.address}
                        </div>
                        <div className="company-phone" style={fit ? { fontSize: '13px', paddingTop: '1px' } : {}}>
                            Ph: {data.phone}
                        </div>
                        <div className="company-email" style={fit ? { fontSize: '13px', paddingTop: '1px' } : {}}>
                            Email: {data.email}
                        </div>
                        <div className="company-website" style={fit ? { fontSize: '13px', paddingTop: '1px' } : {}}>
                            {data.website}
                        </div>
                    </div>
                </div>
            </div>
            <div className={title ? "line-header" : "line-header-non-title"}></div>
            {
                title ? <div className="title">{title.toUpperCase()}</div> : <div></div>
            }
            {
                nomor ? <div className="nomor">ID: {nomor}</div> : <div></div>
            }
        </div>
    )
}
