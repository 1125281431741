import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from 'Elements/Label'
import Alert from 'Comps/elements/Alert'
import Table from 'Elements/Table'
import TableNoData from 'Comps/elements/TableNoData'
import { deleteBrand, resetStatusBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import LoadingContent from 'Elements/LoadingContent';
import { scrollToTop, globalDialog } from '@/Helper'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Permission from '../../../../comps/permission/Permission'


export default function TableData(props) {
    const { showDetail, showForm } = props;
    const { data, loading, error, success } = useSelector(state => state.brandReducer);
    const classes = globalTableStyle();
    const dispatch = useDispatch();
    const handleDelete = id => {
        globalDialog(async () => {
            await dispatch(deleteBrand(id))
            scrollToTop()
        })
    }
    const table = tableOpts({
        handleDelete,
        data: data?.filter(brand => brand.id > 1),
        colIdPosition: 0,
        filter: false,
        noPrint: true,
        filename: "Brand_Configuration_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        viewColumns: false,
                        display: false,
                        filter: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                { name: "code", label: 'Code' },
                { name: "name", label: 'Brand Name' },
                {
                    name: "category",
                    label: 'Category',
                    options: {
                        customBodyRender: (value) => {
                            const result = value.charAt(0).toUpperCase() + value.slice(1)
                            return result
                        }
                    }
                },
                {
                    name: "status",
                    label: 'Status',
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Label type={value ? 'success' : 'danger'} text={value ? 'active' : 'Inactive'} />
                            )
                        }
                    }
                },
                {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        customBodyRender: (value, tableMeta, updateValue) => {

                            const attrShow = {
                                className: 'row-action',
                                onClick: () => showDetail(value)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => {
                                    showForm('Edit', value)
                                    scrollToTop()
                                }
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => handleDelete([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />

                                    <Permission
                                        component={
                                            <EditIcon {...attrEdit} />
                                        }
                                        subMenu="brand"
                                        type="update"
                                    />

                                    {
                                        value > 1 &&
                                        <Permission
                                            component={
                                                <DeleteIcon {...attrDelete} />
                                            }
                                            subMenu="brand"
                                            type="delete"
                                        />
                                    }
                                </>
                            );
                        }
                    }
                },
            ],
        },
        deleteAct: (ids) => {
            dispatch(deleteBrand(ids))
        }
    })


    return (


        <ContentWrapper
            title="Brand"
            subtitle="showing table data of brand"
            buttons={[
                {
                    label: 'Add Brand',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => showForm('New'),
                    type: 'create',
                    subMenu: 'brand',
                }
            ]}
        >
            <>
                {
                    success &&
                    <Alert hide={() => dispatch(resetStatusBrand())} option={{ type: 'success', message: success }} />
                }
                {
                    error &&
                    <Alert hide={() => dispatch(resetStatusBrand())} option={{ type: 'error', message: error }} />
                }
                {
                    loading ? <LoadingContent />
                        : (
                            data.length ?
                                <Table {...table} className={classes.table} />
                                : <TableNoData />
                        )
                }
            </>
        </ContentWrapper>

    )
}

