import React from 'react'
import Img from 'Img/analytics.png'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'grid',
        placeItems: 'center',
        // background: '#fafafa',
        // border: '1px solid #eee',
        marginBottom: '30px',
        borderRadius: '3px',
        padding: '50px 0',
        '& p': {
            textAlign: 'center',
            '& span:nth-child(1)': {
                color: '#555',
                display: 'block',
                fontSize: '18px',
                fontWeight: '700'
            },
            '& span:nth-child(2)': {
                color: '#aaa',
                display: 'block',
                fontSize: '13px',
                margin: '10px auto 0',
                lineHeight: '20px'
            }
        }
    },
    img: {
        width: '70px',
        opacity: '.1',
        marginBottom: '30px'
    },

}))


export default function NoReport() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <img src={Img} className={classes.img} alt="no data icon" />
            <p>
                <span>No Data Found</span>
                <span>No data found with current filters<br />try to change filter may show some results.</span>
            </p>
        </div>
    )
}
