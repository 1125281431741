import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { loadBranch } from 'Reduxes/configuration/branch/branchActions';
import { Button, CircularProgress } from '@material-ui/core';
import Datepicker from 'Elements/Datepicker';
import { getLocalStorageAuth } from '../../../Helper';

const animatedComponents = makeAnimated();
const useStyles = makeStyles((theme) => ({
    minWidth: {
        minWidth: '100% !important',
    },
    button: {
        padding: '20px 0 15px',
        marginBottom: '10px',
        borderTop: '1px dashed #eee',
        marginRight: '16px',
        borderRadius: '3px',
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: '13px',
        height: '48px',
    },

    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: '500',
        textTransform: 'capitalize',
    },
    spacer: {
        height: '25px',
    },
    halfWidth: {
        width: '80%',
    },
    formextrafiltercontainer: {
        padding: '44px 20px 20px',
        marginTop: '15px',
        borderRadius: '10px',
        background: '#fafafa',
        border: '1px solid #eee',
        '& label': {
            fontSize: '12px',
            color: '#555',
        },
    },
    daterangeCont: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '10px',
    },
}));

const StockOpnameFilter = ({ handleFilterSubmit, isLoading, stateFilters }) => {
    const dispatch = useDispatch();
    const isSuperAdmin =
        getLocalStorageAuth()?.user_category_id === 1 ||
        getLocalStorageAuth()?.user_category_id === 2;

    /**
     * State for handling Filter Stock Opname
     */
    const [branches, setBranches] = useState([]);
    const [isChildLoading, setChildLoading] = useState(true);

    useEffect(() => {
        // Fetch Brands
        const fetchBranch = async () => {
            if (isSuperAdmin) {
                const resp = await dispatch(loadBranch());
                if (resp.status) {
                    resp.data = resp.data.map((data) => {
                        return {
                            value: data.id,
                            label: data.branch_name,
                        };
                    });

                    setBranches(resp.data);
                }
            }
        };

        fetchBranch();

        setTimeout(() => {
            setChildLoading(false);
        }, 300);
    }, []);

    const localClass = useStyles();

    const [filters, setFilters] = useState(stateFilters);

    return (
        <div style={{ width: '600px', height: '600px' }}>
            <div className={localClass.spacer}></div>

            {isChildLoading ? (
                <CircularProgress size={30} style={{ margin: '0 auto' }} />
            ) : (
                <>
                    <div className={localClass.spacer}></div>

                    {isSuperAdmin && (
                        <div className={localClass.halfWidth}>
                            <label
                                htmlFor='Filter by Branches'
                                className={localClass.label}>
                                Filter by Branches
                            </label>
                            <Select
                                className={localClass.minWidth}
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                onChange={(selected) =>
                                    setFilters({
                                        ...filters,
                                        branches: selected,
                                    })
                                }
                                value={filters?.branches}
                                isMulti
                                options={branches}
                            />
                        </div>
                    )}
                    <div className={localClass.spacer}></div>

                    <div className={localClass.halfWidth}>
                        <label
                            htmlFor='Filter by Stock Opname Status'
                            className={localClass.label}>
                            Filter by Stock Opname Status
                        </label>
                        <Select
                            className={localClass.minWidth}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(selected) =>
                                setFilters({ ...filters, statuses: selected })
                            }
                            value={filters?.statuses}
                            isMulti
                            options={[
                                { value: 'draft', label: 'Draft' },
                                { value: 'open', label: 'Open' },
                                { value: 'checked', label: 'Confirmed' },
                            ]}
                        />
                    </div>
                    <div className={localClass.spacer}></div>

                    <div className={localClass.halfWidth}>
                        <label
                            htmlFor='Filter by Stock Opname Results'
                            className={localClass.label}>
                            Filter by Stock Opname Results
                        </label>
                        <Select
                            className={localClass.minWidth}
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            onChange={(selected) =>
                                setFilters({ ...filters, results: selected })
                            }
                            value={filters?.results}
                            isMulti
                            options={[
                                { value: 'match', label: 'Match' },
                                { value: 'selisih', label: 'Not Match' },
                            ]}
                        />
                    </div>
                    <div className={localClass.spacer}></div>

                    <div className={localClass.daterangeCont}>
                        {
                            <Datepicker
                                handleChange={({ value }) => {
                                    setFilters({
                                        ...filters,
                                        date_from: value,
                                    });
                                }}
                                label='Start date'
                                name='start_date'
                                value='blank'
                            />
                        }

                        <Datepicker
                            {...(filters?.date_to
                                ? { minDate: filters.date_to }
                                : {})}
                            name='end_date'
                            handleChange={({ value }) => {
                                setFilters({ ...filters, date_to: value });
                            }}
                            label='end date'
                            value='blank'
                        />
                    </div>

                    <div className={localClass.spacer}></div>

                    <Button
                        onClick={() => handleFilterSubmit(filters)}
                        disabled={isLoading}
                        startIcon={
                            isLoading && (
                                <CircularProgress
                                    size={24}
                                    style={{ color: '#fff' }}
                                />
                            )
                        }
                        className={`${localClass.minWidth} ${localClass.button}`}
                        style={{ backgroundColor: '#f78300', color: '#fff' }}>
                        Apply Filters
                    </Button>
                </>
            )}
        </div>
    );
};

export default StockOpnameFilter;
