import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    // name:
    //     Yup.string()
    //         .required('Contact name is required')
    //         .matches(/^[a-z 0-9]+$/i, 'Only alphabet numeric allowed'),
    branch_id: Yup.string()
        .required('Branch Name is required'),
    contact_id: Yup.string()
        .required('Destination is required'),
    // qty: Yup.string()
    //     .required('Quantity is required'),
    // brand_id: Yup.string()
    //     .required('Brand is required'),
    // unit_of_measurement_id: Yup.string()
    //     .required('UoM is required'),
    // color_id: Yup.string()
    //     .required('Color is required'),
    // phone:
    //     Yup.string()
    //         .max(15, 'Maximum phone number is 15 character')
    //         //         .required('Phone number  is required')
    //         .nullable()
    //         .matches(/^\d+$/, 'Phone number should have digits only'),
    // address:
    //     Yup.string()
    //         .required('Address is required'),
    // province:
    //     Yup.string()
    //         .required('Province is required'),
    // city:
    //     Yup.string()
    //         .required('City is required'),
    // postal_code:
    //     Yup.string()
    //         .required('Postal code is required')
    //         .matches(/^\d+$/, 'Postal code should have digits only'),
})

