import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    date_from:
        Yup.string()
            .required('Date From is required'),
    date_to:
        Yup.string()
            .required('Date To is required'),
})

