import React, { useEffect, useState } from 'react'
import { loadCategory } from 'Reduxes/inventory/configuration/category/categoryActions'
import { useSelector, useDispatch } from 'react-redux';
import Select from 'Elements/select/Select'
import Input from 'Elements/Input'
import Button from 'Elements/Button'
import { Formik, useFormikContext, Form, Field, FieldArray, ErrorMessage } from 'formik';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { serverValue } from 'Configs/Form';
import InputNum from 'Elements/inputnum/InputNum'
import { globalMessage, globalDialog } from '../../../../Helper';

export default function FormComission(props) {

    const { defaultData, getData } = props;
    const formik = useFormikContext();
    const loading = 0;
    const { data, error } = useSelector(state => state.categoryReducer)
    const dispatch = useDispatch();
    const [tableComission, setTableComission] = useState(defaultData ? defaultData.map(x => ({ name: x.category_name, value: x.comission })) : [])



    /* clean */

    const [items, setItems] = useState(defaultData || []);
    const [selectedCat, setSelectedCat] = useState(null);
    const [comission, setComission] = useState(null);

    const selectCat = select => {
        setSelectedCat(select.selected)
    }
    const handleNum = ({ value }) => {
        setComission(value || 0)
    }
    const handleAdd = () => {

        if (!selectedCat) {
            return;
        }

        const isAlrAdded = items.find(x => x.category_id === selectedCat.value);
        if (isAlrAdded) {
            globalMessage('Category has been added already', 'warning')
            return;
        }

        formik.setFieldValue('custom_comission_input', 0)
        setComission(0)

        setItems(state => {
            return [
                ...state,
                { category_name: selectedCat.label, category_id: selectedCat.value, comission: comission || 0 }
            ]
        })
    }
    const handleDelete = idx => {
        globalDialog(() => {
            setItems(items.filter((x, y) => y !== idx))
        })
    }

    useEffect(() => {
        getData(items)
    }, [items])

    useEffect(() => {
        if (!items.length) {
            setItems(defaultData)
        }
    }, [defaultData])


    // * * *


    return (
        <>
            {
                loading ?
                    'Please wait'
                    : (
                        error ? 'Fail to load category data'
                            : (
                                <>
                                    <div className="fields-group-container">
                                        <p className="field-group-title">Custom Commission</p>

                                        <Select
                                            name="custom_comission_select"
                                            getData={selectCat}
                                            label="Commission / Category"
                                            options="category"
                                        />

                                        <InputNum
                                            getData={handleNum}
                                            label="Commission (%)"
                                            name="custom_comission_input"
                                            max={100}
                                        />

                                        <Button
                                            onClick={handleAdd}
                                            style="LightblueSubbtn"
                                            label=""
                                            icon="plus" />
                                    </div>


                                    {
                                        items.length > 0 &&
                                        <table className="table table-center table-brand-extra-form">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Category</th>
                                                    <th>Commission</th>
                                                    <th>Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    items.map((item, itemIdx) => (
                                                        <tr key={itemIdx}>
                                                            <td>{itemIdx + 1}</td>
                                                            <td>{item.category_name}</td>
                                                            <td>{item.comission} %</td>
                                                            <td><DeleteIcon onClick={() => handleDelete(itemIdx)} /></td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    }


                                </>
                            )
                    )
            }

        </>
    )
}
