import React, { useState, useEffect } from 'react'
import { money, handleSortState, sortData } from '@/Helper'
import TdSort from 'Elements/TdSort'

const headerEnd = {
    stock: {
        has_sort: true,
        sort_type: null,
        label: 'stock'
    },
    reject: {
        has_sort: true,
        sort_type: null,
        label: 'reject'
    },
    uom_name: {
        has_sort: true,
        sort_type: null,
        label: 'pcs'
    },
    total_value: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'total value'
    },
}

export default function GeneralRowChild({ show, indexParent, categories, variants }) {

    const [rows, setRows] = useState(categories)
    const [header, setHeader] = useState({
        no: {
            label: 'no.',
            has_sort: false,
        },
        code: {
            has_sort: true,
            sort_type: null,
            label: 'code'
        },
        type: {
            has_sort: true,
            sort_type: null,
            label: 'type'
        },
        name: {
            has_sort: true,
            className: 'td-width-as-content',
            sort_type: null,
            label: 'Category'
        },
        total_product: {
            has_sort: true,
            colspan: 2,
            sort_type: null,
            className: 'cell-tr',
            label: '#Product'
        },
    })
    const sortColumn = (colName, variantIndex = 1000) => {
        setRows(prevState => {
            prevState = sortData({ type: header[colName].sort_type, column: colName, data: prevState })
            return [...prevState]
        })

        setHeader(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {
        let variantHeader = {}
        let initRows = []

        Object.keys(variants).map((variant, variantIdx) => {
            variantHeader[`variant${variantIdx}`] = {
                has_sort: true,
                sort_type: null,
                className: 'cell-tr',
                label: ' '
            }
        })
        initRows = categories.map(product => {
            variants.forEach((lv, lvIdx) => {
                let qty = 0;

                product.variants.forEach(v => {
                    qty += v?.types?.[lvIdx]?.stock ?? 0
                })

                product[`variant${lvIdx}`] = qty
            })
            return product
        })

        setHeader(prev => {
            return {
                ...prev,
                ...variantHeader,
                ...headerEnd
            }
        })
        setRows(initRows)

    }, [categories, variants])

    // console.clear()
    // console.log(rows)

    return show && (
        <>
            {
                rows.map((row, rowIdx) => (
                    <React.Fragment key={`child${rowIdx}`}>
                        {
                            rowIdx === 0 && (
                                <tr className="tr-detail detail-heading">
                                    {
                                        Object.keys(header).map((key, idx) => (
                                            <TdSort
                                                key={`th-${idx}`}
                                                label={header[key].label}
                                                rowspan={header[key].rowspan}
                                                colspan={header[key].colspan}
                                                sort={header[key].has_sort}
                                                handleClick={() => sortColumn(key)}
                                                className={`${header[key].className}  ${header[key].sort_type}`}
                                            />
                                        ))
                                    }
                                </tr>
                            )
                        }
                        <tr>
                            <td>{indexParent}.{rowIdx + 1}</td>
                            <td className="cell-width-as-content">{row.code}</td>
                            <td className="cell-width-as-content">{row.type}</td>
                            <td className="cell-width-as-content" >{row.name}</td>
                            <td colSpan={2} className="cell-currency cell-tr">{money(row.total_product)}</td>
                            {
                                variants.map((lv, lvIdx) => (
                                    <td key={`variantchild${lvIdx}`}>{row[`variant${lvIdx}`]}</td>
                                ))
                            }
                            <td>{row.total_stock}</td>
                            <td>{row.total_reject}</td>
                            <td>Pcs</td>
                            <td className="cell-tr cell-currency">{money(row.total_value)}</td>
                        </tr>
                    </React.Fragment>
                ))
            }
        </>
    )
}
