import React, { useState, useEffect } from 'react'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport'
import { money, dateDMY, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';
import { Helmet } from 'react-helmet'


export default function TableDetail({ data }) {

    const [scroll, setScroll] = useState(true)
    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        },
        date: {
            label: 'date',
            has_sort: true,
            sort_type: null,
        },
        branch_name: {
            label: 'branch',
            has_sort: false
        },
        sales_type: {
            label: 'sales',
            has_sort: false,
        },
        code: {
            label: 'id',
            has_sort: false,
        },
        product_name: {
            label: 'product',
            has_sort: true,
            sort_type: null
        },
        variant_value: {
            label: 'variant',
            has_sort: true,
            sort_type: null,
        },
        sub_total: {
            label: 'sub total',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        total_discount: {
            label: 'disc',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        grand_total: {
            label: 'total',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        hpp: {
            label: 'hpp',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        buyer_category: {
            label: 'type',
            has_sort: false,
        }
    })
    const sortColumn = (colName, tableIndex) => {

        console.log(colName, tableIndex);

        setRows(prevState => {
            prevState.pos[tableIndex].details = sortData({ type: sort[colName].sort_type, column: colName, data: prevState.pos[tableIndex].details })
            return { ...prevState }
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }
    const toggleScroll = () => {
        setScroll(!scroll)
    }

    useEffect(() => {
        setRows(data)
    }, [data])

    return (
        <div>
            {
                rows?.status === 'failed' || rows?.pos?.length < 1 ? <NoReport /> :
                    data.pos.map((pos, tableIndex) => (
                        <>
                            <Helmet>
                                <style type="text/css">
                                    {`@page {size: landscape}`}
                                </style>
                            </Helmet>
                            <p className="asdasd" style={{ marginTop: '40px' }}>
                                POS Sales Report
                                <span>Showing POS report from all branches</span>
                            </p>
                            <h5><DeviceHubIcon /> Brand Name  : {pos.brand_name}</h5>
                            <div className="mb-16 report-summary-box">
                                <div className="item teal">
                                    <p>
                                        <span>Net Value</span>
                                        <span>IDR {money(pos.net_income)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Sales</span>
                                        <span>{pos.sales}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Sub Total</span>
                                        <span>IDR {money(pos.sub_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Discount</span>
                                        <span>IDR {money(data.total_discount)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Total</span>
                                        <span>IDR {money(data.grand_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>HPP</span>
                                        <span>IDR {money(data.hpp)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Other Cost</span>
                                        <span>IDR {money(data.additional_cost)}</span>
                                    </p>
                                </div>
                            </div>

                            <NoPrint force={true}>
                                <div className="table-menu">
                                    <div className="left">
                                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                    </div>
                                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                </div>
                            </NoPrint>

                            <SimpleBarHOC status={scroll}>
                                <table className="table-report mb-80">
                                    <thead>
                                        <tr>
                                            {
                                                Object.keys(sort).map((key, idx) => (
                                                    <ThSort
                                                        key={`th-${idx}`}
                                                        label={sort[key].label}
                                                        colspan={sort[key].colspan}
                                                        sort={sort[key].has_sort}
                                                        handleClick={() => sortColumn(key, tableIndex)}
                                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                    />
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pos.details.map((detail, detailIdx) => (
                                                <tr className={detail.sales_type === 'return' && 'row-red'}>
                                                    <td>{detailIdx + 1}</td>
                                                    <td>{dateDMY(detail.date)}</td>
                                                    <td className="td-highlight">{detail.branch_name}</td>
                                                    <td>{detail.sales_type}</td>
                                                    <td>{detail.code}</td>
                                                    <td>{detail.product_name}</td>
                                                    <td>{detail.variant_value}</td>
                                                    <td className="td-text-right">{money(detail.sub_total)}</td>
                                                    <td className="td-text-right">{money(detail.total_discount)}</td>
                                                    <td className="td-text-right">{money(detail.grand_total)}</td>
                                                    <td className="td-text-right">{money(detail.hpp)}</td>
                                                    <td>{detail.buyer_category}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </SimpleBarHOC>
                        </>
                    ))
            }

        </div>
    )
}
