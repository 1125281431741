import React, { useState, useEffect, createElement } from 'react'
import Module from 'Layouts/Module'
import { useSelector, useDispatch } from 'react-redux';
import FormNew from './FormNew'
import TableData from './TableData'
import Detail from './Detail'
import FormEdit from './FormEdit'
import { scrollToTop, globalDialog } from '@/Helper'
import { loadUsercategory, showUsercategory, deleteUsercategory, setEdit } from 'Reduxes/usercategory/usercategoryActions'

export default function UserCategory(props) {

    const { data } = useSelector(state => state.usercategoryReducer)
    const dispatch = useDispatch();
    const setContentActive = activeContent => {
        setContents(
            contents.map(content => {
                content.active = content.name === activeContent
                return content;
            })
        )
    }
    const fieldGroup = [
        {
            name: 'User Category Information',
            fields: [
                // {
                //     label: 'Id',
                //     name: 'code',
                //     value: '1'
                // },
                {
                    label: 'Category name',
                    col: 4,
                    name: 'user_category_name',
                    value: ''
                },
            ]
        }
    ]
    const [contents, setContents] = useState([
        {
            name: 'table',
            active: true,
            component: TableData,
            props: {
                data,
                setContentActiveFormEdit: id => {
                    dispatch(setEdit(id))
                    scrollToTop()
                    setContentActive('formedit')
                },
                setContentActiveDetail: id => {
                    dispatch(setEdit(id))
                    scrollToTop()
                    setContentActive('detail')
                },
                deleteUsercategoryProps: id => {
                    globalDialog(async () => {
                        await dispatch(deleteUsercategory(id))
                        scrollToTop()
                    })
                }
            },
            title: {
                title: 'User Category',
                subtitle: 'Manage data user category',
                button: {
                    label: 'Add category',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => {
                        setContentActive('form')
                    },
                    type: 'create',
                    subMenu: 'user_category',
                }
            }
        },
        {
            name: 'form',
            active: false,
            component: FormNew,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Add New User Category',
                subtitle: 'Fill the form below to create new User Category',
            },
        },
        {
            name: 'formedit',
            active: false,
            component: FormEdit,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Update User Category',
                subtitle: 'Good to keep the User category data updated',
            }
        }, {
            name: 'detail',
            active: false,
            component: Detail,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'User Category Detail',
                subtitle: '',
                button: {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => {
                        setContentActive('table')
                    },
                }
            }
        }
    ])

    useEffect(() => {
        dispatch(loadUsercategory())
    }, [contents])

    return (
        <>
            {
                contents.map((c, cI) => {
                    if (c.active) {
                        return (
                            <Module {...c.title} key={cI}>
                                {createElement(c.component, c.props)}
                            </Module>
                        )
                    }
                })
            }
        </>
    )
}
