import React, { useState, useEffect, createElement } from 'react'
import Module from 'Layouts/Module'
import { useSelector, useDispatch } from 'react-redux';
import FormNew from './FormNew'
import TableData from './TableData'
import Detail from './Detail'
import FormEdit from './FormEdit'
import { scrollToTop, datePost, globalDialog } from '@/Helper'
import { loadVariant, showVariant, deleteVariant, setEdit } from 'Reduxes/inventory/configuration/variant/variantActions'

export default function Variant(props) {

    const dev = 0;
    const { data, loading } = useSelector(state => state.variantReducer)
    const dispatch = useDispatch();
    const dataLength = data.length;
    const setContentActive = activeContent => {
        setContents(
            contents.map(content => {
                content.active = content.name === activeContent
                return content;
            })
        )
    }


    console.log({ data })

    const [contents, setContents] = useState([
        {
            name: 'table',
            active: true,
            component: TableData,
            props: {
                data,
                setContentActiveFormEdit: id => {
                    dispatch(setEdit(id))
                    scrollToTop()
                    setContentActive('formedit')
                },
                setContentActiveDetail: (idShow) => {
                    dispatch(showVariant(idShow))
                    setContentActive('detail')
                },
                deleteVariantProps: id => {
                    globalDialog(async () => {
                        await dispatch(deleteVariant(id))
                        scrollToTop()
                    })
                }
            },
            title: {
                title: 'Variant',
                subtitle: 'Manage data variant',
                button: {
                    label: 'Add variant',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => {
                        setContentActive('form')
                    },
                    subMenu: 'variant',
                    type: 'create',
                }
            }
        },
        {
            name: 'form',
            active: false,
            component: FormNew,
            props: {
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Add new Variant',
                subtitle: 'Fill the form below to create new Variant',
            },
        },
        {
            name: 'formedit',
            active: false,
            component: FormEdit,
            props: {
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Update Variant',
                subtitle: 'Good to keep the Variant data updated',
            }
        }, {
            name: 'detail',
            active: false,
            component: Detail,
            props: {
                setContentActiveFormEdit: idx => {
                    dispatch(showVariant(idx))
                    setContentActive('formedit')
                },
            },
            title: {
                title: 'Variant Detail',
                subtitle: '',
                button: {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => {
                        setContentActive('table')
                    },
                }
            }
        }
    ])

    useEffect(() => {
        dispatch(loadVariant({ all: true }))
    }, [])

    return (
        <>
            {
                contents.map((c, cI) => {
                    if (c.active) {
                        return (
                            <Module {...c.title} key={cI}>
                                {createElement(c.component, c.props)}
                            </Module>
                        )
                    }
                })
            }
        </>
    )
}
