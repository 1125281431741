import {
    PROFILE_LOADING,
    PROFILE_SET,
    PROFILE_ERROR,
    PROFILE_ADD,
    PROFILE_SHOW,
    PROFILE_DELETE,
    PROFILE_UPDATE,
    PROFILE_RESET,
    PROFILE_EDIT,
    PROFILE_UPDATE_LOGO,
} from './profileActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
}

const profileReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case PROFILE_LOADING:
            return {
                ...state,
                loading: true,
            }
        case PROFILE_UPDATE:

            console.log(payload)

            return {
                ...state,
                loading: false,
                data: payload,
                // data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case PROFILE_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case PROFILE_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New profile has been added successfully.'
            }
        case PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case PROFILE_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case PROFILE_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case PROFILE_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case PROFILE_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case PROFILE_UPDATE_LOGO:
            return {
                ...state,
                data: { ...state.data, logo_url: payload }
            }
        default:
            return state;
    }
}

export default profileReducer