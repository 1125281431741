import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, hideColumns, globalTableStyle } from 'Configs/table'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from 'Elements/Label'
import Email from 'Elements/Email'
import Alert from 'Comps/elements/Alert'
import Table from 'Elements/Table'
import TableNoData from 'Comps/elements/TableNoData'
import { loadBranch, deleteBranch, resetStatusBranch, setEdit } from 'Reduxes/configuration/branch/branchActions'
import LoadingContent from 'Elements/LoadingContent';
import { scrollToTop, globalMessage, globalDialog } from '@/Helper'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Permission from '../../../comps/permission/Permission';
export default function TableData(props) {

    const { showForm, showDetail } = props
    const { data, loading, error, success } = useSelector(state => state.branchReducer);
    const classes = globalTableStyle();

    const dispatch = useDispatch();

    const handleDelete = async id => {
        if (id.includes(1)) {
            globalMessage('You cannot delete Main Office', 'warning')
            return false
        }
        globalDialog(async () => {
            await dispatch(deleteBranch(id))
            scrollToTop()
        })
    }


    // console.clear()
    // console.log(data)


    let dataModified = data.map(row => ({
        ...row,
        fullAddress: [row.address ? row.address : null, row.city ? row.city : null, row.province ? row.province : null, row.country ? row.country : null, row.postal_code ? row.postal_code : null].filter(Boolean)
    }))

    const table = tableOpts({
        handleDelete,
        data: dataModified,
        colIdPosition: 0,
        noPrint: true,
        filename: "Branch_Data",
        filter: false,
        table: {

            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        viewColumns: false,
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                {
                    name: "branch_code",
                    label: 'Code',
                    options: {
                        filter: true,
                        filterOptions: {
                            renderValue: v => v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : '',
                        },
                        filterType: 'dropdown',
                    }

                },
                {
                    name: "branch_name",
                    label: 'Branch',
                    options: {
                        filter: true,
                        filterType: 'textField',


                    }
                },
                {

                    name: 'fullAddress',
                    label: 'Address',
                    options: {
                        filter: false,
                        setCellHeaderProps: () => ({ className: 'address-col' }),
                        customBodyRender: (value, tableMeta) => {

                            return value.join(", ")
                        },

                    }
                },
                {
                    name: "email",
                    label: 'Email', searchable: false,
                    options: {
                        filter: false,
                    }
                    // label: 'Contact', searchable: false,
                    // options: {
                    //     filter: false,
                    //     // searchable: false,
                    //     customBodyRender: (value, tableMeta) => {
                    //         const { rowData } = tableMeta;
                    //         return (
                    //             <>
                    //                 <Email email={value} /> <br />
                    //                 {rowData[5]}
                    //             </>
                    //         )
                    //     }
                    // }
                },
                {
                    name: "phone",
                    label: 'Phone', searchable: false,
                    options: {
                        filter: false,
                    }

                },

                {
                    name: "is_active",
                    label: 'Status',
                    options: {
                        // customFilterListOptions: { render: v => v ? 'Active' : 'Inactive' },
                        // filterList: ['Franky Miles', 'mantap'],

                        // filterOptions: {
                        // renderValue: v => {
                        // v ? 'Active' : 'Inactive'
                        // console.log(v)
                        // return v ? 'aaaa':'bbbb';
                        // },
                        // false - true
                        // names: ["Inactive", "Active"],
                        // logic(a, b, c) {
                        // console.log(a, b, c)
                        // const show =
                        //     (filterVal.indexOf("Yes") >= 0 && (salary === true || salary === 'true')) ||
                        //     (filterVal.indexOf("No") >= 0 && (salary === false || salary === 'false'));
                        // return !show;
                        // return b[0] === 'Inactive';
                        // }
                        // },
                        // customBodyRender: (val) => {
                        //     return val === true ? "true" : "false";
                        // }, 
                        customBodyRender: (value) => {
                            return (
                                <Label type={value ? 'success' : 'danger'} text={value ? 'active' : 'Inactive'} />
                            )
                        },

                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: {
                            names: ['Active', 'Inactive'],
                            logic(val, filterVal) {
                                const show =
                                    (filterVal.indexOf('Active') >= 0 && val) ||
                                    (filterVal.indexOf('Inactive') >= 0 && !val);
                                return !show;
                            },
                        },



                    }
                }, {
                    name: "id",
                    label: 'Actions',
                    setCellHeaderProps: () => ({ style: { backgroundColor: 'red' } }),
                    options: {
                        download: false,
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        filter: false,
                        viewColumns: false,
                        customBodyRender: (value, tableMeta) => {
                            console.log(value)
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => showDetail(value)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => {
                                    dispatch(setEdit(value))
                                    dispatch(loadBranch({ all: true }))
                                    showForm('Edit')
                                    scrollToTop()
                                }
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => handleDelete([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />
                                    <Permission
                                        component={
                                            <EditIcon {...attrEdit} />
                                        }
                                        type="update"
                                        subMenu="branch"
                                    />
                                    {
                                        value > 1 &&
                                        <Permission
                                            component={
                                                <DeleteIcon {...attrDelete} />
                                            }
                                            subMenu="branch"
                                            type="delete"
                                        />
                                    }
                                </>
                            );
                        }
                    }
                },
            ],

        }
    })

    return (
        <ContentWrapper
            title="Branch Information"
            subtitle="showing table of all branch data"
            buttons={[
                {
                    label: 'Add Branch',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => showForm('New'),
                    subMenu: 'branch',
                    type: 'create',
                }
            ]}
        >
            {
                success &&
                <Alert hide={() => dispatch(resetStatusBranch())} option={{ type: 'success', message: success }} />
            }
            {
                error && <Alert hide={() => dispatch(resetStatusBranch())} option={{ type: 'error', message: error }} />
            }

            {
                loading ? <LoadingContent />
                    : (


                        data.length ?
                            <Table {...table} className={classes.table} />
                            : <TableNoData />
                    )
            }


        </ContentWrapper>
    )
}

