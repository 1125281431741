import React, { useState, useEffect } from 'react'
import { money } from '@/Helper'
import Button from 'Elements/Button'


export default function TableReturnModal(props) {
    const { transaction, setModalProduct, addSelectedReturnProduct } = props;
    const { payment_method } = transaction;
    const [rows, setRows] = useState(transaction.pos_item);
    const handleAdd = id => {
        addSelectedReturnProduct(rows.find(r => r.id === id));
        setRows(state => {
            return state.filter((s, i) => s.id !== id)
        })
    }

    useEffect(() => {
        if (!rows.length) {
            setModalProduct(false)
        }
    }, [rows])

    return (
        <div className="table-product-outter">
            <div className="table-product">
                <div className="header">
                    <p>ID Penjualan {transaction.code}</p>
                    <p>{transaction.transaction_date}, Created by : {transaction.created_by_contact_name}</p>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>SKU</th>
                            <th>Name</th>
                            <th>Price /Unit</th>
                            <th>Unit</th>
                            {/* <th>Discount</th> */}
                            <th>Qty</th>
                            <th>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.length > 0 && rows.map((t, tI) => {
                                return (
                                    <tr key={tI}>
                                        <td>
                                            <Button
                                                style="TableproductGreen"
                                                icon="plus"
                                                onClick={() => handleAdd(t.id)}
                                            />
                                        </td>
                                        <td className="width-as-content">{t.sku}</td>
                                        <td className="width-as-content">{`${t.product_name} ${t.color_name} ${t.variant_type_value ? `- (${t.variant_type_value})` : ''}`}</td>
                                        <td className="width-as-content">IDR {money(t.price)}</td>
                                        <td>{t.uom_name}</td>
                                        {/* <td>N/a</td> */}
                                        <td>{t.qty}</td>
                                        {/* <td>
                                    <InputNumber value={1} min={1} max={t.qty} />
                                </td> */}
                                        <td className="width-as-content">IDR {money(t.price)}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <tfoot>
                        <tr className="note-total">
                            <td colSpan="5" className="note" rowSpan="4">
                                <b>Notes : </b>
                                <p className="note">{transaction.note || '-'}</p>
                                <p className="payment-summary">
                                    <span>Total :
                                     {
                                            <b>IDR {money(transaction.total)}</b>
                                        }

                                    </span>
                                    <span>Payment :
                                        {
                                            payment_method?.amount ? <b>IDR {money(payment_method.amount)}</b> : '-'
                                        }
                                    </span>
                                    <span>Change :
                                     {
                                            payment_method?.change ? <b>IDR {money(payment_method.change)}</b> : '-'
                                        }

                                    </span>
                                </p>
                            </td>
                            <td>Sub Total</td>
                            <td className="width-as-content">IDR {money(transaction.subtotal)}</td>
                        </tr>
                        <tr className="note-total">
                            <td>Discount</td>
                            <td className="width-as-content">IDR {money(transaction.discount)}</td>
                        </tr>
                        <tr className="note-total">
                            <td>PPN</td>
                            <td className="width-as-content">IDR {money(transaction.ppn)}</td>
                        </tr>
                        <tr className="note-total">
                            <td>Total</td>
                            <td className="width-as-content">IDR {money(transaction.total)}</td>
                        </tr>
                    </tfoot>
                </table>

            </div>
            <Button onClick={() => setModalProduct(false)} style="WideOrangeMdRect" label="Save" icon="check"></Button>
        </div>
    )
}
