import { handleReq, reqGet, reqPatch, reqPost, reqDelIDS, hostUrl, handleRequest, makeGetParam } from 'Configs/request'

const type = 'PAYMENT'

export const PAYMENT_SET = 'PAYMENT_SET'
export const PAYMENT_ADD = 'PAYMENT_ADD'
export const PAYMENT_EDIT = 'PAYMENT_EDIT'
export const PAYMENT_SHOW = 'PAYMENT_SHOW'
export const PAYMENT_ERROR = 'PAYMENT_ERROR'
export const PAYMENT_RESET = 'PAYMENT_RESET'
export const PAYMENT_DELETE = 'PAYMENT_DELETE'
export const PAYMENT_UPDATE = 'PAYMENT_UPDATE'
export const PAYMENT_LOADING = 'PAYMENT_LOADING'
export const PAYMENT_CONFIRM = 'PAYMENT_CONFIRM'
export const PAYMENT_STOP_LOADING = 'PAYMENT_STOP_LOADING'

export const loadPayment = () => async (dispatch, getState) => {
    handleReq({
        type,
        dispatch,
        // body: () => fake,
        body: () => reqGet(`${hostUrl}/api/payment`),
        next: payload => dispatch({ type: PAYMENT_SET, payload })
    })
}

export const addPayment = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/payment`, data),
        next: payload => dispatch({ type: PAYMENT_ADD, payload })
    })
}

export const setEditPayment = idEdit => {
    return {
        type: PAYMENT_EDIT,
        payload: idEdit
    }
}

export const showPayment = idShow => {
    return {
        type: PAYMENT_SHOW,
        payload: idShow
    }
}

export const resetStatusPayment = () => {
    return {
        type: PAYMENT_RESET
    }
}

export const deletePayment = id => async (dispatch, getState) => {
    handleReq({
        type,
        dispatch,
        body: () => reqDelIDS(`${hostUrl}/api/payment`, id),
        next: () => dispatch({ type: PAYMENT_DELETE, payload: id })
    })
}

export const updateStatusPayment = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/payment/${id}/${type}`),
        next: () => dispatch({ type: PAYMENT_CONFIRM, payload: { type, id } })
    })
}

export const updatePayment = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/payment/${idEdit}`, data),
        next: (payload) => dispatch({ type: PAYMENT_UPDATE, payload })
    })
}
export const getPaymentById = id => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/payment/${id}`),
    })
}
export const filterPaymentReport = (report, args = {}) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/payment/${report}${makeGetParam(args)}`),
    })
}