import Input from 'Elements/Input';
import { Formik, Form } from 'formik';
import Alert from 'Comps/elements/Alert'
import Textarea from 'Elements/Textarea';
import Select from 'Elements/select/Select';
import FormButton from 'Elements/FormButton'
import { scrollToTop } from '@/Helper'
import Datepicker from 'Elements/Datepicker';
import ProductManager from './ProductManager'
import { getLocalStorage, getLocalStorageAuth } from '../../../Helper'
import React, { useEffect, useState } from 'react'
import LoadingContent from 'Elements/LoadingContent'
import FieldsContainer from 'Elements/FieldsContainer'
import { useDispatch, useSelector } from 'react-redux';
import ContentWrapper from 'Comps/container/ContentWrapper'
import { resetStatusAcquisition, addAcquisition, getAquisitionById, updateAcquisition } from 'Reduxes/inventory/acquisition/acquisitionActions'
import { CircularProgress } from '@material-ui/core';

function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const [defaultData, setDefaultData] = useState(null) // untuk update
    const { type, showTable, rowId } = props;
    const currentBranch = getLocalStorage()?.branchLogin;
    const isSuperAdmin = getLocalStorageAuth()?.user_category_id === 1 || getLocalStorageAuth()?.user_category_id === 2
    const dispatch = useDispatch();
    const [typeValue, setTypeValue] = useState('general'); // utk nampung data select:type, data ini dipake tuk conditional rendering jenis contact
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [productSearchType] = useState('productName');
    const [isLoadingEdit, setLoadingEdit] = useState(false)

    const [initialValues, setInitialValues] = useState({
        type: "general",
        contact_id: '',
        branch_id: getLocalStorage().branchLogin.id,
        date: new Date().toISOString().split('T')[0],
        note: '',
        reference: '',
        status: "draft",
        product_receivement_item: [],
        _search_product_by: productSearchType === 'productName' ? 'search_by_product_name' : 'search_by_product_id',
        _product_id: 'aaaaa',
        _price: 0,
        _qty: 0,
        _qty_received: 0,
        _unit_of_measurement_id: ''
    })

    const [productReceipt, setProductReceipt] = useState([]);

    // jemput data dari product manager
    const getData = products => {
        setProductReceipt(products);
    }

    /* FORM HANDLER */
    const handleType = ({ selected }) => {
        setSelectedBrand(null)
        setTypeValue(selected.value);
    }

    const handleContactChange = ({ selected }) => {
        setSelectedBrand(selected.value)
    }

    const [isReady, setIsReady] = useState(true);
    const [tableRow, setTableRow] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const { error } = useSelector(state => state.acquisitionReducer);

    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // if (!values.product_receivement_item.length) {
            //     setInputError('You need to add at least one product')
            //     setSubmitting(false)
            //     scrollToTop()
            //     return;
            // }

            const productsRec = [];

            const qty0 = productReceipt.find(item => item.qty === 0);
            const oneProduct = productReceipt.find(item => 'name' in item);

            if (!oneProduct) {
                setInputError('You must add at least 1 product')
                setSubmitting(false)
                scrollToTop()
                return;
            } else if (qty0) {
                setInputError('Qty must greater than 0')
                setSubmitting(false)
                scrollToTop()
                return;
            } else {
                productReceipt.forEach(product => {
                    const qtyDelivery = product.qty;
                    if (qtyDelivery > 0) {

                        productsRec.push({
                            variant_type_id: product.variant_type_id || null,
                            product_id: product.product_id || product.id,
                            price: product.sales_price,
                            qty: product.qty,
                            qty_received: product.qty_receipt,
                            unit_of_measurement_id: product.uom_id

                        })
                    }

                })

                let cleanVal = {
                    "type": values.type,
                    "branch_id": +values.branch_id,
                    "contact_id": +values.contact_id,
                    "date": values.date,
                    "note": values.note,
                    "reference": values.reference,
                    "status": values.draft || 'draft',
                    "product_receivement_item": productsRec
                }

                let result = type === 'new'
                    ? await dispatch(addAcquisition(cleanVal))
                    : await dispatch(updateAcquisition(rowId, cleanVal)) // idEdit harus dirubah dengan rowId tapi cek dlu

                if (result) {
                    resetForm();
                    showTable();
                }

                scrollToTop()

                setSubmitting(false)
            }
        }
    }
    const [inputError, setInputError] = useState(null)
    const { data: contacts } = useSelector(state => state.contactReducer);
    const [address, setAddress] = useState('');

    const handleAddTableRow = newRow => setTableRow([...tableRow, newRow])
    const handleRemoveTableRow = (idx, { values, setValues }) => {
        setTableRow(tableRow.filter((_, trIdx) => trIdx !== idx))
        setValues({
            ...values,
            product_receivement_item: values.product_receivement_item.filter((_, i) => idx !== i)
        })
    }
    const prodSelectHandler = ({ row, rows, formikAttr }) => {
        setSelectedProduct(row);
        formikAttr.form.setValues({
            ...formikAttr.form.values,
            _price: row.sales_price,
            _unit_of_measurement_id: row.unit_of_measurement_id
        })
    }


    useEffect(() => {
        // if (type === 'edit') {
        //     let findData = data.find(d => d.id === idEdit);
        //     const { type, reference, status, product_receivement_item, note, date, contact_id, branch_id } = findData;
        //     setTypeValue(type)
        //     setInitialValues({
        //         type,
        //         contact_id,
        //         branch_id,
        //         date,
        //         note,
        //         reference,
        //         status,
        //         product_receivement_item,
        //         _search_product_by: 'search_by_product_name',
        //         _product_id: 'aaaaa',
        //         _price: 0,
        //         _qty: 0,
        //         _qty_received: 0,
        //         _unit_of_measurement_id: ''
        //     })

        //     setTableRow(
        //         product_receivement_item.map(data => ({
        //             id: data.product_code,
        //             name: data.product_name,
        //             price: `IDR ${money(data.price)}`,
        //             qty: data.qty,
        //             qty_received: data.qty_received,
        //             uom: data.uom_name,
        //             total: `IDR ${money(data.price * data.qty)}`
        //         }))
        //     )
        // }
    }, [])


    useEffect(() => {

        setAddress('')

    }, [contacts])

    useEffect(() => {

        const fetchDataEdit = async () => {
            setLoadingEdit(true)
            const resp = await dispatch(getAquisitionById(rowId))

            const { type, reference, branch_id, contact_id, date, note, product_receivement_item, status, } = resp.data
            setTypeValue(type)
            setInitialValues({
                type,
                contact_id,
                branch_id,
                date,
                note,
                reference,
                status,
                product_receivement_item,
                _search_product_by: 'search_by_product_name',
                _product_id: 'aaaaa',
                _price: 0,
                _qty: 0,
                _qty_received: 0,
                _unit_of_measurement_id: ''
            })
            setDefaultData(product_receivement_item)
        }

        if (type === 'edit') {
            fetchDataEdit();


            if (contacts) {
                setTimeout(() => {
                    setLoadingEdit(false)
                }, 300)
            }
        }
    }, [])



    const contactProps = {
        handleProps: (val) => {
            const contact = contacts.find(item => item.id === val.value)
            const parsedAddress = [contact.address ? contact.address : null, contact.city ? contact.city : null, contact.province ? contact.province : null, contact.country ? contact.country : null, contact.postal_code ? contact.postal_code : null].filter(Boolean)
            setAddress(parsedAddress.join(', '))
        },
        name: "contact_id",
        note: address,
        label: "From",
        showAddress: true,
    }

    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Receive" : "Update data Receive"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data receive`}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusAcquisition())} option={{ type: 'error', message: error }} />
                }
                {
                    inputError && <Alert hide={() => setInputError(null)} option={{ type: 'error', message: inputError }} />
                }
                {
                    isReady ? (
                        <>

                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {

                                    const { isSubmitting } = formik;

                                    return (
                                        <Form className="form-layout-1">

                                            <FieldsContainer title="Receive Information">
                                                <Select
                                                    name="type"
                                                    label="type"
                                                    getData={handleType}
                                                    options={[
                                                        { label: 'General', value: 'general' },
                                                        { label: 'Consignment', value: 'consignment' },
                                                    ]}
                                                />
                                                <Select
                                                    name="branch_id"
                                                    options='branch'
                                                    label="Branch name"
                                                    isDisabled={!isSuperAdmin}
                                                />
                                                <Datepicker name="date" label="Date" />
                                                {
                                                    typeValue === 'general' &&
                                                    <Select {...contactProps} options="contact" />
                                                }
                                                {
                                                    typeValue !== 'general' &&
                                                    <Select {...contactProps}
                                                        param={{ is_brand: true }}
                                                        options="contact"
                                                        getData={handleContactChange}
                                                    />
                                                }

                                                <Input name="reference" label="Reference" />
                                                <div></div>
                                                <Textarea name="note" label="Notes" col="6" />
                                            </FieldsContainer>

                                            <div className="fields-group-container product_information">
                                                <p className="field-group-title">
                                                    Product Information
                                                    <span>You must add at least one product</span>
                                                </p>

                                                {/* <SelectData label="Search by" name="_search_product_by"
                                                    options={[
                                                        { label: 'Product Name', value: 'search_by_product_name' },
                                                        { label: 'Product ID', value: 'search_by_product_id' },
                                                    ]}
                                                    handleChange={(val, formik) => {
                                                        setProductSearchType(val === 'search_by_product_id' ? 'productId' : 'productName')
                                                    }}
                                                />
                                                <SelectAsync
                                                    type={productSearchType}
                                                    name="_product_id"
                                                    param={ {brand_id: selectedBrand} }
                                                    value={productSearchType}
                                                    handleChange={prodSelectHandler}
                                                    label="Product name"
                                                /> */}
                                            </div>

                                            {
                                                // selectedProduct &&
                                                // <FormProduct
                                                //     handleAddTableRow={handleAddTableRow}
                                                //     setSelectedProduct={setSelectedProduct}
                                                //     data={selectedProduct}
                                                // />
                                            }

                                            {
                                                // tableRow.length > 0 &&
                                                // <TableForm
                                                //     handleRemoveTableRow={handleRemoveTableRow}
                                                //     rows={tableRow}
                                                // />
                                            }

                                            {
                                                isLoadingEdit ?<CircularProgress
                                                size={24}
                                                style={{ marginLeft: 15, position: 'relative', top: 4 }} /> : 
                                                <ProductManager defaultData={defaultData} getData={getData} dataErrStatus={setDataErr} />
                                            }
                                            <FormButton
                                                isSubmitting={isSubmitting}
                                                showTable={showTable}
                                            />

                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper>
    )
}

export default FormElement