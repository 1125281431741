import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik';
import FieldsGroup from 'Elements/FieldsGroup'
import { useSelector, useDispatch } from 'react-redux';
import { scrollToTop, yupValidation, fieldsToFormik, fieldsValidation } from '@/Helper'
import Button from 'Elements/Button';
import Alert from 'Comps/elements/Alert'
import { validationSchema } from './validation'
import { serverValue } from 'Configs/Form';
import FieldGroup from 'Elements/FieldGroup';
import Input from 'Elements/Input';
import Select from 'Elements/Select';
import SelectMulti from 'Elements/SelectMulti';
import { loadVariant } from 'Reduxes/inventory/configuration/variant/variantActions'
import { addCategory, updateCategory, resetStatusCategory } from 'Reduxes/inventory/configuration/category/categoryActions'

export default function FormHTML(props) {
    const [showVariantList, setShowVariantList] = useState(false)
    const [subVariant, setSubVariant] = useState([])
    const { goToIndex, type, fieldGroup, setFieldGroup } = props;
    const { data, success, error, loading, idEdit } = useSelector(state => state.categoryReducer)
    const { data: variantList } = useSelector(state => state.variantReducer)
    const dispatch = useDispatch();
    const parentOpt = [
        { value: 'null', label: 'None' },
        ...data.map(x => ({ label: x.name, value: x.id })),
    ]
    const [isSubmittingForm, setIsSubmittingForm] = useState(false);

    // console.log({variantList})

    let formValue = type === 'new' ? {
        code: '',
        name: '',
        parent_id: 'null',
        status: 'true',
        variant_id: '',
        category_variant: []
    } : data.filter(x => x.id === idEdit)[0];

    useEffect(() => {
        dispatch(loadVariant())

        if (type === 'edit') {
            setSubVariant(formValue.category_variant && formValue.category_variant.length ?
                formValue.category_variant.map(x => ({ label: x.variant_value_item, value: x.category_variant_id }))
                : [{ value: '', label: 'Has no subvariant' }]
            )

            // console.log({formValue})
            setShowVariantList(formValue.category_variant && formValue.category_variant.length > 0)
        }
    }, [])

    console.log({ formValue })

    const formikOpt = {
        initialValues: {
            ...formValue,
            category_variant: type === 'edit' && formValue.category_variant ?
                formValue.category_variant.map(x => `${x.variant_type_id}|${x.variant_value_item}`) : []
        },
        onSubmit: async (values, formik) => {



            setIsSubmittingForm(true)

            let { setSubmitting, resetForm } = formik;
            let cleanVal = serverValue(values);

            if (cleanVal.category_variant.length) {
                cleanVal.category_variant = cleanVal.category_variant.map(x => serverValue({ variant_type_id: x.split('|')[0] }))
            }


            console.log(cleanVal);
            // return;


            let result = type === 'new' ?
                await dispatch(addCategory(cleanVal))
                : await dispatch(updateCategory(idEdit, cleanVal))

            setIsSubmittingForm(false)

            if (result) {
                goToIndex();
                resetForm();
            }

            scrollToTop()
        }
    }

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusCategory())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formikOpt} enableReinitialize={false}>
                {({ isSubmitting }) => {

                    return (
                        <Form className="form-member">
                            <FieldGroup title="Category detail">
                                <Input name="code" label="Code" uppercasing />
                                <Input col="4" name="name" label="Name" />
                                <Select
                                    options={[
                                        { value: 'true', label: 'active' },
                                        { value: 'false', label: 'inactive' },
                                    ]}
                                    value="true"
                                    name="status"
                                    label="Status"
                                />
                            </FieldGroup>
                            {/**
                                <FieldGroup title="Other Information">
                                    <Select
                                        options={parentOpt}
                                        value={parentOpt[0].value}
                                        name="parent_id"
                                        label="Parent"
                                    />
                                    <Select
                                        options={[
                                            {label: 'None', value: ''},
                                            ...variantList.map(x => ({value: x.id, label: x.name}))
                                        ]}
                                        value=""
                                        name="variant_id"
                                        label="Variant"
                                        handleChange={(val, formikAttr) => {
                                            setShowVariantList(val !== '')
                                            formikAttr.setFieldValue('category_variant', [])

                                            if (val !== '') {
                                                // console.log(111, variantList.find(x => x.id === parseInt(val)), val)
                                                let hasSubVariant = variantList.find(x => x.id === parseInt(val)).variant_value;
                                                setSubVariant(hasSubVariant && hasSubVariant.length ?
                                                    hasSubVariant.map(x => ({label: x.value, value: x.variant_type_id}))
                                                    : [{value: '', label: 'Has no subvariant'}]
                                                )
                                            }
                                        }}
                                    />
                                    {
                                        showVariantList &&
                                        <SelectMulti
                                            label="variant Size"
                                            options={subVariant}
                                            name="category_variant"
                                        />
                                    }
                                </FieldGroup>
                             */
                            }
                            <div className="fields-group-container buttons">
                                <Button
                                    type="submit"
                                    style="OrangeMdWide"
                                    label={isSubmittingForm ? 'Saving' : 'Save'}
                                    icon={isSubmittingForm ? 'spinner' : null}
                                    disabled={isSubmittingForm} />
                                <button disabled={isSubmittingForm} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}
