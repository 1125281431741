import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { updateStatus, updateAcquisition, loadAcquisition, getAquisitionById } from 'Reduxes/inventory/acquisition/acquisitionActions'
import { useDispatch, useSelector } from 'react-redux';
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import { money, dateDMY } from '@/Helper'
import PrintProvider, { Print } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import InputNumber from 'Elements/inputnumber/InputNumber';
import KopSurat from 'Elements/KopSurat'
import ScanProduct from 'Elements/ScanProduct'
import Alert from 'Comps/elements/Alert'
import Permission from '../../../comps/permission/Permission';
import { CircularProgress } from '@material-ui/core';
import SimpleModal from 'Elements/modal/Modal';
import WarningIcon from '@material-ui/icons/Warning';
import Button from 'Elements/Button';

function Detail(props) {
    const [dataErr, setDataErr] = useState(false)
    const { showTable, rowId } = props;
    const [data, setData] = useState({
        contact_name: '',
        contact_address: '',
        status: '',
        result: '',
        type: '',
        branch_name: '',
        date: dateDMY(new Date()),
        reference: '',
        product_receivement_item: []
    })
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(true)
    const [disabled, setDisabled] = useState(false)
    const [code, setCode] = useState('N/A')
    const parsed = data.type.split('_')
    const result = data.type === 'return_consigment' ? 'Return Consignment' : parsed.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
    const detailHeader = !data ? {} : {
        from: data.contact_name,
        address: data.contact_address,
        status: data.status,
        type: result,
        branch: data.branch_name,
        date: dateDMY(data.date),
        reference: data.reference,
        'Created By': data?.created_by_contact_name
    }
    const isBackorder = data.reference.split(' ')[0] === 'Backorder'
    const isGeneratedFromOtherModule = data.type !== 'general' && data.type !== 'consignment'
    const isInternalTransfer = data.type === 'internal_transfer'
    const isWaiting = data.status === 'waiting'
    const isDone = data.status === 'done'
    const waitingOrDone = (isWaiting || isDone)
    const [modalOpen, setModalOpen] = useState(false)
    const [openedModal, setOpenedModal] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [redLabels, setRedLabels] = useState([]);
    const makeStatusButton = (type, label, permissionType) => {
        return {
            label: label,
            style: 'TileMd',
            marginLeft: "ml-10",
            ...(loading ? { icon: 'spinner' } : {}),
            disabled: disabled,
            onClick: async () => {
                setLoading(true)
                setDisabled(true)
                if (type === 'done') {
                    let cleanVal = {
                        "type": data.type,
                        "contact_id": data.contact_id,
                        "branch_id": data.branch_id,
                        "date": data.date,
                        "note": data.note,
                        "reference": data.reference,
                        "status": "done",
                        "product_receivement_item": data.product_receivement_item.map(x => ({
                            "product_id": x['product_id'],
                            "price": x['price'],
                            "qty": x['qty'],
                            "qty_received": x['qty_received'],
                            "unit_of_measurement_id": x['unit_of_measurement_id'],
                            "variant_type_id": x['variant_type_id'],
                        }))
                    }

                    const missmatch = Boolean(cleanVal.product_receivement_item.find((item) => item.qty !== item.qty_received));

                    if (missmatch) {
                        setModalOpen(Boolean(missmatch))
                        setLoading(false)
                        setDisabled(false)
                    } else {
                        const req = await dispatch(updateAcquisition(data.id, cleanVal))
                        if (req) {
                            // after success update receipt, refetch and set data
                            const reFetch = await dispatch(getAquisitionById(rowId))
                            if (reFetch.status) {
                                setData(reFetch.data)
                                setLoading(false)
                                setDisabled(false)
                            }
                        }
                        setLoading(false)
                        setDisabled(false)
                    }
                } else {
                    const req = await dispatch(updateStatus(data.id, type))
                    if (req.status) {
                        const reFetch = await dispatch(getAquisitionById(rowId))
                        if (reFetch.status) {
                            setData(reFetch.data)
                            setDataEdit(reFetch.data)
                            setCode(reFetch.data.code)
                            setLoading(false)
                            setDisabled(false)
                        }
                    }
                }
            },
            subMenu: 'receipts',
            type: permissionType,
        }
    }
    const { permission } = useSelector(state => state.userReducer)
    const hasAccess = permission.some(access => access.menu === "receipts" && access.type === "confirm")
    const [dataEdit, setDataEdit] = useState()
    // const handleQtyReceivedChange = (event, idx) => {
    //     let val = parseInt(event.target.value);
    //     setDataEdit({
    //         ...dataEdit,
    //         product_receivement_item: dataEdit.product_receivement_item.map((dE, dEIdx) => {
    //             if (idx === dEIdx) {
    //                 dE.qty_received = val;
    //             }
    //             return dE;
    //         })
    //     })
    // }
    const setQty = (val, props) => {
        setDataEdit({
            ...dataEdit,
            product_receivement_item: dataEdit.product_receivement_item.map((dE) => {
                if (dE.product_id === props.product_id) {
                    if (dE.variant_type_value === props.variant) {
                        dE.qty_received = +val
                    } else if (!props.variant) {
                        dE.qty_received = +val
                    }

                    if (dE.qty_received !== dE.qty) {
                        const existing = redLabels.find((label) => label.product_id === props.product_id && label.variant === dE.variant_type_value);
                        if (!existing) {
                            setRedLabels([...redLabels, { product_id: props.product_id, variant: dE.variant_type_value }])
                        }
                    }
                }
                return dE;
            })
        })
    }

    const renderQTyReceivedWaiting = (product) => {
        const { qty_mod, product_id, product_name } = product;
        return (qty_mod && qty_mod.length ? qty_mod : [1]).map((v, vIdx) => {
            const qty_re = v.qty_received || 0;

            const hasRedLabel = redLabels?.find((label) => label.product_id === product_id && label?.variant === product?.variant_type_value) && openedModal

            return <div style={{ color: hasRedLabel ? 'red' : '' }}>
                <InputNumber
                    product_id={product_id}
                    product_name={product_name}
                    variant={v.variant}
                    key={vIdx}
                    name={vIdx}
                    withFieldContainer
                    handleChange={setQty}
                    value={qty_re}
                    labelAsBaloon
                    label={v.variant}
                // disabled={data.type === 'internal_transfer'}
                />
            </div>
        })
    }

    const renderQTyReceived = (product) => {
        const { qty_mod } = product;
        return (qty_mod && qty_mod.length ? qty_mod : [1]).map((v) => {
            const qty_re = v.qty_received;
            const variant = v.variant
            return (
                <span className="label rect mr-4 mb-4 grey">{variant}{variant ? ':' : <></>}{qty_re}</span>
            )
        })
    }
    let arrTableItem = [];
    const productReceived = data.product_receivement_item;
    productReceived.forEach((row) => {
        const isFound = arrTableItem.find(x => x.product_id === row.product_id);
        if (!isFound) {
            const productReceivedLength = productReceived.length;
            const isSingleQty = productReceivedLength === 1 && !productReceived[0].variant_type_value;
            const qty = [
                {
                    qty: row.qty,
                    qty_received: row.qty_received,
                    ...(!isSingleQty ? { variant: row.variant_type_value } : {}),
                    difference: row.difference,
                    price: row.price
                }
            ];
            row['qty_mod'] = qty;
            arrTableItem.push(row);
        } else {
            const indexToUpdate = arrTableItem.findIndex(x => JSON.stringify(x) === JSON.stringify(isFound));
            const oldVal = arrTableItem[indexToUpdate]['qty_mod'];
            const newVal = [
                ...oldVal,
                {
                    qty: row.qty,
                    qty_received: row.qty_received,
                    variant: row.variant_type_value,
                    difference: row.difference,
                    price: row.price
                }
            ]
            arrTableItem[indexToUpdate]['qty_mod'] = newVal;
        }
    });
    let total = 0;
    let totalReceived = 0;
    arrTableItem.map((a) => {
        let subTotal = 0;
        let subTotal_received = 0;
        const price = a.price;
        a.qty_mod.forEach(b => {
            subTotal += price * b.qty;
            subTotal_received += price * b.qty_received;
        })
        a['total'] = subTotal;
        a['total_received'] = subTotal_received;
        total += subTotal;
        totalReceived += subTotal_received;
        return a;
    })

    const handleScannerProduct = (value) => {
        if (data.status === 'waiting') {
            const productIdx = dataEdit.product_receivement_item.findIndex(x => x.barcode === value || x.product_code === value)
            const foundData = dataEdit.product_receivement_item.find(x => x.barcode === value || x.product_code === value);
            if (foundData) {
                setDataEdit({
                    ...dataEdit,
                    product_receivement_item: dataEdit.product_receivement_item.map((dE, dEIdx) => {
                        if (productIdx === dEIdx) {
                            dE.qty_received += 1;
                        }
                        return dE;
                    })
                })
            } else {
                setDataErr(true)
            }
        }
        return value
    }
    const actionScannerProduct = () => { }

    useEffect(() => {

        const fetchReceipt = async () => {
            const resp = await dispatch(getAquisitionById(rowId))

            if (resp.status) {
                setData(resp.data)
                setDataEdit(resp.data)

                setInitialLoading(false)
            }
        }

        fetchReceipt()
    }, [])

    useEffect(() => {
        setCode(data.code)
    }, [data])

    const handleConfirm = async () => {
        let cleanVal = {
            "type": data.type,
            "contact_id": data.contact_id,
            "branch_id": data.branch_id,
            "date": data.date,
            "note": data.note,
            "reference": data.reference,
            "status": "done",
            "product_receivement_item": data.product_receivement_item.map(x => ({
                "product_id": x['product_id'],
                "price": x['price'],
                "qty": x['qty'],
                "qty_received": x['qty_received'],
                "unit_of_measurement_id": x['unit_of_measurement_id'],
                "variant_type_id": x['variant_type_id'],
            }))
        }

        const req = await dispatch(updateAcquisition(data.id, cleanVal))
        if (req) {
            // after success update receipt, refetch and set data
            const reFetch = await dispatch(getAquisitionById(rowId))
            if (reFetch.status) {
                setData(reFetch.data)
                setLoading(false)
                setDisabled(false)
                setModalOpen(false)
                setOpenedModal(false)
            }
        }
    }

    return (

        <PrintProvider >
            <Print single name="print-acquisiton-detail-1" >
                {
                    data.status === 'waiting' && <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} type={"receipt"} />
                }
                <div className="print-report zoom-70">
                    <KopSurat title="Receive" nomor={`${data.code || 'N/a'}`} />
                    <ContentWrapper
                        title="Receive Detail"
                        subtitle={`Showing Receive product with id ${code}`}
                        buttons={[
                            {
                                label: 'Go back',
                                style: 'WhiteMd',
                                icon: 'arrow-left',
                                onClick: () => {
                                    dispatch(loadAcquisition({
                                        page: 0,
                                        limit: 10
                                    }))
                                    showTable()
                                },
                            },
                            // draft - open - waiting - done(bisa mundur)
                            (data.status === 'draft' ? makeStatusButton('open', 'Open', 'create') : {}),
                            (data.status === 'open' && !isBackorder && data.type !== 'internal_transfer' && (data.type !== 'return_consigment') ? makeStatusButton('draft', 'Reject', 'confirm') : {}),
                            // (data.status === 'open' && data.type !== 'internal_transfer' && (data.type !== 'return_consigment') ? makeStatusButton('draft', 'Reject', 'confirm') : {}),
                            (data.status === 'open' ? makeStatusButton('waiting', 'Approve', 'confirm') : {}),
                            (data.status == 'open' && isBackorder ? makeStatusButton('cancel', 'Cancel', 'confirm') : {}),
                            (data.status === 'waiting' && isBackorder ? makeStatusButton('cancel', 'Cancel', 'confirm') : {}),
                            (data.status === 'waiting' && data.type !== 'internal_transfer' && data.type !== 'return_consigment' ? makeStatusButton('open', 'Open', 'create') : {}),
                            (data.status === 'waiting' ? makeStatusButton('done', 'Confirm', 'create') : {}),
                            {
                                label: "Print",
                                style: "DarkblueMd",
                                marginLeft: "ml-10",
                                icon: "print",
                                onClick: () => window.print(),
                                subMenu: 'receipts',
                                type: 'print',
                            },
                        ]}
                    >
                        <SimpleModal
                            isOpen={modalOpen}
                            setIsOpen={setModalOpen}
                        >
                            <div className="modal-stockopname">
                                <WarningIcon className="row-action red" />
                                <p>Quantity Missmatch</p>
                                <div className="button-container">
                                    <Button
                                        onClick={() => {
                                            setModalOpen(false)
                                            setOpenedModal(true)
                                        }}
                                        label="Re-calculate"
                                        disabled={confirming} />
                                    <Button
                                        onClick={handleConfirm}
                                        label={confirming ? 'Confirming...' : 'Confirm'}
                                        disabled={confirming}
                                    />
                                </div>
                            </div>
                        </SimpleModal>
                        {
                            dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                        }
                        <DetailHeader data={detailHeader} />
                        <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} noUseDisp={true} />

                        <div className="detail-page ">
                            <div className="table-container">
                                {
                                    initialLoading ? <CircularProgress size={24} /> :
                                        arrTableItem?.length > 0 && (
                                            <table className="table wide table-detail style-2">
                                                <thead>
                                                    <tr>
                                                        <th>ID</th>
                                                        <th>Product Name</th>
                                                        <th className="width-as-content">Sales price</th>
                                                        <Permission
                                                            component={
                                                                <>
                                                                    <th>Qty Sent</th>
                                                                    <th>Total Qty Sent</th>
                                                                    <th className="width-as-content">Total Qty Sent Value</th>
                                                                </>
                                                            }
                                                            type="confirm"
                                                            subMenu="receipts"
                                                        />
                                                        {/* {(!isGeneratedFromOtherModule && !waitingOrDone) && <th>Qty Sent</th>}
                                                {(!isGeneratedFromOtherModule && !waitingOrDone) && <th>Total Qty Sent</th>}
                                                {(!isGeneratedFromOtherModule && !waitingOrDone) && <th className="width-as-content">Total Qty Sent Value</th>} */}
                                                        <th>Qty Received</th>
                                                        <th>Qty Total</th>
                                                        <th>UoM</th>
                                                        <th>Total Received Value</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        arrTableItem.map((product, productIdx) => {
                                                            const qty = product.qty_mod.map((q, qIdx) => q.variant ? <span key={qIdx} className="label rect mr-4 mb-4 grey">{q.variant}:{q.qty}</span> : <>{q.qty}</>);
                                                            const total_qty = product.qty_mod.length > 1 ? product.qty_mod.reduce((prev, current) => ({ qty: prev.qty + current.qty })) : { qty: product.qty_mod[0].qty }
                                                            const total_qty_receive = product.qty_mod.length > 1 ? product.qty_mod.reduce((prev, current) => ({ qty_received: prev?.qty_received + current?.qty_received })) : { qty_received: product.qty_mod[0].qty_received }
                                                            const total_qty_receive_value = product.qty_mod.map(qty => qty.price * total_qty_receive.qty_received)[0]
                                                            const total_qty_value = product.qty_mod.map(qty => qty.price * total_qty.qty)[0]
                                                            const qtyMod = product.qty_mod[productIdx]
                                                            // const redLabel = openedModal && qtyMod.qty_received !== qtyMod.qty
                                                            const redLabel = openedModal && product.qty !== product.qty_received
                                                            return (
                                                                <tr key={productIdx}>
                                                                    <td>{product.product_code}</td>
                                                                    <td className="highlight">{`${product.product_name} ${product.color_name}`}</td>
                                                                    <td>IDR {money(product.price)}</td>
                                                                    <Permission
                                                                        component={
                                                                            <>
                                                                                <td>{qty}</td>
                                                                                <td>{total_qty.qty || 0}</td>
                                                                                <td>IDR {money(total_qty_value || 0)}</td>
                                                                            </>
                                                                        }
                                                                        type="confirm"
                                                                        subMenu="receipts"
                                                                    />
                                                                    {/* {
                                                                (!isGeneratedFromOtherModule && !waitingOrDone) &&
                                                                <td>{qty}</td>
                                                            }
                                                            {
                                                                (!isGeneratedFromOtherModule && !waitingOrDone) &&
                                                                <td>{total_qty.qty || 0}</td>
                                                            }
                                                            {
                                                                (!isGeneratedFromOtherModule && !waitingOrDone) &&
                                                                <td>IDR {money(total_qty_value || 0)}</td>
                                                            } */}
                                                                    <td className="w-60">
                                                                        {
                                                                            data.status !== 'waiting' ?
                                                                                renderQTyReceived(product) : renderQTyReceivedWaiting(product, productIdx)
                                                                        }
                                                                    </td>
                                                                    <td>{total_qty_receive.qty_received}</td>
                                                                    <td>{product.uom_name}</td>
                                                                    <td>IDR {money(total_qty_receive_value)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr className="total">
                                                        <td colSpan={hasAccess ? '4' : '3'} className="note br-0">
                                                            <b>Notes : </b>
                                                            <p>{data.note}</p>
                                                        </td>
                                                        <Permission
                                                            component={
                                                                <>
                                                                    <td></td>
                                                                    <td className="v-top">
                                                                        <p className="total-inline">
                                                                            <span>Total Value</span>
                                                                            IDR {money(total)}
                                                                        </p>
                                                                    </td>
                                                                </>
                                                            }
                                                            type="confirm"
                                                            subMenu="receipts"
                                                        />
                                                        {/* {
                                                    (!isGeneratedFromOtherModule && !waitingOrDone) &&
                                                    <>
                                                        <td></td>
                                                        <td className="v-top">
                                                            <p className="total-inline">
                                                                <span>Total Value</span>
                                                            IDR {money(total)}
                                                            </p>
                                                        </td>
                                                    </>
                                                } */}
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td className="v-top">
                                                            <p className="total-inline">
                                                                <span>Total</span>
                                                                IDR {money(totalReceived)}
                                                            </p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>)
                                }
                            </div>
                        </div>
                        <SignatureColumn typeContent={"All"} data={data} />
                    </ContentWrapper>
                </div>
            </Print>
        </PrintProvider>
    )
}

export default Detail
