import { handleRequest, makeGetParam, handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
export const MEMBER_SET = 'MEMBER_SET'
export const MEMBER_ERROR = 'MEMBER_ERROR'
export const MEMBER_ADD = 'MEMBER_ADD'
export const MEMBER_SHOW = 'MEMBER_SHOW'
export const MEMBER_EDIT = 'MEMBER_EDIT'
export const MEMBER_UPDATE = 'MEMBER_UPDATE'
export const MEMBER_DELETE = 'MEMBER_DELETE'
export const MEMBER_LOADING = 'MEMBER_LOADING'
export const MEMBER_STOP_LOADING = 'MEMBER_STOP_LOADING'
export const MEMBER_RESET = 'MEMBER_RESET'
const type = "MEMBER"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const loadMember = () => async (dispatch) => {
    const online = await isOnline()
    handleReq({
        type: 'MEMBER',
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/member`),
        next: (payload) => dispatch({ type: MEMBER_SET, payload })
    })
}
export const addMember = data => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type: 'MEMBER',
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/member`, data),
        next: (payload) => dispatch({ type: MEMBER_ADD, payload })
    })
}
export const updateMember = (idEdit, data) => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type: 'MEMBER',
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/member/${idEdit}`, data),
        next: (payload) => dispatch({ type: MEMBER_UPDATE, payload })
    })
}
export const deleteMember = id => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${online ? hostUrl : offlineUrl}/api/member`, id),
        next: () => dispatch({ type: MEMBER_DELETE, payload: id })
    })
}
export const showMember = idx => {
    return {
        type: MEMBER_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: MEMBER_EDIT,
        payload: idEdit,
    }
}
export const resetStatusMember = () => {
    return {
        type: MEMBER_RESET
    }
}
export const filterMember = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/member${makeGetParam(args)}`),
        next: () => dispatch({ type: MEMBER_STOP_LOADING })
    })
}
export const loadProvince = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/provinces${makeGetParam(args)}`),
        next: () => dispatch({ type: MEMBER_STOP_LOADING })
    })
}
export const loadCity = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/city${makeGetParam(args)}`),
        next: () => dispatch({ type: MEMBER_STOP_LOADING })
    })
}
