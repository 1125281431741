import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik';


const Button = (props) => {

    const { label, style, disabled, onClick, type, icon = null, marginLeft, marginRight, MarginBottom, MarginTop } = props
    const formik = useFormikContext();

    let btnStyle = 'btn '

    btnStyle += style.includes('Orange') ? ' btn-orange' : ''
    btnStyle += style.includes('Secondary') ? ' btn-secondary' : ''
    btnStyle += style.includes('Text') ? ' btn-text' : ''
    btnStyle += style.includes('Tile') ? ' btn-tile' : ''
    btnStyle += style.includes('Green') ? ' btn-green' : ''
    btnStyle += style.includes('Red') ? ' btn-red' : ''
    btnStyle += style.includes('Blue') ? ' btn-blue' : ''
    btnStyle += style.includes('Darkblue') ? ' btn-darkblue' : ''
    btnStyle += style.includes('Grey') ? ' btn-grey' : ''
    btnStyle += style.includes('Lg') ? ' btn-lg' : ''
    btnStyle += style.includes('Md') ? ' btn-md' : ''
    btnStyle += style.includes('Block') ? ' btn-block' : ''
    btnStyle += style.includes('Wide') ? ' btn-wide' : ''
    btnStyle += style.includes('Search') ? ' btn-search' : ''
    btnStyle += style.includes('Subbtn') ? ' btn-subbtn' : ''
    btnStyle += style.includes('Sub') ? ' btn-sub' : ''
    btnStyle += style.includes('Lightblue') ? ' btn-lightblue' : ''
    btnStyle += style.includes('Rect') ? ' btn-rect' : ''
    btnStyle += style.includes('Tableproduct') ? ' btn-tableproduct' : ''

    btnStyle += ' ' + marginLeft
    btnStyle += ' ' + marginRight
    btnStyle += ' ' + MarginBottom
    btnStyle += ' ' + MarginTop

    btnStyle += !label ? ' no-label' : ''




    return (
        <button disabled={disabled} type={type || 'button'} className={btnStyle} onClick={() => {
            if (onClick) {
                onClick(formik)
            }
        }}>
            {icon && icon !== 'spinner' && <FontAwesomeIcon icon={icon} />}
            {icon && icon === 'spinner' && <FontAwesomeIcon spin icon="spinner" />}
            { label || ''}
        </button>
    )
}
Button.propTypes = {
    label: PropTypes.string,
    style: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    // onClick: PropTypes.func.isRequired,
    // marginLeft: PropTypes.string
}

Button.defaultProps = {
    label: '',
    style: '',
    type: 'button',

}

export default Button