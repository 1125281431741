import React, { useState, useEffect } from 'react'
import { getAuthToken, globalDialog, money, scrollToTop } from '../../../Helper'
import { debounce } from 'lodash'

/** ICONS */
import VisibilityIcon from '@material-ui/icons/Visibility'
import PrintIcon from '@material-ui/icons/Print'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'

/** Components */
import Label from 'Elements/Label'
import Permission from '../../../comps/permission/Permission'
import { globalTableStyle } from 'Configs/table'
import Alert from 'Comps/elements/Alert'
import MUIDataTable from 'mui-datatables'
import KopSurat from 'Elements/KopSurat'
import ContentWrapper from 'Comps/container/ContentWrapper'
import SimpleModal from 'Elements/modal/Modal'
import InputNumber from 'Elements/inputnumber/InputNumber'
import Button from 'Elements/Button'
import { makeGetParam, customGetParam } from 'Configs/request.js'
import print_barcode from '../../../lib/print-barcode'

/** Product Redux */
import {
  deleteProduct,
  setEditProduct,
  resetStatusProduct
} from 'Reduxes/inventory/product/productActions'

import { useDispatch, useSelector } from 'react-redux'
import PrintProvider, { Print } from 'react-easy-print'
import CustomToolbar from '../../../comps/elements/CustomToolbar'
import { TableConfigOnDownload } from '../../../configs/on-download-table'
import ProductFilter from './ProductFilter'
import { Chip, CircularProgress, Typography } from '@material-ui/core'
import { CustomSortColumn } from '../../../utils/SortColumn'

const TableDataSSR = (props) => {
  const classes = globalTableStyle()

  const { error, success } = useSelector((state) => state.productReducer)

  const {
    showDetail,
    showForm,
    indexSetRowsPerPage,
    indexSetPage,
    indexSetFilters,
    indexSetParams,
    initialPage,
    initialRowsPerPage,
    initialFilters,
    initialParams
  } = props

  const dispatch = useDispatch()

  const initialValues = Object.keys(initialFilters)?.length > 0 ? initialFilters : {
    brand_ids: [],
    category_ids: [],
    color_ids: [],
    stock_from: '',
    stock_to: '',
    product_types: [],
    product_statuses: [],
    sales_price_from: '',
    sales_price_to: '',
    hpp_from: '',
    hpp_to: ''
  }

  const handleDelete = (id) => {
    globalDialog(async () => {
      await dispatch(deleteProduct(id))
      scrollToTop()
      fetchProducts()
      setFilters(initialValues)
    })
  }
  const [page, setPage] = useState(initialPage)
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage)
  const [count, setCount] = useState(0)
  const [data, setData] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [filters, setFilters] = useState(initialValues)
  const [params, setParams] = useState('')

  /**
   * PRINT CONFIGURATIONS
   */

  // SKEMA PRINT BARCODE DI ROW TABLE
  const [printErr, setPrintErr] = useState(false)
  const [printSuc, setPrintSuc] = useState(false)
  const [modal, setModal] = useState(false)
  const [dataPrintBarcode, setDataPrintBarcode] = useState([])

  // menampilkan modal
  const showModal = (e) => {
    setModal((state) => !state)
  }

  //update copies print
  const setCopiesPrint = (val, data) => {
    const { barcode } = data
    setDataPrintBarcode((state) => {
      return state.map((s) => {
        if (s.barcode === barcode) {
          s.copies = +val
        }
        return s
      })
    })
  }

  // action ketika icon print di row table di klick
  const handlePrint = (v) => {
    const filterData = data.find((x) => x.id === v)
    let countData = []

    if (filterData.product_variant_item.length) {
      filterData.product_variant_item.map((d) => {
        countData.push({
          barcode: d.barcode,
          product_name: filterData.product_name,
          brand: filterData.brand_name,
          price: filterData.sales_price,
          color: filterData.color_name,
          variant: d.variant_type_value,
          copies: 0
        })
      })
      setDataPrintBarcode(countData)
    } else {
      countData.push({
        barcode: filterData.code,
        product_name: filterData.product_name,
        brand: filterData.brand_name,
        price: filterData.sales_price,
        color: filterData.color_name,
        variant: null,
        copies: 0
      })
      setDataPrintBarcode(countData)
    }
    showModal()
  }

  //action ketika tombol print di modal di klick
  const printBarcode = () => {
    // PRINT BARCODE
    const optionsBarcode = {
      printerName: 'PW_BARCODE_PRINTER'
    }

    try {
      print_barcode(dataPrintBarcode, optionsBarcode)

      // jika berhasil print
      setPrintSuc(true) // menampilkan notif success

      setTimeout(function () {
        setPrintSuc(false) // menghilangkan notif success
      }, 3000)

      console.log(dataPrintBarcode)
    } catch (err) {
      // jika gagal print
      setPrintErr(true) // menampilkan notif error

      setTimeout(function () {
        setPrintErr(false) // menghilangkan notif error
      }, 3000)
    }
    // END print Barcode
  }

  /** END PRINT CONFIGURATIONS */

  /** Fetch Products */
  useEffect(() => {
    fetchProducts()
  }, [])

  const fetchProducts = () => {
    setLoading(true)

    if (Object.keys(initialFilters)?.length > 0 && initialParams) {
      loadProduct(page, rowsPerPage, initialParams).then((res) => {
        setData(res.data)
        setLoading(false)
        setCount(res.total)
      })
    } else {
      loadProduct(page).then((res) => {
        setData(res.data)
        setLoading(false)
        setCount(res.total)
      })
    }
  }

  /** Fetch Products
   * @param page integer
   * @param rowsPerPage integer
   * @param params Parameter URL
   */
  const loadProduct = async (page, rowPerPage, params = null) => {
    if (!rowPerPage) rowPerPage = rowsPerPage

    return new Promise(async (resolve, reject) => {
      const auth = getAuthToken()
      const headers = {
        Authorization: auth,
        method: 'GET'
      }

      let query = `${process.env.PW_API_URL}/api/product`

      if (params) {
        query += `?page=${page}&limit=${rowPerPage}${params}`
        const idxQuestionMarkParam = query.indexOf('?')
        query = query
          .split('')
          .map((str, idx) => {
            if (str === '?' && idx > idxQuestionMarkParam) {
              return '&'
            }
            return str
          })
          .join('')
      } else {
        query += `?page=${page}&limit=${rowPerPage}`
      }

      const products = await fetch(query, {
        headers
      })
        .then((res) => res.json())
        .catch(({ message }) => reject(message))

      if (products) {
        /** Format response Products for Render Stock */
        const formattedData = products.data.map((product) => {

          let total = 0

          if (product?.product_variant_item?.length > 0) {
            total = product.product_variant_item?.reduce(
              (total, current) => {
                return (total += current.stock_on_hand)
              },
              0
            )
          } else {
            total = product?.stock_on_hand
          }


          return { ...product, stock_on_hand: total }
        })

        const total = products.total
        const data = formattedData

        resolve({
          data,
          total
        })
      }

      reject()
    })
  }

  const changePage = (page, rowsPerPage, params = null) => {
    setLoading(true)

    loadProduct(page, rowsPerPage, params).then((res) => {
      setPage(page)
      setData(res.data)
      setCount(res.total)
      setRowsPerPage(rowsPerPage)
      indexSetRowsPerPage(rowsPerPage)
      indexSetPage(page)

      setTimeout(() => {
        setLoading(false)
      }, 150)
    })
  }

  const columns = [
    {
      name: 'id',
      options: {
        display: false,
        viewColumns: false,
        filter: false
      }
    },
    {
      name: 'no',
      label: 'No.',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta) => {
          const tableState = tableMeta.tableState
          const { page, rowsPerPage } = tableState
          if (page >= 1) {
            const result = tableMeta.rowIndex + 1 + page * rowsPerPage
            return result
          }
          return tableMeta.rowIndex + 1
        }
      }
    },
    {
      name: 'code',
      label: 'Code',
      options: {
        filter: false
      }
    },
    {
      name: 'category_name',
      label: 'Category',
      options: {
        filter: false
      }
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: false
      }
    },
    {
      name: 'product_type',
      label: 'Type',
      options: {
        filter: false
      }
    },
    {
      name: 'brand_name',
      label: 'Brand',
      options: {
        filter: false
      }
    },
    {
      name: 'color_name',
      label: 'Color',
      options: {
        filter: false
      }
    },
    {
      name: 'sales_price',
      label: 'Sales Price',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return `IDR ${money(value)}`
        }
      }
    },
    {
      name: 'hpp',
      label: 'HPP',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return `IDR ${money(value)}`
        }
      }
    },
    {
      name: 'material_price',
      label: 'Material Price',
      options: {
        filter: false,
        customBodyRender: (value) => {
          return `IDR ${money(value)}`
        }
      }
    },
    {
      name: 'stock_on_hand',
      label: 'Stock',
      options: {
        filter: false,
        customBodyRender: (value) => {
          if (!value) return 0
          return value
        }
      }
    },
    {
      name: 'uom_name',
      label: 'UoM',
      options: {
        filter: false
      }
    },
    {
      name: 'status',
      label: 'Status',
      options: {
        filter: false,
        customBodyRender: (value) => (
          <Label
            type={value ? 'success' : 'danger'}
            text={value ? 'active' : 'Inactive'}
            onClick={() => alert(1)}
          />
        )
      }
    },
    {
      name: 'id',
      label: 'Actions',
      options: {
        setCellHeaderProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: 0,
            zIndex: 50
          }
        }),
        setCellProps: () => ({
          style: {
            whiteSpace: 'nowrap',
            position: 'sticky',
            right: '0',
            background: 'white',
            zIndex: 50
          }
        }),
        filter: false,
        sort: false,
        print: false,
        customBodyRender: (value) => {
          const attrShow = {
            className: 'row-action',
            onClick: () => showDetail(value)
          }

          const attrEdit = {
            className: 'row-action',
            onClick: () => {
              dispatch(setEditProduct(value))
              showForm('Edit')
              scrollToTop()
            }
          }

          const attrDelete = {
            className: 'row-action',
            onClick: () => handleDelete([value])
          }

          const attrPrint = {
            className: 'row-action',
            onClick: () => handlePrint(value)
          }

          return (
            <>
              <PrintIcon {...attrPrint} />
              <VisibilityIcon {...attrShow} />
              <Permission
                component={<EditIcon {...attrEdit} />}
                type="update"
                subMenu="product"
              />
              <Permission
                component={<DeleteIcon {...attrDelete} />}
                type="delete"
                subMenu="product"
              />
            </>
          )
        }
      }
    }
  ]

  const handleSearch = debounce((keyword, props) => {
    setLoading(true)
    const params = makeGetParam({ q: keyword })
    loadProduct(page, rowsPerPage, params).then((res) => {
      setData(res.data)
      setCount(res.total)
      setLoading(false)
    })
  }, 1000)

  const handleFilterSubmit = ({
    brand_ids,
    category_ids,
    color_ids,
    hpp_from,
    hpp_to,
    sales_price_from,
    sales_price_to,
    stock_from,
    stock_to,
    product_types,
    product_statuses
  }) => {
    setLoading(true)
    let params = ''

    setFilters({
      brand_ids,
      category_ids,
      color_ids,
      hpp_from,
      hpp_to,
      sales_price_from,
      sales_price_to,
      stock_from,
      stock_to,
      product_types,
      product_statuses
    })
    indexSetFilters({brand_ids, category_ids, color_ids, hpp_from, hpp_to, sales_price_from, sales_price_to, stock_from, stock_to, product_types, product_statuses})

    if (brand_ids?.length) {
      brand_ids = serializeSelectValue(brand_ids)
      params += makeGetParam({ brand_ids })
    }

    if (category_ids?.length) {
      category_ids = serializeSelectValue(category_ids)
      params += makeGetParam({ category_ids })
    }

    if (color_ids?.length) {
      color_ids = serializeSelectValue(color_ids)
      params += makeGetParam({ color_ids })
    }

    if (hpp_from && hpp_to) {
      params += customGetParam({ hpp_from, hpp_to })
    }

    if (sales_price_from && sales_price_to) {
      params += customGetParam({ sales_price_from, sales_price_to })
    }

    if (stock_from && stock_to) {
      params += customGetParam({ stock_from, stock_to })
    }

    if (product_types?.length) {
      product_types = serializeSelectValue(product_types)
      params += makeGetParam({ product_types })
    }

    if (product_statuses?.length) {
      product_statuses = serializeSelectValue(product_statuses)
      params += makeGetParam({ product_statuses })
    }

    setParams(params)
    indexSetParams(params)

    loadProduct(page, rowsPerPage, params).then((res) => {
      setData(res.data)
      setCount(res.total)
      indexSetPage(page)
      indexSetRowsPerPage()

      setTimeout(() => {
        setLoading(false)
      }, 150)
    })
  }

  const serializeSelectValue = (data) => {
    return data.map((data) => data.value)
  }

  const onDeleteChip = (title) => {
    if (title === 'Brands') {
      setFilters({ ...filters, brand_ids: [] })
    }

    switch (title) {
      case 'Brands':
        filters.brand_ids = []
        setFilters(filters)
        break
      case 'Categories':
        filters.category_ids = []
        setFilters(filters)
        break
      case 'Colors':
        filters.color_ids = []
        setFilters(filters)
        break
      case 'Product Type(s)':
        filters.product_types = []
        setFilters(filters)
        break
      case 'Product Status':
        filters.product_statuses = []
        setFilters(filters)
        break
      case 'Hpp':
        filters.hpp_from = 0
        filters.hpp_to = 0
        setFilters(filters)
        break
      case 'Sales Price':
        filters.sales_price_from = 0
        filters.sales_price_to = 0
        setFilters(filters)
        break
      case 'Stock':
        filters.stock_from = 0
        filters.stock_to = 0
        setFilters(filters)
        break
      default:
    }
    handleFilterSubmit(filters)
  }

  const RenderChipFilter = () => {
    let keyProperty = []

    if (filters.brand_ids?.length) {
      keyProperty.push({
        title: 'Brands',
        lists: filters.brand_ids.map(({ label }) => label)
      })
    }

    if (filters.category_ids?.length) {
      keyProperty.push({
        title: 'Categories',
        lists: filters.category_ids.map(({ label }) => label)
      })
    }

    if (filters.color_ids?.length) {
      keyProperty.push({
        title: 'Colors',
        lists: filters.color_ids.map(({ label }) => label)
      })
    }

    if (filters.product_types?.length) {
      keyProperty.push({
        title: 'Product Type(s)',
        lists: filters.product_types.map(({ label }) => label)
      })
    }

    if (filters.product_statuses?.length) {
      keyProperty.push({
        title: 'Product Status',
        lists: filters.product_statuses.map(({ label }) => label)
      })
    }

    if (filters.hpp_from && filters.hpp_to) {
      keyProperty.push({
        title: 'Hpp',
        lists: `${filters.hpp_from} - ${filters.hpp_to}`
      })
    }

    if (filters.sales_price_from && filters.sales_price_to) {
      keyProperty.push({
        title: 'Sales Price',
        lists: `${filters.sales_price_from} - ${filters.sales_price_to}`
      })
    }

    if (filters.stock_from && filters.stock_to) {
      keyProperty.push({
        title: 'Stock',
        lists: `${filters.stock_from} - ${filters.stock_to}`
      })
    }

    return keyProperty.map(({ title, lists }) => (
      <>
        <Chip
          variant="outlined"
          color="secondary"
          label={`${title}: ${lists}`}
          key={title}
          onDelete={() => onDeleteChip(title)}
        />
      </>
    ))
  }

  const handleDeleteBatch = (ids) => {
    const triggerDelete = async (ids) => {
      await Promise.all(
        ids.map(async (id) => {
          await dispatch(deleteProduct(id))
        })
      )
    }

    triggerDelete(ids)
    scrollToTop()
    setLoading(false)
  }

  const options = {
    responsive: false /* scroll */,
    rowsPerPage: rowsPerPage || 10,
    colIdPosition: 0,
    filename: 'Product_Data',
    rowsPerPageOptions: [10, 25, 50],
    filter: true,
    serverSide: true,
    sort: true,
    print: false,
    selectableRows: false,
    count: count || 10,
    confirmFilters: true,
    page: page,
    customFilter: true,
    customFilterDialogFooter: () => (
      <ProductFilter
        handleFilterSubmit={handleFilterSubmit}
        isLoading={isLoading}
        stateFilters={filters}
      />
    ),
    customToolbar: () => <CustomToolbar showPrint={true} />,
    onSearchChange: (keyword) => {
      handleSearch(keyword)
    },
    onDownload: (buildHead, buildBody, columns, values) =>
      TableConfigOnDownload(
        'Product_Data',
        buildHead,
        buildBody,
        columns,
        values
      ),
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        changePage(tableState.page, tableState.rowsPerPage, params)
      }
    },
    onRowsDelete: (e) => {
      const ids = e.data.map(({ index }) => index)

      const productIds = data
        .filter((val, idx) => ids.includes(idx))
        ?.map((data) => data.id)

      setLoading(true)
      handleDeleteBatch(productIds)
      fetchProducts()
      setFilters(initialValues)
    },
    onColumnSortChange: (changedColumn, direction) => {
      if (isLoading) console.log('You clicked when data is being proceceed.')

      setLoading(true)

      const sortered = CustomSortColumn(changedColumn, direction, data)

      if (sortered.length) {
        setData(sortered)
      }

      setTimeout(() => {
        setLoading(false)
      }, 400)
    }
  }

  return (
    <PrintProvider>
      <Print single name="print-product-table-1">
        <KopSurat />
        <div className="spacer-60"></div>
        <ContentWrapper
          title="Data Products"
          subtitle="showing table of all data products"
          buttons={[
            {
              label: 'Add product',
              style: 'OrangeMd',
              icon: 'plus-circle',
              onClick: () => showForm('New'),
              type: 'create',
              subMenu: 'product'
            }
          ]}
        >
          <>
            {success && (
              <Alert
                hide={() => dispatch(resetStatusProduct())}
                option={{ type: 'success', message: success }}
              />
            )}
            {error && (
              <Alert
                hide={() => dispatch(resetStatusProduct())}
                option={{ type: 'error', message: error }}
              />
            )}

            <Typography variant="h6">
              {isLoading && (
                <CircularProgress
                  size={24}
                  style={{ marginLeft: 15, position: 'relative', top: 4 }}
                />
              )}
            </Typography>

            {filters && <RenderChipFilter />}
            {
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
                className={classes.table}
              />
            }
            <SimpleModal
              customClass="modal"
              isOpen={modal}
              setIsOpen={setModal}
              width={900}
              title="Print Barcode"
            >
              {printSuc && (
                <div className="print-notif mt-30">
                  <Alert
                    option={{
                      type: 'success',
                      message: 'Printed Barcode Successfully'
                    }}
                  />
                </div>
              )}
              {printErr && (
                <div className="print-notif mt-30">
                  <Alert
                    option={{
                      type: 'error',
                      message: 'Barcode Failed to Print'
                    }}
                  />
                </div>
              )}

              <div className="scroll">
                <table className="table table-detail style-2 mb-30 ">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Barcode</th>
                      <th>Product Name</th>
                      <th>Variant</th>
                      <th>Color</th>
                      <th>Copies</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataPrintBarcode.map((data, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{data.barcode}</td>
                        <td>{data.product_name}</td>
                        <td>{data.variant ? data.variant : '-'}</td>
                        <td>{data.color}</td>
                        <td>
                          <InputNumber
                            withFieldContainer
                            value={data.copies}
                            handleChange={(val) => setCopiesPrint(val, data)}
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Button
                  type="button"
                  style="OrangeMdRect"
                  label="Print"
                  icon="print"
                  onClick={() => printBarcode()}
                />
              </div>
            </SimpleModal>
          </>
        </ContentWrapper>
      </Print>
    </PrintProvider>
  )
}

export default TableDataSSR
