import React, { useState, useEffect } from 'react'
import DeleteIcon from '@material-ui/icons/Delete';
import { money } from '@/Helper'
import InputNumber from 'Elements/inputnumber/InputNumber'
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash'

export default function TableSales(props) {
    const { permission } = useSelector(state => state.userReducer);
    const {
        products: productsProps,
        setProducts: setProductsProps,
        removeProduct,
        status,
        action,
        buyer,
        tableType,
        transactionReturn
    } = props;
    const [products, setProducts] = useState([]);
    const hasConfirmPermission = permission.some(access => access.menu === 'sales_pos' && access.type === 'confirm')

    const updateQty = debounce((val, props) => {
        const beforeMaxQty = products[props.index].qty
        products[props.index].qty = +val;
        Object.assign(products[props.index], {
            max_qty: beforeMaxQty
        })
        setProducts([...products]);
        setProductsProps([...products])
        action([])
    }, 1000)

    const availablePromotionTypes = ['percent', 'quantity']
    const isMember = buyer?.label?.toLowerCase() !== 'general' && buyer?.label !== 'vip'
    const [productGaIds, setProductGaIds] = useState([]);
    const [isReady, setIsReady] = useState(false)
    /**
     * 
     * @param {Passed From JSX} product 
     * @param {Current Index Loop From JSX} productIdx 
     * @returns Component Button Delete
     */
    const renderDelete = (product, productIdx) => {
        const isExchangeItem = product?.is_exchange && product?.is_return_exchange;

        if (!product) return null;

        if (!isExchangeItem) {
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }

        // validate bonus givaway (cannot returned)
        if (productGaIds?.length > 0) {
            if (productGaIds.includes(product.product_id) && isExchangeItem) {
                return false
            }
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }

        if (!product?.promotion) {
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }

        const hasPromotion = Object?.keys(product?.promotion)?.length > 0

        const promotion_id = product?.promotion?.promotion_id;
        const promotion_type = product?.promotion?.promotion_type;
        const hasForbiddenPromotion = availablePromotionTypes.includes(promotion_type)
        const isReturnAble = product?.promotion?.returnable;


        const isPromoMemberDefault = promotion_id === 10000004

        if (!hasPromotion || promotion_type === 'quantity' || isReturnAble) {
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }

        if (product?.is_exchange && !product?.hasOwnProperty("is_return_exchange")) {
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }

        if (isMember && (isPromoMemberDefault || !hasForbiddenPromotion)) {
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }

        if (!isMember && !hasForbiddenPromotion) {
            return (
                <DeleteIcon onClick={() => removeProduct(productIdx)} />
            )
        }
    }

    const renderTable = () => {
        return (
            <div className="table-product">
                <table>
                    <thead>
                        <tr>
                            <th>Code Product</th>
                            <th>Product name</th>
                            <th>Price / unit</th>
                            <th>Unit</th>
                            <th>Promotion</th>
                            <th>Qty</th>
                            <th>Total</th>
                            {
                                status !== 'paid' &&
                                <th>Action</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            products.map((product, productIdx) => {
                                const promotion_name = product?.promotion?.promotion_name ?? product?.promotion?.name;
                                const promotion_type = product?.promotion?.promotion_type ?? product?.promotion?.type;
                                const promotion_values = product?.promotion?.promotion_values ?? product?.promotion?.values;
                                const isExchangeItem = product?.is_return_exchange;

                                const style = isExchangeItem ? { color: 'red' } : null;

                                const promoName = promotion_name ?? '-'
                                const promoPercent = promotion_type === 'percent' ? ` (${promotion_values.discount}%)` : ''
                                return (
                                    <tr key={uuidv4()}>
                                        <td className="width-as-content" style={{ ...style }}>{product.sku}</td>
                                        <td className="highlight width-as-content" style={{ ...style }}>{product.product_name} {product.color_name} {product.variant_type_value ? `- (${product.variant_type_value})` : ``}</td>
                                        <td className="text-right width-as-content" style={{ ...style }}>IDR {money(product.price)}</td>
                                        <td style={{ ...style }}>{product.uom_name}</td>
                                        <td className="width-200" style={{ ...style }}>
                                            {
                                                promoName + promoPercent
                                            }
                                        </td>
                                        <td className="text-center" style={{ ...style }}>
                                            {
                                                tableType === 'exchange' ? <p>{product.qty}</p> : <InputNumber handleChange={updateQty} {...(product.isReturn || product?.is_return_exchange ? { max: product?.max_qty ?? product.qty } : {})} index={productIdx} value={product.qty} disabled={!hasConfirmPermission} />
                                            }
                                        </td>
                                        {
                                            isExchangeItem ?
                                                <td className={`${product.isReturn ? `text-right` : ``} text-right width-as-content`} style={{ ...style }}>IDR {money(product.total)}</td>
                                                :
                                                <td className={`${product.isReturn ? `text-right` : ``} text-right width-as-content`} style={{ ...style }}>IDR {money(product.price * product.qty)}</td>
                                        }
                                        {
                                            status !== 'paid' &&
                                            <td className="btn-action" style={{ textAlign: 'center' }}>
                                                {
                                                    tableType === 'exchange' ? false : (
                                                        isReady ? renderDelete(product, productIdx) : false
                                                    )
                                                }
                                            </td>
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

            </div>
        )
    }

    useEffect(() => {
        setProducts(productsProps);
    }, [productsProps])

    useEffect(() => {
        if (transactionReturn) {
            const bonusIds = []
            const giveaway = transactionReturn?.payment_method?.giveaway_promotion;

            if (giveaway) {
                const promoValue = giveaway?.values;

                if (promoValue?.giveaways?.length > 0) {
                    promoValue.giveaways.forEach((giveaway) => {
                        if (giveaway?.products?.length > 0) {
                            giveaway.products.forEach((product) => {
                                bonusIds.push(product.id)
                            })
                        }
                    })
                }

                setProductGaIds(bonusIds)
            }
        }

        setIsReady(true)
    }, [products])

    return (
        <>
            {renderTable()}
        </>
    )
}
