import {
    PRODUCT_LOADING,
    PRODUCT_SET,
    PRODUCT_ERROR,
    PRODUCT_ADD,
    PRODUCT_SHOW,
    PRODUCT_DELETE,
    PRODUCT_UPDATE,
    PRODUCT_RESET,
    PRODUCT_EDIT,
    PRODUCT_FILTER,
    PRODUCT_STOP_LOADING
} from './productActions'


const initialState = {
    loading: false,
    data: [],
    dataFilter: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const productReducer = (state = initialState, { payload, type }) => {

    switch (type) {
        case PRODUCT_FILTER:
            return {
                ...state,
                loading: false,
                dataFilter: payload
            }
        case PRODUCT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case PRODUCT_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case PRODUCT_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case PRODUCT_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case PRODUCT_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New product has been added successfully.'
            }
        case PRODUCT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case PRODUCT_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case PRODUCT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case PRODUCT_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case PRODUCT_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                // data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default productReducer
