import React, { useState, useEffect } from "react";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { makeStyles } from "@material-ui/core/styles";
import Select from "Elements/select/Select";
import SelectAsync from "Elements/SelectAsync";
import Datepicker from "Elements/Datepicker";
import Multiselect from "Elements/multiselect/Multiselect";
import InputNum from "Elements/inputnum/InputNum";
import { filterTheme } from "Configs/report";
import { getLocalStorageAuth } from "@/Helper";
import { Formik, Form } from "formik";
import * as _ from "lodash";

const customerData = {
  sales_order: [
    { label: "Draft", value: "draft" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Waiting", value: "waiting" },
    { label: "Delivered", value: "delivered" },
    { label: "Received", value: "received" },
    { label: "Invoiced", value: "invoiced" },
    { label: "Done", value: "done" },
  ],
  invoice: [
    { label: "Draft", value: "draft" },
    { label: "Confirmed", value: "confirmed" },
    { label: "Closed", value: "closed" },
  ],
  payment: [
    { label: "Draft", value: "draft" },
    { label: "Open", value: "open" },
    { label: "Paid", value: "paid" },
  ],
};
const useStyles = makeStyles((theme) => filterTheme(theme));

export default function Filter({ getData, type }) {
  /* STATE */
  const classes = useStyles();
  const [showExtraFilter, setShowExtraFilter] = useState(false);
  const [filters, setFilters] = useState({});
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const isSuperadmin =
    getLocalStorageAuth().user_category_id === 1 ||
    getLocalStorageAuth().user_category_id === 2;
  const [productName, setProductName] = useState(null);

  /* METHOD */
  const toggleExtraFilter = (event) => {
    event.preventDefault();
    setShowExtraFilter((state) => !state);
  };
  const handleChange = (param) => {
    // handle change yang terjadi pada field yg ada di extra filter
    if (param.props.name === "start_date" || param.props.name === "end_date") {
      // start date, end date
      if (param.value === "Invalid date") {
        // jika invalida hapus key start date or end date
        setFilters((state) => {
          let { [param.props.name]: remove, ...rest } = state;
          return { ...rest };
        });
        return;
      }

      // untuk minDate nya end_date
      if (param.props.name === "start_date") {
        setStartDate(param.value);
      } else if (param.props.name === "end_date") {
        setEndDate(param.value);
      }

      setFilters((state) => {
        return {
          ...state,
          [param.props.name]: param.value,
        };
      });
    } else if (
      param.props.name === "start_hpp" ||
      param.props.name === "end_hpp" ||
      param.props.name === "start_price" ||
      param.props.name === "end_price" ||
      param.props.name === "stock_from" ||
      param.props.name === "stock_to" ||
      param.props.name === "start_hpp" ||
      param.props.name === "end_hpp"
    ) {
      setFilters((state) => {
        return {
          ...state,
          [param.props.name]: param.value,
        };
      });
    } else if (param.props.name === "product_id") {
      setFilters((state) => {
        return {
          ...state,
          [param.props.name]: param.selected.value,
        };
      });
    } else if (Array.isArray(param.value)) {
      // saat ini jika array diasumsikan berarti dari selectbox [{label: 1, value: 1}] *case lain  jika array juga belum di handle

      if (param.isAll) {
        // jika ini dari multi select menandakan semua opsi dipilih jadi tidak perlu kirim apa2 ke server
        setFilters((state) => {
          let { [param.props.name]: remove, ...rest } = state;
          return { ...rest };
        });
      } else {
        setFilters((state) => {
          return {
            ...state,
            [param.props.name]: param.value.map((x) => x.value),
          };
        });
      }
    }
  };

  /* EFFECT */
  useEffect(() => {
    getData(filters);
  }, [filters]);

  useEffect(() => {
    setFilters({});
  }, [showExtraFilter]);

  const [initialValues, setInitialValues] = useState({
    product_id: null,
  });
  const formOpts = {
    initialValues,
    onSubmit: async (values, { setSubmitting, setValues, resetForm }) => {
      resetForm();
    },
  };

  const selectProduct = ({ row }) => {
    if (!row) {
      setProductName({
        label: "Select Product",
        value: null,
      });

      setFilters((prevState) => {
        delete prevState.product_id;

        return { ...prevState };
      });
    }

    if (row) {
      setFilters((prevState) => ({
        ...prevState,
        product_id: row.id,
      }));
    }
  };

  return (
    <div className={classes.formextrafilter}>
      <button
        className={`${classes.toggler} ${showExtraFilter ? "teal" : ""}`}
        onClick={toggleExtraFilter}
      >
        {showExtraFilter ? "Hide" : "Show"} Extra Filter
        {showExtraFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </button>
      {showExtraFilter && (
        <div className={classes.formextrafiltercontainer}>
          <div className={classes.formFilter}>
            <div className="mb-24">
              <Formik {...formOpts} enableReinitialize={true}>
                <>
                  <SelectAsync
                    type="productName"
                    name="product_id"
                    cache={false}
                    options="productName"
                    additionalPropsReport="SelectProduct"
                    value={productName}
                    handleChange={(value) => selectProduct(value)}
                    param={{ flat: false }}
                    label="Select Product"
                  />
                </>
              </Formik>
            </div>

            <Multiselect
              name="status"
              getData={handleChange}
              hasSelectAll
              label="Status"
              options={[
                { label: "Active", value: "active" },
                { label: "Inactive", value: "inactive" },
              ]}
            />

            <div></div>
            <div></div>

            <div className={classes.daterangeCont}>
              {
                <Datepicker
                  handleChange={handleChange}
                  label="Start date"
                  name="start_date"
                  value="blank"
                />
              }

              <Datepicker
                {...(startDate ? { minDate: startDate } : {})}
                name="end_date"
                handleChange={handleChange}
                label="end date"
                value="blank"
              />
            </div>

            {type != "stockopname" && type !== "stockopname_detail" && (
              <div className={classes.daterangeCont}>
                <InputNum
                  getData={handleChange}
                  label="Stock from"
                  name="stock_from"
                  type="report"
                />
                <InputNum
                  name="stock_to"
                  getData={handleChange}
                  label="Stock to"
                  type="report"
                />
              </div>
            )}

            {type !== "stockopname_detail" && (
              <div className={classes.daterangeCont}>
                <InputNum
                  getData={handleChange}
                  label="Price from"
                  name="start_price"
                  type="report"
                />
                <InputNum
                  name="end_price"
                  getData={handleChange}
                  label="Price to"
                  type="report"
                />
              </div>
            )}

            {type !== "stockopname_detail" && (
              <div className={classes.daterangeCont}>
                <InputNum
                  name="start_hpp"
                  getData={handleChange}
                  label="HPP from"
                  type="report"
                />
                <InputNum
                  name="end_hpp"
                  getData={handleChange}
                  label="HPP to"
                  type="report"
                />
              </div>
            )}
            {isSuperadmin && (
              <Multiselect
                name="branch_id"
                getData={handleChange}
                param={{ all: true }}
                hasSelectAll
                label="branch"
                options="branch"
              />
            )}

            {type != "mutation" && (
              <Multiselect
                name="warehouse"
                getData={handleChange}
                hasSelectAll
                label="warehouse"
                options={[
                  { label: "Stock", value: "stock" },
                  { label: "Reject", value: "reject" },
                ]}
              />
            )}

            <Multiselect
              name="product_type"
              getData={handleChange}
              hasSelectAll
              label="Product Type"
              options={[
                { label: "Product Sales", value: "product_sales" },
                { label: "Product Consignment", value: "consignment" },
                { label: "Product General", value: "product_general" },
                { label: "Discontinue", value: "discontinue" },
              ]}
            />

            <Multiselect
              name="brand_id"
              getData={handleChange}
              hasSelectAll
              label="brand"
              options="brand"
            />

            <Multiselect
              label="category"
              hasSelectAll
              getData={handleChange}
              name="category_id"
              options="category"
            />
            {/* {
                            type === 'stockopname_detail' && (
                                <Multiselect
                                    name="results"
                                    getData={handleChange}
                                    hasSelectAll
                                    label="Stock Opname Result"
                                    options={[
                                        { label: 'Match', value: 'match' },
                                        { label: 'Not Match', value: 'selisih' },
                                    ]}
                                />
                            )
                        } */}
            {type?.includes("stockopname_detail") && (
              <Multiselect
                name="difference"
                getData={handleChange}
                hasSelectAll
                label="Quantity Diff."
                options={[
                  { label: "Plus", value: "plus" },
                  { label: "Minus", value: "minus" },
                ]}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
