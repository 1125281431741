import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "PROFILE"

export const PROFILE_SET = 'PROFILE_SET'
export const PROFILE_ERROR = 'PROFILE_ERROR'
export const PROFILE_ADD = 'PROFILE_ADD'
export const PROFILE_SHOW = 'PROFILE_SHOW'
export const PROFILE_EDIT = 'PROFILE_EDIT'
export const PROFILE_UPDATE = 'PROFILE_UPDATE'
export const PROFILE_DELETE = 'PROFILE_DELETE'
export const PROFILE_LOADING = 'PROFILE_LOADING'
export const PROFILE_RESET = 'PROFILE_RESET'
export const PROFILE_UPDATE_LOGO = 'PROFILE_UPDATE_LOGO'

export const loadProfile = () => async (dispatch, getState) => {
    handleReq({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/company`),
        next: (payload) => dispatch({ type: PROFILE_SET, payload: payload[0] })
    })
}
export const addProfile = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/company`, data),
        next: (payload) => dispatch({ type: PROFILE_ADD, payload })
    })
}
export const updateProfile = (data) => async (dispatch, getState) => {
    delete data._id;
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/company`, data),
        next: (payload) => dispatch({ type: PROFILE_UPDATE, payload: payload })
    })
}
export const deleteProfile = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/company`, id),
        next: () => dispatch({ type: PROFILE_DELETE, payload: id })
    })
}
export const showProfile = idx => {
    return {
        type: PROFILE_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: PROFILE_EDIT,
        payload: idEdit,
    }
}
export const updateLogo = imageUrl => {
    return {
        type: PROFILE_UPDATE_LOGO,
        payload: imageUrl,
    }
}
export const resetStatusProfile = () => {
    return {
        type: PROFILE_RESET
    }
}