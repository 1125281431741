import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Select from 'Elements/select/Select';
import TableGeneral from './TableGeneral';
import TableDetail from './TableDetail';
import { makeStyles } from '@material-ui/core/styles';
import LoadingContent from 'Elements/LoadingContent';
import { muiStyle, topButton, actionButton } from 'Configs/report'
import Filter from '../Filter'
import Button from 'Elements/Button';
import { makeGetParam, hostUrl, reqGet } from 'Configs/request.js';
import { globalMessage } from '../../../../Helper';
import PrintProvider, { Print } from "react-easy-print";
import KopSurat from "../../../../comps/elements/KopSurat";


const useStyles = makeStyles((theme) => (muiStyle))

const getUrlReport = {
    'false': `${hostUrl}/api/invoice/report`,
    'true': `${hostUrl}/api/invoice/report-per-costumer`,
}


export default function Report() {

    const classes = useStyles();

    /* STATE */
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [extraFilter, setExtraFilter] = useState({});
    const [reportUrl, setReportUrl] = useState('');
    const [filter, setFilter] = useState({
        details: '',
        date_type: null,
    })


    /* METHOD */
    const handleMainFilter = (params) => { // handle main filter seperti type dan group_by
        setData(null);
        setFilter(state => {
            state[params.props.name] = params.selected.value
            return { ...state };
        })
    }
    const reqReport = async (params) => {
        const resp = await reqGet(params.url)
        setLoading(false);

        if (resp.status !== 'success') {
            globalMessage('Fetching report error', 'warning')
            return;
        }

        setData(resp.data);
    }
    const handleExtraFilter = objFilter => {
        setExtraFilter(objFilter)
    }
    const applyFilter = async () => {
        setData(null);
        if (filter.details && filter.date_type) { // jika main filter terpenuhi
            setLoading(true);
            const reportUrl = getUrlReport[filter.details] + makeGetParam({ ...filter, ...extraFilter })
            setReportUrl(reportUrl)
            await reqReport({ url: reportUrl })
        }
    }

    useEffect(() => {
        if (loading) {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }
    }, [loading])


    return (
        <div>
            <ContentWrapper
                title="Invoice Report"
                subtitle="Use available filter below to show custom criteria "
                buttons={actionButton({ reportUrl })}
            >
                <div className="report report-pos" style={{ paddingBottom: '40px' }}>
                    <div className={classes.formfilter}>
                        <Select
                            name="details"
                            label="Type"
                            getData={handleMainFilter}
                            options={[
                                { label: 'Invoice summary', value: 'false' },
                                { label: 'Invoice detail  by customer', value: 'true' }
                            ]}
                        />
                        <Select
                            name="date_type"
                            label="Group by"
                            getData={handleMainFilter}
                            options={[
                                { label: 'Day', value: 'day' },
                                { label: 'Month', value: 'month' },
                                { label: 'Year', value: 'year' }
                            ]}
                        />
                    </div>

                    {/* THE AWESOME FILTER  */}
                    <div className="mb-30">
                        <Filter
                            setFilter={setFilter}
                            type="invoice"
                            getData={handleExtraFilter}
                        />
                    </div>

                    <div className="mb-20">
                        <Button onClick={applyFilter} style="OrangeWideMdRect" icon="check-circle" label="Apply Filter" />
                    </div>


                    <PrintProvider>
                        <Print single name="print-pos-report-table-1" >
                            <KopSurat fit />
                            {data && filter.details === 'false' && <TableGeneral type={filter.date_type} data={data} />}
                            {data && filter.details === 'true' && <TableDetail type={filter.date_type} data={data} />}
                            {loading ? <LoadingContent /> : null}
                        </Print>
                    </PrintProvider>

                </div>
            </ContentWrapper>
        </div>
    )
}
