import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts } from 'Configs/table'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from 'Elements/Label'
import Alert from 'Comps/elements/Alert'
import Table from 'Elements/Table'
import TableNoData from 'Comps/elements/TableNoData'
import { deleteWarehouse, resetStatusWarehouse } from 'Reduxes/inventory/configuration/warehouse/warehouseActions'
import LoadingContent from 'Elements/LoadingContent';

const useStyles = makeStyles(theme => ({
    table: {
        '& td:nth-child(2)': {
            width: '10%',
        },
        '& td:nth-child(6)': {
            width: '10%',
        },
        '& .row-action': {
            color: '#555',
            fontSize: '16px',
            marginRight: '8px',
            cursor: 'pointer'
        }
    },
}));

export default function TableData(props) {

    const { setContentActiveFormEdit, deleteWarehouseProps, setContentActiveDetail } = props
    const { data, loading, error, success } = useSelector(state => state.warehouseReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const table = tableOpts({
        data,
        colIdPosition: 0,
        filter: false,
        noPrint: true,
        showCheckbox: false,
        filename: "Warehouse_Configuration_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        print: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },

                { name: "warehouse_code", label: 'Code' },
                { name: "location_name", label: 'Branch Location' },
                { name: "address", label: 'Address' },
                { name: "phone", label: 'Phone' },
                {
                    name: "status",
                    label: 'Status',
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Label type={value ? 'success' : 'danger'} text={value ? 'active' : 'Inactive'} />
                            )
                        }
                    }
                },
                {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => setContentActiveDetail(tableMeta.rowIndex)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => setContentActiveFormEdit(value)
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => deleteWarehouseProps([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />
                                </>
                            );
                        }
                    }
                },
            ],
        },
        deleteAct: (ids) => {
            dispatch(deleteWarehouse(ids))
        }
    })


    return (
        <>
            {
                success &&
                <Alert hide={() => dispatch(resetStatusWarehouse())} option={{ type: 'success', message: success }} />
            }
            {
                error && <Alert hide={() => dispatch(resetStatusWarehouse())} option={{ type: 'error', message: error }} />
            }
            {
                loading ? <LoadingContent />
                    : (
                        data.length ?
                            <Table {...table} className={classes.table} />
                            : <TableNoData />
                    )
            }
        </>
    )
}

