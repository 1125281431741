import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import { darken } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme, props) => ({
    root: {
        border: 0,
        boxShadow: 'none',
        height: '34px',
        width: '34px !important',
        borderRadius: '3px',
        display: 'inline-grid',
        marginRight: '8px',
        padding: 0,
        minWidth: 'auto',
        '& svg': {
            placeItems: 'center',
        },
        '&:hover': {
            boxShadow: 'none',
        }
    },
    teal: {
        backgroundColor: theme.pw.teal,
        '& svg': {
            color: 'white',
        },
        '&:hover': {
            backgroundColor: darken(theme.pw.teal, 0.1),
        }
    },
    grey: {
        backgroundColor: '#eee',
        '& svg': {
            color: '#999',
        },
        '&:hover': {
            backgroundColor: '#d3d3d3',
            '& svg': {
                color: '#777',
            },
        }
    }
}));


export default function ButtonIcon(props) {
    const { icon, theme, onClick } = props;
    const classes = useStyles(props);

    return (
        <Button variant="contained" className={`${classes.root} ${classes[theme]}`} onClick={onClick}>
            { icon === 'plus' && <AddIcon />}
            { icon === 'trash' && <DeleteIcon />}
        </Button>
    )
}
