import React from 'react'
import PropTypes from "prop-types";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const PageBreadcrumb = ({breadcrumb}) => {
    return null
    return (
        <div className="page-breadcrumb">
            {
                breadcrumb.map( (b, bI) => (
                    <React.Fragment key={bI}>
                        <p className={ b.active && 'active'}>{ b.label }</p>
                        {bI !== (breadcrumb.length - 1) ? <FontAwesomeIcon icon="angle-right" /> : '' }
                    </React.Fragment>
                ))
            }
        </div>
    )
}  

PageBreadcrumb.propTypes = {
    breadcrumb: PropTypes.array,
}

PageBreadcrumb.defaultProps = {
    breadcrumb: [],
}

export default PageBreadcrumb