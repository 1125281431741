import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { comingSoon } from "@/Helper";
import Permission from "../permission/Permission";
import { v4 as uuidv4 } from 'uuid';


const Sidebar = (props) => {
  const navs = [
    {
      path: "/pos",
      label: "pos",
      img: "discount",
      permissionName: 'pos',
      permissionType: 'read',
    },
    {
      path: "/consignment",
      label: "consignment",
      img: "consigment",
      permissionName: 'sales',
      permissionType: 'read',
    },
    {
      path: "/inventory",
      label: "inventory",
      img: "box",
      permissionName: 'inventory',
      permissionType: 'read',
    },
    {
      path: "/membership",
      label: "contact",
      img: "contact",
      permissionName: 'contact',
      permissionType: 'read',
    },
    // {
    //   path: "/download",
    //   label: "download",
    //   img: "download"
    // },
    {
      path: "/configuration",
      label: "settings",
      img: "gear",
    },
  ];

  return (
    <div className="sidebar">
      {navs.map((n, nI) => {
        const isActive = props.match.path === n.path;
        return (
          <React.Fragment key={uuidv4()}>
            {n.label === "download" ? (
              <a href={process.env.PW_DOWNLOAD_URL}>
                <img
                  src={require(`Img/icons/${isActive ? `${n.img}act` : n.img
                    }.svg`)}
                  alt={n.label}
                />
                <span>{n.label}</span>
              </a>
            ) : (
                <Permission
                  component={
                    <NavLink key={nI} to={n.path}>
                      <img
                        src={require(`Img/icons/${isActive ? `${n.img}act` : n.img
                          }.svg`)}
                        alt={n.label}
                      />
                      <span>{n.label}</span>
                    </NavLink>
                  }
                  menu={n.permissionName}
                  type={n.permissionType}
                />
              )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default withRouter(Sidebar);
