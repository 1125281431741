import React from 'react'

export default function ThSort({ title, rowspan, label, colspan, sort, handleClick, className = '' }) {

    const props = {
        colSpan: colspan,
        title,
        rowSpan: rowspan,
        className: `${sort ? `cur-pointer sortable ` : ``} ${className}`
    }

    return sort ? (
        <th
            onClick={handleClick}
            {...props}
        >
            <div>
                <span>{label}</span>
                <div className="sort">
                    <div className="arrow-asc"></div>
                    <div className="arrow-desc"></div>
                </div>
            </div>
        </th>
    ) : <th {...props}>{label}</th>
}
