import Coba from 'Pages/Coba';
import Pos from 'Pages/pos/Pos';
import './assets/styles/App.scss';
import NotFound from 'Img/error.png'
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import Inventory from 'Pages/inventory/Index';
import Membership from 'Pages/membership/Index';
import LoginUser from 'Pages/loginuser/LoginUser';
import { getUserById } from 'Reduxes/user/userActions'
import LoginCabang from 'Pages/logincabang/LoginCabang';
import Consignment from 'Pages/consignment/Consignment';
import { fab } from '@fortawesome/free-brands-svg-icons'
import PrivateRoute from './comps/permission/PrivateRoute'
import { library } from '@fortawesome/fontawesome-svg-core'
import Configuration from 'Pages/configuration/Configuration';
import { Switch, Route, HashRouter, Redirect } from 'react-router-dom';
import { getUsercategoryPermission } from 'Reduxes/usercategory/usercategoryActions'
import { faTrash, faUndo, faPrint, faFilePdf, faTimesCircle, faFileExcel, faExternalLinkAlt, faExclamationTriangle, faPlus, faCircle, faSearch, faUserCircle, faArrowLeft, faSpinner, faPlusCircle, faAngleRight, faCheck, faTimes, faInfoCircle, faPen, faExclamationCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faTrash, faUndo, faPrint, faFilePdf, faFileExcel, faPlusCircle, faArrowLeft, faExclamationTriangle, faPlus, faCircle, faExternalLinkAlt, faSearch, faUserCircle, faSpinner, faTimesCircle, faInfoCircle, faCheck, faTimes, faPen, faAngleRight, faExclamationCircle, faCheckCircle)

function PagesList() {
    const branchAuth = useSelector(state => state.userReducer).branch ? true : false;

	const userAuth = useSelector(state => state.userReducer).token ? true : false;

	const dispatch = useDispatch();
	
	useEffect(() => {	
		const getCurrentUser = () => {
			const userId = JSON.parse(localStorage.getItem('auth'))?.id;
			
			if (userId) {
				dispatch(getUserById(userId)).then((user) => {
					if (user.data) {
						getPermissions(user.data.user_category_id);
					}
				});
			}
		}

		getCurrentUser();
	}, [localStorage.getItem('auth')]);

	const getPermissions = async (userCategoryId) => {
		await dispatch(getUsercategoryPermission({ user_category_id: userCategoryId }));
    }

	return (
		<HashRouter>
			<Switch>
				<PrivateRoute path='/' exact component={LoginCabang} authorization={!branchAuth} redirectUrl="/login" />
				<PrivateRoute path='/login-cabang' component={LoginCabang} authorization={!branchAuth} redirectUrl="/login" />
				<PrivateRoute path='/login' component={LoginUser} authorization={branchAuth && !userAuth} redirectUrl={userAuth ? 'configuration' : '/login-cabang'} />
				<PrivateRoute path='/consignment' component={Consignment} authorization={userAuth} redirectUrl="/login" />
				<PrivateRoute path='/pos' component={Pos} authorization={userAuth} redirectUrl="/login" />
				<PrivateRoute path='/inventory' component={Inventory} authorization={userAuth} redirectUrl="/login" />
				<PrivateRoute path='/membership' component={Membership} authorization={userAuth} redirectUrl="/login" />
				<PrivateRoute path='/configuration' component={Configuration} authorization={userAuth} redirectUrl="/login" />
				<Route path='/coba' component={Coba} />
				<Route render={() => {
					return (
						<div className="page-not-found">
							<img src={NotFound} alt="page not found" />
							<h1>The page you are looking for<span>Could not be found.</span></h1>
						</div>
					);
				}} />
			</Switch>
		</HashRouter>
	);
}

export default PagesList;
