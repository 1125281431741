import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

export default function Detail(props) {

    const { idxShow, data } = useSelector(state => state.categoryReducer);
    const detail = data.filter((a, b) => b === +idxShow)[0]

    console.log({ detail })

    return (
        <div>
            <div className="detail branch">
                <div className="col">
                    <div className="row">
                        <span className="key">Code</span>
                        <span className="val">{detail.code}</span>
                    </div>
                    <div className="row">
                        <span className="key">Category</span>
                        <span className="val">{detail.name}</span>
                    </div>
                    {/* <div className="row">
                        <span className="key">Parent Category</span>
                        <span className="val">{detail.parent_name ? detail.parent_name : '-'}</span>
                    </div>  */}
                </div>
                <div className="col">
                    <div className="row">
                        <span className="key">Status</span>
                        <span className="val">
                            <label className={`label ${detail.status ? `success` : `danger`}`}>{detail.status ? 'Active' : 'Inactive'}</label>
                        </span>
                    </div>
                    {/* <div className="row">
                        <span className="key">Variant</span>
                        <span className="val">
                            {
                                // detail.variant.includes(',') || detail.variant !== '' ? detail.variant.replace(/'|\[|\]/gi, x => '') : '-' 
                                detail.category_variant ? detail.category_variant.map(x => x.variant_value_item).join(', ') : '-'
                            }
                        </span>
                    </div>  */}
                </div>
            </div>
        </div>
    )
}
