import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    name: 
        Yup.string()
            .required('Color name is required'),
    code:
        Yup.string()
            .required('Color code is required'),
})

  