import {
    USER_LOGIN,
    USER_LOADING,
    USER_SET,
    USER_ERROR,
    USER_RESET,
    USER_AUTH,
    USER_TOKEN,
    USER_LOGOUT,
    USER_BRANCH,
} from './userActions'

import { USERCATEGORYPERMISSION_GET } from '../usercategory/usercategoryActions'

const initialState = {
    loading: false,
    data: [],
    login: false,
    error: null,
    success: null,
    auth: null,
    permission: [],
    token: localStorage.getItem('token'),
    branch: localStorage.getItem('phillipworks'),
}

const userReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case USER_LOGIN:
            return {
                ...state,
                login: true,
                loading: false,
            }
        case USER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case USER_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case USER_ERROR:
            return {
                ...state,
                loading: false,
                error: payload,
                login:false,
            }
        case USER_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case USER_AUTH: 
            return {
                ...state,
                auth: payload,
            }
        case USER_TOKEN: 
            return {
                ...state,
                token: payload,
            }
        case USERCATEGORYPERMISSION_GET: 
            return {
                ...state,
                permission: payload,
            }
        case USER_LOGOUT: 
            return {
                ...state,
                token: null,
                auth: null,
            }
        case USER_BRANCH: 
            return {
                ...state,
                branch: payload,
            }
        default:
            return state;
    }
}

export default userReducer