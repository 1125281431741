import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import TableNoData from 'Comps/elements/TableNoData'
import LoadingContent from 'Elements/LoadingContent';
import Table from 'Elements/Table'
import { deleteEdc, setEditEdc, resetStatusEdc } from 'Reduxes/pos/edc/edcActions'
import { scrollToTop, globalDialog } from '@/Helper'
import CustomToolbar from "../../../comps/elements/CustomToolbar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import Permission from "../../../comps/permission/Permission";
import KopSurat from 'Elements/KopSurat'

export default function TableData(props) {

    const { showDetail, showForm } = props;
    const { data, loading, error, success } = useSelector(state => state.edcReducer);
    const classes = globalTableStyle();
    const dispatch = useDispatch();
    const handleDelete = id => {
        globalDialog(async () => {
            await dispatch(deleteEdc(id))
            scrollToTop()
        })
    }

    const table = tableOpts({
        handleDelete,
        data,
        colIdPosition: 0,
        filter: false,
        filename: "EDC_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    label: 'ID',
                    options: {
                        display: false,
                        viewColumns: false,
                        filter: false

                    }
                },
                // Jika is_warning di un comment, akan ikut ke download dan bisa di tampilkan si table jika kolom nya di ceklis
                // {
                //     name: 'is_warning', label: 'is_warning',
                //     options: { display: false, filter: false }
                // },
                {
                    name: 'no', label: 'No.',
                    options: {
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                { name: 'code', label: 'ID' },
                { name: "name", label: 'Source' },
                {
                    name: "edc_item", label: '#EDC',
                    options: {
                        customBodyRender: (value) => {
                            return value.length
                        }
                    }
                },
                {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        print: false,
                        customBodyRender: (value) => {
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => showDetail(value)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => {
                                    dispatch(setEditEdc(value))
                                    showForm('Edit')
                                    scrollToTop()
                                }
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => handleDelete([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />
                                    <Permission
                                        component={<EditIcon {...attrEdit} />}
                                        type='update'
                                        subMenu='edc'
                                    />
                                    <Permission
                                        component={<DeleteIcon {...attrDelete} />}
                                        type='delete'
                                        subMenu='edc'
                                    />
                                </>
                            );
                        }
                    }
                },
            ],
        },
    })

    return (
        <PrintProvider>
            <Print single name="print-edc-table-1" >
                <KopSurat />
                <div className="spacer-60"></div>
                <ContentWrapper
                    title="EDC"
                    subtitle="showing table of all data EDC"
                    buttons={[
                        {
                            label: 'Add EDC',
                            style: 'OrangeMd',
                            icon: 'plus-circle',
                            onClick: () => showForm('New'),
                            type: 'create',
                            subMenu: 'edc',
                        }
                    ]}
                >
                    <>
                        {
                            success &&
                            <Alert hide={() => dispatch(resetStatusEdc())} option={{ type: 'success', message: success }} />
                        }
                        {
                            error &&
                            <Alert hide={() => dispatch(resetStatusEdc())} option={{ type: 'error', message: error }} />
                        }
                        {
                            loading ? <LoadingContent />
                                : (
                                    data.length ?
                                        <Table {...table} className={classes.table} />
                                        : <TableNoData />
                                )
                        }
                    </>
                </ContentWrapper>
            </Print>
        </PrintProvider>

    )
}