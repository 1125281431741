import React, { useEffect, useState } from 'react'
import { updateStatus, showSalesreturn, resetStatusSalesreturn } from 'Reduxes/consignment/salesreturn/salesreturnActions'
import DetailHeader from 'Layouts/detailheader/DetailHeader';
import ContentWrapper from 'Comps/container/ContentWrapper';
import LoadingContent from 'Elements/LoadingContent'
import { money, datePost } from '@/Helper';
import { useDispatch, useSelector } from 'react-redux';
import Button from 'Elements/Button'
import Alert from 'Comps/elements/Alert'
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import Permission from '../../../comps/permission/Permission';
import Box from "@material-ui/core/Box";
import KopSurat from 'Elements/KopSurat'


export default function Detail(props) {
    const dispatch = useDispatch();
    const { success, error } = useSelector(state => state.salesreturnReducer);

    const [data, setData] = useState(null)
    const [statusLoading, setStatusLoading] = useState(false)
    const [priceSummary, setPriceSummary] = useState({
        subTotal: 0,
        discount: 0,
        total: 0,
    })
    const { showTable, rowId } = props;

    useEffect(() => {
        const fetchRow = async () => {
            const resp = await dispatch(showSalesreturn(rowId))
            console.log(resp.data, 'data detail')
            setData(resp.data)

            // price summary 
            let subTotal = 0;
            let discount = 0;
            let total = 0;
            resp.data.sales_return_products.forEach(d => {
                subTotal += d.total_price;
                discount += d.discount;
            })
            total = subTotal - discount;
            setPriceSummary({ subTotal, discount, total })

        }
        fetchRow();
    }, [])

    const detailHeader = data && {
        address: [data.contact.address, data.contact.city, data.contact.province, data.contact.country, data.contact.postal_code].filter(Boolean).join(', '),
        reference: data.reference,
        contact: data.contact.name,
        status: data.status,
        date: data.date,
    }
    const handleStatus = async (type) => {
        setStatusLoading(true);
        await dispatch(updateStatus(data.id, type))
        console.log(data, 'update state disini bang', type)
        setData({
            ...data,
            status: type === 'approve' ? 'confirmed' : 'draft'
        })
        setStatusLoading(false);
    }

    const handleApprove = () => {
        handleStatus('approve')
    }

    const handleCancel = () => {
        handleStatus('cancel')
    }

    const filterNull = (value) => {
        return !value ? 0 : value;
    }

    const ButtonStatus = () => {
        /* 
        draft confirm 
        bisa mundur 
        */
        const btnProps = ({ type, label, style = "TileMd", icon = "" }) => {
            const modLabel = label || type;
            return {
                icon: statusLoading ? "spinner" : icon,
                disabled: statusLoading,
                label: statusLoading ? "Saving" : modLabel[0].toUpperCase() + type.substring(1),
                style,
            }
        }

        return (
            <>
                <Button
                    disabled={statusLoading}
                    style='WhiteMd'
                    label="Go Back"
                    icon='arrow-left'
                    onClick={() => showTable()}
                />
                {
                    data.status === 'draft' &&
                    <Permission
                        component={
                            <Button
                                {...btnProps({ label: 'Approve', type: 'approve', })}
                                onClick={handleApprove}
                                marginLeft="ml-10"
                            />
                        }
                        subMenu="sales_return"
                        type="confirm"
                    />
                }
                {
                    data.status === 'confirmed' &&
                    <Permission
                        component={
                            <Button
                                {...btnProps({ label: 'Cancel', type: 'cancel', icon: 'undo' })}
                                onClick={handleCancel}
                                marginLeft="ml-10"
                            />
                        }
                        subMenu="sales_return"
                        type="confirm"
                    />
                }
                <Permission
                    component={
                        <Button
                            label="Print"
                            style="DarkblueMd"
                            marginLeft="ml-10"
                            icon="print"
                            onClick={() => window.print()}
                        />
                    }
                    subMenu="sales_return"
                    type="print"
                />
            </>
        )
    }

    // console.log(data)
    return (
        <>
            {
                !data ?
                    <LoadingContent /> :
                    <PrintProvider>
                        <Print single name="print-salesreturn-detail-1" >
                            <div className="print-report zoom-70">
                                <KopSurat title="Sales Return" nomor={`${data.code || 'N/a'}`} />
                                <ContentWrapper
                                    title="Sales Return Detail"
                                    subtitle={`Showing return product with id ${data.code || 'N/a'}`}
                                    buttons={<ButtonStatus />}
                                >
                                    <div className="detail-page">
                                        {
                                            (success || error) &&
                                            <Alert hide={() => dispatch(resetStatusSalesreturn())} option={{ type: 'success', message: success || error }} />
                                        }
                                        <DetailHeader data={detailHeader} />
                                        {
                                            data.sales_return_products?.length > 0 && (<table className="table table-detail style-2">
                                                <thead>
                                                    <tr>
                                                        <th>Code Product</th>
                                                        <th>Product</th>
                                                        <th>Price / unit</th>
                                                        <th>Discount</th>
                                                        <th>Qty</th>
                                                        <th>UoM</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.sales_return_products.map((row, rowIndex) => {
                                                            const parsedTotal = (filterNull(row.quantity) * filterNull(row.unit.value) * filterNull(row.price)) - (filterNull(row.quantity) * filterNull(row.unit.value) * filterNull(row.discount))
                                                            console.log(row)
                                                            return (
                                                                <tr key={rowIndex}>
                                                                    <td>{row.sku}</td>
                                                                    <td className="highlight">{row.variant ? ` ${row.name} ${row.product.color.name} - (${row.variant.value})` : row.name}</td>
                                                                    <td>IDR {money(row.price)}</td>
                                                                    <td>IDR {money(row.discount)}</td>
                                                                    <td>{row.quantity}</td>
                                                                    <td>{row.unit.name}</td>
                                                                    <td>IDR {money(parsedTotal)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                    <tr className="total">
                                                        <td colSpan="5" className="note" rowSpan="3">
                                                            <b>Notes : </b>
                                                            {data.note && <p>{data.note}</p>}
                                                        </td>
                                                        <td>Sub Total</td>
                                                        <td>IDR {money(data.sub_total)}</td>
                                                    </tr>
                                                    <tr className="total">
                                                        <td>Discount</td>
                                                        <td>IDR {money(data.total_discount)}</td>
                                                    </tr>
                                                    <tr className="total">
                                                        <td>Total</td>
                                                        <td>IDR {money(data.total)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>)
                                        }
                                        <Box>
                                            <p className="detail-note">Printed by: User 1, {data.printed_count}</p>
                                            <p className="detail-note">Created : {data.created_by_contact_name}, {datePost(data.created_at)}</p>
                                            <p className="detail-note"> Updated : {data.updated_by_contact_name || '-'}, {datePost(data.updated_at)}</p>
                                        </Box>
                                    </div>
                                    <SignatureColumn typeContent={"ReceivedDelivered"} data={data} />
                                </ContentWrapper>
                            </div>
                        </Print>
                    </PrintProvider>
            }
        </>
    )
}
