import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import TableData from './TableData'
import FormEdit from './FormEdit'
import { loadProfile, showProfile, deleteProfile, setEdit } from 'Reduxes/configuration/profile/profileActions'
import { scrollToTop, datePost } from '@/Helper'


function Profile() {

    const dispatch = useDispatch()
    const { data } = useSelector(state => state.profileReducer)

    const [content, setContent] = useState('table')
    const [idShow, setIdShow] = useState(null)

    const showDetail = (id) => {
        setIdShow(id);
        setContent('detail')
    }
    const showTable = () => {
        setContent('table');
    }
    const showForm = (type, id) => {
        setIdShow(id);
        setContent('form' + type);
    }

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                return (<TableData showForm={showForm} showDetail={showDetail} />)
            case 'formEdit':
                return (<FormEdit rowId={idShow} type="edit" showTable={showTable} />)
            default:
                return <>Nothing to show here</>
        }
    }

    useEffect(() => {
        dispatch(loadProfile());
    }, [])

    return (
        <>
            {renderContent(content)}
        </>
    )
}

export default Profile


