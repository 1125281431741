import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ContentWrapper from 'Comps/container/ContentWrapper'
import DetailHeader from 'Layouts/detailheader/DetailHeader'

export default function Detail(props) {
    const { showTable, rowId } = props;

    const { idxShow, data: datas } = useSelector(state => state.brandReducer);
    const data = datas.filter((a, b) => a.id === +rowId)[0]
    console.log({ data })

    const detailHeader = {
        code: data.code,
        'brand name': data.name,
        // 'default comission': data.comission + ' %',
        status: data.status ? 'Active' : 'Inactive',
        category: data.category === 'external' ? ' External Brand' : 'Internal Brand',
        'Commissions / category': data.has_comission_by_category ? 'Yes' : 'No',
        ...(data.category === 'external' ? { 'contact brand & default commission': `${data.contact_name} (${data.comission}%)` } : { 'default comission': data.comission + ' %' }),
    }

    return (
        <ContentWrapper
            title="Brand Detail"
            subtitle={`Showing brand with id ${data.code}`}
            buttons={[
                {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => showTable(),
                }
            ]}
        >

            <DetailHeader data={detailHeader} />


            {
                data.category_item.length > 0 && (
                    <div className="mt-32">
                        <h6>Custom Commissions</h6>
                        <table className="table table-detail style-2 mt-16">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Category</th>
                                    <th>Sales Commissions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.category_item.map((row, rowIdx) => (
                                        <tr>
                                            <td>{rowIdx + 1}</td>
                                            <td>{row.category_name}</td>
                                            <td>{row.comission} %</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                        <br />
                        <br />
                    </div>
                )
            }
        </ContentWrapper>
    )
}
