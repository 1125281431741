import React, { useState, memo, useEffect } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { money } from '@/Helper'
import MutationRowChild from './MutationRowChild';


const MutationRowParent = ({ data, no, rows, showDetail: defaultShowDetail, keys, subrows, longestBranch }) => {

    const [d, setD] = useState(data)
    const [showDetail, setShowDetail] = useState(defaultShowDetail)
    const handleShowDetail = () => {
        setShowDetail(!showDetail)
    }
    useEffect(() => {
        setShowDetail(!showDetail)
    }, [defaultShowDetail])

    useEffect(() => {
        setD(data)
        setShowDetail(false)
    }, [data, longestBranch])


    return (
        <>
            <tr>
                <td>{no}.</td>
                <td className="cell-width-as-content td-highlight">
                    <span className="trigger-show-detail" onClick={handleShowDetail}>
                        {d.code} {subrows.length && <KeyboardArrowDownIcon className="info-icon" />}
                    </span>
                </td>
                <td className="cell-width-as-content">{d.brand_name}</td>
                <td className="cell-width-as-content">
                    {d.name}
                </td>
                <td></td>
                {
                    longestBranch.map((lb, lbIdx) => (
                        [...keys].map((x, xIdx) => (
                            <td key={`${xIdx}-${lbIdx}`}>
                                {d[`variantParent${lbIdx}${xIdx}`]}
                            </td>
                        ))
                    ))
                }
                {
                    /* 
                        Remove Total on Hand and Total Value on Report Mutation
                        <td>{d.on_hand}</td>
                        <td className="cell-tr">IDR {money(d.on_hand_value)}</td> 
                    */
                }
            </tr>

            {showDetail && <MutationRowChild
                indexParent={no}
                longestBranch={longestBranch}
                keys={keys}
                show={showDetail}
                subrows={subrows}
                data={data}
                products={d.products}
            />}

        </>
    )
}



export default memo(MutationRowParent)