import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "WAREHOUSE"
export const WAREHOUSE_SET = 'WAREHOUSE_SET'
export const WAREHOUSE_ERROR = 'WAREHOUSE_ERROR'
export const WAREHOUSE_ADD = 'WAREHOUSE_ADD'
export const WAREHOUSE_SHOW = 'WAREHOUSE_SHOW'
export const WAREHOUSE_EDIT = 'WAREHOUSE_EDIT'
export const WAREHOUSE_UPDATE = 'WAREHOUSE_UPDATE'
export const WAREHOUSE_DELETE = 'WAREHOUSE_DELETE'
export const WAREHOUSE_LOADING = 'WAREHOUSE_LOADING'
export const WAREHOUSE_RESET = 'WAREHOUSE_RESET'


export const loadWarehouse = () => async (dispatch, getState) => {
    handleReq({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/warehouse`),
        next: (payload) => dispatch({ type: WAREHOUSE_SET, payload })
    })
}
export const addWarehouse = data => async (dispatch, getState) => {

    data.status = data.status === 'true'
    data.is_store = data.status || data.is_store === 'true'
    
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/warehouse`, data),
        next: (payload) => dispatch({ type: WAREHOUSE_ADD, payload })
    })
}
export const updateWarehouse = (idEdit, data) => async (dispatch, getState) => {

    data.status = typeof data.status === 'boolean' ? data.status : data.status === 'true'
    data.is_store = typeof data.is_store === 'boolean' ? data.is_store : data.is_store === 'true'
    data.branch_id = parseInt(data.branch_id);

    delete data.id
    delete data.created_at
    delete data.deleted_at
    delete data.updated_at

    // console.log(data)
    // return;

    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/warehouse/${idEdit}`, data),
        next: (payload) => dispatch({ type: WAREHOUSE_UPDATE, payload })
    })
}
export const deleteWarehouse = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/warehouse`, id),
        next: () => dispatch({ type: WAREHOUSE_DELETE, payload: id })
    })
}
export const showWarehouse = idx => {
    return {
        type: WAREHOUSE_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: WAREHOUSE_EDIT,
        payload: idEdit,
    }
}
export const resetStatusWarehouse = () => {
    return {
        type: WAREHOUSE_RESET
    }
}