import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function LoadingInline({message}) {
    return (
        <div className="loading-inline">
            <FontAwesomeIcon spin icon="spinner" />
            <p>{message ? message : 'Please wait for a moment'}</p>
        </div>
    )
}
