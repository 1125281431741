import React, { Fragment } from 'react'
import { useSelector } from 'react-redux';
import { money } from '@/Helper'
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import ContentWrapper from 'Comps/container/ContentWrapper'

export default function Detail(props) {
    const { showTable, rowId } = props;
    console.log(rowId)
    const { idxShow, data } = useSelector(state => state.memberReducer);
    const detail = data.filter((a, b) => a.id === +rowId)[0]

    let socmed = JSON.parse(detail.member_social)
    let arrSocmedKey = Object.keys(socmed)
    const socmedEl = arrSocmedKey.map((x, idx) => {
        if (x === 'fb') {
            return <Fragment key={idx}><p>Facebook : {socmed[x] || '-'}</p></Fragment>
        } else if (x === 'tw') {
            return <Fragment key={idx}><p>Twitter : {socmed[x] || '-'}</p></Fragment>
        } else {
            return <Fragment key={idx}><p>Instagram : {socmed[x] || '-'}</p></Fragment>
        }
    })
    const tempAddress = [detail.address, detail.city === '' ? null : detail.city, detail.province === '' ? null : detail.province, detail.country, detail.postal_code === 0 ? null : detail.postal_code].filter(Boolean).join(', ')

    const detailHeader = !data ? {} : {
        code: detail.member_code,
        name: detail.name,
        status: detail.is_active ? 'active' : 'inactive',
        'total purchase': `IDR ${money(detail.total_transaction)}`,
        phone: detail.phone,
        'membership status': detail.member_card_status === 'BELUM_DIBERIKAN' ? 'Belum diberikan' : 'Sudah diberikan',
        'membership received': detail.member_card_received_date,
        'membership expired': detail.member_card_expired_date,
        address: tempAddress,
        email: detail.email,
        socmed: socmedEl,
        note: detail.note,
    }

    return (
        <ContentWrapper
            title="Member Detail"
            subtitle={`Showing member with id ${detail.member_code || 'N/a'}`}
            buttons={[
                {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => showTable(),
                },
            ]}
        >
            <div className="page-detail">
                <DetailHeader data={detailHeader} />
            </div>
        </ContentWrapper>
    )
}
