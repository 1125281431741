import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Select from 'Elements/select/Select';
import TableGeneral from './TableGeneral';
import TableDetail from './TableDetail';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import { getLocalStorage, getLocalStorageAuth } from '@/Helper'
import LoadingContent from 'Elements/LoadingContent';
import { makeGetParam, hostUrl, reqGet } from 'Configs/request.js';
import { loadPosProductReport } from 'Reduxes/pos/posActions';
import FilterGeneral from '../report-filter/FilterGeneral';
import Button from 'Elements/Button';
import { actionButton } from "../../../configs/report";
import PrintProvider, { Print } from "react-easy-print";


const useStyles = makeStyles((theme) => ({
    formfilter: {
        display: 'grid',
        gridTemplateColumns: '300px 300px',
        columnGap: '24px',
        marginBottom: '24px'
    },
    formextrafilter: {
        '& .toggler': {
            fontSize: '12px',
            fontWeight: 700,
            color: '#333',
            '& svg': {
                marginBottom: '-7px'
            }
        }
    },
    formextrafiltercontainer: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '3px',
        marginTop: '15px',
        marginBottom: '20px'
    }
}))

export default function Report() {

    const classes = useStyles();
    const dispatch = useDispatch();

    const currentBranch = getLocalStorage().branchLogin.id
    const isSuperadmin = getLocalStorageAuth().user_category_id === 1 || getLocalStorageAuth().user_category_id === 2
    /* STATE */
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [extraFilter, setExtraFilter] = useState({});
    const [reportUrl, setReportUrl] = useState('');
    const [filter, setFilter] = useState({
        group_by: null,
        details: null,
    })
    const handleSelect = select => {
        setData(null);

        setFilter(state => {
            state[select.props.name] = select.selected.value
            return { ...state };
        })

    }
    const reqReport = async () => {
        const reportUrl = `${hostUrl}/api/pos/report-per-product` + makeGetParam({ ...filter, ...extraFilter, ...(!isSuperadmin ? { branch_id: currentBranch } : {}) })
        setReportUrl(reportUrl)
        const resp = await dispatch(loadPosProductReport({ ...filter, ...extraFilter, ...(!isSuperadmin ? { branch_id: currentBranch } : {}) }))
        setData(resp.data);
        setLoading(false);
    }
    const applyFilter = async () => {
        setData(null);

        if (filter.details) {
            setLoading(true);
            reqReport()
        }
    }
    const handleExtraFilter = objFilter => {
        setExtraFilter(objFilter)
    }

    useEffect(() => {
        if (loading) {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }
    }, [loading])

    return (
        <div>
            <ContentWrapper
                title="Sales Report by Product"
                subtitle="Use available filter below to show custom criteria "
                buttons={actionButton({ reportUrl })}
            >
                <div className="report report-pos" style={{ paddingBottom: '40px' }}>
                    <div className={classes.formfilter}>
                        <Select
                            name="details"
                            label="Type"
                            getData={handleSelect}
                            options={[
                                { label: 'Summary sales by product', value: 'false' },
                                { label: 'Sales detail by product', value: 'true' }
                            ]}
                        />
                    </div>

                    {/* THE AWESOME FILTER  */}
                    <div className="mb-30">
                        <FilterGeneral
                            type="product"
                            setFilter={setFilter}
                            getData={handleExtraFilter}
                        />
                    </div>

                    <div className="mb-20">
                        <Button onClick={applyFilter} style="OrangeWideMdRect" icon="check-circle" label="Apply Filter" />
                    </div>


                    <PrintProvider>
                        <Print single name="print-product-report-table-1" >
                            {/* <TableGeneral data={fakeDataGeneral.data} /> */}
                            {data && filter.details === 'false' && <TableGeneral data={data} />}
                            {data && filter.details === 'true' && <TableDetail data={data} />}
                            {loading ? <LoadingContent /> : null}
                        </Print>
                    </PrintProvider>

                </div>
            </ContentWrapper>
        </div>
    )
}
