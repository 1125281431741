import React, { useEffect, useState } from 'react'
import { addBrand, resetStatusBrand, updateBrand, showBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { datePost, scrollToTop } from '@/Helper'
import Input from 'Elements/Input';
import { default as Input2 } from 'Elements/input/Input';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import ProductManager from 'Elements/ProductManager'
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import Button from 'Elements/Button';
import SelectData from 'Elements/SelectData';
import FormComission from './FormComission'
import { serverValue } from 'Configs/Form';
import FieldsContainer from 'Elements/FieldsContainer'
import { validationSchema } from './validation'
import InputNum from 'Elements/inputnum/InputNum'
import { default as Select } from 'Elements/select/Select'


function FormElement(props) {

    const [dataErr, setDataErr] = useState(false)
    const { type, showTable, rowId } = props;


    const dispatch = useDispatch();
    const { error } = useSelector(state => state.brandReducer)


    const [defaultData, setDefaultData] = useState(null) // untuk update
    const [isReady, setIsReady] = useState(true)
    const [formCustomComission, setFormCustomComission] = useState(false)
    const [category, setCategory] = useState(null) // internal - external
    const [selectedBrand, setSelectedBrand] = useState(null); // untuk nyimpan data ketika external dan brand dipilih (rawOpt)
    const [customComission, setCustomComission] = useState([]); // untuk nyimpan data custom comission yg di extra table

    const handleCategoryChange = (select) => {
        select.formik.setFieldValue('comission', '');
        setCategory(select.selected.value);
    }
    const handleChangeComissionPerCat = (select) => {
        setFormCustomComission(select.selected.value)
    }
    const handleExtComission = select => {
        setSelectedBrand(select.rawOpt)
    }
    const [initialValues, setInitialValues] = useState({
        branch_id: '',
        date: datePost(new Date()),
        category: '',
        reference: '',
        code: '',
        name: '',
        comission: '',
        barcode: '',
        notes: '',
        contact_id: '',
        products: [],
        product_id: '',
        variant_type_id: '',
        unit_of_measurement_id: '',
        has_comission_by_category: null,
        price: '',
        qty: 0,
        reason: '',
        status: true,
    })
    const getCustomComission = (data) => {
        setCustomComission(data)
    }

    const formOpts = {
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const { category, comission, has_comission_by_category, name, status, contact_id, code, branch_id, date, reference } = values;
            const category_item = customComission.map(x => ({
                "category_id": x.category_id,
                "comission": x.comission
            }));


            console.log(1, values);

            const cleanVal = {
                name,
                category,
                code,
                status,
                comission: category === 'external' ? selectedBrand?.brand_comission : comission,
                has_comission_by_category,
                contact_id: category === 'internal' ? '' : (selectedBrand?.id || contact_id),
                category_item
            }

            console.log(2, selectedBrand);
            console.log(3, cleanVal);


            // return;

            let result = type === 'new' ?
                await dispatch(addBrand(cleanVal))
                : await dispatch(updateBrand(rowId, cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }


    useEffect(() => {
        const fetchDataEdit = async () => {

            const resp = await dispatch(showBrand(rowId))
            const {
                name,
                category,
                code,
                status,
                comission,
                has_comission_by_category,
                contact_id,
                category_item: catItem,
            } = resp.data;

            setCategory(category)
            setFormCustomComission(catItem.length > 0)

            const category_item = catItem.map(x => ({
                "category_id": x.category_id,
                "comission": x.comission
            }));

            setInitialValues({
                name,
                category,
                code,
                status,
                comission: (category === 'internal' ? comission : contact_id),
                has_comission_by_category,
                contact_id: contact_id || '',
                category_item
            })

            setCustomComission(catItem.map(x => ({
                category_name: x.category_name,
                category_id: x.category_id,
                comission: x.comission
            })))

        }

        if (type === 'edit') {
            fetchDataEdit();
            console.log(initialValues)
        }
    }, [])



    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Brand" : "Update data Brand"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data brand`}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusBrand())} option={{ type: 'error', message: error }} />
                }

                {
                    isReady ? (
                        <>

                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {
                                    const { initialValues, values, isSubmitting } = formik;
                                    return (

                                        <Form className="form-layout-1">
                                            <FieldsContainer subtitle="Please fill fields below" title="Brand Information">
                                                <Input name="code" label="Code" />
                                                <Input name="name" label="Brand name" />

                                                <Select
                                                    name="status"
                                                    hasNone
                                                    label="status"
                                                    options={[
                                                        { label: 'Active', value: true },
                                                        { label: 'Inactive', value: false },
                                                    ]}
                                                />

                                                <Select
                                                    name="category"
                                                    getData={handleCategoryChange}
                                                    label="Category"
                                                    options={[
                                                        { value: 'internal', label: 'Internal' },
                                                        { value: 'external', label: 'External' },
                                                    ]}
                                                />
                                                {
                                                    category === 'internal' &&
                                                    <InputNum name="comission" max={100} label="Default commission" />
                                                }

                                                {
                                                    category === 'external' &&
                                                    <Select
                                                        name="contact_id"
                                                        label="contact brand &amp; default comission"
                                                        isClearable
                                                        options="contact"
                                                        getData={handleExtComission}
                                                        param={{ is_brand: true }}
                                                    />
                                                }
                                                <Select
                                                    name="has_comission_by_category"
                                                    getData={handleChangeComissionPerCat}
                                                    label="Commission / Category"
                                                    options={[
                                                        { value: false, label: 'No' },
                                                        { value: true, label: 'Yes' },
                                                    ]}
                                                />

                                            </FieldsContainer>

                                            {
                                                formCustomComission &&
                                                <FormComission
                                                    defaultData={customComission}
                                                    getData={getCustomComission}
                                                />
                                            }

                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper >
    )
}

export default FormElement