import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    warehouse_code: 
        Yup.string()
            .required('Warehouse code is required'),
    location_name:
        Yup.string()
            .required('Location is required'),
    address:
        Yup.string()
            .required('Address is required'),
    phone:
        Yup.string()
            .required('Phone is required')
            .matches(/^\d+$/, 'Phone should have digits only'),
    branch_id:
        Yup.string()
            .required('Branch is required')
            .matches(/^\d+$/, 'Branch id should have digits only'),
})