import React, { useState, useEffect } from 'react'
import { money, sortData } from '@/Helper'
import NoReport from 'Elements/NoReport'
import ThSort from 'Elements/ThSort'
import SimpleBar from 'simplebar-react';
import { NoPrint } from "react-easy-print";
import { Helmet } from "react-helmet"

export default function TableGeneral(props) {
    const { data } = props;
    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        }, date: {
            label: 'date',
            has_sort: true,
            sort_type: null,
        }, branch: {
            label: 'branch',
            has_sort: false
        }, buyer_general_count: {
            label: 'general',
            has_sort: true,
            sort_type: null,
        }, buyer_vip_count: {
            label: 'vip',
            has_sort: true,
            sort_type: null,
        }, buyer_member_count: {
            label: 'member',
            has_sort: true,
            sort_type: null,
        }, sales_return_count: {
            label: 'retur',
            has_sort: true,
            className: 'cell-width-as-content',
            sort_type: null,
        }, sales_cancel_count: {
            label: 'cancel',
            has_sort: true,
            className: 'cell-width-as-content',
            sort_type: null,
        }, sales_refund_count: {
            label: 'refund',
            has_sort: true,
            className: 'cell-width-as-content',
            sort_type: null,
        }, sub_total: {
            label: 'sub total',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null,
        }, discount_value: {
            label: 'disc',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        }, grand_total: {
            label: 'total',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null
        }, hpp: {
            label: 'hpp',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        }, additional_cost: {
            label: 'ad. cost',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        }, net_income: {
            label: 'net val.',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        },
    })
    const [scroll, setScroll] = useState(true)
    const [toggleSubRows, setToggleSubRows] = useState(true)

    const defaultSubRowsVisibility = data.pos.map(_ => false)
    const [subRowsVisibility, setSubRowsVisibility] = useState(defaultSubRowsVisibility)


    const handleClickHiglightRow = idx => {
        setSubRowsVisibility(prev => {
            prev[idx] = !prev[idx]
            return [...prev]
        })
    }
    const makeTbodyRow = () => {

        let incrementor = 0;

        return rows.map((parentRow, parentRowIdx) => {
            console.log(parentRow, 'parent roww')
            return parentRow.children.map((childRow, childRowIdx) => {
                console.log(childRow, 'child roww')
                incrementor++
                return (
                    <React.Fragment key={`td-${incrementor}`}>
                        {
                            childRowIdx < 1 && (
                                <tr
                                    key={`parent-${parentRowIdx}`}
                                    className={`highlight ${subRowsVisibility[parentRowIdx] ? 'tr-darker' : ''} cur-pointer`}
                                    onClick={() => handleClickHiglightRow(parentRowIdx)}
                                >
                                    <td className="cell-no-highlight">{parentRowIdx + 1}</td>
                                    <td className="td-width-as-content cell-no-highlight">{parentRow.date}</td>
                                    <td>{parentRow.branch_initial.reduce((a, b) => [a, ',', b])}</td>
                                    <td>{parentRow.buyer_general_count}</td>
                                    <td>{parentRow.buyer_vip_count}</td>
                                    <td>{parentRow.buyer_member_count}</td>
                                    <td classname="td-width-as-content">{parentRow.sales_return_count}</td>
                                    <td classname="td-width-as-content">{parentRow.sales_cancel_count}</td>
                                    <td classname="td-width-as-content">{parentRow.sales_refund_count}</td>
                                    <td className="td-text-right td-width-as-content">{money(parentRow.sub_total)}</td>
                                    <td className="td-text-right">{money(parentRow.discount_value)}</td>
                                    <td className="td-text-right">{money(parentRow.grand_total)}</td>
                                    <td className="td-text-right">{money(parentRow.hpp)}</td>
                                    <td className="td-text-right">{money(parentRow.additional_cost)}</td>
                                    <td className="td-text-right">{money(parentRow.net_income)}</td>
                                </tr>
                            )
                        }
                        <tr className={subRowsVisibility[parentRowIdx] ? '' : 'hidden'}>
                            <td></td>
                            <td></td>
                            <td className="">{childRow.branch_name}</td>
                            <td>{childRow.buyer_general_count}</td>
                            <td>{childRow.buyer_vip_count}</td>
                            <td>{childRow.buyer_member_count}</td>
                            <td classname="td-width-as-content">{childRow.sales_return_count}</td>
                            <td classname="td-width-as-content">{childRow.sales_cancel_count}</td>
                            <td classname="td-width-as-content">{childRow.sales_refund_count}</td>
                            <td className="td-text-right">{money(childRow.sub_total)}</td>
                            <td className="td-text-right">{money(childRow.discount_value)}</td>
                            <td className="td-text-right">{money(childRow.grand_total)}</td>
                            <td className="td-text-right">{money(childRow.hpp)}</td>
                            <td className="td-text-right">{money(childRow.additional_cost)}</td>
                            <td className="td-text-right">{money(childRow.net_income)}</td>
                        </tr>
                    </React.Fragment>
                )
            })
        })
    }

    const sortColumn = (colName) => {

        setSubRowsVisibility(defaultSubRowsVisibility)

        setRows(prevState => {
            return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
        })

        setSort(prev => {

            Object.keys(prev).forEach(sortKey => {
                if (sortKey !== colName) {
                    prev[sortKey].sort_type = null
                } else {
                    prev[sortKey].sort_type = prev[sortKey].sort_type === 'asc' ? 'desc' : 'asc'
                }
            })

            return { ...prev }
        })
    }

    const toggleScroll = () => {
        setScroll(!scroll)
    }

    const handleToggleSubRows = () => {
        setSubRowsVisibility(prev => {
            const toggled = prev.map(x => toggleSubRows)
            return [...toggled]
        })

        setToggleSubRows(!toggleSubRows)
    }

    useEffect(() => {

        // init data
        const { pos } = props?.data

        const formattedData = pos.map((p, w) => {

            let branch_initial = []
            let children = []

            p.branches.forEach((branch, branchIdx) => {
                const branchName = branch?.branch_name
                const arrSplit = branchName.split(' ')

                branch_initial.push(
                    <span key={branchIdx} className="initial-branch" title={branchName}>
                        {branchName[0]}
                        {arrSplit[1] ? arrSplit[1][0] : branchName[1]}
                    </span>
                )

                children.push({
                    branch_name: branch.branch_name,
                    buyer_general_count: branch.buyer_general_count,
                    buyer_vip_count: branch.buyer_vip_count,
                    buyer_member_count: branch.buyer_member_count,
                    sales_return_count: branch.sales_return_count,
                    sales_cancel_count: branch.sales_cancel_count,
                    sales_return_count: branch.sales_refund_count,
                    sub_total: branch.sub_total,
                    discount_value: branch.discount_value,
                    grand_total: branch.grand_total,
                    hpp: branch.hpp,
                    additional_cost: branch.additional_cost,
                    net_income: branch.net_income
                })
            })

            return {
                date: p.date,
                branch_initial,
                buyer_general_count: p.buyer_general_count,
                buyer_vip_count: p.buyer_vip_count,
                buyer_member_count: p.buyer_member_count,
                sales_return_count: p.sales_return_count,
                sales_cancel_count: p.sales_cancel_count,
                sales_refund_count: p.sales_refund_count,
                sub_total: p.sub_total,
                discount_value: p.discount_value,
                grand_total: p.grand_total,
                hpp: p.hpp,
                additional_cost: p?.additional_cost,
                net_income: p?.net_income,
                children
            }
        })

        setRows(formattedData)
    }, [props])

    const getAvgPrice = () => {
        if (!data) return 0
        // pengambil data dari response data.sales_count, yang mana total trx tsb sudah dikurangi dengan return.
        if (data?.grand_total && data?.sales_count) return Math.floor(data.grand_total / data.sales_count)
        return 0
    }

    return (
        <>
            {
                data.pos.length ? (
                    <>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <div className="print-report zoom-60">
                            <div className="report-summary-box">
                                <div className="item teal">
                                    <p>
                                        <span>Net Value</span>
                                        <span>IDR {money(data.net_income)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Sub Total</span>
                                        <span>IDR {money(data.sub_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Total</span>
                                        <span>IDR {money(data.grand_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Return Value</span>
                                        <span>IDR {money(data.sales_return_value)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Discount</span>
                                        <span>IDR {money(data.discount_value)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>HPP</span>
                                        <span>IDR {money(data.hpp)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Other Cost</span>
                                        <span>IDR {money(data.additional_cost)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Avg. Transaction</span>
                                        <span>IDR {getAvgPrice() ? money(getAvgPrice()) : 0}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="report-summary-pill mt-16">
                                <p>
                                    <span>Sales :</span>
                                    <span>{data.sales_count}</span>
                                </p>
                                <p>
                                    <span>General :</span>
                                    <span>{data.buyer_general_count}</span>
                                </p>
                                <p>
                                    <span>VIP :</span>
                                    <span>{data.buyer_vip_count}</span>
                                </p>
                                <p>
                                    <span>Member :</span>
                                    <span>{data.buyer_member_count}</span>
                                </p>
                                <p>
                                    <span>Promotion :</span>
                                    <span>{data.sales_promotion_count}</span>
                                </p>
                                <p>
                                    <span>Retur :</span>
                                    <span>{data.sales_return_count}</span>
                                </p>
                                <p>
                                    <span>Cancel :</span>
                                    <span>{data.sales_cancel_count}</span>
                                </p>
                                <p>
                                    <span>Refund :</span>
                                    <span>{data.sales_refund_count}</span>
                                </p>
                            </div>

                            <NoPrint force={true}>
                                <div className="table-menu">
                                    <div className="left">
                                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                        <button onClick={handleToggleSubRows}>toggle subrows</button>
                                    </div>
                                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                </div>
                            </NoPrint>

                            <SimpleBar className={`table-sticky mb-24 ${scroll ? '' : 'mh-auto'}`}>

                                <table className="table-report pos-sale-general">
                                    <thead>
                                        <tr>
                                            {
                                                Object.keys(sort).map((key, idx) => (
                                                    <ThSort
                                                        key={`th-${idx}`}
                                                        label={sort[key].label}
                                                        sort={sort[key].has_sort}
                                                        handleClick={() => sortColumn(key)}
                                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                    />
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {makeTbodyRow()}
                                    </tbody>
                                </table>
                            </SimpleBar>
                        </div>
                    </>
                ) : <NoReport />
            }
        </>
    )
}
