import React from 'react'
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: '200px',
        zIndex: 99
    },
    menuItem: {
        fontSize: '13px',
        color: '#333',
    }
}));

export default function SubModuleDropdown(props) {
    const { open, items, anchorRef, handleListKeyDown, handleClose, position } = props;
    const classes = useStyles();

    return (
        <Popper className={classes.root} open={open} anchorEl={anchorRef} role={undefined} transition disablePortal>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? (position || 'center') + ' top' : (position || 'center') + ' bottom' }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                {
                                    items.map((item) => (
                                        <MenuItem className={classes.menuItem} key={item.label} onClick={(e) => handleClose(e, item)}>{item.label}</MenuItem>
                                    ))
                                }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    )
}
