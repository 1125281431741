export const menus = [
    {
        label: 'Sales',
        subMenus: [
            {
                label: 'Payment',
                name: 'Payment',
            },
            {
                label: 'Sales order',
                name: 'Sales Order',
            },
            {
                label: 'Sales return',
                name: 'Sales return',
            },
            {
                label: 'Report',
                name: 'Report Sales',
            },
            {
                label: 'Invoice',
                name: 'Invoice',
            },
        ],
    },
    {
        label: 'Inventory',
        subMenus: [
            {
                label: 'Product',
                name: 'Product',
            },
            {
                label: 'Receives',
                name: 'Receipts',
            },
            {
                label: 'Delivery',
                name: 'Delivery',
            },
            {
                label: 'Reject',
                name: 'Reject',
            },
            {
                label: 'Stock Opname',
                name: 'Stock Opname',
            },
            {
                label: 'Configuration',
                name: 'Configuration',
            },
            {
                label: 'Report',
                name: 'Report Inventory',
            },
            {
                label: 'Color',
                name: 'Color',
            },
            {
                label: 'Variant',
                name: 'Variant',
            },
            {
                label: 'Brand',
                name: 'Brand',
            },
            {
                label: 'Category',
                name: 'Category',
            },
            // {
            //     label: 'Unit',
            //     name: 'Unit',
            // },
            {
                label: 'Warehouse',
                name: 'Warehouse',
            },
        ],
    },
    {
        label: 'Contact',
        subMenus: [
            {
                label: 'Member',
                name: 'Member',
            },
            {
                label: 'Contact',
                name: 'Contacts',
            },
            {
                label: 'User Category',
                name: 'User Category',
            },
        ],
    },
    {
        label: 'Pos',
        subMenus: [
            {
                label: 'Sales',
                name: 'Sales Pos',
            },
            {
                label: 'Report',
                name: 'Report Pos',
            },
            {
                label: 'Promotion',
                name: 'Promotion',
            },
            {
                label: 'EDC',
                name: 'EDC',
            },
        ]
    },
    {
        label: 'Setting',
        subMenus: [
            {
                label: 'Branch',
                name: 'Branch',
            },
            {
                label: 'Log',
                name: 'Log',
            },
            {
                label: 'Profile',
                name: 'Profile',
            },
        ]
    }
];

export const type = [
    'read', 'create', 'update', 'confirm', 'delete', 'print', 'export'
];

export const filterTabs = (tabs, permission) => {
    const filteredTabs = tabs.filter((tab) =>
        permission.filter((item) => item.menu === tab.permissionName && item.type === tab.permissionType).length > 0 ||
        !tab.permissionName && !tab.permissionType
    );

    return filteredTabs;
};