import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "REJECT"

export const REJECT_SET = 'REJECT_SET'
export const REJECT_ERROR = 'REJECT_ERROR'
export const REJECT_ADD = 'REJECT_ADD'
export const REJECT_SHOW = 'REJECT_SHOW'
export const REJECT_EDIT = 'REJECT_EDIT'
export const REJECT_UPDATE = 'REJECT_UPDATE'
export const REJECT_DELETE = 'REJECT_DELETE'
export const REJECT_LOADING = 'REJECT_LOADING'
export const REJECT_RESET = 'REJECT_RESET'
export const REJECT_CONFIRM = 'REJECT_CONFIRM'
export const REJECT_STOP_LOADING = 'REJECT_STOP_LOADING'


export const loadReject = () => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/reject`),
        next: (payload) => dispatch({ type: REJECT_SET, payload })
    })
}
export const addReject = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/reject`, data),
        next: (payload) => dispatch({ type: REJECT_ADD, payload })
    })
}
export const updateReject = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/reject-update/${idEdit}`, data),
        next: (payload) => dispatch({ type: REJECT_UPDATE, payload })
    })
}
export const deleteReject = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/reject`, id),
        next: () => dispatch({ type: REJECT_DELETE, payload: id })
    })
}
export const showReject = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/reject/${idx}`),
    })
}
export const setEditReject = idEdit => {
    return {
        type: REJECT_EDIT,
        payload: idEdit,
    }
}
export const resetStatusReject = () => {
    return {
        type: REJECT_RESET
    }
}
export const updateStatusReject = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/reject/${id}/${type}`),
        next: () => dispatch({ type: REJECT_CONFIRM, payload: { type, id } })
    })
}
export const rejectTemp = (id, type) => {
    return {
        type: REJECT_CONFIRM,
        payload: { id, type },
    }
}