import React, { useState, useEffect } from 'react'
import Topbar from 'Comps/layouts/Topbar'
import PageTitle from 'Comps/layouts/PageTitle'
import Sidebar from 'Comps/layouts/Sidebar'
import PageBreadcrumb from 'Comps/layouts/PageBreadcrumb'
import PropTypes from "prop-types";

const Dashboard = ({ children, pageTitle, breadcrumb }) => {    
    return (
        <div className="adminarea">
            <Topbar />
            <Sidebar />
            <div className="page">
                <div className="page-header">
                    <PageTitle {...pageTitle} />
                    <PageBreadcrumb breadcrumb={breadcrumb} />
                </div>
                <div className="page-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

Dashboard.propTypes = {
    pageTitle: PropTypes.object,
    breadcrumb: PropTypes.array,
    children: PropTypes.element.isRequired,
};
 
export default Dashboard

