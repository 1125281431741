import React, { useState } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { updateStatusPayment } from 'Reduxes/consignment/payment/paymentActions'
import { useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { money, datePost } from '@/Helper'
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import KopSurat from 'Elements/KopSurat'


function Detail(props) {

    const { data, showTable } = props;
    console.log("Detail -> data", data)
    const dispatch = useDispatch();
    const detailHeader = {
        date: data.date,
        contact: data.contact_name,
        address: data.contact_address,
        status: data.status,
        "Payment Method": data.payment_method,
        "Sales Order": data.payment_items.map(data => data.invoice_code)
    }
    const makeStatusButton = (type, permissionType) => {
        return {
            label: type[0].toUpperCase() + type.substring(1),
            style: 'TileMd',
            marginLeft: "ml-10",
            onClick: async () => {
                console.log(data.id, type)
                if (type === 'done') {
                    let cleanVal = {
                        "type": data.type,
                        "contact_id": data.contact_id,
                        "branch_id": data.branch_id,
                        "date": data.date,
                        "note": data.note,
                        "reference": data.reference,
                        "status": "done",
                        "product_receivement_item": data.product_receivement_item.map(x => ({
                            "product_id": x['product_id'],
                            "price": x['price'],
                            "qty": x['qty'],
                            "qty_received": x['qty_received'],
                            "unit_of_measurement_id": x['unit_of_measurement_id'],
                        }))
                    }
                } else if (type === 'draft') {
                    const req = await dispatch(updateStatusPayment(data.id, type, {}))
                } else if (type === 'open') {
                    const req = await dispatch(updateStatusPayment(data.id, type, { forced: false }))
                } else if (type === 'paid') {
                    const req = await dispatch(updateStatusPayment(data.id, type, {}))
                }
            },
            subMenu: 'payment',
            type: permissionType,
        }
    }

    return (
        <PrintProvider>
            <Print single name="print-delivery-detail-1" >
                <div className="detail-page print-report zoom-70">
                    <KopSurat title="Payment Detail" nomor={`${data.code || 'N/a'}`} />

                    <ContentWrapper
                        title="Payment Detail"
                        subtitle={`Showing payment with id ${data.code || 'N/a'}`}
                        buttons={[
                            {
                                label: 'Go back',
                                style: 'WhiteMd',
                                icon: 'arrow-left',
                                onClick: () => showTable(),
                            },
                            (data.status === 'draft' ? makeStatusButton('open', 'create') : {}),
                            (data.status === 'open' ? makeStatusButton('draft', 'create') : {}),
                            (data.status === 'open' ? makeStatusButton('paid', 'confirm') : {}),
                            (data.status === 'paid' ? makeStatusButton('open', 'confirm') : {}),
                            {
                                label: "Print",
                                style: "DarkblueMd",
                                marginLeft: "ml-10",
                                icon: "print",
                                onClick: () => window.print(),
                                subMenu: 'payment',
                                type: 'print',
                            },
                        ]}
                    >
                        <div className="detail-page">
                            <DetailHeader data={detailHeader} />

                            {
                                data.payment_items?.length > 0 && (<table className="table table-detail style-2">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>INV No.</th>
                                            <th>Due Date</th>
                                            <th>Selling Periode</th>
                                            {/* <th>Subtotal</th>
                                        <th>Discount</th> */}
                                            <th>Total Invoice</th>
                                            <th>Total Payment</th>
                                            <th>Difference</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.payment_items.map((item, itemIndex) => (
                                                <tr key={itemIndex}>
                                                    <td>{itemIndex + 1}</td>
                                                    <td>{item.invoice_code}</td>
                                                    <td>{item.due_date}</td>
                                                    <td>{item.sales_period}</td>
                                                    {/* <td>IDR {money(item.sub_total)}</td>
                                                <td>IDR {money(item.discount)}</td> */}
                                                    <td>IDR {money(item.total)}</td>
                                                    <td>IDR {money(item.payment_amount)}</td>
                                                    <td>IDR {money(item.total - item.payment_amount)}</td>
                                                </tr>
                                            ))
                                        }
                                        <tr className="total">
                                            <td colSpan="5" className="note" rowSpan="4">
                                                <b>Notes : </b>
                                                <p>{data.note}</p>
                                            </td>
                                            {/* <td>Sub Total</td>
                                        <td colSpan="2">IDR {money(data.sub_total)}</td> */}
                                        </tr>
                                        <tr className="total">
                                            <td>Total Invoice</td>
                                            <td colSpan="2">IDR {money(data.sub_total)}</td>
                                        </tr>
                                        <tr className="total">
                                            <td>Total Payment</td>
                                            <td colSpan="2">IDR {money(data.total)}</td>
                                        </tr>
                                        <tr className="total">
                                            <td>Difference</td>
                                            <td colSpan="2">IDR {money(data.sub_total - data.total)}</td>
                                        </tr>
                                    </tbody>
                                </table>)
                            }
                            <Box>
                                <p className="detail-note">Printed by: User 1, {data.print_count}x</p>
                                <p className="detail-note">Created by: {data.created_by_contact_name}, {datePost(data.created_at)}</p>
                                <p className="detail-note">Last edited by: {data.updated_by_contact_name || '-'}, {datePost(data.updated_at)}</p>

                                <SignatureColumn typeContent={"ReceivedDelivered"} data={data} />

                            </Box>
                        </div>
                    </ContentWrapper>
                </div>
            </Print>
        </PrintProvider>
    )
}

export default Detail
