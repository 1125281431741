import React, { useState, useEffect } from 'react'
import { money, sortData, handleSortState } from '@/Helper'
import NoReport from 'Elements/NoReport'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';
import { Helmet } from "react-helmet"

export default function TableGeneral({ data }) {

    const [rows, setRows] = useState({})
    const [scroll, setScroll] = useState(true)

    const defaultSort = {
        no: {
            label: 'No',
            colspan: 'asd',
            has_sort: false,
        },
        date: {
            label: 'date',
            has_sort: true,
            sort_type: null
        },
        branch_code: {
            label: 'code',
            has_sort: false
        },
        branch_name: {
            label: 'name',
            has_sort: false,
        },
        sub_total: {
            label: 'Sub total',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null
        },
        discount_value: {
            label: 'disc',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        },
        grand_total: {
            label: 'total',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        },
    }

    const [sort, setSort] = useState(defaultSort)

    const sortColumn = (colName, tableIndex) => {

        if (!defaultSort[colName]) {
            setRows(prevState => {
                prevState.pos = prevState.pos.sort((a, b) => {

                    let pembandingA, pembandingB

                    a.payments.forEach(x => {
                        Object.keys(x).forEach(y => {
                            if (y === colName) {
                                pembandingA = x[y]
                            }
                        })
                    })

                    b.payments.forEach(x => {
                        Object.keys(x).forEach(y => {
                            if (y === colName) {
                                pembandingB = x[y]
                            }
                        })
                    })

                    return (pembandingA > pembandingB) && (sort[colName].sort_type === 'desc' || !sort[colName].sort_type) ? 1 : -1

                })
                return { ...prevState }
            })
        } else {
            setRows(prevState => {
                prevState.pos = sortData({ type: sort[colName].sort_type, column: colName, data: prevState.pos })
                return { ...prevState }
            })
        }

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }


    const toggleScroll = () => {
        setScroll(!scroll)
    }
    useEffect(() => {
        setRows(data)
        setSort(prev => {
            data.payments.forEach(payment => {
                let p = Object.keys(payment)
                prev[p[0]] = {
                    label: p[0],
                    has_sort: true,
                    sort_type: null,
                    className: 'th-text-right cell-width-as-content'
                }
            })
            return { ...prev }
        })
    }, [data])

    return (
        <>
            {
                rows?.pos?.length < 1 ? <NoReport /> : (
                    <>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <p style={{ marginTop: '40px' }} className="asdasd">POS Sales Report <span>Showing POS report from all branches</span></p>

                        <div className="report-summary-box mb-16">
                            <div className="item teal">
                                <p>
                                    <span>Sub Total</span>
                                    <span>IDR {money(data.sub_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Discount</span>
                                    <span>IDR {money(data.discount_value)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Total</span>
                                    <span>IDR {money(data.grand_total)}</span>
                                </p>
                            </div>
                            {
                                data.payments.map(payment => {
                                    let p = Object.keys(payment)
                                    return (
                                        <div className="item">
                                            <p>
                                                <span>Total {p[0]}</span>
                                                <span>IDR {money(payment[p[0]])}</span>
                                            </p>
                                        </div>
                                    )
                                })
                            }
                        </div>

                        <NoPrint force={true}>
                            <div className="table-menu">
                                <div className="left">
                                    <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                </div>
                                <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                            </div>
                        </NoPrint>

                        <SimpleBarHOC status={scroll}>
                            <table className="table-report">
                                <thead>
                                    <tr>
                                        {
                                            Object.keys(sort).map((key, idx) => (
                                                <ThSort
                                                    key={`th-${idx}`}
                                                    label={sort[key].label}
                                                    colspan={sort[key].colspan}
                                                    sort={sort[key].has_sort}
                                                    handleClick={() => sortColumn(key)}
                                                    className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                />
                                            ))
                                        }

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rows?.pos?.map((pos, posIdx) => (
                                            <tr>
                                                <td>{posIdx + 1}.</td>
                                                <td>{pos.date}</td>
                                                <td>{pos.branch_code}</td>
                                                <td>{pos.branch_name}</td>
                                                <td className="td-text-right">{money(pos.sub_total)}</td>
                                                <td className="td-text-right">{money(pos.discount_value)}</td>
                                                <td className="td-text-right">{money(pos.grand_total)}</td>
                                                {
                                                    pos.payments.map(payment => {
                                                        const p = Object.keys(payment);
                                                        return <td className="td-text-right">{money(payment[p])}</td>
                                                    })
                                                }
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </SimpleBarHOC>
                    </>
                )
            }
        </>
    )
}
