import React, { useState, useEffect } from 'react'
import { money, dateDMY, sortData, handleSortState } from '@/Helper'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { Helmet } from "react-helmet"
import { NoPrint } from "react-easy-print";
import ThSort from 'Elements/ThSort'

export default function TableDetail(props) {

    // const { data: datas } = props;
    const [rows, setRows] = useState([])
    const [scroll, setScroll] = useState(true)


    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        },
        date: {
            label: 'date',
            has_sort: true,
            sort_type: null,
        },
        code: {
            label: 'ID',
            sort_type: null,
            has_sort: false
        },
        user_type: {
            label: 'type',
            has_sort: false,
            sort_type: null,
        },
        category: {
            label: 'category',
            has_sort: false,
            sort_type: null,
        },
        sub_total: {
            label: 'sub total',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
        },
        discount_value: {
            label: 'disc',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null,
        },
        grand_total: {
            label: 'total',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null,
        },
        hpp: {
            label: 'hpp',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
        additional_cost: {
            label: 'ad. cost',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null
        },
        net_income: {
            label: 'net value',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        }
    })


    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            prevState[tableIndex].pos = sortData({ type: sort[colName].sort_type, column: colName, data: prevState[tableIndex].pos })
            return [...prevState]
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }
    const toggleScroll = () => {
        setScroll(!scroll)
    }

    useEffect(() => {
        setRows(props.data)
    }, [props])

    return (
        <>
            <div style={{ paddingTop: '75px' }} />
            {
                setRows.length ? (
                    <>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <div className="print-report zoom-60">
                            {
                                rows.map((data, dataIdx) => (
                                    <div key={dataIdx} className="report-pos-detail-table-container">
                                        <h5><DeviceHubIcon /> Branch Name  : {data.branch_name}</h5>
                                        <div className="report-summary-box">
                                            <div className="item teal">
                                                <p>
                                                    <span>Net Value</span>
                                                    <span>IDR {money(data.net_income)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Retur Value</span>
                                                    <span>IDR {money(data.sales_return_value)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Sub Total</span>
                                                    <span>IDR {money(data.sub_total)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Discount</span>
                                                    <span>IDR {money(data.discount_value)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Total</span>
                                                    <span>IDR {money(data.grand_total)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>HPP</span>
                                                    <span>IDR {money(data.hpp)}</span>
                                                </p>
                                            </div>
                                            <div className="item">
                                                <p>
                                                    <span>Other Cost</span>
                                                    <span>IDR {money(data.additional_cost)}</span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="report-summary-pill mt-16">
                                            <p>
                                                <span>Sales :</span>
                                                <span>{data.sales_count}</span>
                                            </p>
                                            <p>
                                                <span>General :</span>
                                                <span>{data.buyer_general_count}</span>
                                            </p>
                                            <p>
                                                <span>VIP :</span>
                                                <span>{data.buyer_vip_count}</span>
                                            </p>
                                            <p>
                                                <span>Member :</span>
                                                <span>{data.buyer_member_count}</span>
                                            </p>
                                            <p>
                                                <span>Promotion :</span>
                                                <span>{data.sales_promotion_count}</span>
                                            </p>
                                            <p>
                                                <span>Retur :</span>
                                                <span>{data.sales_return_count}</span>
                                            </p>
                                            <p>
                                                <span>Cancel :</span>
                                                <span>{data.sales_cancel_count}</span>
                                            </p>
                                            <p>
                                                <span>Refund :</span>
                                                <span>{data.sales_refund_count}</span>
                                            </p>
                                        </div>

                                        <NoPrint force={true}>
                                            <div className="table-menu">
                                                <div className="left">
                                                    <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                                </div>
                                                <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                            </div>
                                        </NoPrint>

                                        <SimpleBar className={`table-sticky mb-24 ${scroll ? '' : 'mh-auto'}`}>
                                            <table className="table-report">
                                                <thead>
                                                    <tr>
                                                        {
                                                            Object.keys(sort).map((key, idx) => (
                                                                <ThSort
                                                                    key={`th-${idx}`}
                                                                    label={sort[key].label}
                                                                    sort={sort[key].has_sort}
                                                                    handleClick={() => sortColumn(key, dataIdx)}
                                                                    className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                                />
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.pos.map((pos, posIdx) => {
                                                            const { category } = pos
                                                            const isReturn = category === 'return'
                                                            return (
                                                                <tr key={posIdx} className={isReturn ? "row-red" : ''}>
                                                                    <td >{posIdx + 1}</td>
                                                                    <td >{dateDMY(pos.date)}</td>
                                                                    <td className='td-highlight'>{pos.code}</td>
                                                                    <td >{pos.user_type}</td>
                                                                    <td >{pos.category}</td>
                                                                    <td className='td-text-right'>{money(pos.sub_total)}</td>
                                                                    <td className='td-text-right'>{money(pos.discount_value)}</td>
                                                                    <td className='td-text-right'>{money(pos.grand_total)}</td>
                                                                    <td className='td-text-right'>{money(pos.hpp)}</td>
                                                                    <td className='td-text-right'>{money(pos.additional_cost)}</td>
                                                                    <td className='td-text-right'>{money(pos.net_income)}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </SimpleBar>

                                    </div>
                                ))
                            }
                        </div>
                    </>
                ) : <NoReport />
            }
        </>
    )
}
