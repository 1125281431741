import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import DeleteIcon from '@material-ui/icons/Delete';
import TableNoData from 'Comps/elements/TableNoData'
import LoadingContent from 'Elements/LoadingContent';
import Table from 'Elements/Table'
import { deletePayment, setEditPayment, resetStatusPayment } from 'Reduxes/consignment/payment/paymentActions'
import { loadProduct } from 'Reduxes/inventory/product/productActions'
import { scrollToTop, money, moneyToInt, globalMessage, globalDialog } from '@/Helper'
import Label from 'Elements/Label'
import CustomToolbar from "../../../comps/elements/CustomToolbar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import KopSurat from 'Elements/KopSurat'
import SelectFilter from 'Elements/SelectFilter'


import {
  FormGroup,
  FormLabel,
  FormControl,
  ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Permission from '../../../comps/permission/Permission';


const useStyles = makeStyles((theme) => ({
  spacer: {
    height: "25px"
  }
}));


export default function TableData(props) {
  const localClass = useStyles();
  const { showDetail, showForm } = props;
  const { data, loading, error, success } = useSelector(state => state.paymentReducer);

  const classes = globalTableStyle();
  const dispatch = useDispatch();
  const handleDelete = async id => {
    let allowDelete = true;
    id.forEach(i => {
      const isFound = data.find(d => d.id === i)
      if (isFound && isFound.status !== 'draft') {
        allowDelete = false;
      }
    });

    if (!allowDelete) {
      globalMessage('You only allowed to delete data with status \'Draft\'', 'warning')
      return;
    }

    globalDialog(async () => {
      await dispatch(deletePayment(id))
      scrollToTop()
    })
  }
  const [date, setdate] = useState({
    dateFilterChecked: false
  })

  const [subTotal, setSubTotal] = useState({
    subTotalFilterChecked: false
  })

  const [disc, setDisc] = useState({
    discFilterChecked: false
  })

  const [total, setTotal] = useState({
    totalFilterChecked: false
  })


  //OPTIONS UNTUK SELECT CUSTOM FILTER 
  const uniqueContact = [...new Set(data.map(item => item.contact_name))];
  const contactOptions = uniqueContact.map(item => {
    return {
      value: item,
      label: item
    }
  })

  const table = tableOpts({
    handleDelete,
    data,
    colIdPosition: 0,
    filename: "Payment_Data",
    table: {
      columns: [
        {
          name: 'id',
          options: {
            viewColumns: false,
            display: false,
            filter: false,
            print: false
          }
        },
        {
          name: 'no',
          label: 'No.',
          options: {
            filter: false,
            customBodyRender: (value, tableMeta) => {
              return tableMeta.rowIndex + 1
            }
          }
        },
        // {
        //   name: 'is_warning', label: 'is_warning',
        //   options: { display: false, filter: false, print: false }
        // },

        {
          name: "date",
          label: 'Date',
          options: {
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && date.dateFilterChecked) {
                  return [`Min Date: ${v[0]}`, `Max Date: ${v[1]}`];
                } else if (v[0] && v[1] && !date.dateFilterChecked) {
                  return `Min Date: ${v[0]}, Max Date: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Date: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Date: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },

            filterOptions: {
              names: [],
              logic(date, filters) {


                if (filters[0] && filters[1]) {
                  return Date.parse(date) < Date.parse(filters[0]) || Date.parse(date) > Date.parse(filters[1]);
                } else if (Date.parse(filters[0])) {
                  return Date.parse(date) < Date.parse(filters[0]);
                } else if (Date.parse(filters[1])) {
                  return Date.parse(date) > Date.parse(filters[1]);
                }
                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel className={localClass.title}>Date</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />
                    <TextField
                      variant="outlined"
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="date"
                    />

                  </FormGroup>
                </div>
              ),
            },
          }
        },
        {
          name: 'code',
          label: 'ID',
          options: {
            filter: false
          }
        },
        {
          name: "contact_name",
          label: 'Contact',
          options: {
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[4].length === 1 && v[4][0]?.value === "") {  // index harus di sesuaikan dengan posisi column
                  return [];
                } else if (v[4].length) {
                  const val = v[4].map(x => x.value);
                  return [`Contact : ${val.join(', ')}`]
                }
                return [];

              },
              update: (filterList, filterPos, index) => { // untuk update chip filter diatas table
                if (filterPos === 0) {
                  filterList[index] = [];
                }
                return filterList;

              }
            },
            filterOptions: {
              logic(value, filters) {
                const dataFilter = filters[4]?.map(item => item.value)
                return !dataFilter || !dataFilter.length ? false : !dataFilter.includes(value); // jika tidak ada filter akan ditampilkan semua data di table

              },

              display: (filterList, onChange, index, column) => (
                <>
                  <SelectFilter
                    label="contact"
                    name="contact"
                    handleChange={select => {
                      filterList[index] = select || [];
                      onChange(filterList, index, column);
                    }}
                    options={contactOptions}
                    defaultValue={filterList[index][index]}
                  />
                </>
              )

            }

          }
        },
        {
          name: "sub_total",
          label: 'Total Invoice',
          options: {

            customBodyRender: (value, tableMeta, updateValue) => {
              return `IDR ${money(value)}`
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && subTotal.subTotalFilterChecked) {
                  return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
                } else if (v[0] && v[1] && !subTotal.subTotalFilterChecked) {
                  return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Price: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Price: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(price, filters) {

                if (filters[0] && filters[1]) {
                  return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                } else if (filters[0]) {
                  return moneyToInt(price) < filters[0];
                } else if (filters[1]) {
                  return moneyToInt(price) > filters[1];
                }

                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Sub Total</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      type='number'
                    />
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      type='number'

                    />
                  </FormGroup>
                </div>
              )

            }
          }

        },
        // {
        //   name: "discount",
        //   label: 'Discount',
        //   options: {
        //     customBodyRender: (value, tableMeta, updateValue) => {
        //       return `IDR ${money(value)}`
        //     },
        //     filter: true,
        //     filterType: 'custom',
        //     customFilterListOptions: {
        //       render: v => {
        //         if (v[0] && v[1] && disc.discFilterChecked) {
        //           return [`Min Discount: ${v[0]}`, `Max Discount: ${v[1]}`];
        //         } else if (v[0] && v[1] && !disc.discFilterChecked) {
        //           return `Min Discount: ${v[0]}, Max Discount: ${v[1]}`;
        //         } else if (v[0]) {
        //           return `Min Discount: ${v[0]}`;
        //         } else if (v[1]) {
        //           return `Max Discount: ${v[1]}`;
        //         }
        //         return [];
        //       },
        //       update: (filterList, filterPos, index) => {
        //         console.log('customFilterListOnDelete: ', filterList, filterPos, index);

        //         if (filterPos === 0) {
        //           filterList[index].splice(filterPos, 1, '');
        //         } else if (filterPos === 1) {
        //           filterList[index].splice(filterPos, 1);
        //         } else if (filterPos === -1) {
        //           filterList[index] = [];
        //         }

        //         return filterList;
        //       },
        //     },
        //     filterOptions: {
        //       names: [],
        //       logic(price, filters) {

        //         if (filters[0] && filters[1]) {
        //           return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
        //         } else if (filters[0]) {
        //           return moneyToInt(price) < filters[0];
        //         } else if (filters[1]) {
        //           return moneyToInt(price) > filters[1];
        //         }

        //         return false;
        //       },
        //       display: (filterList, onChange, index, column) => (
        //         <div>
        //           <FormLabel>Discount</FormLabel>
        //           <div className={localClass.spacer}></div>
        //           <FormGroup row>
        //             <TextField
        //               variant="outlined"
        //               InputLabelProps={{
        //                 shrink: true,
        //               }}
        //               label='min'
        //               value={filterList[index][0] || ''}
        //               onChange={event => {
        //                 filterList[index][0] = event.target.value;
        //                 onChange(filterList[index], index, column);
        //               }}
        //               style={{ width: '45%', marginRight: '5%' }}
        //               type='number'
        //             />
        //             <TextField
        //               variant="outlined"
        //               InputLabelProps={{
        //                 shrink: true,
        //               }}
        //               label='max'
        //               value={filterList[index][1] || ''}
        //               onChange={event => {
        //                 filterList[index][1] = event.target.value;
        //                 onChange(filterList[index], index, column);
        //               }}
        //               style={{ width: '45%' }}
        //               type='number'
        //             />
        //           </FormGroup>
        //         </div>
        //       )

        //     }
        //   }
        // },
        {
          name: "total",
          label: 'Total Payment',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {

              return `IDR ${money(value)}`
            },
            filter: true,
            filterType: 'custom',
            customFilterListOptions: {
              render: v => {
                if (v[0] && v[1] && total.totalFilterChecked) {
                  return [`Min Price: ${v[0]}`, `Max Price: ${v[1]}`];
                } else if (v[0] && v[1] && !total.totalFilterChecked) {
                  return `Min Price: ${v[0]}, Max Price: ${v[1]}`;
                } else if (v[0]) {
                  return `Min Price: ${v[0]}`;
                } else if (v[1]) {
                  return `Max Price: ${v[1]}`;
                }
                return [];
              },
              update: (filterList, filterPos, index) => {
                console.log('customFilterListOnDelete: ', filterList, filterPos, index);

                if (filterPos === 0) {
                  filterList[index].splice(filterPos, 1, '');
                } else if (filterPos === 1) {
                  filterList[index].splice(filterPos, 1);
                } else if (filterPos === -1) {
                  filterList[index] = [];
                }

                return filterList;
              },
            },
            filterOptions: {
              names: [],
              logic(price, filters) {

                if (filters[0] && filters[1]) {
                  return moneyToInt(price) < parseInt(filters[0]) || moneyToInt(price) > parseInt(filters[1]);
                } else if (filters[0]) {
                  return moneyToInt(price) < filters[0];
                } else if (filters[1]) {
                  return moneyToInt(price) > filters[1];
                }

                return false;
              },
              display: (filterList, onChange, index, column) => (
                <div>
                  <FormLabel>Total</FormLabel>
                  <div className={localClass.spacer}></div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='min'
                      value={filterList[index][0] || ''}
                      onChange={event => {
                        filterList[index][0] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%', marginRight: '5%' }}
                      type='number'
                    />
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      label='max'
                      value={filterList[index][1] || ''}
                      onChange={event => {
                        filterList[index][1] = event.target.value;
                        onChange(filterList[index], index, column);
                      }}
                      style={{ width: '45%' }}
                      type='number'

                    />
                  </FormGroup>
                </div>
              )

            }
          }
        },
        {
          name: 'difference',
          label: 'Difference',
          options: {
            customBodyRender: (val, tableMeta) => {
              const total_invoice = tableMeta.rowData[5]
              const total_payment = tableMeta.rowData[6]
              return `IDR ${money(total_invoice - total_payment)}`
            }
          }
        },
        {
          name: "status",
          label: 'Status',
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              let type = '';
              if (value === 'draft') {
                type = 'grey';
              } else if (value === 'paid') {
                type = 'success';
              } else {
                type = 'primary';
              }

              return <Label text={value} type={type} />
            },
            filter: true,
            filterType: 'checkbox',
            // filterOptions:{
            //     names: ['Draft', 'Open', 'Paid'],
            //     logic(val, filterVal) {
            //     const show =
            //             (filterVal.indexOf('Draft') >= 0  && val ==='draft')||
            //             (filterVal.indexOf('open') >= 0  && val ==='open') ||
            //             (filterVal.indexOf('paid') >= 0  && val === 'paid');


            //     return !show;
            //     },
            // }
          }
        },
        {
          name: "id",
          label: 'Actions',
          options: {
            setCellHeaderProps: () => ({
              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: 0,
                zIndex: 51
              }
            }),
            setCellProps: () => ({

              style: {
                whiteSpace: "nowrap",
                position: "sticky",
                right: "0",
                background: "white",
                zIndex: 51
              }
            }),
            print: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              console.log(tableMeta);
              const attrShow = {
                className: 'row-action',
                onClick: () => showDetail(value)
              }
              const attrEdit = {
                className: 'row-action',
                onClick: () => {
                  dispatch(setEditPayment(value))
                  dispatch(loadProduct());
                  showForm('Edit', value)
                  scrollToTop()
                }
              }
              const attrDelete = {
                className: 'row-action',
                onClick: () => handleDelete([value])
              }

              return (
                <>
                  <VisibilityIcon {...attrShow} />
                  { tableMeta.rowData[1] && <WarningIcon className="row-action red" />}
                  { tableMeta.rowData[8] === 'draft' &&
                    <Permission
                      component={
                        <EditIcon {...attrEdit} />
                      }
                      subMenu="payment"
                      type="update"
                    />
                  }
                  { tableMeta.rowData[8] === 'draft' &&
                    <Permission
                      component={
                        <DeleteIcon {...attrDelete} />
                      }
                      subMenu="payment"
                      type="delete"
                    />
                  }
                </>
              );
            },
            filter: false
          }
        },
      ],
    },
  })
  console.log(data)
  return (
    <PrintProvider>
      <Print single name="print-payment-table-1" >
        <KopSurat />
        <div className="spacer-60"></div>
        <ContentWrapper
          title="Payment"
          subtitle="showing table of all data payment"
          buttons={[
            {
              label: 'Add Payment',
              style: 'OrangeMd',
              icon: 'plus-circle',
              onClick: () => showForm('New'),
              subMenu: 'payment',
              type: 'create',
            }
          ]}
        >
          <>
            {
              success &&
              <Alert hide={() => dispatch(resetStatusPayment())} option={{ type: 'success', message: success }} />
            }
            {
              error && <Alert hide={() => dispatch(resetStatusPayment())} option={{ type: 'error', message: error }} />
            }
            {
              loading ? <LoadingContent />
                : (
                  data.length ?
                    <Table {...table} className={classes.table} />
                    : <TableNoData />
                )
            }
          </>
        </ContentWrapper>
      </Print>
    </PrintProvider>
  )
}