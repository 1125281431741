import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import { useDispatch, useSelector } from 'react-redux';
import { resetStatusSales, addSales, showSales, updateSales } from 'Reduxes/pos/sales/salesActions'
import { scrollToTop, getLocalStorage, makeImgUrl } from '@/Helper'
import Textarea from 'Elements/Textarea';
import SelectAsync from 'Elements/SelectAsync';
import SelectData from 'Elements/SelectData';
import Input from 'Elements/Input';
import FormButton from 'Elements/FormButton'
import TextHelper from 'Elements/TextHelper'
import Button from 'Elements/Button'
import Alert from 'Comps/elements/Alert'
import SimpleModal from 'Elements/modal/Modal'
import InputNumber from 'Elements/inputnumber/InputNumber'
import TableProduct from 'Elements/tableproduct/TableProduct'
import TableSales from './TableSales';
import styled from 'styled-components'
import { money, globalMessage } from '@/Helper'
import LoadingContent from 'Elements/LoadingContent'
import ScanProduct from 'Elements/ScanProduct'
import TableReturnModal from './TableReturnModal';
// import { ProductScanner } from '../../../utils/ProductScanner'
import print_sales from '../../../lib/print-receipt';
import ImageUploader from 'react-images-upload';
import { reqPost, hostUrl } from 'Configs/request'
import DisplayImage from 'Elements/DisplayImage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Permission from '../../../comps/permission/Permission';
import { debounce } from 'lodash'
import { SalesExchangeTransformer } from './transformers/sales-exchange.transformer';
import * as _ from 'lodash'

/** TAB */

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'

const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default function FormSales(props) {
    const { type, showTable, rowId, isDefaultReturn } = props;
    const dataLocalStorage = getLocalStorage();
    const dispatch = useDispatch();
    const [products, setProducts] = useState([]);
    const [showEDC, setShowEDC] = useState(false);
    const [dataEdit, setDataEdit] = useState(null); // untuk keperluan restore default data ketika edit (utk comp : SelectAsync)
    const [isReady, setIsReady] = useState(true);
    const [productDiscount, setProductDiscount] = useState([]);
    const [transItems, setTransItems] = useState([]); // untuk menyimpan produk yg akan ditampilkan di modal table return
    const [change, setChange] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const [modalReturn, setModalReturn] = useState(false);
    const [checkMember, setCheckMember] = useState({
        label: 'GENERAL', value: 2
    })
    const [checkVoucher, setCheckVoucher] = useState('')
    const [discountSummary, setDiscountSummary] = useState(0)
    const [totalSummary, setTotalSummary] = useState(0)
    const [totalExchangeSummary, setTotalExchangeSummary] = useState(false)
    // For store data total quantity
    const [totalQtySummary, setTotalQtySummary] = useState(0)
    const [summary, setSummary] = useState([
        { label: 'sub total', value: 0 },
        { label: 'discount', value: 0 },
        { label: 'total', value: 0 },
    ])

    const [dataErr, setDataErr] = useState(false)
    const { error, idEdit } = useSelector(state => state.salesReducer);
    const [initialValues, setInitialValues] = useState({
        "member_id": checkMember.value,
        "category": '',
        "shipment_date": '',
        "status": "paid",
        "note": '',
        "voucher": checkVoucher,
        payment_method: {
            "payment_name": "cash",
            "amount": 0,
            "edc_id": 0,
            "note": "",
            "change": 0
        }
    })

    const [transactionReturn, setTransactionReturn] = useState({})
    const [transactionReturnId, setTransactionReturnId] = useState(null)
    const hasTransactionReturn = Object.keys(transactionReturn)?.length > 0

    const formOpts = {
        initialValues,
        onSubmit: async (values, formik) => {
            const { setSubmitting, setFieldError, resetForm } = formik;
            const { voucher, variant_type_id, payment_method, member_id, status } = values;

            const pos_item = products.map(product => ({
                product_id: product.isReturn || product.product_id,
                price: product.price,
                uom_id: product.uom_id,
                qty: +product.qty,
                pos_retur_id: product.isReturn ? product.id : null,
                ...(product.variant_type_id ? { variant_type_id: product.variant_type_id } : {}),
                ...(product?.is_exchange ? { is_exchange: product.is_exchange } : {}),
                ...(product?.is_return_exchange ? { is_return_exchange: product.is_return_exchange } : {}),
                ...(product?.pos_item_promotion_id ? { pos_item_promotion_id: product.pos_item_promotion_id } : {}),
                ...(product?.is_exchange && product?.discount ? { discount: product.discount } : {})
            }))

            const cleanVal = {
                "branch_id": dataLocalStorage.branchLogin.id,
                "proof_member": proofMemberImage, // boleh ga dikirim
                "category": "sales",
                "note": payment_method?.note,
                ...(status ? { status } : {}),
                member_id: +checkMember.value,
                pos_item,
                voucher: checkVoucher,
                payment_method: {
                    ...payment_method,
                    amount: paymentAmount,
                    change: change,
                    ...(payment_method.edc_id ? { edc_id: + payment_method.edc_id } : {})
                }
                // voucher, 
            }
            if (member_id === '') {
                delete cleanVal.member_id
            }
            if (cleanVal.payment_method.edc_id === 0) {
                delete cleanVal.payment_method.edc_id;
            } else {
                delete cleanVal.payment_method.edc_id_label;
            }

            delete cleanVal.payment_method.payment_name_label;
            delete cleanVal.payment_method.edc_id_label;

            if (hasTransactionReturn) {
                Object.assign(cleanVal, {
                    return_total: transactionReturn.total,
                    exchange_id: transactionReturnId
                })

                const isWithoutGeneralProduct = pos_item?.find((item) => item.is_exchange && !item.is_return_exchange)

                if (!isWithoutGeneralProduct && type !== 'edit') {
                    globalMessage('Detected as an exchange transaction, but the exchange item does not exist on list(s).', 'warning');
                    return;
                }

                cleanVal.pos_item = cleanVal.pos_item.map((posItem) => {
                    return { ...posItem, is_exchange: true }
                })
            }

            // return;
            if (pos_item.length < 1) {
                globalMessage('You must at least add one product', 'warning')
                return;
            } else if ((cleanVal.payment_method.change < 0) && cleanVal.status === 'paid') {
                /* 
                inline error ini belum bisa dipake
                kaerena field InputNumber belum 
                terintegrasi  dengan formik
                setFieldError('payment_method.amount', 'something') 
                */
                globalMessage('Please recheck payment amount', 'warning');
                return;
            }
            if (0) {
                return;
            }

            // Print Options
            const options = {
                printerName: "PW_POS_PRINTER",
            };

            if (type === 'new') {
                if (!hasTransactionReturn) {
                    const result = await dispatch(addSales(cleanVal));
                    if (result) {
                        resetForm();
                        showTable();
                    }

                    if (result.data.struct) {
                        if (result.data.status === "paid") {
                            print_sales(result.data.struct, options)
                        }
                    }
                } else {
                    delete transactionReturn.member_name;
                    Object.assign(cleanVal, { exchange: transactionReturn })
                    const result = await dispatch(addSales(cleanVal));
                    if (result) {
                        resetForm();
                        showTable();
                    }

                    if (result.data.struct) {
                        if (result.data.status === "paid") {
                            print_sales(result.data.struct, options)
                        }
                    }
                }
            } else {
                const result = await dispatch(updateSales(rowId, cleanVal))
                if (result) {
                    resetForm();
                    showTable();
                }

                if (result.data.struct) {
                    if (result.data.status === "paid") {
                        print_sales(result.data.struct, options)
                    }
                }
            }

            scrollToTop()
            setSubmitting(false)
        }
    }

    const showModalProduct = e => {
        setModalProduct(state => !state)
    }
    const paymentOpts = [
        { label: 'Cash', value: 'cash' },
        { label: 'Ovo', value: 'ovo' },
        { label: 'Gopay', value: 'gopay' },
        { label: 'Dana', value: 'dana' },
        { label: 'Jenius', value: 'jenius' },
        { label: 'Credit Card', value: 'credit_card' },
        { label: 'Debit Card', value: 'debit_card' },
    ]

    // branch_id: dataLocalStorage.branchLogin.id 
    const [columns] = useState([
        { label: 'Code', name: 'code', className: 'td-1', param: { flat: true, status_product: 'active' } },
        { label: 'Product Name', name: 'name', className: 'td-1', param: { flat: true, status_product: 'active' } },
        { label: 'Brand', name: 'brand_name', className: 'width-as-content' },
        { label: 'Price /Unit', name: 'sales_price', type: 'money', align: 'right', className: 'width-as-content' },
        // { label: 'Disc (%)', name: 'disc', align: 'right', className: 'width-as-content' },
        { label: 'Color', name: 'color_name' },
    ]);

    const handleManualProduct = val => {

        // handleScannerProduct({ status: true, data: val })
        const prods = val.map(v => {
            const { id, variant_type_id, sales_price, uom_id, code } = v
            const productisDouble = products?.find(p => p.sku === v.code);
            // checking product is double for general transaction
            if (productisDouble && (!productisDouble?.is_return_exchange && !productisDouble?.is_exchange)) {
                const productisDoubleIdx = products.findIndex(x => x.sku === productisDouble.sku)
                products[productisDoubleIdx].qty = +products[productisDoubleIdx].qty + 1;
                return null;
            }

            // checking product is double for exchange transaction


            return {
                product_id: id,
                ...(variant_type_id ? { variant_type_id: variant_type_id } : {}),
                price: sales_price,
                uom_id: uom_id,
                qty: 1,
                sku: code
            }
        }).filter(Boolean);
        actionScannerProduct(prods, checkMember.value, checkVoucher)
        setModalProduct(false)
    }

    const handlePayment = v => {
        const changed = v - (totalSummary - discountSummary)
        setPaymentAmount(+v);
        setChange(changed);
    }
    const handlePaymentNameChange = (val, { setFieldValue }) => {
        const isCard = val === 'credit_card' || val === 'debit_card';
        setShowEDC(isCard);

        if (!isCard) {
            setFieldValue('payment_method.edc_id', 0)
        }
    }

    const removeProduct = async index => {
        const online = await isOnline()
        // if (products.length <= 1) handlePayment(0)
        let removeSelectedProducts = []

        if (hasTransactionReturn) {
            const product = products[index]
            const isBogo = product?.promotion?.promotion_type === 'quantity' || product?.promotion?.type === 'quantity'

            // checking if item deleted is bogo/bonus bogo, then delete target/bonus.
            if (product?.price === 0) {
                const targetBogoIdx = products.findIndex((item, currIdx) => {
                    const isBogo = item?.promotion?.promotion_type === 'quantity' || item?.promotion?.type === 'quantity'

                    return item.product_id === product.product_id && currIdx !== index && isBogo
                })

                removeSelectedProducts = products.filter((_, productIdx) => {
                    return productIdx !== index && productIdx !== targetBogoIdx
                })
            } else if (isBogo) {
                const bonusProductIdx = products.findIndex((item, currIdx) =>
                    item.product_id === product.product_id &&
                    currIdx !== index
                )

                removeSelectedProducts = products.filter((_, productIdx) => {
                    return productIdx !== index && productIdx !== bonusProductIdx
                })
            } else {
                // remove like usual.
                removeSelectedProducts = products.filter((_, productIdx) => productIdx !== index)
            }
        } else {
            // remove like usual.
            removeSelectedProducts = products.filter((_, productIdx) => productIdx !== index)
        }

        const newValue = {
            member_id: +checkMember.value,
            voucher: checkVoucher,
            branch_id: getLocalStorage().branchLogin.id,
            pos_item: removeSelectedProducts
        }
        const resp = await reqPost(`${online ? hostUrl : offlineUrl}/api/pos-calculate`, newValue)
        const { pos_item, total, discount, total_qty } = resp.data
        if (resp.status === "success") {

            setChange(paymentAmount - total)
            setProducts(pos_item)
            if (pos_item.length > 0) {
                if (pos_item[0].promotion?.promotion_type === 'voucher') {
                    setDiscountSummary(pos_item[0].promotion.promotion_values.value)
                    setTotalSummary(total)
                    setChange(paymentAmount - total)
                    setTotalQtySummary(total_qty)
                } else {
                    const totalDiscount = pos_item.reduce((total, posItem) => {
                        return total += posItem.discount * posItem.qty
                    }, 0)
                    setTotalQtySummary(total_qty)
                    setTotalSummary(total)
                    setDiscountSummary(totalDiscount)
                    setChange(paymentAmount - total)
                }
            }
        }
    }

    /*****
        Action setelah memilih dari SelectAsync transaction
            . setelah data transaction di fetch 
            . simpan data ke products 
            . transItems digunakan sbg data yg akan di render di table return (modal)
            . tampilkan modalnya
            . ketika di tekan tombol plus pada modal product return dari function addSelecterReturnProduct() simpan yg dipilih ke lokal
            . untuk product yg ditambahkan dari hasil pencarian transaction ditambahkan flag isReturn (karena key yg akan ditampilkan berbeda antara dari search product - transaction  jadi ini perlu dilakukan) eksekusi nya ada di addSelectedReturnProduct
    *****/
    const handleTransactionSearch = data => {
        const productsByTransaction = data.row.pos_item.map(item => ({
            product_code: item.sku,
            id: item.product_id,
            name: item.product_name,
            sales_price: item.price,
            uom_name: item.uom_name,
            uom_id: item.uom_id,
            qty: item.qty,
            promotion_values: item.promotion_values
        }))
        // setProducts(productsByTransaction);
        setTransItems(data.row);
        setModalReturn(true);
    }
    const addSelectedReturnProduct = (row) => {
        const modifiedRow = {
            ...row,
            product_code: row.sku,
            name: row.product_name,
            variant_type_value: null, // ini null karena di pos item by transaction ga ada datanya
            sales_price: row.price,
            isReturn: true
        }
        const mergedProducts = [...products, modifiedRow];
        setProducts(mergedProducts);
        countPaymentSummary(products);
    }
    const countPaymentSummary = () => {
        /*  ini dieksekusi ketika nambahkan produk
        dan ketika qty pada table tablesales berubah */
        let subTotal = 0;
        let totalDiscount = 0;

        products.map(p => {
            // if (p.isReturn || p.is_exchange) {
            if (p.isReturn) {
                subTotal -= p.sales_price * +p.qty;
            } else {
                subTotal += p.sales_price * +p.qty;
            }
        })

        /* get giveaway */
        products.map(p => {
            if (!p.promotion) {
                return;
            }

            const hasPromo = p.promotion.length;
            if (hasPromo) {
                const promo = p.promotion[0];
                const promoType = promo.promotion_type;
                const proVal = promo.promotion_values;
                const minimumBuy = proVal.minimum;


                if (subTotal >= minimumBuy) {
                    // setProducts([...products, { ...proVal, qty: 1 }])
                }
            }
        })



        /* 
        *** GET DISCOUNT ***
        setelah dapat subtotal baru 
        bisa dihitung discount nya 
        */
        products.map(p => {
            if (p.promotion && subTotal >= p.promotion.minimum) {
                totalDiscount += p.promotion.value
            }
        })

        setSummary(state => {
            state[0].value = totalSummary + discountSummary;
            state[1].value = discountSummary;
            state[2].value = totalSummary;
            return [...state];
        })
    }

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const transformTableSalesProductData = data => { //karena data beda2 pake ini biar sesuai data yg dikirim ke tablesales (products)
        return data.map(d => {
            return {
                ...d,
                isReturn: d.category === 'return',
                product_code: d.sku,
                name: d.product_name,
                note: d.note || '',
                variant_type_value: d.variant_type_value,
                sales_price: d.price,
                uom_name: d.uom_name,
                qty: d.qty,
            };
        })
    }

    useEffect(() => {
        countPaymentSummary();
        // setTableSalesDisc();
    }, [products])

    useEffect(() => {

        const getDataEdit = async () => {
            const dataSales = await dispatch(showSales(rowId))

            if (dataSales.status) {
                const {
                    category,
                    member_id = '',
                    note,
                    status,
                    pos_item,
                    member_name,
                    payment_method,
                    proof_member,
                    discount,
                    total,
                    total_qty,
                    voucher,
                } = dataSales.data;

                setDataEdit(dataSales.data);
                const realDiscount = (discount < 0 ? discount * -1 : discount) ?? 0
                const { amount, change } = payment_method;
                setCheckMember({ label: member_name, value: member_id })
                setDiscountSummary(realDiscount)
                setTotalSummary(total + realDiscount)
                setTotalQtySummary(total_qty)
                setPaymentAmount(amount);
                setChange(change);
                setProducts(transformTableSalesProductData(pos_item));
                setProofMemberImage(proof_member)
                setCheckVoucher(voucher)
                setInitialValues({
                    status,
                    member_id,
                    category,
                    note: note || '',
                    pos_item: pos_item.map(({ product_name, sku, variant_type_id, product_id, price, uom_id, qty, id, promotion }) => ({
                        name: product_name,
                        product_code: sku,
                        product_id,
                        ...(variant_type_id ? { variant_type_id } : {}),
                        price,
                        uom_id,
                        qty,
                        id,
                        ...(promotion ? promotion?.name : '-')
                    })),
                    payment_method,
                    voucher
                })

                if (dataSales?.data?.exchange_id) {
                    setTransactionReturnId(dataSales.data.exchange_id)
                }

                setIsReady(true);
            } else {
                showTable();
            }

            return dataSales;
        }

        if (type === 'edit') {
            setIsReady(false);
            getDataEdit();
        }

    }, [])

    useEffect(() => {
        const getExchange = async () => {
            return await dispatch(showSales(transactionReturnId))
        }

        if (type === 'edit' && !!transactionReturnId) {
            getExchange().then((res) => {
                const pos = SalesExchangeTransformer(res.data);
                setTransactionReturn(pos)
            }).catch((err) => console.log(err))
        }

    }, [transactionReturnId])

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChangeMember = (val) => {
        const { value, row } = val
        const tempMember = { label: row.name, value }
        setCheckMember(tempMember)

        actionScannerProduct([], value, checkVoucher)
    }
    const handleChangeVoucher = debounce((value) => {
        setCheckVoucher(value)

        actionScannerProduct([], checkMember.value, value)
    }, 1000)
    const handleScannerProduct = async val => {
        // jika member kosong, user tidak bisa scan product,
        // jika member sudah terisi scan product dari filter product diolah lalu dia ngehit dulu ke api baru
        // yang dikirim ke api baru pos_item, member id (required) sama voucher (optional)
        // render hasil dari api baru
        if (checkMember === '') {
            globalMessage('Please fill the member field before you scan your products.', 'warning')
            return [];
        } else if (dataEdit && dataEdit.status === 'paid') {
            globalMessage("You cannot add products with status 'Paid'", 'warning')
            return []
        } else if (val.status) {
            if (val.data.length > 0) {
                const pos_item = val.data.map(v => {
                    const { id, variant_type_id, sales_price, uom_id, code, existing_giveaway } = v
                    return {
                        product_id: id,
                        ...(variant_type_id ? { variant_type_id: variant_type_id } : {}),
                        price: sales_price,
                        uom_id: uom_id,
                        qty: 1,
                        sku: code,
                        existing_giveaway
                    }
                }).filter(Boolean);

                const productisDouble = products?.find(p => p.sku === pos_item[0].sku);
                if (productisDouble) {
                    const productisDoubleIdx = products.findIndex(x => x.sku === productisDouble.sku)
                    products[productisDoubleIdx].qty = +products[productisDoubleIdx].qty + 1;
                    return []
                }

                return pos_item


            } else {
                globalMessage('Product not found', 'warning')
                return []
            }

        } else {
            setDataErr(true)
            return []
        }
    }

    const selectTransactionReturn = ({ row }) => {
        if (!row) return

        setTransactionReturnId(row.id)
        const pos = SalesExchangeTransformer(row);
        setTransactionReturn(pos)
        setCheckMember({
            label: pos.member_name,
            value: pos.member_id
        })
        setCheckVoucher(pos.voucher)

        let returnTotalQty = 0
        let returnTotalDiscount = 0

        const clonedPos = _.cloneDeep(pos);

        const posItems = clonedPos.pos_item.map((posItem) => {
            returnTotalDiscount += (posItem.discount * posItem.qty);
            returnTotalQty += posItem.qty;

            posItem.price = posItem.price * -1;
            posItem.total = posItem.total * -1;

            return posItem;
        });

        setDiscountSummary(returnTotalDiscount)
        setTotalExchangeSummary(pos.total)
        setTotalQtySummary(returnTotalQty)
        setChange(paymentAmount - (pos.total * -1))
        actionScannerProduct(posItems, pos.member_id, pos.voucher, true)
    }

    const actionScannerProduct = async (handle, member_id, voucher, isInitialReturn = false) => {
        const pro = await handle

        let mergedProducts = isInitialReturn ? [...pro] : [...products, ...pro]

        if (hasTransactionReturn) {
            mergedProducts = mergedProducts.map((mergedProduct) => {
                return { ...mergedProduct, is_exchange: true }
            })
        }

        const newValue = {
            member_id: +member_id || +checkMember.value,
            voucher: voucher,
            branch_id: getLocalStorage().branchLogin.id,
            pos_item: mergedProducts
        }

        const online = await isOnline()
        const resp = await reqPost(`${online ? hostUrl : offlineUrl}/api/pos-calculate`, newValue)
        const { pos_item, discount, total, total_qty } = resp.data

        if (resp.status === "success") {
            const totalDiscount = pos_item?.reduce((total, item) => {
                return total += (item.qty * item.discount)
            }, 0)

            if (hasTransactionReturn) {
                setDiscountSummary(totalDiscount)
            } else {
                setDiscountSummary(discount)
            }
            
            setTotalSummary(total)
            setTotalQtySummary(total_qty)
            setProducts(pos_item)
            countPaymentSummary(mergedProducts);

            if (hasTransactionReturn) {
                const mapped = pos_item.map((item) => {
                    if (!item.is_exchange && !item.transaction_return) {
                        return { ...item, is_exchange: true }
                    }

                    return item
                })

                setProducts(mapped)
            }

            setChange(paymentAmount - (total - totalDiscount))
        }
    }

    const [proofMemberImage, setProofMemberImage] = useState(null)
    const [loading, setLoading] = useState(false)

    const onDrop = async (pictureFiles, pictureDataURLs) => {
        const online = await isOnline()
        if (pictureFiles.length) {
            let formData = new FormData();
            let file = pictureFiles[0];
            formData.append('file_member', file)
            setLoading(true)
            const resp = await reqPost(`${online ? hostUrl : offlineUrl}/api/pos/attachment`, formData);
            setLoading(false)
            setProofMemberImage(resp.data)
        } else {
            setProofMemberImage(null)
        }
    }

    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Sales" : "Update data Sales"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data sales `}
        >
            <>

                <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} type={"pos"} />

                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }

                {
                    error && <Alert hide={() => dispatch(resetStatusSales())} option={{ type: 'error', message: error }} />
                }

                <SimpleModal
                    customClass="modal"
                    isOpen={modal}
                    width={900}
                    setIsOpen={setModal}
                    title="Something smell good"
                >
                    <div className="modal-sales">
                        <div className="detail style-2">
                            <div className="col">
                                <span className="key">Description</span>
                                <span className="val">Bahureksa - User 1<br />22 March 2021</span>
                            </div>
                            <div className="col">
                                <span className="key">Sales ID</span>
                                <span className="val">#ABC-123-8822</span>
                            </div>
                            <div className="col">
                                <span className="key">Payment</span>
                                <span className="val">IDR 200.000 (Cash)</span>
                            </div>
                            <div className="col">
                                <span className="key">Change</span>
                                <span className="val">IDR 20.000</span>
                            </div>
                        </div>

                        <div className="table-product">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Code Product</th>
                                        <th>Name</th>
                                        <th>Price /unit</th>
                                        <th>Unit</th>
                                        <th>Discount</th>
                                        <th>Total</th>
                                        <th className="right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...new Array(10)].map((_, idx) => (
                                            <tr key={idx}>
                                                <td>SKU</td>
                                                <td>Name</td>
                                                <td>Price /unit</td>
                                                <td>Unit</td>
                                                <td>Discount</td>
                                                <td>Total</td>
                                                <td className="btn-action">
                                                    <Button
                                                        style="GreenSm"
                                                        icon="plus"
                                                    />
                                                </td>
                                            </tr>
                                        ))

                                    }
                                </tbody>
                            </table>
                        </div>

                        <p className="total">
                            <span>Subtotal : IDR 20. 000. 000</span>
                            <span>Discount : IDR 10. 000. 000</span>
                            <span>PPN : IDR 1. 000. 000</span>
                            <span>Total : IDR 20. 000. 000</span>
                        </p>
                    </div>
                </SimpleModal>

                {
                    !isReady ? <LoadingContent />
                        : <Formik {...formOpts} enableReinitialize={true}>
                            {(formik) => {

                                const { isSubmitting, values } = formik;
                                return (

                                    <Form className="form-layout-1">
                                        <Box sx={{ width: '100%' }}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                <Tabs value={value} onChange={handleChange} aria-label="POS Order Return">
                                                    <Tab label="POS Order" {...a11yProps(0)} />
                                                    <Tab label="POS Return" {...a11yProps(1)} />
                                                </Tabs>
                                            </Box>
                                            <TabPanel value={value} index={0}>
                                                <FieldsContainer title="Sales Information">
                                                    <SelectAsync
                                                        {...(type === 'edit' && dataEdit ? { value: { label: dataEdit.member_name, value: dataEdit.member_id } } : {})}
                                                        type="member"
                                                        name="member_id"
                                                        label="Customer Type"
                                                        value={checkMember}
                                                        isClearable={false}
                                                        isDisabled={(dataEdit && dataEdit.status === 'paid') || hasTransactionReturn ? true : false}
                                                        handleChange={handleChangeMember}
                                                    />
                                                    <Input
                                                        name="voucher"
                                                        value={(type === 'edit' && dataEdit) ? dataEdit.voucher : checkVoucher}
                                                        label="No. Voucher"
                                                        handleChange={handleChangeVoucher}
                                                        disabled={(dataEdit && dataEdit.status === 'paid') || hasTransactionReturn ? true : false} />
                                                    <div
                                                        style={{ display: 'none' }}
                                                    >
                                                        <SelectAsync
                                                            type="transaction"
                                                            handleChange={handleTransactionSearch}
                                                            name="transaction_id"
                                                            label="Search Transaction"
                                                            note="Use this field to add transaction return"
                                                            isDisabled={dataEdit && dataEdit.status === 'paid' ? true : false}
                                                        />
                                                    </div>
                                                    <br />
                                                    {
                                                        dataEdit?.status !== 'paid' &&
                                                        <Permission
                                                            component={
                                                                <div style={{ marginTop: '30px' }}>
                                                                    <Button
                                                                        label="Search Product"
                                                                        icon="plus"
                                                                        style="TextGreen"
                                                                        onClick={showModalProduct}
                                                                    />
                                                                </div>
                                                            }
                                                            type="confirm"
                                                            subMenu="sales_pos"
                                                        />
                                                    }
                                                </FieldsContainer>


                                                {/* ================== T A B L E   S A L E S ================== */}
                                                {
                                                    products.length > 0 &&
                                                    <>
                                                        <TableSales
                                                            removeProduct={removeProduct}
                                                            setProducts={setProducts}
                                                            productDiscount={productDiscount}
                                                            products={products}
                                                            summary={summary}
                                                            status={dataEdit && dataEdit?.status}
                                                            type={type}
                                                            action={actionScannerProduct}
                                                            buyer={checkMember}
                                                            tableType={'sales'}
                                                            transactionReturn={transactionReturn}
                                                        />
                                                        {/* <SummaryBox items={summary} /> */}
                                                        <Item>
                                                            <SmallText>Total Quantity</SmallText>
                                                            <BigText>
                                                                {
                                                                    totalQtySummary
                                                                }
                                                            </BigText>
                                                        </Item>
                                                        <Item>
                                                            <SmallText>Subtotal</SmallText>
                                                            <BigText>
                                                                {
                                                                    `IDR ${money(totalSummary)}`
                                                                }
                                                            </BigText>
                                                        </Item>
                                                        <Item>
                                                            <SmallText>Discount</SmallText>
                                                            <BigText>
                                                                {
                                                                    `IDR ${money(discountSummary)}`
                                                                }
                                                            </BigText>
                                                        </Item>
                                                        {/* <Item>
                                                            <SmallText>Order Total</SmallText>
                                                            <BigText>
                                                                {
                                                                    `IDR ${money(totalSummary * -1)}`
                                                                }
                                                            </BigText>
                                                        </Item> */}
                                                        {
                                                            totalExchangeSummary ? (
                                                                <>
                                                                    <Item>
                                                                        <SmallText>Return Total</SmallText>
                                                                        <BigText>
                                                                            {
                                                                                `IDR ${money(totalExchangeSummary)}`
                                                                            }
                                                                        </BigText>
                                                                    </Item>
                                                                    <Item>
                                                                        <SmallText>Total Difference</SmallText>
                                                                        <BigText>
                                                                            {
                                                                                `IDR ${money(totalExchangeSummary + (totalSummary-discountSummary))}`
                                                                            }
                                                                        </BigText>
                                                                    </Item>
                                                                </>

                                                            ) : false
                                                        }
                                                        <Item>
                                                            <SmallText>Total</SmallText>
                                                            <BigText>
                                                                {
                                                                    `IDR ${money(totalSummary - discountSummary)}`
                                                                }
                                                            </BigText>
                                                        </Item>
                                                        <br />
                                                        <br />
                                                        <Textarea name="note" label="Notes" col="6" disabled={dataEdit && dataEdit.status === 'paid' ? true : false} />
                                                        <FieldsContainer title="Payment Information">
                                                            <SelectData handleChange={(v, formik) => handlePaymentNameChange(v, formik)} options={paymentOpts} name="payment_method.payment_name" label="Payment method" />
                                                            {
                                                                showEDC ?
                                                                    (<>
                                                                        <SelectData options="edc" name="payment_method.edc_id" label="EDC" />
                                                                        <div></div>
                                                                    </>)
                                                                    : (<><div></div><div></div></>)
                                                            }
                                                            <InputNumber value={initialValues.payment_method.amount} name="payment_method.amount" handleChange={handlePayment} label="Total Payment" withFieldContainer />
                                                            <Input disabled name="payment_method.change" label="Change" value={change} />
                                                        </FieldsContainer>
                                                        <Textarea name="payment_method.note" label="Notes" col="6" disabled={dataEdit && dataEdit.status === 'paid' ? true : false} />
                                                    </>
                                                }


                                                <SimpleModal
                                                    customClass="modal"
                                                    isOpen={modalProduct}
                                                    setIsOpen={setModalProduct}
                                                    width={900}
                                                    title="Add Product Sales"
                                                >
                                                    <div className="scroll">
                                                        <TableProduct
                                                            columns={columns}
                                                            saveButton={(val) => handleManualProduct(val)}
                                                            showTotal={false}
                                                        />
                                                    </div>
                                                </SimpleModal>
                                                {
                                                    proofMemberImage === "" || proofMemberImage === null && loading &&
                                                    < div
                                                        style={{
                                                            background: '#efefef',
                                                            width: '23%',
                                                            height: '50px',
                                                            border: '1px solid #ddd',
                                                            color: "#555",
                                                            fontWeight: '400',
                                                            fontSize: '13px !important',
                                                            border: '1px solid rgb(221, 221, 221)',
                                                            borderRadius: '30px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                        <span> <FontAwesomeIcon spin icon="spinner" /> Uploading </span>
                                                    </div>
                                                }
                                                {
                                                    proofMemberImage === "" || proofMemberImage === null && !loading ?
                                                        <ImageUploader
                                                            withIcon={false}
                                                            buttonText='Upload Proof Member'
                                                            onChange={onDrop}
                                                            imgExtension={['.jpg', '.png', '.jpeg']}
                                                            maxFileSize={5242880}
                                                            singleImage={true}
                                                            label="Upload Proof Member Hire"
                                                            withPreview={false}
                                                            withLabel={false}
                                                            buttonStyles={{
                                                                background: '#efefef',
                                                                width: '23%',
                                                                height: '50px',
                                                                border: '1px solid #ddd',
                                                                color: "#555",
                                                                fontWeight: '400',
                                                                fontSize: '13px !important'
                                                            }}
                                                            fileContainerStyle={{
                                                                alignItems: 'start',
                                                                justifyContent: 'start'
                                                            }}
                                                        /> :
                                                        <> </>
                                                }

                                                {
                                                    proofMemberImage === "" || proofMemberImage === null ?
                                                        <></> :
                                                        <DisplayImage
                                                            linkImage={makeImgUrl(proofMemberImage)}
                                                            onChangeLink={() => {
                                                                setProofMemberImage(null)
                                                            }}
                                                        />

                                                }
                                            </TabPanel>
                                            <TabPanel value={value} index={1}>
                                                <FieldsContainer title="Sales Return Information">
                                                    <SelectAsync
                                                        type="transaction_return"
                                                        handleChange={selectTransactionReturn}
                                                        name="transaction_id"
                                                        label="Search Transaction"
                                                        note="Use this field to add product exchange"
                                                        isDisabled={dataEdit && dataEdit.status === 'paid' ? true : false}
                                                    />
                                                </FieldsContainer>
                                                {/* TABLE RETURN */}
                                                <br />
                                                {
                                                    Object.keys(transactionReturn)?.length > 0 &&
                                                    <>
                                                        <TableSales
                                                            removeProduct={removeProduct}
                                                            setProducts={setProducts}
                                                            productDiscount={productDiscount}
                                                            products={transactionReturn.pos_item}
                                                            summary={summary}
                                                            status={dataEdit && dataEdit?.status}
                                                            type={type}
                                                            action={actionScannerProduct}
                                                            tableType={'exchange'}
                                                        />
                                                        <Item>
                                                            <SmallText>Total</SmallText>
                                                            <BigText>
                                                                {
                                                                    `IDR ${money(transactionReturn.total)}`
                                                                }
                                                            </BigText>
                                                        </Item>
                                                    </>
                                                }
                                            </TabPanel>
                                        </Box>

                                        {/* MODAL TABLE RETURN */}
                                        <SimpleModal
                                            customClass="modal"
                                            isOpen={modalReturn}
                                            setIsOpen={setModalReturn}
                                            width={900}
                                            title="Product Retur"
                                            data={transItems}
                                        >
                                            <TableReturnModal
                                                addSelectedReturnProduct={addSelectedReturnProduct}
                                                transaction={transItems}
                                                setModalProduct={setModalReturn}
                                            />
                                        </SimpleModal>
                                        <FormButton hasDraft={dataEdit?.status !== 'paid'} isSubmitting={isSubmitting} showTable={showTable} label='Paid' />
                                        <TextHelper message="*Save data will save the transaction as `Paid` or use draft button to save transaction as 'Draft'" />
                                    </Form>
                                )
                            }}
                        </Formik>
                }

            </>
        </ContentWrapper >
    )
}

const Item = styled.div`
    border: 1px solid #eee;
    min-width: 130px;
    display: inline-block;
    border-radius: 5px;
    padding: 20px;
    margin-right: 10px;
    background: #fafafa;
`
const BigText = styled.p`
    font-weight: 700;
    font-size: 18px;
    color: #333;
`
const SmallText = styled.p`
    text-transform: uppercase;
    font-size: 10px;
    color: #aaa;
    font-weight: 700;
    margin-bottom: 4px;
`