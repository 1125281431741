import React, { useEffect, useState } from 'react'

import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { getBarcode, resetStatusProduct, addProduct, updateProduct } from 'Reduxes/inventory/product/productActions'
import { scrollToTop, toSnackCase, globalMessage } from '@/Helper'
import Button from 'Elements/button/Button';
import Input from 'Elements/input/Input';
import InputNum from 'Elements/inputnum/InputNum';
import Select from 'Elements/select/Select';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import FormButton from 'Elements/FormButton'
import LoadingContent from 'Elements/LoadingContent'
import TableForm from './TableForm'
import { validationSchema } from './validation'
import { showProductByid } from '../../../reduxes/inventory/product/productActions';


export default function FormElement(props) {

    /* CONST */
    const { type, showTable } = props;
    const dispatch = useDispatch();
    const { error, idEdit } = useSelector(state => state.productReducer)
    const [data, setData] = useState({})

    /* STATE */
    const [isReady, setIsReady] = useState(false) // untuk loading data yang akan diedit
    const [initialValues, setInitialValues] = useState({
        product_type: "",
        name: '',
        status: true,
        category_id: '',
        brand_id: '',
        unit_of_measurement_id: '',
        color_id: '',
        hpp: 0,
        material_price: 0,
        sales_price: 0,
        comission: 0,
        product_variant_item: [],
        variant_id: '',
        variant_type_id: '',
    })
    const [subVariant, setSubVariant] = useState([]) // options subvariant berdasarkan variant jadi distore di state
    const [variantList, setVariantList] = useState([])// ini utk nampung data variant yg di submit
    const [loadingBarcode, setLoadingBarcode] = useState(false) //state loading saat request barcdode ketika request variant
    const [selectedVariant, setSelectedVariant] = useState(null) // nyimpan data variant name yg dipilih
    const [selectedVariantValue, setSelectedVariantValue] = useState(null) // nyimpan data variant value yg dipilih
    const [selectedBrand, setSelectedBrand] = useState(null) // nyimpan data rawOpt dari brand yang dipilih, ini berguna ketika user ubah kategori setelah memilih brand name


    /* FORM HANDLER */
    const handleVariant = (data) => {
        setSelectedVariant(data.rawOpt)

        // set untuk value sub variant value
        setSubVariant(
            data.rawOpt.variant_value && data.rawOpt.variant_value.length ? data.rawOpt.variant_value.map(x => ({
                label: x.value, value: x.variant_type_id
            })) : []
        )
    }
    const handleVariantValue = (data) => {
        setSelectedVariantValue(data.selected)
    }
    const handleSubmitVariant = async (formikAttr) => {

        if (!selectedVariant || !selectedVariantValue) {
            return;
        }

        const { color_id, brand_id, category_id } = formikAttr.values;
        const variant_id = selectedVariant.id;
        const variant_type_id = selectedVariantValue.value;

        const isAdded = variantList.find(row => {
            return row.variant_value === variant_id && row.subvariant_name === variant_type_id
        });

        if (isAdded) {
            globalMessage("Variant has been added already", 'warning')
            return;
        }

        setLoadingBarcode(true);

        const reqBarcode = await dispatch(getBarcode({
            color_id,
            brand_id,
            category_id,
            product_variant_item: [
                { variant_id, variant_type_id }
            ]
        }))
        setLoadingBarcode(false);

        if (!reqBarcode.status) {
            globalMessage('Fail to generate barcode !', 'danger');
            return;
        }


        setVariantList([
            ...variantList,
            {
                variant_name: selectedVariant.name,
                variant_value: selectedVariant.id,
                subvariant_name: selectedVariantValue.value,
                subvariant_value: selectedVariantValue.label,
                barcode: reqBarcode.data.barcode[0]
            }
        ]);

    }
    const handleBrand = ({ selected, rawOpt, formik }) => {
        const { setFieldValue, values } = formik;
        const hasCat = rawOpt.category_item;
        let comissionApplied = rawOpt.comission || 0;

        setSelectedBrand(rawOpt);

        if (hasCat && hasCat.length) {
            const isCatFound = hasCat.find(x => x.category_id === +values.category_id)
            if (isCatFound) {
                comissionApplied = isCatFound.comission;
            }
        }

        setFieldValue('comission', comissionApplied);
    }
    const handleCategory = ({ selected, rawOpt, formik }) => {

        if (selectedBrand) {
            const selectedCategoryId = selected.value;
            const selectedBrandCatItem = selectedBrand.category_item;

            if (selectedBrandCatItem) {
                const isFound = selectedBrandCatItem.find(x => x.category_id === selectedCategoryId);
                isFound && formik.setFieldValue('comission', isFound.comission);
            }
        }
    }


    /* FORMIK */
    const formOpts = {
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const {
                name, product_type, category_id, unit_of_measurement_id, status,
                color_id, hpp, material_price, sales_price, comission, brand_id, code
            } = values;

            const product_variant_item = variantList.map(v => {
                return { variant_id: v.variant_value, variant_type_id: v.subvariant_name }
            })

            const cleanVal = {
                name,
                product_type,
                category_id,
                ...(type === 'edit' ? { code } : {}),
                unit_of_measurement_id,
                color_id,
                hpp,
                material_price,
                sales_price,
                comission,
                brand_id,
                status,
                product_variant_item
            };

            let result = type === 'new' ?
                await dispatch(addProduct(cleanVal))
                : await dispatch(updateProduct(idEdit, cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }

    useEffect(() => {
        if (type === 'edit' && Object.keys(data).length > 1) {
            let {
                name, product_type, category_id, unit_of_measurement_id, uom_id, status, product_variant_item,
                color_id, hpp, material_price, sales_price, comission, brand_id, code
            } = data;

            product_type = toSnackCase(product_type)


            /* masang default value */
            /* generate value tuk default table */
            const variants = product_variant_item.map(variant => {
                setVariantList(state => {
                    return [
                        ...state,
                        {
                            variant_name: variant.variant_name,
                            variant_value: variant.variant_id,
                            subvariant_value: variant.variant_type_value,
                            subvariant_name: variant.variant_type_id,
                            barcode: variant.barcode
                        }
                    ]
                })
                return { variant_id: variant.variant_id, variant_type_id: variant.variant_type_id }
            })

            setInitialValues({
                name,
                product_type,
                category_id,
                ...(type === 'edit' ? { code } : {}),
                unit_of_measurement_id: unit_of_measurement_id || uom_id,
                color_id,
                hpp,
                material_price,
                sales_price,
                comission,
                brand_id,
                status,
                product_variant_item: variants
            });
        }
    }, [data])

    /* EFFECT */
    useEffect(() => {
        setIsReady(true)
        if (type === 'edit') {

            const fetchProductById = async () => {
                const resp = await dispatch(showProductByid(idEdit))
                setData(resp.data)
            }

            fetchProductById()
        }
    }, [])



    return (
        <ContentWrapper
            title={type === 'edit' ? "Update data Product" : "Add new Product"}
            subtitle={`Please fill the form below to ${type === 'edit' ? 'update' : 'create new'} data product`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusProduct())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ?
                        (<Formik {...formOpts} enableReinitialize={true}>
                            {({ isSubmitting, values }) => {
                                return (
                                    <Form className="form-layout-1">
                                        <FieldsContainer title="General Information">
                                            <Select
                                                name="product_type"
                                                options={[
                                                    { label: 'Product Sales', value: 'product_sales' },
                                                    { label: 'Product General', value: 'product_general' },
                                                    { label: 'Consignment', value: 'consignment' },
                                                    { label: 'Discontinue', value: 'discontinue' }
                                                ]}
                                                label="Type"
                                            />
                                            <Input name="name" label="Name" />
                                            <Select name="status" options="isActive" label="status" />
                                        </FieldsContainer>

                                        <FieldsContainer title="Detail Information">
                                            <Select name="category_id" getData={handleCategory} options="category" label="category" />
                                            <Select name="brand_id" getData={handleBrand} options="brand" label="Brand Name" />
                                            <div></div>
                                            <Select name="unit_of_measurement_id" options="uom" label="UoM" />
                                            <Select label="Color" name="color_id" options="color" />
                                        </FieldsContainer>

                                        <FieldsContainer title="Fee Information">
                                            <InputNum name="hpp" label="HPP" />
                                            <InputNum name="material_price" label="Material Price" />
                                            <div></div>
                                            <InputNum name="sales_price" label="Sales Price" />
                                            <InputNum name="comission" label="Commission (%)" />
                                        </FieldsContainer>

                                        <FieldsContainer title="Variant Information">
                                            <Select name="variant_id" options="variant" label="Variant Name" getData={handleVariant} />
                                            <Select name="variant_type_id" options={subVariant} label="Variant value" getData={handleVariantValue} />

                                            {/* <SelectData label="" name="" options="variant" handleChange={handleVariant} /> */}
                                            {/* <Select name="variant_type_id" label="Variant Value" options={subVariant} /> */}
                                            <div className="field-container">
                                                <Button
                                                    handleClick={handleSubmitVariant}
                                                    theme="Grey"
                                                    loading={loadingBarcode}
                                                    icon="plus"
                                                />
                                            </div>
                                        </FieldsContainer>

                                        {variantList.length > 0 && <TableForm setVariantList={setVariantList} data={variantList} />}
                                        <br />
                                        {/* <TableVariant /> */}

                                        <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                    </Form>
                                )


                            }}
                        </Formik>)
                        : <LoadingContent />
                }

            </>
        </ContentWrapper >
    )
}