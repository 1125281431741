/*
    implemented on :
        - Invoice
        - sales return
*/

import { money, globalMessage, globalDialog } from '@/Helper'
import Button from 'Elements/Button'
import { useDispatch } from 'react-redux';
import SelectData from 'Elements/SelectData';
import SelectAsync from 'Elements/SelectAsync';
import React, { useState, useEffect } from 'react'
import InputNumber from 'Elements/inputnumber/InputNumber'
import { loadUnit } from 'Reduxes/inventory/configuration/unit/unitActions'
import ScanProduct from 'Elements/ScanProduct'

export default function TableProduct(props) {
    const dispatch = useDispatch();

    /*
    PROPS
    =====================
    . saveButton = get the data on table product by button
    */
    const { moduleType, saveButton = false, defaultData, showTotal = true, columns, handlechange, dataErrStatus, requireContact = true, portal, isConsignment } = props;

    const [rows, setRows] = useState([{}])
    const [subTotal, setSubtotal] = useState(0)
    const [discount, setDiscount] = useState(0)
    const [unitOption, setUnitOption] = useState([]);
    const [showForm, setShowForm] = useState(true);
    const updateShowForm = async () => {
        await setShowForm(false);
        setTimeout(() => {
            setShowForm(true);
        }, 0);
        setShowForm(true);
    };

    // nyari mana yg ada input biar
    // dipasang ke rows biar bisa ntar di assign
    // default value untuk input di td nya

    useEffect(() => {
        if (Array.isArray(defaultData)) {
            if (defaultData.length > 0) {
                setRows(defaultData)
            }
        } else {
            const obj = columns.filter((col, colIdx) => col.type === 'input')
            const objTrans = {}
            obj.forEach(element => {
                objTrans[element.name] = element.value
            });
            setRows([{ ...objTrans }])
        }

        const fetchUnit = async () => {
            const units = (await dispatch(loadUnit())).data;

            setUnitOption(units);
        }

        fetchUnit();
    }, [])

    /**
     * Watch default data
     * Refill rows if default data changed
     */

    useEffect(() => {
        updateShowForm()
    }, [rows])

    useEffect(() => {
        if (Array.isArray(defaultData)) {
            if (defaultData.length > 0) {
                setRows(defaultData)
            }
        }
    }, [defaultData]);

    useEffect(() => {
        let resultSubTotal = 0;
        let resultDiscount = 0;

        rows.forEach((row, rowIdx) => {
            resultSubTotal += getSubtotal(rowIdx)
            if (moduleType === 'invoice') {
                resultDiscount += parseInt(((row.sales_price * row.comission) / 100) * (row.qty * row.uom_value) || 0)
            } else {
                const discount_value = (((rows[rowIdx]?.discount ?? 0) * 100) / (rows[rowIdx].sales_price ?? 0))
                resultDiscount += parseInt(((row.sales_price * (row?.comission ?? discount_value)) / 100) * (row.quantity * row.uom_value) || 0)
            }
        })

        setSubtotal(resultSubTotal)
        setDiscount(resultDiscount)

        if (handlechange) {
            handlechange(rows)
        }
    }, [rows])

    const removeRow = index => {
        globalDialog(() => setRows(rows.filter((_, rowIdx) => index !== rowIdx)))
    }
    const selectProduct = ({ props, row }) => {
        /*  
            handle no duplicate entry
            ini mulai di implementasi di pos - sales
        */
        const isDuplicate = rows.find(r => r?.code === row?.code);

        if (isDuplicate) {
            globalMessage(
                'Product has been added already',
                'warning',
            )
        } else {
            const newVal = rows.map((rowState, rowIdx) => {
                return rowIdx === props.index ? { ...rowState, ...row } : rowState
            })

            if (moduleType === 'invoice' || moduleType === 'sales_order' || moduleType === 'salesreturn') {
                if (newVal[0].variant_type_value) {
                    newVal.map(val => {
                        if (generalizeValue(val)) {
                            val.name = `${val.name} ${val.color_name} (${val.variant_type_value})`
                        }
                        return val
                    })
                } else {
                    newVal.map(val => {
                        if (generalizeValue(val)) {
                            val.name = `${val.name} ${val.color_name}`
                        }
                        return val
                    })
                }

            }

            setRows(newVal)
        }
    }

    const generalizeValue = (val, hasVariant) => {
        if (hasVariant) {
            return val.name && val.color_name && val.variant_type_value
        }

        return val.name && val.color_name
    }

    const addRow = () => {
        setRows([...rows, {}])
    }
    const generateTheadRow = e => {
        return columns.map((column, columnIdx) => {
            return (
                <React.Fragment key={columnIdx}>
                    {columnIdx < 1 && <th></th>}
                    <th key={columnIdx}>{column.label}</th>
                </React.Fragment>
            )
        })
    }
    const handleInputChange = (args) => {
        const { name, index, value } = args;

        const newRows = rows.map((row, rowIdx) => {
            if (rowIdx === index) {
                row[name] = value;

                if (moduleType === 'invoice') {
                    if (index === rowIdx) {
                        row.remain_qty = (row.consignment_rest_of_qty - value) || 0;
                        
                        row.discount_total = row.discount_consigment * value;
                    }
                }

                if (name === 'uom_id') {
                    unitOption.map((unit) => {
                        if (unit.id === value) {
                            row.uom_value = unit.value;
                        }
                    });
                }
            }
            return row;
        })

        setRows([...newRows]);
    }
    const generateTd = (args) => {
        const { name, label, type, className = '', colIdx, rowIdx } = args;
        const val = rows[rowIdx][name];
        
        let TdContent;

        const tdProp = {
            key: `td${colIdx}${rowIdx}`,
            className: `${name} ${className}`,
            id: `td${colIdx}${rowIdx}`
        }

        if (name === 'code') {
            const rawColumn = columns.find(col => col.name === name);
            const extraParam = rawColumn.param
            TdContent = showForm && <SelectAsync
                type="productCode"
                portal={portal ? true : false}
                name="itdoesntmatterwhatsthename"
                cache={false}
                {...(extraParam ? { param: extraParam } : {})}
                {...(val ? { value: { label: val, value: val } } : {})}
                index={rowIdx}
                options={moduleType}
                handleChange={selectProduct}
            />
        } else if (name === 'name') {
            // cari jika ada param tambahan
            const rawColumn = columns.find(col => col.name === name);
            const extraParam = rawColumn.param
            if (!requireContact) {
                delete extraParam['contact_id']
            }

            if (isConsignment) {
                extraParam['consignment'] = 1
            }

            TdContent = showForm && <SelectAsync
                type="productName"
                portal={portal ? true : false}
                name="itdoesntmatterwhatsthename"
                cache={false}
                {...(extraParam ? { param: extraParam } : {})}
                {...(val ? { value: { label: val, value: val } } : {})}
                index={rowIdx}
                options={moduleType}
                handleChange={selectProduct}
            />
        } else if (name === 'product_name') {
            TdContent = rows[rowIdx]['name']
        } else if (type === 'inputNumber') {
            if (moduleType === 'reject') {

                // loop sebanyak vairnt item atau hanya 1 saja jika tidak punya variant
                const variants = rows[rowIdx].product_variant_item && rows[rowIdx].product_variant_item.length ? rows[rowIdx].product_variant_item : [1]
                TdContent = variants.map(el => {
                    return (
                        <InputNumber
                            name={name}
                            value={22}
                            handleChange={(value) => {
                                handleInputChange({
                                    name,
                                    index: rowIdx,
                                    value: parseInt(value)
                                })
                            }}
                        />
                    )
                })


            } else {
                TdContent = <InputNumber
                    name={name}
                    value={val}
                    handleChange={(value) => {
                        handleInputChange({
                            name,
                            index: rowIdx,
                            value: parseInt(value)
                        })
                    }}
                />
            }
        } else if (name === 'disc') {
            const discount_value = (((rows[rowIdx]?.discount ?? 0) * 100) / (rows[rowIdx].sales_price ?? 0))
            TdContent = Object.keys(rows[rowIdx]).length ? (
                rows[rowIdx].comission
                    ? `${rows[rowIdx].comission}%`
                    : isNaN(discount_value) ? 0 : `${discount_value}%`) : 0;
        } else if (name === 'uom_id') {
            if (type === 'selectData') {
                TdContent = <SelectData
                    options="uom"
                    name={`${name}${rowIdx}`}
                    value={val}
                    handleChange={(value) => {
                        handleInputChange({
                            name,
                            index: rowIdx,
                            value: +value
                        })
                    }}
                />
            }
        } else if (name === 'total') {
            TdContent = money(getTotal(rowIdx));
        } else if (name === 'sales_price') {
            TdContent = `IDR ${money(val)}`;
        } else if (name === 'disc_value') {
            if (moduleType === 'invoice') {
                TdContent = `IDR ${money(rows[rowIdx]?.discount_total ?? 0)}`;
            } else {
                TdContent = `IDR ${money(val)}`;
            }
        } else if (name === 'uom_name') {
            TdContent = val ? `${val} ${'uom_value' in rows[rowIdx] ? `(${rows[rowIdx].uom_value})` : ''}` : ''
        } else if (name === 'consignment_rest_of_qty') {
            let v = rows[rowIdx]?.remain_qty || val || 0
            TdContent = <span className={v < 0 ? 'text-red' : ''}>{v}</span>;
        } else {
            TdContent = val;
        }

        return <td {...tdProp}>{TdContent}</td>
    }
    const generateTbodyRow = e => {
        return rows.map((row, rowIdx) => {
            return (
                <tr key={rowIdx}>
                    <td className="btn-action">
                        <div>
                            {(rowIdx > 0 || rows.length > 1) && <Button style="TableproductGrey" icon="trash" onClick={() => removeRow(rowIdx)} />}
                            {(rowIdx === rows.length - 1) && <Button style="TableproductGreen" icon="plus" onClick={addRow} />}
                        </div>
                    </td>
                    {
                        columns.map((column, colIdx) => generateTd({ ...column, rowIdx, colIdx }))
                    }
                </tr>
            )
        })
    }

    // BELUM PAKE PENERAPAN PERKALIAN UOM VALUE (KODI, LUSIN, PACK)
    const getSubtotal = (index) => {
        let result;
        const qty = parseInt(rows[index]['qty'] || rows[index]['quantity']) || 0;
        const unitValue = rows[index]['uom_value'] || 0;
        const price = rows[index]['sales_price'] || 0;
        result = (qty * unitValue) * price

        return result
    }
    const getTotal = (index) => {
        let result;

        if (moduleType === 'invoice') {
            const qty = parseInt(rows[index]['qty']) || 0;
            const unitValue = rows[index]['uom_value'] || 0;
            const price = rows[index]['sales_price'] || 0;
            const discount = rows[index]['comission'] || 0;

            result = (qty * unitValue) * (price - ((price * discount) / 100));
        } else if (moduleType === 'sales_order') {
            const qty = parseInt(rows[index]['quantity']) || 0;
            const unitValue = rows[index]['uom_value'] || 0;
            const price = rows[index]['sales_price'] || 0;
            const discount = rows[index]['comission'] || 0;

            result = (qty * unitValue) * (price - ((price * discount) / 100));
        } else if (moduleType === 'salesreturn') {
            const qty = parseInt(rows[index]['quantity']) || 0;
            const unitValue = rows[index]['uom_value'] || 0;
            const price = rows[index]['sales_price'] || 0;
            const discount = rows[index]['comission'] || 0;

            result = (qty * unitValue) * (price - ((price * discount) / 100));
        }

        return result;
    }
    const handleSaveProducts = () => {
        const cleanRows = rows.map(r => r.id ? r : null).filter(Boolean)
        saveButton(cleanRows);
    }
    //handle scanner
    const handleScannerProduct = val => {
        if (val.status) {
            if (val.data.length > 0) {
                const prods = val.data.map(v => {
                    const productisDouble = rows.find(p => p.code === v.code);
                    if (productisDouble) {
                        const productisDoubleIdx = rows.findIndex(x => x.code === productisDouble.code)
                        rows[productisDoubleIdx].qty = +rows[productisDoubleIdx].qty + 1;
                        return null;
                    }
                    v.qty = 1;
                    return v;
                }).filter(Boolean);

                return prods
            } else {
                dataErrStatus(true)
                return []
            }
        } else {
            dataErrStatus(true)
            return []
        }
    }
    const actionScannerProduct = (handle) => {
        const mergeHandle = [...handle, [{}]]

        if (rows.length === 1) {
            setRows(mergeHandle)

        } else {
            const newRows = rows.splice(0, rows.length - 1)
            const mergeRows = [...newRows, ...mergeHandle]
            setRows(mergeRows)
        }
    }



    return (
        <div className="table-product">
            <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} />
            <table>
                <thead>
                    <tr>
                        {generateTheadRow()}
                    </tr>
                </thead>
                <tbody>
                    {generateTbodyRow()}
                </tbody>
            </table>
            {
                rows.length > 0 && Object.keys(rows[0]).length > 0 && showTotal &&
                <div className="total-container">
                    <p>
                        <span>Sub Total</span>
                        <span>IDR {money(subTotal)}</span>
                    </p>
                    <p>
                        <span>Discount</span>
                        <span>IDR {money(discount)}</span>
                    </p>
                    <p>
                        <span>Total</span>
                        <span>IDR {money(subTotal - discount)}</span>
                    </p>
                </div>
            }
            {
                saveButton &&
                <Button
                    label="Add Product"
                    icon="plus"
                    style="WideRectOrange"
                    onClick={handleSaveProducts}
                />
            }
        </div>
    )
}