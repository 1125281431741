import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl, handleRequest } from 'Configs/request'
export const EDC_SET = 'EDC_SET'
export const EDC_ERROR = 'EDC_ERROR'
export const EDC_ADD = 'EDC_ADD'
export const EDC_SHOW = 'EDC_SHOW'
export const EDC_EDIT = 'EDC_EDIT'
export const EDC_UPDATE = 'EDC_UPDATE'
export const EDC_DELETE = 'EDC_DELETE'
export const EDC_LOADING = 'EDC_LOADING'
export const EDC_RESET = 'EDC_RESET'
export const EDC_CONFIRM = 'EDC_CONFIRM'
const type = 'EDC'
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const addEdc = data => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/edc`, data),
        next: (payload) => dispatch({
            type: EDC_ADD,
            payload
        })
    })
}
export const loadEdc = () => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/edc`),
        next: (payload) => dispatch({ type: EDC_SET, payload })
    })
}
export const updateEdc = (idEdit, data) => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/edc/${idEdit}`, data),
        next: (payload) => dispatch({ type: EDC_UPDATE, payload })
    })
}
export const setEditEdc = idEdit => {
    return {
        type: EDC_EDIT,
        payload: idEdit,
    }
}
export const showEdc = idx => {
    return {
        type: EDC_SHOW,
        payload: idx
    }
}
export const showEdcEdit = idShow => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/edc`, idShow),
        next: () => dispatch({ type: EDC_SHOW, payload: idShow })
    })
}
export const deleteEdc = id => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${online ? hostUrl : offlineUrl}/api/edc/`, id),
        next: () => dispatch({ type: EDC_DELETE, payload: id })
    })
}
export const resetStatusEdc = () => {
    return {
        type: EDC_RESET
    }
}
export const updateStatusEdc = (id, type) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/edc/${id}/${type}`),
        next: () => dispatch({ type: EDC_CONFIRM, payload: { type, id } })
    })
}
