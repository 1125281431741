import moment from 'moment'
import Table from 'Elements/Table'
import Button from 'Elements/Button'
import Alert from 'Comps/elements/Alert'
import { tableOpts } from 'Configs/table'
import { validationSchema } from './Validation'
import React, { useEffect, useState } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingContent from 'Elements/LoadingContent';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Divider, FormGroup, FormLabel, Grid, IconButton, Popover, TextField, Tooltip } from '@material-ui/core'
import { deleteLogActivity, resetStatusLogActivity, setLogActivityAnchor, filterLogActivity } from '../../../reduxes/configuration/activitylog/logActivityActions'
import Permission from "../../../comps/permission/Permission";

/** Put custom style here */
const useStyles = makeStyles(theme => ({
    table: {
        '& .row-action': {
            color: '#555',
            fontSize: '16px',
            marginRight: '8px',
            cursor: 'pointer'
        }
    },
}));


export default function TableData(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const divRef = React.useRef();
    const { data, loading, error, success, deletePopoverAnchor } = useSelector(state => state.logActivityReducer);
    const showDeletePopover = Boolean(deletePopoverAnchor);
    const [showFilterPopover, setShowFilterPopover] = useState(false);
    const [filterDate, setFilterDate] = useState(moment().format('Y-M-DD'));
    const [isFiltering, setIsFiltering] = useState(false);

    /**
     * Close delete popover
     *
     * @param {Function} setFieldValue
     */
    const handleCloseDeletePopover = (setFieldValue, resetForm) => {
        resetForm()
        dispatch(setLogActivityAnchor(null));

        setFieldValue('date_from', '');
        setFieldValue('date_to', '');
    }

    /**
     * Close filter popover
     *
     * @param {Function} setFieldValue
     */
    const handleCloseFilterPopover = () => {
        setShowFilterPopover(false);
    }

    /**
     * Open delete popover
     *
     * @param {Event} event
     */
    const handleOpenDeletePopover = () => {
        dispatch(setLogActivityAnchor(divRef.current));
    }

    /**
     * Open filter popover
     *
     * @param {Event} event
     */
    const handleOpenFilterPopover = (event) => {
        setShowFilterPopover(true);
    }

    /** Watch delete popover anchor */
    useEffect(() => {
        // Put something here if needed
    }, [deletePopoverAnchor])

    const formOpts = {
        initialValues: {
            date_from: '',
            date_to: '',
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm, setFieldValue }) => {
            await dispatch(deleteLogActivity({ ...values }));

            handleCloseDeletePopover(setFieldValue, resetForm);

            await dispatch(filterLogActivity({ date: filterDate }))
        }
    }

    /**
     * Setting for table props
     */
    const table = tableOpts({
        data,
        colIdPosition: 0,
        filter: false,
        filename: "User_Log_Activity",
        showCheckbox: false,
        noPrint: true,
        customToolbarItems: () => {
            return (
                <>
                    <Permission
                        component={
                            <Tooltip title="Delete">
                                <IconButton aria-describedby="delete-log-popover" onClick={handleOpenDeletePopover}>
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        }
                        subMenu="log"
                        type="delete"
                    />

                    <Tooltip title="Filter">
                        <IconButton aria-describedby="delete-log-popover" onClick={handleOpenFilterPopover}>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )
        },
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        filter: false,
                        display: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                {
                    name: "created_at",
                    label: 'Date/Time',
                    options: {
                        filter: false,
                        customBodyRender: (value, tableMeta) => {
                            return moment(value).format('DD-M-Y h:mm:ss')
                        }
                    }
                },
                {
                    name: "module",
                    label: 'Module',
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "activity",
                    label: 'Activity',
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "branch",
                    label: 'Branch',
                    options: {
                        filter: false,
                    }
                },
                {
                    name: "user",
                    label: 'User',
                    options: {
                        filter: false,
                    }
                },
            ],
        },
    })


    return (
        <>
            <div ref={divRef}></div>

            <Formik {...formOpts} enableReinitialize={true}>
                {(formik) => {
                    const { isSubmitting, setFieldValue, resetForm } = formik;

                    return (
                        <Popover
                            id="delete-log-popover"
                            anchorEl={deletePopoverAnchor}
                            open={showDeletePopover}
                            onClose={() => {
                                handleCloseDeletePopover(setFieldValue, resetForm)
                            }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <div style={{ padding: '20px' }}>
                                <Form className="form-layout-1">
                                    <h4 style={{ marginBottom: '10px', fontFamily: 'opensans' }}>
                                        Delete Log
                                    </h4>

                                    <Divider component="div" />

                                    <div style={{ marginTop: '20px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='From'
                                                    name="date_from"
                                                    variant="outlined"
                                                    onChange={
                                                        (event) => {
                                                            setFieldValue('date_from', event.target.value);
                                                        }
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="date"
                                                />

                                                <ErrorMessage name="date_from" component="div" className="errMsg" />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label='To'
                                                    name="date_to"
                                                    variant="outlined"
                                                    onChange={
                                                        (event) => {
                                                            setFieldValue('date_to', event.target.value);
                                                        }
                                                    }
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    type="date"
                                                />

                                                <ErrorMessage name="date_to" component="div" className="errMsg" />
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Form>

                                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                                    <div style={{ marginRight: '10px' }}>
                                        <Button
                                            type="button"
                                            style="OrangeMdWideRect"
                                            label={isSubmitting ? 'Deleting' : 'Delete'}
                                            icon={isSubmitting ? 'spinner' : 'trash'}
                                            disabled={isSubmitting}
                                            onClick={
                                                ({ handleSubmit }) => {
                                                    handleSubmit()
                                                }
                                            }
                                        />
                                    </div>

                                    <button
                                        type="button"
                                        className="btn btn-md btn-rect"
                                        onClick={() => {
                                            handleCloseDeletePopover(setFieldValue, resetForm)
                                        }}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Popover>
                    )
                }}
            </Formik>

            <Popover
                id="filter-log-popover"
                anchorEl={divRef.current}
                open={showFilterPopover}
                onClose={() => {
                    handleCloseFilterPopover()
                }}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <div style={{ padding: '20px' }}>
                    <form className="form-layout-1">
                        <h4 style={{ marginBottom: '10px', fontFamily: 'opensans' }}>
                            Filter
                        </h4>

                        <Divider component="div" />

                        <div style={{ marginTop: '20px' }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        label='Date'
                                        value={filterDate}
                                        onChange={event => {
                                            setFilterDate(event.target.value);
                                        }}
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        type="date"
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </form>

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                        <div style={{ marginRight: '10px' }}>
                            <Button
                                type="button"
                                style="OrangeMdWideRect"
                                label={isFiltering ? 'Filtering' : 'Filter'}
                                icon={isFiltering ? 'spinner' : ''}
                                disabled={isFiltering}
                                onClick={
                                    (event) => {
                                        dispatch(filterLogActivity({ date: filterDate }))
                                    }
                                }
                            ></Button>
                        </div>

                        <button
                            type="button"
                            className="btn btn-md btn-rect"
                            onClick={() => {
                                handleCloseFilterPopover()
                            }}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </Popover>

            {
                success &&
                <Alert hide={() => dispatch(resetStatusLogActivity())} option={{ type: 'success', message: success }} />
            }

            {
                error && <Alert hide={() => dispatch(resetStatusLogActivity())} option={{ type: 'error', message: error }} />
            }

            {
                loading ? <LoadingContent />
                    : (
                        <Table {...table} className={classes.table} />
                    )
            }
        </>
    )
}

