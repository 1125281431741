import React, { } from 'react'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

export default function SimpleBarHOC({ children, status }) {


    return status ? (
        <SimpleBar className={`table-sticky more-height mb-24`}>
            {children}
        </SimpleBar>
    ) : (
        <div className="scroll-x">{children}</div>
    )
}
