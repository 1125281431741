import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';


export default function SimpleModal({ isOpen, customClass, title, width, children, setIsOpen }) {

    function getModalStyle() {
        return {
            top: `${(width && width > 400) ? 10 : 20}%`,
            left: `50%`,
            transform: `translate(-50%)`,
        };
    }
    const useStyles = makeStyles((theme) => ({
        paper: {
            position: 'absolute',
            width: width || 400,
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[5],
            borderRadius: '3px',
            padding: theme.spacing(3, 3, 3),
        },
    }));
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [open, setOpen] = useState(isOpen);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        console.loc(123)
        setOpen(false);
    };
    const TitleEl = () => (
        <div className="modal-title">
            <p>{title}</p>
            <CloseIcon onClick={() => setOpen(false)} />
        </div>
    )

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen])
    useEffect(() => {
        setIsOpen(open)
    }, [open])

    return (
        <div>
            <Modal
                open={open}
                onClose={() => setIsOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <div style={modalStyle} className={classes.paper + ' ' + (customClass || '')}>
                    {
                        title && <TitleEl />
                    }
                    {children}
                </div>
            </Modal>
        </div>
    );
}
