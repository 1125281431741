import React, { useState, useEffect } from "react";
import ContentWrapper from "Comps/container/ContentWrapper";
import { money, dateDMY } from '@/Helper'
import { hostUrl, reqPatch, reqGet } from 'Configs/request'
import {
  loadDelivery,
  updateStatus,
} from "Reduxes/inventory/delivery/deliveryActions";
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import { useDispatch } from "react-redux";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import KopSurat from 'Elements/KopSurat'
import ScanProduct from 'Elements/ScanProduct'
import Alert from 'Comps/elements/Alert'
import { getDeliveryById } from "../../../reduxes/inventory/delivery/deliveryActions";


function Detail(props) {
  const { showTable, rowId, showDetail } = props;
  const [dataErr, setDataErr] = useState(false)
  const [errors, setErrors] = useState({})
  const [showPartial, setShowPartial] = useState(false);
  const [loading, setLoading] = useState(false)
  const [qtySent, setQtySent] = useState(0)
  const [data, setData] = useState({
    type: '',
    branch_name: '',
    destination_name: '',
    destination_address: '',
    date: dateDMY(new Date()),
    reference: '',
    status: ''
  })
  const [dataEdit, setDataEdit] = useState();
  const [buttonToggler, setButtonToggler] = useState(true)

  const dispatch = useDispatch();
  
  useEffect(() => {
    /** Fetch data delivery by id */
     const fetchDelivery = async () => {
      const resp = await dispatch(getDeliveryById(rowId))

      if (resp.status) {
        setData(resp.data)
        setDataEdit(resp.data)
      }
    }


    fetchDelivery()
  }, [])

  useEffect(() => {
    const fetchDelivery = async () => {
      const resp = await dispatch(getDeliveryById(rowId))

      if (resp.status) {
        setData(resp.data)
        setDataEdit(resp.data)
      }
    }

    fetchDelivery()
  }, [buttonToggler])

  const detailHeader = !data ? {} : {
    type: data.type === 'internal_transfer' ? 'Internal Transfer' : data.type.charAt(0).toUpperCase() + data.type.slice(1),
    'branch name': data.branch_name,
    'destination': data.destination_name,
    'destination address': data.destination_address,
    date: dateDMY(data.date),
    reference: data.reference,
    status: data.status,
    'Created By': data?.created_by_contact_name
  }

  const isBackorder = data.reference.includes('Backorder')
  const isBackorderReceivement = data.reference.includes('Backorder RCV')
  const isOpen = data.status === 'open'
  const isInternalTransfer = data.type === 'internal_transfer'
  const isSalesOrder = data.type === 'consigment'

  
  const makeStatusButton = (type, label, permissionType) => {
    return {
      label: label,
      style: "TileMd",
      marginLeft: "ml-10",
      ...(loading && { icon: 'spinner' }),
      onClick: async () => {
        setLoading(true)
        setTimeout(async () => {
          if (type === "full") {
            setShowPartial(false);
            const req = await dispatch(updateStatus(data.id, "waiting"));
            if (req.status) {
              setButtonToggler(!buttonToggler)
              setLoading(false)
            } else {
              setErrors({
                status: true,
                message: req.data.errors,
              })
            }
            setLoading(false)
          } else if (type === 'draft') {
            const req = await dispatch(updateStatus(data.id, 'draft'))
            if (req.status) {
              setButtonToggler(!buttonToggler)
              setLoading(false)
            }
          } else if (type === "partial") {
            if (!showPartial) {
              setShowPartial(true);
              setLoading(false)
            }
          } else if (type === 'cancel') {
            const req = await dispatch(updateStatus(data.id, 'cancel'))
            if (req.status) {
              setButtonToggler(!buttonToggler)
              setLoading(false)
            }
          } else if (label === "Cancel") {
            setShowPartial(false)
            setLoading(false)
            setDataEdit({
              ...dataEdit,
              product_shipment_item: dataEdit.product_shipment_item.map((dE) => {
                dE.qty_shipment = 0;
                return dE;
              }),
            });
          } else if (type === "confirmed" && label === "Reverse") {
            let cleanVal = {
              type: data.type,
              contact_id: data.contact_id,
              branch_id: data.branch_id,
              date: data.date,
              note: data.note,
              reference: data.reference,
              status: "confirmed",
              product_shipment_item: data.product_shipment_item.map((x) => {
                
                return {
                  product_id: x["product_id"],
                  type: x["type"],
                  variant_id: x["variant_id"],
                  price: x["price"],
                  qty: x["qty"],
                  qty_shipment: x["qty_shipment"],
                  uom_id: x["uom_id"],
                  product_location: x["product_location"],
                  variant_type_id: x["variant_type_id"],
                };
              }),
            };
            const req = await reqPatch(`${hostUrl}/api/product_shipment/${data.id}`, cleanVal)
            if (req.status === 'success') {
              setLoading(false)
              setShowPartial(false)

              // refetch after reverse shipment
              const reFetch = await reqGet(`${hostUrl}/api/product_shipment/${rowId}`)
              if (reFetch.status) {
                setData(reFetch.data)
                setDataEdit(reFetch.data)
              }

              // dispatch({ type: 'DELIVERY_UPDATE', payload: req.data })
              // showTable()
              // showDetail(rowId)
            } else {
              setErrors({
                status: true,
                message: req.errors,
              })
            }
            setShowPartial(false)
            setLoading(false)
          } else {
            setShowPartial(false);
            const req = await dispatch(updateStatus(data.id, type));
            if (req.status) {
              setButtonToggler(!buttonToggler)
              setLoading(false)
            }
            setLoading(false)
          }
        }, 500)
      },
      type: permissionType,
      subMenu: 'delivery'
    };
  };

  const handlePartial = async () => {
    setLoading(true)
    let cleanVal = {
      type: data.type,
      contact_id: data.contact_id,
      branch_id: data.branch_id,
      date: data.date,
      note: data.note,
      reference: data.reference,
      status: "waiting",
      product_shipment_item: data.product_shipment_item.map((x) => {
        
        return {
          product_id: x["product_id"],
          type: x["type"],
          variant_id: x["variant_id"],
          price: x["price"],
          qty: x["qty"],
          qty_shipment: x["qty_shipment"] || 0,
          uom_id: x["uom_id"],
          product_location: x["product_location"],
          variant_type_id: x["variant_type_id"],
        };
      }),
    };
    
    const req = await reqPatch(`${hostUrl}/api/product_shipment/${rowId}/partial`, cleanVal)

    
    if (req.status === 'success') {
      setLoading(false)
      setShowPartial(false)
      dispatch({ type: 'DELIVERY_UPDATE', payload: req.data })
    } else {
      setErrors({
        status: true,
        message: req.errors,
      })
    }
    setLoading(false)


  };

  const handleQtySentChange = (event, idx) => {
    let val = parseInt(event.target.value);
    setQtySent(val)
    setDataEdit({
      ...dataEdit,
      product_shipment_item: dataEdit.product_shipment_item.map((dE, dEIdx) => {
        if (idx === dEIdx) {
          dE.qty_shipment = val;
        }
        return dE;
      }),
    });
  };



  const handleScannerProduct = (value) => {

    if (showPartial) {

      const productIdx = data.product_shipment_item.findIndex(x => x.barcode === value || x.product_code === value)
      const foundData = data.product_shipment_item.find(x => x.barcode === value || x.product_code === value);

      if (foundData) {
        setDataEdit({
          ...dataEdit,
          product_shipment_item: dataEdit.product_shipment_item.map((dE, dEIdx) => {
            if (productIdx === dEIdx) {
              dE.qty_shipment += 1;
            }
            return dE;
          }),
        });
      } else {
        setDataErr(true)
      }
    }
    return value
  }

  const actionScannerProduct = (handle) => {
  }

  return (
    <PrintProvider>
      <Print single name="print-delivery-detail-1" >
        <div className="print-report zoom-70">
          <KopSurat title="Delivery" nomor={`${data.code || 'N/a'}`} />
          {/* KALO STATUS DRAFT, PILIHANNYA OPEN -> CONFRIMED */}
          {/* KALO STATUS OPEN -> CONFIRMED, PILIHANNYA DRAFT -> REVERSE DAN CONFRIMED -> APPROVE*/}
          {/* KALO STATUS CONFIRMED, PILIHANNYA OPEN -> REVERSE, PARTIAL SHIPMENT dan FULL SHIPMENT */}
          {/* KALO STATUS PARTIAL SHIPMENT, OPEN -> CANCEL */}
          {/* KALO STATUS FULL SHIPMENT, WAITING*/}

          <ContentWrapper
            title="Delivery Detail"
            subtitle={`Showing receipts product with id ${data.code || "N/a"
              }`}
            buttons={[
              {
                label: "Go back",
                style: "WhiteMd",
                icon: "arrow-left",
                onClick: () => {
                  dispatch(loadDelivery({
                    page: 0,
                    limit: 10
                  }));
                  showTable()
                },
              },
              data.status === "draft" ? makeStatusButton("open", "Confirmed", 'create') : {},
              data.status === "open" && data.reference.split(' ')[0] !== 'Backorder' ? makeStatusButton("draft", "Reject", 'confirm') : {},
              data.status === "open"
                ? makeStatusButton("confirmed", "Approve", 'confirm')
                : {},
              (isBackorder && isOpen) ? makeStatusButton("cancel", "Cancel", 'confirm') : {},
              data.status === "confirmed" && !showPartial && !isSalesOrder ? makeStatusButton("open", "Reverse", 'create') : {},
              data.status === "confirmed" && !showPartial
                ? makeStatusButton("partial", "Partial Shipment", 'create')
                : {},
              data.status === 'confirmed' && showPartial ? makeStatusButton('confirmed', 'Cancel', 'create') : {},
              data.status === "confirmed" && !showPartial
                ? makeStatusButton("full", "Full Shipment", 'create')
                : {},
              data.status === "waiting"
                ? makeStatusButton("confirmed", "Reverse", 'confirm')
                : {},
              data.status === "waiting" && data.type !== "internal_transfer" && makeStatusButton("done", "Done", 'create'),
              data.status === "full" ? makeStatusButton("waiting", "Waiting", 'create') : {},
              data.status === 'confirmed' && showPartial && {
                label: "Save",
                style: "TileMd",
                marginLeft: "ml-10",
                onClick: () => handlePartial(),
              },
              {
                label: "Print",
                style: "DarkblueMd",
                marginLeft: "ml-10",
                icon: "print",
                onClick: () => window.print(),
                subMenu: 'delivery',
                type: 'print',
              },
            ]}
          >
            {
              dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
            }
            {
              errors.status && <Alert hide={() => setErrors({ status: false, message: '' })} option={{ type: 'error', message: errors.message }} />
            }
            <DetailHeader data={detailHeader} />
            <div className="detail-page">

              {
                showPartial ?
                  <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} noUseDisp={true} /> : ''
              }
              <div className="table-container">
                {
                  data.product_shipment_item?.length > 0 && (<table className="table wide-2 table-detail style-2">
                    <thead>
                      <tr>
                        {/* <th>No.</th> */}
                        <th>ID</th>
                        <th>Product Name</th>
                        <th>Variant</th>
                        <th>Product Location</th>
                        <th>Sales price</th>
                        <th>Qty Sent</th>
                        <th>Total Qty Sent Value</th>
                        {isBackorderReceivement && <th>Qty Received</th>}
                        {isBackorderReceivement && <th>Total Qty Received Value</th>}
                        {isBackorderReceivement && <th>Qty Difference</th>}
                        {isBackorderReceivement && <th>Total Qty Difference Value</th>}
                        <th>UoM</th>
                        {
                          showPartial && <th>Qty Sent</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {
                        data.product_shipment_item && data.product_shipment_item.map((product, productIdx) => {
                          return (
                            <tr key={productIdx}>
                              {/* <td>{productIdx + 1}</td> */}
                              <td>{product.barcode || product.product_code}</td>
                              <td>{product.product_name}</td>
                              <td>{product.variant_type_value}</td>
                              <td>{product.product_location}</td>
                              <td>IDR {money(product.price)}</td>
                              {/* QTY SENT <td>{isBackorderReceivement ? product.qty_shipment : product.qty}</td> */}
                              <td>{isBackorderReceivement ? (product.qty_additional_sent ?? product.qty_shipment) : product.qty}</td>
                              <td>IDR {isBackorderReceivement ? money(product.price * (product.qty_additional_sent ?? product.qty_shipment)) : money(product.price * product.qty)}</td>
                              {isBackorderReceivement && <td>{product.qty_additional_received ?? product.qty}</td>}
                              {isBackorderReceivement && <td>IDR {money((product.qty_additional_received ?? product.qty) * product.price)}</td>}
                              {isBackorderReceivement && <td>{product.difference}</td>}
                              {isBackorderReceivement && <td>IDR {money(product.difference * product.price)}</td>}
                              <td>{product.uom_name}</td>
                              {
                                showPartial &&
                                <td>
                                  <input className="input-number"
                                    max={product.qty}
                                    min={0}
                                    value={product.qty_shipment}
                                    type="number"
                                    // disabled={data.type === 'internal_transfer' ? true : false}
                                    onChange={
                                      (e) => handleQtySentChange(e, productIdx)
                                    }
                                  />
                                </td>
                              }
                            </tr>
                          )
                        })
                      }
                      {/* {
                      showPartial &&
                      <div style={{ marginTop: '20px', float: 'right' }}>
                        <button onClick={handlePartial} label="Save Partial" className="button-general" />
                      </div>
                    } */}
                    </tbody>
                  </table>)
                }
              </div>
              <p className="detail-note">**Note : {data.note}</p>
            </div>
            <SignatureColumn typeContent={"All"} data={data} />
          </ContentWrapper>
        </div>
      </Print>
    </PrintProvider>
  );
}

export default Detail;
