import * as _ from 'lodash'
import moment from 'moment'

export const SalesExchangeTransformer = (pos) => {
  if (!pos) return;

  delete pos.created_at;
  delete pos.updated_at;
  delete pos.created_by_contact_id;
  delete pos.created_by_contact_name;
  delete pos.id;
  delete pos?.branch_name;
  delete pos?.type;
  delete pos?.subtotal;
  delete pos?.total_qty;

  pos.transaction_date = moment(new Date()).format('YYYY-MM-DD')

  pos.category = 'return';

  pos.total = -pos.total;
  pos.subtotal = -pos.subtotal;

  pos.note = `Reference to transaction ${pos.code}`

  delete pos.code;

  pos.pos_item = pos.pos_item.map((posItem) => {
    const cloned = _.cloneDeep(posItem);
    
    delete posItem.id;
    delete posItem.created_at;
    delete posItem.updated_at;

    posItem.price = -posItem.price;
    posItem.total = -posItem.total;
    
    Object.assign(posItem, {
      pos_retur_id: cloned.id,
      is_exchange: true,
      is_return_exchange: true,
      category: 'return',
      pos_item_promotion_id: posItem?.promotion?.promotion_id,
    });

    return posItem;
  })

  return pos;
}