import React from 'react'
import { money, showMonth } from '@/Helper'
import NoReport from 'Elements/NoReport'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';


export default function TableDetail({ data: datas, type }) {


    return datas.length < 1 ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '40px' }}>Report Payment Detail by Costumer <span>Showing Details of Payment Report by Costumer</span></p>

            {
                datas.map(data => (
                    <div className="report-pos-detail-table-container">
                        <h5><DeviceHubIcon /> Partner Store  : {data.contact_name}</h5>

                        <div className="report-summary-box">
                            <div className="item teal">
                                <p>
                                    <span>Total Invoice</span>
                                    <span>IDR {money(data.inv_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Total Payment</span>
                                    <span>IDR {money(data.total_payment)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Discount</span>
                                    <span>IDR {money(data.difference)}</span>
                                </p>
                            </div>
                        </div>

                        <div className="report-summary-pill">
                            <p>
                                <span>Invoice:</span>
                                <span>{data.inv_counter}</span>
                            </p>
                            <p>
                                <span>Paid:</span>
                                <span>{data.payment_paid_counter}</span>
                            </p>
                            {
                                type !== 'day' &&
                                <p>
                                    <span>Sales Order:</span>
                                    <span>{data.so_counter}</span>
                                </p>
                            }
                        </div>

                        <table className="table-report">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Date</th>
                                    <th>{type === 'day' ? 'No Payment' : '#Invoice'}</th>
                                    <th className="th-text-right">Total Invoice</th>
                                    <th className="th-text-right">Total Payment</th>
                                    <th className="th-text-right">Difference</th>
                                    {type === 'day' && <th>Status</th>}
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.payments.map((report, index) => (
                                        <tr>
                                            <td>{index + 1}</td>
                                            <td>{type === 'month' ? showMonth(report.date) : report.date}</td>
                                            <td className="td-highlight">{type === 'day' ? report.code : report.invoice_counter}</td>
                                            <td className="td-text-right">IDR {money(report.total_invoice)}</td>
                                            <td className="td-text-right">IDR {money(report.total_payment)}</td>
                                            <td className="td-text-right">IDR {money(report.difference)}</td>
                                            {type === 'day' && <td>{report.status}</td>}
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                ))
            }
        </>
    )
}
