import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = 'REPORT'

export const REPORT_SET = 'REPORT_SET'
export const REPORT_ERROR = 'REPORT_ERROR'
export const REPORT_ADD = 'REPORT_ADD'
export const REPORT_SHOW = 'REPORT_SHOW'
export const REPORT_EDIT = 'REPORT_EDIT'
export const REPORT_UPDATE = 'REPORT_UPDATE'
export const REPORT_DELETE = 'REPORT_DELETE'
export const REPORT_LOADING = 'REPORT_LOADING'
export const REPORT_RESET = 'REPORT_RESET'


export const addReport = data => async (dispatch, getState) => {
    // data.is_active = data.is_active === 'true'
    // return handleReq({
    //     type,
    //     dispatch,
    //     body: () => reqPost(`${hostUrl}/api/product_shipment`, data),
    //     next: (payload) => dispatch({
    //         type: DELIVERY_ADD,
    //         payload
    //     })
    // })
}

export const loadReport = () => async (dispatch, getState) => {
    const fakeData =
    {
        "status": "success",
        "data": [
            {
                'date': 'AAAA',
                'code': '1231241',
                'contact': 'Kontak 1',
                'sub_total': '350000',
                'discount': '40000',
                'total': '1000',
                'status': 'draft',
                'sales_order_products': [
                    {
                        'code': 'TS20-01-001S-BL',
                        'product_name': 'Tshirt Pria 001',
                        'price': '250.000',
                        'discount': '50.000',
                        'quantity': '5',
                        'unit': 'Pc',
                        'total': '1.000.000'
                    },
                    {
                        'code': 'TS20-01-002L-RD',
                        'product_name': 'Tshirt Wanita 002',
                        'price': '150.000',
                        'discount': '50.000',
                        'quantity': '5',
                        'unit': 'Pc',
                        'total': '500.000'
                    }
                ]
            },
            {
                'date': 'BBBB',
                'code': 'zzzzzzz',
                'contact': 'Kontak 2',
                'sub_total': '350000',
                'discount': '40000',
                'total': '1000',
                'status': 'BUKAN DRAFT'
            },
        ],
        "errors": []
    }
    handleReq({
        type,
        dispatch,
        body: () => fakeData,
        // body: () => reqGet(`${hostUrl}/api/product_shipment`),
        next: (payload) => dispatch({ type: REPORT_SET, payload })
    })
}
export const updateReport = (idEdit, data) => async (dispatch, getState) => {
    // return handleReq({
    //     type,
    //     dispatch,
    //     body: () => reqPatch(`${hostUrl}/api/product_shipment/${idEdit}`, data),
    //     next: (payload) => dispatch({ type: DELIVERY_UPDATE, payload })
    // })
}
export const setEditReport = idEdit => {
    return {
        type: REPORT_EDIT,
        payload: idEdit,
    }
}
export const showReport = idx => {
    return {
        type: REPORT_SHOW,
        payload: idx
    }
}
export const deleteReport = id => async (dispatch, getState) => {
    // return handleReq({
    //     type,
    //     dispatch,
    //     body: () => reqDel(`${hostUrl}/api/product_shipment/`, id),
    //     next: () => dispatch({ type: DELIVERY_DELETE, payload: id })
    // })
}
export const resetStatusReport = () => {
    // return {
    //     type: DELIVERY_RESET
    // }
}