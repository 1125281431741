import {
    LOG_ACTIVITY_SET,
    LOG_ACTIVITY_ERROR,
    LOG_ACTIVITY_DELETE,
    LOG_ACTIVITY_LOADING,
    LOG_ACTIVITY_RESET,
    LOG_ACTIVITY_SET_ANCHOR,
} from './logActivityActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    deletePopoverAnchor: null,
}

const branchReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case LOG_ACTIVITY_LOADING:
            return {
                ...state,
                loading: true,
            }
        case LOG_ACTIVITY_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case LOG_ACTIVITY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case LOG_ACTIVITY_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case LOG_ACTIVITY_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                loading: false,
            }
        case LOG_ACTIVITY_SET_ANCHOR:
            return {
                ...state,
                deletePopoverAnchor: payload,
            }
        default:
            return state;
    }
}

export default branchReducer