import React from 'react'
import { money } from '@/Helper'
import NoReport from 'Elements/NoReport'
import { sortReportDate } from 'Configs/report'

export default function GeneralWeekMonth({ data }) {

    let branches = [];
    let variants = [];
    let longestVariant = [];
    let dates = [];

    const { data: x } = {
        "status": "success",
        "data": [
            {
                "id": 1,
                "code": "BR20B-TS001CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Baju Pramuka",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 20000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 10,
                        "reject": 0,
                        "variants": [
                            {
                                "date": 55,
                                "detail_date": "2020-12-28 11:35:09",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 10,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 100002,
                "code": "BR20B-TS002CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Helmet",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 50000,
                "branches": [
                    {
                        "id": 2,
                        "name": "Secondary Office",
                        "stock": 50,
                        "reject": 0,
                        "variants": [
                            {
                                "date": 55,
                                "detail_date": "2020-12-28 11:39:19",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 50,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 100003,
                "code": "BR20B-TS003CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Cardinal",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 250000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 50,
                        "reject": 0,
                        "variants": [
                            {
                                "date": 53,
                                "detail_date": "2020-12-28 11:42:04",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 100004,
                "code": "BR20B-TS004CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Baju Kaws",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 10000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 50,
                        "reject": 0,
                        "variants": [
                            {
                                "date": 53,
                                "detail_date": "2020-12-28 11:44:08",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "errors": []
    }


    const { data: y } = {
        "status": "success",
        "data": [
            {
                "id": 1,
                "code": "BR20B-TS001CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Baju Pramuka",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 20000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 10,
                        "reject": 0,
                        "variants": [
                            {
                                "date": "2020-12",
                                "detail_date": "2020-12-28 11:35:09",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 10,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 100002,
                "code": "BR20B-TS002CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Helmet",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 50000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 50,
                        "reject": 0,
                        "variants": [
                            {
                                "date": "2020-04",
                                "detail_date": "2020-12-28 11:39:19",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 50,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 100003,
                "code": "BR20B-TS003CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Cardinal",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 250000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 50,
                        "reject": 0,
                        "variants": [
                            {
                                "date": "2020-12",
                                "detail_date": "2020-12-28 11:42:04",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "id": 100004,
                "code": "BR20B-TS004CO",
                "category": "T-Shirt",
                "type": "product_sales",
                "name": "Baju Kaws",
                "brand": "EIGER",
                "uom_name": "Piece",
                "sales_price": 10000,
                "branches": [
                    {
                        "id": 1,
                        "name": "Main Office",
                        "stock": 50,
                        "reject": 0,
                        "variants": [
                            {
                                "date": "2020-12",
                                "detail_date": "2020-12-28 11:44:08",
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "types": [
                                            {
                                                "label": "L",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "M",
                                                "stock": 0,
                                                "reject": 0
                                            },
                                            {
                                                "label": "S",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "types": [
                                            {
                                                "label": "40",
                                                "stock": 0,
                                                "reject": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "errors": []
    }


    data.forEach(d => {
        d.branches.forEach((branch, branchIdx) => {
            let isRegistered = branches.find(x => x.id === branch.id)
            if (!isRegistered) {
                branches.push(branch);
                variants = branch.variants[0].variants
            }
            branch.variants.forEach(variant => {
                dates = [...new Set([...dates, variant.date])]
                variant.variants.forEach(vari => {
                    longestVariant = vari.types.length > longestVariant.length ? vari.types : longestVariant;
                })
            })
        })
    })

    /* sorting date ascending */
    if (dates.length) {
        dates = sortReportDate(dates)
    }


    // console.log(data)
    console.log({ data, branches, variants, dates, longestVariant })



    return !branches.length ? <NoReport /> : (
        <>
            <table className="table-report inventory-stock">
                <thead>
                    <tr>
                        <th rowSpan={variants.length + 3}>No.</th>
                        <th rowSpan={variants.length + 3}>ID</th>
                        <th rowSpan={variants.length + 3}>Category</th>
                        <th rowSpan={variants.length + 3}>name</th>
                        <th rowSpan={variants.length + 3}>brand</th>
                        <th rowSpan={variants.length + 3}>sales price</th>
                        {
                            branches.map(branch => {
                                return <th className="cell-tc" colSpan={(longestVariant.length + 2) * dates.length}>{branch.name}</th>
                            })
                        }
                        <th rowSpan={variants.length + 3}>UoM</th>
                    </tr>
                    <tr>
                        {
                            branches.map(branch => {
                                return dates.map(dt => {
                                    return <th className="cell-tc" colSpan={longestVariant.length + 2}>
                                        {typeof dates[0] === 'number' ? 'Week' : ''} {dt}
                                    </th>
                                })
                            })
                        }
                    </tr>
                    <tr>
                        {
                            dates.map(dt => {
                                return branches.map(branch => {
                                    return (
                                        <>
                                            <th className="cell-tc" colSpan={longestVariant.length}>Variant</th>
                                            <th>Stock</th>
                                            <th>reject</th>
                                        </>
                                    )
                                })
                            })
                        }
                    </tr>
                    {
                        variants.map((variant, variantIdx) => {
                            return (
                                <tr>
                                    {
                                        branches.map(branch => (
                                            <>
                                                {
                                                    dates.map(dt => (
                                                        <>
                                                            {
                                                                longestVariant.map((lv, lvIdx) => {
                                                                    return <th>{variant?.types?.[lvIdx]?.label ?? '-'}</th>
                                                                })
                                                            }
                                                            <th></th>
                                                            <th></th>
                                                        </>
                                                    ))
                                                }
                                            </>
                                        ))
                                    }
                                </tr>
                            )
                        })
                    }
                </thead>
                <tbody>
                    {
                        data.map((d, dIdx) => {
                            return (
                                <tr>
                                    <td>{dIdx + 1}.</td>
                                    <td className="cell-width-as-content">{d.code}</td>
                                    <td className="cell-width-as-content">{d.category}</td>
                                    <td className="cell-width-as-content">{d.name}</td>
                                    <td className="cell-width-as-content">{d.brand}</td>
                                    <td className="cell-currency">IDR {money(d.sales_price)}</td>
                                    {
                                        branches.map((branch, branchIdx) => {
                                            return dates.map((dt, dtIdx) => {
                                                return longestVariant.map((lv, lvIdx) => {

                                                    /* :) */
                                                    let qty = 0;
                                                    let findBranch = d.branches.find(x => x.id === branch.id);
                                                    let findDate = findBranch?.variants.find(x => x.date === dt) ?? null

                                                    if (findDate) {
                                                        findDate.variants.forEach(variant => {
                                                            qty += variant?.types?.[lvIdx]?.stock ?? 0;
                                                        })
                                                    }

                                                    return (
                                                        <>
                                                            <td>
                                                                {findDate ? qty : '-'}
                                                            </td>
                                                            {
                                                                longestVariant.length - 1 === lvIdx && (
                                                                    <>
                                                                        <td>{findBranch ? findBranch.stock : '-'}</td>
                                                                        <td>{findBranch ? findBranch.reject : '-'}</td>
                                                                    </>
                                                                )
                                                            }
                                                        </>
                                                    )
                                                })
                                            })
                                        })
                                    }
                                    <td>{d.uom_name}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </>
    )
}
