import React, { useState, useEffect } from 'react'
import NoReport from 'Elements/NoReport'
import { money, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { Helmet } from "react-helmet"
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';
import { NoPrint } from "react-easy-print";

export default function TableGeneral(props) {
    const [scroll, setScroll] = useState(true)
    const [rows, setRows] = useState({})
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            className: 'cell-width-as-content',
            has_sort: false,
        },
        period: {
            label: 'periode',
            has_sort: false,
        },
        promotion_name: {
            label: 'promotion name',
            has_sort: false,
            className: 'cell-width-as-content'
        },
        promotion_type: {
            className: 'cell-width-as-content',
            label: 'type',
            has_sort: false,
        },
        sales: {
            label: 'prod. qty',
            className: 'th-text-center cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        sub_total: {
            label: 'sub total',
            className: 'th-text-center cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        total_discount: {
            label: 'disc',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
        },
        grand_total: {
            label: 'total',
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        hpp: {
            label: 'hpp',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
        additional_cost: {
            label: 'ad. cost',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null
        },
        net_income: {
            label: 'net value',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content'
        },
    })

    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            prevState.pos = sortData({ type: sort[colName].sort_type, column: colName, data: prevState.pos })
            return { ...prevState }
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    const toggleScroll = () => {
        setScroll(!scroll)
    }

    useEffect(() => {
        setRows(props.data)
    }, [props])

    return (
        <>
            {
                rows?.pos?.length < 1 ?
                    <NoReport /> : (
                        <>
                            <div style={{ marginTop: '0px' }}>
                                <Helmet>
                                    <style type="text/css">
                                        {`@page {size: landscape}`}
                                    </style>
                                </Helmet>
                                <div className="print-report zoom-60 print-mt-120">
                                    <div className="report-summary-box mb-16">
                                        <div className="item">
                                            <p>
                                                <span>Total Promotion</span>
                                                <span>{rows.total_promotion}</span>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <p>
                                                <span>Discount</span>
                                                <span>IDR {money(rows.total_discount)}</span>
                                            </p>
                                        </div>
                                    </div>

                                    <NoPrint force={true}>
                                        <div className="table-menu">
                                            <div className="left">
                                                <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                            </div>
                                            <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                        </div>
                                    </NoPrint>

                                    <SimpleBarHOC status={scroll}>
                                        <table className="table-report print-report promotion">
                                            <thead>
                                                <tr>
                                                    {
                                                        Object.keys(sort).map((key, idx) => (
                                                            <ThSort
                                                                key={`th-${idx}`}
                                                                label={sort[key].label}
                                                                sort={sort[key].has_sort}
                                                                handleClick={() => sortColumn(key)}
                                                                className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                            />
                                                        ))
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    rows?.pos?.map((pos, posIdx) => (
                                                        <tr>
                                                            <td>{posIdx + 1}.</td>
                                                            <td className="td-width-as-content">{pos.period || '-'}</td>
                                                            <td className="td-width-as-content">{pos.promotion_name}</td>
                                                            <td>{pos.promotion_type}</td>
                                                            <td>{pos.sales}</td>
                                                            <td className="td-text-right td-currency">IDR {money(pos.sub_total)}</td>
                                                            <td className="td-text-right td-currency">IDR {money(pos.total_discount)}</td>
                                                            <td className="td-text-right td-currency">IDR {money(pos.grand_total)}</td>
                                                            <td className="td-text-right td-currency">IDR {money(pos.hpp)}</td>
                                                            <td className="td-text-right td-currency">IDR {money(pos.additional_cost)}</td>
                                                            <td className="td-text-right td-currency">IDR {money(pos.net_income)}</td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </SimpleBarHOC>
                                </div>
                            </div>
                        </>
                    )
            }
        </>
    )
}
