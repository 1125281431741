import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    name: 
        Yup.string()
            .required('Unit name is required'),
    code:
        Yup.string()
            .required('Unit code is required'),
    value:
        Yup.string()
            .required('Unit value is required')
            .matches(/^\d+$/, 'Unit value should have digits only'),
})

  