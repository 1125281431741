import React, { useState, useEffect } from 'react';
import NoReport from 'Elements/NoReport';
import { money, dateDMY, sortData, handleSortState } from '@/Helper';
import { Helmet } from 'react-helmet';
import KopSurat from 'Elements/KopSurat';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import { NoPrint } from 'react-easy-print';
import ThSort from 'Elements/ThSort';
import SimpleBarHOC from '../../../../comps/elements/SimpleBarHOC';

export default function General({ data }) {
    let variants = null;
    let longestVariant = [];

    if (data.products.length) {
        variants = data.products[0].variants;
        variants.forEach((variant) => {
            if (variant.types.length > longestVariant.length) {
                longestVariant = variant.types;
            }
        });
    }

    const [rows, setRows] = useState([]);
    const [scroll, setScroll] = useState(true);
    const [totalVariant, setTotalVariant] = useState({});
    const [variantSort, setVariantSort] = useState({});
    const [sort, setSort] = useState({
        code: {
            label: 'code',
            sort_type: null,
            has_sort: true,
            rowspan: variants?.length + 1,
        },
        category: {
            has_sort: true,
            sort_type: null,
            rowspan: variants?.length + 1,
            label: 'category',
        },
        type: {
            has_sort: true,
            sort_type: null,
            rowspan: variants?.length + 1,
            label: 'type',
        },
        name: {
            has_sort: true,
            rowspan: variants?.length + 1,
            sort_type: null,
            label: 'name',
        },
        brand: {
            has_sort: true,
            rowspan: variants?.length + 1,
            sort_type: null,
            label: 'brand',
        },
        variant: {
            className: 'th-text-center',
            colspan: longestVariant.length,
            has_sort: false,
            label: 'variant',
        },
        stock: {
            has_sort: true,
            rowspan: variants?.length + 1,
            sort_type: null,
            label: 'stock',
        },
        reject: {
            rowspan: variants?.length + 1,
            sort_type: null,
            has_sort: true,
            label: 'reject',
        },
        uom_name: {
            has_sort: true,
            rowspan: variants?.length + 1,
            sort_type: null,
            label: 'uom',
        },
        sales_price: {
            has_sort: true,
            rowspan: variants?.length + 1,
            className: 'cell-tr',
            sort_type: null,
            label: 'sales price',
        },
        total_value: {
            has_sort: true,
            rowspan: variants?.length + 1,
            className: 'cell-tr',
            sort_type: null,
            label: 'total',
        },
    });
    const sortColumn = (colName, variantIndex = 1000) => {
        if (variantIndex !== 1000) {
            // variamt sort
            setRows((prevState) => {
                return [
                    ...sortData({
                        type: variantSort[`variant-${variantIndex}`].sort_type,
                        column: colName,
                        data: prevState,
                    }),
                ];
            });

            setVariantSort((prev) => {
                return {
                    ...handleSortState({
                        data: prev,
                        column: `variant-${variantIndex}`,
                    }),
                };
            });
        } else {
            setRows((prevState) => {
                return [
                    ...sortData({
                        type: sort[colName].sort_type,
                        column: colName,
                        data: prevState,
                    }),
                ];
            });

            setSort((prev) => {
                return { ...handleSortState({ data: prev, column: colName }) };
            });
        }
    };
    const toggleScroll = () => {
        setScroll(!scroll);
    };

    useEffect(() => {
        let initRows;
        let objTotalVariant = {};

        if (data.products.length > 0) {
            initRows = data.products.map((product, productIdx) => {
                // create variant property
                const variantProp = {};
                longestVariant.forEach((lv, lvIdx) => {
                    let prodVariants = data.products[productIdx].variants;
                    let ttl = 0;
    
                    prodVariants.forEach((a) => {
                        ttl += a.types?.[lvIdx]?.stock ?? 0;
                    });
    
                    variantProp[`variant${lvIdx}`] = ttl;
                });
    
                product.variants.forEach((variant) => {
                    variant.types.forEach((type) => {
                        // if (type.label == '36' && type.qty !== 0) {
                        // console.log(777777, type);
                        // }
                        if (!objTotalVariant[type.label]) {
                            objTotalVariant[type.label] = type.stock;
                        } else {
                            objTotalVariant[type.label] += type.stock;
                        }
                    });
                });
    
                return { ...product, ...variantProp };
            });
    
            setTotalVariant(objTotalVariant);
    
            setVariantSort((prev) => {
                longestVariant.forEach((v, i) => {
                    prev[`variant-${i}`] = {
                        sort_type: null,
                    };
                });
    
                return { ...prev };
            });
            setRows(initRows);
        }
    }, [data, longestVariant]);

    return (!data.products.length || !data) ? (
        <NoReport />
    ) : (
        <>
            <Helmet>
                <style type='text/css'>{`@page {size: landscape}`}</style>
            </Helmet>
            {/* <div className="print-report zoom-50"> */}
            <KopSurat fit />
            <p className='asdasd' style={{ marginBottom: '20px' }}>
                Summary by Product<span>Showing stock from all products</span>
            </p>

            <div className='report-summary-box mb-16'>
                <div className='item teal'>
                    <p>
                        <span>Stock Value</span>
                        <span>IDR {money(data.total_value)}</span>
                    </p>
                </div>
                <div className='item'>
                    <p>
                        <span>Stock</span>
                        <span>{data.total_stock}</span>
                    </p>
                </div>
            </div>

            <NoPrint force={true}>
                <div className='table-menu'>
                    <div className='left'>
                        <button onClick={toggleScroll}>
                            {scroll ? 'Remove' : 'Show'} Scroll
                        </button>
                    </div>
                    <div className='right'>
                        <p className='note'>
                            *Data currency shown in IDR (Indonesian Rupiah)
                        </p>
                    </div>
                </div>
            </NoPrint>

            <SimpleBarHOC status={scroll}>
                <table
                    width='100%'
                    className='table-report inventory-stock-1-1 stock-product '>
                    <thead>
                        <tr>
                            {Object.keys(sort).map((key, idx) => (
                                <ThSort
                                    key={`th-${idx}`}
                                    label={sort[key].label}
                                    rowspan={sort[key].rowspan}
                                    colspan={sort[key].colspan}
                                    sort={sort[key].has_sort}
                                    handleClick={() => sortColumn(key)}
                                    className={`${sort[key].className}  ${sort[key].sort_type}`}
                                />
                            ))}
                        </tr>
                        {variants?.map((variant, variantIdx) => {
                            return (
                                <tr
                                    key={`tr-${variantIdx}`}
                                    className={`sticky-variant-row-${
                                        variantIdx + 1
                                    }`}>
                                    {longestVariant?.map((lv, lvIdx) => {
                                        return (
                                            <ThSort
                                                key={`th-variant-${lvIdx}`}
                                                label={
                                                    <span
                                                        style={{
                                                            display:
                                                                'inline-block',
                                                            textAlign: 'center',
                                                        }}>
                                                        {variant?.types?.[lvIdx]
                                                            ?.label ?? '-'}
                                                        <br />
                                                        {totalVariant[
                                                            variant.types?.[
                                                                lvIdx
                                                            ]?.label
                                                        ] ? (
                                                            <span>
                                                                (
                                                                {
                                                                    totalVariant[
                                                                        variant
                                                                            .types?.[
                                                                            lvIdx
                                                                        ]?.label
                                                                    ]
                                                                }
                                                                )
                                                            </span>
                                                        ) : (
                                                            <>&nbsp;</>
                                                        )}
                                                    </span>
                                                }
                                                sort={
                                                    typeof variant?.types?.[
                                                        lvIdx
                                                    ]?.label !== 'undefined'
                                                }
                                                handleClick={() =>
                                                    sortColumn(
                                                        `variant${lvIdx}`,
                                                        lvIdx
                                                    )
                                                }
                                                className={
                                                    variantSort?.[
                                                        `variant-${lvIdx}`
                                                    ]?.sort_type
                                                }
                                            />
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </thead>
                    <tbody>
                        {rows?.map((product, productIdx) => {
                            return (
                                <tr>
                                    <td>{product?.code}</td>
                                    <td>{product?.category}</td>
                                    <td>{product?.type}</td>
                                    <td>{product?.name}</td>
                                    <td>{product?.brand}</td>
                                    {longestVariant?.map((lv, lvIdx) => {
                                        return (
                                            <td key={`tbodyrow${lvIdx}`}>
                                                {product[`variant${lvIdx}`]}
                                            </td>
                                        );
                                    })}
                                    <td>{product?.stock}</td>
                                    <td>{product?.reject}</td>
                                    <td>{product?.uom_name}</td>
                                    <td className='cell-tr'>
                                        {money(product?.sales_price)}
                                    </td>
                                    <td className='cell-tr'>
                                        {money(product?.total_value)}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </SimpleBarHOC>
        </>
    );
}
