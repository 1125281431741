import React, { useState } from 'react'
import { money } from '@/Helper'
import NoReport from 'Elements/NoReport'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function TableGeneral({ data: datas }) {

    console.log(datas)
    console.log(datas.products)


    const [showDetail, setShowDetail] = useState(
        datas.map(data => data.products.map(product => false))
    )

    const handleShowDetail = (a, b) => {
        setShowDetail(state => {
            state[a][b] = !state[a][b];
            return [...state]
        })
    }

    const setColor = (type) => {
        return type == 'Sales Return' ? { color: 'red' } : { color: 'black' };
    }

    return datas.length < 1 ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '40px' }}>Inventory by Customer Report <span>Showing report from all branches</span></p>
            {
                datas.map((data, dataIdx) => {

                    const variants = data.products[0]["in"];
                    let longestVariant = {};
                    let longestVariantlen = 0;

                    console.log(11111111111, data.products[0])
                    // console.log(22222222222, variants)

                    variants.forEach(variant => {
                        let variantLen = Object.keys(variant.items).length;
                        let currentLongestVariant = Object.keys(longestVariant).length;

                        longestVariant = variantLen > currentLongestVariant ? variant.items : longestVariant;
                    })

                    longestVariantlen = Object.keys(longestVariant).length;

                    return (
                        <>
                            <h5><DeviceHubIcon /> Partner Store  : {data.name}</h5>

                            <div className="report-summary-box">
                                <div className="item teal">
                                    <p>
                                        <span>Net Income</span>
                                        <span>IDR {money(data.income)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Subtotal</span>
                                        <span>IDR {money(data.sub_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Discount</span>
                                        <span>IDR {money(data.discount)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Total</span>
                                        <span>IDR {money(data.total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>HPP</span>
                                        <span>IDR {money(data.hpp)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Other Cost</span>
                                        <span>IDR {money(data.additional_cost)}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="report-summary-pill">
                                <p>
                                    <span>Delivered :</span>
                                    <span>{data.delivered}</span>
                                </p>
                                <p>
                                    <span>Returned :</span>
                                    <span>{data.returned}</span>
                                </p>
                                <p>
                                    <span>Sales :</span>
                                    <span>{data.sales}</span>
                                </p>
                                <p>
                                    <span>On Hand :</span>
                                    <span>{data.on_hand}</span>
                                </p>
                            </div>

                            <div className={`${dataIdx === 0 ? "mb-40" : ""} scroll-x`}>
                                <table className="table-report inventory-by-cust mt-0" border="0" width="100%">
                                    <thead>
                                        <tr>
                                            <th rowSpan={variants.length + 2}>No.</th>
                                            <th rowSpan={variants.length + 2}>Code</th>
                                            <th rowSpan={variants.length + 2}>Category</th>
                                            <th rowSpan={variants.length + 2}>Name</th>
                                            <th rowSpan={variants.length + 2}>Brand</th>
                                            <th rowSpan={variants.length + 2}>Sales Price</th>
                                            <th className="cell-tc" colSpan={longestVariantlen + 1}>In</th>
                                            <th className="cell-tc" colSpan={longestVariantlen + 1}>Return</th>
                                            <th className="cell-tc" colSpan={longestVariantlen + 1}>Sales</th>
                                            <th className="cell-tc" colSpan={longestVariantlen + 1}>On Hand</th>
                                            <th rowSpan={variants.length + 2}>UoM</th>
                                        </tr>
                                        <tr>
                                            <th className="cell-tc" colSpan={longestVariantlen}>Variant</th>
                                            <th>Total</th>
                                            <th className="cell-tc" colSpan={longestVariantlen}>Variant</th>
                                            <th>Total</th>
                                            <th className="cell-tc" colSpan={longestVariantlen}>Variant</th>
                                            <th>Total</th>
                                            <th className="cell-tc" colSpan={longestVariantlen}>Variant</th>
                                            <th>Total</th>
                                        </tr>
                                        {
                                            variants.map((variant, variantIdx) => {

                                                const items = Object.keys(variant.items)

                                                return (
                                                    <tr>
                                                        {
                                                            ['delivered', 'returned', 'sales', 'on_hand'].map(rows => {
                                                                return Object.keys(longestVariant).map((value, key) => {
                                                                    return (
                                                                        <>
                                                                            <th>{items[key]}</th>
                                                                            {
                                                                                Object.keys(longestVariant).length - 1 === key
                                                                                && variantIdx < 1
                                                                                && <th className="cell-tc" rowSpan={variants.length}>{data[rows] || 0}</th>
                                                                            }
                                                                        </>
                                                                    )
                                                                })
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </thead>
                                    <tbody>
                                        {
                                            data.products.map((product, productIdx) => {

                                                /* IN */
                                                let variantValue = {
                                                    in: null,
                                                    returned: null,
                                                    sales: null,
                                                    on_hand: null,
                                                }


                                                Object.keys(variantValue).forEach(x => {
                                                    let temp;
                                                    if (product.variant_id) {
                                                        temp = product[x].find(p => p.variant_id === product.variant_id).items;
                                                    } else {
                                                        temp = product[x][0].items;
                                                    }

                                                    variantValue[x] = Object.keys(temp).map(key => ({ label: key, value: temp[key] }))
                                                })

                                                // console.log('variantValue', variantValue)

                                                return (
                                                    <>
                                                        <tr className="cell-main">
                                                            <td>{productIdx + 1}.</td>
                                                            <td className="cell-min-width-150 td-highlight">
                                                                <span className="trigger-show-detail" onClick={() => handleShowDetail(dataIdx, productIdx)}>
                                                                    {product.code} <KeyboardArrowDownIcon className="info-icon" />
                                                                </span>
                                                            </td>
                                                            <td className="cell-min-width-150">{product.category}</td>
                                                            <td className="cell-min-width-150">{product.product_name}</td>
                                                            <td className="cell-min-width-150">{product.brand}</td>
                                                            <td className="cell-min-width-100">IDR {money(product.sales_price)}</td>
                                                            {
                                                                Object.keys(variantValue).map(x => {
                                                                    let ttl = 0
                                                                    return Object.keys(longestVariant).map((value, key) => {
                                                                        if (product.variant_id) {
                                                                            let findItem = product[x].find(p => p.variant_id === product.variant_id)
                                                                            ttl = findItem.total
                                                                        } else {
                                                                            ttl = product[x][0].total
                                                                        }

                                                                        return <>
                                                                            <td>{variantValue[x]?.[key]?.value ?? 0}</td>
                                                                            {
                                                                                key === longestVariantlen - 1 &&
                                                                                <td className="cell-tc cell-bg-d">
                                                                                    {ttl}
                                                                                </td>
                                                                            }
                                                                        </>
                                                                    })
                                                                })
                                                            }
                                                            <td className="cell-min-width-100">Piece</td>
                                                        </tr>
                                                        {
                                                            // details
                                                            product.details.map((detail, detailIdx) => {

                                                                let detailVariantValue = {
                                                                    in: null,
                                                                    on_hand: null,
                                                                    sales: null,
                                                                    returned: null,
                                                                }
                                                                let ttl = 0;

                                                                Object.keys(variantValue).forEach(x => {
                                                                    let temp;
                                                                    if (product.variant_id) {
                                                                        let findItem = detail[x].find(p => p.variant_id === product.variant_id);
                                                                        temp = findItem.items;
                                                                        ttl = findItem.total;
                                                                    } else {
                                                                        temp = detail[x][0].items;
                                                                        ttl = detail[x][0].total;
                                                                    }

                                                                    detailVariantValue[x] = {
                                                                        total: ttl,
                                                                        items: Object.keys(temp).map(key => ({ label: key, value: temp[key] }))
                                                                    }
                                                                })


                                                                console.log('detailVariantValue', detailVariantValue)

                                                                return (
                                                                    <>
                                                                        {
                                                                            detailIdx === 0 &&
                                                                            <tr className={`${showDetail[dataIdx][productIdx] ? '' : 'hide'} tr-detail detail-heading`}>
                                                                                <td></td>
                                                                                <td>Date</td>
                                                                                <td colSpan="2">ID</td>
                                                                                <td colSpan="2">Transc Type</td>
                                                                                <td colSpan={(longestVariantlen * 4) + 5}></td>
                                                                            </tr>
                                                                        }
                                                                        <tr className={`${showDetail[dataIdx][productIdx] ? '' : 'hide'} tr-detail detail-body`}>
                                                                            <td style={setColor(detail.transaction_type)}>{productIdx + 1}.{detailIdx + 1}.</td>
                                                                            <td style={setColor(detail.transaction_type)}>{detail.date}</td>
                                                                            <td style={setColor(detail.transaction_type)} colSpan="2">{detail.code}</td>
                                                                            <td style={setColor(detail.transaction_type)} colSpan="2">{detail.transaction_type}</td>
                                                                            {
                                                                                Object.keys(variantValue).map(x => {
                                                                                    return Object.keys(longestVariant).map((value, key) => {
                                                                                        return <>
                                                                                            <td style={setColor(detail.transaction_type)} >{detailVariantValue[x].items?.[key]?.value ?? ''}</td>
                                                                                            {
                                                                                                key === longestVariantlen - 1 &&
                                                                                                <td style={setColor(detail.transaction_type)} className="cell-bg-d cell-tc">
                                                                                                    {detailVariantValue[x].total}
                                                                                                </td>
                                                                                            }
                                                                                        </>
                                                                                    })
                                                                                })
                                                                            }
                                                                            <td style={setColor(detail.transaction_type)} className="cell-min-width-100"></td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )
                })
            }
        </>
    )
}