import React from 'react'
import { money, showMonth } from '@/Helper'
import NoReport from 'Elements/NoReport'

const makeTbodyRow = {
    day(rows) {
        return this.makeBody(rows)
    },
    monthOrYear(rows) {
        return rows.map(data => (
            <>
                <tr className="highlight">
                    <td></td>
                    <td>{showMonth(data.date)}</td>
                    <td className="td-highlight"></td>
                    <td></td>
                    <td className="td-text-right">IDR {money(data.sub_total)}</td>
                    <td className="td-text-right">IDR {money(data.total_discount)}</td>
                    <td className="td-text-right">IDR {money(data.grand_total)}</td>
                    <td className="td-text-right">IDR {money(data.hpp)}</td>
                    <td className="td-text-right">IDR {money(data.additional_cost)}</td>
                    <td className="td-text-right">IDR {money(data.net_income)}</td>
                    <td className="td-text-right">{data.status}</td>
                </tr>
                {
                    this.makeBody(data.reports)
                }
            </>
        ))
    },
    makeBody(rows) {
        return rows.map((r, rowIdx) => (
            <tr>
                <td style={setColor(r.type)}>{rowIdx + 1}</td>
                <td style={setColor(r.type)}>{r.date}</td>
                <td style={setColor(r.type)}>{r.code}</td>
                <td style={setColor(r.type)}>{r.contact}</td>
                <td style={setColor(r.type)} className="td-text-right">IDR {money(r.sub_total)}</td>
                <td style={setColor(r.type)} className="td-text-right">IDR {money(r.total_discount)}</td>
                <td style={setColor(r.type)} className="td-text-right">IDR {money(r.grand_total)}</td>
                <td style={setColor(r.type)} className="td-text-right">IDR {money(r.hpp)}</td>
                <td style={setColor(r.type)} className="td-text-right">IDR {money(r.additional_cost)}</td>
                <td style={setColor(r.type)} className="td-text-right">IDR {money(r.net_income)}</td>
                <td style={setColor(r.type)} className="td-text-right">{r.status}</td>
            </tr>
        ))
    }
}

const setColor = (type) => {
    return type == 'sales_return' ? { color: 'red' } : { color: 'black' };
}

export default function TableGeneral({ data, type }) {

    return (
        <>
            {
                data.reports.length < 1 ? <NoReport /> : (
                    <>
                        <p className="asdasd" > Sales Order Report <span>Showing Sales Order Report</span></p>
                        <div className="report-summary-box">
                            <div className="item teal">
                                <p>
                                    <span>Net Income</span>
                                    <span>IDR {money(data.net_income)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Sub Total</span>
                                    <span>IDR {money(data.sub_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Total</span>
                                    <span>IDR {money(data.grand_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Total Return</span>
                                    <span>IDR {money(data.sr_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Discount</span>
                                    <span>IDR {money(data.total_discount)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>HPP</span>
                                    <span>IDR {money(data.hpp)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Other Cost</span>
                                    <span>IDR {money(data.additional_cost)}</span>
                                </p>
                            </div>
                        </div>

                        <div className="report-summary-pill">
                            {/* <p>
                                <span>Contact :</span>
                                <span>{data.contact_counter}</span>
                            </p> */}
                            <p>
                                <span>Sales Order :</span>
                                <span>{data.so_counter}</span>
                            </p>
                            <p>
                                <span>Return Sales Order :</span>
                                <span>{data.sr_counter}</span>
                            </p>
                        </div>

                        <table className="table-report">
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Date</th>
                                    <th>No Sales Order/Return</th>
                                    <th>Contact</th>
                                    <th className="th-text-right">Subtotal</th>
                                    <th className="th-text-right">Discount</th>
                                    <th className="th-text-right">Total</th>
                                    <th className="th-text-right">HPP</th>
                                    <th className="th-text-right">Other Cost</th>
                                    <th className="th-text-right">Net Income</th>
                                    <th className="th-text-right">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {makeTbodyRow[type === 'day' ? 'day' : 'monthOrYear'](data.reports)}
                            </tbody>
                        </table>

                    </>
                )

            }
        </>
    )
}
