import moment from 'moment'
import { write, utils } from 'xlsx'
import { dateDMYHIS } from '../Helper'
import { saveAs } from 'file-saver'

export const TableConfigOnDownload = (
  filename,
  buildHead,
  buildBody,
  columns,
  values
) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const date = dateDMYHIS(Date.now())

  // build the json, this is one way to do it
  const json = values.reduce((result, val) => {
    const temp = {}
    val.data.forEach((v, idx) => {
      temp[columns[idx].label] = v
    })

    result.push(temp)
    return result
  }, [])

  const fileName = `${filename}_` + date || 'Data_Philips_Works_' + date

  const jsonOut = json.map((data, idx) => {
    data['No.'] = idx + 1
    delete data.id
    if ('is_warning' in data) {
      delete data.is_warning
    }

    if (data.Actions) {
      delete data.Actions
    }

    if (data.Action) {
      delete data.Action
    }
    if (data['#EDC']) {
      data['#EDC'] = data['#EDC'].length
    }
    if (data.Address) {
      if (typeof data.Address === 'array' || typeof data.Address === 'object') {
        data.Address = data.Address.join(', ')
      } else {
        data.Address = data.Address
      }
    }
    if ('User' in data && data.User.length > 0) {
      data.User = data.User.join(',')
    }
    if ('Promotion' in data) {
      const name = data.Promotion.find((x) => x)
      data.Promotion = name?.promotion?.promotion_name ?? ''
    }
    if (typeof data.Status === 'boolean' || typeof data.status === 'boolean') {
      if (data.Status) {
        data.Status = 'Active'
      } else {
        data.Status = 'Inactive'
      }
    }
    if (data.Variant) {
      if (typeof data.Variant === 'object' || typeof data.Variant === 'array') {
        let countVal = []
        data.Variant.map((d) => {
          countVal.push(d.value)
        })
        data.Variant = countVal.join(', ')
      }
    }
    if (data.Type && Array.isArray(data.Type)) {
      data.Type = data.Type.join(', ')
    }
    if (data['Date/Time']) {
      data['Date/Time'] = moment(data['Date/Time']).format('DD-M-Y h:mm:ss')
    }
    return data
  })

  const ws = utils.json_to_sheet(jsonOut)
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] }

  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' })
  const data = new Blob([excelBuffer], { type: fileType })

  saveAs(data, fileName + fileExtension)

  // cancel default  CSV download from table
  return false
}
