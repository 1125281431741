import React, {useState, useEffect} from 'react'
import { Formik, Form } from 'formik';
import FieldsGroup from 'Elements/FieldsGroup'
import { useSelector, useDispatch } from 'react-redux';
import { resetStatusProfile, updateProfile } from 'Reduxes/configuration/profile/profileActions'
import { fieldsToFormik, scrollToTop, fieldsValidation } from '@/Helper'
import Button from 'Elements/Button';
import { matchEditValue, serverValue } from 'Configs/Form';
import { validationSchema } from './validation'


import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import Input from 'Elements/Input';
import { default as Input2 } from 'Elements/input/Input';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import ProductManager from 'Elements/ProductManager'
import SelectData from 'Elements/SelectData';
import FieldsContainer from 'Elements/FieldsContainer'
import InputNum from 'Elements/inputnum/InputNum'
import { default as Select } from 'Elements/select/Select'
import SelectAsync from 'Elements/SelectAsync';
// import Select from 'Elements/select/Select'
import { filterMember, loadProvince, loadCity } from 'Reduxes/member/memberActions'


const FormEdit = (props) => {



    const { data, error } = useSelector(state => state.profileReducer)
    const { showTable, type = 'edit' } = props;
    const dev = 0;
    const dispatch = useDispatch()

    const formOpts = {
        initialValues: data,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const { company_name, address, province, city, postal_code, phone, email, site_address } = values;

            let cleanVal = {
                company_name,
                address,
                province,
                city,
                postal_code: +postal_code,
                phone,
                email,
                site_address,
                country: 'Indonesia'
            }

            let result = await dispatch(updateProfile(cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }


    // Handle chain address
    const [dataCity, setDataCity]= useState([])

    const handleChangeProvince = async val =>{
        setDataCity([])
        const resp = await dispatch(loadCity({ province_name :  val.selected.value }))
        setDataCity(resp.data.map(x => ({
            label: x.ibukota, value: x.ibukota
        })))

        if(!val.selected.value){
            setDataCity([])    
        }
    }

    useEffect(() => {

        const loadDataCity = async (province) =>{
            const resp = await dispatch(loadCity({ province_name :  province }))
            setDataCity(resp.data.map(x => ({
                label: x.ibukota, value: x.ibukota
            })))
        }
        if(type === "edit"){
            if(data.province){
                loadDataCity(data.province)
            }
        }

    }, [])

    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Brand" : "Update Profile"}
            subtitle={`Please fill the form below to  update  data profile`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusProfile())} option={{ type: 'error', message: error }} />
                }
                <Formik {...formOpts} enableReinitialize={true}>
                    {(formik) => {
                        const { initialValues, values, isSubmitting } = formik;
                        return (
                            <Form className="form-layout-1">
                                <FieldsContainer subtitle="Please fill fields below" title="General Information">
                                    <Input name="company_name" label="Company name" />
                                    <Input name="address" label="address" />
                                    <div></div>

                                   <Select 
                                        label ="Province"
                                        name="province"
                                        type="province"
                                        portal
                                        options="province"
                                        isClearable
                                        getData={handleChangeProvince}
                                    />

                                    {
                                        dataCity.length > 0 &&
                                            <Select 
                                                label= "City"
                                                name="city"
                                                type="city"
                                                portal
                                                isClearable
                                                options={dataCity}
                                             />  

                                    }
                                    
                                    <Input name="postal_code" label="postal code" />


                                </FieldsContainer>
                                <FieldsContainer subtitle="Please fill fields below" title="Contact Information">
                                    <Input name="phone" label="phone number" />
                                    <Input name="email" label="email" />
                                    <Input name="site_address" label="website" />
                                </FieldsContainer>
                                <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                            </Form>
                        )
                    }}
                </Formik>
            </>
        </ContentWrapper >
    )
}

export default FormEdit