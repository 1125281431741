import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChildRow from './ChildRow';
import { money } from '../../../../Helper';

export default function ParentRow({ product, showDetail: defaultShowDetail, variants, index, longestVariant, detailType }) {
  const [showDetail, setShowDetail] = React.useState(defaultShowDetail)

  const handleShowDetail = () => {
    setShowDetail(!showDetail)
  }

  React.useEffect(() => {
    setShowDetail(!showDetail)
  }, [defaultShowDetail])

  React.useEffect(() => {
    setShowDetail(false)
  }, [product])

  return (
    <>
      <tr>
        <td>{index + 1}.</td>
        <td></td>
        <td className="cell-width-as-content cell-highlight">
          <span className="trigger-show-detail" onClick={handleShowDetail}>
            {product.code} {product.branches.length && <KeyboardArrowDownIcon className="info-icon" />}
          </span>
        </td>
        <td className="cell-width-as-content">{product.category_name}</td>
        <td className="cell-width-as-content">{product.product_name}</td>
        <td className="cell-width-as-content">{product.sales_type}</td>
        <td className="cell-width-as-content">{money(product.product_price)}</td>
        <td className="cell-width-as-content">{product.brand_name}</td>
        <td>{product.uom_name}</td>
        {
          product.total_deviations?.map((totalDeviation) => {
            return (
              <>
                {
                  detailType === 'stockopname_detail_stock' && <td colSpan={6}>{totalDeviation.deviation_stock}</td>
                }
                {
                  detailType === 'stockopname_detail_reject' && <td colSpan={6}>{totalDeviation.deviation_reject}</td>
                }
              </>
            )
          })
        }

        <td colSpan={(longestVariant.length * 6)}></td>
      </tr>
      {
        showDetail &&
        <ChildRow
          data={product.branches}
          variants={variants}
          branches={product.branches}
          longestVariant={longestVariant}
          detailType={detailType}
        />
      }
    </>
  )
}
