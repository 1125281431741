import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import { deleteContact, resetStatusContact } from 'Reduxes/contact/contactActions'
import LoadingContent from 'Elements/LoadingContent';
import Table from 'Elements/Table'
import TableNoData from 'Elements/TableNoData'
import Alert from 'Comps/elements/Alert'
import Label from 'Elements/Label'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomToolbar from "Elements/CustomToolbar";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { makeStyles } from '@material-ui/core/styles';
import KopSurat from 'Elements/KopSurat'
import { scrollToTop, globalDialog } from '@/Helper'
import Permission from '../../../comps/permission/Permission'
import { getLocalStorageAuth } from '../../../Helper';

const useStyles = makeStyles((theme) => ({
    spacer: {
        height: "25px"
    }
}));


export default function TableData(props) {
    const { type, setContentActiveFormEdit, deleteMemberProps, setContentActiveDetail } = props
    const { data, loading, error, success } = useSelector(state => state.contactReducer);
    const classes = globalTableStyle();
    const dispatch = useDispatch();
    const handleDelete = id => {
        globalDialog(async () => {
            await dispatch(deleteContact(id))
            scrollToTop()
        })
    }

    let dataModified = data.map(row => ({
        ...row,
        fullAddress: [row.address ? row.address : null, row.city ? row.city : null, row.province ? row.province : null, row.country ? row.country : null, row.postal_code ? row.postal_code : null].filter(Boolean),
        userType: [row.is_brand ? 'Brand' : null, row.is_consignment ? 'Consignment' : null, row.is_supplier ? 'Supplier' : null, row.is_user ? 'User' : null].filter(Boolean)


    }))

    
    /**
     * If user detected as superadmin, then fetch all contact.
     * Else, hide superadmin.
     */
    const isSuperAdmin = getLocalStorageAuth().user_category_id === 1
    dataModified = isSuperAdmin ? dataModified : dataModified.filter((dataModified) => dataModified.id !== 1)

    const table = tableOpts({
        handleDelete,
        data: dataModified,
        colIdPosition: 0,
        filename: "Contact Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        filter: false,
                        print: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                {
                    name: "code",
                    label: 'Code',
                    options: {
                        filter: false,
                        filterOptions: {
                            renderValue: v => v ? v.replace(/^(\w).* (.*)$/, '$1. $2') : ''
                        },

                        filterType: 'textField',
                    }
                },
                // {
                //     name: "userType",
                //     label: 'Type',
                //     options: {
                //         filter: true,
                //         // display: false,
                //         viewColumns: false,
                //         filterType: 'checkbox',


                //     }
                // },
                {
                    name: "name",
                    label: 'Name',
                    options: {
                        filter: false,
                        filterType: 'textField',


                    }
                },
                {

                    name: 'fullAddress',
                    label: 'Address',
                    options: {
                        filter: false,
                        setCellHeaderProps: () => ({ className: 'address-col' }),
                        customBodyRender: (value, tableMeta) => {

                            return value.join(", ")
                        },

                    }
                },
                {
                    name: 'phone',
                    label: "Phone",
                    options: {
                        // display: false,
                        filter: false
                    }
                },
                {
                    name: "email",
                    label: 'Email',
                    options: {
                        filter: false,
                        // customBodyRender: (value, tableMeta, updateValue) => {
                        //     return (
                        //         !value && !tableMeta.rowData[5] ? '-' :
                        //             <>
                        //                 <a className="td-email" href={`mailto:${value}`}>{value}</a><br />
                        //                 {tableMeta.rowData[4]}
                        //             </>
                        //     )
                        // },


                    }
                },
                {
                    name: 'userType',
                    label: 'Type',
                    options: {
                        filter: true,
                        filterType: 'checkbox',
                        customBodyRender: (value) => {
                            return value.join(', ')
                        }
                    }
                },
                {
                    name: "is_active",
                    label: 'Status',
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return <Label type={value ? 'success' : 'danger'} text={value ? 'active' : 'Inactive'} />
                        },
                        filter: true,
                        filterType: 'checkbox',
                        filterOptions: {
                            names: ['Active', 'Inactive'],
                            logic(val, filterVal) {
                                const show =
                                    (filterVal.indexOf('Active') >= 0 && val) ||
                                    (filterVal.indexOf('Inactive') >= 0 && !val);
                                return !show;
                            },
                        },
                    }
                }, {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        filter: false,
                        print: false,
                        customBodyRender: (value, tableMeta, updateValue) => {

                            const attrShow = {
                                className: 'row-action',
                                onClick: () => setContentActiveDetail(tableMeta.rowIndex)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => setContentActiveFormEdit(value)
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => deleteMemberProps(value)
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />
                                    <Permission
                                        component={
                                            <EditIcon {...attrEdit} />
                                        }
                                        type="update"
                                        subMenu="contacts"
                                    />
                                    <Permission
                                        component={
                                            <DeleteIcon {...attrDelete} />
                                        }
                                        type="delete"
                                        subMenu="contacts"
                                    />
                                </>
                            );
                        }
                    }
                },
            ],
        },
        deleteAct: (ids) => {
            dispatch(deleteContact(ids))
        }
    })

    return (
        <>
            <PrintProvider>
                <Print single name="print-contacnt-table-1" >
                    <KopSurat />
                    <div className="spacer-60"></div>
                    {
                        success &&
                        <Alert hide={() => dispatch(resetStatusContact())} option={{ type: 'success', message: success }} />
                    }
                    {
                        error && <Alert hide={() => dispatch(resetStatusContact())} option={{ type: 'error', message: error }} />
                    }
                    {
                        loading ? <LoadingContent />
                            : (
                                data.length ?
                                    <Table {...table} className={classes.table} />
                                    : <TableNoData />
                            )
                    }
                </Print>
            </PrintProvider>
        </>
    )
}
