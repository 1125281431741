import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import Alert from "Comps/elements/Alert";
import Input from "Elements/Input";
import Button from "Elements/Button";
import {
  resetStatusLoginBranch,
  getBranchByCode,
} from "Reduxes/logincabang/loginCabangActions";
import { setBranch } from "Reduxes/user/userActions";
import { useSelector, useDispatch } from "react-redux";

const LoginCabangForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.loginCabangReducer);
  const formOpts = {
    initialValues: { branchid: "" },
    validate: (v) => {
      const errors = {};

      if (!v.branchid) {
        errors.branchid = "Branch ID is required";
      }

      return errors;
    },
    onSubmit: async (values, { setFieldValue, setSubmitting, resetForm }) => {
      const { branchid } = values;
      const resp = await dispatch(getBranchByCode(branchid));
      if (resp.status) {
        dispatch(setBranch(resp.data));

        history.push("/login");
      }
    },
  };

  return (
    <Formik {...formOpts}>
      {({ values, isSubmitting }) => (
        <Form autoComplete="off">
          <h3 className="form-title">Branch Login</h3>
          {error && (
            <Alert
              hide={() => dispatch(resetStatusLoginBranch())}
              option={{ type: "error", message: "Recheck your credential." }}
            />
          )}
          <Input type="text" name="branchid" label="Branch ID" />
          <Button
            type="submit"
            style="OrangeLgBlock"
            label={isSubmitting ? "Processing" : "Continue"}
            icon={isSubmitting ? "spinner" : "check-circle"}
          />
        </Form>
      )}
    </Formik>
  );
};

export default LoginCabangForm;
