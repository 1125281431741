import React, { useState } from 'react'
import FormElement from './FormElement'
import Detail from './Detail'
import TableDataSSR from './TableDataSSR';

function Reject() {
    const [content, setContent] = useState('table')
    const [idShow, setIdShow] = useState('table')
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [filters, setFilters] = useState({})
    const [params, setParams] = useState('')

    const showDetail = (id) => {
        setIdShow(id);
        setContent('detail')
    }
    const showTable = () => {
        setContent('table');
    }
    const showForm = (type, id) => {
        setIdShow(id);
        setContent('form' + type);
    }

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                return (
                    <TableDataSSR
                        showForm={showForm}
                        showDetail={showDetail}
                        indexSetPage={setPage}
                        indexSetRowsPerPage={setRowsPerPage}
                        indexSetFilters={setFilters}
                        indexSetParams={setParams}
                        initialPage={page}
                        initialRowsPerPage={rowsPerPage}
                        initialFilters={filters}
                        initialParams={params}
                    />
                )
            case 'formNew':
                return (<FormElement type="new" showTable={showTable} />)
            case 'formEdit':
                return (<FormElement rowId={idShow} type="edit" showTable={showTable} />)
            case 'detail':
                return (<Detail showTable={showTable} rowId={idShow} />)
            default:
                return <>Nothing to show here</>
        }
    }

    return (
        <>
            {renderContent(content)}
        </>
    )
}

export default Reject