import React from 'react'
import Dashboard from 'Pages/Dashboard'
import Member from 'Pages/membership/member/Member'
import Contact from 'Pages/membership/contact/Index'
import UserCategory from 'Pages/membership/usercategory/Index'
import Submodule from 'Comps/layouts/Submodule'

export default function Membership() {

    const pageTitle = {
        title: 'Contact',
        description: 'Show or Manage data user and member',
    }

    const breadcrumb = [
        { label: 'Home' },
        { label: 'Contact' },
        { label: 'Member', active: true },
    ]

    const subModules = [
        { 
            label: 'Member', 
            component: Member, 
            permissionName: 'member',
            permissionType: 'read',
        },
        {
            label: 'Contact',
            dropdownPos: 'left',
            child: [
                { label: 'All Contacts', component: Contact },
                { label: 'User', component: Contact, props: { type: 'user' } },
                { label: 'Supplier', component: Contact, props: { type: 'supplier' } },
                { label: 'Consignment', component: Contact, props: { type: 'consignment' } },
                { label: 'Brand', component: Contact, props: { type: 'brand' } },
            ],
            permissionName: 'contacts',
            permissionType: 'read',
        },
        {
            label: 'User Category',
            component: UserCategory,
            permissionName: 'user_category',
            permissionType: 'read',
        },
    ]

    return (
        <Dashboard pageTitle={pageTitle} breadcrumb={breadcrumb} >
            <Submodule buttonAdd subModules={subModules} />
        </Dashboard>
    )
}
