import React, { useState, Fragment, useEffect } from 'react'
import { money, handleSortState, sortData } from '@/Helper'
// import TdSort from 'Elements/TdSort'

const headerEnd = {
    stock: {
        has_sort: true,
        sort_type: null,
        label: 'stock'
    },
    reject: {
        has_sort: true,
        sort_type: null,
        label: 'reject'
    },
    uom_name: {
        has_sort: true,
        sort_type: null,
        label: 'pcs'
    },
    total_value: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'total value'
    },
}

export default function MutationRowChild({ indexParent, longestBranch, products, keys, subrows, data }) {


    const [rows, setRows] = useState(products)
    const [header, setHeader] = useState({
        no: {
            label: 'no.',
            has_sort: false,
        },
        code: {
            has_sort: true,
            sort_type: null,
            label: 'code'
        },
        category: {
            has_sort: true,
            sort_type: null,
            label: 'category'
        },
        name: {
            has_sort: true,
            className: 'td-width-as-content',
            sort_type: null,
            colspan: 2,
            label: 'product name'
        },
        sales_price: {
            has_sort: true,
            sort_type: null,
            className: 'cell-tr',
            label: 'sales price'
        },
    })
    const sortColumn = (colName, variantIndex = 1000) => {
        setRows(prevState => {
            prevState = sortData({ type: header[colName].sort_type, column: colName, data: prevState })
            return [...prevState]
        })

        setHeader(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    // useEffect(() => {
    //     let variantHeader = {}
    //     let initRows = []

    //     Object.keys(variants).map((variant, variantIdx) => {
    //         variantHeader[`variant${variantIdx}`] = {
    //             has_sort: true,
    //             sort_type: null,
    //             className: 'cell-tr',
    //             label: ' '
    //         }
    //     })
    //     initRows = products.map(product => {
    //         variants.forEach((lv, lvIdx) => {
    //             let qty = 0;

    //             product.variants.forEach(v => {
    //                 qty += v?.types?.[lvIdx]?.stock ?? 0
    //             })

    //             product[`variant${lvIdx}`] = qty
    //         })
    //         return product
    //     })

    //     setHeader(prev => {
    //         return {
    //             ...prev,
    //             ...variantHeader,
    //             ...headerEnd
    //         }
    //     })
    //     setRows(initRows)

    // }, [products, variants])

    return subrows.map((row, rowIdx) => (
        <tr key={rowIdx}>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{row}</td>
            {
                longestBranch.map((lb, lbIdx) => {
                    return [...keys].map((x, xIdx) => {
                        let variantQtyValue = null;
                        const variantsActive = data?.branches?.[lbIdx]?.variants;
                        if (variantsActive) {
                            let found = variantsActive.find(x => x.label === row);
                            if (found) {
                                variantQtyValue = found.qty;
                            }
                        }
                        let summaryByVariant = data['total_on_hand_per_variants'].find(x => x.label === row);
                        const isOut = x === "out"
                        const isSales = x === "sales"
                        const isReject = x === "reject"
                        const hasValue = variantQtyValue?.[x] > 0
                        const isMinus = isOut || isSales || isReject
                        return (
                            <Fragment key={xIdx}>
                                <td>{isMinus && hasValue ? `-${variantQtyValue?.[x]}` : variantQtyValue?.[x] || 0}</td>
                                {
                                    xIdx === keys.length - 1 &&
                                    lbIdx === longestBranch.length - 1 && (
                                        <>
                                            <td>{summaryByVariant?.on_hand ?? 0}</td>
                                            <td className="cell-currency cell-tr">IDR {money(summaryByVariant?.on_hand_value ?? 0)}</td>
                                        </>
                                    )
                                }
                            </Fragment>
                        )
                    })
                })
            }
        </tr>
    ))
}