import React from 'react'
import { money } from '@/Helper'
import NoReport from 'Elements/NoReport'
import { Helmet } from 'react-helmet'

export default function TableGeneral({ data }) {


    return data.brand_commission.length < 1 ? <NoReport /> : (
        <>
            <Helmet>
                <style type="text/css">
                    {`@page {size: landscape}`}
                </style>
            </Helmet>
            <p className="asdasd" > Brand Commission Report<span>Showing Brand Commission Report</span></p>

            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Net Income</span>
                        <span>IDR {money(data.net_income)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total Return</span>
                        <span>IDR {money(data.sr_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Sub Total</span>
                        <span>IDR {money(data.sub_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Commission</span>
                        <span>IDR {money(data.commission)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total</span>
                        <span>IDR {money(data.grand_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>HPP</span>
                        <span>IDR {money(data.hpp)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Other Cost</span>
                        <span>IDR {money(data.additional_cost)}</span>
                    </p>
                </div>
            </div>

            <div className="report-summary-pill">
                <p>
                    <span>#Brand</span>
                    <span>{data.brand_counter}</span>
                </p>
                <p>
                    <span>#Sales Return</span>
                    <span>{data.sr_counter}</span>
                </p>
                <p>
                    <span>#Sales</span>
                    <span>{data.so_counter}</span>
                </p>
            </div>

            <div className="scroll-x">
                <table className="table-report mt-0">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Code</th>
                            <th>Brand</th>
                            <th>#sales</th>
                            <th>#return</th>
                            <th className="th-text-right">sub total</th>
                            <th className="th-text-right">commission</th>
                            <th className="th-text-right">total</th>
                            <th className="th-text-right">hpp</th>
                            <th className="th-text-right">other cost</th>
                            <th className="th-text-right">net income</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.brand_commission.map((brandComission, index) => (
                                <tr>
                                    <td>{index + 1}.</td>
                                    <td className="td-width-as-content">{brandComission.code}</td>
                                    <td className="td-width-as-content">{brandComission.brand_name}</td>
                                    <td >{brandComission.so_counter}</td>
                                    <td >{brandComission.sr_counter}</td>
                                    <td className="td-text-right td-currency">IDR {money(brandComission.sub_total)}</td>
                                    <td className="td-text-right td-currency">IDR {money(brandComission.commission)}</td>
                                    <td className="td-text-right td-currency">IDR {money(brandComission.grand_total)}</td>
                                    <td className="td-text-right td-currency">IDR {money(brandComission.hpp)}</td>
                                    <td className="td-text-right td-currency">IDR {money(brandComission.additional_cost)}</td>
                                    <td className="td-text-right td-currency">IDR {money(brandComission.net_income)}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}