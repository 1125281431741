import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "COLOR"

export const COLOR_SET = 'COLOR_SET'
export const COLOR_ERROR = 'COLOR_ERROR'
export const COLOR_ADD = 'COLOR_ADD'
export const COLOR_SHOW = 'COLOR_SHOW'
export const COLOR_EDIT = 'COLOR_EDIT'
export const COLOR_UPDATE = 'COLOR_UPDATE'
export const COLOR_DELETE = 'COLOR_DELETE'
export const COLOR_LOADING = 'COLOR_LOADING'
export const COLOR_RESET = 'COLOR_RESET'




export const loadColor = () => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/color`),
        next: (payload) => dispatch({ type: COLOR_SET, payload })
    })
}
export const addColor = data => async (dispatch, getState) => { 
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/color`, data),
        next: (payload) => dispatch({ type: COLOR_ADD, payload })
    })
}
export const updateColor = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/color/${idEdit}`, data),
        next: (payload) => dispatch({ type: COLOR_UPDATE, payload })
    })
}
export const deleteColor = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/color`, id),
        next: () => dispatch({ type: COLOR_DELETE, payload: id })
    })
}
export const showColor = idx => {
    return {
        type: COLOR_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: COLOR_EDIT,
        payload: idEdit,
    }
}
export const resetStatusColor = () => {
    return {
        type: COLOR_RESET
    }
}