import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { ErrorMessage, useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
    label: {
        ...theme.pw.form.label
    },
    input: {
        ...theme.pw.form.input
    },
    container: {
        ...theme.pw.form.input.container
    },
    text_helper: {
        ...theme.pw.form.text_helper
    }
}))

export default function Input(props) {

    const { label, name, type, getData } = props;
    const classes = useStyles(props);
    const formik = useFormikContext();
    const [value, setValue] = useState('')

    const handleChange = event => {

        const elValue = event.target.value;
        setValue(elValue);

        formik && formik.setFieldValue(name, elValue);

        if (getData) {
            getData({ value: elValue })
        }
    }

    useEffect(() => {
        // console.log(formik, 222222222, name)
        if (!formik) { return; }
        /* 
        sebelumnya ini di cek pake apakah key nya ada / values[name]
        tapi ada case ini ga masuk ke if nya 
        karena ternyata values[name] punya value 0
        */
        const { values } = formik;
        if (values && (name in values)) {
            setValue(values[name])
        }
    }, [formik])



    return (
        <div className={classes.container}>
            {label && <label className={classes.label} htmlFor={name}>{label}</label>}
            <input value={value} onChange={handleChange} className={classes.input} name={name || ''} type={type || 'text'} />
            {
                formik &&
                <ErrorMessage name={name} component="div" className={`${classes.text_helper} error`} />
            }
        </div>
    )
}
