import {
    WARNING_SET,
} from './warningActions'


const initialState = {
    loading: false,
    data: null,
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const warningReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case WARNING_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        default:
            return state;
    }
}

export default warningReducer