import React from 'react'
import { Field, ErrorMessage } from 'formik';

function Textarea(props) {

    const { name, label, type, placeholder, col, disabled } = props;

    return (
        <div className={`field-container textarea ${col ? `span-${col}` : ``}`}>
            <label htmlFor={name}>{label}</label>

            <Field name={name} >
                {({ form, field }) => {
                    const { value } = field;
                    return (
                        <textarea
                            disabled={disabled}
                            {...field}
                        >
                            {value}
                        </textarea>)
                }}
            </Field>
            <ErrorMessage name={name} component="div" className="errMsg" />
        </div>
    )
}

export default Textarea
