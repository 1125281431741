import React from 'react'
import PropTypes from 'prop-types'
import Button from 'Elements/Button'
import Permission from '../permission/Permission'

function ModuleHeader(props) {


    const { title, subtitle, button } = props
    const buttons = Array.isArray(button) ? button : [button]

    return (
        <div className="module-header">
            <div>
                <h3>{title}</h3>
                <p>{subtitle}</p>
            </div>
            <div>
                {
                    buttons.map( (btn, btnI) => (
                        <Permission
                            component={
                                <Button key={btnI} {...btn} />
                            }
                            subMenu={btn.subMenu}
                            type={btn.type}
                        />
                    ))
                }
            </div>
        </div>
    )
}

ModuleHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    button: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
}

ModuleHeader.defaultProps = {
    title: '',
    subtitle: '',
    button: []
}

export default ModuleHeader
 