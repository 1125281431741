import isElectron from "is-electron";
import { globalMessage } from '@/Helper'

export default async function print_barcode(dataPrintBarcode, options) {
  let data_barcode = []
  dataPrintBarcode.map((data) => {
    for (let i = 0; i < data.copies; i++){
      data_barcode.push(data)
    }
  })

  if (isElectron()) {
    
    let { remote } = window.require("electron");
    
    console.log(remote.app.getAppPath());
    const { PosPrinter } = remote.require("electron-pos-printer");
    const path = require("path");
    
    const listPrinter = remote.BrowserWindow.getFocusedWindow().webContents.getPrinters();
    const found = listPrinter.find(printer => printer.name === options.printerName);
    if (!found) {
      throw new Error('PW_BARCODE_PRINTER Not Found');
    }
    
    let leftColumnIndex = 0;
    let rightColumnIndex = 1;
    for (let i = 0; i < (data_barcode.length); i++){

      let leftColumnProductName = data_barcode[leftColumnIndex].product_name.slice(0, 15) + (data_barcode[leftColumnIndex].variant ? " - " + data_barcode[leftColumnIndex].variant : "") + " - " + data_barcode[leftColumnIndex].color;
      
      let rightColumnProductName = ""
      if (data_barcode[rightColumnIndex]){
        rightColumnProductName = data_barcode[leftColumnIndex].product_name.slice(0, 15) + (data_barcode[rightColumnIndex].variant ? " - " + data_barcode[rightColumnIndex].variant : "") + " - " + data_barcode[rightColumnIndex].color;
      }

      const htmltext = `
          <style>
              .column {
                  float: left;
                  width: 48%;
                  padding: 0px;
                  text-align: center;
              }
          
              /* Clear floats after the columns */
              .row:after {
                  content: "";
                  display: table;
                  clear: both;
              }
          </style>
        
          <div class="row" style="font-family: Arial, Helvetica, sans-serif; margin: 0; padding: 0;">
            <div class="column" style="padding-left: 0%; padding-right: 0%; margin-left: 6px;">
              <img id="barcode1" width="177" height="40"/>
              <div class="row"> 
                <div class="column" style="text-align: left; font-size:6px; padding-left: 2px;">${data_barcode[leftColumnIndex].barcode}</div>
                <div class="column" style="text-align: right; font-size:7px;"><b>IDR ${data_barcode[leftColumnIndex].price.toLocaleString()}</b></div>
              </div>
              <div class="row"> 
                <div style="text-align: left; font-size:7px; padding-left: 2px;"><b>${leftColumnProductName.slice(0,30)}</b></div>
              </div>
            </div>
            ${data_barcode[rightColumnIndex] ? `
            <div style="padding-left: 2%; padding-right: 0; margin-left: 9px; margin-right: 0; float: right;">
            <img id="barcode2" width="177" height="40" />
              <div class="row"> 
                <div class="column" style="text-align: left; font-size:6px; padding-left: 2px;">${data_barcode[rightColumnIndex].barcode}</div>
                <div class="column" style="text-align: right; font-size:7px;"><b>IDR ${data_barcode[rightColumnIndex].price.toLocaleString()}</b></div>
              </div>
              <div class="row"> 
                <div style="text-align: left; font-size:7px; padding-left: 2px;"><b>${rightColumnProductName.slice(0,30)}</b></div>
              </div>
            </div>
            `: ""}
          </div>
        
          <script>
            JsBarcode("#barcode1", "${data_barcode[leftColumnIndex].barcode}", {width:1, height:22, fontSize: 24, displayValue: false, margin: 0});
            ${data_barcode[rightColumnIndex] ? `
            JsBarcode("#barcode2", "${data_barcode[rightColumnIndex].barcode}", {width:1, height:22, fontSize: 24, displayValue: false, margin: 0});
            ` : ""}
          </script>`;

      const data = [
        {
          type: "text", // 'text' | 'barCode' | 'qrCode' | 'image' | 'table
          value: htmltext,
        },
      ];

      let printerName = options.printerName;

      const options_print = {
        preview: false, // Preview in window or print
        width: 417, //  width of content body
        margin: "0px 0px 0px 0px", // margin of content body
        copies: 1, // Number of copies to print
        printerName: printerName, // printerName: string, check it at webContent.getPrinters()
        timeOutPerLine: 400,
        pageSize: { height: 20000, width: 127000 },
        silent: true,
      };

      const d = [...data];

      if (printerName && leftColumnIndex % 2 === 0) {
        PosPrinter.print(d, options_print)
          .then(() => { })
          .catch((error) => {
            console.error(error);
            // globalMessage("Select the printer", 'info');
          });
        await sleep(2000);
      }
      
      leftColumnIndex = leftColumnIndex + 1;
      rightColumnIndex = rightColumnIndex + 1;
    }
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}
