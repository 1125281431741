
import React from 'react';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { makeStyles } from '@material-ui/core/styles';
import { FormGroup, FormLabel } from '@material-ui/core';

const animatedComponents = makeAnimated();
const useStyles = makeStyles((theme) => ({
    minWidth: {
        minWidth: "100% !important"
    },

    label: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '14px',
        textAlign: 'left',
        fontWeight: '500',
        textTransform: 'capitalize',
    },
    spacer: {
        height: "25px"
    }
}));

export default function SelectFilter(props) {

    const localClass = useStyles();
    const {
        col,
        name,
        label,
        nofieldcon,
        defaultValue,
        options,
        handleChange
    } = props;

    return (
       <div className={nofieldcon ? '' : `field-container ${col ? `span-${col}` : ``}`}>
            {label && <label htmlFor={name} className={localClass.label} >{label}</label>}
            <div className={localClass.spacer}></div>
            <Select
                name={name}
                className={localClass.minWidth}
                closeMenuOnSelect={false}
                components={animatedComponents}
                isMulti
                options={options}
                onChange={handleChange}
                defaultValue={defaultValue}
            />
    </div >
        
  );
}
