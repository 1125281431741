import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    code:
        Yup.string()
            .required('Brand code is required'),
    name:
        Yup.string()
            .required('Brand name is required'),
    category:
        Yup.string()
            .required('Category is required'),
    status:
        Yup.string()
            .required('Status is required'),
    has_comission_by_category:
        Yup.string()
            .required('Comission category is required')
            .nullable(),
    comission:
        /* comission akan required jika internal */
        Yup.string()
            .when('category', {
                is: (category) => category === 'internal',
                then: Yup.string().required('Comission is required')
            }),
    contact_id:
        Yup.string()
            .when('category', {
                is: (category) => category === 'external',
                then: Yup.string().required('Brand comission is required')
            })
})

