import React, { useState, useEffect } from 'react'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import NoReport from 'Elements/NoReport'
import { money, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import { Helmet } from "react-helmet"
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';

export default function TableSalesDetail({ data }) {

    const [scroll, setScroll] = useState(true)
    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        date: {
            has_sort: true,
            sort_type: null,
            label: 'date',
        },
        branch_name: {
            sort_type: null,
            label: 'branch',
            has_sort: true,
        },
        transaction_id: {
            label: 'id',
            has_sort: false,
        },
        sub_total: {
            label: 'sub total',
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        discount_value: {
            label: 'disc',
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        grand_total: {
            label: 'total',
            has_sort: true,
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
        },
        type: {
            label: 'type',
            has_sort: false,
        },
        category: {
            label: 'category',
            has_sort: false,
        }
    })

    const sortColumn = (colName, tableIndex) => {
        setRows(prevState => {
            prevState[tableIndex].details = sortData({ type: sort[colName].sort_type, column: colName, data: prevState[tableIndex].details })
            return [...prevState]
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    const toggleScroll = () => {
        setScroll(!scroll)
    }


    useEffect(() => {
        const newStructure = data?.map((a, b) => {
            const details = []

            a.details.forEach((c, d) => {
                c.branches.forEach((e, f) => {
                    details.push({ ...e, date: c.date })
                })
            })

            return {
                ...a,
                details
            }
        })

        setRows(newStructure)
    }, [data])


    return (
        <div>
            {
                data.length < 1 ?
                    <NoReport /> : (
                        <>
                            {
                                rows.map((item, tableIdx) => {
                                    return (
                                        <>
                                            <Helmet>
                                                <style type="text/css">
                                                    {`@page {size: landscape}`}
                                                </style>
                                            </Helmet>
                                            <div className="print-report zoom-60 print-mt-120">
                                                <div style={{ paddingTop: '24px' }} />
                                                <h5><DeviceHubIcon /> Category Name  : {item.category_name}</h5>
                                                <div className="report-summary-box mb-16">
                                                    <div className="item teal">
                                                        <p>
                                                            <span>Net Income</span>
                                                            <span>IDR {money(item.net_income)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <p>
                                                            <span>Sales Qty</span>
                                                            <span>{item.total_qty}</span>
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <p>
                                                            <span>sub total</span>
                                                            <span>IDR {money(item.sub_total)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <p>
                                                            <span>Discount</span>
                                                            <span>IDR {money(item.discount_value)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <p>
                                                            <span>Total</span>
                                                            <span>IDR {money(item.grand_total)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <p>
                                                            <span>HPP</span>
                                                            <span>IDR {money(item.hpp)}</span>
                                                        </p>
                                                    </div>
                                                    <div className="item">
                                                        <p>
                                                            <span>Other Cost</span>
                                                            <span>IDR {money(item.additional_cost)}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <NoPrint force={true}>
                                                    <div className="table-menu">
                                                        <div className="left">
                                                            <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                                                        </div>
                                                        <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                                    </div>
                                                </NoPrint>

                                                <SimpleBarHOC status={scroll}>
                                                    <table className="table-report">
                                                        <thead>
                                                            <tr>
                                                                {
                                                                    Object.keys(sort).map((key, idx) => (
                                                                        <ThSort
                                                                            key={`th-${idx}`}
                                                                            label={sort[key].label}
                                                                            sort={sort[key].has_sort}
                                                                            handleClick={() => sortColumn(key, tableIdx)}
                                                                            className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                                        />
                                                                    ))
                                                                }
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                item.details.map((detail, detailIdx) => (
                                                                    <tr key={detailIdx} className={detail.category === 'return' && 'row-red'}>
                                                                        <td>{detail.date}</td>
                                                                        <td>{detail.branch_name || 'Not available'}</td>
                                                                        <td>{detail.transaction_id}</td>
                                                                        <td className="td-text-right td-currency">{money(detail.sub_total)}</td>
                                                                        <td className="td-text-right td-currency">{money(detail.discount_value)}</td>
                                                                        <td className="td-text-right td-currency">{money(detail.grand_total)}</td>
                                                                        <td>{detail.type}</td>
                                                                        <td>{detail.category}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </SimpleBarHOC>
                                            </div>
                                        </>
                                    )
                                })
                            }
                        </>
                    )
            }


        </div>
    )
}
