/**
 *
 * @param {key name of column} changedColumn
 * @param {asc/desc} direction
 * @param {table data} data
 */
export const CustomSortColumn = (changedColumn, direction, data) => {
  if (!data) return

  const key = changedColumn.toString()

  return data.sort((prev, next) => {
    switch (getDataType(data, key)) {
      case 'string':
        const propOrderSort = getLengthOrderSort(prev, next, key)
        const directionOrderSort = getOrderSort(direction)

        return propOrderSort * directionOrderSort

      case 'number':
        return direction === 'asc'
          ? prev[key] - next[key]
          : next[key] - prev[key]

      default:
        return 1
    }
  })
}

const getDataType = (data, key) => {
  return typeof data[0][key]
}

const getLengthOrderSort = (prev, next, key) => {
  if (!prev[key]) prev[key] = ''
  if (!next[key]) next[key] = ''

  return (prev[key] > next[key]) - (prev[key] < next[key])
}

const getOrderSort = (direction) => {
  return direction === 'asc' ? 1 : -1
}
