import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl, makeGetParam, handleRequest, reqDelIDS } from 'Configs/request'

const type = 'SALES_ORDER'

export const SALES_ORDER_SET = 'SALES_ORDER_SET'
export const SALES_ORDER_ERROR = 'SALES_ORDER_ERROR'
export const SALES_ORDER_ADD = 'SALES_ORDER_ADD'
export const SALES_ORDER_SHOW = 'SALES_ORDER_SHOW'
export const SALES_ORDER_EDIT = 'SALES_ORDER_EDIT'
export const SALES_ORDER_UPDATE = 'SALES_ORDER_UPDATE'
export const SALES_ORDER_DELETE = 'SALES_ORDER_DELETE'
export const SALES_ORDER_LOADING = 'SALES_ORDER_LOADING'
export const SALES_ORDER_RESET = 'SALES_ORDER_RESET'
export const SALES_ORDER_STOP_LOADING = 'SALES_ORDER_STOP_LOADING'
export const SALES_ORDER_CONFIRM = 'SALES_ORDER_CONFIRM'
export const SALES_ORDER_REPORT_SET = 'SALES_ORDER_REPORT_SET'


export const addSalesOrder = data => async (dispatch, getState) => {
    data.is_active = data.is_active === 'true'
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/sales_order`, data),
        next: (payload) => dispatch({
            type: SALES_ORDER_ADD,
            payload
        })
    })
}
export const loadSalesOrder = () => async (dispatch, getState) => {
    // const fakeData =
    // {
    //     "status": "success",
    //     "data": [
    //         {
    //             'date': 'AAAA',
    //             'code': '1231241',
    //             'contact': 'Kontak 1',
    //             'sub_total': '350000',
    //             'discount': '40000',
    //             'total': '1000',
    //             'status': 'draft',
    //             'sales_order_products': [
    //                 {
    //                     'code': 'TS20-01-001S-BL',
    //                     'product_name': 'Tshirt Pria 001',
    //                     'price': '250.000',
    //                     'discount': '50.000',
    //                     'quantity': '5',
    //                     'unit': 'Pc',
    //                     'total': '1.000.000'
    //                 },
    //                 {
    //                     'code': 'TS20-01-002L-RD',
    //                     'product_name': 'Tshirt Wanita 002',
    //                     'price': '150.000',
    //                     'discount': '50.000',
    //                     'quantity': '5',
    //                     'unit': 'Pc',
    //                     'total': '500.000'
    //                 }
    //             ]
    //         },
    //         {
    //             'date': 'BBBB',
    //             'code': 'zzzzzzz',
    //             'contact': 'Kontak 2',
    //             'sub_total': '350000',
    //             'discount': '40000',
    //             'total': '1000',
    //             'status': 'BUKAN DRAFT'
    //         },
    //     ],
    //     "errors": []
    // }
    handleReq({
        type,
        dispatch,
        // body: () => fakeData,
        body: () => reqGet(`${hostUrl}/api/sales_order`),
        next: (payload) => dispatch({ type: SALES_ORDER_SET, payload })
    })
}
export const updateSalesOrder = (idEdit, data) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/sales_order/${idEdit}`, data),
        next: (payload) => dispatch({ type: SALES_ORDER_UPDATE, payload })
    })
}
export const setEditSalesOrder = idEdit => {
    return {
        type: SALES_ORDER_EDIT,
        payload: idEdit,
    }
}
export const showSalesOrder = idx => {
    return {
        type: SALES_ORDER_SHOW,
        payload: idx
    }
}
export const deleteSalesOrder = ids => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDelIDS(`${hostUrl}/api/sales_order/`, ids),
        next: () => dispatch({ type: SALES_ORDER_DELETE, payload: ids })
    })
}
export const resetStatusSalesOrder = () => {
    return {
        type: SALES_ORDER_RESET
    }
}
export const salesOrderGetById = (id) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/sales_order/${id}}`),
    })
}
export const updateStatusSalesOrder = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/sales_order/${type}/${id}`),
        next: (resp) => dispatch({ type: SALES_ORDER_CONFIRM, payload: { type, resp, id } })
    })
}

export const loadSalesOrderReport = () => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/sales/report`),
        next: (payload) => dispatch({ type: SALES_ORDER_REPORT_SET, payload })
    })
}
export const filterSalesOrderReport = (report, args = {}) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/sales/${report}${makeGetParam(args)}`),
    })
}

