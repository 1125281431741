import React, {useState} from 'react'
import { Link, withRouter } from 'react-router-dom'
import Fields from 'Comps/elements/Fields'
import Alert from 'Comps/elements/Alert'
import Button from 'Elements/Button'
import { Formik, Form } from 'formik';
import { fieldsToFormik, fieldsValidation } from '@/Helper'
import { userLogin, setToken, resetStatus, setAuthenticationData } from 'Reduxes/user/userActions'
import { useSelector, useDispatch } from 'react-redux';


const LoginUserForm = (props) => {
    const { error } = useSelector(state => state.userReducer)
    const dispatch = useDispatch();
    const [msg, setMsg] = useState('Recheck your credential.');
    const fields = [
        {
            name: 'username',
            label: 'username',
            validation: 'req',
            value: ''
        },
        {
            type: 'password',
            name: 'password',
            validation: 'req',
            label: 'password',
            value: ''
        }
    ]

    const formOpts = {
        initialValues: fieldsToFormik(fields),
        validate: v => fieldsValidation(v, fields),
        onSubmit: async (values, { setSubmitting }) => {
            let resp = await dispatch(userLogin(values));

            setSubmitting(false);

            if (resp.status) {
                dispatch(setToken(resp.data.token))

                delete resp.data.token;

                dispatch(setAuthenticationData(resp.data))

                props.history.push('/configuration')
            } else {
                setMsg(resp.data.errors)
            }
        }
    }

    return (
        <Formik {...formOpts}>
            {({ isSubmitting }) => (
                <Form autoComplete="off">
                    <h3 className="form-title">Sign In</h3>
                    {
                        error &&
                        <Alert hide={() => dispatch(resetStatus())} option={{ type: 'error', message: msg }} />
                    }
                    <Fields isSubmitting={isSubmitting} fields={fields} />

                    <Button
                        icon={isSubmitting ? 'spinner' : 'check-circle'}
                        label='Login'
                        disabled={isSubmitting}
                        type='submit'
                        style='OrangeLgBlock' />
                </Form>
            )}
        </Formik>
    )
}

export default withRouter(LoginUserForm)
