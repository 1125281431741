import React, { useState, useEffect } from 'react'
import { money, globalMessage, globalDialog } from '@/Helper'
import InputNumber from 'Elements/inputnumber/InputNumber';
import ButtonIcon from 'Elements/ButtonIcon'
import SelectData from 'Elements/SelectData';
import Select from 'Elements/select/Select'
import { v4 as uuidv4 } from 'uuid';
import ScanProduct from 'Elements/ScanProduct'
import SelectAsync from 'Elements/SelectAsync';
import { CircularProgress } from '@material-ui/core'
import ReactSelect from 'react-select'



export default function ProductManager(props) {

    const { getData: sendProducts, defaultData, dataErrStatus } = props;
    const [products, setProducts] = useState([{ key: uuidv4() }])
    const [isEditable, setEditable] = useState(false)
    const [isLoading, setLoading] = useState(false)

    // add product
    const addProduct = () => {
        setProducts([...products, { key: uuidv4() }]);
    }
    // remove product
    const removeProduct = id => {
        globalDialog(() =>
            setProducts(products.filter((product) => product.key !== id))
        )
    }

    // INPUT CHANGE : U O M
    const setUom = (index, val) => {

        setProducts(state => {
            return state.map((s, i) => {
                s.uom_id = i === index ? +val : +s.uom_id
                return s;
            })
        })
    }

    // INPUT QTY
    const setQty = (val, product) => {
        const { code } = product;

        setProducts(state => {
            return state.map(s => {
                if (s.code === code) {
                    s.qty = +val;
                    s.total_value = s.qty * s.sales_price
                }
                return s;
            });
        })
    }

    const setPrice = (val, index) => {

        const price = parseInt(val)
        setProducts(state => {
            return state.map((s, i) => {
                s.sales_price = i === index ? price : s.sales_price
                s.total_value = s.qty * s.sales_price
                return s;
            })
        })
    }

    const selectProduct = ({ row }, index) => {

        if (row) {
            const isDuplicate = products.find(product => product.code === row.code)

            if (isDuplicate) {
                globalMessage('Product has been added already', 'warning')
                return false
                // setProducts(state => {
                //     return state.filter((_, i) => i !== index)
                // });
            } else {
                setProducts(state => {
                    state[index] = { ...row, key: state[index].key };
                    state[index].qty = 0;
                    state[index].qty_receipt = 0;
                    state[index].total_value = 0;
                    state[index].product_location = "stock"
                    return [...state];
                });
            }
        }

    }

    // effect product receipt
    useEffect(() => {
        /* ngirim data ke parent sendProducts : props by alias */
        if (defaultData && defaultData.length === products.length) {
            setTimeout(() => {
                setLoading(false)
                sendProducts(products)
            }, 500)
        } else {
            sendProducts(products)
        }
    }, [products])


    // masang data default dari edit
    useEffect(() => {

        if (defaultData) {
            setEditable(true)
            setLoading(true)
            setProducts(
                defaultData.map(product => {
                    return {
                        id: product.id,
                        product_id: product.product_id,
                        sales_price: product.price,
                        qty_receipt: product.qty_received,
                        qty: product.qty,
                        uom_id: product.uom_id,
                        name: product.product_name,
                        code: product.barcode || product.product_code,
                        variant_type_id: product.variant_type_id,
                        total_value: (product.qty * product.price),
                        key: uuidv4(),
                        color_name: product.color_name,
                        variant_type_value: product.variant_type_value
                    }
                })
            )
        }
    }, [defaultData])
    /* ================================= */

    const handleScannerProduct = ({ master_product }) => {

        if (master_product.status) {
            const isFound = products.find(product => product.code === master_product.data[0]?.code)
            let data = master_product.data[0]

            if (master_product.data.length) {
                if (isFound) {
                    const updatedQty = products.map(product => {
                        if (product.code === isFound.code) {
                            product.qty_receipt = (product.qty_receipt || 0)
                            product.qty = (product.qty || 0) + 1
                            product.total_value = product.qty * product.sales_price
                        }
                        return product;
                    })
                    return updatedQty //kirim data ke actionScannerProduct
                } else {
                    data.qty = 1;
                    data.total_value = data.qty * data.sales_price;
                    data.qty_receipt = data.qty_receipt || 0;

                    if (products.length === 1 && !products[0].code) {
                        return ([{ ...data, key: uuidv4() }])
                    } else {
                        return ([...products, { ...data, key: uuidv4() }])
                    }
                }
            } else {
                dataErrStatus(true)
                return products
            }
        } else {
            dataErrStatus(true)
            return products
        }
    }

    const actionScannerProduct = (handle) => {
        setProducts(handle)
    }

    return (
        <div className="table-product style-2">
            <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} type={"receipt"} />
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Product Name</th>
                        <th>Product Code</th>
                        <th>Price</th>
                        <th>Qty</th>
                        <th>Qty Receipt</th>
                        <th>UoM</th>
                        <th>Total Value</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (isLoading && isEditable) ? <tr><td colSpan="9"><h1 style={{textAlign:'center'}}>Loading...</h1></td></tr> :
                        products.map((product, productIdx) => {
                            const { sales_price, name, code, uom_id, total_value, qty, qty_receipt, color_name, variant_type_value } = product;
                            
                            const productName =  name ?
                                 {
                                     label: `${name} ${color_name ? `- ${color_name}`: ''} ${variant_type_value ? `(${variant_type_value})` : ''}`,
                                     value: code
                                 } : null
                            return (
                                <tr key={product.key}>
                                    <td className="width-120">
                                        <div>
                                            {(productIdx > 0 || products.length > 1) && <ButtonIcon onClick={() => removeProduct(product.key)} icon="trash" theme="grey" />}
                                            {(productIdx === products.length - 1) && <ButtonIcon onClick={addProduct} icon="plus" theme="teal" />}
                                        </div>
                                    </td>
                                    <td className="width-250">
                                        {
                                            <SelectAsync
                                                type="productName"
                                                portal={true}
                                                name={product.key}
                                                cache={false}
                                                index={productIdx}
                                                options='productName'
                                                value={productName}
                                                handleChange={(x) => selectProduct(x, productIdx)}
                                                param={{ flat: true }}
                                                extraParams={{ status_product: 'active' }}
                                            />
                                        }                                   
                                    </td>
                                    <td className="width-160">
                                        {code || '-'}
                                    </td>
                                    <td className="text-left width-160">
                                        {/* {sales_price ? `IDR ${money(sales_price)}` : '-'} */}
                                        {
                                            sales_price != null ?
                                                <InputNumber
                                                    value={sales_price}
                                                    handleChange={(e) => setPrice(e, productIdx)}
                                                /> : "-"
                                        }
                                    </td>
                                    <td className="qtyInput width-as-content">
                                        {
                                            qty != null ?
                                                <InputNumber
                                                    withFieldContainer
                                                    value={qty}
                                                    handleChange={(val) => setQty(val, product)}
                                                /> : "-"
                                        }
                                    </td>
                                    <td className="qtyInput width-as-content">
                                        {qty_receipt != null ? qty_receipt : '-'

                                        }
                                    </td>
                                    <td className="width-200">
                                        {
                                            uom_id ?
                                                // <SelectData handleChange={(val) => setUom(productIdx, val)} value={uom_id} options="uom" name={`uom-${productIdx}`} />
                                                <ReactSelect 
                                                    label="UoM"
                                                    options={{label: 'Pieces', value: 1}}
                                                    value={{ label: 'Pieces', value: 1}}
                                                    name={`uom-${productIdx}`}
                                                    isDisabled={true}
                                                />
                                                : '-'
                                        }
                                    </td>
                                    <td className="width-130">
                                        {
                                            total_value != null ? `IDR. ${money(total_value)}` : '-'
                                        }
                                    </td>

                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}