import { combineReducers, createStore, applyMiddleware } from "redux";
import profileReducer from 'Reduxes/configuration/profile/profileReducer'
import branchReducer from 'Reduxes/configuration/branch/branchReducer'
import logActivityReducer from 'Reduxes/configuration/activitylog/logActivityReducer'

import memberReducer from 'Reduxes/member/memberReducer'
import contactReducer from 'Reduxes/contact/contactReducer'
import usercategoryReducer from 'Reduxes/usercategory/usercategoryReducer'

import warningReducer from 'Reduxes/inventory/warning/warningReducer'
import acquisitionReducer from 'Reduxes/inventory/acquisition/acquisitionReducer'
import deliveryReducer from 'Reduxes/inventory/delivery/deliveryReducer'
import colorReducer from 'Reduxes/inventory/configuration/color/colorReducer'
import unitReducer from 'Reduxes/inventory/configuration/unit/unitReducer'
import warehouseReducer from 'Reduxes/inventory/configuration/warehouse/warehouseReducer'
import categoryReducer from 'Reduxes/inventory/configuration/category/categoryReducer'
import brandReducer from 'Reduxes/inventory/configuration/brand/brandReducer'
import variantReducer from 'Reduxes/inventory/configuration/variant/variantReducer'
import productReducer from 'Reduxes/inventory/product/productReducer'
import rejectReducer from 'Reduxes/inventory/reject/rejectReducer'
import stockopnameReducer from 'Reduxes/inventory/stockopname/stockopnameReducer'
import userReducer from 'Reduxes/user/userReducer'
import loginCabangReducer from 'Reduxes/logincabang/loginCabangReducer'
import salesreturnReducer from 'Reduxes/consignment/salesreturn/salesreturnReducer'
import invoiceReducer from 'Reduxes/consignment/invoice/invoiceReducer'
import salesOrderReducer from 'Reduxes/consignment/salesorder/salesOrderReducer'
import paymentReducer from 'Reduxes/consignment/payment/paymentReducer'

import promotionReducer from 'Reduxes/pos/promotion/promotionReducer'
import reportReducer from 'Reduxes/pos/report/reportReducer'
import salesReducer from 'Reduxes/pos/sales/salesReducer'

import posReducer from 'Reduxes/pos/posReducer'
import edcReducer from 'Reduxes/pos/edc/edcReducer'
import promotionConfigurationReducer from 'Reduxes/pos/promotion_configuration/promotionConfigurationReducer'

import { default as promotionReducer2 } from 'Reduxes/other/promotion/promotionReducer'



import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

const rootReducer = combineReducers({
    acquisitionReducer,
    branchReducer,
    brandReducer,
    categoryReducer,
    contactReducer,
    colorReducer,
    deliveryReducer,
    edcReducer,
    invoiceReducer,
    loginCabangReducer,
    memberReducer,
    posReducer,
    profileReducer,
    productReducer,
    rejectReducer,
    reportReducer,
    salesreturnReducer,
    stockopnameReducer,
    usercategoryReducer,
    unitReducer,
    userReducer,
    variantReducer,
    warehouseReducer,
    warningReducer,
    paymentReducer,
    promotionReducer,
    promotionReducer2,
    salesOrderReducer,
    salesReducer,
    promotionConfigurationReducer,
    logActivityReducer,
})

const store = createStore(
    rootReducer,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
)

export default store
