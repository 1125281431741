import { handleRequest, reqGet, reqPost, hostUrl } from 'Configs/request'
export const USER_LOGIN = 'USER_LOGIN'
export const USER_LOADING = 'USER_LOADING'
export const USER_SET = 'USER_SET'
export const USER_ERROR = 'USER_ERROR'
export const USER_RESET = 'USER_RESET'
export const USER_AUTH = 'USER_AUTH'
export const USER_TOKEN = 'USER_TOKEN'
export const USER_BRANCH = 'USER_BRANCH'
export const USER_LOGOUT = 'USER_LOGOUT'
const type = "USER"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const userLogin = (credential) => async (dispatch) => {
    const { branchLogin } = JSON.parse(localStorage.getItem('phillipworks'));
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/login`, { ...credential, branchLogin }),
        next: (payload) => dispatch({ type: USER_LOGIN, payload })
    })
}
export const userLogout = () => async (dispatch) => {
    localStorage.removeItem('token');
    localStorage.removeItem('auth');

    dispatch({ type: USER_LOGOUT });
}
export const getUserById = (id) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/user/${id}`),
        next: (payload) => dispatch({ type: USER_AUTH, payload })
    })
}
export const setToken = (token) => (dispatch) => {
    localStorage.setItem("token", token);
    dispatch({ type: USER_TOKEN, payload: token });
}
export const setBranch = (branch) => (dispatch) => {
    localStorage.setItem('phillipworks', JSON.stringify({ branchLogin: branch }));
    dispatch({ type: USER_BRANCH, payload: branch });
}
export const setAuthenticationData = (user) => (dispatch) => {
    localStorage.setItem("auth", JSON.stringify(user));
    dispatch({ type: USER_AUTH, payload: user });
}
export const resetStatus = () => {
    return {
        type: USER_RESET
    }
}