import React from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'

function Detail(props) {

    const { data, showTable } = props;
    console.log('data detail', data)

    return (
        <ContentWrapper
            title="EDC Detail"
            subtitle={`Showing edc with id ${data.code || 'N/a'}`}
            buttons={[
                {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => showTable(),
                }
            ]}
        >
            <div className="detail-page">
                <div className="detail product">
                    <div className="col">
                        <div className="row">
                            <span className="key">EDC CODE</span>
                            <span className="val">{data.code}</span>
                        </div>
                        <div className="row">
                            <span className="key">Source</span>
                            <span className="val">{data.name}</span>
                        </div>
                    </div>
                </div>

                {
                    data.edc_item?.length > 0 && (<table className="table table-detail">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>EDC ID</th>
                                <th>Branch</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.edc_item.map((edc, edcIndex) => (
                                    <tr key={edcIndex}>
                                        <td>{edcIndex + 1}</td>
                                        <td>{edc.code}</td>
                                        <td>{edc.branch_name}</td>
                                        {
                                            edc.status === true ?
                                                <td>Active</td> :
                                                <td>Inactive</td>
                                        }
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>)
                }
            </div>
        </ContentWrapper>
    )
}

export default Detail
