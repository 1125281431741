import React, { useState, useEffect } from 'react'
import { money, handleSortState, sortData } from '@/Helper'
import TdSort from 'Elements/TdSort'

const headerEnd = {
    total_stock: {
        has_sort: true,
        sort_type: null,
        label: 'stock'
    },
    total_value: {
        has_sort: true,
        sort_type: null,
        label: 'value'
    },
    total_physic: {
        has_sort: true,
        sort_type: null,
        label: 'physic'
    },
    physic_value: {
        has_sort: true,
        sort_type: null,
        label: 'value'
    },
    deviation: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'dif'
    },
    deviation_value: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'value'
    },
    total_reject: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'reject'
    },
    total_reject_value: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'value'
    },
    total_reject_physic: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr cell-width-as-content',
        label: 'R. physic'
    },
    total_reject_physic_value: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'value'
    },
    total_reject_deviation: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr cell-width-as-content',
        label: 'R. Dif'
    },
    total_reject_deviation_value: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'value'
    },
}

export default function ChildRow({ data, branches, variants, longestVariant }) {

    const [rows, setRows] = useState(data)
    const [header, setHeader] = useState({
        no: {
            label: '',
            has_sort: false,
        },
        date: {
            has_sort: true,
            sort_type: null,
            label: 'date'
        },
        branch_name: {
            has_sort: true,
            sort_type: null,
            label: 'branch',
            colspan: 6,
        },
    })
    const sortColumn = (colName, variantIndex = 1000) => {

        setRows(prevState => {
            prevState = sortData({ type: header[colName].sort_type, column: colName, data: prevState })
            return [...prevState]
        })

        setHeader(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {

        let variantHeader = {}
        let initRows = []

        Object.keys(longestVariant).forEach((variant, variantIdx) => {
            for (let i = 0; i < 6; i++) {
                variantHeader[`variant${variantIdx}${i}`] = {
                    has_sort: true,
                    sort_type: null,
                    className: 'cell-tr',
                    label: ' '
                }
            }
        })
        initRows = branches.map(branch => {
            [...new Array(longestVariant.length)].forEach((x, xIdx) => {
                [...['stock', 'physic', 'deviation', 'reject', 'reject_physic', 'reject_deviation']].forEach((y, yIdx) => {
                    let ttl = 0

                    branch.variants.forEach((variant, variantIdx) => {
                        let types = variant.types;

                        ttl += types?.[xIdx]?.[y] ?? 0
                    })

                    branch[`variant${xIdx}${yIdx}`] = ttl
                })
            })

            return branch
        })

        setRows(initRows)
        setHeader(prev => {
            return {
                ...prev,
                ...variantHeader,
                ...headerEnd
            }
        })

    }, [branches, longestVariant])


    return (
        <>
            {
                rows.map((branch, branchIdx) => (
                    <React.Fragment key={`subrows${branchIdx}`}>
                        {branchIdx < 1 && (
                            <tr className="tr-detail detail-heading">
                                {
                                    Object.keys(header).map((key, idx) => (
                                        <TdSort
                                            key={`th-${idx}`}
                                            label={header[key].label}
                                            rowspan={header[key].rowspan}
                                            colspan={header[key].colspan}
                                            sort={header[key].has_sort}
                                            handleClick={() => sortColumn(key)}
                                            className={`${header[key].className}  ${header[key].sort_type}`}
                                        />
                                    ))
                                }
                            </tr>
                        )}
                        <tr>
                            <td></td>
                            <td>{branch.date}</td>
                            <td colSpan={6}>{branch.branch_name}</td>
                            {
                                [...new Array(longestVariant.length)].map((x, xIdx) => { // yg looping 3x
                                    return (
                                        <React.Fragment key={xIdx}>
                                            {
                                                [...['stock', 'physic', 'deviation', 'reject', 'reject_physic', 'reject_deviation']].map((y, yIdx) => {

                                                    return (
                                                        <td>
                                                            {branch[`variant${xIdx}${yIdx}`]}
                                                        </td>
                                                    )
                                                })
                                            }
                                        </React.Fragment>
                                    )
                                })
                            }
                            <td>{branch.total_stock}</td>
                            <td className="cell-width-as-content">{money(branch.total_value)}</td>
                            <td>{branch.total_physic}</td>
                            <td className="cell-width-as-content">{money(branch.physic_value)}</td>
                            <td>{branch.deviation}</td>
                            <td className="cell-width-as-content">{money(branch.deviation_value)}</td>
                            <td>{branch.total_reject}</td>
                            <td className="cell-width-as-content">{money(branch.total_reject_value)}</td>
                            <td>{branch.total_reject_physic}</td>
                            <td className="cell-width-as-content">{money(branch.total_reject_physic_value)}</td>
                            <td>{branch.total_reject_deviation}</td>
                            <td className="cell-width-as-content">{money(branch.total_reject_deviation_value)}</td>
                        </tr>
                    </React.Fragment>
                ))
            }
        </>
    )
}
