import React, { useState, useEffect } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'Elements/select/Select';
import Datepicker from 'Elements/Datepicker';
import Multiselect from 'Elements/multiselect/Multiselect';
import InputNum from 'Elements/inputnum/InputNum';
import { filterTheme } from 'Configs/report'

const customerData = {
    'sales_order': [
        { label: 'Draft', value: 'draft' },
        { label: 'Confirmed', value: 'confirmed' },
        { label: 'Waiting', value: 'waiting' },
        { label: 'Received', value: 'received' },
        { label: 'Done', value: 'done' },
    ],
    'invoice': [
        { label: 'Draft', value: 'draft' },
        { label: 'Confirmed', value: 'confirmed' },
        { label: 'Closed', value: 'closed' },
    ],
    'payment': [
        { label: 'Draft', value: 'draft' },
        { label: 'Open', value: 'open' },
        { label: 'Paid', value: 'paid' },
    ]
}
const useStyles = makeStyles((theme) => (filterTheme(theme)))

export default function FilterGeneral({ getData, type }) {

    /* STATE */
    const classes = useStyles();
    const [showExtraFilter, setShowExtraFilter] = useState(false);
    const [filters, setFilters] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    /* METHOD */
    const toggleExtraFilter = event => {
        event.preventDefault()
        setShowExtraFilter(state => !state);
    }
    const handleChange = (param) => { // handle change yang terjadi pada field yg ada di extra filter
        if (param.props.name === 'start_date' || param.props.name === 'end_date') { // start date, end date

            if (param.value === 'Invalid date') { // jika invalida hapus key start date or end date
                setFilters(state => {
                    let { [param.props.name]: remove, ...rest } = state;
                    return { ...rest }
                })
                return;
            }

            // untuk minDate nya end_date
            if (param.props.name === 'start_date') {
                setStartDate(param.value);
            }

            setFilters(state => {
                return {
                    ...state,
                    [param.props.name]: param.value
                }
            })
        } else if (param.props.name === 'start_price' || param.props.name === 'end_price' || param.props.name === 'commission_from' || param.props.name === 'commission_to') {
            setFilters(state => {
                return {
                    ...state,
                    [param.props.name]: param.value
                }
            })
        } else if (param.props.name === 'product_id') {
            setFilters(state => {
                return {
                    ...state,
                    [param.props.name]: param.selected.value
                }
            })

        } else if (Array.isArray(param.value)) { // saat ini jika array diasumsikan berarti dari selectbox [{label: 1, value: 1}] *case lain  jika array juga belum di handle

            if (param.isAll) { // jika ini dari multi select menandakan semua opsi dipilih jadi tidak perlu kirim apa2 ke server
                setFilters(state => {
                    let { [param.props.name]: remove, ...rest } = state;
                    return { ...rest }
                })
            } else {
                setFilters(state => {
                    return {
                        ...state,
                        [param.props.name]: param.value.map(x => x.value)
                    }
                })
            }


        }
    }

    /* EFFECT */
    useEffect(() => {
        getData(filters)
    }, [filters])
    useEffect(() => {
        setFilters({})
    }, [showExtraFilter])
    return (
        <div className={classes.formextrafilter}>
            <button
                className={`${classes.toggler} ${showExtraFilter ? 'teal' : ''}`}
                onClick={toggleExtraFilter}>
                {showExtraFilter ? 'Hide' : 'Show'} Extra Filter
                    {showExtraFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </button>
            {
                showExtraFilter &&
                <div className={classes.formextrafiltercontainer}>
                    <div className={classes.formFilter}>
                        <div className={classes.daterangeCont}>
                            <Datepicker
                                handleChange={handleChange}
                                label="Start date"
                                name="start_date"
                                value="blank"
                            />
                            <Datepicker
                                {...(startDate ? { minDate: startDate } : {})}
                                name="end_date"
                                handleChange={handleChange}
                                label="end date"
                                value="blank"
                            />
                        </div>
                        {
                            (type === 'invoice' || type === 'payment' || type === 'brand') &&
                            <div className={classes.daterangeCont}>
                                <InputNum
                                    getData={handleChange}
                                    label="min total"
                                    name="start_price"
                                />
                                <InputNum
                                    name="end_price"
                                    getData={handleChange}
                                    label="max total"
                                />
                            </div>
                        }
                        {
                            (type === 'sales_order') &&
                            <Multiselect
                                label="category"
                                hasSelectAll
                                getData={handleChange}
                                name="category"
                                options={[
                                    { label: 'Sales', value: 'sales' },
                                    { label: 'Sales retur', value: 'sales_return' },
                                ]}
                            />
                        }
                        {
                            (type === 'sales_order' || type === 'invoice' || type === 'inventory' || type === 'payment') &&
                            <Multiselect
                                name="contact_id"
                                getData={handleChange}
                                hasSelectAll
                                label="Customer"
                                options="contact"
                            />
                        }
                        {
                            type === 'brand' &&
                            <>
                                <Multiselect
                                    name="brand_id"
                                    getData={handleChange}
                                    hasSelectAll
                                    label="brand"
                                    options="brand"
                                />
                                <div className={classes.daterangeCont}>
                                    <InputNum
                                        getData={handleChange}
                                        label="Commission from"
                                        name="commission_from"
                                    />
                                    <InputNum
                                        getData={handleChange}
                                        label="Commission to"
                                        name="commission_to"
                                    />
                                </div>
                            </>

                        }
                        {
                            (type === 'sales_order' || type === 'invoice' || type === 'payment') &&
                            <Multiselect
                                name="status"
                                hasSelectAll
                                getData={handleChange}
                                label="status"
                                options={customerData[type]}
                            />
                        }
                    </div>
                </div>
            }
        </div>
    )
}
