import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { showPromotionById } from 'Reduxes/pos/promotion_configuration/promotionConfigurationActions'
import { useDispatch } from 'react-redux'
import LoadingContent from 'Elements/LoadingContent'
import PrintProvider, { Print } from "react-easy-print";
import KopSurat from 'Elements/KopSurat'
import Chip from '@material-ui/core/Chip';


function Detail(props) {
    const { showTable, rowId } = props

    const [data, setData] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        const fetchRow = async () => {
            const resp = await dispatch(showPromotionById(rowId))
            setData(resp.data)
        }
        fetchRow()
    }, [])

    const parsedTarget = (args) => {
        // const parsed = args.split('_')
        const result = args.map(word => {
            if (word === 'all_product') {
                return 'All Product'
            } else if (word === 'all_brand') {
                return 'All Brand'
            } else {
                return word.charAt(0).toUpperCase() + word.slice(1)
            }
        }).join(', ')
        return result
    }

    return (
        <>
            {
                !data ? <LoadingContent /> :
                    <PrintProvider>
                        <Print single name="print-pc-detail-1" >
                            <KopSurat title="Promotion" nomor={`${data.code || 'N/a'}`} />
                            <div className="spacer-30"></div>
                            <ContentWrapper
                                title="Promotion Detail"
                                subtitle={`Showing promotion with id ${data.code || 'N/a'}`}
                                buttons={[
                                    {
                                        label: 'Go back',
                                        style: 'WhiteMd',
                                        icon: 'arrow-left',
                                        onClick: () => showTable(),
                                    },
                                    {
                                        label: "Print",
                                        style: "DarkblueMd",
                                        marginLeft: "ml-10",
                                        icon: "print",
                                        onClick: () => window.print(),
                                        subMenu: "promotion",
                                        type: "print",
                                    },
                                ]}
                            >
                                <div className="detail-page">
                                    <div className="detail product">
                                        <div className="col">
                                            <div className="row">
                                                <span className="key">ID</span>
                                                <span className="val">{data.code}</span>
                                            </div>
                                            <div className="row">
                                                <span className="key">Promotion Name</span>
                                                <span className="val">{data.name}</span>
                                            </div>
                                            {
                                                data.promotion_branches?.length > 0 && (
                                                    <div className="row">
                                                        <span className="key">Branch</span>
                                                        {
                                                            <span className="val">
                                                                {
                                                                    data.promotion_branches.map((branch) => branch.branch_code + " | ")
                                                                }
                                                            </span>
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                rowId > 1 && (
                                                    <div className="row">
                                                        <span className="key">Promotion Type</span>
                                                        <span className="val">{data.type.charAt(0).toUpperCase() + data.type.slice(1)}</span>
                                                    </div>
                                                )
                                            }
                                        </div>

                                        {
                                            rowId > 1 && (
                                                <div className="col">
                                                    <div className="row">
                                                        <span className="key">Start Date</span>
                                                        <span className="val">{data.date_start}</span>
                                                    </div>
                                                    <div className="row">
                                                        <span className="key">End Date</span>
                                                        <span className="val">{data.date_end}</span>
                                                    </div>
                                                    {
                                                        data.type !== 'voucher' &&
                                                        <>
                                                            <div className="row">
                                                                <span className="key">User Target</span>
                                                                <span className="val">{parsedTarget(data.user_target)}</span>
                                                            </div>
                                                            <div className="row">
                                                                <span className="key">Returnable</span>
                                                                <span className="val">{data?.returnable ? 'Yes' : 'No'}</span>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            )
                                        }

                                        {
                                            data.type === 'quantity' && rowId > 1 &&
                                            <>
                                                <div className="col">
                                                    <div className="row">
                                                        <span className="key">Buying Quantity</span>
                                                        <span className="val">{data.values.qty_buy}</span>
                                                    </div>
                                                    <div className="row">
                                                        <span className="key">Quantity Bonus</span>
                                                        <span className="val">{data.values.qty_bonus}</span>
                                                    </div>
                                                    {/* <div className="row">
                                                        <span className="key">Brand Target</span>
                                                        <span className="val">{parsedTarget(data.brand_target)}</span>
                                                    </div> */}
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <span className="key">Brand Target</span>
                                                        <span className="val">{parsedTarget(data.brand_target)}</span>
                                                    </div>
                                                    {/* BUG BY ALUR NOMOR 40, MAXIMUM VALUE BONUS DI HIDE DULU */}
                                                    {/* <div className="row">
                                                        <span className="key">Maximum Value Bonus</span>
                                                        <span className="val">{data.values.maximum}</span>
                                                    </div> */}
                                                    <div className="row">
                                                        <span className="key">Product Target</span>
                                                        <span className="val">{parsedTarget(data.item_target)}</span>
                                                    </div>
                                                </div>
                                                {
                                                    data.promotion_brand.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Brand Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_brand.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_product.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Product Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_product.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_category.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Category Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_category.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                            </>
                                        }
                                        {
                                            data.type === 'percent' &&
                                            <>
                                                {rowId > 1 && <div className="col">
                                                    <div className="row">
                                                        <span className="key">Discount</span>
                                                        <span className="val">{data.values.discount}</span>
                                                    </div>
                                                    <div className="row">
                                                        <span className="key">Brand Target</span>
                                                        <span className="val">{parsedTarget(data.brand_target)}</span>
                                                    </div>
                                                </div>}
                                                <div className="col">
                                                    <div className="row">
                                                        <span className="key">Minimum Purchase</span>
                                                        <span className="val">{data.values.minimum}</span>
                                                    </div>
                                                    {rowId > 1 && <div className="row">
                                                        <span className="key">Item Target</span>
                                                        <span className="val">{parsedTarget(data.item_target)}</span>
                                                    </div>}
                                                </div>
                                                {
                                                    data.promotion_brand.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Brand Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_brand.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_product.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Product Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_product.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_category.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Category Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_category.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                            </>
                                        }
                                        {
                                            data.type === 'giveaway' &&
                                            <>
                                                <div className="col">
                                                    {
                                                        rowId > 1 &&
                                                        <div className="row">
                                                            <span className="key">Brand Target</span>
                                                            <span className="val">{parsedTarget(data.brand_target)}</span>
                                                        </div>
                                                    }
                                                </div>
                                                {rowId > 1 &&
                                                    <div className="col">
                                                        <div className="row">
                                                            <span className="key">Item Target</span>
                                                            <span className="val">{parsedTarget(data.item_target)}</span>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    rowId > 1 &&
                                                    <div className="row">
                                                        <span className="key">Quantity Bonus</span>
                                                        <span className="val">{data.values.giveaway_product_qty}</span>
                                                    </div>
                                                }
                                                {
                                                    data.promotion_brand.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Brand Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_brand.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_product.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Product Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_product.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_category.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Category Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_category.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }

                                                {
                                                    data.values?.giveaways?.length > 0 && (
                                                        <table className="table table-detail" style={{width: '700px'}}>
                                                            <thead>
                                                                <tr>
                                                                    <th>No.</th>
                                                                    <th>Minimum Purchase</th>
                                                                    <th>Products Bonus</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    data?.values?.giveaways?.map((giveaway, giveawayIndex) => {
                                                                        return (
                                                                            <tr key={giveawayIndex}>
                                                                                <td style={{ padding: '25px' }}>{giveawayIndex + 1}</td>
                                                                                <td style={{ padding: '25px' }}>{giveaway.minimum_purchase}</td>
                                                                                <td style={{ padding: '25px' }}>
                                                                                    {giveaway?.products?.map((product, productIndex) => {
                                                                                        return <Chip label={product.label} variant="outlined" />
                                                                                    })}
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })?.sort((prev, next) => next.minimum_purchase - prev.minimum_purchase)
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                            </>
                                        }
                                        {
                                            data.type === 'voucher' &&
                                            <>
                                                {rowId > 1 && <div className="col">
                                                    <div className="row">
                                                        <span className="key">Value</span>
                                                        <span className="val">{data.values.value}</span>
                                                    </div>
                                                </div>}

                                                <div className="col">
                                                    <div className="row">
                                                        <span className="key">Minimum Purchase</span>
                                                        <span className="val">{data.values.minimum}</span>
                                                    </div>
                                                </div>
                                                {
                                                    data.promotion_brand.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Brand Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_brand.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_product.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Product Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_product.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                                {
                                                    data.promotion_category.length > 0 && rowId > 1 && (<table className="table table-detail">
                                                        <thead>
                                                            <tr>
                                                                <th>No.</th>
                                                                <th>ID</th>
                                                                <th>Category Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data.promotion_category.map((promotion, promotionIndex) => {
                                                                    return (
                                                                        <tr key={promotionIndex}>
                                                                            <td>{promotionIndex + 1}</td>
                                                                            <td>{promotion.code}</td>
                                                                            <td>{promotion.name}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </tbody>
                                                    </table>)
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </ContentWrapper>
                        </Print>
                    </PrintProvider>
            }
        </>
    )
}

export default Detail
