import React from "react";
import Button from "Elements/Button";
import Permission from "../permission/Permission";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { v4 as uuidv4 } from 'uuid';

function ContentWrapper({ children, buttons, title, subtitle }) {
  return (
    <div className="module">
      <div className="module-header">
        <div>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
        <div>
          <PrintProvider>
            <NoPrint>
              {Array.isArray(buttons) &&
                buttons &&
                buttons.map((btn, btnI) => {
                  return (
                    Object.keys(btn).length > 0 && (
                      <Permission
                        key={uuidv4()}
                        component={<Button key={btnI} {...btn} />}
                        subMenu={btn.subMenu}
                        type={btn.type}
                      />
                    )
                  );
                })}
              {!Array.isArray(buttons) && buttons}
            </NoPrint>
          </PrintProvider>
        </div>
      </div>
      {children}
    </div>
  );
}

export default ContentWrapper;
