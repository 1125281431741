import React from 'react'
import { Formik, Form } from 'formik';
import FieldsGroup from 'Elements/FieldsGroup'
import { useSelector, useDispatch } from 'react-redux';
import { fieldsToFormik, scrollToTop, fieldsValidation } from '@/Helper'
import { resetStatusWarehouse, updateWarehouse } from 'Reduxes/inventory/configuration/warehouse/warehouseActions'

import Alert from 'Comps/elements/Alert'
import Button from 'Elements/Button';
import { validationSchema } from './validation'

const FormEdit = (props) => {

    const { goToIndex, fieldGroup } = props;
    const { data, loading, error, idEdit } = useSelector(state => state.warehouseReducer)
    const dataEdit = data.filter((a, b) => a.id === idEdit)[0]

    console.log({dataEdit})

    const dispatch = useDispatch();  
    const formOpts = {
        initialValues: dataEdit,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => { 

            let result = await dispatch(updateWarehouse(idEdit, values))

            if (result) {
                goToIndex();
                resetForm();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }    

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusWarehouse())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts}>
                {(formik) => {
                    const { isSubmitting, values }  = formik;

                    return (
                        <Form className="form-member">
                            <FieldsGroup  fields={fieldGroup} /> 
                            <div className="fields-group-container buttons">
                                <Button
                                    type="submit"
                                    style="OrangeMdWide"
                                    label={isSubmitting ? 'Saving' : 'Save'}
                                    icon={isSubmitting ? 'spinner' : null}
                                    disabled={isSubmitting} />
                                <button disabled={isSubmitting} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default FormEdit