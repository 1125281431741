import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function LoadingContent() {
    return (
        <div className="loading-content">
            <FontAwesomeIcon spin icon="spinner" />
            <p>Please wait for a moment</p>
        </div>
    )
}
