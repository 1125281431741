import React from 'react'
import { money, showMonth } from '@/Helper'
import NoReport from 'Elements/NoReport'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

const setColor = (type) => {
    return type == 'sales_return' ? { color: 'red' } : { color: 'black' };
}

export default function TableDetail({ data, type }) {
    return data.length < 1 ? <NoReport /> : data.map((d, dIdx) => (
        <>
            {dIdx < 1 && <p className="asdasd" style={{ marginBottom: '40px' }}> Report Sales Order by Costumer Detail <span>Showing Report Sales Order by Costumer Detail</span></p>}
            <h5><DeviceHubIcon /> Partner Store  : {d.contact}</h5>
            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Net Income</span>
                        <span>IDR {money(d.income)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Sub Total</span>
                        <span>IDR {money(d.subtotal)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total</span>
                        <span>IDR {money(d.total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total Return</span>
                        <span>IDR {money(d.sr_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Discount</span>
                        <span>IDR {money(d.total_discount)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>HPP</span>
                        <span>IDR {money(d.hpp)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Other Cost</span>
                        <span>IDR {money(d.additional_cost)}</span>
                    </p>
                </div>
            </div>

            <div className="report-summary-pill">
                <p>
                    <span>Sales Order :</span>
                    <span>{d.so_counter}</span>
                </p>
                <p>
                    <span>Return Sales Order :</span>
                    <span>{d.sr_counter}</span>
                </p>
            </div>

            <table className="table-report">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>No Sales Order/Return</th>
                        <th className="th-text-right">Sub Total</th>
                        <th className="th-text-right">Discount</th>
                        <th className="th-text-right">Total</th>
                        <th className="th-text-right">HPP</th>
                        <th className="th-text-right">Other Cost</th>
                        <th className="th-text-right">Net Income</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        d.reports.map((report, index) => (
                            <tr key={index}>
                                <td style={setColor(report.type)}>{index + 1}</td>
                                <td style={setColor(report.type)}>{type === "month" ? showMonth(report.date) : report.date}</td>
                                <td style={setColor(report.type)} className="td-highlight">{report.code}</td>
                                <td style={setColor(report.type)} className="td-text-right">IDR {money(report.sub_total)}</td>
                                <td style={setColor(report.type)} className="td-text-right">IDR {money(report.total_discount)}</td>
                                <td style={setColor(report.type)} className="td-text-right">IDR {money(report.total)}</td>
                                <td style={setColor(report.type)} className="td-text-right">IDR {money(report.hpp)}</td>
                                <td style={setColor(report.type)} className="td-text-right">IDR {money(report.additional_cost)}</td>
                                <td style={setColor(report.type)} className="td-text-right">IDR {money(report.income)}</td>
                                <td style={setColor(report.type)}>{report.status}</td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </>
    ))
}