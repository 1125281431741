import { makeGetParam, handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl, handleRequest } from 'Configs/request'

const type = 'POS'

export const POS_LOADING = 'POS_LOADING'
export const POS_ERROR = 'POS_ERROR'
export const POS_SET = 'POS_SET'
export const POS_RESET = 'POS_RESET'


export const loadPosReport = (param) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/pos/report-general${makeGetParam(param)}`),
    })
}

export const loadPosCategoryReport = (param) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/pos/report-per-category${makeGetParam(param)}`),
    })
}

export const loadPosPromotionReport = (param) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/pos/report-per-promotion${makeGetParam(param)}`),
    })
}

export const loadPosBrandReport = (param) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/pos/report-per-brand${makeGetParam(param)}`),
    })
}

export const loadPosPaymentReport = (param) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/pos/report-per-payment-method${makeGetParam(param)}`),
    })
}

export const loadPosProductReport = (param) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/pos/report-per-product${makeGetParam(param)}`),
    })
}