import { handleReq, handleRequest, makeGetParam, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = 'CONTACT'

export const CONTACT_SET = 'CONTACT_SET'
export const CONTACT_ERROR = 'CONTACT_ERROR'
export const CONTACT_ADD = 'CONTACT_ADD'
export const CONTACT_SHOW = 'CONTACT_SHOW'
export const CONTACT_EDIT = 'CONTACT_EDIT'
export const CONTACT_UPDATE = 'CONTACT_UPDATE'
export const CONTACT_DELETE = 'CONTACT_DELETE'
export const CONTACT_LOADING = 'CONTACT_LOADING'
export const CONTACT_RESET = 'CONTACT_RESET'
export const CONTACT_SET_FILTER = 'CONTACT_SET_FILTER'



export const filterContact = (args = {}) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/contact${makeGetParam(args)}`),
    })
}
export const addContact = data => async (dispatch, getState) => {
    data.country = 'Indonesia';

    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/contact`, data),
        next: (payload) => dispatch({
            type: CONTACT_ADD,
            payload
        })
    })
}
export const loadContact = (param, args) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/contact${makeGetParam(param)}`),

        /* 
        disini ada update untuk kontak
        dihapus type nya (url/contact)
        tapi setelah fetch selesai data ga keupdate
        di redux nya
        jadi dicoba dihapus dulu conditionalnya
        */
        // next: (payload) => dispatch({ type: param ? CONTACT_SET_FILTER : CONTACT_SET, payload })
        next: (payload) => dispatch({ type: CONTACT_SET, payload })
    })
}
export const setEdit = idEdit => {
    return {
        type: CONTACT_EDIT,
        payload: idEdit,
    }
}
export const showContact = idx => {
    return {
        type: CONTACT_SHOW,
        payload: idx
    }
}
export const deleteContact = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/contact`, id),
        next: () => dispatch({ type: CONTACT_DELETE, payload: Array.isArray(id) ? id : [id] })
    })
}
export const resetStatusContact = () => {
    return {
        type: CONTACT_RESET
    }
}
export const updateContact = (idEdit, data) => async (dispatch, getState) => {
    // data.is_active = data.is_active === 'true'

    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/contact/${idEdit}`, data),
        next: (payload) => dispatch({
            type: CONTACT_UPDATE,
            payload
        })
    })
}
