import React, { useState, useEffect } from 'react'
import NoReport from 'Elements/NoReport'
import { money, sortData, handleSortState } from '@/Helper'
import KopSurat from "../../../comps/elements/KopSurat";
import ThSort from 'Elements/ThSort'
import { Helmet } from "react-helmet"
import { NoPrint } from "react-easy-print";
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC';


export default function TableTotalCategory({ data }) {

    const [scroll, setScroll] = useState(true)
    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            className: 'cell-width-as-content',
            has_sort: false,
        },
        category_name: {
            has_sort: true,
            sort_type: null,
            label: 'category',
        },
        total_qty: {
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
            label: 'qty',
            has_sort: true,
        },
        sub_total: {
            label: 'sub total',
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
        },
        discount_value: {
            label: 'discount',
            className: 'cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        grand_total: {
            label: 'total',
            className: 'th-text-right ',
            sort_type: null,
            has_sort: true,
        },
        hpp: {
            label: 'hpp',
            has_sort: true,
            className: 'th-text-right ',
            sort_type: null,
        },
        additional_cost: {
            label: 'ad. cost',
            className: 'th-text-right cell-width-as-content',
            sort_type: null,
            has_sort: true,
        },
        net_income: {
            label: 'total value',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
    })

    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {
        setRows(data)
    }, [data])


    const toggleScroll = () => {
        setScroll(!scroll)
    }


    return (
        <div className="scroll-x">
            {
                rows.length < 1 ?
                    <NoReport /> :
                    <>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <div className="print-report zoom-60">

                            <p className="asdasd print-mt-80">
                                POS Sales Report
                                <span>Showing POS report from all branches</span>
                            </p>

                            <NoPrint force={true}>
                                <div className="table-menu">
                                    <div className="left">
                                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scrollx</button>
                                    </div>
                                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                                </div>
                            </NoPrint>

                            <SimpleBarHOC status={scroll}>
                                <table className="table-report category print-report zoom-85">
                                    <thead>
                                        <tr>
                                            {
                                                Object.keys(sort).map((key, idx) => (
                                                    <ThSort
                                                        key={`th-${idx}`}
                                                        label={sort[key].label}
                                                        sort={sort[key].has_sort}
                                                        handleClick={() => sortColumn(key)}
                                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                    />
                                                ))
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            rows.map((d, dIdx) => (
                                                <tr>
                                                    <td className="td-index">{dIdx + 1}.</td>
                                                    <td className="td-highlight td-200">{d.category_name}</td>
                                                    <td>{d.total_qty}</td>
                                                    <td className="td-currency td-text-right">{money(d.sub_total)}</td>
                                                    <td className="td-currency td-text-right">{money(d.discount_value)}</td>
                                                    <td className="td-currency td-text-right">{money(d.grand_total)}</td>
                                                    <td className="td-currency td-text-right">{money(d.hpp)}</td>
                                                    <td className="td-currency td-text-right">{money(d.additional_cost)}</td>
                                                    <td className="td-currency td-text-right">{money(d.net_income)}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </SimpleBarHOC>

                        </div>
                    </>
            }
        </div>
    )
}
