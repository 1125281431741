import React, { useEffect, useState } from 'react'
import { money, sortData, handleSortState } from '@/Helper'
import NoReport from 'Elements/NoReport'
import { Helmet } from 'react-helmet'
import KopSurat from 'Elements/KopSurat'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import DetailRows from './DetailRows'
import SimpleBarHOC from '../../../../comps/elements/SimpleBarHOC'

export default function General({ data }) {
    const hasData = data.categories.length;
    let variants = null;
    let longestVariant = [];

    if (hasData) {
        variants = data.categories[0].variants;
        variants.forEach(variant => {
            longestVariant = variant.types.length > longestVariant.length ? variant.types : longestVariant;
        });
    }


    const [rows, setRows] = useState([])
    const [scroll, setScroll] = useState(true)
    const [showDetail, setShowDetail] = useState(false)
    const [variantSort, setVariantSort] = useState({})
    const [sort, setSort] = useState({
        no: {
            label: 'no.',
            has_sort: false,
            rowspan: variants.length + 1,
        },
        code: {
            label: 'code',
            sort_type: null,
            has_sort: true,
            rowspan: variants.length + 1,
        },
        product_type: {
            has_sort: true,
            sort_type: null,
            rowspan: variants.length + 1,
            label: 'type'
        },
        name: {
            has_sort: true,
            sort_type: null,
            rowspan: variants.length + 1,
            label: 'category'
        },
        total_product: {
            has_sort: true,
            rowspan: variants.length + 1,
            sort_type: null,
            label: '#product'
        },
        variant: {
            has_sort: false,
            sort_type: null,
            className: 'th-text-center',
            colspan: longestVariant.length,
            label: 'variant'
        },
        total_stock: {
            rowspan: variants.length + 1,
            has_sort: true,
            sort_type: null,
            label: 'stock'
        },
        total_reject: {
            has_sort: true,
            rowspan: variants.length + 1,
            sort_type: null,
            label: 'reject'
        },
        uom: {
            rowspan: variants.length + 1,
            has_sort: false,
            label: 'uom'
        },
        total_value: {
            has_sort: true,
            rowspan: variants.length + 1,
            className: "cell-tr",
            sort_type: null,
            label: 'total value'
        },

    })
    const sortColumn = (colName, variantIndex = 1000) => {

        if (variantIndex !== 1000) { // variamt sort
            setRows(prevState => {
                return [...sortData({ type: variantSort[`variant-${variantIndex}`].sort_type, column: colName, data: prevState })]
            })

            setVariantSort(prev => {
                return { ...handleSortState({ data: prev, column: `variant-${variantIndex}` }) }
            })
        } else {
            setRows(prevState => {
                return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
            })

            setSort(prev => {
                return { ...handleSortState({ data: prev, column: colName }) }
            })
        }
    }
    const toggleScroll = () => {
        setScroll(!scroll)
    }
    const toggleSubrow = () => {
        setShowDetail(!showDetail)
    }

    useEffect(() => {

        let initRows

        if (hasData) {

            initRows = data.categories.map((d, dIdx) => {

                // create variant property
                const variantProp = {}
                longestVariant.forEach((variant, variantIdx) => {
                    let qty = 0;
                    d.variants.forEach(variant => {
                        qty += variant?.types?.[variantIdx]?.stock ?? 0
                    })

                    variantProp[`variant${variantIdx}`] = qty
                })

                return { ...d, ...variantProp }
            })

            setVariantSort(prev => {
                longestVariant.forEach((v, i) => {
                    prev[`variant-${i}`] = {
                        sort_type: null,
                    }
                })

                return { ...prev }
            })
            setRows(initRows)
        }

    }, [data, longestVariant])



    return !hasData ? <NoReport /> : (
        <>
            <Helmet>
                <style type="text/css">
                    {`@page {size: landscape}`}
                </style>
            </Helmet>
            <KopSurat fit />
            <p className="asdasd" style={{ marginBottom: '0px' }}> Detail by Category <span>Showing detail stock per category</span></p>
            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Total Stock Value</span>
                        <span>IDR {money(data.total_value)}</span>
                    </p>
                </div>
            </div>
            <NoPrint force={true}>
                <div className="table-menu">
                    <div className="left">
                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                        <button onClick={toggleSubrow}>toggle subrows</button>
                    </div>
                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                </div>
            </NoPrint>

            <SimpleBarHOC status={scroll}>
                <table className="table-report inventory-stock">
                    <thead>
                        <tr>
                            {
                                Object.keys(sort).map((key, idx) => (
                                    <ThSort
                                        key={`th-${idx}`}
                                        label={sort[key].label}
                                        rowspan={sort[key].rowspan}
                                        colspan={sort[key].colspan}
                                        sort={sort[key].has_sort}
                                        handleClick={() => sortColumn(key)}
                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                    />
                                ))
                            }
                        </tr>
                        {
                            variants.map((variant, variantIdx) => {
                                return (
                                    <tr className={`sticky-variant-row-${variantIdx + 1}`}>
                                        {
                                            longestVariant.map((lv, lvIdx) => {
                                                return (
                                                    <ThSort
                                                        key={`th-variant-${lvIdx}`}
                                                        label={variant?.types?.[lvIdx]?.label ?? '-'}
                                                        sort={typeof (variant?.types?.[lvIdx]?.label) !== 'undefined'}
                                                        handleClick={() => sortColumn(`variant${lvIdx}`, lvIdx)}
                                                        className={variantSort?.[`variant-${lvIdx}`]?.sort_type}
                                                    />
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody>
                        {
                            rows.map((d, dIdx) => {
                                return (
                                    <DetailRows
                                        showDetail={showDetail}
                                        key={`trdetail${dIdx}`}
                                        no={dIdx + 1}
                                        variants={longestVariant}
                                        data={d}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </SimpleBarHOC>
        </>
    )
}
