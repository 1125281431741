import React from 'react'
import { money } from '@/Helper'
import NoReport from 'Elements/NoReport'




export default function TableGeneral({ data: d, type }) {

    return d.reports.length < 1 ? <NoReport /> : (
        <>
            <p className="asdasd" > Sales Order Report by Costumer <span>Showing Sales Order Report by Costumer</span></p>
            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Net Income</span>
                        <span>IDR {money(d.net_income)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Sub Total</span>
                        <span>IDR {money(d.sub_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total</span>
                        <span>IDR {money(d.grand_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total Return</span>
                        <span>IDR {money(d.sr_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Discount</span>
                        <span>IDR {money(d.total_discount)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>HPP</span>
                        <span>IDR {money(d.hpp)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Other Cost</span>
                        <span>IDR {money(d.additional_cost)}</span>
                    </p>
                </div>
            </div>
            <div className="report-summary-pill">
                {/* <p>
                    <span>Contact :</span>
                    <span>{d.contact_counter}</span>
                </p> */}
                <p>
                    <span>Sales Order :</span>
                    <span>{d.so_counter}</span>
                </p>
                <p>
                    <span>Return Sales Order :</span>
                    <span>{d.sr_counter}</span>
                </p>
            </div>

            <table className="table-report">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Code</th>
                        <th>Contact</th>
                        <th># Sales Order</th>
                        <th># Sales Return</th>
                        <th className="th-text-right">Subtotal</th>
                        <th className="th-text-right">Discount</th>
                        <th className="th-text-right">Total</th>
                        <th className="th-text-right">HPP</th>
                        <th className="th-text-right">Other Cost</th>
                        <th className="th-text-right">Net Income</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        d.reports.map((row, rowIdx) => (
                            <tr key={rowIdx}>
                                <td>{rowIdx + 1}</td>
                                <td>{row.code}</td>
                                <td>{row.contact}</td>
                                <td>{row.so_counter}</td>
                                <td>{row.sr_counter}</td>
                                <td className="td-text-right">IDR {money(row.sub_total)}</td>
                                <td className="td-text-right">IDR {money(row.total_discount)}</td>
                                <td className="td-text-right">IDR {money(row.grand_total)}</td>
                                <td className="td-text-right">IDR {money(row.hpp)}</td>
                                <td className="td-text-right">IDR {money(row.additional_cost)}</td>
                                <td className="td-text-right">IDR {money(row.net_income)}</td>
                            </tr>

                        ))
                    }
                </tbody>
            </table>
        </>
    )
}