import {
    SALES_ORDER_ADD,
    SALES_ORDER_DELETE,
    SALES_ORDER_EDIT,
    SALES_ORDER_ERROR,
    SALES_ORDER_LOADING,
    SALES_ORDER_RESET,
    SALES_ORDER_SET,
    SALES_ORDER_CONFIRM,
    SALES_ORDER_SHOW,
    SALES_ORDER_STOP_LOADING,
    SALES_ORDER_REPORT_SET,
    SALES_ORDER_UPDATE
} from './salesOrderActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const salesOrderReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case SALES_ORDER_SET:
            return {
                ...state,
                data: payload,
                error: null,
                loading: false,
            }
        case SALES_ORDER_REPORT_SET:
            return {
                ...state,
                data: payload,
                error: null,
                loading: false,
            }
        case SALES_ORDER_ADD:
            payload.is_active = payload.is_active === 1
            return {
                ...state,
                data: [payload, ...state.data],
                loading: false,
                error: null,
                success: 'New sales order has been added successfully.'
            }
        case SALES_ORDER_DELETE:
            return {
                ...state,
                // data: state.data.filter(deletedData => deletedData.id !== payload),
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
                success: 'Data has been deleted successfully',
            }
        case SALES_ORDER_EDIT:
            return {
                ...state,
                idEdit: payload,
                error: null,
                success: null,
            }
        case SALES_ORDER_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case SALES_ORDER_LOADING:
            return {
                ...state,
                loading: true
            }
        case SALES_ORDER_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case SALES_ORDER_SHOW:
            return {
                ...state,
                idShow: payload
            }
        case SALES_ORDER_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case SALES_ORDER_CONFIRM:
            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type === 'cancel' ? 'draft' : 'confirmed';
                }
                return d
            })

            return {
                ...state,
                success: payload.resp,
                data: newData

            }
        case SALES_ORDER_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        default:
            return state
    }
}

export default salesOrderReducer