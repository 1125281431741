import React from 'react'
import moment from 'moment'
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CustomToolbar from "../comps/elements/CustomToolbar";
import { write, utils } from 'xlsx';
import { saveAs } from 'file-saver';
import { dateDMYHIS, globalDialog } from '@/Helper'

export const tableOpt = {
    counter: 0,
    rowsPerPage: 10,
    filter: true,
    sortOrder: {},
    filterType: "textField",
    resizableColumns: true,
    selectableRows: true,
    responsive: 'stacked',  /* scroll */
    options: {
        // searchOpen: true,
        onRowClick: () => {
            // console.log('row clicked')
        },
        onSearchOpen: () => {
            // console.log('search open')
        },
        onRowsDelete: (a, b) => {
            // console.log({ a, b })
        },
    }
}
export const tableOpts = params => {

    const { colIdPosition, deleteAct, table, handleDelete, data, noPrint, filter, filename, customToolbarItems } = params;

    return {
        data,
        counter: 0,
        rowsPerPage: 5,
        filter: false,
        sortOrder: {},
        resizableColumns: true,
        selectableRows: false,
        responsive: 'stacked',  /* scroll */
        options: {
            textLabels: {
                toolbar: {
                    downloadCsv: "Download",
                },
            },
            responsive: false, //["standard", "vertical", "verticalAlways", "simple"].
            print: false,
            customToolbar: () => <CustomToolbar showPrint={!noPrint} customComponents={customToolbarItems} />,
            selectableRows: params?.showCheckbox ?? true,
            filter: filter,
            onDownload: (buildHead, buildBody, columns, values) => {
                const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                const fileExtension = '.xlsx';
                const date = dateDMYHIS(Date.now())

                // build the json, this is one way to do it
                const json = values.reduce((result, val) => {
                    const temp = {};
                    val.data.forEach((v, idx) => {

                        temp[columns[idx].label] = v;
                    });

                    result.push(temp);
                    return result;
                }, []);


                const fileName = `${filename}_` + date || 'Data_Philips_Works_' + date;

                const jsonOut = json.map((data, idx) => {
                    data['No.'] = idx + 1;
                    console.log()
                    delete (data.id)
                    if ('is_warning' in data) {
                        delete (data.is_warning)
                    }

                    if (data.Actions) {
                        delete (data.Actions)
                    }

                    if (data.Action) {
                        delete (data.Action)
                    }
                    if (data['#EDC']) {
                        data['#EDC'] = data['#EDC'].length
                    }
                    if (data.Address) {
                        if (typeof data.Address === 'array' || typeof data.Address === 'object') {
                            data.Address = data.Address.join(", ")
                        } else {
                            data.Address = data.Address
                        }
                    }
                    if ("User" in data && data.User.length > 0) {
                        data.User = data.User.join(',')
                    }
                    if ("Promotion" in data) {
                        const name = data.Promotion.find(x => x)
                        data.Promotion = name?.promotion?.promotion_name ?? ''
                    }
                    if (typeof data.Status === 'boolean' || typeof data.status === 'boolean') {
                        if (data.Status) {
                            data.Status = 'Active'
                        } else {
                            data.Status = 'Inactive'
                        }
                    }
                    if (data.Variant) {
                        if (typeof data.Variant === 'object' || typeof data.Variant === 'array') {
                            let countVal = []
                            data.Variant.map(d => {
                                countVal.push(d.value)
                            })
                            data.Variant = countVal.join(", ")
                        }
                    }
                    if (data.Type && Array.isArray(data.Type)) {
                        data.Type = data.Type.join(', ')
                    }
                    if (data['Date/Time']) {
                        data['Date/Time'] = moment(data['Date/Time']).format('DD-M-Y h:mm:ss')
                    }
                    return data
                })


                const ws = utils.json_to_sheet(jsonOut);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

                const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });

                saveAs(data, fileName + fileExtension);

                // cancel default  CSV download from table
                return false;

            },

            // filterType: 'textField',
            // sortFilterList :false,
            // searchOpen: true,
            onRowClick: () => {
                // console.log('row clicked')
            },
            onSearchOpen: () => {
                // console.log('search open')
            },
            onRowsDelete: (a, b) => {
                // console.log({ a, b })
            },
            customToolbarSelect: (selectedRows, displayData, setSelectedRows) => {

                // kolom dimana id berada
                // const ids = Object.keys(selectedRows.lookup).map(x =>displayData[parseInt(x)].data[colIdPosition])  // jika menggunakan ini, tidak bisa  multi delete untuk data hasil filtrasi


                // menampung id berdasarkan display data dalam tabel
                const ids = displayData.map(data => {
                    let count = null;
                    Object.keys(selectedRows.lookup).map(x => {
                        if (parseInt(x) === data.dataIndex) {
                            count = data.data[colIdPosition]
                        }
                    })
                    return count;
                }).filter(Boolean)

                return (
                    <div
                        style={{
                            padding: '8px 16px',
                            fontWeight: '600',
                            display: 'grid',
                            gridTemplateColumns: 'auto 1fr',
                            background: '#6684f2',
                            alignItems: 'center',
                            cursor: 'pointer',
                            color: 'white',
                            marginRight: '16px',
                            borderRadius: '3px',
                            fontSize: '13px',
                        }}
                        onClick={() => {
                            if (!handleDelete) {
                                globalDialog(() => deleteAct(ids))
                            } else {
                                handleDelete(ids);
                            }
                        }}
                    >
                        <DeleteIcon /> Delete selected
                    </div>
                )
            },
        },
        ...table,
    }
}
export const globalTableStyle = makeStyles(theme => ({
    table: {
        '& tbody': {
            '& tr': {
                '& td:last-child': {
                    borderRight: '1px solid #ddd !important',
                    // background: 'red !important'
                    // boxShadow: '0px -1px 0px red'
                }
            }
        },

        '& td:last-child': {
            width: '1%',
            whiteSpace: 'nowrap',
            textAlign: 'right',
        },
        '& td:nth-child(3)': {
        },
        '& .row-action': {
            color: '#555',
            fontSize: '16px',
            marginRight: '8px',
            cursor: 'pointer',
            '&.red': {
                cursor: 'auto',
                color: 'red'
            }

        },
        '& .url:hover': {
            color: '#f78300',
        },
        '& .address-col': {
            width: '300px'
        }
    },
}));
export const hideColumns = arrCols => {
    return arrCols.map(a => {
        // let filter = (
        //     a === 'country' || 
        //     a === 'province' || 
        //     a === 'postal_code' ||
        //     a === 'city' ||
        //     a === 'phone' 
        // )
        // console.log(a)

        return ({
            name: a,
            searchable: true,
            // options: { }
            // options: { display: false, filter }
            options: { display: false, filter: false }
            // options: { display: false, viewColumns: false, filter }
            // options: { viewColumns: false, }
        })
    })
}
