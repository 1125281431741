import React from 'react'

function FieldsContainer({children, subtitle, border, title}) {

    const childrenModified = border ? <div className="bordered">{children}</div> : <>{children}</>

    return (
        <div className={`fields-group-container ${ title.toLowerCase().replace(' ', '_') }`}>
            <p className="field-group-title">
                {title}
                {subtitle && <span>{subtitle}</span>}
            </p>
            {childrenModified}
        </div>
    )
}

export default FieldsContainer
