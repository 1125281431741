import React from 'react'
import ModuleHeader from 'Layouts/ModuleHeader'
import PropTypes from 'prop-types'

const Module = (props) => {
    return (
        <div className="module">
            <ModuleHeader {...props} />
            {props.children}
        </div>
    )
}

Module.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    button: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.object,
    ]),
    children: PropTypes.element.isRequired
}

export default Module
