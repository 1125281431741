import {
    LOGIN_BRANCH_SET,
    LOGIN_BRANCH_ERROR,
    LOGIN_BRANCH_RESET
} from './loginCabangActions'

const initialState = {
    isLogin: false,
    error: null,
    success: null,
    loading: false,
    branchId: null,
    branchLogin: null,
}

const loginCabangReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_BRANCH_SET:
            return {
                ...state,
                error: null,
                branchLogin: action.payload
            }
        case LOGIN_BRANCH_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case LOGIN_BRANCH_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        default:
            return state;
    }
}

export default loginCabangReducer