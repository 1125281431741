import React from 'react'
import { useSelector } from 'react-redux'
import { menus } from '../../utils/MenuConfig'

const Permission = ({component, menu, subMenu, type}) => {
    const { permission } = useSelector(state => state.userReducer)
    
    const visible = getIsVisible(menu, subMenu, type, permission);
    
    return (
        <>
            { visible && component }
        </>
    )
}

const getIsVisible = (menu, subMenu, type, permission) => {
    let status = false;

    if ((!(menu || subMenu)) || !type) {
        status = true;
    }

    if (subMenu) {
        permission.map((item) => {
            if (item.menu === subMenu && item.type === type) {
                status = true;
            }
        });
    }

    if (menu) {
        const menuIndex = menus.findIndex(menuItem => formatToSnakeCase(menuItem.label) === menu);

        if (menuIndex >= 0) {
            menus[menuIndex].subMenus.map((subMenuItem) => {
                permission.map((item) => {
                    if (item.menu === formatToSnakeCase(subMenuItem.name)) {
                        status = true
                    }
                })
            });
        }
    }

    return status;
}

const formatToSnakeCase = (name) => {
    return `${name.replace(' ', '_').toLowerCase()}`;
}    

export default Permission
