import React, { useEffect } from 'react'
import { Field, useFormikContext, ErrorMessage } from 'formik';

export default function Select(props) {

    const { name, label, value, options, col, placeholder, disabled } = props;
    let formikContext = useFormikContext();

    // useEffect(() => {
    //     console.log('options change', name, options)
    //     // formikContext.setFieldValue(name, options.length ? options[0].value : '');
    // }, [options])

    return (
        <div className={`field-container ${col ? `span-${col}` : ``}`}>
            <label htmlFor={name}>{label}</label>
            <Field name={name} value={value}>
                {(formik) => {
                    let { form, field } = formik;
                    const { setFieldValue, values } = form;
                    const { value, name } = field;



                    return (
                        <select
                            {...field}
                            disabled={disabled}
                            onChange={(v) => {
                                let newVal = v.currentTarget.value;
                                setFieldValue(name, v.currentTarget.value)

                                if (props.handleChange) {
                                    props.handleChange(newVal, form)
                                }
                            }}
                        >
                            {
                                (options.length ? options : [{ label: 'Option unavailable', value: '' }]).map((opt, optI) => (
                                    <option key={opt.value} value={opt.value}>{opt.label}</option>
                                ))
                            }
                        </select>
                    )
                }}
            </Field>
            <ErrorMessage name={name} component="div" className="errMsg" />
        </div>
    )
}
