import React, {useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addBranch, updateBranch, resetStatusBranch } from "Reduxes/configuration/branch/branchActions";
import { scrollToTop } from '@/Helper'
import { serverValue, matchEditValue } from 'Configs/Form';
import { validationSchema } from './validation'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import FormButton from 'Elements/FormButton'
import Input from 'Elements/Input';
import Select from 'Elements/select/Select'
import SelectData from 'Elements/SelectData';
import SelectAsync from 'Elements/SelectAsync';
import { filterMember, loadProvince, loadCity } from 'Reduxes/member/memberActions'


function FormElement(props) {

    const { type, showTable, fieldGroup } = props
    const dispatch = useDispatch();
    const { data, error, idEdit } = useSelector(state => state.branchReducer)
    const [initialValues, setInitialValues] = useState(type === 'new' ?
        {
            fieldGroup , is_active:true
        }
        : { ...data.filter(dataTarget => dataTarget.id === idEdit)[0] }
    )

    const formOpts = {
        initialValues: {
            ...initialValues,

        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            values = {
                ...values,
                country: "Indonesia",
            }

            let cleanValEdit = serverValue(matchEditValue(fieldGroup, values), true);


            let result = type === 'new' ?
                await dispatch(addBranch(cleanValEdit))
                : await dispatch(updateBranch(idEdit, cleanValEdit))

            if (result) {
                resetForm();
                showTable();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }


    
    // Handle chain address
    const [dataCity, setDataCity]= useState([])

    const handleChangeProvince = async val =>{
        setDataCity([])
        const resp = await dispatch(loadCity({ province_name :  val.selected.value }))
        setDataCity(resp.data.map(x => ({
            label: x.ibukota, value: x.ibukota
        })))
        if(!val.selected.value){
            setDataCity([])    
        }
    }

    useEffect(() => {
        const loadDataCity = async (province) =>{
            const resp = await dispatch(loadCity({ province_name :  province }))
            setDataCity(resp.data.map(x => ({
                label: x.ibukota, value: x.ibukota
            })))
        }
        if(props.type === "edit"){
            if(initialValues.province){
                loadDataCity(initialValues.province)
            }
        }
    }, [])

    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Branch" : "Update data branch"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data branch`}
        >
            {
                error && <Alert hide={() => dispatch(resetStatusBranch())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts}>
                {formik => {
                    const { isSubmitting } = formik;
                    return (
                        <Form className="form-layout-1">
                            <FieldsContainer
                                title="General Information"
                            >
                                <Input
                                    name="branch_code"
                                    label="Branch Code"
                                    onSubmitValidation={true}
                                />
                                <Input
                                    name="branch_name"
                                    label="Branch Name"
                                    onSubmitValidation={true}
                                />
                                {
                                  initialValues.id === 1 ?  
                                  <div></div>:
                                  <Select
                                        label="Status"
                                        name="is_active"
                                        options={
                                            [
                                                { label: "Active", value: true },
                                                { label: "Inactive", value: false },
                                            ]
                                        }
                                        onSubmitValidation={true}
                                    /> 
                                }

                            </FieldsContainer>
                            <FieldsContainer
                                title="Address Information"
                            >
                                <Input
                                    name="address"
                                    label="Address"
                                    onSubmitValidation={true}
                                />


                                <Select 
                                    label ="Province"
                                    name="province"
                                    type="province"
                                    portal
                                    options="province"
                                    isClearable
                                    getData={handleChangeProvince}

                                />

                                {
                                    dataCity.length > 0 &&
                                        <Select 
                                        label= "City"
                                        name="city"
                                        type="city"
                                        portal
                                        isClearable
                                        options={dataCity}
                                    />  
                                }
                                                                
                                <Input
                                    name="postal_code"
                                    label="Post Code"
                                />
                                <Input
                                    name="country"
                                    value="Indonesia"
                                    type="hidden"
                                />

                            </FieldsContainer>

                            <FieldsContainer
                                title="Contact Information"
                            >
                                <Input
                                    name="phone"
                                    label="Phone"
                                />
                                <Input
                                    name="email"
                                    label="Email"
                                />
                                <Input
                                    name="web_address"
                                    label="Website"
                                />
                            </FieldsContainer>
                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                        </Form>
                    )
                }}
            </Formik>

        </ContentWrapper>
    )
}

export default FormElement
