import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { datePost } from '@/Helper'

const useStyles = makeStyles((theme) => ({
    tableSign: {
        width: '100%',
        // border:'1px solid',
        textAlign: 'right'
    },
    // spacer50: {
    //     height: "50px"
    // },
    // spacer20: {
    //     height: "20px"
    // },
    createdBy: {
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end'
    },
    center: {
        textAlign: 'center',
        // backgroundColor:'red',

    },
    gapLeft: {
        marginRight: '70px'
    },
    gapRight: {
        marginLeft: '70px'
    },
    td: {
        fontSize: '15px',
        border: '1px solid rgb(255, 255, 255)',
        color: '#21495E'
    }


}));

export default function SignatureColumn({ typeContent, data }) {
    const localClass = useStyles();

    const renderContent = (contentType) => {

        if (contentType == "All") {
            return (
                <table className={localClass.tableSign} >
                    <tr className={localClass.center}>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}>
                            {/* <p >Created by: {data.created_by_contact_name}, {datePost(data.created_at)}</p> */}
                        </td>
                    </tr>
                    <div className={localClass.spacer20}></div>
                    <tr className={localClass.center}>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}>Received by,</td>
                        <td className={localClass.td}>Delivered by,</td>
                        <td className={localClass.td}>Checked by,</td>
                        <td className={localClass.td}>Created by,</td>
                    </tr>
                    <div className={localClass.spacer50}></div>
                    <div className={localClass.spacer50}></div>
                    <tr className={localClass.center}>
                        <td className={localClass.td}></td>

                        {/* <td className={localClass.td}>
                        <span className={localClass.gapLeft}>(</span> 
                        <span className={localClass.gapRight}>)</span>
                    </td>
                    <td className={localClass.td}>
                        <span className={localClass.gapLeft}>(</span> 
                        <span className={localClass.gapRight}>)</span>
                    </td>
                    <td className={localClass.td}>
                        <span className={localClass.gapLeft}>(</span> 
                        <span className={localClass.gapRight}>)</span>
                    </td>
                    <td className={localClass.td}>
                        <span className={localClass.gapLeft}>(</span> 
                        <span className={localClass.gapRight}>)</span>
                    </td> */}

                    </tr>

                </table>)
        } else if (contentType == "ReceivedDelivered") {
            return (
                <table className={localClass.tableSign} >
                    <tr className={localClass.center}>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}>
                            {/* <p >Created by: {data.created_by_contact_name}, {datePost(data.created_at)}</p> */}
                        </td>
                    </tr>
                    <div className={localClass.spacer20}></div>
                    <tr className={localClass.center}>
                        <td className={localClass.td}></td>
                        <td className={localClass.td}>Received by,</td>
                        <td className={localClass.td}>Delivered by,</td>

                    </tr>
                    <div className={localClass.spacer50}></div>
                    <div className={localClass.spacer50}></div>
                    <tr className={localClass.center}>
                        <td className={localClass.td}></td>
                        {/* <td className={localClass.td}>
                            <span className={localClass.gapLeft}>(</span> 
                            <span className={localClass.gapRight}>)</span>
                        </td>
                        <td className={localClass.td}>
                            <span className={localClass.gapLeft}>(</span> 
                            <span className={localClass.gapRight}>)</span>
                        </td> */}

                    </tr>
                </table>
            )
        } else if (contentType = "CheckedApproved") {
            return (<table className={localClass.tableSign} >
                <tr className={localClass.center}>
                    <td className={localClass.td}></td>
                    <td className={localClass.td}></td>
                    <td className={localClass.td}>
                        {/* <p >Created by: {data.created_by_contact_name}, {datePost(data.created_at)}</p> */}
                    </td>
                </tr>
                <div className={localClass.spacer20}></div>
                <tr className={localClass.center}>
                    <td className={localClass.td}></td>
                    <td className={localClass.td}>Checked by,</td>
                    <td className={localClass.td}>Approved by,</td>

                </tr>
                <div className={localClass.spacer50}></div>
                <div className={localClass.spacer50}></div>
                <tr className={localClass.center}>
                    <td className={localClass.td}></td>
                    {/* <td className={localClass.td}>
                            <span className={localClass.gapLeft}>(</span> 
                            <span className={localClass.gapRight}>)</span>
                        </td>
                        <td className={localClass.td}>
                            <span className={localClass.gapLeft}>(</span> 
                            <span className={localClass.gapRight}>)</span>
                        </td> */}

                </tr>
            </table>
            )
        }
    }



    return (
        <div>
            <div className={localClass.spacer50}></div>
            <div className="ext-print">
                <div className={localClass.createdBy}>
                    {
                        renderContent(typeContent)
                    }
                </div>
            </div>
        </div>
    )
}
