import React from 'react'

export default function VariantSort({ rowspan, label, colspan, sort, handleClick, className = '' }) {

    const props = {
        colSpan: colspan,
        rowSpan: rowspan,
        className: `${sort ? `cur-pointer sortable ` : ``} ${className}`
    }

    return sort ? (
        <td
            onClick={handleClick}
            {...props}
        >
            <div>
                <span>{label}</span>
                {
                    label &&
                    <div className="sort">
                        <div className="arrow-asc"></div>
                        <div className="arrow-desc"></div>
                    </div>
                }
            </div>
        </td>
    ) : <td {...props}>{label}</td>
}
