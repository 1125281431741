import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl, makeGetParam } from 'Configs/request'

const type = "INVOICE"

export const INVOICE_SET = 'INVOICE_SET'
export const INVOICE_ERROR = 'INVOICE_ERROR'
export const INVOICE_ADD = 'INVOICE_ADD'
export const INVOICE_SHOW = 'INVOICE_SHOW'
export const INVOICE_EDIT = 'INVOICE_EDIT'
export const INVOICE_UPDATE = 'INVOICE_UPDATE'
export const INVOICE_DELETE = 'INVOICE_DELETE'
export const INVOICE_LOADING = 'INVOICE_LOADING'
export const INVOICE_STOP_LOADING = 'INVOICE_STOP_LOADING'
export const INVOICE_RESET = 'INVOICE_RESET'
export const INVOICE_CONFIRM = 'INVOICE_CONFIRM'

export const loadInvoice = () => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/invoice`),
        next: (payload) => dispatch({ type: INVOICE_SET, payload })
    })
}
export const addInvoice = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/invoice`, data),
        next: (payload) => dispatch({ type: INVOICE_ADD, payload })
    })
}
export const updateInvoice = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/invoice/${idEdit}`, data),
        next: (payload) => dispatch({ type: INVOICE_UPDATE, payload })
    })
}
export const deleteInvoice = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/invoice/`, id),
        next: () => dispatch({ type: INVOICE_DELETE, payload: id })
    })
}
export const showInvoice = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/invoice/${idx}`),
    })
}
export const setEditInvoice = idEdit => {
    return {
        type: INVOICE_EDIT,
        payload: idEdit,
    }
}
export const resetStatusInvoice = () => {
    return {
        type: INVOICE_RESET
    }
}
export const updateStatus = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/invoice/${id}/${type}`),
        next: () => dispatch({ type: INVOICE_CONFIRM, payload: { type, id } })
    })
}

export const filterInvoice = (args = {}) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/invoice${makeGetParam(args)}`),
        next: (payload) => dispatch({ type: INVOICE_STOP_LOADING })
    })
}
export const filterInvoiceToPaid = (id) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/invoice/to-paid?contact_id=${id}&code=INV`),
        next: (payload) => dispatch({ type: INVOICE_STOP_LOADING })
    })
}
export const filterInvoiceReport = (report, args = {}) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/invoice/${report}${makeGetParam(args)}`),
    })
}