import {
    UNIT_LOADING,
    UNIT_SET,
    UNIT_ERROR,
    UNIT_ADD,
    UNIT_SHOW,
    UNIT_DELETE,
    UNIT_UPDATE,
    UNIT_RESET,
    UNIT_EDIT,
} from './unitActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
}

const unitReducer = (state = initialState, { payload, type }) => {

    switch (type) {
        case UNIT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case UNIT_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case UNIT_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case UNIT_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New unit has been added successfully.'
            }
        case UNIT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case UNIT_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case UNIT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case UNIT_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case UNIT_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default unitReducer