import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { updateStatus, getStockOpnameById } from 'Reduxes/inventory/stockopname/stockopnameActions'
import { useDispatch } from 'react-redux';
import SimpleModal from 'Elements/modal/Modal'
import WarningIcon from '@material-ui/icons/Warning';
import Button from 'Elements/Button'
import { setEditStockopname } from 'Reduxes/inventory/stockopname/stockopnameActions'
import { loadProduct } from 'Reduxes/inventory/product/productActions'
import { scrollToTop } from '@/Helper'
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import PrintProvider, { Print } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import KopSurat from 'Elements/KopSurat'
import Permission from '../../../comps/permission/Permission';
import LoadingContent from 'Elements/LoadingContent'
import { productOpnameColumns } from './TableFormProduct';
import ThSort from 'Elements/ThSort'

/**
 *  
 * <th>Stock Location</th>
    <th>Code</th>
    <th>Product Name</th>
    <th>Category</th>
    {isStockLocation(data) && <th className="width-as-content">Stock Qty</th>}
    {isRejectLocation(data) && <th className="width-as-content">Reject Qty</th>}
    <th>UoM</th>
 * 
 */

const columns = {
    location: {
        label: 'Location',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    code: {
        label: 'Code',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    product_name: {
        label: 'Product',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    category_name: {
        label: 'Category',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    qty_stock_physic: {
        label: 'Qty Stock',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    qty_reject_physic: {
        label: 'Qty Reject',
        className: 'cell-width-as-content',
        has_sort: true,
    },
    uom: {
        label: 'UoM',
        className: 'cell-width-as-content',
        has_sort: true,
    },
}

function Detail(props) {
    const { showTable, showForm, rowId } = props;
    // console.log(data)
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false)
    const [confirming, setConfirming] = useState(false)
    const [checking, setChecking] = useState(false)
    const [sortType, setSortType] = useState('asc')
    const [data, setData] = useState({
        branch_name: '',
        location: '',
        based_on: '',
        reference: '',
        status: '',
        date: ''
    })
    const productName = data?.stock_opname_item?.map(data => data?.product_name)
    const uniqueProductsName = [...new Set(productName)]
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!data) {
            setLoading(true)
        } else {
            setLoading(false)
        }
    }, [data])

    const renderProductType = (data) => {
        const statics = [
            { value: 'consignment', label: 'Consignment' },
            { value: 'product_sales', label: 'Product Sales' },
            { value: 'product_general', label: 'Product General' },
            { value: 'discontinue', label: 'Discontinue' }
        ];

        if (!data?.product_type) return '-';

        return statics.find((stat) => stat.value === data.product_type)?.label
    }

    const detailHeader = !data ? {} : {
        branch: data?.branch_name,
        location: data?.location === 'all_location' ? 'All Location' : data.location,
        'based on': data?.based_on === 'all_product' ? 'All Product' : data.based_on.charAt(0).toUpperCase() + data.based_on.slice(1),
        // 'category': data.based_on === 'category' ? data.stock_opname_category.map(data => data.category_name) : null,
        ...(data?.based_on === 'category' && { 'category': data.stock_opname_category.map(data => data.category_name) }),
        ...(data?.based_on === 'product' && {
            'product': uniqueProductsName.join(', ')
        }),
        reference: data?.reference || '-',
        status: data?.status,
        date: data?.date,
        'Created By': data?.created_by_contact_name,
        'Product Type': renderProductType(data),
    }

    const loadStockOpname = async (id) => {
        const res = await dispatch(getStockOpnameById(id))

        if (res.status) {
            setData(res.data)
        }
    }

    useEffect(() => {
        const fetchStockOpname = async () => {
            const resp = await dispatch(getStockOpnameById(rowId))

            if (resp.status) {
                setData(resp.data)
            }
        }

        fetchStockOpname()
    }, [])

    const handleRescan = () => {
        dispatch(setEditStockopname(data.id))
        dispatch(loadProduct());
        showForm('Edit')
        scrollToTop()
    }
    const handleConfirm = async () => {
        reqStatusOpen({ forced: true })
        await loadStockOpname(rowId)
    }
    const setStatusToChecked = async () => {
        setChecking(true);
        await dispatch(updateStatus(data.id, 'checked', {}))
        setChecking(false);
        await loadStockOpname(data.id)
    }
    const setStatusToDraft = async () => {
        setChecking(true);
        await dispatch(updateStatus(data.id, 'draft', {}))
        setChecking(false);
        await loadStockOpname(data.id)
    }
    const setStatusToOpen = async () => {
        if (data.result !== 'match') {
            setModalOpen(true)
        } else {
            reqStatusOpen({ forced: true });
        }
    }
    const reqStatusOpen = async (forced) => {
        setConfirming(true);
        await dispatch(updateStatus(data.id, 'open', forced))
        await loadStockOpname(data.id)
        setConfirming(false);
        setModalOpen(false);
        await loadStockOpname(data.id)
    }
    const ButtonStatus = () => {
        return (
            <>
                <Button
                    style='WhiteMd'
                    label="Go Back"
                    icon='arrow-left'
                    onClick={() => showTable()}
                />
                <Permission
                    component={
                        data?.status === 'draft' && <Button label="Open" disabled={checking} style="TileMd" onClick={setStatusToOpen} marginLeft="ml-10" />
                    }
                    subMenu="stock_opname"
                    type="create"
                />
                <Permission
                    component={
                        data?.status === 'open' && <Button disabled={checking} label={checking ? "Checking..." : "Reject"} style="TileMd" onClick={setStatusToDraft} marginLeft="ml-10" />
                    }
                    subMenu="stock_opname"
                    type="confirm"
                />
                <Permission
                    component={
                        data?.status === 'open' && <Button disabled={checking} label={checking ? "Checking..." : "Checked"} style="TileMd" onClick={setStatusToChecked} marginLeft="ml-10" />
                    }
                    subMenu="stock_opname"
                    type="confirm"
                />
                <Permission
                    component={
                        <Button
                            style='DarkblueMd'
                            label="Print"
                            icon='print'
                            marginLeft="ml-10"
                            onClick={() => window.print()}
                        />
                    }
                    subMenu="stock_opname"
                    type="print"
                />
            </>
        )
    }

    const isStockLocation = (stockOpname) => {
        return stockOpname.location === 'all_location' || stockOpname.location === 'stock'
    }

    const isRejectLocation = (stockOpname) => {
        return stockOpname.location === 'all_location' || stockOpname.location === 'reject'
    }

    const isDifference = (stockOpname) => {
        return stockOpname.result === 'selisih' && stockOpname.status !== 'draft'
    }

    const customSort = (columnName) => {
        const currentSo = { ...data }
        const soProducts = data.stock_opname_item.sort((prev, next) => {
            const prevValue = typeof prev === 'string' ? prev[columnName]?.split('') : prev[columnName]
            const nextValue = typeof next === 'string' ? next[columnName]?.split('') : next[columnName]
            switch (sortType) {
                case 'asc':
                    if (prevValue > nextValue) return 1
                    if (prevValue < nextValue) return -1
                    return 0
                case 'desc':
                    if (prevValue < nextValue) return 1
                    if (prevValue > nextValue) return -1
                    return 0
            }
        })

        currentSo.stock_opname_item = soProducts

        setSortType(sortType === 'asc' ? 'desc' : 'asc')

        setData(currentSo)
    }

    const getTableHeaders = () => {
        const clonedColumns = JSON.parse(JSON.stringify(columns))

        const renderTableHeaders = (currentColumns) => (
            Object.keys(currentColumns).map((key, index) =>
                <ThSort
                    key={`th-${index}`}
                    label={currentColumns[key].label}
                    sort={currentColumns[key].has_sort}
                    handleClick={() => customSort(key)}
                    className={''}
                />
            )
        )

        if (data.location === 'stock') {
            delete clonedColumns.qty_reject_physic
        }

        if (data.location === 'reject') {
            delete clonedColumns.qty_stock_physic
        }

        return renderTableHeaders(clonedColumns)
    }

    return (
        <PrintProvider>
            <Print single name="print-so-detail-1" >
                {
                    loading ? <LoadingContent /> :
                        <div className="print-report zoom-70">
                            <KopSurat title="Stock Opname" nomor={`${data?.code || 'N/a'}`} />
                            <ContentWrapper
                                title="Stock Opname Detail"
                                subtitle={`Showing Stock Opname with id ${data?.code || 'N/a'}`}
                                buttons={<ButtonStatus />}
                            >
                                <SimpleModal
                                    isOpen={modalOpen}
                                    setIsOpen={setModalOpen}
                                >
                                    <div className="modal-stockopname">
                                        <WarningIcon className="row-action red" />
                                        <p>Quantity Missmatch</p>
                                        <div className="button-container">
                                            <Button
                                                onClick={handleRescan}
                                                label="Re-scan"
                                                disabled={confirming} />
                                            <Button
                                                onClick={handleConfirm}
                                                label={confirming ? 'Confirming...' : 'Confirm'}
                                                disabled={confirming}
                                            />
                                        </div>
                                    </div>
                                </SimpleModal>
                                <DetailHeader data={detailHeader} />
                                {
                                    data?.stock_opname_item?.length > 0 && (<table className="table table-detail style-2">
                                        <thead>
                                            <tr>
                                                {
                                                    getTableHeaders()
                                                }
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.stock_opname_item.map((soi, soiIdx) => {

                                                    let showWarning

                                                    switch (data.location) {
                                                        case 'all_location':
                                                            showWarning = isDifference(data) && (soi.selisih_stock != 0 || soi.selisih_reject != 0)
                                                            break;
                                                        case 'stock':
                                                            showWarning = isDifference(data) && soi.selisih_stock != 0
                                                            break;
                                                        case 'reject':
                                                            showWarning = isDifference(data) && soi.selisih_reject != 0
                                                            break;
                                                        default:
                                                            showWarning = false
                                                    }

                                                    return (
                                                        <tr key={soiIdx}>
                                                            <td>{data.location === 'all_location' ? 'All Location' : data.location}</td>
                                                            <td>{soi.code}</td>
                                                            <td className="highlight">{soi.product_name} - {soi.color_name} {soi.variant_type_value ? `(${soi.variant_type_value})` : ''}</td>
                                                            <td>{soi.category_name}</td>
                                                            {isStockLocation(data) && <td className="width-as-content">{soi.qty_stock_physic}</td>}
                                                            {isRejectLocation(data) && <td className="width-as-content">{soi.qty_reject_physic}</td>}
                                                            {/* <td>{soi.uom_name}</td> */}
                                                            <td>Piece</td>
                                                            {showWarning ? <td className="width-as-content"><WarningIcon className="row-action red" /></td> : <td className="width-as-content"></td>}
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr className="total">
                                                {/* <td colSpan={isDifference(data) ? '8' : '7'} className="note" rowSpan="3"> */}
                                                <td colSpan='8' className="note" rowSpan="3">
                                                    <b>Notes : </b>
                                                    <p>{data.note}</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>)
                                }
                                <SignatureColumn typeContent={"CheckedApproved"} data={data} />
                            </ContentWrapper>
                        </div>
                }
            </Print>
        </PrintProvider>
    )
}

export default Detail