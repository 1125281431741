import { handleRequest, reqGet, hostUrl } from 'Configs/request'

const type = "WARNING"

export const WARNING_SET = 'WARNING_SET'

export const loadWarning = () => async (dispatch, getState) => {
    const resp = { "status": "success", "data": { "receivement": { "is_warning": 2 }, "shipment": { "is_warning": 1 }, "reject": { "is_warning": 0 }, "stock_opname": { "is_warning": 1 } }, "errors": [] }
    return handleRequest({
        type,
        dispatch,
        // body: () => resp,
        body: () => reqGet(`${hostUrl}/api/inventory`),
        next: (payload) => { dispatch({ type: WARNING_SET, payload }) }
    })
}