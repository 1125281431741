import React, { useState, useEffect, createElement } from 'react'
import Module from 'Layouts/Module'
import { useSelector, useDispatch } from 'react-redux';
import FormNew from './FormNew'
import TableData from './TableData'
import Detail from './Detail'
import FormEdit from './FormEdit'
import { scrollToTop, datePost, globalDialog } from '@/Helper'
import { loadColor, showColor, deleteColor, setEdit } from 'Reduxes/inventory/configuration/color/colorActions'

export default function Color(props) {

    const dev = 0;
    const { data, loading } = useSelector(state => state.colorReducer)
    const dispatch = useDispatch();
    const dataLength = data.length;
    const setContentActive = activeContent => {
        setContents(
            contents.map(content => {
                content.active = content.name === activeContent
                return content;
            })
        )
    }
    const fieldGroup = [
        {
            name: 'Color Information',
            fields: [
                {
                    label: 'code',
                    name: 'code',
                    value: ''
                },
                {
                    label: 'Color name',
                    col: 4,
                    name: 'name',
                    value: ''
                },
            ]
        }
    ]
    const [contents, setContents] = useState([
        {
            name: 'table',
            active: true,
            component: TableData,
            props: {
                data,
                setContentActiveFormEdit: id => {
                    dispatch(setEdit(id))
                    scrollToTop()
                    setContentActive('formedit')
                },
                setContentActiveDetail: (idShow) => {
                    dispatch(showColor(idShow))
                    setContentActive('detail')
                },
                deleteColorProps: id => {
                    globalDialog(async () => {
                        await dispatch(deleteColor(id))
                        scrollToTop()
                    })
                }
            },
            title: {
                title: 'Color',
                subtitle: 'Manage data color',
                button: {
                    label: 'Add color',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => {
                        setContentActive('form')
                    },
                    subMenu: 'color',
                    type: 'create',
                }
            }
        },
        {
            name: 'form',
            active: false,
            component: FormNew,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Add new Color',
                subtitle: 'Fill the form below to create new Color',
            },
        },
        {
            name: 'formedit',
            active: false,
            component: FormEdit,
            props: {
                fieldGroup,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Update Color',
                subtitle: 'Good to keep the Color data updated',
            }
        }, {
            name: 'detail',
            active: false,
            component: Detail,
            props: {
                setContentActiveFormEdit: idx => {
                    dispatch(showColor(idx))
                    setContentActive('formedit')
                },
            },
            title: {
                title: 'Color Detail',
                subtitle: '',
                button: {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => {
                        setContentActive('table')
                    },
                }
            }
        }
    ])

    useEffect(() => {
        dispatch(loadColor())
    }, [])

    return (
        <>
            {
                contents.map((c, cI) => {
                    if (c.active) {
                        return (
                            <Module {...c.title} key={cI}>
                                {createElement(c.component, c.props)}
                            </Module>
                        )
                    }
                })
            }
        </>
    )
}
