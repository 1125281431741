import Input from 'Elements/Input';
import { scrollToTop } from '@/Helper'
import { Formik, Form } from 'formik';
import Alert from 'Comps/elements/Alert'
import Textarea from 'Elements/Textarea';
import SelectData from 'Elements/SelectData'
import FormButton from 'Elements/FormButton'
import Select from 'Elements/select/Select';
import Datepicker from 'Elements/Datepicker';
import ProductManager from './ProductManager'
import React, { useState, useEffect } from 'react'
import { getLocalStorage, getLocalStorageAuth } from '../../../Helper';
import LoadingContent from 'Elements/LoadingContent'
import { useDispatch, useSelector } from 'react-redux'
import FieldsContainer from 'Elements/FieldsContainer'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { validationSchema } from './validation'
import { CircularProgress } from '@material-ui/core'

import { addDelivery, updateDelivery, resetStatusDelivery, getDeliveryById } from 'Reduxes/inventory/delivery/deliveryActions'

function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const { type, showTable, rowId } = props
    const dispatch = useDispatch()
    const [isReady] = useState(true);
    const [typeValue, setTypeValue] = useState('general');
    const { error } = useSelector(state => state.deliveryReducer);
    const [productSearchType] = useState('productName');
    const [inputError, setInputError] = useState(null)
    const { data: contacts } = useSelector(state => state.contactReducer);
    const { data: branch } = useSelector(state => state.branchReducer);
    const [address, setAddress] = useState(null);
    const [defaultData, setDefaultData] = useState(null) // untuk update
    // const currentBranch = getLocalStorage()?.branchLogin;
    const superAdmin = getLocalStorageAuth()?.user_category_id === 1 || getLocalStorageAuth()?.user_category_id === 2
    const [initialValues, setInitialValues] = useState({
        type: "",
        contact_id: '',
        branch_id: getLocalStorage().branchLogin.id,
        date: new Date().toISOString().split('T')[0],
        note: '',
        reference: '',
        status: "draft",
        variant_id: 0,
        product_shipment_item: [],
        _search_product_by: productSearchType === 'productName' ? 'search_by_product_name' : 'search_by_product_id',
        _product_id: 'aaaaa',
        _type: '',
        _price: 0,
        _qty: 0,
        _unit_of_measurement_id: '',
        _product_location: ''
    })
    const [branchId, setBranchId] = useState(null)
    const [productDelivery, setProductDelivery] = useState([]);
    const [isLoadingEdit, setLoadingEdit] = useState(false)

    // jemput data dari product manager
    const getData = products => {
        setProductDelivery(products);
    }

    const formOpts = {
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const productsDelv = [];
            const qty0 = productDelivery.find(item => item.qty === 0);
            if (qty0) {
                setInputError('Qty must greater than 0')
                setSubmitting(false)
                scrollToTop()
                return;
            } else {
                productDelivery.forEach(product => {
                    const qtyDelivery = product.qty;
                    if (qtyDelivery > 0) {
                        productsDelv.push({
                            product_id: product.product_id || product.id,
                            type: product.product_location,
                            price: product.sales_price,
                            qty: product.qty,
                            uom_id: product.uom_id,
                            variant_id: product.variant_id || null,
                            product_location: product.product_location,
                            variant_type_id: product.variant_type_id || null,

                        })
                    }

                })
                let cleanVal = {
                    "type": values.type,
                    "branch_id": +values.branch_id,
                    "contact_id": +values.contact_id,
                    "date": values.date,
                    "note": values.note,
                    "reference": values.reference,
                    "status": values.draft || 'draft',
                    "product_shipment_item": productsDelv
                }
                let result = type === 'new' ?
                    await dispatch(addDelivery(cleanVal))
                    : await dispatch(updateDelivery(rowId, cleanVal))
                if (result) {
                    resetForm();
                    showTable();
                }
                scrollToTop()
                setSubmitting(false)
            }
        }
    }

    const typeChangeHandler = (val) => {
        setTypeValue(val);
        setAddress(null);
        Object.assign(contactProps, {
            note: null,
        });
    }

    useEffect(() => {
        const fetchDataEdit = async () => {
            setLoadingEdit(true)
            const resp = await dispatch(getDeliveryById(rowId))
            const { type, reference, branch_id, contact_id, date, note, product_shipment_item, status, branch_name } = resp.data
            setTypeValue(type)
            setInitialValues({
                type,
                contact_id,
                branch_id,
                date,
                note,
                reference,
                status,
                product_shipment_item,
                _search_product_by: 'search_by_product_name',
                _product_id: 'aaaaa',
                _price: 0,
                _qty: 0,
                _qty_received: 0,
                _unit_of_measurement_id: ''
            })
            setBranchId({ label: branch_name, value: branch_id })
            setDefaultData(product_shipment_item)
        }
        if (type === 'edit') {
            fetchDataEdit();
            
            setTimeout(() => {
                if (branch && contacts) {
                    setLoadingEdit(false)
                }
            }, 2000)
        } else if (type === 'new') {
            setBranchId(getLocalStorage().branchLogin)
        }
    }, [])

    useEffect(() => {
        setAddress('')
    }, [contacts])

    const contactProps = {
        handleProps: (val) => {
            if (typeValue === 'general') {
                const contact = contacts.find(item => item.id === val.value)
                const parsedAddress = [contact?.address ? contact.address : null, contact?.city ? contact.city : null, contact?.province ? contact.province : null, contact?.country ? contact.country : null, contact?.postal_code ? contact.postal_code : null].filter(Boolean)
                setAddress(parsedAddress.join(', '))
            } else if (typeValue === 'internal_transfer') {
                const branches = branch.find(item => item.id === val.value)
                const parsedAddress = [branches?.address ? branches.address : null, branches?.city ? branches.city : null, branches?.province ? branches.province : null, branches?.country ? branches.country : null, branches?.postal_code ? branches.postal_code : null].filter(Boolean)
                setAddress(parsedAddress.join(', '))
            }
        },
        name: "contact_id",
        note: address,
        label: "To",
        showAddress: true,
    }

    const handleChangeBranch = (e) => {
        setBranchId(e)
    }

    return (
        <ContentWrapper
            title={type === 'new' ? "Add New Delivery" : "Update data Delivery"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data delivery`}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusDelivery())} option={{ type: 'error', message: error }} />
                }
                {
                    inputError && <Alert hide={() => setInputError(null)} option={{ type: 'error', message: inputError }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {
                                    const { isSubmitting } = formik;
                                    return (
                                        <Form className="form-layout-1">

                                            <FieldsContainer title="Delivery Information">
                                                <SelectData name="type" label="type"
                                                    handleChange={typeChangeHandler}
                                                    options={[
                                                        { label: 'General', value: 'general' },
                                                        { label: 'Internal Transfer', value: 'internal_transfer' },
                                                    ]}
                                                />
                                                <Select
                                                    name="branch_id"
                                                    options='branch'
                                                    label="Branch name"
                                                    isDisabled={!superAdmin}
                                                    handleProps={handleChangeBranch}
                                                />
                                                <Datepicker name="date" label="Date" />
                                                {
                                                    typeValue === 'general' &&
                                                    <Select {...contactProps} options="contact" />
                                                }
                                                {
                                                    typeValue === 'internal_transfer' &&
                                                    <Select {...contactProps} options="branch" />
                                                }
                                                <Input name="reference" label="Reference" />
                                                <div></div>
                                                <Textarea name="note" label="Notes" col="6" />
                                            </FieldsContainer>
                                            <div className="fields-group-container product_information">
                                                <p className="field-group-title">
                                                    Product Information
                                                    <span>You must add at least one product</span>
                                                </p>
                                            </div>
                                            {
                                                isLoadingEdit ? <CircularProgress
                                                size={24}
                                                style={{ marginLeft: 15, position: 'relative', top: 4 }}
                                              /> : <ProductManager defaultData={defaultData} getData={getData} branch={branchId} dataErrStatus={setDataErr} />  
                                            }
                                            <FormButton
                                                isSubmitting={isSubmitting}
                                                showTable={showTable}
                                            />
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </>
                    )
                        : <LoadingContent />
                }
            </>
        </ContentWrapper>
    )
}

export default FormElement
