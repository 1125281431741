import React, { useEffect, useState } from 'react'
import { money, sortData, handleSortState } from '@/Helper'
import NoReport from 'Elements/NoReport'
import { Helmet } from 'react-helmet'
import KopSurat from 'Elements/KopSurat'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import DetailRowParent from './DetailRowParent'
import SimpleBarHOC from '../../../../comps/elements/SimpleBarHOC'


export default function Detail({ data }) {


    let variants = [];
    let longestVariant = [];

    if (data.products.length) {
        variants = data.products[0].variants
        variants.forEach(variant => {
            longestVariant = variant.types.length > longestVariant.length ? variant.types : longestVariant
        })
    }

    const [rows, setRows] = useState([])
    const [showDetail, setShowDetail] = useState(false)
    const [scroll, setScroll] = useState(true)
    const [sort, setSort] = useState({
        no: {
            label: 'no.',
            has_sort: false,
            rowspan: variants.length + 1,
        },
        code: {
            label: 'code',
            className: 'cell-width-as-content cell-highlight',
            sort_type: null,
            has_sort: true,
            rowspan: variants.length + 1,
        },
        category: {
            has_sort: true,
            sort_type: null,
            rowspan: variants.length + 1,
            label: 'category'
        },
        name: {
            has_sort: true,
            sort_type: null,
            rowspan: variants.length + 1,
            label: 'name'
        },
        brand: {
            has_sort: true,
            rowspan: variants.length + 1,
            sort_type: null,
            label: 'brand'
        },
        sales_price: {
            has_sort: true,
            className: 'cell-width-as-content',
            rowspan: variants.length + 1,
            sort_type: null,
            label: 'sales price'
        },
        variant: {
            has_sort: false,
            className: 'th-text-center',
            colspan: longestVariant.length,
            label: 'variant'
        },
        stock: {
            rowspan: variants.length + 1,
            has_sort: true,
            sort_type: null,
            label: 'stock'
        },
        reject: {
            has_sort: true,
            rowspan: variants.length + 1,
            sort_type: null,
            label: 'reject'
        },
        uom_name: {
            sort_type: null,
            rowspan: variants.length + 1,
            has_sort: true,
            label: 'uom'
        },
        total_value: {
            has_sort: true,
            rowspan: variants.length + 1,
            className: "cell-tr",
            sort_type: null,
            label: 'total value'
        },

    })
    const [variantSort, setVariantSort] = useState({})
    const sortColumn = (colName, variantIndex = 1000) => {
        if (variantIndex !== 1000) { // variamt sort
            setRows(prevState => {
                return [...sortData({ type: variantSort[`variant-${variantIndex}`].sort_type, column: colName, data: prevState })]
            })

            setVariantSort(prev => {
                return { ...handleSortState({ data: prev, column: `variant-${variantIndex}` }) }
            })
        } else {
            setRows(prevState => {
                return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
            })

            setSort(prev => {
                return { ...handleSortState({ data: prev, column: colName }) }
            })
        }
    }
    const toggleScroll = () => {
        setScroll(!scroll)
    }
    const toggleSubRows = () => {
        setShowDetail(!showDetail)
    }

    useEffect(() => {

        let initRows

        if (data?.products?.length > 0) {
            initRows = data.products.map((d, dIdx) => {

                // create variant property
                const variantProp = {}
                longestVariant.forEach((variant, variantIdx) => {
                    let qty = 0;
    
                    data.products[dIdx].variants.forEach(variant => {
                        qty += variant?.types?.[variantIdx]?.stock ?? 0;
                    })
    
                    variantProp[`variant${variantIdx}`] = qty
                })
    
                return { ...d, ...variantProp }
            })
    
            setVariantSort(prev => {
                longestVariant.forEach((v, i) => {
                    prev[`variant-${i}`] = {
                        sort_type: null,
                    }
                })
    
                return { ...prev }
            })
            setRows(initRows)
        }

    }, [data, longestVariant])



    return data.products.length < 1 ? <NoReport /> : (
        <>
            <Helmet>
                <style type="text/css">
                    {`@page {size: landscape}`}
                </style>
            </Helmet>

            <KopSurat fit />
            <p className="asdasd" style={{ marginBottom: '40px' }}> Detail by Product <span>Showing detail stock transaction from all products</span></p>

            <div className="report-summary-box mb-16">
                <div className="item teal">
                    <p>
                        <span>Stock Value</span>
                        <span>IDR {money(data.total_value)}</span>
                    </p>
                </div>
            </div>

            <NoPrint force={true}>
                <div className="table-menu">
                    <div className="left">
                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                        <button onClick={toggleSubRows}>Toggle SubRows</button>
                    </div>
                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                </div>
            </NoPrint>

            <SimpleBarHOC status={scroll}>
                <table width="100%" className="table-report inventory-stock ">
                    <thead>
                        <tr>
                            {
                                Object.keys(sort).map((key, idx) => (
                                    <ThSort
                                        key={`th-${idx}`}
                                        label={sort[key].label}
                                        rowspan={sort[key].rowspan}
                                        colspan={sort[key].colspan}
                                        sort={sort[key].has_sort}
                                        handleClick={() => sortColumn(key)}
                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                    />
                                ))
                            }
                        </tr>
                        {
                            variants.map((variant, variantIdx) => {
                                return (
                                    <tr key={`trvaraintheader${variantIdx}`} className={`sticky-variant-row-${variantIdx + 1}`}>
                                        {
                                            longestVariant.map((lv, lvIdx) => {
                                                return (
                                                    <ThSort
                                                        key={`th-variant-${lvIdx}`}
                                                        label={variant?.types?.[lvIdx]?.label ?? '-'}
                                                        sort={typeof (variant?.types?.[lvIdx]?.label) !== 'undefined'}
                                                        handleClick={() => sortColumn(`variant${lvIdx}`, lvIdx)}
                                                        className={variantSort?.[`variant-${lvIdx}`]?.sort_type}
                                                    />
                                                )
                                            })
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody>
                        {
                            rows.map((d, dIdx) => {
                                return (
                                    <DetailRowParent
                                        showDetail={showDetail}
                                        key={`trdetail${dIdx}`}
                                        no={dIdx + 1}
                                        index={dIdx}
                                        variants={longestVariant}
                                        data={d}
                                        data2={rows}
                                    />
                                )
                            })
                        }
                    </tbody>
                </table>
            </SimpleBarHOC>

        </>
    )
}
