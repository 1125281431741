import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Select from 'Elements/select/Select';
import TableGeneral from './TableGeneral';
import TableDetail from './TableDetail';
import TableDetailPerCustomer from './TableDetailPerCustomer'
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch } from 'react-redux';
import LoadingContent from 'Elements/LoadingContent';
import { muiStyle, topButton, actionButton } from 'Configs/report'
import Filter from '../Filter'
import Button from 'Elements/Button';
import { makeGetParam, hostUrl, reqGet } from 'Configs/request.js';
import { globalMessage } from '../../../../Helper';
import PrintProvider, { Print } from "react-easy-print";
import KopSurat from "../../../../comps/elements/KopSurat";


const useStyles = makeStyles((theme) => (muiStyle))

const getUrlReport = {
    '1': `${hostUrl}/api/sales/report`,
    '2': `${hostUrl}/api/sales/report_per_costumer`,
    '3': `${hostUrl}/api/sales/detail_report_per_costumer`
}


export default function Report() {

    const classes = useStyles();
    const dispatch = useDispatch();

    /* STATE */
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [extraFilter, setExtraFilter] = useState({});
    const [requiredError, setRequiredError] = useState(false)
    const [reportUrl, setReportUrl] = useState('');
    const [filter, setFilter] = useState({
        type: '',
        date_type: null,
    })


    /* METHOD */
    const handleMainFilter = (params) => { // handle main filter seperti type dan group_by
        setData(null);
        setRequiredError(false)
        setFilter(state => {
            state[params.props.name] = params.selected.value
            return { ...state };
        })
    }
    const reqReport = async (params) => {
        const resp = await reqGet(params.url)
        setLoading(false);

        if (resp.status !== 'success') {
            globalMessage('Fetching report error', 'warning')
            return;
        }

        setData(resp.data);
    }
    const handleExtraFilter = objFilter => {
        setExtraFilter(objFilter)
    }
    const applyFilter = async () => {
        setData(null);
        if (filter.type && filter.date_type) { // jika main filter terpenuhi
            setLoading(true);
            const reportUrl = getUrlReport[filter.type] + makeGetParam({ ...filter, ...extraFilter })
            setReportUrl(reportUrl)
            await reqReport({ url: reportUrl })
        } else {
            setRequiredError(true)
            return
        }
    }

    useEffect(() => {
        if (loading) {
            window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        }
    }, [loading])


    return (
        <div>
            <ContentWrapper
                title="Sales Order Report"
                subtitle="Use available filter below to show custom criteria "
                buttons={actionButton({ reportUrl })}
            >
                <div className="report report-pos" style={{ paddingBottom: '40px' }}>
                    <div className={classes.formfilter}>
                        <Select
                            name="type"
                            label="Type"
                            getData={handleMainFilter}
                            options={[
                                { label: 'Sales order', value: '1' },
                                { label: 'Sales order by customer', value: '2' },
                                { label: 'Sales order by customer detail', value: '3' }
                            ]}
                        />
                        {
                            <Select
                                name="date_type"
                                label="Group By"
                                getData={handleMainFilter}
                                options={[
                                    { label: 'Day', value: 'day' },
                                    { label: 'Month', value: 'month' },
                                    { label: 'Year', value: 'year' }
                                ]}
                                {
                                ...(requiredError ? {
                                    note: "Please select detail type",
                                    isError: true
                                } : {})
                                }
                            />
                        }
                    </div>

                    {/* THE AWESOME FILTER  */}
                    <div className="mb-30">
                        <Filter
                            setFilter={setFilter}
                            type="sales_order"
                            getData={handleExtraFilter}
                        />
                    </div>
                    <div className="mb-20">
                        <Button onClick={applyFilter} style="OrangeWideMdRect" icon="check-circle" label="Apply Filter" />
                    </div>
                    <PrintProvider>
                        <Print single name="print-pos-report-table-1" >
                            <KopSurat fit />
                            {data && filter.type === '1' && <TableGeneral data={data} type={filter.date_type} />}
                            {data && filter.type === '2' && <TableDetailPerCustomer data={data} type={filter.date_type} />}
                            {data && filter.type === '3' && <TableDetail data={data} type={filter.date_type} />}
                            {loading ? <LoadingContent /> : null}
                        </Print>
                    </PrintProvider>

                </div>
            </ContentWrapper>
        </div>
    )
}
