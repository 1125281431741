import React from 'react'
import { Formik, Form } from 'formik';
import FieldsGroup from 'Elements/FieldsGroup'
import { useSelector, useDispatch } from 'react-redux';
import { scrollToTop, yupValidation, fieldsToFormik, fieldsValidation } from '@/Helper'
import { addWarehouse, resetStatusWarehouse } from 'Reduxes/inventory/configuration/warehouse/warehouseActions'
import Button from 'Elements/Button';
import Alert from 'Comps/elements/Alert'
import { validationSchema } from './validation'
import { serverValue } from 'Configs/Form'

const FormNew = (props) => {

    const { goToIndex, fieldGroup } = props;

    console.log(props, props.fieldGroup[0].fields[1].options.length)

    const { data, success, error, loading } = useSelector(state => state.warehouseReducer)
    const dispatch = useDispatch();
    const formOpts = {
        initialValues: fieldsToFormik(fieldGroup),
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            let result = await dispatch(addWarehouse(serverValue(values)))

            if (result) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusWarehouse())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts}>
                {(formik) => {
                    const { initialValues, values, isSubmitting } = formik;
                    return (
                        <Form className="form-member">
                            <FieldsGroup fields={fieldGroup} />
                            <div className="fields-group-container buttons">
                                <Button
                                    type="submit"
                                    style="OrangeMdWide"
                                    label={isSubmitting ? 'Saving' : 'Save'}
                                    icon={isSubmitting ? 'spinner' : null}
                                    disabled={isSubmitting} />
                                <button disabled={isSubmitting} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default FormNew