import { getAuthToken } from '@/Helper'

export const muiStyle = {
    formfilter: {
        display: 'grid',
        gridTemplateColumns: '300px 300px',
        columnGap: '24px',
        marginBottom: '24px',
        '&.col-3': {
            gridTemplateColumns: '300px 300px 300px',
        }
    },
    formextrafilter: {
        '& .toggler': {
            fontSize: '12px',
            fontWeight: 700,
            color: '#333',
            '& svg': {
                marginBottom: '-7px'
            }
        }
    },
    formextrafiltercontainer: {
        border: '1px solid #ddd',
        padding: '20px',
        borderRadius: '3px',
        marginTop: '15px',
        marginBottom: '20px'
    }
}

const print = () => {
    window.print()
}

export const topButton = [
    {
        label: 'PDF',
        style: 'GreyMd',
        icon: 'file-pdf',
        onClick: () => null,
        subMenu: "report_pos",
        type: "export",
    }, {
        label: 'Excel',
        style: 'GreyMd',
        icon: 'file-excel',
        onClick: () => null,
        subMenu: "report_pos",
        type: "export",
    }, {
        label: 'Print',
        style: 'DarkblueMd',
        icon: 'print',
        onClick: () => null,
        subMenu: "report_pos",
        type: "print",
    }
]

export const actionButton = ({ onPdfClick, onPrintClick, reportUrl }) => {
    const [, token] = getAuthToken().split(' ')
    const [, params] = reportUrl.split('/api/')
    return [
        onPdfClick !== undefined && {
            label: 'PDF',
            style: 'GreyMd',
            icon: 'file-pdf',
            onClick: onPdfClick,
            subMenu: "report_pos",
            type: "export",
        },
        {
            label: 'Excel',
            style: 'GreenMd',
            icon: 'file-excel',
            onClick: () => {
                if (reportUrl !== '') {
                    const downloadUrl = process.env.PW_REPORT_URL + `/${params}`
                    window.location = downloadUrl + `&token=${token}`
                }
            },
            subMenu: "report_pos",
            type: "export",
        },
        {
            label: 'Print',
            style: 'DarkblueMd',
            icon: 'print',
            onClick: reportUrl !== '' && print,
            subMenu: "report_pos",
            type: "print",
        }
    ]
}

export const filterTheme = (theme) => ({
    toggler: {
        color: '#333',
        fontSize: '13px',
        background: 'white',
        border: 0,
        cursor: 'pinter',
        fontWeight: 600,
        '& svg': {
            marginBottom: '-7px',
            marginLeft: '5px',
        },
        '&:hover': {
            color: theme.pw.teal
        },
        '&.teal': {
            color: theme.pw.teal
        }
    },
    formFilter: {
        display: 'grid',
        gridTemplateColumns: '300px  240px  240px  240px ',
        justifyContent: 'space-evenly'
    },
    fielContainer: {
        width: '100%   '
    },
    formextrafilter: {

        '& .toggler': {
            fontSize: '12px',
            fontWeight: 700,
            color: '#333',
            '& svg': {
                marginBottom: '-7px'
            }
        }
    },
    formextrafiltercontainer: {
        padding: '44px 20px 20px',
        marginTop: '15px',
        borderRadius: '10px',
        background: '#fafafa',
        border: '1px solid #eee',
        '& label': {
            fontSize: '12px',
            color: '#555'
        }
    },
    daterangeCont: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        columnGap: '10px'
    }
})

export const sortReportDate = dates => {
    if (typeof dates[0] === 'number') {
        return dates.sort((a, b) => a - b);
    } else {
        let tmp = [];
        dates.forEach(d => {
            let exploded = d.split('-');
            let tmpDate = new Date()

            tmpDate.setFullYear(+exploded[0]);
            tmpDate.setMonth(+exploded[1] - 1);

            tmp.push(tmpDate)
        })

        let sorted = tmp.sort((a, b) => a - b)

        return sorted.map(s => {
            let y = s.getFullYear();
            let m = s.getMonth();
            let mm = String(m).length === 1 ? `0${m + 1} ` : m + 1;

            return `${y}-${mm}`
        })
    }
}
