import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl, handleRequest } from 'Configs/request'

const type = 'PROMOTION'

export const PROMOTION_SET = 'PROMOTION_SET'
export const PROMOTION_ADD = 'PROMOTION_ADD'
export const PROMOTION_EDIT = 'PROMOTION_EDIT'
export const PROMOTION_SHOW = 'PROMOTION_SHOW'
export const PROMOTION_ERROR = 'PROMOTION_ERROR'
export const PROMOTION_RESET = 'PROMOTION_RESET'
export const PROMOTION_DELETE = 'PROMOTION_DELETE'
export const PROMOTION_UPDATE = 'PROMOTION_UPDATE'
export const PROMOTION_LOADING = 'PROMOTION_LOADING'
export const PROMOTION_CONFIRM = 'PROMOTION_CONFIRM'

export const loadPromotion = () => async (dispatch, getState) => {
    const fake = {
        "status": "success",
        "data": [
            {
                'id': 1,
                'date': 'DATE',
                'due_date': 'DUE DATE',
                'code': 'CODE-12511',
                'contact': 'KONTAK 1',
                'sub_total': '100000',
                'discount': '0',
                'total': '400000',
                'note': 'INI ADALAH NOTE',
                'status': 'draft',
                'payment_method': 'cash',
                'payment_items': [
                    {
                        'invoice_number': 'INV #001',
                        'due_date': '26 Oct 2020',
                        'selling_periode': '1 Oct 2020 - 5 Nov 2020',
                        'sub_total': '1.000.000',
                        'discount': '100.000',
                        'total': '900.000',
                        'total_payment': '900.000',
                        'margin': '-'
                    },
                    {
                        'invoice_number': 'INV #002',
                        'due_date': '26 Oct 2020',
                        'selling_periode': '1 Oct 2020 - 5 Nov 2020',
                        'sub_total': '6.000.000',
                        'discount': '500.000',
                        'total': '5.500.000',
                        'total_payment': '5.500.000',
                        'margin': '-'
                    },
                ]
            },
            {
                'id': 2,
                'date': 'DATE 2',
                'due_date': 'DUE DATE 2',
                'code': 'CODE-9999',
                'contact': 'KONTAK 2',
                'sub_total': '500000',
                'discount': '120',
                'total': '600000',
                'status': 'bukan draft'
            },
        ],
        "errors": []
    }
    handleReq({
        type,
        dispatch,
        body: () => fake,
        // body: () => reqGet(`${hostUrl}/api/payment`),
        next: payload => dispatch({ type: PROMOTION_SET, payload })
    })
}

export const addPromotion = data => async (dispatch, getState) => {
    handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/payment`, data),
        next: payload => dispatch({ type: PROMOTION_ADD, payload })
    })
}

export const setEditPromotion = idEdit => {
    return {
        type: PROMOTION_EDIT,
        payload: idEdit
    }
}

export const showPromotion = idShow => {
    return {
        type: PROMOTION_SHOW,
        payload: idShow
    }
}

export const resetStatusPromotion = () => {
    return {
        type: PROMOTION_RESET
    }
}

export const deletePromotion = id => async (dispatch, getState) => {
    handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/payment`, id),
        next: () => dispatch({ type: PROMOTION_DELETE, payload: id })
    })
}

export const updateStatusPromotion = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/payment/${id}/${type}`),
        next: () => dispatch({ type: PROMOTION_CONFIRM, payload: { type, id } })
    })
}

export const updatePromotion = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/payment/${idEdit}`, data),
        next: (payload) => dispatch({ type: PROMOTION_UPDATE, payload })
    })
}