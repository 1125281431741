import { Formik, Form } from 'formik';
import Button from 'Elements/Button';
import { scrollToTop } from '@/Helper'
import Alert from 'Comps/elements/Alert'
import FieldsGroup from 'Elements/FieldsGroup'
import { validationSchema } from './validation'
import React, { useEffect, useState } from 'react'
import LoadingContent from 'Elements/LoadingContent';
import { useSelector, useDispatch } from 'react-redux';
import { type, menus } from '../../../utils/MenuConfig';
import { 
    resetStatusUsercategory, 
    updateUsercategory, 
    addUsercategoryPermission, 
    getPermission,
    deletePermission, 
} from 'Reduxes/usercategory/usercategoryActions'

const Detail = (props) => {
    const { goToIndex, fieldGroup } = props;

    const currentFieldGroup = JSON.parse(JSON.stringify(fieldGroup));

    const { data, error, idEdit } = useSelector(state => state.usercategoryReducer);

    const dataEdit = data.filter((a, b) => a.id === idEdit)[0]

    const dispatch = useDispatch();
    
    const [loading, setLoading] = useState(true);

    const [initValue, setInitValue] = useState({});

    const [deletePermissionIds, setDeletePermissionIds] = useState([]);

    const [permissionsData, setPermissionData] = useState([]);
    
    const colLength = [...Array(7)]

    currentFieldGroup[0].fields.map((field, index) => {
        Object.assign(currentFieldGroup[0].fields[index], {
            readOnly: true,
        });
    });
    
    const cbRow = (submod, formik) => {
        const { values } = formik
    
        return (
            <>
                {
                    colLength.map((a, index) => {
                        const name = formatName(submod, index);
    
                        return (
                            <td key={index} style={{
                                paddingTop: '10px',
                                paddingBottom: '5px'
                            }}>
                                <input
                                    type="checkbox" 
                                    name={name}           
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    checked={values[name]}
                                    readOnly                   
                                />
                            </td>
                        )
                    })
                }
            </>
        )
    }
    
    const salesSubMod = (arrSubMod, formik) => (
        <>
            {
                arrSubMod.map((s, sI) => (
                    <tr key={sI}>
                        <td></td>
                        <td>{s.label}</td>
                        <td></td>
                        {cbRow(s.name, formik)}
                    </tr>
                ))
            }
        </>
    )
    
    const formatName = (name, index) => {
        return `${name.replace(' ', '_').toLowerCase()}_${type[index]}`;
    }

    const formatToSnakeCase = (name) => {
        return `${name.replace(' ', '_').toLowerCase()}`;
    } 

    const getPermissionStatus = (menu, type, permissions) => {
        return permissions.findIndex(permission => permission.menu === formatToSnakeCase(menu) && permission.type === type) >= 0 
            ? true 
            : false;
    }

    const formOpts = {
        initialValues: initValue,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const cleanVal = {
                user_category_name: values.user_category_name
            }

            let result = await dispatch(updateUsercategory(idEdit, cleanVal))

            let data = {
                permissions: [],
            }
            
            menus.map((menu) => {
                type.map((action, index) => {
                    if (values[formatName(menu.label, index)]) {
                        data.permissions.push({
                            user_category_id: idEdit,
                            type: action,
                            menu: formatToSnakeCase(menu.label),
                        });
                    }
                })

                menu.subMenus.map((submenu) => {
                    type.map((action, index) => {
                        if (values[formatName(submenu.name, index)]) {
                            data.permissions.push({
                                user_category_id: idEdit,
                                type: action,
                                menu: formatToSnakeCase(submenu.name),
                            });
                        }
                    })
                })
            })

            await dispatch(deletePermission(deletePermissionIds));

            await dispatch(addUsercategoryPermission(data));

            if (result) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }

    useEffect(() => {
        let data = {
            ...dataEdit
        };

        const getPermissions = async () => {
            const permissions = (await dispatch(getPermission({ user_category_id: idEdit }))).data;

            setPermissionData(permissions);

            menus.map((menu) => {
                type.map((action, index) => {
                    const mainKeyName = formatName(menu.label, index);
        
                    Object.assign(data, {
                        [mainKeyName]: getPermissionStatus(menu.label, action, permissions),
                    })
                });
        
                menu.subMenus.map((subMenu) => {
                    type.map((action, index) => {
                        const keyName = formatName(subMenu.name, index);
                        
                        Object.assign(data, {
                            [keyName]: getPermissionStatus(subMenu.name, action, permissions),
                        })
                    });
                })
            });
        }

        getPermissions().then(() => {
            setInitValue(data);
            setLoading(false);
        });
    }, []);

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusUsercategory())} option={{ type: 'error', message: error }} />
            }

            {
                !loading &&
                <Formik {...formOpts}>
                    {(formik) => {
                        return (
                            <Form className="form-member">
                                <FieldsGroup fields={currentFieldGroup} />
                                <table className="table-usercategory">
                                    <thead>
                                        <tr>
                                            <th>Module</th>
                                            <th>Sub Module</th>
                                            <th>Activity</th>
                                            <th>Read</th>
                                            <th>Create</th>
                                            <th>Update</th>
                                            <th>Confirm</th>
                                            <th>Delete</th>
                                            <th>Print</th>
                                            <th>Export</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            menus.map((menu) => (
                                                <>
                                                    <tr>
                                                        <td>{menu.label}</td>
                                                        <td></td>
                                                        <td></td>
                                                        {cbRow(menu.label, formik)}
                                                    </tr>
                                                    
                                                    {salesSubMod(menu.subMenus, formik)}
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </Form>
                        )
                    }}
                </Formik>
            }

            {
                loading && <LoadingContent />
            }
        </>
    )
}

export default Detail