import { handleReq, makeGetParam, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
export const BRAND_SET = 'BRAND_SET'
export const BRAND_ERROR = 'BRAND_ERROR'
export const BRAND_ADD = 'BRAND_ADD'
export const BRAND_SHOW = 'BRAND_SHOW'
export const BRAND_EDIT = 'BRAND_EDIT'
export const BRAND_UPDATE = 'BRAND_UPDATE'
export const BRAND_DELETE = 'BRAND_DELETE'
export const BRAND_LOADING = 'BRAND_LOADING'
export const BRAND_RESET = 'BRAND_RESET'
export const BRAND_STOP_LOADING = 'BRAND_STOP_LOADING'
const type = "BRAND"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const filterBrand = (args = {}) => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/brand${makeGetParam(args)}`),
    })
}
export const loadBrand = (all) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/brand${all ? '?all=true' : ''}`),
        next: (payload) => dispatch({ type: BRAND_SET, payload })
    })
}
export const addBrand = data => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/brand`, data),
        next: (payload) => dispatch({ type: BRAND_ADD, payload })
    })
}
export const updateBrand = (idEdit, data) => async (dispatch) => {
    console.log(idEdit, data)
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/brand/${idEdit}`, data),
        next: (payload) => dispatch({ type: BRAND_UPDATE, payload })
    })
}
export const deleteBrand = id => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/brand`, id),
        next: () => dispatch({ type: BRAND_DELETE, payload: id })
    })
}
export const showBrand = idx => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/brand/${idx}`),
    })
}
export const setEdit = idEdit => {
    return {
        type: BRAND_EDIT,
        payload: idEdit,
    }
}
export const resetStatusBrand = () => {
    return {
        type: BRAND_RESET
    }
}