import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({

    sales_order_products: Yup.object().shape({
        arr: Yup.array().of(Yup.object().shape({
            quantity: Yup.string().required('KUDU')
        }))
    })

    // name:
    //     Yup.string()
    //         .required('Contact name is required'),
    // postal_code:
    //     Yup.string()
    //         .matches(/^\d+$/, 'Postal code should have digits only')
    //         .max(5, 'Postal code must 5 digits')
    //         .nullable(),
    // email: Yup.string()
    //     .email('Invalid email')
    //     .required('Email is required'),
    // phone:
    //     Yup.string()
    //         .max(15, 'Maximum phone number is 15 character')
    //         .matches(/^\d+$/, 'Phone number should have digits only')
    //         .nullable(),
    //         .required('Phone number  is required')
    // address:
    //     Yup.string()
    //         .required('Address is required'),
    // email: Yup.string()
    //     .email('Invalid email')
    // //     .required('Email is required'),
    // province:
    //     Yup.string()
    //         .required('Province is required'),
    // city:
    //     Yup.string()
    //         .required('City is required'),
    // postal_code:
    //     Yup.string()
    //         .required('Postal code is required')
    //         .matches(/^\d+$/, 'Postal code should have digits only'),
    // member_card_received_date:
    //     Yup.string()
    //         .nullable()
    //         .when("member_card_status", {
    //             is: "SUDAH_DIBERIKAN",
    //             then: Yup.string().matches(/[^a-zA-Z]/, 'Received date is required'),
    //         }),

    // member_card_expired_date:
    //     Yup.string()
    //         .nullable()
    //         .when("member_card_status", {
    //             is: "SUDAH_DIBERIKAN",
    //             then: Yup.string().matches(/[^a-zA-Z]/, 'Expired date is required'),
    //         }),
})

Yup.reach(validationSchema, 'sales_order_products.arr.quantity')
