import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    company_name:
        Yup.string()
            .required('Company name is required'),
    address:
        Yup.string()
            .required('Address is required'),
    // .matches(/[^A-Za-z 0-9]+/g, 'Address should have digits only'),
    // province:
    //     Yup.string()
    //         .required('Province is required'),
    // country:
    //     Yup.string()
    //         .required('Country is required'),
    // city:
    //     Yup.string()
    //         .required('City is required'),
    postal_code:
        Yup.string()
            .max(5, 'Postal code must 5 digits')
            .nullable()
            .matches(/^\d+$/, 'Postal code should have digits only'),
    phone:
        Yup.string()
            .max(15, 'Maximum phone number is 15 character')
            .nullable()
            // .required('Phone number  is required')
            .matches(/^\d+$/, 'Phone number should have digits only'),
    email: Yup.string()
        .nullable()
        .email('Invalid email'),
    // .required('Email is Required'),
    site_address: Yup.string()
        .matches(
            /^((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Invalid url'
        )
})

