import { handleReq, makeGetParam, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
export const SALES_SET = 'SALES_SET'
export const SALES_ERROR = 'SALES_ERROR'
export const SALES_ADD = 'SALES_ADD'
export const SALES_SHOW = 'SALES_SHOW'
export const SALES_EDIT = 'SALES_EDIT'
export const SALES_UPDATE = 'SALES_UPDATE'
export const SALES_DELETE = 'SALES_DELETE'
export const SALES_LOADING = 'SALES_LOADING'
export const SALES_STOP_LOADING = 'SALES_STOP_LOADING'
export const SALES_RESET = 'SALES_RESET'
export const SALES_CONFIRM = 'SALES_CONFIRM'
export const SALES_FILTER_INDEX = 'SALES_FILTER_INDEX'
const type = "SALES"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const loadSales = () => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/pos`),
        next: (payload) => dispatch({ type: SALES_SET, payload })
    })
}
export const showSales = idx => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/pos/${idx}`),
    })
}
export const addSales = data => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/pos`, data),
        next: (payload) => dispatch({ type: SALES_ADD, payload })
    })
}
export const addSalesExchange = data => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${online ? hostUrl : offlineUrl}/api/pos/exchange`, data),
        next: (payload) => dispatch({ type: SALES_ADD, payload })
    })
}
export const updateSales = (idEdit, data) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/pos/${idEdit}`, data),
        next: (payload) => dispatch({ type: SALES_UPDATE, payload })
    })
}
export const deleteSales = id => async (dispatch) => {
    const online = await isOnline()
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${online ? hostUrl : offlineUrl}/api/pos`, id),
        next: () => dispatch({ type: SALES_DELETE, payload: id })
    })
}
export const setEditSales = idEdit => {
    return {
        type: SALES_EDIT,
        payload: idEdit,
    }
}
export const resetStatusSales = () => {
    return {
        type: SALES_RESET
    }
}
export const updateStatus = (id, type) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${online ? hostUrl : offlineUrl}/api/product_receivement/${id}/${type}`),
        next: () => dispatch({ type: SALES_CONFIRM, payload: { type, id } })
    })
}
export const filterSales = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/pos${makeGetParam(args)}`),
        next: () => dispatch({ type: SALES_STOP_LOADING })
    })
}
export const filterSalesIndex = (args = {}) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/pos${makeGetParam(args)}`),
        next: (payload) => dispatch({ type: SALES_FILTER_INDEX, payload })
    })
}