import {
    MEMBER_LOADING,
    MEMBER_SET,
    MEMBER_ERROR,
    MEMBER_ADD,
    MEMBER_STOP_LOADING,
    MEMBER_SHOW,
    MEMBER_DELETE,
    MEMBER_UPDATE,
    MEMBER_RESET,
    MEMBER_EDIT,
} from './memberActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
}

const memberReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case MEMBER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case MEMBER_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === payload.id ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case MEMBER_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case MEMBER_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New member has been added successfully.'
            }
        case MEMBER_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case MEMBER_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case MEMBER_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case MEMBER_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case MEMBER_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case MEMBER_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default memberReducer