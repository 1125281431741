import React from 'react'
import FieldsGroup from 'Elements/FieldsGroup'
import Button from 'Elements/Button';

export const fieldGroup = [
    {
        name: 'Variant Information',
        fields: [
            {
                label: 'code',
                name: 'code',
                value: ''
            },
            {
                label: 'Variant name',
                col: 4,
                name: 'name',
                value: ''
            }, {
                label: 'Status',
                value: 'true',
                name: 'status',
                type: 'select',
                options: [
                    { value: 'true', label: 'active' },
                    { value: 'false', label: 'inactive' },
                ]
            }, {
                label: 'Value',
                col: 4,
                name: 'variant_value',
                value: ''
            },
        ]
    }
]

export const Form = (props) => {
    const { isSubmitting, goToIndex } = props;

    return (
        <>
            <FieldsGroup fields={fieldGroup} />  

            <div className="fields-group-container buttons">
                <Button
                    type="submit"
                    style="OrangeMdWide"
                    label={isSubmitting ? 'Saving' : 'Save'}
                    icon={isSubmitting ? 'spinner' : null}
                    disabled={isSubmitting} />
                <button disabled={isSubmitting} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
            </div>
        </>
    )
}
