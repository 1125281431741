import { getAuthToken } from '@/Helper'
export const hostUrl = process.env.PW_API_URL;

export const makeGetParam = (objParam, type = null) => {
    let result = '';
    let arrArgs = Object.keys(objParam);

    arrArgs.forEach((x, y) => {

        result += y < 1 ? '?' : '';

        const val = objParam[x];

        if (!type && !val) {
            return;
        } else if (Array.isArray(val)) {
            if (!val.length) {
                return;
            }
            val.forEach((a, b) => {
                result += `${x}[${b}]=${a}`
                result += b < val.length - 1 ? '&' : ''

            })
        } else {
            result += `${x}=${val}`
        }


        result += y < arrArgs.length - 1 ? '&' : ''
    })

    return result;
}
export const reqGet = async url => {
    const token = await getAuthToken();

    const req = await fetch(url, {
        headers: {
            // Authorization: `Bearer ${token}`
            "Authorization": getAuthToken(),
        }
    });
    return await req.json();
};
export const reqPost = async (url, data = {}) => {
    const token = await getAuthToken();

    const dataIsObject = data.constructor === Object;

    const req = await fetch(url, {
        body: dataIsObject ? JSON.stringify(data) : data,
        method: "post",
        headers: {
            // Authorization: `Bearer ${token}`,
            "Authorization": getAuthToken(),
            ...(dataIsObject ? { "Content-Type": "application/json" } : {})
        }
    });
    return await req.json();
};
export const reqDel = async (url, id) => {
    const token = await getAuthToken();

    let idArr = Array.isArray(id) ? id : [id];

    const req = await fetch(url, {
        body: JSON.stringify({ id: idArr }),
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            "Authorization": getAuthToken(),

            // Authorization: `Bearer ${token}`
        }
    });

    return await req.json();

};
export const reqDelIDS = async (url, id) => {
    let idArr = Array.isArray(id) ? id : [id];

    const token = await getAuthToken();

    const req = await fetch(url, {
        body: JSON.stringify({ ids: idArr }),
        method: "delete",
        headers: {
            "Content-Type": "application/json",
            "Authorization": getAuthToken(),

            // Authorization: `Bearer ${token}`
        }
    });

    return await req.json();

};
export const reqPatch = async (url, data) => {
    const token = await getAuthToken();

    const req = await fetch(url, {
        body: JSON.stringify(data),
        method: "PUT",
        headers: {
            // Authorization: `Bearer ${token}`,
            "Authorization": getAuthToken(),
            "Content-Type": "application/json",
            // 'Accept': 'application/json',

        }
    });

    return await req.json();
};
export const handleReq = async (params) => {
    let { type, dispatch, body, next } = params;

    let resStatus = false;

    dispatch({
        type: type + "_LOADING"
    });

    try {
        let res = await body();

        dispatch({
            type: type + "STOP_LOADING"
        });

        if (res.status && res.status === "success") {
            resStatus = true;
            next(res.data);
        } else {
            throw new Error(res.errors || "Something went wrong, please try again later.");
        }

    } catch (e) {
        dispatch({
            type: type + "_ERROR",
            payload: e.message
        });
    }

    return resStatus;
}

export const handleRequest = async (params) => {

    let { type, dispatch, body, next } = params;
    let resStatus = false;
    let data = null;

    dispatch({
        type: type + '_LOADING'
    })

    try {
        let res = await body();
        dispatch({
            type: type + "_STOP_LOADING"
        });

        data = res;

        if (res.status && res.status === 'success') {
            resStatus = true;
            if (next) {
                next(res.data);
            }
            data = res.data
        } else {
            throw new Error(res.errors || 'Something went wrong, please try again later.')
        }

    } catch (e) {
        dispatch({
            type: type + '_ERROR',
            payload: e.message
        })
    }

    return {
        status: resStatus,
        data
    };

}

export const customGetParam = (objParam, type = null) => {
    let result = '';
    let arrArgs = Object.keys(objParam);

    arrArgs.forEach((x, y) => {

        result += y < 1 ? '?' : '';

        const val = objParam[x];

        if (typeof val === 'undefined' || val === null) return

        if (Array.isArray(val)) {
            if (!val.length) {
                return;
            }
            val.forEach((a, b) => {
                result += `${x}[${b}]=${a}`
                result += b < val.length - 1 ? '&' : ''

            })
        } else {
            result += `${x}=${val}`
        }

        result += y < arrArgs.length - 1 ? '&' : ''
    })

    return result;
}
