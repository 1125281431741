import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    name: Yup.string()
        .required('Contact name is required')
        .max(100, 'Maximum contact name is 100 characters')
        .matches(/^[a-z 0-9\-]+$/i, 'Only alphabet, numeric, and dash (-) allowed'),
    product_type: Yup.string()
        .required('Product Type is required'),
    status: Yup.string()
        .required('Status is required'),
    category_id: Yup.string()
        .required('Category is required'),
    brand_id: Yup.string()
        .required('Brand is required'),
    unit_of_measurement_id: Yup.string()
        .required('UoM is required'),
    color_id: Yup.string()
        .required('Color is required'),
    comission: Yup.number()
        .max(100, 'Maximum commission is 100')
    // phone:
    //     Yup.string()
    //         .max(15, 'Maximum phone number is 15 character')
    //         //         .required('Phone number  is required')
    //         .nullable()
    //         .matches(/^\d+$/, 'Phone number should have digits only'),
    // address:
    //     Yup.string()
    //         .required('Address is required'),
    // province:
    //     Yup.string()
    //         .required('Province is required'),
    // city:
    //     Yup.string()
    //         .required('City is required'),
    // postal_code:
    //     Yup.string()
    //         .required('Postal code is required')
    //         .matches(/^\d+$/, 'Postal code should have digits only'),
})

