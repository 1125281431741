import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { money } from '@/Helper'

export default function SummaryBox({ items }) {

    return (
        <>
            {
                items.map((item, index) => (
                    <Item key={index}>
                        <SmallText>{item.label}</SmallText>
                        <BigText>{item.value}</BigText>
                    </Item>
                ))
            }
        </>
    )
}

const Item = styled.div`
    border: 1px solid #eee;
    min-width: 130px;
    display: inline-block;
    border-radius: 5px;
    padding: 20px;
    margin-right: 10px;
    background: #fafafa;
`
const BigText = styled.p`
    font-weight: 700;
    font-size: 18px;
    color: #333;
`
const SmallText = styled.p`
    text-transform: uppercase;
    font-size: 10px;
    color: #aaa;
    font-weight: 700;
    margin-bottom: 4px;
`
