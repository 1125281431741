import React, { useEffect, useState } from 'react'
import { addMember, updateMember, resetStatusMember } from 'Reduxes/member/memberActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop } from '@/Helper'
import SelectData from 'Elements/SelectData';
import Input from 'Elements/Input';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import { Formik, Field, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import { validationSchema } from './validation'
import SelectAsync from 'Elements/SelectAsync';
import { filterMember, loadProvince, loadCity } from 'Reduxes/member/memberActions'
import Select from 'Elements/select/Select'


export default function FormElement(props) {


    const { type, showTable, rowId } = props;
    const [isReady, setIsReady] = useState(true);
    const [initialValues, setInitialValues] = useState({
        is_active: 'true',
        phone: '',
        name: '',
        email: '',
        note: '',
        member_card_status: "BELUM_DIBERIKAN",
        member_card_received_date: "blank",
        member_card_expired_date: "blank",
        facebook: '',
        twitter: '',
        instagram: '',
        postal_code: '',
        province: '',
        country: 'Indonesia',
        city: '',
        address: '',
    });
    const [membershipReceived, setMembershipReceived] = useState("");
    const dispatch = useDispatch();
    const { data, loading, error, success } = useSelector(state => state.memberReducer);
    const formOpts = {
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const { address, city, country, email, is_active, member_card_expired_date, member_card_status, facebook, twitter, instagram, name, note, phone, postal_code, province, member_card_received_date } = values;

            const sosmed = {
                fb: facebook,
                tw: twitter,
                ig: instagram,
            }

            const cleanVal = {
                address,
                // city : dataCity.ibukota, //harusnya di set dengan id city
                city,
                country,
                email,
                member_card_status,
                name,
                note,
                phone,
                // province : dataProvince.provinsi, //harusnya di set dengan id city
                province,
                postal_code: +postal_code,
                is_active: is_active === 'true',
                member_social: JSON.stringify(sosmed),
                member_card_received_date: member_card_received_date === "blank" || member_card_status === "BELUM_DIBERIKAN" ? null : member_card_received_date,
                member_card_expired_date: member_card_expired_date === "blank" || member_card_status === "BELUM_DIBERIKAN" ? null : member_card_expired_date,
            }

            let result = type === 'new' ?
                await dispatch(addMember(cleanVal))
                : await dispatch(updateMember(rowId, cleanVal))

            setSubmitting(false)
            scrollToTop()

            if (result) {
                resetForm();
                showTable();
            }
        }
    }
    const changeMembershipReceived = (param) => {
        setMembershipReceived(param.value)
    }

    useEffect(() => {
        if (type === 'edit') {


            let dataEdit = data.filter(a => a.id === rowId)[0]
            let socmed = JSON.parse(dataEdit.member_social);
            Object.keys(socmed).forEach(x => {
                if (x === 'fb') {
                    dataEdit.facebook = socmed.fb
                }
                if (x === 'tw') {
                    dataEdit.twitter = socmed.tw
                }
                if (x === 'ig') {
                    dataEdit.instagram = socmed.ig
                }
            })

            const { postal_code, is_active, phone, name, email, note, member_card_status, member_card_received_date, member_card_expired_date, province, city, address, facebook, twitter, instagram } = dataEdit;
            setMembershipReceived(member_card_received_date)
            setInitialValues({
                is_active: is_active ? 'true' : 'false',
                phone,
                name,
                email,
                note,
                member_card_status,
                member_card_received_date: member_card_received_date ? member_card_received_date : "blank",
                member_card_expired_date: member_card_expired_date ? member_card_expired_date : "blank",
                facebook,
                twitter,
                instagram,
                postal_code,
                province,
                country: 'Indonesia',
                city,
                address,
            })

            const loadDataCity = async (province) => {
                const resp = await dispatch(loadCity({ province_name: province }))
                setDataCity(resp.data.map(x => ({
                    label: x.ibukota, value: x.ibukota
                })))
            }

            if (province) {
                loadDataCity(province)
            }

        }
    }, [])

    // Handle chain address
    const [dataCity, setDataCity] = useState([])

    const handleChangeProvince = async val => {
        setDataCity([])
        const resp = await dispatch(loadCity({ province_name: val.selected.value }))
        setDataCity(resp.data.map(x => ({
            label: x.ibukota, value: x.ibukota
        })))

        if (!val.selected.value) {
            setDataCity([])
        }
    }



    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Member" : "Update data Member"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data member `}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusMember())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true} >

                                {(formik) => {
                                    const { values, isSubmitting } = formik;
                                    const { member_card_status } = values

                                    return (


                                        <Form className="form-layout-1">
                                            <FieldsContainer title="General Information">
                                                <Input name="name" label="Name" />
                                                <SelectData label="Status" name="is_active" options={[
                                                    { label: 'Active', value: 'true' },
                                                    { label: 'Inactive', value: 'false' }
                                                ]} />
                                                <div></div>
                                                <Input name="phone" label="Phone" />
                                                <Input name="email" label="Email" />

                                            </FieldsContainer>

                                            <FieldsContainer title="Membership Information">
                                                <SelectData name="member_card_status" label="Membership Status" options={[
                                                    { label: 'Belum diberikan', value: 'BELUM_DIBERIKAN' },
                                                    { label: 'Sudah diberikan', value: 'SUDAH_DIBERIKAN' },
                                                ]} />
                                                <Datepicker
                                                    handleChange={changeMembershipReceived}
                                                    name="member_card_received_date"
                                                    label="Membership Received"
                                                    disabled={member_card_status === "BELUM_DIBERIKAN"}
                                                    value={null}
                                                />
                                                <Datepicker
                                                    {...(membershipReceived !== "" ? { minDate: membershipReceived } : {})}
                                                    name="member_card_expired_date"
                                                    label="Membership Expired"
                                                    disabled={member_card_status === "BELUM_DIBERIKAN"}
                                                    value="blank"
                                                />

                                                { }
                                            </FieldsContainer>

                                            <FieldsContainer title="Address Information">
                                                <Input name="address" col={2} label="Address" />
                                                <div></div>
                                                {/*                                                 
                                                <Input name="province" label="Province" />
                                                <Input name="city" label="City" />
                                                <Input name="postal_code" label="Postal Code" /> 
                                                */}

                                                <Select
                                                    label="Province"
                                                    name="province"
                                                    type="province"
                                                    portal
                                                    options="province"
                                                    isClearable
                                                    getData={handleChangeProvince}

                                                />

                                                {
                                                    dataCity.length > 0 &&
                                                    <Select
                                                        label="City"
                                                        name="city"
                                                        type="city"
                                                        portal
                                                        isClearable
                                                        options={dataCity}
                                                    />
                                                }


                                                <Input name="postal_code" label="Postal Code" />

                                            </FieldsContainer>

                                            <FieldsContainer title="Other Information">
                                                <Input name="facebook" label="Facebook" />
                                                <Input name="twitter" label="Twitter" />
                                                <Input name="instagram" label="Instagram" />
                                                <Textarea name="note" label="Notes" col="2" />
                                            </FieldsContainer>

                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper>
    )
}


