import React from 'react'
import Chip from '@material-ui/core/Chip';
import MUIDataTable, { TableFilterList } from "mui-datatables";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const CustomChip = ({ label, onDelete }) => {
    return (
        <Chip
            variant="outlined"
            color="secondary"
            label={label}
            onDelete={onDelete}
        />
    );
};
const theme = createMuiTheme({
    overrides: {
        MuiToolbar: {
            root: {
                display: "none"
            }
        },
    }
});

const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

function Table(props) {
    const { disableToolbar } = props
    return (
        // <MuiThemeProvider theme={getMuiTheme}>
        <ThemeProvider theme={disableToolbar ? theme : {}}>
            <MUIDataTable
                {...props}
                components={{
                    TableFilterList: CustomFilterList,
                }}
            />
        </ThemeProvider>
        // </MuiThemeProvider>
    )
}

export default Table
