import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useSelector } from 'react-redux'
import SubmoduleNav from 'Layouts/SubmoduleNav';
import { filterTabs } from '../../utils/MenuConfig'

export default function Submodule(props) {

    const { subModules } = props;
    const [navs, setNavs] = React.useState(subModules);
    const { permission } = useSelector(state => state.userReducer);
    const [content, setContent] = React.useState(React.createElement(subModules[0].child ? subModules[0].child[0].component : subModules[0].component));

    const renderContent = nav => {
        if (nav) {
            if (nav.child) {
                return;
            }
            setContent(React.createElement(nav.component, (nav.props || {})))
        }
    }

    useEffect(() => {
        const fileteredSubmodules = filterTabs(subModules, permission);
        const hasChild = fileteredSubmodules[0]?.hasOwnProperty('child')

        setNavs(fileteredSubmodules);

        setContent(fileteredSubmodules[0] ? React.createElement(hasChild ? fileteredSubmodules[0].child[0].component : fileteredSubmodules[0].component) : null);
    }, [permission]);
    return (
        <div className="tab">
            <div className="tab-nav">
                <SubmoduleNav
                    navs={navs}
                    renderContent={renderContent}
                    setNavs={setNavs}
                />
            </div>
            <TabContent>
                {content}
            </TabContent>
        </div>
    )
}


const TabContent = styled.div`
    padding: 16px 28px;
`