import React from 'react'
import moment from 'moment';
import * as Yup from 'yup';
import ScannerDetection from "Lib/scanner-detection";
import { hostUrl } from 'Configs/request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)

export const globalMessage = (message, icon) => {
    return MySwal.fire({
        icon,
        title: <p>{message}</p>,
    })
}

export const globalDialog = async (action) => {
    const result = await MySwal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes'
    });
    if (result.isConfirmed) {
        action();
    }
}

export const showMonth = (args) => {
    const month = args.split('-')[1]
    const year = args.split('-')[0]
    const monthArray = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ]
    const monthName = monthArray[+month - 1]
    return `${monthName || ''} ${year}`
}

export const fieldsToFormik = els => {
    const result = {}

    els.forEach(e => {
        if ((e.name && e.name.includes(' ')) || e.fields) {
            e.fields.forEach(f => {
                if (f.name) {
                    result[f.name] = f.value
                }
            })
        } else {
            if (e.name) {
                result[e.name] = e.value
            }
        }
    })

    return result
}
export const fieldsValidation = (values, fields) => {
    /*
        values :
            {name: 'orlando', address: 'bandung'}
    */


    const errors = {};

    const extractValidation = (row, v) => {
        let rules = row.validation.split('|')
        let inputVal = values[v]
        if (rules.includes('req')) {
            if (!inputVal) {
                errors[v] = `${[...row.label][0].toUpperCase() + row.label.slice(1)} is required`
            }
        }
        if (rules.includes('email')) {
            if (!inputVal || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
        }
    }


    Object.keys(values).map(v => {
        if (fields[0].fields) {
            fields.map(fg => {
                let row = fg.fields.find(f => f.name === v)
                if (row && row.validation) {
                    extractValidation(row, v)
                }
            })
        } else {
            let row = fields.find(f => f.name === v);
            if (row && row.validation) {
                extractValidation(row, v)
            }
        }
    })


    return errors;
}
export const comingSoon = (e) => {
    e.preventDefault()
    alert('Feature available later')
}
export const scrollToTop = () => {
    window.scrollTo(0, 0)
}
export const datePost = dateObj => {
    let a = moment(dateObj).format('YYYY-MM-DD')
    return a
}
export const dateDMY = dateObj => {
    let a = moment(dateObj).format('DD-MM-YYYY')
    return a
}
export const dateHuman = date => {
    return moment(date).format('DD MMM YYYY')
}
export const dateDMYHIS = dateObj => {
    let a = moment(dateObj).format('DD-MM-YYYY_HHmmss')
    return a
}
export const yupValidation = fieldGroup => {

    let validation = {}; // fokus ini

    fieldGroup.map(fg => {
        fg.fields.map(f => {
            if (f.validation) {

                let yup = Yup.string(); // fokus ini
                let rules = f.validation.split('|')

                if (rules.includes('req')) {
                    yup['required'] = () => 'this is required' // fokus ini
                }

                validation[f.name] = yup // fokus ini
            }
        })
    })

    return Yup.object().shape(validation) // fokus ini
}
export const setAsyncDefaultVal = (serverResponse) => {
    return serverResponse.data.length ? serverResponse.data[0].id : ''
}
export const arrSelectOpt = (args) => {
    if (args.data) {
        let { data, value, label } = args;
        return !data.length ? [] : data.map(x => ({ label: x[label], value: x[value ? value : 'id'] }))
    }
    return []
}
export const money = (digits = 0) => {
    const isMinus = digits < 0;

    if (typeof digits !== 'number' || isNaN(digits)) {
        digits = 0;
    }
    let reverse = digits.toString().split('').reverse().join('');
    let ribuan = reverse.match(/\d{1,3}/g);
    return `${isMinus ? '-' : ''}${ribuan.join('.').split('').reverse().join('')}`;
}
export const getLocalStorage = () => {
    const data = localStorage.getItem('phillipworks');

    return JSON.parse(data)
}
export const getLocalStorageAuth = () => {
    const data = localStorage.getItem('auth');

    return JSON.parse(data)
}
export const sortData = ({ data, column, type }) => {
    let result = data.sort((a, b) => {
        return (a[column] > b[column]) && (type === 'desc' || !type) ? 1 : -1
    })

    if (result[0].children) {
        result = result.map(n => {
            n.children = n.children.sort((a, b) =>
                (a[column] > b[column]) &&
                    (type === 'desc' || !type)
                    ? 1 : -1)
            return n
        })
    }

    return result
}
export const handleSortState = ({ data, column }) => {
    Object.keys(data).forEach(sortKey => {
        if (sortKey !== column) {
            data[sortKey].sort_type = null
        } else {
            data[sortKey].sort_type = data[sortKey].sort_type === 'asc' ? 'desc' : 'asc'
        }
    })

    return data
}
export const getAuthToken = () => {
    return `Bearer ${localStorage.getItem('token')}`;
}
export const getCurrentUser = () => {
    const token = getAuthToken();
    return parseJwt(token);
}
export const Uid = () => {
    return Date.now() + Math.random().toString();
}
export const moneyToInt = (strMoney) => {

    const arrStr = strMoney.toString()

    return parseInt(arrStr.replace(/[^0-9-a-z-A-Z ]/g, "").replace(/ +/, " ").replace(/\D/g, ''))


}
export const sortSize = args => {
    let result = []
    const size = [
        "XS",
        "XXS",
        "S",
        "M",
        "L",
        "XL",
        "XXL",
    ]
    args.map(element => {
        if (parseInt(element.variant_value_item)) {
            result.push(parseInt(element.variant_value_item))
            result.sort()
        } else {
            result[size.indexOf(element.variant_value_item.toUpperCase())] = element.variant_value_item.toUpperCase()
        }
    })
    return result.filter(Boolean).join(', ')
}
export const toSnackCase = text => {
    return text.toLowerCase().replace(/ /g, "_")
}
export const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};
export const makeImgUrl = (url) => {
    return String(url).includes("http") ? url : hostUrl.concat('/' + url);
}