import React from 'react'
import { money } from '@/Helper'
import NoReport from 'Elements/NoReport'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export default function Detail({ data }) {
    let variants = null;
    let longestVariant = [];
    let detailsHeadLabel = ['in', 'out', 'reject', 'stock'];

    if (data.length) {
        variants = data[0].products[0].variants
        variants.forEach(variant => {
            longestVariant = variant.types.length > longestVariant.length ? variant.types : longestVariant
        })
    }

    const [showDetail, setShowDetail] = React.useState(
        data.map(data => false)
    )

    const handleShowDetail = (a) => {
        setShowDetail(state => {
            state[a] = !state[a];
            return [...state]
        })
    }

    const getTotalReceivementQtyPerBranch = (branch, lvIdx) => {
        return branch.products.reduce((totalPerProducts, product) => {
            return totalPerProducts += product.variants.reduce((totalPerVariant, variant) => {
                return totalPerVariant += variant.types.reduce((total, type, index) => {
                    if (lvIdx == index) return total += type.in
                    else return total
                }, 0)
            }, 0)
        }, 0)
    }

    return !data.length ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '40px' }}>Inventory by Customer Report <span>Showing report from all branches</span></p>
            <div className="scroll-x">
                <table className="table-report inventory-stock">
                    <thead>
                        <tr>
                            <th rowSpan={variants.length + 1}>no.</th>
                            <th rowSpan={variants.length + 1}>Code</th>
                            <th rowSpan={variants.length + 1}>type</th>
                            <th colSpan="2" rowSpan={variants.length + 1}>branch name</th>
                            <th colSpan={longestVariant.length * detailsHeadLabel.length}>variant</th>
                            <th rowSpan={variants.length + 1}>stock</th>
                            <th rowSpan={variants.length + 1}>reject</th>
                            <th rowSpan={variants.length + 1}>UoM</th>
                            <th className="cell-tr" rowSpan={variants.length + 1}>Total Value</th>
                        </tr>
                        {
                            variants.map(variant => (
                                <tr>
                                    {
                                        longestVariant.map((lv, lvIdx) => (
                                            <th colSpan={detailsHeadLabel.length}>{variant?.types?.[lvIdx]?.label ?? '-'}</th>
                                        ))
                                    }

                                </tr>
                            ))
                        }
                    </thead>
                    <tbody>
                        {
                            data.map((d, dIdx) => {
                                return (
                                    <>
                                        <tr>
                                            <td>{dIdx + 1}.</td>
                                            <td className="cell-width-as-content cell-highlight">
                                                <span className="trigger-show-detail" onClick={() => handleShowDetail(dIdx)}>
                                                    {d.branch_code} <KeyboardArrowDownIcon className="info-icon" />
                                                </span>
                                            </td>

                                            <td>{d.type}</td>
                                            <td colSpan="2">{d.branch_name}</td>
                                            {
                                                longestVariant.map((lv, lvIdx) => {
                                                    return detailsHeadLabel.map((dhl, dhlIdx) => (
                                                        <td>{dhlIdx < 1 ? getTotalReceivementQtyPerBranch(d, lvIdx) : ' '}</td>
                                                    ))
                                                })
                                            }
                                            <td>{d.stock}</td>
                                            <td>{d.reject}</td>
                                            <td></td>
                                            <td className="cell-tr">IDR {money(d.total_value)}</td>
                                        </tr>
                                        {
                                            d.products.map((product, productIdx) => {
                                                return (
                                                    <>
                                                        {
                                                            productIdx < 1 && (
                                                                <tr className={`${showDetail[dIdx] ? '' : 'hide'} tr-detail detail-heading`}>
                                                                    <td>No.</td>
                                                                    <td>Code</td>
                                                                    <td>brand</td>
                                                                    <td>product name</td>
                                                                    <td className="cell-tr">sales price</td>
                                                                    {
                                                                        longestVariant.map((lv, lvIdx) => {
                                                                            return detailsHeadLabel.map(dhl => {
                                                                                return <td>{dhl}</td>
                                                                            })
                                                                        })
                                                                    }
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            )
                                                        }
                                                        <tr className={`${showDetail[dIdx] ? '' : 'hide'} tr-detail detail-body`}>

                                                            <td>{dIdx + 1}.{productIdx + 1}.</td>
                                                            <td className="cell-width-as-content">{product.code}</td>
                                                            <td className="cell-width-as-content">{product.brand}</td>
                                                            <td className="cell-width-as-content">{product.name}</td>
                                                            <td className="cell-currency cell-tr">IDR {money(product.sales_price)}</td>
                                                            {
                                                                longestVariant.map((lv, lvIdx) => {
                                                                    return detailsHeadLabel.map(dhl => {
                                                                        let qty = 0;
                                                                        data[dIdx].products[productIdx].variants.forEach(variant => {
                                                                            qty += variant?.types?.[lvIdx]?.[dhl] ?? 0;
                                                                        })

                                                                        return <td>{qty}</td>
                                                                    })
                                                                })
                                                            }
                                                            <td>{product.stock}</td>
                                                            <td>{product.reject}</td>
                                                            <td>{product.uom_name}</td>
                                                            <td className="cell-tr cell-currency">IDR {money(product.total_value)}</td>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}