import {
    ACQUISITION_LOADING,
    ACQUISITION_SET,
    ACQUISITION_ERROR,
    ACQUISITION_ADD,
    ACQUISITION_SHOW,
    ACQUISITION_DELETE,
    ACQUISITION_UPDATE,
    ACQUISITION_RESET,
    ACQUISITION_EDIT,
    ACQUISITION_CONFIRM,
    ACQUISITION_STOP_LOADING
} from './acquisitionActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const acquisitionReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case ACQUISITION_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case ACQUISITION_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ACQUISITION_UPDATE:
            // ketika pindah ke done idEdit tidak dipasang seperti seharusnya 
            // jadi diakali ngambil dari pyload ny 
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case ACQUISITION_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case ACQUISITION_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New Receipts has been added successfully.'
            }
        case ACQUISITION_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case ACQUISITION_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case ACQUISITION_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case ACQUISITION_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload,
            }
        case ACQUISITION_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case ACQUISITION_STOP_LOADING: {
            return {
                ...state,
                loading: false
            }
        }
        case ACQUISITION_CONFIRM:
            console.log('111111111111', payload, type);

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData

            }
        default:
            return state;
    }
}

export default acquisitionReducer