import React, { useState, useRef } from 'react'
import Button from '@material-ui/core/Button';
import SubModuleDropdown from './SubModuleDropdown';
import { makeStyles } from '@material-ui/core/styles';
import NavWarning from 'Elements/navwarning/NavWarning';

const useStyles = makeStyles((theme) => ({
    root: {
        textTransform: 'capitalize',
        display: 'inline-block',
        padding: '24px',
        fontSize: '13.3px',
        fontWeight: 600,
        color: '#aaa',
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: `1px solid transparent`,
        marginBottom: '-1px',
        '&:hover': {
            color: '#777',
            backgroundColor: 'transparent'
        }
    },
    active: {
        color: '#333',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
    }
}));

export default function SubmoduleNavItem(props) {
    const { nav, setNavs, renderContent } = props;
    const classes = useStyles();
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const handleClick = (label, comp) => {
        setOpen((prevOpen) => !prevOpen);
        renderContent(nav);
        setNavs(state => {
            return state.map(s => {
                s.active = s.label === label;
                return s;
            })
        })
    };
    const handleClose = (event, nav) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        renderContent(nav);
        setOpen(false);
    };
    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);


    return (
        <>
            <Button
                className={`${classes.root} ${nav.active && classes.active} `}
                aria-haspopup="true"
                aria-controls={open ? 'menu-list-grow' : undefined}
                onClick={() => handleClick(nav.label, nav.component)}
                ref={anchorRef}
            >
                {nav.label}
                {nav.warning > 0 && <NavWarning num={nav.warning} />}
            </Button>
            {
                nav.child &&
                <SubModuleDropdown
                    handleListKeyDown={handleListKeyDown}
                    open={open}
                    items={nav.child}
                    position={nav.dropdownPos ? nav.dropdownPos : null}
                    renderContent={renderContent}
                    handleClose={handleClose}
                    anchorRef={anchorRef.current}
                />
            }
        </>
    )
}
