import React, { useState, useEffect } from 'react'
import AsyncSelect from 'react-select/async';
import { Field } from 'formik';
import { filterProduct } from 'Reduxes/inventory/product/productActions'
import { filterCategory } from 'Reduxes/inventory/configuration/category/categoryActions'
import { filterContact } from 'Reduxes/contact/contactActions'
import { filterInvoice, filterInvoiceToPaid } from 'Reduxes/consignment/invoice/invoiceActions'
import { filterBrand } from 'Reduxes/inventory/configuration/brand/brandActions'
import { filterMember, loadProvince, loadCity } from 'Reduxes/member/memberActions'
import { filterSales } from 'Reduxes/pos/sales/salesActions'
import { filterBranch } from 'Reduxes/configuration/branch/branchActions'
import { useDispatch } from 'react-redux'
import { ErrorMessage, useFormikContext } from 'formik';

export default function SelectAsync(props) {
    let {
        type, handleChange, note, portal, name, resetOnSelect, cache, label, value, index, param,
        options, col, placeholder, defaultValue, showAddress, id, isClearable = true, isDisabled, extraParams, isMulti = false,
        additionalPropsReport
    } = props;

    const formik = useFormikContext();
    const [cacheField, setChaceField] = useState(true)
    const [valueByProp, setValueByProp] = useState(value);
    const [autoNote, setAutoNote] = useState(note || null);
    const [selectValue, setSelectValue] = useState(null);
    const dispatch = useDispatch();
    let [searchResult, setSearchResult] = useState([]);
    const [isClearProduct, setIsClearProduct] = useState(null)


    // ketika product name yg dicari
    // ditampilkan flat
    // contoh kasusnya di stock opname
    // maka ditentukan pake code atau id sebagai value
    const valueAttr = param && param.flat && type === 'productName' ? 'code' : 'id';


    const loadOptions = async (inputValue = '', callback) => {
        if (inputValue.length < 1) {
            return;
        }

        let objVal = null
        let resp = null;
        let dataArray;


        if (type === 'productId' || type === 'productName' || type === 'productCode') {
            const key = type === 'productId' || type === 'productCode' ? 'code' : 'q'

            inputValue = type !== 'productName' ? inputValue.toUpperCase() : inputValue;
            const objParam = {
                [key]: inputValue,
                ...(param ? param : {})
            }


            if (extraParams) {
                let newObjParam = {
                    ...objParam,
                    ...extraParams
                }

                resp = await dispatch(filterProduct(newObjParam))

            } else {
                resp = await dispatch(filterProduct(objParam))
            }
        } else if (type === 'category') {
            const p = { q: inputValue, ...(param || {}) }
            if (extraParams) {
                let newObjParam = {
                    ...p,
                    ...extraParams
                }

                resp = await dispatch(filterCategory(newObjParam))

            } else {
                resp = await dispatch(filterCategory(p))
            }

        } else if (type === 'member') {
            resp = await dispatch(filterMember({ q: inputValue }))
        } else if (options === 'invoice_number') {
            resp = await dispatch(filterInvoice({
                [type === 'invoice_number' ? 'code' : 'q']: inputValue,
                ...(param ? param : {})
            }))
        } else if (options === 'invoice_to_paid') {
            resp = await dispatch(filterInvoiceToPaid(id))
        } else if (options === 'contact-brand') {
            resp = await dispatch(filterContact({ q: inputValue, is_brand: true }))
        } else if (options === 'contact') {
            resp = await dispatch(filterContact({ q: inputValue }))
        } else if (options === 'brand') {
            resp = await dispatch(filterBrand({ q: inputValue }))
        } else if (type === 'transaction') {
            resp = await dispatch(filterSales({ q: inputValue }))
        } else if (type === 'transaction_return') {
            resp = await dispatch(filterSales({ q: inputValue, is_return: 'true' }))
        } else if (options === 'internal-transfer') {
            resp = await dispatch(filterBranch({ q: inputValue }))
        } else if (options === 'province') {
            resp = await dispatch(loadProvince({ q: inputValue }))
        } else if (options === 'city') {
            resp = await dispatch(loadCity({ q: inputValue }))
        } else if (Array.isArray(options)) {
            const searchResult = options.filter(x => x.label.toLowerCase().includes(inputValue))
            callback(searchResult);
            setSearchResult(options)
            return;
        }

        if (resp != null && resp.status) {
            dataArray = Array.isArray(resp.data) ? resp.data : [resp.data]
            if (type === 'productCode' || type === 'transaction' || type === 'transaction_return' || type === 'invoice_number' || type === 'brandCode') {
                objVal = dataArray.map(data => (
                    { value: data.id, label: data.code }
                ))
            } else if (type === "province") {

                objVal = dataArray.map(data => (
                    // {value : data.id, label: data.provinsi}
                    { value: data.provinsi, label: data.provinsi }

                ))

            } else if (type === "city") {


                objVal = dataArray.map(data => (
                    { value: data.ibukota, label: data.ibukota }
                ))

            } else {
                objVal = dataArray.map(data => {
                    return { value: data[valueAttr], label: `${data?.name ?? data?.branch_name} ${data.color_name || ''} ${data.variant_type_value ? `(${data.variant_type_value})` : ``}` }
                })
            }
        } else {
            objVal = []
        }

        callback(objVal)
        setSearchResult(dataArray)
    };
    const [inputValue, setInputValue] = useState('')
    const handleInputChange = (newValue) => {
        setInputValue({ inputValue: newValue });
        return newValue
    };
    const customStyles = {
        base: (base) => ({
            ...base,
            zIndex: 9999,
            background: 'red'
        }),
        option: (provided, state) => ({
            ...provided,
            fontWeight: '400',
            fontSize: '13px',
            color: '#555',
            zIndex: 999999,
        }),
        singleValue: (provided) => ({
            ...provided,
            color: '#555',
            top: '55%',
            fontSize: '13px',
        }),
        input: (provided) => ({
            ...provided,
            margin: '0 2px',
            paddingTop: 0,
            paddingBottom: 0,
            height: '38px',
            paddingLeft: '4px',
            color: '#555',
            fontSize: '13px',
            '&input': {
                background: 'magenta'
            }
        }),
        control: (provided, state) => ({
            ...provided,
            width: '100%',
            border: state.isFocused ? '1px solid #red' : '1px solid #ddd',
            boxShadow: state.isFocused ? 0 : 0,
            '&:hover': {
                // border: state.isFocused ? 0 : 0
            }
        }),
        placeholder: () => ({
            fontWeight: '400',
            fontSize: '13px',
            color: '#555',
        }),
        noOptionsMessage: (provided) => ({
            ...provided,
            fontWeight: '400',
            fontSize: '13px',
            color: '#555',
            overflow: 'visible'
        }),
    }

    useEffect(() => {

        if (!selectValue) {
            // jika propsnya valueByProp modul add sales order, add sales return, add invoice error
            // setSelectValue(valueByProp)
            setSelectValue(value)
        } else if (options === 'sales_order' || options === 'sales_return' || options === "invoice") {
            setSelectValue(value)
        }

    }, [value])

    useEffect(() => {
        loadOptions();
    }, [options])


    useEffect(() => {
        if (!cache) {
            setChaceField(cache)
        }
    }, [])


    return (
        <div className={`field-container ${col ? `span-${col}` : ``}`}>

            {label && <label htmlFor={name}>{label}</label>}
            <Field name={name}>
                {(formik) => {
                    let { form, field } = formik;
                    const { setFieldValue, setValues, values } = form;
                    const { value, name } = field;

                    return (
                        <>
                            <AsyncSelect
                                cacheOptions={cacheField}
                                loadOptions={loadOptions}
                                defaultOptions
                                isMulti={isMulti}
                                placeholder="Search option"
                                autoSize={false}
                                isClearable={isClearable}
                                name={name}
                                styles={customStyles}
                                isDisabled={isDisabled}
                                {...portal ? { menuPortalTarget: document.body } : {}}
                                classNamePrefix="custom-react-select"
                                onInputChange={handleInputChange}
                                value={selectValue}
                                {
                                ...resetOnSelect ? { resetOnSelect: true } : {}
                                }
                                onChange={(opt, state) => {
                                    setIsClearProduct(state)
                                    setSelectValue(opt)

                                    if (!opt) {
                                        setAutoNote(null)

                                        /** 
                                         * if user clicked clear indicator, then give callback of data with value nothing
                                         */

                                        if (additionalPropsReport && additionalPropsReport === "SelectProduct") {
                                            handleChange({
                                                value: null,
                                                rows: {
                                                    value: '',
                                                    label: ''
                                                },
                                                formikAttr: formik,
                                                props
                                            })
                                        }
                                    }

                                    if (opt) {
                                        if (opt.value) {
                                            setFieldValue(name, opt.value)
                                            if (options === 'contact' || options === 'contact-brand' || options === 'internal-transfer' && showAddress) {
                                                const row = searchResult && searchResult.find(search => search.id === +opt.value);
                                                const { address, province, city, country, postal_code } = row
                                                const parsedAddress = [address ? address : null, city ? city : null, province ? province : null, country ? country : null, postal_code ? postal_code : null].filter(Boolean)
                                                setAutoNote(parsedAddress.join(', '))
                                            }
                                        }
                                        if (handleChange) {
                                            handleChange({
                                                value: opt.value,
                                                rows: searchResult,
                                                ...(Array.isArray(options) && { row: searchResult.find(x => x.value === opt.value) }),
                                                ...(!Array.isArray(options) && searchResult && { row: searchResult.find(search => search[valueAttr] == (valueAttr === 'code' ? opt.value : +opt.value)) }),
                                                formikAttr: formik,
                                                props
                                            })
                                        }
                                    }
                                }}
                            />
                            {/* {note && <span className="note">{note}</span>} */}
                            {autoNote && <span className="note">{autoNote}</span>}
                            {/*<ErrorMessage name={name} component="div" className="errMsg" />*/}
                        </>
                    )
                }}
            </Field >
        </div >
    )
}
