import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts } from 'Configs/table'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Alert from 'Comps/elements/Alert'
import Table from 'Elements/Table'
import TableNoData from 'Comps/elements/TableNoData'
import { deleteColor, resetStatusColor } from 'Reduxes/inventory/configuration/color/colorActions'
import LoadingContent from 'Elements/LoadingContent';
import Permission from '../../../../comps/permission/Permission'


const useStyles = makeStyles(theme => ({
    table: {
        // '& td:nth-child(2)': {
        //     width: '10%',
        // },
        // '& td:nth-child(3)': {
        //     width: '20%',
        // },
        // '& td:nth-child(4)': {
        //     width: '10%',
        // },
        '& .row-action': {
            color: '#555',
            fontSize: '16px',
            marginRight: '8px',
            cursor: 'pointer'
        }
    },
}));


export default function TableData(props) {

    const { setContentActiveFormEdit, deleteColorProps, setContentActiveDetail } = props
    const { data, loading, error, success } = useSelector(state => state.colorReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const table = tableOpts({
        data: data?.filter(brand => brand.id > 1),
        colIdPosition: 0,
        noPrint: true,
        filter: false,
        filename: "Color_Configuration_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                },
                { name: "code", label: 'Code' },
                { name: "name", label: 'Color name' },
                {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: 0,
                                zIndex: 51
                            }
                        }),
                        setCellProps: () => ({

                            style: {
                                whiteSpace: "nowrap",
                                position: "sticky",
                                right: "0",
                                background: "white",
                                zIndex: 51
                            }
                        }),
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => setContentActiveDetail(tableMeta.rowIndex)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => setContentActiveFormEdit(value)
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => deleteColorProps([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />

                                    <Permission
                                        component={
                                            <EditIcon {...attrEdit} />
                                        }
                                        subMenu="color"
                                        type="update"
                                    />

                                    {
                                        value > 1 &&
                                        <Permission
                                            component={
                                                <DeleteIcon {...attrDelete} />
                                            }
                                            subMenu="color"
                                            type="delete"
                                        />
                                    }
                                </>
                            );
                        }
                    }
                },
            ],
        },
        deleteAct: (ids) => {
            dispatch(deleteColor(ids))
        }
    })


    return (
        <>
            {
                success &&
                <Alert hide={() => dispatch(resetStatusColor())} option={{ type: 'success', message: success }} />
            }
            {
                error && <Alert hide={() => dispatch(resetStatusColor())} option={{ type: 'error', message: error }} />
            }
            {
                loading ? <LoadingContent />
                    : (
                        data.length ?
                            <Table {...table} className={classes.table} />
                            : <TableNoData />
                    )
            }
        </>
    )
}

