import React from 'react'
import Dashboard from 'Pages/Dashboard'
import Branch from 'Pages/configuration/branch/index'
import Profile from 'Pages/configuration/profile/index'
import Log from 'Pages/configuration/log/Index'
import Submodule from 'Comps/layouts/Submodule'

export default function Configuration() { 
    
    const pageTitle = {
        title: 'Settings',
        description: 'You can manage branch, log and profile here',
    }

    const breadcrumb = [
        { label: 'Home' },
        { label: 'configuration' },
        { label: 'Branch', active: true },
    ]

    const subModules = [
        { 
            label: 'Profile', 
            component: Profile,
        },
        { 
            label: 'Branch', 
            component: Branch, 
            permissionName: 'branch',
            permissionType: 'read',
        },
        { 
            label: 'Log', 
            component: Log,
            permissionName: 'log',
            permissionType: 'read', 
        },
    ]

    return (
        <Dashboard pageTitle={pageTitle} breadcrumb={breadcrumb} >
            <Submodule buttonAdd subModules={subModules} />
        </Dashboard>
    )
}
