import {
    CATEGORY_LOADING,
    CATEGORY_SET,
    CATEGORY_ERROR,
    CATEGORY_ADD,
    CATEGORY_SHOW,
    CATEGORY_DELETE,
    CATEGORY_UPDATE,
    CATEGORY_RESET,
    CATEGORY_EDIT,
    CATEGORY_STOP_LOADING,
} from './categoryActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
}

const categoryReducer = (state = initialState, { payload, type }) => {

    switch (type) {
        case CATEGORY_LOADING:
            return {
                ...state,
                loading: true,
            }
        case CATEGORY_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case CATEGORY_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case CATEGORY_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case CATEGORY_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New category has been added successfully.'
            }
        case CATEGORY_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case CATEGORY_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case CATEGORY_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case CATEGORY_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case CATEGORY_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default categoryReducer