import React, { useEffect, useState } from 'react'
import { resetStatusSalesreturn, addSalesreturn, updateSalesreturn } from 'Reduxes/consignment/salesreturn/salesreturnActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop } from '@/Helper'
import SelectAsync from 'Elements/SelectAsync';
import Input from 'Elements/Input';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import { serverValue } from 'Configs/Form'
import TableProduct from 'Elements/tableproduct/TableProduct'

function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const { type, showTable } = props;
    const [tableProductDefaultData, setTableProducDefaulttData] = useState(null);
    const dispatch = useDispatch();
    const [returnItem, setReturnItem] = useState([])
    const [isReady, setIsReady] = useState(true);
    const [contact, setContact] = useState(null)
    const { error, idEdit, data } = useSelector(state => state.salesreturnReducer);
    const [initialValues, setInitialValues] = useState({
        "contact_id": '',
        "date": new Date().toISOString().split('T')[0],
        "reference": '',
        "shipment_date": '',
        "note": '',
        "sales_order_products": [],
    })
    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const {
                contact_id, date, reference, note
            } = values;

            const sales_return_products = returnItem.map(({
                uom_id,
                id: product_id,
                variant_type_id = null,
                quantity,
                discount,
                comission,
                sales_price
            }) => {
                if (!product_id) {
                    return null
                }
                return {
                    uom_id,
                    product_id,
                    variant_type_id,
                    quantity,
                    discount: comission ? (sales_price * comission) / 100 : discount,
                    price: sales_price,
                }
            }).filter(Boolean)

            const cleanVal = {
                contact_id, reference, date, note, sales_return_products
            }
            console.log('srp', returnItem)
            console.log('ini clean', cleanVal)
            // return;
            let result = type === 'new' ?
                await dispatch(addSalesreturn(cleanVal))
                : await dispatch(updateSalesreturn(idEdit, cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }
    const handleChangeContact = (data) => {
        setContact(null);

        setColumns(state => {
            state[0].param = { contact_id: data.value, flat: true, consignment: 1 }
            state[1].param = { contact_id: data.value, flat: true, consignment: 1 }
            return [...state]
        })
    }

    const [columns, setColumns] = useState([
        { label: 'Code', name: 'code', className: 'td-1', param: { flat: true } },
        { label: 'Product Name', name: 'name', className: 'td-1', param: { flat: true } },
        { label: 'Price /Unit', name: 'sales_price', type: 'money', align: 'right' },
        { label: 'Disc (%)', name: 'disc', align: 'right' },
        // { label: 'Qty', name: 'quantity', type: 'inputNumber', value: 0 },
        { label: 'Qty', name: 'quantity', type: 'inputNumber', value: 0 },
        { label: 'UoM', name: 'uom_id', type: 'selectData', value: 0 },
        { label: 'Total', name: 'total', align: 'right' },
    ]);

    useEffect(() => {
        if (type === 'edit') {
            let findData = data.find(d => d.id === idEdit);

            const { code, contact, date, discount, status, sub_total, total, sales_return_products } = findData;
            const salesReturnProducts = sales_return_products.map(singleProduct => {
                console.log(singleProduct)
                const { sku, discount, price, name, quantity, total_price, unit, variant_type, product, variant } = singleProduct
                return {
                    id: product.id,
                    code: sku,
                    variant_type_id: variant?.id,
                    discount,
                    sales_price: price,
                    name: variant ? `${name} ${product.color.name} - (${variant.value})` : name,
                    quantity,
                    total: total_price,
                    uom_id: unit.id,
                    uom_value: unit.value,
                }
            })

            setTableProducDefaulttData(salesReturnProducts)

            setContact(contact);

            setInitialValues({
                code,
                contact_id: contact.id,
                date,
                discount,
                status,
                sub_total,
                total,
                sales_return_products: salesReturnProducts,
            })
        }
    }, [])



    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Sales Return" : "Update data Sales Return"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data sales return `}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusSalesreturn())} option={{ type: 'error', message: error }} />
                }

                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {

                                    const { isSubmitting } = formik;

                                    return (
                                        <Form className="form-layout-1">
                                            <FieldsContainer title="Sales return Information">
                                                <SelectAsync name="contact_id" {...(contact ? { value: { label: contact.name, value: contact.id } } : {})} showAddress options="contact" handleChange={handleChangeContact} label="Contact" />
                                                <Datepicker name="date" label="Date" />
                                                <Input name="reference" label="Reference" />
                                            </FieldsContainer>
                                            <div className="scroll">
                                                <TableProduct
                                                    moduleType="salesreturn"
                                                    defaultData={tableProductDefaultData}
                                                    columns={columns}
                                                    handlechange={(val) => setReturnItem(val)}
                                                    dataErrStatus={setDataErr}
                                                    portal={true}
                                                    isConsignment={true}
                                                />
                                            </div>
                                            <Textarea name="note" label="Notes" col="6" />
                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper>
    )
}

export default FormElement