import {
    VARIANT_LOADING,
    VARIANT_SET,
    VARIANT_ERROR,
    VARIANT_ADD,
    VARIANT_SHOW,
    VARIANT_DELETE,
    VARIANT_UPDATE,
    VARIANT_RESET,
    VARIANT_EDIT,
} from './variantActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idxShow: null,
    nomor: 1
}

const variantReducer = (state = initialState, { payload, type }) => {

    switch (type) {
        case VARIANT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case VARIANT_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case VARIANT_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case VARIANT_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New variant has been added successfully.'
            }
        case VARIANT_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case VARIANT_SHOW:
            return {
                ...state,
                idxShow: payload,
                success: null,
                error: null
            }
        case VARIANT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case VARIANT_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case VARIANT_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        default:
            return state;
    }
}

export default variantReducer