import React from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'

export default function Detail(props) {

    const { data, showTable } = props

    const detailPlaceholder = {
        branch_code: 'Branch Code',
        branch_name: 'Branch Name',
        phone: 'Phone',
        email: 'Email',
        status: 'Status',
        address: 'Address',
        website: 'Website',
    }

    return (
        <ContentWrapper
            title="Branch Detail"
            subtitle={`Information for Branch ${data.branch_name || 'N/a'}`}
            buttons={[
                {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => showTable(),
                },
            ]}
        >
            <div className="detail branch">
                <div className="col">
                    <div className="row">
                        <span className="key">{ detailPlaceholder.branch_code }</span>
                        <span className="val">{ data.branch_code }</span>
                    </div>
                    
                    <div className="row">
                        <span className="key">{ detailPlaceholder.branch_name }</span>
                        <span className="val">{ data.branch_name }</span>
                    </div>
                    <div className="row">
                        <span className="key">{ detailPlaceholder.phone }</span>
                        <span className="val">{ data.phone }</span>
                    </div>
                    <div className="row">
                        <span className="key">{ detailPlaceholder.email }</span>
                        <span className="val">{ data.email }</span>
                    </div>
                    
                </div>
                <div className="col">
                    <div className="row">
                        <span className="key">{ detailPlaceholder.status }</span>
                        <span className="val">
                            <label className={`label ${data.is_active ? `success` : `danger`}`}>{data.is_active ? 'Active' : 'Inactive'}</label>
                        </span>
                    </div>
                    <div className="row">
                        <span className="key">{ detailPlaceholder.address }</span>
                        <span className="val">{ data.address }, { data.province }, { data.city }, { data.country }, { data.postal_code }</span>
                    </div>
                    <div className="row">
                        <span className="key">{ detailPlaceholder.website }</span>
                        <span className="val">{ data.web_address }</span>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    )
}
