import React from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChildRow from './ChildRow';

export default function ParentRow({ product, showDetail: defaultShowDetail, variants, index, longestVariant }) {
    const [showDetail, setShowDetail] = React.useState(defaultShowDetail)

    const handleShowDetail = () => {
        setShowDetail(!showDetail)
    }

    React.useEffect(() => {
        setShowDetail(!showDetail)
    }, [defaultShowDetail])

    React.useEffect(() => {
        setShowDetail(false)
    }, [product])

    return (
        <>
            <tr>
                <td>{index + 1}.</td>
                <td></td>
                <td className="cell-width-as-content cell-highlight">
                    <span className="trigger-show-detail" onClick={handleShowDetail}>
                        {product.code} {product.branches.length && <KeyboardArrowDownIcon className="info-icon" />}
                    </span>
                </td>
                <td className="cell-width-as-content">{product.category_name}</td>
                <td className="cell-width-as-content">{product.product_name}</td>
                <td className="cell-width-as-content">{product.sales_type}</td>
                <td className="cell-width-as-content">{product.brand_name}</td>
                <td>{product.uom_name}</td>
                <td colSpan={(longestVariant.length * 6) + 12}></td>
            </tr>
            {
                showDetail &&
                <ChildRow
                    data={product.branches}
                    variants={variants}
                    branches={product.branches}
                    longestVariant={longestVariant}
                />
            }
        </>
    )
}
