import React from 'react'
import Img from 'Img/empty.png'

export default function TableNoData() {
    return (
        <div className="tablenodata">
            <img src={Img} alt="no data icon"/>
            <p>There is no data yet, create one.</p>
        </div>
    )
}
