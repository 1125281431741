import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl, makeGetParam } from 'Configs/request'
export const BRANCH_SET = 'BRANCH_SET'
export const BRANCH_ERROR = 'BRANCH_ERROR'
export const BRANCH_ADD = 'BRANCH_ADD'
export const BRANCH_SHOW = 'BRANCH_SHOW'
export const BRANCH_EDIT = 'BRANCH_EDIT'
export const BRANCH_UPDATE = 'BRANCH_UPDATE'
export const BRANCH_DELETE = 'BRANCH_DELETE'
export const BRANCH_LOADING = 'BRANCH_LOADING'
export const BRANCH_RESET = 'BRANCH_RESET'
const type = "BRANCH"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const loadBranch = (all) => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        // body: () => fake,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/branch${all ? '?all=true' : ''}`),
        next: (payload) => dispatch({ type: BRANCH_SET, payload })
    })
}
export const filterBranch = (args = {}) => async (dispatch) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/branch${makeGetParam(args)}`),
    })
}
export const addBranch = data => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/branch`, data),
        next: (payload) => dispatch({ type: BRANCH_ADD, payload })
    })
}
export const updateBranch = (idEdit, data) => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/branch/${idEdit}`, data),
        next: (payload) => dispatch({ type: BRANCH_UPDATE, payload })
    })
}
export const deleteBranch = id => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/branch`, id),
        next: () => dispatch({ type: BRANCH_DELETE, payload: id })
    })
}
export const showBranch = idx => {
    return {
        type: BRANCH_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: BRANCH_EDIT,
        payload: idEdit,
    }
}
export const resetStatusBranch = () => {
    return {
        type: BRANCH_RESET
    }
}