import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableData from "./TableData";
import Detail from "./Detail";
import { loadBranch, showBranch } from "Reduxes/configuration/branch/branchActions";
import FormElement from './FormElement'

export default function Branch() {

    const { data, idxShow } = useSelector(state => state.branchReducer);
    const dispatch = useDispatch();
    const [content, setContent] = useState('table')
    const dev = 0

    useEffect(() => {
        dispatch(loadBranch({ all: true }))
    }, [])

    const showDetail = (id) => {
        dispatch(showBranch(id))
        setContent('detail')
    }

    const showTable = () => {
        setContent('table')
    }

    const showForm = (type) => {
        setContent('form' + type)
    }

    const fieldGroup = [
        {
            name: "General Information",
            fields: [
                {
                    label: "Branch Code",
                    validation: "req",
                    name: "branch_code",
                    value: dev ? "PW-" : ""
                }, {
                    label: "Branch Name",
                    name: "branch_name",
                    validation: "req",
                    value: dev ? "Branch testing" : ""

                }, {
                    name: "is_active",
                    validation: "req",
                    label: "Status",
                    type: "select",
                    placeholder: "Select status",
                    options: [
                        { label: "Active", value: "true" },
                        { label: "Inactive", value: "false" },
                    ],
                    value: "true"
                }
            ]
        }, {
            name: "Address Information",
            fields: [
                {
                    label: "Address",
                    validation: "req",
                    col: 6,
                    name: "address",
                    value: dev ? "Jl. Waruga Jaya No.108, Ciwaruga, Kec. Parongpong, Kabupaten Bandung Barat" : ""
                }, {
                    label: "Province",
                    validation: "req",
                    name: "province",
                    value: dev ? "West Java" : ""
                }, {
                    label: "City",
                    validation: "req",
                    name: "city",
                    value: dev ? "Bandung" : ""
                }, {
                    label: "Post Code",
                    validation: "req",
                    name: "postal_code",
                    value: dev ? "20242" : ""
                }, {
                    name: "country",
                    value: "Indonesia",
                    type: "hidden"
                }
            ]
        }, {
            name: "Contact Information",
            fields: [
                {
                    label: "Phone",
                    validation: "req",
                    name: "phone",
                    value: dev ? "08212774283" : ""
                }, {
                    label: "Email",
                    validation: "req",
                    name: "email",
                    value: dev ? "pworks@gmail.com" : ""
                },
                {
                    label: "Website",
                    validation: "req",
                    name: "web_address",
                    value: dev ? "pworks.com" : ""
                }
            ]
        }
    ];

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                return <TableData showForm={showForm} showDetail={showDetail} />
            case 'formNew':
                return <FormElement type='new' showTable={showTable} fieldGroup={fieldGroup} />
            case 'formEdit':
                return <FormElement type='edit' showTable={showTable} fieldGroup={fieldGroup} />
            case 'detail':
                return <Detail
                    data={data.filter(dataTarget => dataTarget.id === idxShow)[0]}
                    showTable={showTable}
                    fieldGroup={fieldGroup}
                />
            default:
                return <>Nothing to show here</>

        }
    }

    return (
        <>
            {
                renderContent(content)
            }
        </>
    );
}
