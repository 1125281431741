import React, { useEffect, useState } from 'react'
import Dashboard from 'Pages/Dashboard'
import Submodule from 'Comps/layouts/Submodule'

import LoadingContent from 'Elements/LoadingContent'
import SalesOrder from 'Pages/consignment/salesorder/SalesOrder'
import SalesReturn from 'Pages/consignment/salesreturn/SalesReturn'
import Invoice from 'Pages/consignment/invoice/Invoice'
import Payment from 'Pages/consignment/payment/Payment'
import { default as SalesOrderReport } from 'Pages/consignment/report/sales_order/Report'
import { default as InvoicesReport } from 'Pages/consignment/report/invoices/Report'
import { default as PaymentReport } from 'Pages/consignment/report/payment/Report'
import { default as InventoryPerCustomerReport } from 'Pages/consignment/report/inventory_per_customer/Report'
import { default as BrandComissionReport } from 'Pages/consignment/report/brand_comission/Report'

import { default as ReportInvoice } from './report/invoices/Report'


export default function Consignment() {

    const [subModules, setSubModules] = useState([
        {
            label: 'Sales Order',
            component: SalesOrder,
            active: true,
            permissionName: 'sales_order',
            permissionType: 'read',
        },
        {
            label: 'Sales Return',
            component: SalesReturn,
            permissionName: 'sales_return',
            permissionType: 'read',
        },
        {
            label: 'Invoice',
            component: Invoice,
            permissionName: 'invoice',
            permissionType: 'read',
        },
        {
            label: 'Payment',
            component: Payment,
            permissionName: 'payment',
            permissionType: 'read',
        },
        {
            label: 'Report',
            permissionName: 'report_sales',
            permissionType: 'read',
            child: [
                { label: 'Sales Order', component: SalesOrderReport },
                { label: 'Inventory by Customer', component: InventoryPerCustomerReport },
                { label: 'Invoice', component: ReportInvoice },
                { label: 'Payment', component: PaymentReport },
                { label: 'Brand Comission', component: BrandComissionReport },
            ]
        },
    ])

    const pageTitle = {
        title: 'Consignment',
        description: 'whereby goods are shipped to a dealer who pays you',
    }

    const breadcrumb = [
        { label: 'Home' },
        { label: 'Contact' },
        { label: 'Member', active: true },
    ]

    return (
        <Dashboard pageTitle={pageTitle} breadcrumb={breadcrumb} >
            <Submodule buttonAdd subModules={subModules} />
        </Dashboard>
    )
}
