import React from 'react'
import Bg from 'Img/_AMB8982-2.jpg'
import Logo from 'Img/logo-black.png'
import LoginCabangForm from './LoginCabangForm'

export default function LoginCabang() {
    return (
        <div className="login-page">
            <div className="col-left">
                <div className="caption">
                    <p>
                        We are a community of free spirits,<br />untroubled by the pace of modern life.
                    </p>
                    <h1>
                        <hr />
                        <span>phillipworks</span>
                    </h1>
                </div>
                <img src={Bg} alt="bg" />
            </div>
            <div className="col-right">
                <div>
                    <img src={Logo} className="logo" alt="logo" />
                    <LoginCabangForm />
                </div>
            </div>
        </div>
    )
}

