import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import DetailHeader from 'Layouts/detailheader/DetailHeader'

export default function Detail(props) {

    const { idShow, data } = useSelector(state => state.contactReducer);
    const detail = data.filter((a, b) => b === +idShow)[0]


    let type = [];
    type.push(detail.is_user ? 'User' : null)
    type.push(detail.is_brand ? 'Brand' : null)
    type.push(detail.is_consignment ? 'Partnership store' : null)
    type.push(detail.is_supplier ? 'Supplier' : null)

    const getBrandCommissionLabel = (detail) => {
        if (!detail.brand_comission_type) return  "-"

        return detail.brand_comission_type === 'all_product' ?
            detail.brand_comission ? detail.brand_comission + ' %' : '0%'
            : 'By Category'
    }

    const getPartnerCommissionLabel = (detail) => {
        console.log(detail)
        if (!detail.consignment_comission_type) return  "-"

        return detail.consignment_comission_type === 'all_product' ?
            detail.consignment_comission ? detail.consignment_comission + ' %' : '0%'
            : 'By Category'
    }

    const detailHeader = {
        'Contact ID': detail.code,
        name: detail.name,
        Address: [detail.address, detail.province, detail.city, detail.postal_code].filter(Boolean).join(', '),
        type: type.filter(Boolean).join(', '),
        status: detail.is_active ? 'active' : 'inactive',
        // ...(detail.brand_comission_type ? { 'comission type': detail.brand_comission_type === 'all_product' ? 'All products' : 'By category' } : {}),
        // ...(detail.consignment_comission_type ? { 'Partnership comission type': detail.consignment_comission_type === 'all_product' ? 'All products' : 'By category' } : {}),
        'Username': detail?.username ?? '-',
        'Role': detail?.user_category_name ?? '-',
        email: detail.email,
        Phone: detail.phone,
        Note: detail.note,
        'Brand commission type': detail.brand_comission_type ? detail.brand_comission_type === 'all_product' ? 'All Product' : 'By Category' : '-',
        'Brand commission': getBrandCommissionLabel(detail),
        // ...(detail.brand_comission ? { 'Brand Commission': detail.brand_comission + ' %' } : { 'Brand Commission': 'By Category' }),
        'Partnership commission type': detail.consignment_comission_type ? detail.consignment_comission_type === 'all_product' ? 'All Product' : 'By Category' : '-',
        'Partnership commission': getPartnerCommissionLabel(detail),
        // ...(detail.consignment_comission ? { 'Partnership comission': detail.consignment_comission + ' %' } : { 'Partnership Commission': 'By Category' }),
    }

    return (
        <div>
            <DetailHeader className="contact" data={detailHeader} />
        </div>
    )
}
