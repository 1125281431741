import React from 'react'
import { useSelector, useDispatch } from 'react-redux';

export default function Detail(props) {

    const { idxShow, data } = useSelector(state => state.warehouseReducer);
    const detail = data.filter((a, b) => b === +idxShow)[0]
    const {
        address,
        branch_id,
        id,
        is_store,
        location_name,
        phone,
        status,
        warehouse_code,
    } = detail;


    return (
        <div>
            <div className="detail branch">
                <div className="col">
                    <div className="row">
                        <span className="key">Code</span>
                        <span className="val">{warehouse_code}</span>
                    </div>
                    <div className="row">
                        <span className="key">Status</span>
                        <span className="val">
                            <label className={`label ${status ? `success` : `danger`}`}>{status ? 'Active' : 'Inactive'}</label>
                        </span>
                    </div>
                    <div className="row">
                        <span className="key">Store</span>
                        <span className="val">{is_store ? 'Yes' : 'No'}</span>
                    </div>
                </div>
                <div className="col">
                    <div className="row">
                        <span className="key">Branch</span>
                        <span className="val">{location_name}</span>
                    </div>
                    <div className="row">
                        <span className="key">Address</span>
                        <span className="val">{detail.address}</span>
                    </div>
                    <div className="row">
                        <span className="key">Phone</span>
                        <span className="val">{phone}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
