import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import { useDispatch, useSelector } from 'react-redux';
import { resetStatusSales, addSales, showSales, updateSales } from 'Reduxes/pos/sales/salesActions'
import { scrollToTop, money, getLocalStorage } from '@/Helper'
import Textarea from 'Elements/Textarea';
import SelectAsync from 'Elements/SelectAsync';
import SummaryBox from 'Elements/summarybox/SummaryBox';
import FormButton from 'Elements/FormButton'
import Button from 'Elements/Button'
import Alert from 'Comps/elements/Alert'
import SimpleModal from 'Elements/modal/Modal'
import TextHelper from 'Elements/TextHelper'
import TableReturn from './TableReturn';
import TableReturnModal from './TableReturnModal';
import print_sales_return from '../../../lib/print-return';

export default function FormReturn(props) {
    const { type, showTable, rowId } = props;
    const dataLocalStorage = getLocalStorage();
    const dispatch = useDispatch();
    const [selectedReturnProduct, setSelectedReturnProduct] = useState([])
    const [defaultData, setDefaultData] = useState(null);
    const [paymentSummary, setPaymentSummary] = useState([
        { label: 'sub total', value: 'IDR 20. 0000' },
        { label: 'Discount', value: 'IDR 100. 000. 000' },
        { label: 'total', value: 'IDR 90. 0000' }
    ]);
    const [transactionReturn, setTransactionReturn] = useState(null);
    const [change, setChange] = useState(0);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [totalPay, setTotalPay] = useState(0);
    const [modal, setModal] = useState(false);
    const [modalProduct, setModalProduct] = useState(false);
    const { error, idEdit } = useSelector(state => state.salesReducer);
    const [initialValues, setInitialValues] = useState({
        "member_id": '',
        "category": '',
        "shipment_date": '',
        "status": "paid",
        "note": '',
        "voucher": '',
        payment_method: {
            "payment_name": "cash",
            "amount": 0,
            "note": "",
            "change": 0
        }
    })
    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const { voucher, payment_method, member_id, status, note } = values;
            const pos_item = selectedReturnProduct.map((product) => {
                const { price, qty, uom_id, id, product_id, proof_retur, variant_type_id, pos_retur_id } = product;
                return {
                    pos_retur_id: type === "new" || type === "addArrow" ? id : pos_retur_id,
                    product_id,
                    price,
                    uom_id,
                    qty: +qty,
                    proof_retur,
                    ...(variant_type_id ? { variant_type_id: variant_type_id } : {}),
                }
            })

            const cleanVal = {
                "branch_id": dataLocalStorage.branchLogin.id,
                "category": "return", // fix return
                "proof_member": "", // boleh ga dikirim
                note,
                status,
                pos_item,
            }

            // Print Options
            const options = {
                printerName: "PW_POS_PRINTER",
            };

            let result;
            try {
                if (type === 'new' || type === 'addArrow') {
                    result = await dispatch(addSales(cleanVal));
                    if (result.data.struct) {
                        if (result.data.status === "paid") {
                            console.log(result.data.struct)
                            print_sales_return(result.data.struct, options)
                        }
                    }
                } else {
                    result = await dispatch(updateSales(rowId, cleanVal))
                    if (result.data.struct) {
                        if (result.data.status === "paid") {
                            console.log(result.data.struct)
                            print_sales_return(result.data.struct, options)
                        }
                    }
                }
            } catch (err) {
                result = false;
                console.log(err);
            }
            // console.log(type)
            // console.log(selectedReturnProduct)
            // console.log(cleanVal)
            // return;

            // let result = type === 'new' || type === 'addArrow' ?
            //     await dispatch(addSales(cleanVal)) :
            //     await dispatch(updateSales(rowId, cleanVal))



            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }
    const getPaymentSummary = val => {
        setTotalPay(val.total)
    }
    const selectTransaction = (props) => {
        if (!props.row) {
            const trx = props.rows.find(v => parseInt(v.id) === parseInt(props.value));
            setModalProduct(true);
            setSelectedReturnProduct([]);    
            setTransactionReturn(trx);
        } else {
            setModalProduct(true);
            setTransactionReturn(props.row);
            setSelectedReturnProduct([]);
        };
    }
    const addSelectedReturnProduct = (row) => {
        setSelectedReturnProduct([...selectedReturnProduct, row])
    }

    useEffect(() => {
        let subTotal = 0;
        let discount = 0;

        selectedReturnProduct.forEach(el => {
            const qty = +el.qty_input || +el.qty || 1;
            subTotal += el.price * qty;
            discount += (el.discount || 0) * qty;
        });

        setPaymentSummary(state => {

            state[0].value = `IDR ${money(subTotal)}`
            state[1].value = `IDR ${money(discount)}`
            state[2].value = `IDR ${money(subTotal - discount)}`
            return [...state];
        })

    }, [selectedReturnProduct])
    useEffect(() => {
        if (paymentAmount > 0) {
            setChange((+paymentAmount || 0) - (+totalPay || 0))
        }
    }, [totalPay, paymentAmount])
    useEffect(() => {
        const getDataTransc = async () => {
            const dataSales = await dispatch(showSales(rowId));
            setInitialValues({
                note: dataSales.data.note
            })
            if (type === 'edit') {
                setDefaultData(dataSales.data);
                // selectTransaction({ row: dataSales.data });
                setSelectedReturnProduct(dataSales.data.pos_item)
            } else if (type === 'addArrow') {
                setDefaultData(dataSales.data);
                selectTransaction({ row: dataSales.data });
                // setSelectedReturnProduct(dataSales.data.pos_item)
            }
        }

        if (type === 'edit' || type === 'addArrow') {
            getDataTransc();
        }
    }, [])

    return (
        <ContentWrapper
            title={type === 'new' || type === 'addArrow' ? "Create New Sales Refund" : "Update data Sales Refund"}
            subtitle={`Please fill the form below to ${type === 'new' || type === 'addArrow' ? 'add' : 'update'} data sales refund`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusSales())} option={{ type: 'error', message: error }} />
                }

                <SimpleModal
                    customClass="modal"
                    isOpen={modal}
                    width={900}
                    setIsOpen={setModal}
                    title="Something smell good"
                >
                    <div className="modal-sales">
                        <div className="detail style-2">
                            <div className="col">
                                <span className="key">Description</span>
                                <span className="val">Bahureksa - User 1<br />22 March 2021</span>
                            </div>
                            <div className="col">
                                <span className="key">Sales ID</span>
                                <span className="val">#ABC-123-8822</span>
                            </div>
                            <div className="col">
                                <span className="key">Payment</span>
                                <span className="val">IDR 200.000 (Cash)</span>
                            </div>
                            <div className="col">
                                <span className="key">Change</span>
                                <span className="val">IDR 20.000</span>
                            </div>
                        </div>

                        <div className="table-product">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Code Product</th>
                                        <th>Name</th>
                                        <th>Price /unit</th>
                                        <th>Unit</th>
                                        <th>Discount</th>
                                        <th>Total</th>
                                        <th className="right">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...new Array(10)].map((_, idx) => (
                                            <tr key={idx}>
                                                <td>SKU</td>
                                                <td>Name</td>
                                                <td>Price /unit</td>
                                                <td>Unit</td>
                                                <td>Discount</td>
                                                <td>Total</td>
                                                <td className="btn-action">
                                                    <Button
                                                        style="GreenSm"
                                                        icon="plus"
                                                    />
                                                </td>
                                            </tr>
                                        ))

                                    }
                                </tbody>
                            </table>
                        </div>

                        <p className="total">
                            <span>Subtotal : IDR 20. 000. 000</span>
                            <span>Discount : IDR 10. 000. 000</span>
                            <span>PPN : IDR 1. 000. 000</span>
                            <span>Total : IDR 20. 000. 000</span>
                        </p>
                    </div>
                </SimpleModal>

                <Formik {...formOpts} enableReinitialize={true}>
                    {(formik) => {

                        const { isSubmitting } = formik;

                        return (
                            <Form className="form-layout-1">
                                <FieldsContainer title="Sales Information">
                                    <SelectAsync
                                        {...(type === 'edit' && defaultData ? { value: { label: defaultData.code, value: defaultData.code } } : {})}
                                        handleChange={selectTransaction}
                                        type="transaction_return"
                                        name="transaction_id"
                                        label="Search Transaction"
                                    />
                                </FieldsContainer>

                                {
                                    selectedReturnProduct.length < 1 &&
                                    <TextHelper message="Please select product from the transaction you want to return" />
                                }

                                {
                                    selectedReturnProduct.length > 0 &&
                                    <>
                                        <TableReturn
                                            items={selectedReturnProduct}
                                            getPaymentSummary={(v) => getPaymentSummary(v)}
                                            setSelectedReturnProduct={setSelectedReturnProduct}
                                        />
                                        <Textarea name="note" label="Notes" col="6" />
                                        <SummaryBox
                                            items={paymentSummary}
                                        />
                                        <br />
                                        <br />
                                    </>
                                }

                                <SimpleModal
                                    customClass="modal"
                                    isOpen={modalProduct}
                                    setIsOpen={setModalProduct}
                                    width={900}
                                    title="Add Product Return"
                                >
                                    {/* Fix ketika select trx return ngeblank */}
                                    {
                                        transactionReturn && (
                                            <TableReturnModal
                                                addSelectedReturnProduct={addSelectedReturnProduct}
                                                transaction={transactionReturn}
                                                setModalProduct={setModalProduct}
                                            />
                                        )
                                    }
                                </SimpleModal>

                                <FormButton hasDraft isSubmitting={isSubmitting} showTable={showTable} />
                            </Form>
                        )
                    }}
                </Formik>
            </>
        </ContentWrapper>
    )
}
