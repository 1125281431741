import React, { useState, useEffect } from 'react'
import { dateDMY, money, handleSortState, sortData } from '@/Helper'
import TdSort from 'Elements/TdSort'

const headerEnd = {
    stock: {
        has_sort: true,
        sort_type: null,
        label: 'stock'
    },
    reject: {
        has_sort: true,
        sort_type: null,
        label: 'reject'
    },
    uom_name: {
        has_sort: false,
        sort_type: null,
        label: ''
    },
    price: {
        has_sort: true,
        sort_type: null,
        className: 'cell-tr',
        label: 'total value'
    },
}

export default function GeneralRowChild({ indexParent, data2, product, index, details, variants }) {


    const [rows, setRows] = useState(details)
    const [header, setHeader] = useState({
        no: {
            label: 'no.',
            has_sort: false,
        },
        date: {
            has_sort: true,
            sort_type: null,
            label: 'date'
        },
        transaction_code: {
            has_sort: true,
            sort_type: null,
            label: 'Transc ID'
        },
        description: {
            has_sort: true,
            className: 'td-width-as-content',
            sort_type: null,
            label: 'transaction'
        },
        branch_name: {
            has_sort: true,
            colspan: 2,
            sort_type: null,
            className: 'cell-tr',
            label: 'branch'
        },
    })
    const sortColumn = (colName, variantIndex = 1000) => {
        setRows(prevState => {
            prevState = sortData({ type: header[colName].sort_type, column: colName, data: prevState })
            return [...prevState]
        })

        setHeader(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }
    const getTotalInitialSock = (variants) => {
        return variants.reduce((total, variant) => {
            return total += variant.types.reduce((total, type) => { return total + type.stock }, 0)
        }, 0)
    }
    const getTotalInitialReject = (variants) => {
        return variants.reduce((total, variant) => {
            return total += variant.types.reduce((total, type) => { return total + type.reject }, 0)
        }, 0)
    }
    const setColor = (stock, reject) => {
        return stock < 0 || reject < 0 ? { color: 'red' } : { color: 'black' };
    }

    useEffect(() => {
        let variantHeader = {}
        let initRows = []

        Object.keys(variants).map((variant, variantIdx) => {
            variantHeader[`variant${variantIdx}`] = {
                has_sort: true,
                sort_type: null,
                className: 'cell-tr',
                label: ' '
            }
        })

        initRows = details.map((detail, detailIdx) => {
            variants.forEach((lv, lvIdx) => {
                let qty = 0;

                detail.variants.forEach(v => {
                    qty += v?.types?.[lvIdx]?.stock ?? 0
                })

                detail[`variant${lvIdx}`] = qty
            })
            return detail
        })

        setHeader(prev => {
            return {
                ...prev,
                ...variantHeader,
                ...headerEnd
            }
        })

        setRows(initRows)

    }, [details, index, data2, variants])


    return (
        <>
            {
                rows.map((detail, rowIdx) => (
                    <React.Fragment key={`child${rowIdx}`}>
                        {
                            rowIdx === 0 && (
                                <>
                                    <tr className="tr-detail detail-heading">
                                        {
                                            Object.keys(header).map((key, idx) => (
                                                <TdSort
                                                    key={`th-${idx}`}
                                                    label={header[key].label}
                                                    rowspan={header[key].rowspan}
                                                    colspan={header[key].colspan}
                                                    sort={header[key].has_sort}
                                                    handleClick={() => sortColumn(key)}
                                                    className={`${header[key].className}  ${header[key].sort_type}`}
                                                />
                                            ))
                                        }
                                    </tr>
                                    <tr className={`tr-detail detail-body`}>
                                        <td align="center" colSpan={6}><b style={{ fontSize: '10px', color: '#aaa', textTransform: 'uppercase', letterSpacing: '5px' }}>I N I T I A L  - I N V E N T O R Y</b></td>
                                        {
                                            variants.map((lv, lvIdx) => {
                                                let qty = 0;
                                                data2[index].initial_stock.forEach(variant => {
                                                    qty += variant?.types?.[lvIdx]?.stock ?? 0;
                                                })
                                                return <td key={`initialstock${lvIdx}`}><b>{qty}</b></td>
                                            })
                                        }
                                        <td><b>{getTotalInitialSock(data2[index].initial_stock)}</b></td>
                                        <td><b>{getTotalInitialReject(data2[index].initial_stock)}</b></td>
                                        <td colSpan={2}></td>
                                    </tr>
                                </>
                            )
                        }

                        <tr className={`tr-detail detail-body`}>
                            <td style={setColor(detail.stock, detail.reject)}>{indexParent}.{rowIdx + 1}</td>
                            <td style={setColor(detail.stock, detail.reject)}>{dateDMY(detail.date)}</td>
                            <td className="cell-width-as-content" style={setColor(detail.stock, detail.reject)}>{detail.transaction_code}</td>
                            <td style={setColor(detail.stock, detail.reject)}>{detail.description}</td>
                            <td style={setColor(detail.stock, detail.reject)} colSpan="2">{detail.branch_name}</td>
                            {
                                variants.map((lv, lvIdx) => {
                                    let qty = 0;
                                    detail.variants.forEach(variant => {
                                        qty += variant?.types?.[lvIdx]?.stock ?? 0;

                                    })
                                    return <td>{qty}</td>
                                })
                            }
                            <td style={setColor(detail.stock, detail.reject)} >{detail.stock}</td>
                            <td style={setColor(detail.stock, detail.reject)} >{detail.reject}</td>
                            <td style={setColor(detail.stock, detail.reject)} ></td>
                            <td style={setColor(detail.stock, detail.reject)} > {money(detail.price)}</td>
                        </tr>

                    </React.Fragment>
                ))
            }
        </>
    )
}
