import React from 'react'

export default function FieldGroup(props) {
    const { title, children } = props;

    return (
        <div className="fields-group-container category-information">
            <p className="field-group-title">{title}</p>
            {children}
        </div>
    )
}
