import React, { useState, useEffect } from 'react'
import NoReport from 'Elements/NoReport'
import { money, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { Helmet } from 'react-helmet'
import SimpleBarHOC from '../../../comps/elements/SimpleBarHOC'


export default function TableGeneral({ data }) {

    const [rows, setRows] = useState([])
    const [sort, setSort] = useState({
        no: {
            label: 'No',
            has_sort: false,
        },
        period: {
            label: 'periode',
            has_sort: true,
            sort_type: null,
        },
        brand_name: {
            label: 'brand',
            has_sort: false
        },
        sales: {
            label: 'sales',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
        sub_total: {
            label: 'sub total',
            className: 'th-text-right cell-width-as-content',
            has_sort: true,
            sort_type: null
        },
        total_discount: {
            label: 'disc',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        grand_total: {
            label: 'total',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        hpp: {
            label: 'hpp',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        additional_cost: {
            label: 'ad. cost',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        },
        net_income: {
            label: 'net value',
            has_sort: true,
            sort_type: null,
            className: 'th-text-right cell-width-as-content',
        }
    })
    const sortColumn = (colName, tableIndex) => {

        setRows(prevState => {
            prevState.pos = sortData({ type: sort[colName].sort_type, column: colName, data: prevState.pos })
            return { ...prevState }
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {
        setRows(data)
    }, [data])


    return (
        <>
            {
                (rows?.status === 'failed' || rows.pos?.length < 1) ? <NoReport /> :
                    <div style={{ marginTop: '40px' }}>
                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>

                        <p className="asdasd">
                            POS Sales Report
                            <span>Showing POS report from all branches</span>
                        </p>
                        <div className="report-summary-box mb-16">
                            <div className="item teal">
                                <p>
                                    <span>Net Value</span>
                                    <span>IDR {money(data.net_income)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Brand</span>
                                    <span>{data.total_brand}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Sub Total</span>
                                    <span>IDR {money(data.sub_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Discount</span>
                                    <span>IDR {money(data.total_discount)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Total</span>
                                    <span>IDR {money(data.grand_total)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>HPP</span>
                                    <span>IDR {money(data.hpp)}</span>
                                </p>
                            </div>
                            <div className="item">
                                <p>
                                    <span>Other Cost</span>
                                    <span>IDR {money(data.additional_cost)}</span>
                                </p>
                            </div>
                        </div>

                        <SimpleBarHOC status={true}>
                            <table className="table-report  print-report brand">
                                <thead>
                                    <tr>
                                        {
                                            Object.keys(sort).map((key, idx) => (
                                                <ThSort
                                                    key={`th-${idx}`}
                                                    label={sort[key].label}
                                                    colspan={sort[key].colspan}
                                                    sort={sort[key].has_sort}
                                                    handleClick={() => sortColumn(key)}
                                                    className={`${sort[key].className}  ${sort[key].sort_type}`}
                                                />
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        rows?.pos?.map((pos, posIdx) => (
                                            <tr>
                                                <td className="td-index">{posIdx + 1}</td>
                                                <td className="td-width-as-content">{data.period}</td>
                                                <td className="td-highlight cell-min-width-200">{pos.brand_name}</td>
                                                <td>{pos.sales}</td>
                                                <td className="td-text-right td-currency">{money(pos.sub_total)}</td>
                                                <td className="td-text-right td-currency">{money(pos.total_discount)}</td>
                                                <td className="td-text-right td-currency">{money(pos.grand_total)}</td>
                                                <td className="td-text-right td-currency">{money(pos.hpp)}</td>
                                                <td className="td-text-right td-currency">{money(pos.additional_cost)}</td>
                                                <td className="td-text-right td-currency">{money(pos.net_income)}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </SimpleBarHOC>

                    </div>
            }
        </>
    )
}
