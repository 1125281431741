import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


export default function Alert(props) {
    const { type, message } = props.option
    let icon

    if (type === 'success') {
        icon = 'check-circle'
    } else if (type === 'error') {
        icon = 'times-circle'
    } else if (type === 'warning') {
        icon = 'exclamation-circle'
    } else {
        icon = 'info-circle'
    }


    useEffect(() => {
        let t = null;
        if (t) {
            clearTimeout(t)
        } else {
            t = setTimeout(() => {
                if (props.hide) {
                    props.hide()
                }
            }, 5000);
        }
    }, [])

    return (
        <div className={`alert ${type}`}>
            <p><b><FontAwesomeIcon icon={icon} /> {type}.</b> {message}</p>
        </div>
    )
}


/*
<Alert option={{ type: 'success', message: 'Every component that is an immediate child of the' }} />
<Alert option={{ type: 'warning', message: 'Every component that is an immediate child of the' }} />
<Alert option={{ type: 'info', message: 'Every component that is an immediate child of the' }} />
<Alert option={{ type: 'error', message: 'Please recheck your credential' }} />
*/