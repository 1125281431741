import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'
export const UNIT_SET = 'UNIT_SET'
export const UNIT_ERROR = 'UNIT_ERROR'
export const UNIT_ADD = 'UNIT_ADD'
export const UNIT_SHOW = 'UNIT_SHOW'
export const UNIT_EDIT = 'UNIT_EDIT'
export const UNIT_UPDATE = 'UNIT_UPDATE'
export const UNIT_DELETE = 'UNIT_DELETE'
export const UNIT_LOADING = 'UNIT_LOADING'
export const UNIT_RESET = 'UNIT_RESET'
const type = "UNIT"
const isOnline = require('is-online');
const offlineUrl = process.env.PW_OFFLINE_URL
export const loadUnit = () => async (dispatch) => {
    const online = await isOnline()
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${online ? hostUrl : offlineUrl}/api/unit`),
        next: (payload) => dispatch({ type: UNIT_SET, payload })
    })
}
export const addUnit = data => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/unit`, data),
        next: (payload) => dispatch({ type: UNIT_ADD, payload })
    })
}
export const updateUnit = (idEdit, data) => async (dispatch) => {

    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/unit/${idEdit}`, data),
        next: (payload) => dispatch({ type: UNIT_UPDATE, payload })
    })
}
export const deleteUnit = id => async (dispatch) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/unit`, id),
        next: () => dispatch({ type: UNIT_DELETE, payload: id })
    })
}
export const showUnit = idx => {
    return {
        type: UNIT_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: UNIT_EDIT,
        payload: idEdit,
    }
}
export const resetStatusUnit = () => {
    return {
        type: UNIT_RESET
    }
}