import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import TextHelper from 'Elements/TextHelper'
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import { money } from '@/Helper'
import SimpleModal from 'Elements/modal/Modal'
import Button from 'Elements/Button'
import InputNumber from 'Elements/inputnumber/InputNumber';
import Alert from 'Comps/elements/Alert'
import { showProductByid } from 'Reduxes/inventory/product/productActions'
import { useDispatch } from 'react-redux'

function Detail(props) {

    const { showTable, rowId } = props;
    const [data, setData] = useState({})
    const dispatch = useDispatch()
    const [dataPrintBarcode, setDataPrintBarcode] = useState([])

    

    const detailHeader = {
        name: data.name,
        type: data.product_type,
        category: data.category_name,
        brand: data.brand_name,
        color: data.color_name,
        UoM: data.uom_name,
        status: data.status ? 'active' : 'inactive',
        'Sales price': `IDR ${money(data.sales_price)}`,
        hpp: `IDR ${money(data.hpp)}`,
        'Material price': `IDR ${money(data.material_price)}`,
        'commission': data.comission + ` %`,
        ...(data.product_variant_item?.length === 0 && {
            'stock on hand': data.stock_on_hand,
            'reject': data.reject
        })
    }

    // SKEMA PRINT BARCODE
    const [printErr, setPrintErr] = useState(false)
    const [printSuc, setPrintSuc] = useState(false)

    const [modal, setModal] = useState(false)
    const showModal = e => {
        setModal(state => !state)
    }

    const printBarcode = () => {

        // PRINT BARCODE
        const optionsBarcode = {
            printerName: "PW_BARCODE_PRINTER",
        };

        try {
            printBarcode(dataPrintBarcode, optionsBarcode);
            // jika berhasil print
            setPrintSuc(true) // menampilkan notif success
            setTimeout(function () {
                setPrintSuc(false) // menghilangkan notif success
            }, 3000)
            console.log(dataPrintBarcode)
        } catch (err) {
            // jika gagal print
            setPrintErr(true) // menampilkan notif error
            setTimeout(function () {
                setPrintErr(false) // menghilangkan notif error
            }, 3000)
        }
        // END print Barcode

    }

    // Generat inital value barcode
    useEffect(() => {

        /** Fetch data product by id */
        const fetchProductById = async () => {
            const resp = await dispatch(showProductByid(rowId))
            setData(resp.data)
        }

        fetchProductById()

        let countData = []
        if (data.product_variant_item?.length) {
            data.product_variant_item.map(d => {
                countData.push({
                    barcode: d.barcode,
                    product_name: data.product_name,
                    brand: data.brand_name,
                    price: data.sales_price,
                    color: data.color_name,
                    variant: d.variant_type_value,
                    copies: 0
                })
            })
            setDataPrintBarcode(countData)
        } else {
            countData.push({
                barcode: data.code,
                product_name: data.product_name,
                brand: data.brand_name,
                price: data.sales_price,
                color: data.color_name,
                variant: null,
                copies: 0
            })
            setDataPrintBarcode(countData)
        }
    }, [])

    // update copies barcode
    const setCopiesPrint = (val, data) => {
        const { barcode } = data;
        setDataPrintBarcode(state => {
            return state.map(s => {
                if (s.barcode === barcode) {
                    s.copies = +val;
                }
                return s;
            });
        })
    }
    
    return (
        <ContentWrapper
            title="Products Detail"
            subtitle={`Showing product with id ${data.code}`}
            buttons={[
                {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => showTable(),
                },
                {
                    label: "Print Barcode",
                    style: "DarkblueMd",
                    marginLeft: "ml-10",
                    icon: "print",
                    onClick: () => showModal(),
                    type: 'print',
                    subMenu: 'product',
                }
            ]}
        >

            <SimpleModal
                customClass="modal"
                isOpen={modal}
                setIsOpen={setModal}
                width={900}
                title="Print Barcode"
            >

                {
                    printSuc &&
                    <div className="print-notif mt-30">
                        <Alert
                            option={{ type: 'success', message: "Printed Barcode Successfully" }}
                        />
                    </div>
                }
                {
                    printErr &&
                    <div className="print-notif mt-30">
                        <Alert
                            option={{ type: 'error', message: "Barcode Failed to Print" }}
                        />
                    </div>

                }

                <div className="scroll">
                    <table className="table table-detail style-2 mb-30">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Barcode</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Color</th>
                                <th>Copies</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataPrintBarcode.map((data, idx) => (
                                    <tr key={idx}>
                                        <td>{idx + 1}</td>
                                        <td>{data.barcode}</td>
                                        <td>{data.product_name}</td>
                                        <td>{data.variant ? data.variant : "-"}</td>
                                        <td>{data.color}</td>
                                        <td>
                                            <InputNumber
                                                withFieldContainer
                                                value={data.copies}
                                                handleChange={(val) => setCopiesPrint(val, data)}
                                            />
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>




                    <Button
                        type="button"
                        style="OrangeMdRect"
                        label="Print"
                        icon="print"
                        onClick={() => printBarcode()}
                    />

                </div>



            </SimpleModal>



            <DetailHeader data={detailHeader} />

            {
                data.product_variant_item?.length > 0 ?
                    <table className="table table-detail style-2">
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Varian</th>
                                <th>Value</th>
                                <th>ID</th>
                                <th>Stock on Hand</th>
                                <th>Reject</th>
                                <th>UoM</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.product_variant_item.map((x, y) => (
                                    <tr key={y}>
                                        <td>{y + 1}</td>
                                        <td>{x.variant_name}</td>
                                        <td>{x.variant_type_value}</td>
                                        <td>{x.barcode}</td>
                                        <td>{x.stock_on_hand}</td>
                                        <td>{x.reject}</td>
                                        <td>{data.uom_name}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    : <TextHelper message="*This product has no variant" />
            }
            <br />

        </ContentWrapper>
    )
}

export default Detail
