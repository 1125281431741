import * as Yup from 'yup'

export const validationSchema = Yup.object().shape({
    code: 
        Yup.string()
            .required('Code is required'),
    name:
        Yup.string()
            .required('Category name is required'),
     
})