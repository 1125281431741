import {
    SALES_LOADING,
    SALES_STOP_LOADING,
    SALES_SET,
    SALES_ERROR,
    SALES_ADD,
    SALES_SHOW,
    SALES_DELETE,
    SALES_UPDATE,
    SALES_RESET,
    SALES_EDIT,
    SALES_CONFIRM,
    SALES_FILTER_INDEX
} from './salesActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const salesReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case SALES_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SALES_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case SALES_UPDATE:
            // ketika pindah ke done idEdit tidak dipasang seperti seharusnya 
            // jadi diakali ngambil dari pyload ny 
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case SALES_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case SALES_FILTER_INDEX:
            console.log('INI PAYLOAD', payload)
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case SALES_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New sales has been added successfully.'
            }
        case SALES_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case SALES_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case SALES_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case SALES_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case SALES_DELETE:
            console.log('INI STATE', state)
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case SALES_CONFIRM:
            console.log('111111111111', payload, type);

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData

            }
        default:
            return state;
    }
}

export default salesReducer