import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormikContext } from 'formik';

export default function TableFormProduct(props) {
    const { rows, stockLocation, handleRemoveTableRow } = props;
    const { setFieldValue, setValues, values } = useFormikContext();
    const [stateRow, setStateRow] = useState([])

    const handleRemoveRow = idxRemove => {
        setStateRow(
            stateRow.filter((_, idx) => idx !== idxRemove)
        )
        const stock_opname_item = values.stock_opname_item.filter((_, idx) => idx !== idxRemove)
        setValues({
            ...values,
            stock_opname_item
        })
    }

    const handleQtyChange = (event, idx) => {
        if (values.stock_opname_item) {
            let val = parseInt(event.target.value);

            const stock_opname_item = values.stock_opname_item.map((soi, soiIdx) => {
                if (idx === soiIdx) {
                    soi.product_qty_physic = val;
                }
                return soi;
            })

            setFieldValue('stock_opname_item', stock_opname_item)
        }
    }

    useEffect(() => {
        let tempRows = [];
        const stock_opname_item = [];
        const extractValue = (row, pvi = {}) => {
            let { name, unit_of_measurement_id: uom_id, product_qty, code, brand_id, category_id, category_name, brand_name, product_name, id: product_id, uom_name } = row;
            const { barcode, variant_name, product_qty: product_qty_pvi } = pvi;
            const emptyPvi = !Object.keys(pvi).length;
            code = emptyPvi ? code : barcode
            product_qty = emptyPvi ? product_qty : (product_qty_pvi || 1)

            stock_opname_item.push({
                code,
                product_id,
                product_name,
                category_id,
                category_name,
                brand_id,
                brand_name,
                uom_id,
                uom_name,
                product_qty,
                product_qty_physic: 1,
            })

            tempRows.push({
                location: stockLocation,
                code,
                category: category_name,
                brand: brand_name,
                product: product_name || name,
                variant: emptyPvi ? '-' : variant_name,
                uom: uom_name,
            })
        }
        rows.map((row, rowIdx) => {

            const arrPvi = row.product_variant_item.variant_id ? [row.product_variant_item] : row.product_variant_item
            if (arrPvi.length) {
                arrPvi.map(pvi => extractValue(row, pvi))
            } else {
                extractValue(row, {})
            }
        })

        setFieldValue('stock_opname_item', stock_opname_item)
        setStateRow(tempRows);
    }, [rows])

    return (
        <table className="table-form-2 no-padding">
            <thead>
                <tr>
                    <th>Stock Location</th>
                    <th>Code</th>
                    <th>Category</th>
                    <th>Brand</th>
                    <th>Product Name</th>
                    <th>Variant</th>
                    <th>Qty</th>
                    <th>UoM</th>
                    <th className="action">Delete</th>
                </tr>
            </thead>
            <tbody>
                {
                    stateRow.map((sr, srIdx) => (
                        <tr key={srIdx}>
                            <td>{sr.location}</td>
                            <td>{sr.code}</td>
                            <td>{sr.category}</td>
                            <td>{sr.brand}</td>
                            <td>{sr.product}</td>
                            <td>{sr.variant}</td>
                            <td>
                                <input
                                    type="text"
                                    className="input-on-table-row"
                                    onChange={(e) => handleQtyChange(e, srIdx)}
                                />
                            </td>
                            <td>{sr.uom}</td>
                            <td className="action">
                                <FontAwesomeIcon
                                    icon="trash"
                                    onClick={() => handleRemoveRow(srIdx)}
                                />
                            </td>

                        </tr>
                    ))
                }
            </tbody>
        </table>
    )
}
