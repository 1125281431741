import React, { useEffect, useState } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { updateStatus, showInvoice } from 'Reduxes/consignment/invoice/invoiceActions'
import { useDispatch } from 'react-redux';
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import { money, datePost, globalMessage } from '@/Helper'
import LoadingContent from 'Elements/LoadingContent'
import Box from "@material-ui/core/Box";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import KopSurat from 'Elements/KopSurat'

function Detail(props) {
    const { showTable, rowId, showDetail } = props;
    const dispatch = useDispatch();
    const [data, setData] = useState(null)
    const [statusLoading, setStatusLoading] = useState(false)
    const handleStatus = async (type) => {
        setStatusLoading(true);
        await dispatch(updateStatus(data.id, type))
        setData({
            ...data,
            status: type
        })
        setStatusLoading(false);
        showTable()
        showDetail(rowId)

    }
    const handleApprove = () => {
        handleStatus('confirmed')
    }
    const handleCancel = () => {
        handleStatus('draft')
    }

    const makeStatusButton = (type, permissionType = null) => {
        return {
            label: type[0].toUpperCase() + type.substring(1),
            style: 'TileMd',
            marginLeft: "ml-10",
            onClick: async () => {
                if (type === 'done') {
                    // const req = await dispatch(updateAcquisition(data.id, cleanVal))
                } else if (type === 'confirmed') {
                    handleApprove()
                } else if (type === 'cancel') {
                    handleCancel()
                } else {
                    globalMessage('no action setted !', 'warning')
                }
            },
            subMenu: 'invoice',
            type: permissionType,
        }
    }

    const detailHeader = !data ? {} : {
        address: data.contact_address,
        reference: data.reference,
        contact: data.contact_name,
        status: data.status,
        date: data.date,
        'due date': data.due_date,
        'sales period': `${data.start_date || '-'} - ${data.end_date || '-'}`,
    }

    useEffect(() => {
        const fetchRow = async () => {
            const resp = await dispatch(showInvoice(rowId))
            setData(resp.data)
        }
        fetchRow();
    }, [])

    return (
        <>
            {
                !data ?
                    <LoadingContent /> :
                    <PrintProvider>
                        <Print single name="print-delivery-detail-1" >
                            <div className="detail-page print-report zoom-70">
                                <KopSurat title="Invoice" nomor={`${data.code || 'N/a'}`} />
                                <ContentWrapper
                                    title="Invoice Detail"
                                    subtitle={`Showing invoice with id ${data.code || 'N/a'}`}
                                    buttons={[
                                        {
                                            label: 'Go back',
                                            style: 'WhiteMd',
                                            icon: 'arrow-left',
                                            onClick: () => showTable(),
                                        },
                                        // draft - open - checked (NO mundur)
                                        (data.status === 'draft' ? makeStatusButton('confirmed', 'create') : {}),
                                        ((data.status === 'confirmed' && !data?.has_payment) ? makeStatusButton('cancel', 'confirm') : {}),
                                        {
                                            label: "Print",
                                            style: "DarkblueMd",
                                            marginLeft: "ml-10",
                                            icon: "print",
                                            onClick: () => window.print(),
                                            subMenu: 'invoice',
                                            type: 'print',
                                        },
                                    ]}
                                >

                                    <DetailHeader data={detailHeader} />
                                    {
                                        data.invoice_items?.length > 0 && (<table className="table table-detail style-2 print-invoice">
                                            <thead>
                                                <tr>
                                                    <th>No</th>
                                                    <th>Code Product</th>
                                                    <th>Product</th>
                                                    <th>Price / unit</th>
                                                    <th>Discount</th>
                                                    <th>Qty</th>
                                                    <th>Remain Qty</th>
                                                    <th>UoM</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.invoice_items.map((row, rowIndex) => (
                                                        <>
                                                            <tr key={row.sku}>
                                                                {console.log(row)}
                                                                <td>{rowIndex + 1}</td>
                                                                <td>{row.sku}</td>
                                                                <td className="highlight">{`${row.product_name} ${row.color_name ?? ''}  ${row?.variant_type_value ? `- (${row.variant_type_value})` : ''}`}</td>
                                                                <td>IDR {money(row.price)}</td>
                                                                <td>IDR {money(row.discount)}</td>
                                                                <td>{row.invoice_qty}</td>
                                                                <td>{row.rest_qty}</td>
                                                                <td>{row.uom_name}</td>
                                                                <td>IDR {money(row.total)}</td>
                                                            </tr>
                                                        </>
                                                    ))
                                                }
                                                <tr className="total">
                                                    <td colSpan="7" className="note" rowSpan="5">
                                                        <b>Notes : </b>
                                                        <p>{data.note}</p>
                                                    </td>
                                                    <td>Sub Total</td>
                                                    <td>IDR {money(data.sub_total)}</td>
                                                </tr>
                                                <tr className="total">
                                                    <td>Discount</td>
                                                    <td>IDR {money(data.total_discount)}</td>
                                                </tr>
                                                <tr className="total">
                                                    <td>Total</td>
                                                    <td>IDR {money(data.total)}</td>
                                                </tr>
                                                <tr className="total">
                                                    <td>Total Payment</td>
                                                    <td>IDR {money(data.paid)}</td>
                                                </tr>
                                                <tr className="total">
                                                    <td>Difference</td>
                                                    <td>IDR {money(data.total - data.paid)}</td>
                                                </tr>
                                            </tbody>
                                        </table>)
                                    }
                                    <NoPrint>
                                        <Box>
                                            <p className="detail-note">Printed by: User 1, {data.print_count}x</p>
                                            <p className="detail-note">Created by: {data.created_by_contact_name}, {datePost(data.created_at)}</p>
                                            <p className="detail-note">Last edited by: {data.updated_by_contact_name || '-'}, {datePost(data.updated_at)}</p>
                                        </Box>
                                    </NoPrint>
                                    <SignatureColumn typeContent={"ReceivedDelivered"} data={data} />
                                </ContentWrapper>
                            </div>
                        </Print>
                    </PrintProvider>
                // </div>
            }
        </>
    )
}

export default Detail
