import React from 'react'
import { money, dateDMY } from '@/Helper'
import NoReport from 'Elements/NoReport'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { sortReportDate } from 'Configs/report'

export default function DetailWeekMonth({ data, group_by }) {

    const { data: fake } = {
        "status": "success",
        "data": [
            {
                "id": 1,
                "branch_code": "PW-01",
                "branch_name": "Main Office",
                "type": "Main",
                "stock": 8,
                "reject": 0,
                "total_value": 160000,
                "products": [
                    {
                        "id": 1,
                        "code": "BR20B-TS001CO",
                        "name": "Baju Pramuka",
                        "brand": "EIGER",
                        "stock": 8,
                        "reject": 0,
                        "uom_name": "Piece",
                        "sales_price": 20000,
                        "total_value": 160000,
                        "variants": [
                            {
                                "date": "2021-01",
                                "detail_date": "2021-01-04 14:00:15",
                                "stock": 10,
                                "reject": 0,
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "reject": 0,
                                        "stock": 10,
                                        "types": [
                                            {
                                                "label": "L",
                                                "in": 0,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 0
                                            },
                                            {
                                                "label": "M",
                                                "in": 0,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 0
                                            },
                                            {
                                                "label": "S",
                                                "in": 10,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 10
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "reject": 0,
                                        "stock": 0,
                                        "types": [
                                            {
                                                "label": "40",
                                                "in": 0,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 0
                                            }
                                        ]
                                    }
                                ]
                            },
                            {
                                "date": "2020-12",
                                "detail_date": "2020-12-17 09:38:00",
                                "stock": -2,
                                "reject": 0,
                                "variants": [
                                    {
                                        "id": 1,
                                        "name": "T-shirt",
                                        "reject": 0,
                                        "stock": 0,
                                        "types": [
                                            {
                                                "label": "L",
                                                "in": 0,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 0
                                            },
                                            {
                                                "label": "M",
                                                "in": 0,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 0
                                            },
                                            {
                                                "label": "S",
                                                "in": 0,
                                                "out": 2,
                                                "reject": 0,
                                                "stock": -2
                                            }
                                        ]
                                    },
                                    {
                                        "id": 2,
                                        "name": "shoes",
                                        "reject": 0,
                                        "stock": 0,
                                        "types": [
                                            {
                                                "label": "40",
                                                "in": 0,
                                                "out": 0,
                                                "reject": 0,
                                                "stock": 0
                                            }
                                        ]
                                    }
                                ]
                            }
                        ]
                    }
                ],
                "variants": [
                    {
                        "date": "2021-01",
                        "detail_date": "2021-01-04 14:00:15",
                        "stock": 10,
                        "reject": 0,
                        "variants": [
                            {
                                "id": 1,
                                "name": "T-shirt",
                                "stock": 10,
                                "reject": 0,
                                "types": [
                                    {
                                        "label": "L",
                                        "stock": 0,
                                        "reject": 0
                                    },
                                    {
                                        "label": "M",
                                        "stock": 0,
                                        "reject": 0
                                    },
                                    {
                                        "label": "S",
                                        "stock": 10,
                                        "reject": 0
                                    }
                                ]
                            },
                            {
                                "id": 2,
                                "name": "shoes",
                                "stock": 0,
                                "reject": 0,
                                "types": [
                                    {
                                        "label": "40",
                                        "stock": 0,
                                        "reject": 0
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        "date": "2020-12",
                        "detail_date": "2020-12-17 09:38:00",
                        "stock": -2,
                        "reject": 0,
                        "variants": [
                            {
                                "id": 1,
                                "name": "T-shirt",
                                "stock": -2,
                                "reject": 0,
                                "types": [
                                    {
                                        "label": "L",
                                        "stock": 0,
                                        "reject": 0
                                    },
                                    {
                                        "label": "M",
                                        "stock": 0,
                                        "reject": 0
                                    },
                                    {
                                        "label": "S",
                                        "stock": -2,
                                        "reject": 0
                                    }
                                ]
                            },
                            {
                                "id": 2,
                                "name": "shoes",
                                "stock": 0,
                                "reject": 0,
                                "types": [
                                    {
                                        "label": "40",
                                        "stock": 0,
                                        "reject": 0
                                    }
                                ]
                            }
                        ]
                    }
                ]
            }
        ],
        "errors": []
    }


    const hasData = data.length;
    const detailsHeadLabel = ['in', 'out', 'reject', 'stock'];

    let variants = null;
    let dates = [];
    let longestVariant = [];

    if (hasData) {
        variants = data[0].products[0].variants[0].variants

        data.forEach(d => {
            d.products.forEach(product => {
                product.variants.forEach(variant => {
                    dates = [...new Set([...dates, variant.date])]
                    variant.variants.forEach(variant2 => {
                        longestVariant = variant2.types.length > longestVariant.length ? variant2.types : longestVariant
                    })
                })
            })
        })
    }

    dates = sortReportDate(dates)

    const [showDetail, setShowDetail] = React.useState(
        data.map(data => false)
    )

    const handleShowDetail = (a) => {
        setShowDetail(state => {
            state[a] = !state[a];
            return [...state]
        })
    }

    console.clear()
    console.log({ data, variants, dates, longestVariant })

    return !hasData ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '0px' }}>Inventory by Customer Report <span>Showing report from all branches</span></p>
            {
                data.map((d, dIdx) => {
                    return (
                        <>
                            <div className="report-summary-box">
                                <div className="item teal">
                                    <p>
                                        <span>Stock Value</span>
                                        <span>IDR {money(d.total_value)}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="scroll-x">
                                <table className="table-report inventory-stock">
                                    <thead>
                                        <tr>
                                            <th rowspan={variants.length + 1}>no.</th>
                                            <th rowspan={variants.length + 1}>code</th>
                                            <th rowspan={variants.length + 1}>type</th>
                                            <th rowspan={variants.length + 1} colSpan="2">branch name</th>
                                            {
                                                dates.map((dt, dtIdx) => (
                                                    <>
                                                        <th className="cell-tc" colSpan={longestVariant.length * detailsHeadLabel.length}>{group_by === 'week' ? 'Week' : ''} {dt}</th>
                                                        <th rowSpan={variants.length + 1}>Stock</th>
                                                        <th rowSpan={variants.length + 1}>reject</th>
                                                    </>
                                                ))
                                            }
                                            <th rowspan={variants.length + 1}>UoM</th>
                                            <th className="cell-tr" rowspan={variants.length + 1}>Total Value</th>
                                        </tr>
                                        {
                                            variants.map(variant => {
                                                return (
                                                    <tr>
                                                        {
                                                            dates.map((dt, dtIdx) => {
                                                                return (
                                                                    <>
                                                                        {
                                                                            longestVariant.map((lv, lvIdx) => {
                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            detailsHeadLabel.map((dhl, dhlIdx) => {
                                                                                                return dhlIdx < 1 && (
                                                                                                    <th className="cell-tc" colSpan="4">
                                                                                                        {dhlIdx < 1 && (variant?.types?.[lvIdx]?.label)}
                                                                                                    </th>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                    </thead>
                                    <tbody>
                                        {
                                            data.map((dt, dtIdx) => { // looping 1 ::::::::::::::::::: Data
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{dtIdx + 1}.</td>
                                                            <td className="cell-width-as-content cell-highlight">
                                                                <span className="trigger-show-detail" onClick={() => handleShowDetail(dIdx)}>
                                                                    {dt.branch_code} <KeyboardArrowDownIcon className="info-icon" />
                                                                </span>
                                                            </td>
                                                            <td>{dt.type}</td>
                                                            <td colSpan="2" className="cell-width-as-content">{dt.branch_name}</td>
                                                            {
                                                                dates.map((dtx, dtIdxx) => {
                                                                    return longestVariant.map((lv, lvIdx) => {
                                                                        {/* ===================================<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<=========================================== */ }

                                                                        let qty = 0;
                                                                        let isFound = dt.variants.find(x => x.date === dtx);
                                                                        if (isFound) {
                                                                            isFound.variants.forEach(variant => {
                                                                                qty += variant?.types?.[lvIdx]?.stock ?? 0
                                                                            })
                                                                        }


                                                                        return (
                                                                            <>
                                                                                {
                                                                                    detailsHeadLabel.map((dhl, dhlIdx) => (
                                                                                        <td className="cell-tc cell-min-width-30">
                                                                                            { dhlIdx < 1 && qty}
                                                                                        </td>
                                                                                    ))
                                                                                }
                                                                                {
                                                                                    longestVariant.length - 1 === lvIdx && (
                                                                                        <>
                                                                                            <td className="cell-tc">{dt.stock}</td>
                                                                                            <td className="cell-tc">{dt.reject}</td>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                })
                                                            }
                                                            <td></td>
                                                            <td className="cell-tr cell-currency">IDR {money(dt.total_value)}</td>
                                                        </tr>
                                                        {
                                                            dt.products.map((product, productIdx) => { // looping 2 ::::::::::::::::::: Products
                                                                return (
                                                                    <>
                                                                        {
                                                                            productIdx < 1 && (
                                                                                <tr className={`${showDetail[dIdx] ? '' : 'hide'} tr-detail detail-heading`}>
                                                                                    <td>No.</td>
                                                                                    <td>code</td>
                                                                                    <td>brand</td>
                                                                                    <td className="cell-width-as-content">product name</td>
                                                                                    <td className="cell-tr">sales price</td>
                                                                                    {
                                                                                        dates.map((dt, dtIdx) => {
                                                                                            return longestVariant.map((lv, lvIdx) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            detailsHeadLabel.map((dhl, dhlIdx) => {
                                                                                                                return <td>{dhl}</td>
                                                                                                            })
                                                                                                        }
                                                                                                        {
                                                                                                            longestVariant.length - 1 === lvIdx && (
                                                                                                                <>
                                                                                                                    <td colSpan="2"></td>
                                                                                                                </>
                                                                                                            )
                                                                                                        }
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                        })
                                                                                    }
                                                                                    <td></td>
                                                                                    <td></td>
                                                                                </tr>
                                                                            )
                                                                        }
                                                                        <tr className={`${showDetail[dIdx] ? '' : 'hide'} tr-detail detail-body`}>
                                                                            <td>{dtIdx + 1}.{productIdx + 1}.</td>
                                                                            <td className="cell-width-as-content">{product.code}</td>
                                                                            <td className="cell-width-as-content">{product.brand}</td>
                                                                            <td className="cell-width-as-content">{product.name}</td>
                                                                            <td className="cell-tr cell-currency">IDR {money(product.sales_price)}</td>
                                                                            {
                                                                                dates.map((dte, dteIdx) => { // looping 3 ::::::::::::::::::: dates
                                                                                    return longestVariant.map((lv, lvIdx) => { // looping 4 ::::::::::::::::::: LongestVariant 
                                                                                        return (
                                                                                            <>
                                                                                                {
                                                                                                    detailsHeadLabel.map((dhl, dhlIdx) => {

                                                                                                        let qty = 0;

                                                                                                        let findDate = product.variants.find(x => x.date === dte);
                                                                                                        if (findDate) {
                                                                                                            findDate.variants.forEach(variant => {
                                                                                                                qty += variant?.types?.[lvIdx]?.[dhl] ?? 0;
                                                                                                            })
                                                                                                        }

                                                                                                        return <td>{qty} </td>
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    longestVariant.length - 1 === lvIdx && (
                                                                                                        <>
                                                                                                            <td className="cell-tc">{product.stock}</td>
                                                                                                            <td className="cell-tc">{product.reject}</td>
                                                                                                        </>
                                                                                                    )
                                                                                                }
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                })
                                                                            }
                                                                            <td>{product.uom_name}</td>
                                                                            <td className="cell-tr cell-currency">IDR {money(product.total_value)}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )

                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )
                })
            }





        </>
    )
}
