import { createMuiTheme } from '@material-ui/core/styles';

const bgTh = '#e4efff';
const colorDark = '#21495e';

const muiTheme = createMuiTheme({
    pw: {
        green: '#38c172',
        teal: '#4dc0b5',
        blue: '#3367d6',
        c3: '#333',
        c7: '#777',
        c5: '#555',
        form: {
            label: {
                display: 'block',
                fontWeight: 600,
                textTransform: 'capitalize',
                color: '#777',
                marginBottom: '6px',
                fontSize: '13px'
            },
            input: {
                border: '1px solid #ddd',
                height: '44px',
                fontWeight: 400,
                fontSize: '13px',
                color: '#555',
                display: 'block',
                padding: '8px 12px',
                boxSizing: 'border-box',
                borderRadius: '3px',
                width: '100%',
                errMsg: {
                    color: '#e3342f',
                    fontSize: '12px',
                    fontWeight: 400,
                    marginTop: '4px',
                },
                container: {
                    marginBottom: '24px'
                }
            },
            text_helper: {
                fontSize: '12px',
                color: '#555',
                fontWeight: 400,
                marginTop: '4px',
                '&.error': {
                    color: '#e3342f'
                }
            },
            fieldContainer: {
                marginBottom: '24px',
                '& .dropdown-heading-value span': {
                    fontSize: '13px',
                    color: '#555',
                },
                '& .dropdown-container': {
                    // background: 'red'
                    border: '1px solid #ddd',
                    borderRadius: '3px',
                    height: '44px'
                },
                '& .dropdown-heading': {
                    padding: '0 12px'
                },
                '& .dropdown-content *': {
                    color: 'green',
                    fontFamily: 'Open sans',
                    fontSize: '13px',
                    color: '#555'
                }
            },
            fieldContainerDatePicker: {
                marginBottom: '24px',

                '& .MuiFormControl-root': {
                    width: '100%',
                    '& .MuiButtonBase-root': {
                        border: '1px solid #ddd',
                        borderLeft: 0,
                        background: 'white',
                    },
                    '& .MuiInputAdornment-positionEnd': {
                        marginLeft: '-2px',
                    },
                    '& .MuiInputBase-input': {
                        border: '1px solid #ddd',
                        borderRight: 0,
                        height: '44px',
                        boxSizing: 'border-box',
                        borderRadius: '3px',
                        background: 'white',
                        fontSize: '13px',
                        color: '#555',
                        padding: ' 0 12px',
                    },
                    '& .MuiInput-underline:before': {
                        border: 0,
                    },
                    '& .MuiInput-underline:after': {
                        border: 0,
                    },

                },
                MuiFormControl: {
                    root: {
                        background: 'brown'
                    }
                },
                '& .MuiInput': {
                    background: 'red'
                },
                '& .MuiInput-underline': {
                    '& :before, & :after': {
                        border: '0 !important'
                    }
                },
            }
        }
    },
    typography: {
        fontFamily: [
            'Open Sans',
            'sans-serif',
        ].join(',')
    },
    palette: {
        primary: {
            main: '#f78300'
        }
    },
    overrides: {
        MuiPickersDatePickerRoot: {
            toolbar: {
                background: '#eee'
            }
        },
        MUIDataTableToolbarSelect: {
            root: {
                zIndex: '98',
            }
        },
        MuiButtonGroup: {
            contained: {
                boxShadow: 'none'
            }
        },
        MUIDataTableFilterList: {
            root: {
                paddingBottom: '16px'
            }
        },
        MuiTab: {
            root: {
                textTransform: 'capitalize',
                fontSize: '13px',
                fontWeight: '600',
                minHeight: '56px',
                color: '#777',
                '@media (min-width: 600px)': {
                    minWidth: '120px'
                }

            }
        },
        PrivateTabIndicator: {
            root: {
                height: '1px'
            },
            colorSecondary: {
                backgroundColor: '#ddd',
                borderRadius: '3px'
            }
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#fafafa',
                // border:'1px solid #eee'
            }
        },
        MUIDataTableSearch: {
            searchText: {
                // border: '1px solid #ddd',
                borderRadius: '3px',
                paddingLeft: '8px',
                fontSize: '13px',
                paddingTop: '5px'
            }
        },
        MuiInputBase: {
            input: {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderRight: 0,
            }
        },
        MuiIconButton: {
            root: {
                borderRadius: 0,
                padding: '9px'
            }
        },
        MuiPickersDay: {
            daySelected: {
                color: 'white'
            }
        },
        MuiInput: {
            underline: {
                "&:before": {
                    // borderBottom: 0,
                },
                "&:after": {
                    // borderBottom: 0,
                },
                "&:hover": {
                    "&:not(.Mui-disabled)": {
                        "&:before": {
                            // borderBottom: 0
                        }
                    }
                }
            }
        },
        MuiFormControl: {
            marginNormal: {
                marginTop: 0,
                marginBottom: 0,
            }
        },
        MUIDataTable: {
            tableRoot: {
            },
            paper: {
                marginBottom: '40px'
            }
        },
        MuiPaper: {
            elevation4: {
                boxShadow: 'none'
            }
        },
        MUIDataTableSelectCell: {
            fixedLeft: {
                zIndex: 50,
                background: 'white'
            },
            headerCell: {
                zIndex: '60',
                backgroundColor: colorDark,
                '& .MuiCheckbox': {
                    root: {
                        color: 'white'
                    }
                },
                MuiSvgIcon: {
                    root: {
                        color: 'white'
                    }
                },
                '& .MuiSvgIcon': {
                    root: {
                        color: 'white'
                    }
                }
            }
        },
        MuiTableFooter: {
            root: {
                // marginBottom: '40px'
            }
        },
        MuiTable: {
            root: {
                borderRadius: '3px',
                overflow: 'hidden',
            }
        },

        MuiTableCell: {
            // tbody - row
            head: {
                fontWeight: '700',
            },
            footer: {
                border: 0,
            },
            root: {
                fontSize: '13px',
            }
        },
        MUIDataTableHeadCell: {
            data: {
                // fontSize: '30px'
            },
            fixedHeader: {
                backgroundColor: colorDark,
                zIndex: '50',
                color: 'white',
                textTransform: "uppercase",
                fontSize: '12px',
                padding: '8px'
            },
            sortLabelRoot: {
                marginBottom: '-14px',
                MuiSvgIcon: {
                    marginBottom: '-14px'
                },
                '& .MuiSvgIcon': {
                    color: 'white',
                    marginBottom: '-14px'

                }
            }
        },
        MUIDataTableBodyCell: {
            root: {
                whiteSpace: 'nowrap',
                padding: '4px',
                fontSize: '12px',
                verticalAlign: 'middle',
                lineHeight: '22px',
                color: '#555'

            },
        }
    }
})

export default muiTheme
