import React, { useEffect, useState } from 'react'
import { resetStatusSalesOrder, addSalesOrder, updateSalesOrder } from 'Reduxes/consignment/salesorder/salesOrderActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop, globalMessage } from '@/Helper'
import SelectAsync from 'Elements/SelectAsync';
import Input from 'Elements/Input';
import { validationSchema } from './validation'
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import SelectData from 'Elements/SelectData'
import FormButton from 'Elements/FormButton'
import { serverValue } from 'Configs/Form'
import DeleteIcon from '@material-ui/icons/Delete'
import Button from 'Elements/Button';
import Grid from '@material-ui/core/Grid';
import TableProduct from 'Elements/tableproduct/TableProduct'


function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const { type, showTable } = props;
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(true);
    const { error, idEdit, data } = useSelector(state => state.salesOrderReducer);
    const { data: contacts } = useSelector(state => state.contactReducer);
    const [address, setAddress] = useState('');
    const [contact, setContact] = useState(null)
    const [selectedSku, setSelectedSku] = useState({
        product_name: '',
        sales_price: '',
        uom_name: '',
        discount: 0,
    });
    const [initialValues, setInitialValues] = useState({
        "contact_id": '',
        "date": new Date().toISOString().split('T')[0],
        "shipment_date": new Date().toISOString().split('T')[0],
        "note": '',
        "sales_order_products": [],
    })
    const [inputList, setInputList] = useState([
        {
            code: '',
            product: '',
            price: '',
            unit: '',
            discount: 0,
            quantity: '',
            total: ''
        }
    ])
    const [salesOrderItem, setSalesOrderItem] = useState([])

    const [columns, setColumns] = useState([
        { label: 'Code', name: 'code', className: 'td-1', param: { flat: true, consignment: 1 } },
        { label: 'Product Name', name: 'name', className: 'td-1', param: { flat: true, consignment: 1 } },
        { label: 'Price /Unit', name: 'sales_price', type: 'money', align: 'right' },
        { label: 'Disc (%)', name: 'disc', align: 'right' },
        { label: 'Qty', name: 'quantity', type: 'inputNumber', value: 0 },
        // { label: 'Qty', name: 'qty', type: 'inputNumber', value: 0 },
        { label: 'UoM', name: 'uom_id', type: 'selectData', value: 0 },
        { label: 'Total', name: 'total', align: 'right' },
    ]);

    console.log(salesOrderItem, 'heheuheueue')

    const handleInputChange = (e, index) => {
        const { name, value } = e.target
        const list = [...inputList]
        list[index][name] = value
        setInputList(list)
    }

    const handleRemoveInput = (index) => {
        const list = [...inputList]
        list.splice(index, 1)
        setInputList(list)
    }

    const handleAddInput = () => {
        setInputList([
            ...inputList,
            {
                code: '',
                product: '',
                price: '',
                unit: '',
                discount: 0,
                quantity: '',
                total: ''
            },
        ])
    }

    const skuSelectHandler = ({ row, rows, formikAttr }) => {
        setSelectedSku({ ...row, discount: 0 });
        formikAttr.form.setValues({
            ...formikAttr.form.values,
        })
    }

    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {

            const {
                date, note, contact_id, shipment_date
            } = values;

            const sales_order_items = salesOrderItem.map((sales_order, invIdx) => {

                if (!sales_order.id) {
                    return null
                }

                return {
                    "product_id": sales_order.id,
                    "uom_id": sales_order.uom_id,
                    "variant_type_id": sales_order.variant_type_id,
                    "price": sales_order.sales_price,
                    "discount": sales_order.comission ? parseInt((sales_order.sales_price * sales_order.comission) / 100) : sales_order.discount,
                    "quantity": +sales_order.quantity || +sales_order.qty
                }
            }).filter(Boolean)



            const cleanVal = {
                date,
                note,
                contact_id,
                shipment_date,
                sales_order_products: sales_order_items
            }
            // console.log(sales_order_items)
            // return;
            if (cleanVal.sales_order_products.length < 1) {
                globalMessage('You must enter at least 1 product', 'warning')
                return false
            }

            let result = type === 'new' ?
                await dispatch(addSalesOrder(cleanVal))
                : await dispatch(updateSalesOrder(idEdit, cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }

    // NANTI NAMA VARIABLE EDIT NYA DI CEK ULANG YA
    useEffect(() => {
        if (type === 'edit') {
            let findData = data.find(d => d.id === idEdit);
            const { code, contact, date, discount, status, sub_total, total, sales_order_products, shipment_date, note } = findData;
            const salesOrderProducts = sales_order_products?.map(singleProduct => {
                const { id, sku, discount, price, name, quantity, total_price, unit, product, variant } = singleProduct
                console.log(singleProduct)

                return {
                    id: product.id,
                    code: sku,
                    variant_type_id: variant?.id,
                    discount,
                    sales_price: price,
                    name: `${product.name} ${product.color.name} ${variant?.value ? `- (${variant.value})` : ''}`,
                    quantity,
                    total: total_price,
                    uom_id: unit.id,
                    uom_value: unit.value,
                    note
                }
            })

            setContact(contact);

            setInitialValues({
                code,
                contact_id: contact.id,
                date,
                discount,
                status,
                sub_total,
                total,
                shipment_date,
                sales_order_products: salesOrderProducts,
                note
            })
        }
    }, [])

    useEffect(() => {
        setAddress(contacts.length ? contacts[0].address : '')
    }, [contacts])

    const handleChangeContact = (data) => {
        setContact(null);

        setColumns(state => {
            state[0].param = { contact_id: data.value, flat: true, consignment: 1 }
            state[1].param = { contact_id: data.value, flat: true, consignment: 1 }
            return [...state]
        })
    }

    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Sales Order" : "Update data Sales Order"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data sales order`}
        >
            <>
                {
                    error && <Alert hide={() => dispatch(resetStatusSalesOrder())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {
                                    const { isSubmitting } = formik;

                                    return (
                                        <Form className="form-layout-1">
                                            <FieldsContainer title="Sales Order Information">
                                                <SelectAsync name="contact_id" {...(contact ? { value: { label: contact.name, value: contact.id } } : {})} showAddress options="contact" label="Contact" handleChange={handleChangeContact} />
                                                <Datepicker name="date" label="Date" />
                                                <Datepicker name="shipment_date" label="Shipment Date" />
                                            </FieldsContainer>
                                            <div className="scroll">
                                                <TableProduct
                                                    portal={true}
                                                    moduleType="sales_order"
                                                    requireContact={true}
                                                    isConsignment={true}
                                                    columns={columns}
                                                    handlechange={(val) => setSalesOrderItem(val)}
                                                    defaultData={initialValues?.sales_order_products}
                                                    dataErrStatus={setDataErr}
                                                />
                                            </div>
                                            <Textarea name="note" label="Notes" col="6" />

                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper>
    )
}

export default FormElement