import {
    SALESRETURN_LOADING,
    SALESRETURN_SET,
    SALESRETURN_ERROR,
    SALESRETURN_ADD,
    SALESRETURN_SHOW,
    SALESRETURN_DELETE,
    SALESRETURN_UPDATE,
    SALESRETURN_RESET,
    SALESRETURN_EDIT,
    SALESRETURN_CONFIRM,
    SALESRETURN_STOP_LOADING
} from './salesreturnActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const salesreturnReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case SALESRETURN_LOADING:
            return {
                ...state,
                loading: true,
            }
        case SALESRETURN_STOP_LOADING:
            return {
                ...state,
                loading: false
            }
        case SALESRETURN_UPDATE:
            // ketika pindah ke done idEdit tidak dipasang seperti seharusnya 
            // jadi diakali ngambil dari pyload ny 
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case SALESRETURN_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case SALESRETURN_ADD:
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New salesreturn has been added successfully.'
            }
        case SALESRETURN_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case SALESRETURN_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case SALESRETURN_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case SALESRETURN_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case SALESRETURN_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case SALESRETURN_CONFIRM:
            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type === 'cancel' ? 'draft' : 'confirmed';
                }
                return d
            })

            return {
                ...state,
                success: payload.resp,
                data: newData

            }
        default:
            return state;
    }
}

export default salesreturnReducer