import React from 'react'
import { money, showMonth } from '@/Helper'
import NoReport from 'Elements/NoReport'
import DeviceHubIcon from '@material-ui/icons/DeviceHub';

export default function TableDetail({ data: datas, type }) {

    return datas.length < 1 ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '40px' }}> Report Invoice Detail by Costumer <span>Showing Details of Invoice Report by Costumer</span></p>
            {
                datas.map(data => (
                    <>
                        <h5><DeviceHubIcon /> Partner Store  : {data.contact_name}</h5>
                        <div className="report-pos-detail-table-container">
                            <div className="report-summary-box">
                                <div className="item teal">
                                    <p>
                                        <span>Net Income</span>
                                        <span>IDR {money(data.net_income)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Sub Total</span>
                                        <span>IDR {money(data.inv_sub_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Discount</span>
                                        <span>IDR {money(data.inv_discount)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Total</span>
                                        <span>IDR {money(data.inv_total)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>HPP</span>
                                        <span>IDR {money(data.hpp)}</span>
                                    </p>
                                </div>
                                <div className="item">
                                    <p>
                                        <span>Other Cost</span>
                                        <span>IDR {money(data.additional_cost)}</span>
                                    </p>
                                </div>
                            </div>

                            <div className="report-summary-pill">
                                <p>
                                    <span>Sales Order:</span>
                                    <span>{data.so_counter}</span>
                                </p>
                                <p>
                                    <span>Invoice:</span>
                                    <span>{data.inv_counter}</span>
                                </p>
                                <p>
                                    <span>Paid:</span>
                                    <span>{data.inv_paid_counter}</span>
                                </p>
                            </div>

                            <table className="table-report">
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>Date</th>
                                        <th>{type === 'day' ? 'No Invoice' : '#Invoice'}</th>
                                        <th className="th-text-right">Subtotal</th>
                                        <th className="th-text-right">Discount</th>
                                        <th className="th-text-right">Total</th>
                                        <th className="th-text-right">HPP</th>
                                        <th className="th-text-right">Other Cost</th>
                                        <th className="th-text-right">Net Income</th>
                                        {type !== 'year' && <th>Status</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.invoices.map((report, index) => (
                                            <tr>
                                                <td>{index + 1}</td>
                                                <td>{type === 'month' ? showMonth(report.date) : report.date}</td>
                                                <td className="td-highlight">{type === 'day' ? report.code : report.inv_counter}</td>
                                                <td className="td-text-right">IDR {money(report.sub_total)}</td>
                                                <td className="td-text-right">IDR {money(report.total_discount)}</td>
                                                <td className="td-text-right">IDR {money(report.grand_total)}</td>
                                                <td className="td-text-right">IDR {money(report.hpp)}</td>
                                                <td className="td-text-right">IDR {money(report.additional_cost)}</td>
                                                <td className="td-text-right">IDR {money(report.net_income)}</td>
                                                {type !== 'year' && <td>{report?.status ?? '-'}</td>}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </>
                ))
            }
        </>
    )
}
