import React, { useState, useEffect } from 'react'
import { money, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import NoReport from 'Elements/NoReport'
import { Helmet } from "react-helmet"

const createTbodyRow = (rows, branches) => {
    return rows.map((row, rowIdx) => {
        return (
            <tr key={rowIdx}>
                <td className="td-index">{rowIdx + 1}. </td>
                <td className="td-200 td-highlight">{row.category_name}</td>
                {
                    branches.map((branch, branchIdx) => {
                        return (
                            <React.Fragment key={branchIdx}>
                                <td className={branchIdx % 2 === 0 ? `td-darker` : ''}>{row?.[branch.branch_name]?.qty ?? 0}</td>
                                <td className={`${branchIdx % 2 === 0 ? `td-darker` : ''} text-right td-currency`}>{money(row?.[branch.branch_name]?.value)}</td>
                            </React.Fragment>
                        )
                    })
                }
                <td className="text-right">{row.total_qty}</td>
                <td className="text-right td-currency">{money(row.grand_total)}</td>
            </tr>
        )
    })

}

export default function TableCategoryBranch({ data }) {

    const [rows, setRows] = useState([])
    const [branches, setBranches] = useState([])
    const [sort, setSort] = useState({})

    const createThBottom = (branches) => {
        return branches.map((branch, branchIdx) => {
            return (
                <React.Fragment key={branchIdx}>
                    <ThSort
                        sort={true}
                        label="qty"
                        handleClick={() => sortColumn(branch.branch_name, 'qty')}
                        className={`${branchIdx % 2 === 0 ? 'th-darker' : ''} text-capitalize`}
                    />
                    <ThSort
                        sort={true}
                        label="value"
                        handleClick={() => sortColumn(branch.branch_name, 'value')}
                        className={`${branchIdx % 2 === 0 ? 'th-darker' : ''} text-capitalize text-right`}
                    />
                </React.Fragment>
            )
        })
    }
    const sortColumn = (colName, sortType = null) => {
        setRows(prevState => {
            if (!sortType) {
                prevState = sortData({ type: sort[colName].sort_type, column: colName, data: prevState })
            } else {
                prevState = rows.sort((a, b) => {
                    return (a?.[colName]?.[sortType] > b?.[colName]?.[sortType]) && (sort[colName].sort_type === 'desc' || !sort[colName].sort_type) ? 1 : -1
                })
            }
            return [...prevState]
        })

        setSort(prev => {
            return { ...handleSortState({ data: prev, column: colName }) }
        })
    }

    useEffect(() => {

        let initBranches = []
        let initCategories = {};
        let initRows = [];
        let restBranchSortStart = {
            no: {
                rowspan: 2,
                label: 'No',
                has_sort: false,
            },
            category_name: {
                label: 'category',
                has_sort: true,
                rowspan: 2,
                sort_type: null,
            },
        }
        let restBranchSortEnd = {
            total_qty: {
                label: 'total qty',
                sort_type: null,
                className: 'cell-width-as-content',
                rowspan: 2,
                has_sort: true
            },
            grand_total: {
                label: 'total value',
                rowspan: 2,
                className: 'cell-width-as-content',
                sort_type: null,
                has_sort: true
            },
        }
        let restBranchSortBranch = {}

        data.categories.forEach(category => {

            category.branches.forEach(branch => {
                const isAdded = initBranches.find(x => x.branch_name === branch.branch_name)

                if (!isAdded) {
                    initBranches.push({ branch_name: branch.branch_name });
                }
            })

            initCategories[category.category_name] = {
                branches: initBranches,
                total_qty: category.total_qty,
                grand_total: category.grand_total
            }

            category.branches.forEach(branch => {
                initCategories[category.category_name].branches = initCategories[category.category_name].branches.map((x, y) => {
                    return x.branch_name === branch.branch_name ? branch : x
                })
            })

        })

        initRows = Object.keys(initCategories).map(category => {
            let finalResult = {
                category_name: category,
                grand_total: initCategories[category].grand_total,
                total_qty: initCategories[category].total_qty,
            }

            initCategories[category].branches.forEach(branch => {
                finalResult[branch.branch_name] = { qty: branch?.qty ?? 0, value: branch?.value ?? 0 }
            })

            return finalResult
        })

        initBranches.forEach(branch => {
            restBranchSortBranch[branch.branch_name] = {
                has_sort: false,
                className: 'cell-width-as-content',
                sort_type: null,
                colspan: 2,
                label: branch.branch_name
            }
        })

        setBranches(initBranches)
        setRows(initRows)
        setSort({ ...restBranchSortStart, ...restBranchSortBranch, ...restBranchSortEnd })

    }, [data])


    return (
        <div className="scroll-x">
            {
                data.categories.length < 1 ?
                    <NoReport /> :
                    <>

                        <Helmet>
                            <style type="text/css">
                                {`@page {size: landscape}`}
                            </style>
                        </Helmet>
                        <div className="print-report zoom-60">


                            <p className="asdasd print-mt-80">
                                POS Sales Report
                                <span>Showing POS report from all branches</span>
                            </p>

                            <table className="table-report category">

                                <thead>
                                    <tr>
                                        {
                                            Object.keys(sort).map((key, idx) => (
                                                <ThSort
                                                    key={`th-${idx}`}
                                                    label={sort[key].label}
                                                    rowspan={sort[key].rowspan}
                                                    colspan={sort[key].colspan}
                                                    sort={sort[key].has_sort}
                                                    handleClick={() => sortColumn(key)}
                                                    className={`text-center ${sort[key].className} ${idx % 2 === 0 ? 'th-darker' : ''} ${sort[key].sort_type}`}
                                                />
                                            ))
                                        }
                                    </tr>
                                    <tr>
                                        {createThBottom(branches)}
                                    </tr>
                                </thead>

                                <tbody>

                                    {createTbodyRow(rows, branches)}

                                    <tr className="highlight">
                                        <td colSpan="2"></td>
                                        {
                                            branches.map((branch, rowIdx) => (
                                                <React.Fragment key={rowIdx}>
                                                    <td className={`${rowIdx % 2 === 0 ? 'td-darker' : ''}`}>
                                                        {
                                                            money(rows.reduce((total, x) => {
                                                                return total + (x?.[branch.branch_name]?.qty ?? 0)
                                                            }, 0))
                                                        }
                                                    </td>
                                                    <td className={`${rowIdx % 2 === 0 ? 'td-darker' : ''} text-right`}>
                                                        {
                                                            money(rows.reduce((total, x) => {
                                                                return total + (x?.[branch.branch_name]?.value ?? 0)
                                                            }, 0))
                                                        }
                                                    </td>
                                                </React.Fragment>
                                            ))
                                        }
                                        <td colSpan="2"></td>
                                    </tr>
                                </tbody>
                            </table >

                        </div>
                    </>
            }
        </div >
    )
}
