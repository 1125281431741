import React, { useState, useEffect } from 'react'
import { globalMessage, globalDialog } from '@/Helper'
import InputNumber from 'Elements/inputnumber/InputNumber';
import ButtonIcon from 'Elements/ButtonIcon'
import SelectData from 'Elements/SelectData';
import Select from 'Elements/select/Select'
import { v4 as uuidv4 } from 'uuid';
import ScanProduct from 'Elements/ScanProduct'
import SimpleModal from 'Elements/modal/Modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDispatch } from 'react-redux'
import { filterProduct } from 'Reduxes/inventory/product/productActions'
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectAsync from 'Elements/SelectAsync';
import ReactSelect from 'react-select'


export default function ProductManager(props) {
    const variant_type = [
        { label: 'Stock', value: 'stock' },
        { label: 'Reject', value: 'reject' }
    ]
    const { getData: sendProducts, defaultData, dataErrStatus, branch } = props;
    const [products, setProducts] = useState([{ key: uuidv4() }])
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState([])
    const [modalLoading, setModalLoading] = useState(false)
    const [showForm, setShowForm] = useState(true);
    const [isLoading, setLoading] = useState(true)
    const [isEditable, setEditable] = useState(false)

    const updateShowForm = async () => {
        await setShowForm(false);
        setTimeout(() => {
            setShowForm(true);
        }, 0);
        setShowForm(true);
    };

    const dispatch = useDispatch()
    // add product
    const addProduct = () => {
        setProducts([...products, { key: uuidv4() }]);
    }
    // remove product
    const removeProduct = id => {
        globalDialog(() => {
            setProducts(products.filter((product) => product.key !== id))
        })
    }
    // INPUT CHANGE : U O M
    const setUom = (index, val) => {
        setProducts(state => {
            return state.map((s, i) => {
                s.uom_id = i === index ? +val : +s.uom_id
                return s;
            })
        })
    }
    // INPUT QTY
    const setQty = (val, product) => {
        const { code } = product;

        setProducts(state => {
            return state.map(s => {
                if (s.code === code) {
                    s.qty = +val;
                }
                return s;
            });
        })
    }

    const setProductsLocation = (index, { value }) => {
        setProducts(state => {
            return state.map((s, i) => {
                s.product_location = i === index ? value : s.product_location
                return s;
            })
        })
    }

    const setPrice = (val, index) => {
        const price = parseInt(val)
        setProducts(state => {
            return state.map((s, i) => {
                s.sales_price = i === index ? price : s.sales_price
                return s;
            })
        })
    }

    // effect product deliv
    useEffect(() => {
        /* ngirim data ke parent sendProducts : props by alias */
        // updateShowForm()
        // sendProducts(products)

        if (defaultData && defaultData.length === products.length) {
            setTimeout(() => {
                setLoading(false)
                sendProducts(products)
            }, 500)
        } else {
            sendProducts(products)
        }
    }, [products])

    // masang data default dari edit
    useEffect(() => {
        if (defaultData) {
            setEditable(true)

            let defaultDataProducts = []

            defaultData.forEach((product) => {
                defaultDataProducts.push({
                    id: product.id,
                    product_id: product.product_id,
                    sales_price: product.price,
                    qty_shipment: product.qty_shipment,
                    qty: product.qty,
                    uom_id: product.uom_id,
                    name: product.product_name,
                    code: product.barcode || product.product_code,
                    variant_type_id: product.variant_type_id,
                    variant_type_value: product.variant_type_value,
                    product_location: product.product_location,
                    key: uuidv4()
                })
            })

            setProducts(defaultDataProducts)
        }
    }, [defaultData])
    /* ================================= */
    const selectProduct = (opt, index) => {
        const row = opt.row

        if (row) {
            const isDuplicate = products.find(product => product.code === row.code)

            if (isDuplicate) {
                globalMessage('Product has been added already', 'warning')
                setProducts(state => {
                    return state.filter((_, i) => i !== index)
                });
            } else {
                setProducts(state => {
                    state[index] = { ...row, key: state[index].key };
                    state[index].qty = 0;
                    state[index].product_location = "stock"
                    return [...state];
                });
            } 
        }
    }

    const handleScannerProduct = ({ master_product }) => {
        if (master_product.status) {
            const isFound = products.find(product => product.code === master_product.data[0]?.code)
            let data = master_product.data[0]

            if (master_product.data.length) {
                if (isFound) {
                    const updatedQty = products.map(product => {
                        if (product.code === isFound.code) {
                            product.qty = (product.qty || 0) + 1
                        }
                        return product;
                    })
                    return updatedQty //kirim data ke actionScannerProduct
                } else {
                    data.qty = 1;
                    data.product_location = "stock"
                    if (products.length === 1 && !products[0].code) {
                        return ([{ ...data, key: uuidv4() }])
                    } else {
                        return ([...products, { ...data, key: uuidv4() }])
                    }
                }
            } else {
                dataErrStatus(true)
                return products
            }
        } else {
            dataErrStatus(true)
            return products
        }
    }

    const actionScannerProduct = (handle) => {
        setProducts(handle)
    }

    const modalOpen = async (code, branch_id) => {
        setShowModal(state => !state)
        setModalLoading(true)
        const response = await dispatch(filterProduct({ flat: true, code, branch_id: branch_id?.id ?? branch_id?.value }))
        if (response.status) {
            setModalContent(response.data[0])
            setModalLoading(false)
        } else {
            setModalLoading(false)
        }
    }

    return (
        <div className="table-product style-2">
            <ScanProduct handle={handleScannerProduct} action={actionScannerProduct} type={"delivery"} />
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Product Name</th>
                        <th>Product Code</th>
                        <th>On Hand</th>
                        <th>Qty</th>
                        <th>Product Location</th>
                        <th>Price</th>
                        <th>UoM</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    (isLoading && isEditable) ? <tr><td colSpan="9"><h1 style={{textAlign:'center'}}>Loading...</h1></td></tr> :
                        products.map((product, productIdx) => {
                            const { sales_price, id, name, code, uom_id, qty, color_name, variant_type_value, product_location } = product;
                            
                            let productLocation = product_location == 'stock' ? { label: 'Stock', value: 'stock' } : { label: 'Reject', value: 'reject' }

                            const productName =  name ?
                                 {
                                     label: `${name} ${color_name ? `- ${color_name}`: ''} ${variant_type_value ? `(${variant_type_value})` : ''}`,
                                     value: code
                                 } : null
                            
                            return (
                                <tr key={product.key}>
                                    <td className="width-120">
                                        <div>
                                            {(productIdx > 0 || products.length > 1) && <ButtonIcon onClick={() => removeProduct(product.key)} icon="trash" theme="grey" />}
                                            {(productIdx === products.length - 1) && <ButtonIcon onClick={addProduct} icon="plus" theme="teal" />}
                                        </div>
                                    </td>
                                    <td className="width-250">
                                        {
                                            <SelectAsync
                                                type="productName"
                                                portal={true}
                                                name={product.key}
                                                cache={false}
                                                index={productIdx}
                                                options='productName'
                                                value={productName}
                                                handleChange={(x) => selectProduct(x, productIdx)}
                                                param={{ flat: true, status_product: 'active' }}
                                            />
                                        }
                                    </td>
                                    <td className="width-160">
                                        {code || '-'}
                                    </td>
                                    <td className="width-140 text-center">
                                        <FontAwesomeIcon icon='info-circle' size="lg" onClick={code ? () => modalOpen(code, branch) : null} style={{ cursor: 'pointer' }} />
                                    </td>
                                    <td className="qtyInput width-as-content">
                                        {
                                            qty != null ?
                                                <InputNumber
                                                    withFieldContainer
                                                    value={qty}
                                                    handleChange={(val) => setQty(val, product)}
                                                /> : "-"
                                        }
                                    </td>
                                    <td className="width-130">
                                        {
                                            product_location ?
                                                // <SelectData name={`pl-${productIdx}`} options={variant_type} value={product_location} handleChange={(val) => setProductsLocation(productIdx, val)} /> : '-'
                                                <ReactSelect 
                                                    label="Product Location"
                                                    options={variant_type}
                                                    value={productLocation}
                                                    name={`pl-${productIdx}`}
                                                    onChange={(val) => setProductsLocation(productIdx, val)}
                                                /> : '-'
                                        }
                                    </td>
                                    <td className="text-left width-160">
                                        {
                                            sales_price != null ?
                                                <InputNumber
                                                    value={sales_price}
                                                    handleChange={(e) => setPrice(e, productIdx)}
                                                /> : "-"
                                        }
                                    </td>
                                    <td className="width-200">
                                        {
                                            // uom_id ?
                                            //     <SelectData handleChange={(val) => setUom(productIdx, val)} value={uom_id} options="uom" name={`uom-${productIdx}`} />
                                            //     : '-'
                                            <ReactSelect 
                                                label="UoM"
                                                options={{label: 'Pieces', value: 1}}
                                                value={{ label: 'Pieces', value: 1}}
                                                name={`uom-${productIdx}`}
                                                isDisabled={true}
                                            />
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <SimpleModal
                customClass="modal"
                isOpen={showModal}
                setIsOpen={setShowModal}
                width={600}
                title="Current Stock"
            >
                {
                    modalLoading ?
                        <div className="text-center" style={{ marginTop: '20px' }}>
                            <CircularProgress style={{padding:'10px', marginTop: '20px', marginLeft: '40px'}} />
                        </div>
                        :
                        <>
                            <div className="modal-sales pb-0">
                                <div className="detail style-2">
                                    <div className="col">
                                        <span className="key">Branch</span>
                                        <span className="val">{branch?.branch_name ?? branch?.label}</span>
                                    </div>
                                    <div className="col">
                                        <span className="key">Name</span>
                                        <span className="val">
                                            {`${modalContent?.name} ${modalContent?.color_name ? modalContent?.color_name : ''} ${modalContent?.variant_type_value ? `(${modalContent?.variant_type_value})` : ''}` ?? '-'}
                                        </span>
                                    </div>
                                    <div className="col">
                                        <span className="key">Stock</span>
                                        <span className="val">{modalContent?.stock_on_hand ?? '-'}</span>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </SimpleModal>
        </div>
    )
}