import {
    STOCKOPNAME_LOADING,
    STOCKOPNAME_SET,
    STOCKOPNAME_ERROR,
    STOCKOPNAME_ADD,
    STOCKOPNAME_SHOW,
    STOCKOPNAME_DELETE,
    STOCKOPNAME_UPDATE,
    STOCKOPNAME_RESET,
    STOCKOPNAME_EDIT,
    STOCKOPNAME_CONFIRM,
    STOCKOPNAME_STOP_LOADING
} from './stockopnameActions'


const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const stockopnameReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case STOCKOPNAME_LOADING:
            return {
                ...state,
                loading: true,
            }
        case STOCKOPNAME_STOP_LOADING:
            return {
                ...state,
                loading: false,
            }
        case STOCKOPNAME_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === state.idEdit ? payload : x),
                success: 'Data has been updated successfully.'
            }
        case STOCKOPNAME_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case STOCKOPNAME_ADD:
            console.log(STOCKOPNAME_ADD, type, payload)
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New stockopname has been added successfully.'
            }
        case STOCKOPNAME_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case STOCKOPNAME_SHOW:
            return {
                ...state,
                idShow: payload,
                success: null,
                error: null
            }
        case STOCKOPNAME_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case STOCKOPNAME_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case STOCKOPNAME_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                // data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case STOCKOPNAME_CONFIRM:

            console.log('update status')
            console.log(payload)

            let newData = state.data;

            newData = newData.map(x => {
                if (x.id === payload.id) {
                    x.status = payload.type
                }

                return x
            })


            console.log({ newData })




            return {
                ...state,
                data: newData

            }
        default:
            return state;
    }
}

export default stockopnameReducer


