import { handleReq, makeGetParam, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "STOCKOPNAME"

export const STOCKOPNAME_SET = 'STOCKOPNAME_SET'
export const STOCKOPNAME_ERROR = 'STOCKOPNAME_ERROR'
export const STOCKOPNAME_ADD = 'STOCKOPNAME_ADD'
export const STOCKOPNAME_SHOW = 'STOCKOPNAME_SHOW'
export const STOCKOPNAME_EDIT = 'STOCKOPNAME_EDIT'
export const STOCKOPNAME_UPDATE = 'STOCKOPNAME_UPDATE'
export const STOCKOPNAME_DELETE = 'STOCKOPNAME_DELETE'
export const STOCKOPNAME_LOADING = 'STOCKOPNAME_LOADING'
export const STOCKOPNAME_STOP_LOADING = 'STOCKOPNAME_STOP_LOADING'
export const STOCKOPNAME_RESET = 'STOCKOPNAME_RESET'
export const STOCKOPNAME_CONFIRM = 'STOCKOPNAME_CONFIRM'

export const loadStockopname = () => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/stock_opname`),
        next: (payload) => dispatch({ type: STOCKOPNAME_SET, payload })
    })
}
export const addStockopname = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/stock_opname`, data),
        next: (payload) => dispatch({ type: STOCKOPNAME_ADD, payload })
    })
}
export const updateStockopname = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/stock_opname/${idEdit}`, data),
        next: (payload) => dispatch({ type: STOCKOPNAME_UPDATE, payload })
    })
}
export const deleteStockopname = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/stock_opname`, id),
        next: () => dispatch({ type: STOCKOPNAME_DELETE, payload: id })
    })
}
export const showStockopname = idx => {
    return {
        type: STOCKOPNAME_SHOW,
        payload: idx
    }
}
export const setEditStockopname = idEdit => {
    return {
        type: STOCKOPNAME_EDIT,
        payload: idEdit,
    }
}
export const resetStatusStockopname = () => {
    return {
        type: STOCKOPNAME_RESET
    }
}
export const updateStatus = (id, type, body) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/stock_opname/${id}/${type}`, body),
        next: () => dispatch({ type: STOCKOPNAME_CONFIRM, payload: { type, id } })
    })
}
export const stockopnameTemp = (id, type) => {
    return {
        type: STOCKOPNAME_CONFIRM,
        payload: { id, type },
    }
}
export const loadProductByCat = (args) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/product${makeGetParam(args)}`),
        // next: (payload) => dispatch({ type: STOCKOPNAME_SET, payload })
    })
}
export const getStockOpnameById = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/stock_opname/${idx}`),
    })
}
