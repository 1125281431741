import React from 'react'
import { useSelector } from 'react-redux';
import DetailHeader from 'Comps/layouts/detailheader/DetailHeader'

export default function Detail(props) {

    const { idxShow, data } = useSelector(state => state.colorReducer);
    const detail = data.filter((a, b) => b === +idxShow)[0]
    console.log(detail)
    const objData = {
        code: detail.code,
        color: detail.name
    }

    return <DetailHeader data={objData} />

}
