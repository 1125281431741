import React, { useState, useEffect } from 'react'
import { useFormikContext, ErrorMessage } from 'formik';

function InputNumber(props) {

    const { withFieldContainer, min, labelAsBaloon, max, value, label, handleChange: handleChangeProps, name, disabled, defaultValue } = props;
    const formik = useFormikContext();
    const [val, setVal] = useState(value || 0)
    const handleChange = (e) => {
        let val = e.target.value;
        console.log(val)
        if (val === 0 || val === '0' || val.length < 1 || val === '') {
            val = 0;
        }

        if (isNaN(val)) {
            return;
        }

        if (min || max) {
            if (val < min) {
                // val = min;
            } else if (val > max) {
                return;
            }
        }

        if (val.length > 1 && (val[0] === 0 || val[0] === '0' || val[0] === '-')) {
            val = val.substring(1)
        }

        handleChangeProps && handleChangeProps(val, props)
        setVal(val);
    }

    useEffect(() => {
        setVal(value)
    }, [value])


    return (
        <>
            {
                withFieldContainer ?
                    <div className={`field-container ${labelAsBaloon ? 'label-as-baloon' : ''}`}>
                        {label && <label htmlFor={name}>{label}</label>}
                        <input
                            maxLength='16'
                            name={name}
                            className="input-number"
                            onChange={(e) => handleChange(e)}
                            value={val || 0}
                            disabled={disabled}
                            defaultValue={defaultValue ?? {}}
                        />
                    </div>
                    :
                    <input
                        maxLength='16'
                        name={name}
                        onChange={(e) => handleChange(e)}
                        value={val || 0}
                        disabled={disabled}
                        defaultValue={defaultValue ?? {}}
                    />
            }
        </>

    )
}


export default InputNumber
