import {
    PROMOTION_CONFIGURATION_LOADING,
    PROMOTION_CONFIGURATION_SET,
    PROMOTION_CONFIGURATION_ERROR,
    PROMOTION_CONFIGURATION_ADD,
    PROMOTION_CONFIGURATION_SHOW,
    PROMOTION_CONFIGURATION_DELETE,
    PROMOTION_CONFIGURATION_RESET,
    PROMOTION_CONFIGURATION_EDIT,
    PROMOTION_CONFIGURATION_CONFIRM,
    PROMOTION_CONFIGURATION_UPDATE,
    PROMOTION_CONFIGURATION_STOP_LOADING,
    PROMOTION_CONFIGURATION_RESET_EDIT
} from './promotionConfigurationActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const promotionConfigurationReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case PROMOTION_CONFIGURATION_STOP_LOADING:
            return {
                ...state,
                loading: false,
                idEdit: null,
            }
        case PROMOTION_CONFIGURATION_RESET_EDIT:
            return {
                ...state,
                idEdit: null,
                idShow: null
            }
        case PROMOTION_CONFIGURATION_LOADING:
            return {
                ...state,
                loading: true,
            }
        case PROMOTION_CONFIGURATION_SET:
            return {
                ...state,
                error: null,
                loading: false,
                data: payload
            }
        case PROMOTION_CONFIGURATION_ADD:
            payload.is_active = payload.is_active === 1
            return {
                ...state,
                loading: false,
                error: null,
                data: [payload, ...state.data],
                success: 'New promotion has been added successfully.'
            }
        case PROMOTION_CONFIGURATION_ERROR:
            return {
                ...state,
                loading: false,
                error: payload
            }
        case PROMOTION_CONFIGURATION_SHOW:
            return {
                ...state,
                idShow: payload
            }
        case PROMOTION_CONFIGURATION_DELETE:
            return {
                ...state,
                success: 'Data has been deleted successfully',
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
            }
        case PROMOTION_CONFIGURATION_EDIT:
            return {
                ...state,
                error: null,
                success: null,
                idEdit: payload
            }
        case PROMOTION_CONFIGURATION_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case PROMOTION_CONFIGURATION_CONFIRM:

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData

            }
        case PROMOTION_CONFIGURATION_UPDATE:
            return {
                ...state,
                loading: false,
                data: state.data.map(x => x.id === (state.idEdit || payload.id) ? payload : x),
                success: 'Data has been updated successfully.'
            }

        default:
            return state;
    }
}

export default promotionConfigurationReducer
