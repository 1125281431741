import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import PrintIcon from '@material-ui/icons/Print';
import { withStyles } from "@material-ui/core/styles";

const defaultToolbarStyles = {
  iconButton: {
  },
};

const CustomToolbar = (props) => {
  const { classes, customComponents, showPrint } = props;

  return (
    <>
      {
        showPrint && 
        <Tooltip title={"Print"}>
          <IconButton className={classes.iconButton} onClick={() => window.print()}>
            <PrintIcon className={classes.deleteIcon} />
          </IconButton>
        </Tooltip>
      }

      <>
        { 
          customComponents && React.createElement(customComponents) 
        }
      </>
    </>
  )
}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);

