import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "VARIANT"
export const VARIANT_SET = 'VARIANT_SET'
export const VARIANT_ERROR = 'VARIANT_ERROR'
export const VARIANT_ADD = 'VARIANT_ADD'
export const VARIANT_SHOW = 'VARIANT_SHOW'
export const VARIANT_EDIT = 'VARIANT_EDIT'
export const VARIANT_UPDATE = 'VARIANT_UPDATE'
export const VARIANT_DELETE = 'VARIANT_DELETE'
export const VARIANT_LOADING = 'VARIANT_LOADING'
export const VARIANT_RESET = 'VARIANT_RESET'


export const loadVariant = (all) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/variant${all ? '?all=true' : ''}`),
        next: (payload) => dispatch({ type: VARIANT_SET, payload })
    })
}
export const addVariant = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/variant`, data),
        next: (payload) => dispatch({ type: VARIANT_ADD, payload })
    })
}
export const updateVariant = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/variant/${idEdit}`, data),
        next: (payload) => dispatch({ type: VARIANT_UPDATE, payload })
    })
}
export const deleteVariant = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/variant`, id),
        next: () => dispatch({ type: VARIANT_DELETE, payload: id })
    })
}
export const showVariant = idx => {
    return {
        type: VARIANT_SHOW,
        payload: idx
    }
}
export const setEdit = idEdit => {
    return {
        type: VARIANT_EDIT,
        payload: idEdit,
    }
}
export const resetStatusVariant = () => {
    return {
        type: VARIANT_RESET
    }
}