import {
    PROMOTION_ADD,
    PROMOTION_SET,
    PROMOTION_EDIT,
    PROMOTION_SHOW,
    PROMOTION_ERROR,
    PROMOTION_RESET,
    PROMOTION_DELETE,
    PROMOTION_UPDATE,
    PROMOTION_CONFIRM,
    PROMOTION_LOADING,
} from './promotionActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const promotionReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case PROMOTION_SET:
            return {
                ...state,
                data: payload,
                error: null,
                loading: false,
            }
        case PROMOTION_ADD:
            payload.is_active = payload.is_active === 1
            return {
                ...state,
                data: [payload, ...state.data],
                loading: false,
                error: null,
                success: 'New contact has been added successfully.'
            }
        case PROMOTION_EDIT:
            return {
                ...state,
                idEdit: payload,
                loading: false,
                error: null,
            }
        case PROMOTION_SHOW:
            return {
                ...state,
                idShow: payload,
            }
        case PROMOTION_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case PROMOTION_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case PROMOTION_DELETE:
            return {
                ...state,
                data: state.data.filter(deletedData => deletedData.id !== payload),
                loading: false,
                success: 'Data has been successfully deleted.'
            }
        case PROMOTION_UPDATE:
            return {
                ...state,
                data: state.data.map(editedData => editedData.id === (state.idEdit || payload.id) ? payload : editedData),
                loading: false,
                success: 'Data has been successfully edited.'
            }
        case PROMOTION_CONFIRM:

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData
            }
        case PROMOTION_LOADING:
            return {
                ...state,
                loading: true
            }
        default:
            return state;
    }
}

export default promotionReducer