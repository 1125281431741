import React, { useState, useEffect, createElement } from 'react'
import Module from 'Layouts/Module'
import { useSelector, useDispatch } from 'react-redux';
import FormNew from './FormNew'
import TableData from './TableData'
import Detail from './Detail'
import FormEdit from './FormEdit'
import { loadContact, showContact, deleteContact, setEdit } from 'Reduxes/contact/contactActions'
import { scrollToTop, globalDialog } from '@/Helper'
import { loadUsercategory } from 'Reduxes/usercategory/usercategoryActions'

export default function Contact(props) {
    const { type } = props;
    const dev = 0;
    const { data } = useSelector(state => state.contactReducer)
    const dispatch = useDispatch();
    const dataLength = data.length;
    // const [userRoles, setUserRoles] = useState([{ label: 'loading data', value: 0 }])

    const fieldGroupTop = [
        {
            name: 'Contact Type',
            fields: [
                {
                    label: 'User',
                    validation: 'req',
                    type: 'check',
                    name: 'is_user',
                    value: false,
                }, {
                    type: 'check',
                    label: 'Supplier',
                    value: false,
                    validation: 'req',
                    checked: true,
                    name: 'is_supplier',
                }, {
                    type: 'check',
                    label: 'Brand',
                    value: false,
                    validation: 'req',
                    name: 'is_brand',
                }, {
                    label: 'Partner Store',
                    value: false,
                    name: 'is_consignment',
                    type: 'check',
                    validation: 'req',
                },
            ]
        }
    ]
    const fieldGroup = [
        {
            name: 'General Information',
            fields: [
                {
                    label: 'Contact name',
                    validation: 'req',
                    name: 'name',
                    col: 8,
                    value: dev ? 'Contact Name ' + dataLength : ''
                }, {
                    name: 'is_active',
                    validation: 'req',
                    label: 'Status',
                    col: 4,
                    type: 'select',
                    placeholder: 'Select status',
                    options: [
                        { label: 'Active', value: 'true' },
                        { label: 'Inactive', value: 'false' },
                    ],
                    value: "true"
                },
            ]
        },
        // {
        //     name: 'Address Information',
        //     fields: [
        //         {
        //             label: 'Address',
        //             validation: 'req',
        //             name: 'address',
        //             col: 8,
        //             value: dev ? 'Jl. Waruga Jaya No.108, Ciwaruga, Kec. Parongpong, Kabupaten Bandung Barat' : ''
        //         }, {
        //             label: 'Province',
        //             col: 4,
        //             validation: 'req',
        //             name: 'province',
        //             value: dev ? 'West Java' : ''
        //         }, {
        //             label: 'City',
        //             validation: 'req',
        //             col: 4,
        //             name: 'city',
        //             value: dev ? 'Bandung' : ''
        //         }, {
        //             label: 'Postal Code',
        //             validation: 'req',
        //             col: 4,
        //             name: 'postal_code',
        //             value: dev ? '20242' : ''
        //         },
        //     ]
        // },
        //  {
        //     name: 'Contact Information',
        //     fields: [
        //         {
        //             label: 'Phone',
        //             validation: 'req',
        //             name: 'phone',
        //             col: 4,
        //             value: dev ? '08212774283' : ''
        //         }, {
        //             label: 'Email',
        //             col: 4,
        //             validation: 'req',
        //             name: 'email',
        //             value: dev ? 'pworks@pw.com' : ''
        //         }, {
        //             label: 'Note',
        //             validation: 'req',
        //             type: 'textarea',
        //             col: 12,
        //             name: 'note',
        //             value: dev ? 'The time to get it right is while you are doing it. Anything else sets you up for regret.' : ''
        //         },
        //     ]
        // }
    ]
    const [fieldGroupExtra, setFieldGroupExtra] = useState([
        {
            name: 'User Data',
            fields: [
                {
                    label: 'Username',
                    validation: 'req',
                    name: 'username',
                    value: dev ? 'user' + dataLength : ''
                }, {
                    type: 'password',
                    label: 'Password',
                    name: 'password',
                    value: dev ? 'aaaaa' : '',
                }, {
                    type: 'selectV2',
                    label: 'roles',
                    validation: 'req',
                    name: 'user_category_id',
                    options: [{ label: 'loading data', value: 0 }],
                    value: dev,
                },
            ]
        }, {
            name: 'Brand Data',
            fields: [
                {
                    name: 'brand_comission_type',
                    validation: 'req',
                    label: 'Commissions Condition',
                    type: 'select',
                    options: [
                        { label: 'All products', value: 'all_product' },
                        { label: 'By Category', value: 'by_category' },
                    ],
                    value: dev ? 'by_category' : ''
                }, {
                    label: 'Commissions (%)',
                    name: 'brand_comission',
                    value: dev
                },
            ]
        }, {
            name: 'Partnership Store',
            fields: [
                {
                    type: 'select',
                    label: 'Commissions Condition',
                    name: 'consignment_comission_type',
                    value: 'by_category',
                    options: [
                        { label: 'All products', value: 'all_product' },
                        { label: 'By Category', value: 'by_category' },
                    ],
                }, {
                    label: 'Commissions (%)',
                    value: dev,
                    name: 'consignment_comission',
                },
            ]
        }
    ])
    const setContentActive = activeContent => {
        setContents(
            contents.map(content => {
                content.active = content.name === activeContent
                return content;
            })
        )
    }
    const [contents, setContents] = useState([
        {
            name: 'table',
            active: true,
            component: TableData,
            props: {
                data,
                type,
                setContentActiveFormEdit: id => {
                    dispatch(setEdit(id))
                    setContentActive('formedit')
                },
                setContentActiveDetail: (idShow) => {
                    dispatch(showContact(idShow))
                    setContentActive('detail')
                },
                deleteMemberProps: id => {
                    globalDialog(async () => {
                        await dispatch(deleteContact(id))
                        scrollToTop()
                    })
                }
            },
            title: {
                title: 'Contact',
                subtitle: '',
                button: {
                    label: 'Add Contact',
                    style: 'OrangeMd',
                    icon: 'plus-circle',
                    onClick: () => {
                        // dispatch())
                        setContentActive('form')
                    },
                    subMenu: 'contacts',
                    type: 'create',
                }
            }
        }, {
            name: 'form',
            active: false,
            component: FormNew,
            props: {
                fieldGroupTop,
                fieldGroup,
                fieldGroupExtra,
                goToIndex: () => setContentActive('table'),
            },
            title: {
                title: 'Add New Contact',
                subtitle: 'Fill the form below to create new contact',
            },
        }, {
            name: 'formedit',
            active: false,
            component: FormEdit,
            props: {
                goToIndex: () => setContentActive('table'),
                fieldGroupTop,
                fieldGroup,
                fieldGroupExtra,
            },
            title: {
                title: 'Update contact',
                subtitle: 'Good to keep the contact data updated',
            }
        }, {
            name: 'detail',
            active: false,
            component: Detail,
            title: {
                title: 'Contact Detail',
                subtitle: '',
                button: {
                    label: 'Go back',
                    style: 'WhiteMd',
                    icon: 'arrow-left',
                    onClick: () => {
                        setContentActive('table')
                    },
                }
            },
            props: {
                setContentActiveFormEdit: branchId => {
                    // dispatch(resetStatusMember())
                    // dispatch(setIdEdit(branchId))
                    setContentActive('formedit')
                },
            },
        }
    ])
    const loadUserRoles = async () => {
        const respUserRoles = await dispatch(loadUsercategory())
        if (respUserRoles.status) {
            const rows = respUserRoles.data.map(row => ({
                value: row.id, label: row.user_category_name
            }))

            fieldGroupExtra[0].fields[2].options = rows;
            fieldGroupExtra[0].fields[2].value = rows[0].value;

            setFieldGroupExtra([...fieldGroupExtra])
            return;
        }

        fieldGroupExtra[0].fields[2].options = [{ label: 'Failed load data', value: 0 }];
        fieldGroupExtra[0].fields[2].value = 0;
    }

    useEffect(() => {
        loadUserRoles();

        setContents(state => {
            state[0].title.subtitle = !type ? 'Showing list of all contacts' : `Showing ${type} contacts`;
            return state;
        })
        let param = {};
        if (type === 'consignment') {
            param = { is_consignment: true }
        } else if (type === 'brand') {
            param = { is_brand: true }
        } else if (type === 'user') {
            param = { is_user: true }
        } else if (type === 'supplier') {
            param = { is_supplier: true }
        } else {
            param = { all: true }
        }

        dispatch(loadContact(param));
    }, [type])

    return (
        <>
            {
                contents.map((c, cI) => {
                    if (c.active) {
                        return (
                            <Module {...c.title} key={cI}>
                                {createElement(c.component, c.props)}
                            </Module>
                        )
                    }
                })
            }
        </>
    )

}
