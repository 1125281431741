import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik';
import FieldsGroup from 'Elements/FieldsGroup'
import { useSelector, useDispatch } from 'react-redux';
import { scrollToTop, fieldsToFormik } from '@/Helper'
import { addContact, resetStatusContact } from 'Reduxes/contact/contactActions'
import Alert from 'Comps/elements/Alert'
import Button from 'Elements/Button';
import { serverValue } from 'Configs/Form';
import { validationSchema } from './validation'
import { filterMember, loadProvince, loadCity } from 'Reduxes/member/memberActions'
import Select from 'Elements/select/Select'
import Input from 'Elements/Input';
import FieldsContainer from 'Elements/FieldsContainer'
import Textarea from 'Elements/Textarea';
import SelectData from 'Elements/SelectData'


const FormNew = (props) => {
    const { goToIndex, fieldGroupTop, fieldGroup, fieldGroupExtra } = props;
    const { error } = useSelector(state => state.contactReducer)
    const status = [
        { label: 'Active', value: 'true' },
        { label: 'Inactive', value: 'false' },
    ]
    const dispatch = useDispatch();
    const [formOpts] = useState({
        initialValues: {
            ...fieldsToFormik(fieldGroup),
            ...fieldsToFormik(fieldGroupExtra),
            ...fieldsToFormik(fieldGroupTop),
            user_category_id: 1,
            brand_comission_type: '',
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            if (values.is_user) {
                values.user_category_id = values.user_category_id || 1
            }
            if (values.is_brand) {
                values.brand_comission = values.brand_comission || 0
                values.brand_comission_type = values.brand_comission_type || 'all_product'
            }
            if (values.is_consignment) {
                values.consignment_comission = values.consignment_comission || 0
                values.consignment_comission_type = values.consignment_comission_type || 'all_product'
            }

            let cleanVal = serverValue(values, true);
            let result = await dispatch(addContact(cleanVal))
            scrollToTop()

            if (result) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
        }
    })


    useEffect(() => {
        fieldGroupExtra.map(data => {
            data.fields.map(field => {
                if (field.name !== 'password') {
                    delete field.disabled
                    delete field.isDisabled
                }
            })
        })
        // fieldGroup.map(data => {
        //     data.fields.map(field => {
        //         if (field.name === 'name' || field.name === 'is_active') {
        //             delete field.disabled
        //             delete field.isDisabled
        //         }
        //     })
        // })
        fieldGroupTop.map(data => {
            data.fields.map(field => {
                if (field.name === 'is_user' || field.name === 'is_supplier' || field.name === 'is_brand' || field.name === 'is_consignment') {
                    delete field.disabled
                    delete field.isDisabled
                }
            })
        })
    }, [])
    const handleExtraField = values => {
        let extraFieldTmp = [];
        if (values.is_user) {
            extraFieldTmp.push(fieldGroupExtra[0])
        }
        if (values.is_brand) {
            extraFieldTmp.push(fieldGroupExtra[1])
            extraFieldTmp.map(data => {
                if (data.name === 'Brand Data') {
                    data.fields.map(field => {
                        if (field.name === 'brand_comission' && values.brand_comission_type === 'by_category') {
                            Object.assign(field, { disabled: true })
                            field.value = '0'
                            values.brand_comission = null
                        } else {
                            Object.assign(field, { disabled: false })
                            field.value = values.brand_comission
                        }
                    })
                }
            })
        }
        if (values.is_consignment) {
            extraFieldTmp.push(fieldGroupExtra[2])
            extraFieldTmp.map(data => {
                if (data.name === 'Partnership Store') {
                    data.fields.map(field => {
                        if (field.name === 'consignment_comission' && values.consignment_comission_type === 'by_category') {
                            Object.assign(field, { disabled: true })
                            field.value = '0'
                            values.consignment_comission = null
                        } else {
                            Object.assign(field, { disabled: false })
                            field.value = values.consignment_comission
                        }
                    })
                }
            })
        }
        return extraFieldTmp;
    }



    // Handle chain address
    const [dataCity, setDataCity] = useState([])

    const handleChangeProvince = async val => {
        setDataCity([])
        const resp = await dispatch(loadCity({ province_name: val.selected.value }))
        setDataCity(resp.data.map(x => ({
            label: x.ibukota, value: x.ibukota
        })))

        if (!val.selected.value) {
            setDataCity([])
        }
    }

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusContact())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts}>
                {(formik) => {
                    console.log(formik)
                    const { values, isSubmitting } = formik;
                    if (!formik.values.is_user) {
                        delete formik.values.username
                        delete formik.values.user_category_id
                        delete formik.values.password
                    }
                    if (!formik.values.is_brand) {
                        delete formik.values.brand_comission_type
                        delete formik.values.brand_comission
                    }
                    if (!formik.values.is_consignment) {
                        delete formik.values.consignment_comission_type
                        delete formik.values.consignment_comission
                    }

                    return (
                        <Form className="form-contact">
                            <div className="top">
                                <FieldsGroup fields={fieldGroupTop} />
                            </div>
                            <div className="kiri">
                                {/* <FieldsGroup fields={fieldGroup} /> */}
                                <FieldsContainer title="General Information">
                                    <Input name="name" col={8} label="Contact Name" />
                                    <SelectData name="is_active" options={status} label="Status" value="true" col={4} />
                                </FieldsContainer>
                                <FieldsContainer title="Address Information">
                                    <Input name="address" col={8} label="Address" />
                                    <div></div>
                                    {/*                                                 
                                    <Input name="province" label="Province" />
                                    <Input name="city" label="City" />
                                    <Input name="postal_code" label="Postal Code" /> 
                                    */}
                                    <Select
                                        col="4"
                                        label="Province"
                                        name="province"
                                        type="province"
                                        portal
                                        options="province"
                                        isClearable
                                        getData={handleChangeProvince}
                                    />
                                    {
                                        dataCity.length > 0 &&
                                        <Select
                                            col="4"
                                            label="City"
                                            name="city"
                                            type="city"
                                            portal
                                            isClearable
                                            options={dataCity}
                                        />
                                    }

                                    <Input name="postal_code" label="Postal Code" col="4" />

                                </FieldsContainer>

                                <FieldsContainer title="Other Information">
                                    <Input name="phone" label="Phone" col="4" />
                                    <Input name="email" label="Email" validation='req' col="4" />
                                    <Textarea name="note" label="Notes" col="8" />
                                </FieldsContainer>


                            </div>
                            {
                                handleExtraField(values).length > 0 && (
                                    <div className="kanan">
                                        <FieldsGroup fields={handleExtraField(values)} />
                                    </div>
                                )
                            }
                            <div className="fields-group-container buttons">
                                <Button
                                    type="submit"
                                    style="OrangeMdWide"
                                    label={isSubmitting ? 'Saving' : 'Save'}
                                    icon={isSubmitting ? 'spinner' : null}
                                    disabled={isSubmitting} />
                                <button disabled={isSubmitting} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </>
    )
}

export default FormNew