import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { loadPromotionConfiguration, resetShowIfCancelEdit, showPromotionConfiguration } from 'Reduxes/pos/promotion_configuration/promotionConfigurationActions'
import TableData from './TableData'
import FormElement from './FormElement'
import Detail from './Detail'

function PromotionConfiguration() {

    const { data, idShow } = useSelector(state => state.promotionConfigurationReducer)
    const dispatch = useDispatch()
    const [content, setContent] = useState('table')

    useEffect(() => {
        dispatch(loadPromotionConfiguration());
    }, [])

    const showTable = () => {
        setContent('table')
    }

    const showDetail = (id) => {
        dispatch(showPromotionConfiguration(id))
        setContent('detail')
    }

    const showForm = (type) => {
        if (type !== 'Edit') {
            dispatch(resetShowIfCancelEdit())
        }

        setContent('form' + type)
    }

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                return (<TableData showForm={showForm} showDetail={showDetail} />)
            case 'formNew':
                return (<FormElement type="new" showTable={showTable} />)
            case 'formEdit':
                return (<FormElement type="edit" showTable={showTable} />)
            case 'detail':
                return (<Detail showTable={showTable} rowId={idShow} />)
            default:
                return <>Nothing to show here</>
        }
    }

    return (
        <>
            {
                renderContent(content)
            }
        </>
    )
}

export default PromotionConfiguration
