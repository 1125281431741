import React from 'react'
import { money, showMonth } from '@/Helper'
import NoReport from 'Elements/NoReport'

const makeTbodyRow = {
    day(rows) {
        return this.makeBody(rows)
    },
    monthOrYear(rows) {
        return rows.map(data => (
            <>
                <tr className="highlight">
                    <td></td>
                    <td>{showMonth(data.date)}</td>
                    <td className="td-highlight"></td>
                    <td></td>
                    <td className="td-text-right">IDR {money(data.total_invoice)}</td>
                    <td className="td-text-right">IDR {money(data.total_payment)}</td>
                    <td className="td-text-right">IDR {money(data.difference)}</td>
                    <td className="td-text-right"></td>
                </tr>
                {
                    this.makeBody(data.payments)
                }
            </>
        ))
    },
    makeBody(rows) {
        return rows.map((r, rowIdx) => (
            <tr>
                <td>{rowIdx + 1}</td>
                <td>{r.date}</td>
                <td>{r.code}</td>
                <td>{r.contact_name}</td>
                <td className="td-text-right">IDR {money(r.total_invoice)}</td>
                <td className="td-text-right">IDR {money(r.total_payment)}</td>
                <td className="td-text-right">IDR {money(r.difference)}</td>
                <td className="td-text-right">{r.status}</td>
            </tr>
        ))
    }
}

export default function TableGeneral({ data, type }) {

    return data.payments.length < 1 ? <NoReport /> : (
        <>
            <p className="asdasd" style={{ marginBottom: '10px' }}> Payment Report <span>Showing Payment Report</span></p>
            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Total Invoice</span>
                        <span>IDR {money(data.inv_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total Payment</span>
                        <span>IDR {money(data.payment_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total Difference</span>
                        <span>IDR {money(data.total_difference)}</span>
                    </p>
                </div>
            </div>

            <div className="report-summary-pill">
                <p>
                    <span>Invoice :</span>
                    <span>{data.inv_count}</span>
                </p>
                <p>
                    <span>Payment :</span>
                    <span>{data.payment_count}</span>
                </p>
            </div>

            <table className="table-report">
                <thead>
                    <tr>
                        <th>No.</th>
                        <th>Date</th>
                        <th>No Payment</th>
                        <th>Contact</th>
                        <th className="th-text-right">Total invoice</th>
                        <th className="th-text-right">Total Paid</th>
                        <th className="th-text-right">Difference </th>
                        <th className="th-text-right">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {makeTbodyRow[type === 'day' ? 'day' : 'monthOrYear'](data.payments)}
                </tbody>
            </table>
        </>
    )
}








/*

const makeTbodyRow = arr => {
    // console.log(arr)
    let result = [];
    arr.map((data, index) => {
        console.log(data)
        // pos.branches.map((branch, branchIdx) => {
        //     result.push(
        //         <tr>
        //             <td>{branchIdx === 0 ? posIdx + 1 : ''}</td>
        //             <td>{branchIdx === 0 ? pos.date : ''}</td>
        //             <td className="td-highlight">{branch.branch_name}</td>
        //             <td>{branch.buyer_general_count}</td>
        //             <td>{branch.buyer_vip_count}</td>
        //             <td>{branch.buyer_member_count}</td>
        //             <td>{branch.sales_return_count}</td>
        //             <td className="td-text-right">{money(branch.sub_total)}</td>
        //             <td className="td-text-right">{money(branch.discount_value)}</td>
        //             <td className="td-text-right">{money(branch.grand_total)}</td>
        //             <td className="td-text-right">{money(branch.hpp)}</td>
        //             <td className="td-text-right">{money(branch.additional_cost)}</td>
        //             <td className="td-text-right">{money(branch.net_income)}</td>
        //         </tr>
        //     )
        // })

        result.push(
            <tr className="highlight">
                <td>{index + 1}</td>
                <td>{data.date}</td>
                <td>{data.code}</td>
                <td>{data.contact_name}</td>
                <td className="td-text-right">IDR {money(data.sub_total)}</td>
                <td className="td-text-right">IDR {money(data.total_discount)}</td>
                <td className="td-text-right">IDR {money(data.total)}</td>
                <td>IDR {money(data.hpp)}</td>
                <td className="td-text-right">IDR {money(data.additional_cost)}</td>
                <td className="td-text-right">IDR {money(data.income)}</td>
                <td className="td-text-right">{data.status}</td>
            </tr>
        )
    })

    return result;
}


export default function TableGeneral(props) {
    const { data } = props;
    console.log(data)

    return (
        <>
            <div className="report-summary-box">
                <div className="item teal">
                    <p>
                        <span>Net Income</span>
                        <span>IDR {money(data.inv_income)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Sub Total</span>
                        <span>IDR {money(data.payment_sub_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total</span>
                        <span>IDR {money(data.payment_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Total Invoice</span>
                        <span>IDR {money(data.inv_total)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Discount</span>
                        <span>IDR {money(data.payment_discount)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>HPP</span>
                        <span>IDR {money(data.inv_hpp)}</span>
                    </p>
                </div>
                <div className="item">
                    <p>
                        <span>Other Cost</span>
                        <span>IDR {money(data.inv_additional_cost)}</span>
                    </p>
                </div>
            </div>





        </>
    )
}
 */