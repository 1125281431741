import {
    PAYMENT_ADD,
    PAYMENT_SET,
    PAYMENT_EDIT,
    PAYMENT_SHOW,
    PAYMENT_ERROR,
    PAYMENT_RESET,
    PAYMENT_DELETE,
    PAYMENT_UPDATE,
    PAYMENT_CONFIRM,
    PAYMENT_LOADING,
    PAYMENT_STOP_LOADING
} from './paymentActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const paymentReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case PAYMENT_SET:
            return {
                ...state,
                data: payload,
                error: null,
                loading: false,
            }
        case PAYMENT_ADD:
            payload.is_active = payload.is_active === 1
            return {
                ...state,
                data: [payload, ...state.data],
                loading: false,
                error: null,
                success: 'New contact has been added successfully.'
            }
        case PAYMENT_EDIT:
            return {
                ...state,
                idEdit: payload,
                loading: false,
                error: null,
            }
        case PAYMENT_SHOW:
            return {
                ...state,
                idShow: payload,
            }
        case PAYMENT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false,
            }
        case PAYMENT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case PAYMENT_DELETE:
            return {
                ...state,
                data: state.data.filter(a => !payload.includes(a.id)),
                loading: false,
                success: 'Data has been successfully deleted.'
            }
        case PAYMENT_UPDATE:
            return {
                ...state,
                data: state.data.map(editedData => editedData.id === (state.idEdit || payload.id) ? payload : editedData),
                loading: false,
                success: 'Data has been successfully edited.'
            }
        case PAYMENT_CONFIRM:

            let newData = state.data.map(d => {
                if (d.id === payload.id) {
                    d.status = payload.type;
                }
                return d
            })

            return {
                ...state,
                data: newData
            }
        case PAYMENT_LOADING:
            return {
                ...state,
                loading: true
            }
        case PAYMENT_STOP_LOADING:
            return {
                ...state,
                loading: false
            }
        default:
            return state;
    }
}

export default paymentReducer