import { Formik, Form } from 'formik';
import Button from 'Elements/Button';
import { scrollToTop } from '@/Helper'
import Alert from 'Comps/elements/Alert'
import FieldsGroup from 'Elements/FieldsGroup'
import { validationSchema } from './validation'
import React, { useEffect, useState } from 'react'
import LoadingContent from 'Elements/LoadingContent';
import { useSelector, useDispatch } from 'react-redux';
import { type, menus } from '../../../utils/MenuConfig';
import {
    resetStatusUsercategory,
    updateUsercategory,
    addUsercategoryPermission,
    getPermission,
    deletePermission,
} from 'Reduxes/usercategory/usercategoryActions'

const FormEdit = (props) => {
    const { goToIndex, fieldGroup } = props;

    const { data, error, idEdit } = useSelector(state => state.usercategoryReducer);

    const dataEdit = data.filter((a, b) => a.id === idEdit)[0]

    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);

    const [initValue, setInitValue] = useState({});

    const [deletePermissionIds, setDeletePermissionIds] = useState([]);

    const [permissionsData, setPermissionData] = useState([]);

    const colLength = [...Array(7)]

    const handlePermissionChange = (setFieldValue, value, name, submod, index) => {
        setFieldValue(name, value)

        if (submod) {
            const permissionId = getPermissionId(formatToSnakeCase(submod), type[index], permissionsData)

            setDeletePermission(value, permissionId);
        }


        menus.map((menu) => {
            if (menu.label == submod) {
                menu.subMenus.map((subMenu) => {
                    setFieldValue(formatName(subMenu.name, index), value)

                    const permissionId = getPermissionId(formatToSnakeCase(subMenu.name), type[index], permissionsData)

                    setDeletePermission(value, permissionId);
                });

                const permissionId = getPermissionId(formatToSnakeCase(menu.label), type[index], permissionsData)

                setDeletePermission(value, permissionId);
            }
        })

        if (index == 0 && !value) {
            menus.map((menu) => {
                if (menu.label == submod) {
                    type.map((action, actionIndex) => {
                        setFieldValue(formatName(menu.label, actionIndex), value)

                        const permissionId = getPermissionId(formatToSnakeCase(menu.label), action, permissionsData)

                        setDeletePermission(value, permissionId);
                    })

                    menu.subMenus.map((subMenu) => {
                        type.map((action, actionIndex) => {
                            setFieldValue(formatName(subMenu.name, actionIndex), value)

                            const permissionId = getPermissionId(formatToSnakeCase(subMenu.name), action, permissionsData)

                            setDeletePermission(value, permissionId);
                        })
                    })
                } else {
                    menu.subMenus.map((subMenu) => {
                        if (subMenu.name == submod) {
                            type.map((action, actionIndex) => {
                                setFieldValue(formatName(subMenu.name, actionIndex), value)

                                const permissionId = getPermissionId(formatToSnakeCase(subMenu.name), action, permissionsData)

                                setDeletePermission(value, permissionId);
                            })
                        }
                    })
                }
            })
        }
    }

    const setDeletePermission = (value, permissionId) => {
        if (!value && !deletePermissionIds.includes(permissionId) && permissionId) {
            setDeletePermissionIds(permissions => [...permissions, permissionId]);
        }
    }

    const cbRow = (submod, formik) => {
        const { values, setFieldValue } = formik

        return (
            <>
                {
                    colLength.map((a, index) => {
                        const name = formatName(submod, index);

                        return (
                            <td key={index} style={{
                                paddingTop: '10px',
                                paddingBottom: '5px'
                            }}>
                                <input
                                    type="checkbox"
                                    name={name}
                                    onChange={() => {
                                        handlePermissionChange(setFieldValue, !values[name], name, submod, index);
                                    }}
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                    checked={values[name]}
                                    disabled={index > 0 ? isDisable(submod, values) : false}
                                />
                            </td>
                        )
                    })
                }
            </>
        )
    }

    const salesSubMod = (arrSubMod, formik) => (
        <>
            {
                arrSubMod.map((s, sI) => (
                    <tr key={sI}>
                        <td></td>
                        <td>{s.label}</td>
                        {cbRow(s.name, formik)}
                    </tr>
                ))
            }
        </>
    )

    const formatName = (name, index) => {
        return `${name.replace(' ', '_').toLowerCase()}_${type[index]}`;
    }

    const formatToSnakeCase = (name) => {
        return `${name.replace(' ', '_').toLowerCase()}`;
    }

    const isDisable = (menu, values) => {
        return !values[formatName(menu, 0)];
    }

    const getPermissionStatus = (menu, type, permissions) => {
        return permissions.findIndex(permission => permission.menu === formatToSnakeCase(menu) && permission.type === type) >= 0
            ? true
            : false;
    }

    const getPermissionId = (menu, type, permissions) => {
        const index = permissions.findIndex(permission => permission.menu === formatToSnakeCase(menu) && permission.type === type);

        if (index >= 0) {
            return permissions[index].id;
        }
    }

    const formOpts = {
        initialValues: initValue,
        validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const cleanVal = {
                user_category_name: values.user_category_name
            }

            let result = await dispatch(updateUsercategory(idEdit, cleanVal))

            let data = {
                permissions: [],
            }

            menus.map((menu) => {
                type.map((action, index) => {
                    if (values[formatName(menu.label, index)]) {
                        data.permissions.push({
                            user_category_id: idEdit,
                            type: action,
                            menu: formatToSnakeCase(menu.label),
                        });
                    }
                })

                menu.subMenus.map((submenu) => {
                    type.map((action, index) => {
                        if (values[formatName(submenu.name, index)]) {
                            data.permissions.push({
                                user_category_id: idEdit,
                                type: action,
                                menu: formatToSnakeCase(submenu.name),
                            });
                        }
                    })
                })
            })

            if (deletePermissionIds.length > 0) {
                await dispatch(deletePermission(deletePermissionIds));
            }

            if (data.permissions.length > 0 && result.data.id) {
                await dispatch(addUsercategoryPermission(data));
            }

            if (result.status) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }

    useEffect(() => {
        let data = {
            ...dataEdit
        };

        const getPermissions = async () => {
            const permissions = (await dispatch(getPermission({ user_category_id: idEdit }))).data;

            setPermissionData(permissions);

            menus.map((menu) => {
                type.map((action, index) => {
                    const mainKeyName = formatName(menu.label, index);

                    Object.assign(data, {
                        [mainKeyName]: getPermissionStatus(menu.label, action, permissions),
                    })
                });

                menu.subMenus.map((subMenu) => {
                    type.map((action, index) => {
                        const keyName = formatName(subMenu.name, index);

                        Object.assign(data, {
                            [keyName]: getPermissionStatus(subMenu.name, action, permissions),
                        })
                    });
                })
            });
        }

        getPermissions().then(() => {
            setInitValue(data);
            setLoading(false);
        });
    }, []);

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusUsercategory())} option={{ type: 'error', message: error }} />
            }

            {
                !loading &&
                <Formik {...formOpts}>
                    {(formik) => {
                        const { isSubmitting, validateForm } = formik;

                        return (
                            <Form className="form-member">
                                <FieldsGroup fields={fieldGroup} />
                                <table className="table-usercategory">
                                    <thead>
                                        <tr>
                                            <th>Module</th>
                                            <th>Sub Module</th>
                                            <th>Read</th>
                                            <th>Create</th>
                                            <th>Update</th>
                                            <th>Approval</th>
                                            <th>Delete</th>
                                            <th>Print</th>
                                            <th>Export</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            menus.map((menu) => (
                                                <>
                                                    <tr>
                                                        <td>{menu.label}</td>
                                                        <td></td>
                                                        {cbRow(menu.label, formik)}
                                                    </tr>

                                                    {salesSubMod(menu.subMenus, formik)}
                                                </>
                                            ))
                                        }
                                    </tbody>
                                </table>

                                <div className="fields-group-container buttons">
                                    <Button
                                        type="submit"
                                        style="OrangeMdWide"
                                        label={isSubmitting ? 'Saving' : 'Save'}
                                        icon={isSubmitting ? 'spinner' : null}
                                        disabled={isSubmitting}
                                        onClick={
                                            () => {
                                                validateForm().then((response) => {
                                                    if (Object.keys(response).length > 0) {
                                                        scrollToTop();
                                                    }
                                                });
                                            }}
                                    />
                                    <button disabled={isSubmitting} onClick={goToIndex} type="button" className="btn btn-md btn-wide">Cancel</button>
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            }

            {
                loading && <LoadingContent />
            }
        </>
    )
}

export default FormEdit