import React, { useState, useEffect } from 'react'
import { Formik, Field, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { scrollToTop } from '@/Helper'
import { resetStatusVariant, updateVariant } from 'Reduxes/inventory/configuration/variant/variantActions'
import Alert from 'Comps/elements/Alert'
import Input from 'Elements/Input';
import FieldsContainer from 'Elements/FieldsContainer'
import FormButton from 'Elements/FormButton'
import InputChip from 'Elements/InputChip';
import SelectData from 'Elements/SelectData';

const FormEdit = (props) => {

    const { goToIndex } = props;
    const dispatch = useDispatch();
    const { data, error, idEdit } = useSelector(state => state.variantReducer)
    const dataEdit = data.filter((a, b) => a.id === idEdit)[0]
    const [variant, setVariant] = useState()
    const [deletedVariant, setDeletedVariant] = useState()

    useEffect(() => {
        const variantVal = dataEdit.variant_value
        const deleteVariant = variantVal.filter(value => variant && !variant.some(val => value.value === val.value.trim()));
        setDeletedVariant(deleteVariant)
    }, [variant])
    const formOpts = {
        initialValues: {
            ...dataEdit,
            // status: dataEdit.status ? 'true' : 'false',
            status: 'false',
            variant_value: dataEdit.variant_value && dataEdit.variant_value.map(x => x.value).join(', ')
        },
        // validationSchema,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            // const types = values.variant_value.map(a => ({ value: a }));
            let types = [];
            // pairing existing 
            // {id: x , value : 'aaa'}
            // {id: null , value : 'aaa'}
            console.log(values.variant_value)
            values.variant_value.forEach((variant, index) => {
                const variantValue = variant.trim()
                const isFound = dataEdit.variant_value.find(x => x.value.trim() === variantValue);
                if (isFound) {
                    types.push({ id: isFound.variant_type_id, value: variantValue })
                } else {
                    types.push({ ...(deletedVariant.length > 0 ? { id: deletedVariant.pop().variant_type_id } : {}), value: variantValue })
                }
            });

            const cleanVal = {
                "code": values.code,
                "name": values.name,
                "status": values.status === 'true',
                types
            }
            // console.log(cleanVal)
            // return;
            let result = await dispatch(updateVariant(idEdit, cleanVal))

            if (result) {
                resetForm();
                goToIndex();
            }

            setSubmitting(false)
            scrollToTop()
        }
    }

    return (
        <>
            {
                error && <Alert hide={() => dispatch(resetStatusVariant())} option={{ type: 'error', message: error }} />
            }
            <Formik {...formOpts} enableReinitialize={true}>
                {(formik) => {
                    const { isSubmitting, initialValues } = formik;
                    return (
                        <Form className="form-layout-1">
                            <FieldsContainer title="Variant Information">
                                <Input name="code" label="Code" />
                                <Input name="name" label="Name" />
                                <SelectData
                                    name="status"
                                    label="Status"
                                    value={dataEdit['status'] ? 'true' : 'false'}
                                    options={[
                                        { value: 'true', label: 'active' },
                                        { value: 'false', label: 'inactive' },
                                    ]}
                                />
                                <InputChip
                                    name="variant_value"
                                    col="3"
                                    label="Variant Size"
                                    handleChangeProp={({ values }) => setVariant(values)}
                                />
                            </FieldsContainer>
                            <br />
                            <FormButton isSubmitting={isSubmitting} showTable={goToIndex} />
                        </Form>
                    )
                }}

            </Formik>
        </>
    )
}

export default FormEdit