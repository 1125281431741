import { handleReq, reqGet, reqPatch, reqPost, reqDel, hostUrl, handleRequest } from 'Configs/request'

const type = 'DELIVERY'

export const DELIVERY_SET = 'DELIVERY_SET'
export const DELIVERY_ERROR = 'DELIVERY_ERROR'
export const DELIVERY_ADD = 'DELIVERY_ADD'
export const DELIVERY_SHOW = 'DELIVERY_SHOW'
export const DELIVERY_EDIT = 'DELIVERY_EDIT'
export const DELIVERY_UPDATE = 'DELIVERY_UPDATE'
export const DELIVERY_DELETE = 'DELIVERY_DELETE'
export const DELIVERY_LOADING = 'DELIVERY_LOADING'
export const DELIVERY_RESET = 'DELIVERY_RESET'
export const DELIVERY_CONFIRM = 'DELIVERY_CONFIRM'
export const DELIVERY_STOP_LOADING = 'DELIVERY_STOP_LOADING'



export const addDelivery = data => async (dispatch, getState) => {
    // data.is_active = data.is_active === 'true'

    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/product_shipment`, data),
        next: (payload) => dispatch({
            type: DELIVERY_ADD,
            payload
        })
    })
}
export const loadDelivery = (params = {}) => async (dispatch, getState) => {
    if (Object.keys(params)?.length > 0) {
        handleReq({
            type,
            dispatch,
            body: () => reqGet(`${hostUrl}/api/product_shipment?page=${params.page}&limit=${params.limit}`),
            next: (payload) => dispatch({ type: DELIVERY_SET, payload })
        })    
    } else {
        handleReq({
            type,
            dispatch,
            body: () => reqGet(`${hostUrl}/api/product_shipment`),
            next: (payload) => dispatch({ type: DELIVERY_SET, payload })
        })
    }
}
export const updateDelivery = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/product_shipment/${idEdit}`, data),
        next: (payload) => dispatch({ type: DELIVERY_UPDATE, payload })
    })
}
export const setEditDelivery = idEdit => {
    return {
        type: DELIVERY_EDIT,
        payload: idEdit,
    }
}
export const showDelivery = idx => {
    return {
        type: DELIVERY_SHOW,
        payload: idx
    }
}

export const getDeliveryById = idx =>async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/product_shipment/${idx}`),
    })
}


export const deleteDelivery = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/product_shipment/`, id),
        // body: () => Promise.resolve({ status: 'success' }),
        next: () => dispatch({ type: DELIVERY_DELETE, payload: id })
    })
}
export const resetStatusDelivery = () => {
    return {
        type: DELIVERY_RESET
    }
}

export const updateStatus = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/product_shipment/${id}/${type}`),
        next: () => dispatch({ type: DELIVERY_CONFIRM, payload: { type, id } })
    })
}

export const updateDeliveryPartial = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/product_shipment/${idEdit}/partial`, data),
        next: (payload) => dispatch({ type: DELIVERY_UPDATE, payload })
    })
}



/*
export const updateMember = (idEdit, data) => async (dispatch, getState) => {

    data.is_active = data.is_active === 'true'
    data.member_social = formatSocmed(data)

    return handleReq(
        dispatch,
        () => reqPatch(`${hostUrl}/api/member/${idEdit}`, data),
        (payload) => dispatch({
            type: CONTACT_UPDATE,
            payload
        })
    )
}
 */