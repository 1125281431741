import React, { useState, useEffect } from 'react'
import { Field, ErrorMessage } from 'formik';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';


const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        display:'block',
    },
    coba: {
        borderRadius: '3px',
        border: '1px solid #ddd',
        display: 'block',
        width: '100%',
        overflow: 'hidden',
        height: '42px',
        '& .MuiSelect-select': {
            padding: 0,
            height: '100%',
            display: 'grid',
            alignContent: 'center',
            boxSizing: 'border-box',
            paddingLeft: '16px',
            color: '#555',
            fontSize: '13px'
        }
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        // marginTop: theme.spacing(3),
    },
}));

export default function SelectMulti(props) {

    const classes = useStyles();
    const { name, label, value, options, col, placeholder } = props;

    return (  
        <div className={`field-container selectmulti ${col ? `span-${col}` : ``}`}>
            <label htmlFor={name}>{label}</label>
            <Field name={name} value={value}>
                {(formik) => {
                    
                    let { form, field } = formik;
                    const { setFieldValue } = form;
                    const { value, name } = field;

                    return (
                        <FormControl className={classes.formControl}>
                            <Select
                                name={name}
                                labelId="demo-mutiple-checkbox-label"
                                id="demo-mutiple-checkbox"
                                multiple
                                value={value}
                                onChange={(e)=> {
                                    setFieldValue(name, e.target.value)
                                }}
                                input={<Input />}
                                className={classes.coba}
                                renderValue={(selected) => {
                                    return selected ? selected.map(x => x.split('|')[1]).join(', ') : ''
                                }}
                            >
                                {options.map(({label, value: valueOpt}) => (
                                    <MenuItem key={valueOpt} value={valueOpt+'|'+label}> 
                                        <Checkbox checked={value.indexOf(valueOpt + '|' + label) > -1} />
                                        <ListItemText primary={label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )
                }}
            </Field>
            <ErrorMessage name={name} component="div" className="errMsg" />
        </div>
    )
}
/* 

<select {...field}
    onChange={(v) => {
        let newVal = v.currentTarget.value;
        setFieldValue(name, v.currentTarget.value)

        if (props.handleChange) {
            props.handleChange(newVal, form)
        }
    }}
>
    {
        options.map((opt, optI) => (
            <option key={opt.value} value={opt.value}>{opt.label}</option>
        ))
    }
</select>


 */