import { handleReq, handleRequest, reqGet, reqPatch, reqPost, reqDel, hostUrl } from 'Configs/request'

const type = "ACQUISITION"

export const ACQUISITION_SET = 'ACQUISITION_SET'
export const ACQUISITION_ERROR = 'ACQUISITION_ERROR'
export const ACQUISITION_ADD = 'ACQUISITION_ADD'
export const ACQUISITION_SHOW = 'ACQUISITION_SHOW'
export const ACQUISITION_EDIT = 'ACQUISITION_EDIT'
export const ACQUISITION_UPDATE = 'ACQUISITION_UPDATE'
export const ACQUISITION_DELETE = 'ACQUISITION_DELETE'
export const ACQUISITION_LOADING = 'ACQUISITION_LOADING'
export const ACQUISITION_RESET = 'ACQUISITION_RESET'
export const ACQUISITION_CONFIRM = 'ACQUISITION_CONFIRM'
export const ACQUISITION_STOP_LOADING = 'ACQUISITION_STOP_LOADING'

export const loadAcquisition = (params = {}) => async (dispatch, getState) => {
    if (Object.keys(params)?.length > 0) {
        return handleRequest({
            type,
            dispatch,
            body: () => reqGet(`${hostUrl}/api/product_receivement?page=${params.page}&limit=${params.limit}`),
            next: (payload) => dispatch({ type: ACQUISITION_SET, payload })
        })    
    }
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/product_receivement`),
        next: (payload) => dispatch({ type: ACQUISITION_SET, payload })
    })
}
export const addAcquisition = data => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPost(`${hostUrl}/api/product_receivement`, data),
        next: (payload) => dispatch({ type: ACQUISITION_ADD, payload })
    })
}
export const updateAcquisition = (idEdit, data) => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/product_receivement/${idEdit}`, data),
        next: (payload) => dispatch({ type: ACQUISITION_UPDATE, payload })
    })
}
export const deleteAcquisition = id => async (dispatch, getState) => {
    return handleReq({
        type,
        dispatch,
        body: () => reqDel(`${hostUrl}/api/product_receivement/`, id),
        next: () => dispatch({ type: ACQUISITION_DELETE, payload: id })
    })
}
export const showAcquisition = idx => {
    return {
        type: ACQUISITION_SHOW,
        payload: idx
    }
}

export const getAquisitionById = idx => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqGet(`${hostUrl}/api/product_receivement/${idx}`),
    })
}

export const setEditAcquisition = idEdit => {
    return {
        type: ACQUISITION_EDIT,
        payload: idEdit,
        loading: false
    }
}
export const resetStatusAcquisition = () => {
    return {
        type: ACQUISITION_RESET
    }
}
export const updateStatus = (id, type) => async (dispatch, getState) => {
    return handleRequest({
        type,
        dispatch,
        body: () => reqPatch(`${hostUrl}/api/product_receivement/${id}/${type}`),
        next: () => dispatch({ type: ACQUISITION_CONFIRM, payload: { type, id } })
    })
}
export const acquisitionTemp = (id, type) => {
    return {
        type: ACQUISITION_CONFIRM,
        payload: { id, type },
    }
}