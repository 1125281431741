import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { tableOpts, globalTableStyle } from 'Configs/table'
import VisibilityIcon from '@material-ui/icons/Visibility';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from 'Elements/Label'
import Alert from 'Comps/elements/Alert'
import Table from 'Elements/Table'
import TableNoData from 'Comps/elements/TableNoData'
import { deleteVariant, resetStatusVariant } from 'Reduxes/inventory/configuration/variant/variantActions'
import LoadingContent from 'Elements/LoadingContent';
import Permission from '../../../../comps/permission/Permission'


export default function TableData(props) {

    const { setContentActiveFormEdit, deleteVariantProps, setContentActiveDetail } = props
    const { data, loading, error, success } = useSelector(state => state.variantReducer);
    const classes = globalTableStyle();
    const dispatch = useDispatch();

    const table = tableOpts({
        data,
        colIdPosition: 0,
        filter:false,
        noPrint:true,
        filename: "Variant_Configuration_Data",
        table: {
            columns: [
                {
                    name: 'id',
                    options: {
                        display: false,
                        viewColumns: false
                    }
                },
                {
                    name: 'no',
                    label: 'No.',
                    options: {
                        filter: false,
                        print: true,
                        customBodyRender: (value, tableMeta) => {
                            return tableMeta.rowIndex + 1
                        }
                    }
                  },
                { name: "code", label: 'Code' },
                { name: "name", label: 'Name' },
                {
                    name: "variant_value",
                    label: 'Variant',
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <div className="td-variant">
                                    {
                                        Array.isArray(value) && value.map(x => <span key={x.value}>{x.value}</span>)
                                    }
                                </div>
                            )
                        }
                    }
                },
                {
                    name: "status",
                    label: 'Status',
                    options: {
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Label type={value ? 'success' : 'danger'} text={value ? 'active' : 'Inactive'} />
                            )
                        }
                    }
                },
                {
                    name: "id",
                    label: 'Actions',
                    options: {
                        setCellHeaderProps: () => ({
                            style: {
                              whiteSpace: "nowrap",
                              position: "sticky",
                              right: 0,
                              zIndex: 51
                            }
                          }),
                          setCellProps: () => ({
                           
                            style: {
                              whiteSpace: "nowrap",
                              position: "sticky",
                              right: "0",
                              background: "white",
                              zIndex: 51
                            }
                          }),
                        customBodyRender: (value, tableMeta, updateValue) => {
                            const attrShow = {
                                className: 'row-action',
                                onClick: () => setContentActiveDetail(tableMeta.rowIndex)
                            }
                            const attrEdit = {
                                className: 'row-action',
                                onClick: () => setContentActiveFormEdit(value)
                            }
                            const attrDelete = {
                                className: 'row-action',
                                onClick: () => deleteVariantProps([value])
                            }

                            return (
                                <>
                                    <VisibilityIcon {...attrShow} />
                                    <Permission
                                        component={
                                            <EditIcon {...attrEdit} />
                                        }
                                        subMenu="variant"
                                        type="update"
                                    />
                                    <Permission
                                        component={
                                            <DeleteIcon {...attrDelete} />
                                        }
                                        subMenu="variant"
                                        type="delete"
                                    />
                                </>
                            );
                        }
                    }
                },
            ],
        },
        deleteAct: (ids) => {
            dispatch(deleteVariant(ids))
        }
    })


    return (
        <>
            {
                success &&
                <Alert hide={() => dispatch(resetStatusVariant())} option={{ type: 'success', message: success }} />
            }
            {
                error && <Alert hide={() => dispatch(resetStatusVariant())} option={{ type: 'error', message: error }} />
            }
            {
                loading ? <LoadingContent />
                    : (
                        data.length ?
                            <Table {...table} className={classes.table} />
                            : <TableNoData />
                    )
            }
        </>
    )
}

