import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { useDispatch, useSelector } from 'react-redux';
import LoadingContent from 'Elements/LoadingContent';
import { salesOrderGetById, resetStatusSalesOrder, updateStatusSalesOrder } from 'Reduxes/consignment/salesorder/salesOrderActions'
import Button from 'Elements/Button'
import DetailHeader from 'Layouts/detailheader/DetailHeader';
import Alert from 'Comps/elements/Alert'
import { money, dateDMY, datePost } from '@/Helper'
import Box from "@material-ui/core/Box";
import Permission from '../../../comps/permission/Permission';
import PrintProvider, { Print } from "react-easy-print";
import SignatureColumn from 'Elements/SignatureColumn'
import KopSurat from 'Elements/KopSurat'


function Detail(props) {
    const { showTable, rowId } = props;
    const { success, error } = useSelector(state => state.salesreturnReducer);
    const [data, setData] = useState(null)
    const dispatch = useDispatch();
    const [statusLoading, setStatusLoading] = useState(false)
    const detailHeader = data && {
        contact: data.contact.name,
        address: [data.contact.address, data.contact.city, data.contact.province, data.contact.country, data.contact.postal_code].filter(Boolean).join(', '),
        status: data.status,
        date: dateDMY(data.date),
        'shipment date': dateDMY(data.shipment_date),
    }
    const handleStatus = async (type) => {
        setStatusLoading(true);
        await dispatch(updateStatusSalesOrder(data.id, type))
        setData({
            ...data,
            status: type === 'approve' ? 'confirmed' : 'draft'
        })
        setStatusLoading(false);
    }
    const handleApprove = () => {
        handleStatus('approve')
    }
    const handleCancel = () => {
        handleStatus('cancel')
    }
    const ButtonStatus = () => {
        const btnProps = ({ type, label, style = "TileMd", icon = "" }) => {
            const modLabel = label || type;
            return {
                icon: statusLoading ? "spinner" : icon,
                disabled: statusLoading,
                label: statusLoading ? "Saving" : modLabel[0].toUpperCase() + modLabel.substring(1),
                style,
            }
        }

        return (
            <>
                <Button
                    disabled={statusLoading}
                    style='WhiteMd'
                    label="Go Back"
                    icon='arrow-left'
                    onClick={() => showTable()}
                />
                {
                    data.status === 'draft' &&
                    <Permission
                        component={
                            <Button {...btnProps({ label: 'Approve', type: 'approve' })} onClick={handleApprove} marginLeft="ml-10" />
                        }
                        subMenu="sales_order"
                        type="confirm"
                    ></Permission>
                }
                {
                    data.status === 'confirmed' &&
                    <Permission
                        component={
                            <Button {...btnProps({ label: 'Cancel', type: 'cancel', icon: 'undo' })} onClick={handleCancel} marginLeft="ml-10" />
                        }
                        subMenu="sales_order"
                        type="confirm"
                    ></Permission>
                }
                <Permission
                    component={
                        <Button
                            label="Print"
                            style="DarkblueMd"
                            marginLeft="ml-10"
                            icon="print"
                            onClick={() => window.print()}
                        />
                    }
                    subMenu="sales_order"
                    type="print"
                ></Permission>
            </>
        )
    }

    useEffect(() => {
        const fetchRow = async () => {
            const resp = await dispatch(salesOrderGetById(rowId))
            setData(resp.data)
        }
        fetchRow()
    }, [])

    // console.log(data)
    return (
        <>
            {
                !data ?
                    <LoadingContent /> :
                    <PrintProvider>

                        <Print single name="print-salesorder-detail-1" >
                            <div className="print-report zoom-70">
                                <KopSurat title="Sales Order Detail" nomor={`${data.code || 'N/a'}`} />

                                <ContentWrapper
                                    title="Sales Order Detail"
                                    subtitle={`Showing sales order with id ${data.code || 'N/a'}`}
                                    buttons={<ButtonStatus />}
                                >
                                    <div className="detail-page">
                                        {
                                            (success || error) &&
                                            <Alert hide={() => dispatch(resetStatusSalesOrder())} option={{ type: 'success', message: success || error }} />
                                        }

                                        <DetailHeader data={detailHeader} />

                                        {

                                            data.sales_order_products?.length > 0 &&
                                            <table className="table table-detail style-2">
                                                <thead>
                                                    <tr>
                                                        <th>No.</th>
                                                        <th>Code Product</th>
                                                        <th>Product</th>
                                                        <th>Price per Unit</th>
                                                        <th>Discount</th>
                                                        <th>Quantity</th>
                                                        <th>UoM</th>
                                                        <th>Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data.sales_order_products.map((singleProduct, singleProductIndex) => (
                                                            <tr key={singleProductIndex}>
                                                                <td>{singleProductIndex + 1}</td>
                                                                <td>{singleProduct.sku}</td>
                                                                <td>{singleProduct.variant ? `${singleProduct.name} ${singleProduct.product.color.name} - (${singleProduct.variant.value})` : singleProduct.name}</td>
                                                                <td>IDR. {money(singleProduct.price)}</td>
                                                                <td>IDR. {money(singleProduct.discount)}</td>
                                                                <td>{singleProduct.quantity}</td>
                                                                <td>{singleProduct.unit.name}</td>
                                                                <td>IDR. {money(singleProduct.total_price)}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                    <tr className="total">
                                                        <td colSpan="6" className="note" rowSpan="3">
                                                            <b>Notes : </b>
                                                            {data.note && <p>{data.note}</p>}
                                                        </td>
                                                        <td>Sub Total</td>
                                                        <td>IDR {money(data.sub_total)}</td>
                                                    </tr>
                                                    <tr className="total">
                                                        <td>Discount</td>
                                                        <td>IDR {money(data.total_discount)}</td>
                                                    </tr>
                                                    <tr className="total">
                                                        <td>Total</td>
                                                        <td>IDR {money(data.total)}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        }
                                        <Box>
                                            <p className="detail-note">Printed by: User 1, {data.print_count}x</p>
                                            <p className="detail-note">Created by: {data.created_by_contact_name}, {datePost(data.created_at)}</p>
                                            <p className="detail-note">Last edited by: {data.updated_by_contact_name || '-'}, {datePost(data.updated_at)}</p>
                                        </Box>

                                        <SignatureColumn typeContent={"ReceivedDelivered"} data={data} />

                                    </div>


                                </ContentWrapper>
                            </div>
                        </Print>
                    </PrintProvider>
            }
        </>
    )
}

export default Detail
