import {
    REPORT_ADD,
    REPORT_DELETE,
    REPORT_EDIT,
    REPORT_ERROR,
    REPORT_LOADING,
    REPORT_RESET,
    REPORT_SET,
    REPORT_SHOW,
} from './reportActions'

const initialState = {
    loading: false,
    data: [],
    error: null,
    success: null,
    idEdit: null,
    idShow: null,
}

const reportReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case REPORT_SET:
            return {
                ...state,
                data: payload,
                error: null,
                loading: false,
            }
        case REPORT_ADD:
            payload.is_active = payload.is_active === 1
            return {
                ...state,
                data: [payload, ...state.data],
                loading: false,
                error: null,
                success: 'New sales order has been added successfully.'
            }
        case REPORT_DELETE:
            return {
                ...state,
                data: state.data.filter(deletedData => deletedData.id !== payload),
                loading: false,
                success: 'Data has been deleted successfully',
            }
        case REPORT_EDIT:
            return {
                ...state,
                idEdit: payload,
                error: null,
                success: null,
            }
        case REPORT_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        case REPORT_LOADING:
            return {
                ...state,
                loading: true
            }
        case REPORT_RESET:
            return {
                ...state,
                error: null,
                success: null,
            }
        case REPORT_SHOW:
            return {
                ...state,
                idShow: payload
            }
        default:
            return state
    }
}

export default reportReducer