import React from 'react'
import styled from 'styled-components'

function TextHelper(props) {
    const { message } = props;
    return (
        <P>{message}</P>
    )
}

const P = styled.p`
    font-size: 13px;
    margin:16px 0;
    color: #777;
    font-style: italic;
    font-weight: 600;
`

export default TextHelper
