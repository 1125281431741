import React, { useState, useEffect } from 'react'
import ContentWrapper from 'Comps/container/ContentWrapper'
import { showReject, updateStatusReject } from 'Reduxes/inventory/reject/rejectActions'
import { useDispatch } from 'react-redux';
import PrintProvider, { Print } from "react-easy-print";
import { money, globalMessage } from '@/Helper'
import LoadingContent from 'Elements/LoadingContent'
import TextHelper from 'Elements/TextHelper';
import DetailHeader from 'Layouts/detailheader/DetailHeader'
import Button from 'Elements/Button'
import KopSurat from 'Elements/KopSurat'
import Permission from '../../../comps/permission/Permission';

function Detail(props) {
    const { rowId, showTable } = props;
    const [data, setData] = useState(null)
    const [productTable, setProductTable] = useState([]) // utk table
    const dispatch = useDispatch();
    const [statusButton, setStatusButton] = useState({
        loading: false,
        type: '',
    })

    useEffect(() => {
        const get = async () => {
            const resp = await dispatch(showReject(rowId));
            const fixFormat = [];
            resp.data.products.map((product) => {
                // cari product
                const found = fixFormat.findIndex(item => item.id === product.id);
                if (found < 0) { //ga nemu
                    fixFormat.push(product);
                } else {
                    fixFormat[found] = {
                        ...fixFormat[found],
                        product_variant_item: [
                            ...fixFormat[found].product_variant_item,
                            ...product['product_variant_item']
                        ]
                    }
                }
            })
            setProductTable(fixFormat)
            setData(resp.data)
        }
        get()
    }, [])

    const detailHeader = !data ? {} : {
        Branch: data.branch_name,
        date: data.date,
        reference: data.reference,
        status: data.status,
        'Created By': data?.created_by_contact_name,
    }
    /* status button */
    const handleStatus = async (type) => {
        setStatusButton({
            type,
            loading: true,
        })
        const resp = await dispatch(updateStatusReject(data.id, type));
        if (resp.status) {
            // update data ke local state
            setData({
                ...data,
                status: type
            })
        } else {
            globalMessage(resp.data.errors, 'warning');
        }
        setStatusButton({
            type: null,
            loading: false,
        });
    }

    const ButtonStatus = () => {
        const btnProps = (args) => {
            const { type, label, style = "TileMd", icon = "" } = args;
            const modLabel = label || type;
            const loading = statusButton.loading && type === statusButton.type;
            return {
                icon: loading ? "spinner" : icon,
                disabled: statusButton.loading,
                label: loading ? "Saving" : modLabel[0].toUpperCase() + modLabel.substring(1),
                style,
                onClick: () => handleStatus(type)
            }
        }
        return (
            <>
                <Button
                    disabled={statusButton.loading}
                    style='WhiteMd'
                    label="Go Back"
                    icon='arrow-left'
                    onClick={() => showTable()}
                />
                <Permission
                    component={
                        <>
                            {
                                data.status === 'draft' &&
                                <Button {...btnProps({ type: 'confirmed' })} marginLeft="ml-10" />
                            }
                        </>
                    }
                    subMenu="reject"
                    type="create"
                />
                <Permission
                    component={
                        <>
                            {
                                data.status === 'confirmed' &&
                                <>
                                    <Button {...btnProps({ type: 'draft', label: 'cancel', icon: 'undo' })} marginLeft="ml-10" style='WhiteMd' />
                                    <Button {...btnProps({ type: 'checked', })} marginLeft="ml-10" />
                                </>
                            }
                            {
                                data.status === 'checked' &&
                                <Button {...btnProps({ type: 'confirmed', label: 'Confirm', icon: 'undo' })} marginLeft="ml-10" />
                            }
                        </>
                    }
                    subMenu="reject"
                    type="confirm"
                />
                <Permission
                    component={
                        <Button
                            label="Print"
                            style="DarkblueMd"
                            marginLeft="ml-10"
                            icon="print"
                            onClick={() => window.print()}
                        />
                    }
                    subMenu="reject"
                    type="print"
                />
            </>
        )
    }

    return (
        <PrintProvider>
            <Print single name="print-reject-detail-1" >
                {
                    !data ?
                        <LoadingContent /> :
                        <div className="print-report zoom-70">
                            <KopSurat title="Rejects" nomor={`${data.code || 'N/a'}`} />
                            <ContentWrapper
                                title="Rejects Detail"
                                subtitle={`Showing reject product with id ${data.code || 'N/a'}`}
                                buttons={<ButtonStatus />}
                            >
                                <div className="detail-page">
                                    <DetailHeader data={detailHeader} />
                                    <div className="table-container">
                                        {
                                            data.products && data.products.length > 0 && (
                                                <table className="table table-detail style-2">
                                                    <thead>
                                                        <tr>
                                                            <th>No</th>
                                                            <th>ID</th>
                                                            <th>Product Name</th>
                                                            <th>Sales price</th>
                                                            <th>Qty</th>
                                                            <th>UoM</th>
                                                            <th>Reason</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            data.products.map((x, y) => {
                                                                return (
                                                                    <tr key={y}>
                                                                        <td>{y + 1}</td>
                                                                        <td>{x.product_variant_item.length ? x.product_variant_item.map(prod => prod.barcode) : x.code}</td>
                                                                        <td>{x.product_variant_item.length ? x.product_variant_item.map(prod => `${x.product_name} - ${x.color_name} (${prod.variant_type_value})`) : x.name}</td>
                                                                        <td>IDR {money(x.sales_price)}</td>
                                                                        {/* <td className="width-as-content">
                                                                        <div className="td-variant">
                                                                            {
                                                                                x.product_variant_item.length ?
                                                                                    x.product_variant_item.map(z => <span>{z.variant_type_value} : {z.qty}</span>)
                                                                                    : x.qty
                                                                            }
                                                                        </div>
                                                                    </td> */}
                                                                        <td>{x.product_variant_item.length ? x.product_variant_item.map(prod => prod.qty) : x.qty}</td>
                                                                        <td>{x.uom_name || '-'}</td>
                                                                        <td>{x.product_variant_item[0]?.reject_reason || x.reject_reason}</td>
                                                                    </tr>
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            )
                                        }
                                    </div>
                                    <TextHelper message={`*Note : ${data.notes}`} />
                                </div>
                            </ContentWrapper>
                        </div>
                }
            </Print>
        </PrintProvider>
    )
}

export default Detail
