import React, { useState } from 'react'
import Button from 'Elements/Button'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

const FormButton = (props) => {
    const { isSubmitting, hasDraft, disabled, showTable, label } = props;
    const { setFieldValue, submitForm, setSubmitting } = useFormikContext();
    const [submitAsDraft, setSubmitAsDraft] = useState(false);

    const handleSubmit = async (type) => {

        if (type === 'draft') {
            setFieldValue('status', 'draft');
            setSubmitAsDraft(true)
        } else {
            if (hasDraft) {
                setFieldValue('status', 'paid');
            }
        }

        setSubmitting(true);
        await submitForm();
    }

    return (
        <div className="form-button">
            <Button
                type="button"
                style="OrangeMdWide"
                label={isSubmitting && !submitAsDraft ? 'Saving' : label || 'Save Data'}
                icon={isSubmitting && !submitAsDraft ? 'spinner' : 'check-circle'}
                disabled={disabled || isSubmitting}
                onClick={handleSubmit}
            />
            {
                hasDraft &&
                <Button
                    type="button"
                    style="Md"
                    label={isSubmitting && submitAsDraft ? 'Saving...' : 'Draft'}
                    icon={isSubmitting && submitAsDraft ? 'spinner' : ''}
                    disabled={disabled || isSubmitting}
                    onClick={() => handleSubmit('draft')}
                />
            }
            <button
                disabled={isSubmitting}
                onClick={showTable}
                type="button"
                className="btn btn-md">Cancel</button>
        </div>
    )
}

FormButton.propTypes = {
    isSubmitting: PropTypes.bool.isRequired,
    showTable: PropTypes.func.isRequired,
}

export default FormButton
