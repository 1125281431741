import React from 'react'
import { Field, ErrorMessage } from 'formik';
import Button from 'Elements/Button'
import Input from 'Elements/Input'
import Select from 'Elements/Select'
import Fields from 'Elements/Fields'

function FieldsGroup(props) {
    const { fields } = props;
 
    return (
        <>
            {
                fields.map((f, fI) => (
                    <div key={fI} className={"fields-group-container " + f.name.replace(' ', '-').toLowerCase()}>
                        <p className="field-group-title">{f.name}</p>
                        <Fields fields={f.fields} />
                    </div>
                ))
            }
        </>
    )
}

export default FieldsGroup