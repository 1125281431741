import React, { useState, useEffect } from 'react'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { makeStyles } from '@material-ui/core/styles';
import Select from 'Elements/select/Select';
import Datepicker from 'Elements/Datepicker';
import Multiselect from 'Elements/multiselect/Multiselect';
import InputNum from 'Elements/inputnum/InputNum';
import { filterTheme } from 'Configs/report'
import { getLocalStorageAuth } from '@/Helper'
import { Formik, Form } from 'formik';
import SelectAsync from 'Elements/SelectAsync';

const useStyles = makeStyles((theme) => (filterTheme(theme)))


export default function FilterGeneral({ setFilter, getData, type }) {

    const classes = useStyles();
    const [showExtraFilter, setShowExtraFilter] = useState(false);
    const [filters, setFilters] = useState({});
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const isSuperadmin = getLocalStorageAuth().user_category_id === 1 || getLocalStorageAuth().user_category_id === 2
    const toggleExtraFilter = event => {
        event.preventDefault()
        setShowExtraFilter(state => !state);
    }
    const [productName, setProductName] = useState(null)


    const handleChange = (param) => {
        if (param.props.name === 'start_date' || param.props.name === 'end_date') { // start date, end date

            if (param.value === 'Invalid date') { // jika invalida hapus key start date or end date
                setFilters(state => {
                    let { [param.props.name]: remove, ...rest } = state;
                    return { ...rest }
                })
                return;
            }

            // untuk minDate nya end_date
            if (param.props.name === 'start_date') {
                setStartDate(param.value);
            }

            setFilters(state => {
                return {
                    ...state,
                    [param.props.name]: param.value
                }
            })
        } else if (param.props.name === 'start_price' || param.props.name === 'end_price') {
            setFilters(state => {
                return {
                    ...state,
                    [param.props.name]: param.value
                }
            })
        } else if (param.props.name === 'product_id') {
            setFilters(state => {
                return {
                    ...state,
                    [param.props.name]: param.selected.value
                }
            })

        } else if (Array.isArray(param.value)) { // saat ini jika array diasumsikan berarti dari selectbox [{label: 1, value: 1}] *case lain  jika array juga belum di handle

            if (param.isAll) { // jika ini dari multi select menandakan semua opsi dipilih jadi tidak perlu kirim apa2 ke server
                setFilters(state => {
                    let { [param.props.name]: remove, ...rest } = state;
                    return { ...rest }
                })
            } else {
                setFilters(state => {
                    return {
                        ...state,
                        [param.props.name]: param.value.map(x => x.value)
                    }
                })
            }


        }
    }

    const [initialValues, setInitialValues] = useState({
        "product_id": null,
    })
    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, setValues, resetForm }) => {
            resetForm()
        }
    }

    const selectProduct = ({ row }) => {
        if (!row) {
            setProductName({
                label: 'Select Product',
                value: null
            })

            setFilters(prevState => {
                delete prevState.product_id

                return { ...prevState }
            })
        }

        if (row) {
            setFilters(prevState => ({
                ...prevState,
                product_id: row.id
            }))
        }
    }

    useEffect(() => {
        getData(filters)
    }, [filters])
    useEffect(() => {
        setFilters({})
    }, [showExtraFilter])
    return (
        <div className={classes.formextrafilter}>
            <a href="#" className={`${classes.toggler} ${showExtraFilter ? 'teal' : ''}`} onClick={toggleExtraFilter}>
                {showExtraFilter ? 'Hide' : 'Show'} Extra Filter
                {showExtraFilter ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </a>
            {
                showExtraFilter &&
                <div className={classes.formextrafiltercontainer}>
                    <div className={classes.formFilter}>
                        <div className={classes.daterangeCont}>
                            <Datepicker
                                handleChange={handleChange}
                                label="Start date"
                                name="start_date"
                                value="blank"
                            />
                            <Datepicker
                                {...(startDate ? { minDate: startDate } : {})}
                                name="end_date"
                                handleChange={handleChange}
                                label="end date"
                                value="blank"
                            />
                        </div>
                        {
                            type && type === 'product' &&
                            <Formik {...formOpts} enableReinitialize={true} >
                                <>
                                    <SelectAsync
                                        type="productName"
                                        name="product_id"
                                        cache={false}
                                        options='productName'
                                        value={productName}
                                        handleChange={(value) => selectProduct(value)}
                                        param={{ flat: false }}
                                        additionalPropsReport="SelectProduct"
                                        label="Select Product"
                                    />
                                </>
                            </Formik>
                        }
                        {
                            type && type === 'product' &&
                            <div className={classes.daterangeCont}>
                                <InputNum
                                    getData={handleChange}
                                    label="Subtotal Min"
                                    name="start_price"
                                />
                                <InputNum
                                    name="end_price"
                                    getData={handleChange}
                                    label="Subtotal Max"
                                />
                            </div>
                        }
                        <Multiselect
                            label="Transaction Type"
                            hasSelectAll
                            selectAllLabel='Total sales'
                            getData={handleChange}
                            name="category"
                            options={[
                                { label: 'Sales', value: 'sales' },
                                { label: 'Sales retur', value: 'return' },
                                { label: 'Sales cancel', value: 'cancel' },
                                { label: 'Sales refund', value: 'refund' }
                            ]}
                        />
                        <Multiselect name="promotion_id" getData={handleChange} hasSelectAll label="Promotion" options="promotion" />
                        {
                            isSuperadmin &&
                            <Multiselect name="branch_id" getData={handleChange} hasSelectAll label="Branch" options="branch" param={{ all: true }} />
                        }
                        <Multiselect
                            name="type"
                            getData={handleChange}
                            hasSelectAll
                            label="type"
                            options={[
                                { label: 'General', value: 'general' },
                                { label: 'Member', value: 'member' },
                                { label: 'VIP 1', value: 'vip' },
                            ]}
                        />
                        <Multiselect name="brand_id" getData={handleChange} hasSelectAll label="brand" options="brand" />
                        <Multiselect
                            name="status"
                            hasSelectAll
                            getData={handleChange}
                            label="status"
                            options={[
                                { label: 'Paid', value: 'paid' },
                                { label: 'Draft', value: 'draft' },
                            ]}
                        />

                        <Multiselect
                            name="product_type"
                            getData={handleChange}
                            hasSelectAll
                            label="Product Type"
                            options={[
                                { label: 'Product Sales', value: 'product_sales' },
                                { label: 'Product Consignment', value: 'consignment' },
                                { label: 'Product General', value: 'product_general' },
                                { label: 'Discontinue', value: 'discontinue' }
                            ]}
                        />

                        <Multiselect
                            label="Product Category"
                            hasSelectAll
                            getData={handleChange}
                            name="category_id"
                            options="category"
                        />

                    </div>

                </div>
            }
        </div>
    )
}
