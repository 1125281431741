import React, { useState } from 'react'
import { Field, ErrorMessage } from 'formik';
import { Checkbox, FormControlLabel } from '@material-ui/core';

export default function CheckBox(props) {

    const { name, label, disabled } = props;

    return (
        <div className="field-container">

            <Field name={name} >
                {({ field, form, meta }) => {
                    return (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color="primary"
                                    disabled={disabled}
                                    {...field}
                                    checked={field.value}
                                />
                            }
                            label={label}
                        />
                    )
                }}
            </Field>
            <ErrorMessage name={name} component="div" className="errMsg" />
        </div>
    )
}