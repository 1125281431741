import React, { useEffect, useState } from 'react'
import { money, sortData, handleSortState } from '@/Helper'
import ThSort from 'Elements/ThSort'
import { NoPrint } from "react-easy-print";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import NoReport from 'Elements/NoReport'
import KopSurat from 'Elements/KopSurat'
import SimpleBarHOC from '../../../../comps/elements/SimpleBarHOC';


export default function General({ data }) {

    const [rows, setRows] = useState([])
    const [variants, setVariants] = useState([])
    const [branches, setBranches] = useState([])
    const [longestVariant, setLongestVariant] = useState([])

    const [scroll, setScroll] = useState(true)
    const [variantSort, setVariantSort] = useState({})
    const [sort, setSort] = useState({})

    const sortColumn = (colName, variantIndex = 1000) => {
        if (variantIndex !== 1000) { // variamt sort
            setRows(prevState => {
                return [...sortData({ type: variantSort[colName].sort_type, column: colName, data: prevState })]
            })

            setVariantSort(prev => {
                return { ...handleSortState({ data: prev, column: colName }) }
            })
        } else {
            setRows(prevState => {
                return [...sortData({ type: sort[colName].sort_type, column: colName, data: prevState })]
            })

            setSort(prev => {
                return { ...handleSortState({ data: prev, column: colName }) }
            })
        }
    }
    const toggleScroll = () => {
        setScroll(!scroll)
    }

    useEffect(() => {

        let initRows = []
        let initBranches = []
        let initVariants = []
        let initLongestVariants = []
        let initVariantSort = {}
        let thBranchName = {}
        if (data?.length) {
            data.forEach(d => {
                d.branches.forEach((branch, branchIdx) => {
                    let isRegistered = initBranches.find(x => x.id === branch.id)
                    if (!isRegistered) {
                        initBranches.push(branch);
                        initVariants = branch.variants
                    }
                    branch.variants.forEach(variant => {
                        initLongestVariants = variant.types.length > initLongestVariants.length ? variant.types : initLongestVariants;
                    })
                })
            })

            initRows = data.map((d, dIdx) => {
                const variantProp = {}
                initBranches.forEach((branch, branchIdx) => {
                    longestVariant.map((lv, lvIdx) => {
                        let qty = 0;
                        let findBranch = data[dIdx].branches.find(x => x.name === branch.name);

                        if (findBranch) {
                            findBranch.variants.forEach(variant => {
                                qty += variant?.types?.[lvIdx]?.stock ?? 0;
                            })
                        }

                        variantProp[`variant${branchIdx}${lvIdx}`] = findBranch ? qty : 0;
                        initVariantSort[`variant${branchIdx}${lvIdx}`] = {
                            sort_type: null
                        }

                        if (longestVariant.length - 1 === lvIdx) {
                            variantProp[`stock${branchIdx}`] = findBranch?.stock ?? 0;
                            variantProp[`reject${branchIdx}`] = findBranch?.reject ?? 0;

                            initVariantSort[`stock${branchIdx}`] = {
                                sort_type: null
                            }
                            initVariantSort[`reject${branchIdx}`] = {
                                sort_type: null
                            }
                        }
                    })
                })

                return { ...d, ...variantProp }
            })

            setVariantSort(initVariantSort)


            initBranches.forEach((branch, branchIdx) => {
                thBranchName[`branchName${branchIdx}`] = {
                    sort: false,
                    label: branch.name,
                    colspan: initLongestVariants.length + 2,
                    className: 'cell-tc'
                }
            })

            setSort({
                no: {
                    label: 'no.',
                    has_sort: false,
                    rowspan: initVariants.length + 2,
                },
                code: {
                    label: 'code',
                    className: 'cell-width-as-content cell-highlight',
                    sort_type: null,
                    has_sort: true,
                    rowspan: initVariants.length + 2,
                },
                category: {
                    has_sort: true,
                    sort_type: null,
                    rowspan: initVariants.length + 2,
                    label: 'category'
                },
                name: {
                    has_sort: true,
                    sort_type: null,
                    className: 'cell-width-as-content cell-highlight',
                    rowspan: initVariants.length + 2,
                    label: 'name'
                },
                brand: {
                    has_sort: true,
                    sort_type: null,
                    rowspan: initVariants.length + 2,
                    label: 'brand'
                },
                sales_price: {
                    has_sort: true,
                    rowspan: initVariants.length + 2,
                    className: 'cell-width-as-content',
                    sort_type: null,
                    label: 'sales price'
                },
                ...thBranchName,
                uom_name: {
                    has_sort: true,
                    label: 'uom',
                    rowspan: initVariants.length + 2,
                    sort_type: null
                }
            })
            setBranches(initBranches)
            setVariants(initVariants)
            setLongestVariant(initLongestVariants)
            setRows(initRows)
        }

    }, [data, longestVariant])

    return !branches.length ? <NoReport /> : (
        <>
            <KopSurat fit />
            <p className="asdasd" style={{ marginBottom: '20px' }}>Stock Summary by Branch Report <span>Showing  report from all branches</span></p>

            <NoPrint force={true}>
                <div className="table-menu">
                    <div className="left">
                        <button onClick={toggleScroll}>{scroll ? 'Remove' : 'Show'} Scroll</button>
                    </div>
                    <div className="right"><p className="note">*Data currency shown in IDR (Indonesian Rupiah) </p></div>
                </div>
            </NoPrint>




            <SimpleBarHOC status={scroll}>
                <table width="100%" className="table-report inventory-stock">
                    <thead>
                        <tr className="sticky-variant-row-0">
                            {
                                Object.keys(sort).map((key, idx) => (
                                    <ThSort
                                        key={`th-${idx}`}
                                        label={sort[key].label}
                                        rowspan={sort[key].rowspan}
                                        colspan={sort[key].colspan}
                                        sort={sort[key].has_sort}
                                        handleClick={() => sortColumn(key)}
                                        className={`${sort[key].className}  ${sort[key].sort_type}`}
                                    />
                                ))
                            }
                        </tr>
                        <tr className="sticky-variant-row-1">
                            {
                                branches.map((branch, branchIdx) => {
                                    return (
                                        <React.Fragment key={`branch${branchIdx}`}>
                                            <th className="cell-tc" colSpan={longestVariant.length}>Variant</th>
                                            <ThSort
                                                label="Stock"
                                                sort={true}
                                                handleClick={() => sortColumn(`stock${branchIdx}`, branchIdx)}
                                                className={variantSort?.[`stock${branchIdx}`]?.sort_type}
                                            />
                                            <ThSort
                                                label="reject"
                                                sort={true}
                                                handleClick={() => sortColumn(`stock${branchIdx}`, branchIdx)}
                                                className={variantSort?.[`reject${branchIdx}`]?.sort_type}
                                            />
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tr>
                        {
                            variants.map((variant, variantIdx) => {
                                return (
                                    <tr key={`headvariant${variantIdx}`} className={`sticky-variant-row-${variantIdx + 2}`}>
                                        {
                                            branches.map((branch, branchIdx) => (
                                                <React.Fragment key={`headvariantth${branchIdx}`} >
                                                    {
                                                        longestVariant.map((lv, lvIdx) => (
                                                            <ThSort
                                                                key={`th-variant-${lvIdx}`}
                                                                label={variant?.types?.[lvIdx]?.label ?? '-'}
                                                                sort={typeof (variant?.types?.[lvIdx]?.label) !== 'undefined'}
                                                                handleClick={() => sortColumn(`variant${branchIdx}${lvIdx}`, lvIdx)}
                                                                className={variantSort?.[`variant-${lvIdx}`]?.sort_type}
                                                            />
                                                        ))
                                                    }
                                                    <th></th>
                                                    <th></th>
                                                </React.Fragment>
                                            ))
                                        }
                                    </tr>
                                )
                            })
                        }
                    </thead>
                    <tbody>
                        {
                            rows.map((d, dIdx) => {
                                return (
                                    <tr key={`tr${dIdx}`}>
                                        <td>{dIdx + 1}.</td>
                                        <td className="cell-width-as-content">{d.code}</td>
                                        <td>{d.category}</td>
                                        <td className="cell-width-as-content">{d.name}</td>
                                        <td>{d.brand}</td>
                                        <td>IDR {money(d.sales_price)}</td>
                                        {
                                            branches.map((branch, branchIdx) => (
                                                longestVariant.map((lv, lvIdx) => (
                                                    <React.Fragment key={`something${branchIdx}${lvIdx}`}>
                                                        <td>
                                                            {
                                                                d[`variant${branchIdx}${lvIdx}`]
                                                            }
                                                        </td>
                                                        {
                                                            longestVariant.length - 1 === lvIdx && (
                                                                <>
                                                                    <td>{d[`stock${branchIdx}`]}</td>
                                                                    <td>{d[`reject${branchIdx}`]}</td>
                                                                </>
                                                            )
                                                        }
                                                    </React.Fragment>
                                                )
                                                )
                                            ))
                                        }
                                        <td>{d.uom_name}</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </SimpleBarHOC>



        </>
    )
}