import React, { useEffect, useState } from 'react'
import Dashboard from 'Pages/Dashboard'
import Submodule from 'Comps/layouts/Submodule'
import { loadWarning } from 'Reduxes/inventory/warning/warningActions';
import { useDispatch } from 'react-redux';
import LoadingContent from 'Elements/LoadingContent'
import Products from 'Pages/inventory/products/Index'
import Acquisition from 'Pages/inventory/acquisition/Index'
import Delivery from 'Pages/inventory/delivery/Index'
import Reject from 'Pages/inventory/reject/Index'
import StockOpname from 'Pages/inventory/stockopname/Index'
import Configuration from 'Pages/inventory/configuration/Index'
import Report from 'Pages/inventory/report/Index'

export default function Inventory() {

    const dispatch = useDispatch()
    const [ready, setReady] = useState(false)
    const [subModules, setSubModules] = useState([
        {
            label: 'Products',
            component: Products,
            permissionName: 'product',
            permissionType: 'read',
        },
        {
            label: 'Receive',
            component: Acquisition,
            permissionName: 'receipts',
            permissionType: 'read',
        },
        {
            label: 'Delivery',
            component: Delivery,
            permissionName: 'delivery',
            permissionType: 'read',
        },
        {
            label: 'Reject',
            component: Reject,
            permissionName: 'reject',
            permissionType: 'read',
        },
        {
            label: 'Stock Opname',
            component: StockOpname,
            permissionName: 'stock_opname',
            permissionType: 'read',
        },
        {
            label: 'Configuration',
            component: Configuration,
            permissionName: 'configuration',
            permissionType: 'read',
        },
        {
            label: 'Report',
            component: Report,
            permissionName: 'report_inventory',
            permissionType: 'read',
        },

    ])

    useEffect(() => {
        const loadInventoryWarning = async () => {
            const req = await dispatch(loadWarning())
            if (req.status) {
                setSubModules(state => {
                    const newVal = state.map(row => {
                        const val = (x) => req.data[x].is_warning

                        if (row.label === 'Receive') {
                            row.warning = val('receivement');
                        } else if (row.label === 'Reject') {
                            row.warning = val('reject');
                        } else if (row.label === 'Delivery') {
                            row.warning = val('shipment');
                        } else if (row.label === 'Stock Opname') {
                            row.warning = val('stock_opname');
                        }
                        return row;
                    })
                    return newVal;
                })
            }
            setReady(true)
        }
        loadInventoryWarning();

    }, [])

    const pageTitle = {
        title: 'Inventory',
        description: 'Monitor business closely with product and inventory details',
    }

    const breadcrumb = [
        { label: 'Home' },
        { label: 'Contact' },
        { label: 'Member', active: true },
    ]

    return (
        <Dashboard pageTitle={pageTitle} breadcrumb={breadcrumb} >
            { ready ? <Submodule buttonAdd subModules={subModules} /> : <LoadingContent />}
        </Dashboard>
    )
}
