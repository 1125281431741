import React from 'react'
import SubmoduleNavItem from 'Layouts/SubmoduleNavItem'
import Permission from '../permission/Permission';

export default function SubmoduleNav(props) {
    const { navs, setNavs, renderContent } = props;

    return (
        <>
            {
                navs.map((nav, i) => (
                    <Permission
                        key={i}
                        component={
                            <SubmoduleNavItem
                                key={nav.label}
                                nav={nav}
                                renderContent={renderContent}
                                setNavs={setNavs}
                            />
                        }
                        subMenu={nav.permissionName}
                        type={nav.permissionType}
                    />
                ))
            }
        </>
    )
}