import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { loadSalesreturn } from 'Reduxes/consignment/salesreturn/salesreturnActions'
import TableData from './TableData'
import FormElement from './FormElement'
import Detail from './Detail'

function SalesReturn() {

    const dispatch = useDispatch()
    const [content, setContent] = useState('table')
    const [idShow, setIdShow] = useState(null)

    const showDetail = (id) => {
        setIdShow(id);
        setContent('detail')
    }
    const showTable = () => {
        setContent('table');
    }
    const showForm = (type, id) => {
        setIdShow(id);
        
        setContent('form' + type);
    }

    const renderContent = (contentType) => {
        switch (contentType) {
            case 'table':
                return (<TableData showForm={showForm} showDetail={showDetail} />)
            case 'formNew':
                return (<FormElement type="new" showTable={showTable} />)
            case 'formEdit':
                return (<FormElement type="edit" rowId={idShow} showTable={showTable} />)
            case 'detail':
                return (<Detail showForm={showForm} showTable={showTable} rowId={idShow} />)
            default:
                return <>Nothing to show here</>
        }
    }

    useEffect(() => {
        dispatch(loadSalesreturn());
    }, [])

    return (
        <>
            { renderContent(content)}
        </>
    )
}

export default SalesReturn