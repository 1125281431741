import React, { useEffect, useState } from 'react'
import { resetStatusInvoice, addInvoice, updateInvoice, showInvoice } from 'Reduxes/consignment/invoice/invoiceActions'
import { filterProduct } from 'Reduxes/inventory/product/productActions'
import ContentWrapper from 'Comps/container/ContentWrapper'
import Alert from 'Comps/elements/Alert'
import { useDispatch, useSelector } from 'react-redux';
import { scrollToTop } from '@/Helper'
import SelectAsync from 'Elements/SelectAsync';
import Input from 'Elements/Input';
import Datepicker from 'Elements/Datepicker';
import Textarea from 'Elements/Textarea';
import { Formik, Form } from 'formik';
import FieldsContainer from 'Elements/FieldsContainer'
import LoadingContent from 'Elements/LoadingContent'
import FormButton from 'Elements/FormButton'
import LoadingInline from 'Elements/LoadingInline'
import TableFormProduct from './TableFormProduct'
import TableProduct from 'Elements/tableproduct/TableProduct'

function FormElement(props) {
    const [dataErr, setDataErr] = useState(false)
    const { type, showTable, rowId } = props;
    const dispatch = useDispatch();
    const [isReady, setIsReady] = useState(true);
    const [contact, setContact] = useState(null)
    const [tableProductDefaultData, setTableProducDefaulttData] = useState(null);
    const [minSalesPeriodTo, setMinSalesPeriodTo] = useState(new Date());
    const [columns, setColumns] = useState([
        { label: 'Code', name: 'code', className: 'td-1' },
        { label: 'Product Name', name: 'name', className: 'td-1', param: { flat: true } },
        { label: 'Collectible Qty', name: 'qty', type: 'inputNumber', value: 0 },
        { label: 'Remain Qty', name: 'consignment_rest_of_qty' },
        { label: 'UoM', name: 'uom_name' },
        { label: 'Disc (%)', className: "cell-width-as-content", name: 'disc', align: 'right' },
        { label: 'Disc Value', className: 'cell-width-as-content', name: 'disc_value', align: 'right' },
        { label: 'Price /Unit', name: 'sales_price', className: "cell-width-as-content", type: 'money', align: 'right' },
        { label: 'Total', name: 'total', align: 'right' },
    ]);
    const [invoiceItem, setInvoiceItem] = useState([])
    const [stockLocation, setStockLocation] = useState('');
    const [products, setProducts] = useState([]);
    const { error, idEdit, data } = useSelector(state => state.invoiceReducer);
    const { loading: loadingProducts } = useSelector(state => state.productReducer);
    const [initialValues, setInitialValues] = useState({
        "date": new Date().toISOString().split('T')[0],
        "note": '',
        "reference": '',
        "due_date": new Date().toISOString().split('T')[0],
        "start_date": new Date().toISOString().split('T')[0],
        "end_date": new Date().toISOString().split('T')[0],
        "contact_id": '',
        "invoice_items": []
    })
    const formOpts = {
        initialValues,
        onSubmit: async (values, { setSubmitting, resetForm }) => {
            const {
                date, note, reference, due_date,
                start_date, end_date, contact_id
            } = values;

            const invoice_items = invoiceItem.map(inv => {

                if (!inv.product_id && !inv.id) {
                    return null
                }
                return {
                    "product_id": inv.id,
                    "uom_id": inv.uom_id,
                    "price": inv.sales_price,
                    "discount": inv.comission,
                    "qty": +inv.qty,
                    "variant_type_id": inv.variant_type_id
                }
            }).filter(Boolean)

            const cleanVal = {
                date, note, reference, due_date,
                start_date, end_date, contact_id,
                invoice_items
            }

            let result = type === 'new' ?
                await dispatch(addInvoice(cleanVal))
                : await dispatch(updateInvoice(rowId, cleanVal))

            if (result) {
                resetForm();
                showTable();
            }

            scrollToTop()
            setSubmitting(false)
        }
    }
    const handleChangeContact = (data) => {
        setContact(null)
        setColumns(state => {
            state[0].param = { contact_id: data.value, flat: true, consignment: 1 }
            state[1].param = { contact_id: data.value, flat: true, consignment: 1 }
            return [...state];
        })
    }

    useEffect(() => {

        const getDataEdit = async () => {
            const dataInvoice = await dispatch(showInvoice(rowId))

            if (dataInvoice.status) {
                const {
                    date, note, reference, due_date, start_date,
                    end_date, contact_id, invoice_items, contact_name
                } = dataInvoice.data;

                const products = invoice_items.map(data => data.product_id);
                const invoiceProducts = await dispatch(filterProduct({ contact_id, products }))

                const invoiceItems = invoice_items.map(({
                    product_id,
                    uom_id,
                    price,
                    discount = 0,
                    qty = 0,
                }) => ({
                    product_id,
                    uom_id,
                    price,
                    discount,
                    qty,
                }))

                setColumns(state => {
                    state[0].param = { contact_id, flat: true }
                    state[1].param = { contact_id, flat: true }
                    return [...state];
                })
                setContact({ id: contact_id, name: contact_name })
                const parsedInvoiceForTableProduct = invoice_items.map(item => {
                    const { sku, product_id, variant_type_id, uom_id, product_name, color_name, variant_type_value, invoice_qty, rest_qty, price, total, discount, uom_name, uom_value, discount_percentage } = item
                    return {
                        code: sku,
                        name: `${product_name} ${color_name} ${variant_type_value ? `- (${variant_type_value})` : ''}`,
                        qty: invoice_qty,
                        consignment_rest_of_qty: rest_qty,
                        uom_name,
                        comission: discount_percentage,
                        disc_value: discount,
                        sales_price: price,
                        total,
                        uom_value,
                        id: product_id,
                        variant_type_id,
                        uom_id
                    }
                })
                setInvoiceItem(parsedInvoiceForTableProduct)
                setMinSalesPeriodTo(start_date)
                setTableProducDefaulttData(parsedInvoiceForTableProduct)
                setInitialValues({
                    date,
                    note,
                    reference,
                    due_date,
                    start_date,
                    end_date,
                    contact_id,
                    invoice_items: invoiceItems,
                })
                setIsReady(true);
            } else {
                showTable();
            }

            return dataInvoice;
        }

        if (type === 'edit') {
            setIsReady(false);
            getDataEdit();
        }
    }, [])

    return (
        <ContentWrapper
            title={type === 'new' ? "Create New Invoice" : "Update data Invoice"}
            subtitle={`Please fill the form below to ${type === 'new' ? 'add' : 'update'} data Invoice`}
        >
            <>
                {
                    dataErr && <Alert hide={() => setDataErr(false)} option={{ type: 'error', message: 'Product Not Found' }} />
                }
                {
                    error && <Alert hide={() => dispatch(resetStatusInvoice())} option={{ type: 'error', message: error }} />
                }
                {
                    isReady ? (
                        <>
                            <Formik {...formOpts} enableReinitialize={true}>
                                {(formik) => {

                                    const { isSubmitting } = formik;

                                    return (
                                        <Form className="form-layout-1">
                                            <FieldsContainer title="Invoice Information">
                                                <SelectAsync name="contact_id" {...(contact ? { value: { label: contact.name, value: contact.id } } : {})} showAddress options="contact" handleChange={handleChangeContact} label="Contact" />
                                                <Datepicker name="date" label="Date" />
                                                <Datepicker name="due_date" label="Due Date" />
                                                <Input name="reference" label="Reference" />
                                                <Datepicker handleChange={(param) => setMinSalesPeriodTo(param.value)} name="start_date" label="Sales period (from)" />
                                                <Datepicker minDate={minSalesPeriodTo} name="end_date" label="Sales period (to)" />
                                            </FieldsContainer>
                                            <div className="scroll-x">
                                                <TableProduct
                                                    portal={true}
                                                    requireContact={true}
                                                    moduleType="invoice"
                                                    defaultData={tableProductDefaultData}
                                                    columns={columns}
                                                    handlechange={(val) => setInvoiceItem(val)}
                                                    dataErrStatus={setDataErr}
                                                    isConsignment={true}
                                                />
                                            </div>
                                            <Textarea name="note" label="Notes" col="6" />
                                            { loadingProducts && stockLocation !== '' && <LoadingInline message="Loading data products" />}
                                            { products.length > 0 && <TableFormProduct stockLocation={stockLocation} rows={products} />}
                                            <FormButton isSubmitting={isSubmitting} showTable={showTable} />
                                        </Form>
                                    )
                                }}
                            </Formik>

                        </>
                    )
                        : <LoadingContent />
                }

            </>
        </ContentWrapper>
    )
}

export default FormElement